import { GraphQLClient } from 'graphql-request';
import { getSdk } from '@lyon/graphql/types';

const ENDPOINT = 'https://hasura-service.lyon.com.ph/v1/graphql';
const SERVER_ENDPOINT = 'https://hasura-service.lyon.com.ph/v1/graphql';

const client = (token?: string, isServer?: boolean) => (
  new GraphQLClient(isServer ? SERVER_ENDPOINT : ENDPOINT, {
    headers: {
      'content-type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
);

export const IS_REMOTE = process.env.IS_REMOTE === 'true';

export const sdk = (token?: string, isServer?: boolean) => getSdk(client(token, isServer));
