import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  bigint: any;
  date: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['Boolean']>;
  readonly _gt?: Maybe<Scalars['Boolean']>;
  readonly _gte?: Maybe<Scalars['Boolean']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['Boolean']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['Boolean']>;
  readonly _lte?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Scalars['Boolean']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['Boolean']>>;
};

export type CoursePaymentResponse = {
  readonly __typename?: 'CoursePaymentResponse';
  readonly url: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['Int']>;
  readonly _gt?: Maybe<Scalars['Int']>;
  readonly _gte?: Maybe<Scalars['Int']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['Int']>;
  readonly _lte?: Maybe<Scalars['Int']>;
  readonly _neq?: Maybe<Scalars['Int']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['String']>;
  readonly _gt?: Maybe<Scalars['String']>;
  readonly _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  readonly _ilike?: Maybe<Scalars['String']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  readonly _iregex?: Maybe<Scalars['String']>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  readonly _like?: Maybe<Scalars['String']>;
  readonly _lt?: Maybe<Scalars['String']>;
  readonly _lte?: Maybe<Scalars['String']>;
  readonly _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  readonly _nilike?: Maybe<Scalars['String']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  readonly _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  readonly _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  readonly _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  readonly _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  readonly _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  readonly _similar?: Maybe<Scalars['String']>;
};

export type ValidateFormAccessCodeOutput = {
  readonly __typename?: 'ValidateFormAccessCodeOutput';
  readonly valid: Scalars['Boolean'];
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['_text']>;
  readonly _gt?: Maybe<Scalars['_text']>;
  readonly _gte?: Maybe<Scalars['_text']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['_text']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['_text']>;
  readonly _lte?: Maybe<Scalars['_text']>;
  readonly _neq?: Maybe<Scalars['_text']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['_text']>>;
};

/** columns and relationships of "account_google" */
export type Account_Google = {
  readonly __typename?: 'account_google';
  readonly accessToken?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly account: Accounts;
  readonly account_id: Scalars['uuid'];
  readonly created_at: Scalars['timestamptz'];
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id: Scalars['uuid'];
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "account_google" */
export type Account_Google_Aggregate = {
  readonly __typename?: 'account_google_aggregate';
  readonly aggregate?: Maybe<Account_Google_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Account_Google>;
};

/** aggregate fields of "account_google" */
export type Account_Google_Aggregate_Fields = {
  readonly __typename?: 'account_google_aggregate_fields';
  readonly avg?: Maybe<Account_Google_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Account_Google_Max_Fields>;
  readonly min?: Maybe<Account_Google_Min_Fields>;
  readonly stddev?: Maybe<Account_Google_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Account_Google_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Account_Google_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Account_Google_Sum_Fields>;
  readonly var_pop?: Maybe<Account_Google_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Account_Google_Var_Samp_Fields>;
  readonly variance?: Maybe<Account_Google_Variance_Fields>;
};


/** aggregate fields of "account_google" */
export type Account_Google_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_google" */
export type Account_Google_Aggregate_Order_By = {
  readonly avg?: Maybe<Account_Google_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Account_Google_Max_Order_By>;
  readonly min?: Maybe<Account_Google_Min_Order_By>;
  readonly stddev?: Maybe<Account_Google_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Account_Google_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Account_Google_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Account_Google_Sum_Order_By>;
  readonly var_pop?: Maybe<Account_Google_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Account_Google_Var_Samp_Order_By>;
  readonly variance?: Maybe<Account_Google_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_google" */
export type Account_Google_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Account_Google_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Account_Google_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Google_Avg_Fields = {
  readonly __typename?: 'account_google_avg_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_google" */
export type Account_Google_Avg_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_google". All fields are combined with a logical 'AND'. */
export type Account_Google_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Account_Google_Bool_Exp>>;
  readonly _not?: Maybe<Account_Google_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Account_Google_Bool_Exp>>;
  readonly accessToken?: Maybe<String_Comparison_Exp>;
  readonly account?: Maybe<Accounts_Bool_Exp>;
  readonly account_id?: Maybe<Uuid_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly expiresIn?: Maybe<Int_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly refreshToken?: Maybe<String_Comparison_Exp>;
  readonly scope?: Maybe<String_Comparison_Exp>;
  readonly tokenType?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_google" */
export enum Account_Google_Constraint {
  /** unique or primary key constraint */
  AccountGooglePkey = 'account_google_pkey'
}

/** input type for incrementing numeric columns in table "account_google" */
export type Account_Google_Inc_Input = {
  readonly expiresIn?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_google" */
export type Account_Google_Insert_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Google_Max_Fields = {
  readonly __typename?: 'account_google_max_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_google" */
export type Account_Google_Max_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Google_Min_Fields = {
  readonly __typename?: 'account_google_min_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_google" */
export type Account_Google_Min_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_google" */
export type Account_Google_Mutation_Response = {
  readonly __typename?: 'account_google_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Account_Google>;
};

/** on conflict condition type for table "account_google" */
export type Account_Google_On_Conflict = {
  readonly constraint: Account_Google_Constraint;
  readonly update_columns?: ReadonlyArray<Account_Google_Update_Column>;
  readonly where?: Maybe<Account_Google_Bool_Exp>;
};

/** Ordering options when selecting data from "account_google". */
export type Account_Google_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account?: Maybe<Accounts_Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: account_google */
export type Account_Google_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "account_google" */
export enum Account_Google_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  TokenType = 'tokenType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_google" */
export type Account_Google_Set_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Google_Stddev_Fields = {
  readonly __typename?: 'account_google_stddev_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_google" */
export type Account_Google_Stddev_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Google_Stddev_Pop_Fields = {
  readonly __typename?: 'account_google_stddev_pop_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_google" */
export type Account_Google_Stddev_Pop_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Google_Stddev_Samp_Fields = {
  readonly __typename?: 'account_google_stddev_samp_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_google" */
export type Account_Google_Stddev_Samp_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Google_Sum_Fields = {
  readonly __typename?: 'account_google_sum_fields';
  readonly expiresIn?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account_google" */
export type Account_Google_Sum_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** update columns of table "account_google" */
export enum Account_Google_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  TokenType = 'tokenType',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Google_Var_Pop_Fields = {
  readonly __typename?: 'account_google_var_pop_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_google" */
export type Account_Google_Var_Pop_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Google_Var_Samp_Fields = {
  readonly __typename?: 'account_google_var_samp_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_google" */
export type Account_Google_Var_Samp_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Google_Variance_Fields = {
  readonly __typename?: 'account_google_variance_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_google" */
export type Account_Google_Variance_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** columns and relationships of "account_subscription_plans" */
export type Account_Subscription_Plans = {
  readonly __typename?: 'account_subscription_plans';
  /** An array relationship */
  readonly account_subscriptions: ReadonlyArray<Account_Subscriptions>;
  /** An aggregate relationship */
  readonly account_subscriptions_aggregate: Account_Subscriptions_Aggregate;
  readonly type: Scalars['String'];
};


/** columns and relationships of "account_subscription_plans" */
export type Account_Subscription_PlansAccount_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "account_subscription_plans" */
export type Account_Subscription_PlansAccount_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};

/** aggregated selection of "account_subscription_plans" */
export type Account_Subscription_Plans_Aggregate = {
  readonly __typename?: 'account_subscription_plans_aggregate';
  readonly aggregate?: Maybe<Account_Subscription_Plans_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Account_Subscription_Plans>;
};

/** aggregate fields of "account_subscription_plans" */
export type Account_Subscription_Plans_Aggregate_Fields = {
  readonly __typename?: 'account_subscription_plans_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Account_Subscription_Plans_Max_Fields>;
  readonly min?: Maybe<Account_Subscription_Plans_Min_Fields>;
};


/** aggregate fields of "account_subscription_plans" */
export type Account_Subscription_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Account_Subscription_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_subscription_plans". All fields are combined with a logical 'AND'. */
export type Account_Subscription_Plans_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Account_Subscription_Plans_Bool_Exp>>;
  readonly _not?: Maybe<Account_Subscription_Plans_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Account_Subscription_Plans_Bool_Exp>>;
  readonly account_subscriptions?: Maybe<Account_Subscriptions_Bool_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_subscription_plans" */
export enum Account_Subscription_Plans_Constraint {
  /** unique or primary key constraint */
  AccountSubscriptionPlansPkey = 'account_subscription_plans_pkey'
}

export enum Account_Subscription_Plans_Enum {
  Enterprise = 'enterprise',
  Pro = 'pro',
  Starter = 'starter',
  Trial = 'trial'
}

/** Boolean expression to compare columns of type "account_subscription_plans_enum". All fields are combined with logical 'AND'. */
export type Account_Subscription_Plans_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Account_Subscription_Plans_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Account_Subscription_Plans_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Account_Subscription_Plans_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Account_Subscription_Plans_Enum>>;
};

/** input type for inserting data into table "account_subscription_plans" */
export type Account_Subscription_Plans_Insert_Input = {
  readonly account_subscriptions?: Maybe<Account_Subscriptions_Arr_Rel_Insert_Input>;
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Subscription_Plans_Max_Fields = {
  readonly __typename?: 'account_subscription_plans_max_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Subscription_Plans_Min_Fields = {
  readonly __typename?: 'account_subscription_plans_min_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account_subscription_plans" */
export type Account_Subscription_Plans_Mutation_Response = {
  readonly __typename?: 'account_subscription_plans_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Account_Subscription_Plans>;
};

/** input type for inserting object relation for remote table "account_subscription_plans" */
export type Account_Subscription_Plans_Obj_Rel_Insert_Input = {
  readonly data: Account_Subscription_Plans_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Account_Subscription_Plans_On_Conflict>;
};

/** on conflict condition type for table "account_subscription_plans" */
export type Account_Subscription_Plans_On_Conflict = {
  readonly constraint: Account_Subscription_Plans_Constraint;
  readonly update_columns?: ReadonlyArray<Account_Subscription_Plans_Update_Column>;
  readonly where?: Maybe<Account_Subscription_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "account_subscription_plans". */
export type Account_Subscription_Plans_Order_By = {
  readonly account_subscriptions_aggregate?: Maybe<Account_Subscriptions_Aggregate_Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: account_subscription_plans */
export type Account_Subscription_Plans_Pk_Columns_Input = {
  readonly type: Scalars['String'];
};

/** select columns of table "account_subscription_plans" */
export enum Account_Subscription_Plans_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "account_subscription_plans" */
export type Account_Subscription_Plans_Set_Input = {
  readonly type?: Maybe<Scalars['String']>;
};

/** update columns of table "account_subscription_plans" */
export enum Account_Subscription_Plans_Update_Column {
  /** column name */
  Type = 'type'
}

/** columns and relationships of "account_subscriptions" */
export type Account_Subscriptions = {
  readonly __typename?: 'account_subscriptions';
  /** An object relationship */
  readonly account: Accounts;
  readonly account_id: Scalars['uuid'];
  /** An object relationship */
  readonly account_subscription_plan: Account_Subscription_Plans;
  readonly created_at: Scalars['timestamptz'];
  readonly features: Scalars['_text'];
  readonly id: Scalars['uuid'];
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
  readonly plan: Account_Subscription_Plans_Enum;
  readonly updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "account_subscriptions" */
export type Account_Subscriptions_Aggregate = {
  readonly __typename?: 'account_subscriptions_aggregate';
  readonly aggregate?: Maybe<Account_Subscriptions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Account_Subscriptions>;
};

/** aggregate fields of "account_subscriptions" */
export type Account_Subscriptions_Aggregate_Fields = {
  readonly __typename?: 'account_subscriptions_aggregate_fields';
  readonly avg?: Maybe<Account_Subscriptions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Account_Subscriptions_Max_Fields>;
  readonly min?: Maybe<Account_Subscriptions_Min_Fields>;
  readonly stddev?: Maybe<Account_Subscriptions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Account_Subscriptions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Account_Subscriptions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Account_Subscriptions_Sum_Fields>;
  readonly var_pop?: Maybe<Account_Subscriptions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Account_Subscriptions_Var_Samp_Fields>;
  readonly variance?: Maybe<Account_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "account_subscriptions" */
export type Account_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_subscriptions" */
export type Account_Subscriptions_Aggregate_Order_By = {
  readonly avg?: Maybe<Account_Subscriptions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Account_Subscriptions_Max_Order_By>;
  readonly min?: Maybe<Account_Subscriptions_Min_Order_By>;
  readonly stddev?: Maybe<Account_Subscriptions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Account_Subscriptions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Account_Subscriptions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Account_Subscriptions_Sum_Order_By>;
  readonly var_pop?: Maybe<Account_Subscriptions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Account_Subscriptions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Account_Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_subscriptions" */
export type Account_Subscriptions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Account_Subscriptions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Account_Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Subscriptions_Avg_Fields = {
  readonly __typename?: 'account_subscriptions_avg_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Avg_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_subscriptions". All fields are combined with a logical 'AND'. */
export type Account_Subscriptions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Account_Subscriptions_Bool_Exp>>;
  readonly _not?: Maybe<Account_Subscriptions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Account_Subscriptions_Bool_Exp>>;
  readonly account?: Maybe<Accounts_Bool_Exp>;
  readonly account_id?: Maybe<Uuid_Comparison_Exp>;
  readonly account_subscription_plan?: Maybe<Account_Subscription_Plans_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly features?: Maybe<_Text_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly max_admin_seats?: Maybe<Int_Comparison_Exp>;
  readonly max_monthly_active_students?: Maybe<Int_Comparison_Exp>;
  readonly plan?: Maybe<Account_Subscription_Plans_Enum_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_subscriptions" */
export enum Account_Subscriptions_Constraint {
  /** unique or primary key constraint */
  AccountSubscriptionsPkey = 'account_subscriptions_pkey'
}

/** input type for incrementing numeric columns in table "account_subscriptions" */
export type Account_Subscriptions_Inc_Input = {
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_subscriptions" */
export type Account_Subscriptions_Insert_Input = {
  readonly account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly account_subscription_plan?: Maybe<Account_Subscription_Plans_Obj_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly features?: Maybe<Scalars['_text']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
  readonly plan?: Maybe<Account_Subscription_Plans_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Subscriptions_Max_Fields = {
  readonly __typename?: 'account_subscriptions_max_fields';
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Max_Order_By = {
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Subscriptions_Min_Fields = {
  readonly __typename?: 'account_subscriptions_min_fields';
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Min_Order_By = {
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_subscriptions" */
export type Account_Subscriptions_Mutation_Response = {
  readonly __typename?: 'account_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Account_Subscriptions>;
};

/** on conflict condition type for table "account_subscriptions" */
export type Account_Subscriptions_On_Conflict = {
  readonly constraint: Account_Subscriptions_Constraint;
  readonly update_columns?: ReadonlyArray<Account_Subscriptions_Update_Column>;
  readonly where?: Maybe<Account_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "account_subscriptions". */
export type Account_Subscriptions_Order_By = {
  readonly account?: Maybe<Accounts_Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly account_subscription_plan?: Maybe<Account_Subscription_Plans_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly features?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
  readonly plan?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: account_subscriptions */
export type Account_Subscriptions_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "account_subscriptions" */
export enum Account_Subscriptions_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAdminSeats = 'max_admin_seats',
  /** column name */
  MaxMonthlyActiveStudents = 'max_monthly_active_students',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_subscriptions" */
export type Account_Subscriptions_Set_Input = {
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly features?: Maybe<Scalars['_text']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
  readonly plan?: Maybe<Account_Subscription_Plans_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Subscriptions_Stddev_Fields = {
  readonly __typename?: 'account_subscriptions_stddev_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Stddev_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Subscriptions_Stddev_Pop_Fields = {
  readonly __typename?: 'account_subscriptions_stddev_pop_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Stddev_Pop_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Subscriptions_Stddev_Samp_Fields = {
  readonly __typename?: 'account_subscriptions_stddev_samp_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Stddev_Samp_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Subscriptions_Sum_Fields = {
  readonly __typename?: 'account_subscriptions_sum_fields';
  readonly max_admin_seats?: Maybe<Scalars['Int']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Sum_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** update columns of table "account_subscriptions" */
export enum Account_Subscriptions_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAdminSeats = 'max_admin_seats',
  /** column name */
  MaxMonthlyActiveStudents = 'max_monthly_active_students',
  /** column name */
  Plan = 'plan',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Subscriptions_Var_Pop_Fields = {
  readonly __typename?: 'account_subscriptions_var_pop_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Var_Pop_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Subscriptions_Var_Samp_Fields = {
  readonly __typename?: 'account_subscriptions_var_samp_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Var_Samp_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Subscriptions_Variance_Fields = {
  readonly __typename?: 'account_subscriptions_variance_fields';
  readonly max_admin_seats?: Maybe<Scalars['Float']>;
  readonly max_monthly_active_students?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_subscriptions" */
export type Account_Subscriptions_Variance_Order_By = {
  readonly max_admin_seats?: Maybe<Order_By>;
  readonly max_monthly_active_students?: Maybe<Order_By>;
};

/** columns and relationships of "account_zoom" */
export type Account_Zoom = {
  readonly __typename?: 'account_zoom';
  readonly accessToken?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly account: Accounts;
  readonly account_id: Scalars['uuid'];
  readonly created_at: Scalars['timestamptz'];
  readonly id: Scalars['uuid'];
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account_zoom" */
export type Account_Zoom_Aggregate = {
  readonly __typename?: 'account_zoom_aggregate';
  readonly aggregate?: Maybe<Account_Zoom_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Account_Zoom>;
};

/** aggregate fields of "account_zoom" */
export type Account_Zoom_Aggregate_Fields = {
  readonly __typename?: 'account_zoom_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Account_Zoom_Max_Fields>;
  readonly min?: Maybe<Account_Zoom_Min_Fields>;
};


/** aggregate fields of "account_zoom" */
export type Account_Zoom_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_zoom" */
export type Account_Zoom_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Account_Zoom_Max_Order_By>;
  readonly min?: Maybe<Account_Zoom_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account_zoom" */
export type Account_Zoom_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Account_Zoom_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Account_Zoom_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_zoom". All fields are combined with a logical 'AND'. */
export type Account_Zoom_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Account_Zoom_Bool_Exp>>;
  readonly _not?: Maybe<Account_Zoom_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Account_Zoom_Bool_Exp>>;
  readonly accessToken?: Maybe<String_Comparison_Exp>;
  readonly account?: Maybe<Accounts_Bool_Exp>;
  readonly account_id?: Maybe<Uuid_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly refreshToken?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly zoomAccountId?: Maybe<String_Comparison_Exp>;
  readonly zoomUserId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_zoom" */
export enum Account_Zoom_Constraint {
  /** unique or primary key constraint */
  AccountZoomPkey = 'account_zoom_pkey'
}

/** input type for inserting data into table "account_zoom" */
export type Account_Zoom_Insert_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Zoom_Max_Fields = {
  readonly __typename?: 'account_zoom_max_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account_zoom" */
export type Account_Zoom_Max_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Zoom_Min_Fields = {
  readonly __typename?: 'account_zoom_min_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account_zoom" */
export type Account_Zoom_Min_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_zoom" */
export type Account_Zoom_Mutation_Response = {
  readonly __typename?: 'account_zoom_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Account_Zoom>;
};

/** on conflict condition type for table "account_zoom" */
export type Account_Zoom_On_Conflict = {
  readonly constraint: Account_Zoom_Constraint;
  readonly update_columns?: ReadonlyArray<Account_Zoom_Update_Column>;
  readonly where?: Maybe<Account_Zoom_Bool_Exp>;
};

/** Ordering options when selecting data from "account_zoom". */
export type Account_Zoom_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly account?: Maybe<Accounts_Order_By>;
  readonly account_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: account_zoom */
export type Account_Zoom_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "account_zoom" */
export enum Account_Zoom_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

/** input type for updating data in table "account_zoom" */
export type Account_Zoom_Set_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly account_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** update columns of table "account_zoom" */
export enum Account_Zoom_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

/** columns and relationships of "accounts" */
export type Accounts = {
  readonly __typename?: 'accounts';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly account?: Maybe<Accounts>;
  /** An array relationship */
  readonly account_googles: ReadonlyArray<Account_Google>;
  /** An aggregate relationship */
  readonly account_googles_aggregate: Account_Google_Aggregate;
  /** An array relationship */
  readonly account_subscriptions: ReadonlyArray<Account_Subscriptions>;
  /** An aggregate relationship */
  readonly account_subscriptions_aggregate: Account_Subscriptions_Aggregate;
  /** An array relationship */
  readonly account_zooms: ReadonlyArray<Account_Zoom>;
  /** An aggregate relationship */
  readonly account_zooms_aggregate: Account_Zoom_Aggregate;
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly created_at: Scalars['timestamp'];
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Scalars['String']>;
  readonly default_storage_quota: Scalars['Int'];
  readonly default_time_zone: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id: Scalars['uuid'];
  readonly name: Scalars['String'];
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Scalars['uuid']>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Scalars['uuid']>;
  readonly storage_quota: Scalars['Int'];
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "accounts" */
export type AccountsAccount_GooglesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Googles_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_ZoomsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Zooms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  readonly __typename?: 'accounts_aggregate';
  readonly aggregate?: Maybe<Accounts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Accounts>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  readonly __typename?: 'accounts_aggregate_fields';
  readonly avg?: Maybe<Accounts_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Accounts_Max_Fields>;
  readonly min?: Maybe<Accounts_Min_Fields>;
  readonly stddev?: Maybe<Accounts_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Accounts_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Accounts_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Accounts_Sum_Fields>;
  readonly var_pop?: Maybe<Accounts_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Accounts_Var_Samp_Fields>;
  readonly variance?: Maybe<Accounts_Variance_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  readonly avg?: Maybe<Accounts_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Accounts_Max_Order_By>;
  readonly min?: Maybe<Accounts_Min_Order_By>;
  readonly stddev?: Maybe<Accounts_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Accounts_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Accounts_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Accounts_Sum_Order_By>;
  readonly var_pop?: Maybe<Accounts_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Accounts_Var_Samp_Order_By>;
  readonly variance?: Maybe<Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Accounts_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Accounts_Avg_Fields = {
  readonly __typename?: 'accounts_avg_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "accounts" */
export type Accounts_Avg_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly _not?: Maybe<Accounts_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly aboutVideoUuid?: Maybe<String_Comparison_Exp>;
  readonly account?: Maybe<Accounts_Bool_Exp>;
  readonly account_googles?: Maybe<Account_Google_Bool_Exp>;
  readonly account_subscriptions?: Maybe<Account_Subscriptions_Bool_Exp>;
  readonly account_zooms?: Maybe<Account_Zoom_Bool_Exp>;
  readonly accounts?: Maybe<Accounts_Bool_Exp>;
  readonly address?: Maybe<String_Comparison_Exp>;
  readonly certificateLogoUuid?: Maybe<String_Comparison_Exp>;
  readonly contactNumber?: Maybe<String_Comparison_Exp>;
  readonly coverPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly custom_domain?: Maybe<String_Comparison_Exp>;
  readonly default_storage_quota?: Maybe<Int_Comparison_Exp>;
  readonly default_time_zone?: Maybe<String_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly displayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly parent_account_id?: Maybe<Uuid_Comparison_Exp>;
  readonly root_account_id?: Maybe<Uuid_Comparison_Exp>;
  readonly storage_quota?: Maybe<Int_Comparison_Exp>;
  readonly tenant_id?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey'
}

/** input type for incrementing numeric columns in table "accounts" */
export type Accounts_Inc_Input = {
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  readonly account_googles?: Maybe<Account_Google_Arr_Rel_Insert_Input>;
  readonly account_subscriptions?: Maybe<Account_Subscriptions_Arr_Rel_Insert_Input>;
  readonly account_zooms?: Maybe<Account_Zoom_Arr_Rel_Insert_Input>;
  readonly accounts?: Maybe<Accounts_Arr_Rel_Insert_Input>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Scalars['String']>;
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly default_time_zone?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Scalars['uuid']>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Scalars['uuid']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  readonly __typename?: 'accounts_max_fields';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Scalars['String']>;
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly default_time_zone?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Scalars['uuid']>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Scalars['uuid']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  readonly aboutVideoUuid?: Maybe<Order_By>;
  readonly address?: Maybe<Order_By>;
  readonly certificateLogoUuid?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly coverPhotoUuid?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Order_By>;
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly default_time_zone?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Order_By>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  readonly __typename?: 'accounts_min_fields';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Scalars['String']>;
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly default_time_zone?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Scalars['uuid']>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Scalars['uuid']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  readonly aboutVideoUuid?: Maybe<Order_By>;
  readonly address?: Maybe<Order_By>;
  readonly certificateLogoUuid?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly coverPhotoUuid?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Order_By>;
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly default_time_zone?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Order_By>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  readonly __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  readonly data: Accounts_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Accounts_On_Conflict>;
};

/** on conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  readonly constraint: Accounts_Constraint;
  readonly update_columns?: ReadonlyArray<Accounts_Update_Column>;
  readonly where?: Maybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  readonly aboutVideoUuid?: Maybe<Order_By>;
  readonly account?: Maybe<Accounts_Order_By>;
  readonly account_googles_aggregate?: Maybe<Account_Google_Aggregate_Order_By>;
  readonly account_subscriptions_aggregate?: Maybe<Account_Subscriptions_Aggregate_Order_By>;
  readonly account_zooms_aggregate?: Maybe<Account_Zoom_Aggregate_Order_By>;
  readonly accounts_aggregate?: Maybe<Accounts_Aggregate_Order_By>;
  readonly address?: Maybe<Order_By>;
  readonly certificateLogoUuid?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly coverPhotoUuid?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly custom_domain?: Maybe<Order_By>;
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly default_time_zone?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly parent_account_id?: Maybe<Order_By>;
  readonly root_account_id?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
  readonly tenant_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AboutVideoUuid = 'aboutVideoUuid',
  /** column name */
  Address = 'address',
  /** column name */
  CertificateLogoUuid = 'certificateLogoUuid',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CoverPhotoUuid = 'coverPhotoUuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomDomain = 'custom_domain',
  /** column name */
  DefaultStorageQuota = 'default_storage_quota',
  /** column name */
  DefaultTimeZone = 'default_time_zone',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentAccountId = 'parent_account_id',
  /** column name */
  RootAccountId = 'root_account_id',
  /** column name */
  StorageQuota = 'storage_quota',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  /** Learning Space's custom domain (hostname) */
  readonly custom_domain?: Maybe<Scalars['String']>;
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly default_time_zone?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  /** The account's parent ID, or null if this is the root account */
  readonly parent_account_id?: Maybe<Scalars['uuid']>;
  /** The ID of the root account that this account belongs to, or null if this is the root account */
  readonly root_account_id?: Maybe<Scalars['uuid']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
  /** Google Cloud Identity Platform Multi-tenant ID */
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Accounts_Stddev_Fields = {
  readonly __typename?: 'accounts_stddev_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "accounts" */
export type Accounts_Stddev_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Accounts_Stddev_Pop_Fields = {
  readonly __typename?: 'accounts_stddev_pop_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "accounts" */
export type Accounts_Stddev_Pop_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Accounts_Stddev_Samp_Fields = {
  readonly __typename?: 'accounts_stddev_samp_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "accounts" */
export type Accounts_Stddev_Samp_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Accounts_Sum_Fields = {
  readonly __typename?: 'accounts_sum_fields';
  readonly default_storage_quota?: Maybe<Scalars['Int']>;
  readonly storage_quota?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "accounts" */
export type Accounts_Sum_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  AboutVideoUuid = 'aboutVideoUuid',
  /** column name */
  Address = 'address',
  /** column name */
  CertificateLogoUuid = 'certificateLogoUuid',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CoverPhotoUuid = 'coverPhotoUuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomDomain = 'custom_domain',
  /** column name */
  DefaultStorageQuota = 'default_storage_quota',
  /** column name */
  DefaultTimeZone = 'default_time_zone',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentAccountId = 'parent_account_id',
  /** column name */
  RootAccountId = 'root_account_id',
  /** column name */
  StorageQuota = 'storage_quota',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Accounts_Var_Pop_Fields = {
  readonly __typename?: 'accounts_var_pop_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "accounts" */
export type Accounts_Var_Pop_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Accounts_Var_Samp_Fields = {
  readonly __typename?: 'accounts_var_samp_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "accounts" */
export type Accounts_Var_Samp_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Accounts_Variance_Fields = {
  readonly __typename?: 'accounts_variance_fields';
  readonly default_storage_quota?: Maybe<Scalars['Float']>;
  readonly storage_quota?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "accounts" */
export type Accounts_Variance_Order_By = {
  readonly default_storage_quota?: Maybe<Order_By>;
  readonly storage_quota?: Maybe<Order_By>;
};

/** columns and relationships of "ar_internal_metadata" */
export type Ar_Internal_Metadata = {
  readonly __typename?: 'ar_internal_metadata';
  readonly created_at: Scalars['timestamp'];
  readonly key: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ar_internal_metadata" */
export type Ar_Internal_Metadata_Aggregate = {
  readonly __typename?: 'ar_internal_metadata_aggregate';
  readonly aggregate?: Maybe<Ar_Internal_Metadata_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Ar_Internal_Metadata>;
};

/** aggregate fields of "ar_internal_metadata" */
export type Ar_Internal_Metadata_Aggregate_Fields = {
  readonly __typename?: 'ar_internal_metadata_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Ar_Internal_Metadata_Max_Fields>;
  readonly min?: Maybe<Ar_Internal_Metadata_Min_Fields>;
};


/** aggregate fields of "ar_internal_metadata" */
export type Ar_Internal_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ar_internal_metadata". All fields are combined with a logical 'AND'. */
export type Ar_Internal_Metadata_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Bool_Exp>>;
  readonly _not?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly key?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ar_internal_metadata" */
export enum Ar_Internal_Metadata_Constraint {
  /** unique or primary key constraint */
  ArInternalMetadataPkey = 'ar_internal_metadata_pkey'
}

/** input type for inserting data into table "ar_internal_metadata" */
export type Ar_Internal_Metadata_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ar_Internal_Metadata_Max_Fields = {
  readonly __typename?: 'ar_internal_metadata_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ar_Internal_Metadata_Min_Fields = {
  readonly __typename?: 'ar_internal_metadata_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ar_internal_metadata" */
export type Ar_Internal_Metadata_Mutation_Response = {
  readonly __typename?: 'ar_internal_metadata_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Ar_Internal_Metadata>;
};

/** on conflict condition type for table "ar_internal_metadata" */
export type Ar_Internal_Metadata_On_Conflict = {
  readonly constraint: Ar_Internal_Metadata_Constraint;
  readonly update_columns?: ReadonlyArray<Ar_Internal_Metadata_Update_Column>;
  readonly where?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "ar_internal_metadata". */
export type Ar_Internal_Metadata_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly key?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: ar_internal_metadata */
export type Ar_Internal_Metadata_Pk_Columns_Input = {
  readonly key: Scalars['String'];
};

/** select columns of table "ar_internal_metadata" */
export enum Ar_Internal_Metadata_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ar_internal_metadata" */
export type Ar_Internal_Metadata_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly key?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "ar_internal_metadata" */
export enum Ar_Internal_Metadata_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "attachment" */
export type Attachment = {
  readonly __typename?: 'attachment';
  readonly bucket: Scalars['String'];
  readonly createdAt: Scalars['timestamp'];
  readonly id: Scalars['uuid'];
  readonly isArchived: Scalars['Boolean'];
  readonly mimeType: Scalars['String'];
  readonly name: Scalars['String'];
  readonly rootEntityId?: Maybe<Scalars['String']>;
  readonly rootType: Scalars['String'];
  readonly size: Scalars['Int'];
  readonly updatedAt: Scalars['timestamp'];
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "attachment" */
export type Attachment_Aggregate = {
  readonly __typename?: 'attachment_aggregate';
  readonly aggregate?: Maybe<Attachment_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Attachment>;
};

/** aggregate fields of "attachment" */
export type Attachment_Aggregate_Fields = {
  readonly __typename?: 'attachment_aggregate_fields';
  readonly avg?: Maybe<Attachment_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Attachment_Max_Fields>;
  readonly min?: Maybe<Attachment_Min_Fields>;
  readonly stddev?: Maybe<Attachment_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Attachment_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Attachment_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Attachment_Sum_Fields>;
  readonly var_pop?: Maybe<Attachment_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Attachment_Var_Samp_Fields>;
  readonly variance?: Maybe<Attachment_Variance_Fields>;
};


/** aggregate fields of "attachment" */
export type Attachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Attachment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Attachment_Avg_Fields = {
  readonly __typename?: 'attachment_avg_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "attachment". All fields are combined with a logical 'AND'. */
export type Attachment_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Attachment_Bool_Exp>>;
  readonly _not?: Maybe<Attachment_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Attachment_Bool_Exp>>;
  readonly bucket?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly mimeType?: Maybe<String_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly rootEntityId?: Maybe<String_Comparison_Exp>;
  readonly rootType?: Maybe<String_Comparison_Exp>;
  readonly size?: Maybe<Int_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachment" */
export enum Attachment_Constraint {
  /** unique or primary key constraint */
  PkD2a80c3a8d467f08a750ac4b420 = 'PK_d2a80c3a8d467f08a750ac4b420'
}

/** input type for incrementing numeric columns in table "attachment" */
export type Attachment_Inc_Input = {
  readonly size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "attachment" */
export type Attachment_Insert_Input = {
  readonly bucket?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly rootEntityId?: Maybe<Scalars['String']>;
  readonly rootType?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Attachment_Max_Fields = {
  readonly __typename?: 'attachment_max_fields';
  readonly bucket?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly rootEntityId?: Maybe<Scalars['String']>;
  readonly rootType?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Attachment_Min_Fields = {
  readonly __typename?: 'attachment_min_fields';
  readonly bucket?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly rootEntityId?: Maybe<Scalars['String']>;
  readonly rootType?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attachment" */
export type Attachment_Mutation_Response = {
  readonly __typename?: 'attachment_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Attachment>;
};

/** on conflict condition type for table "attachment" */
export type Attachment_On_Conflict = {
  readonly constraint: Attachment_Constraint;
  readonly update_columns?: ReadonlyArray<Attachment_Update_Column>;
  readonly where?: Maybe<Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment". */
export type Attachment_Order_By = {
  readonly bucket?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly mimeType?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly rootEntityId?: Maybe<Order_By>;
  readonly rootType?: Maybe<Order_By>;
  readonly size?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: attachment */
export type Attachment_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "attachment" */
export enum Attachment_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  RootEntityId = 'rootEntityId',
  /** column name */
  RootType = 'rootType',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "attachment" */
export type Attachment_Set_Input = {
  readonly bucket?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly rootEntityId?: Maybe<Scalars['String']>;
  readonly rootType?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Attachment_Stddev_Fields = {
  readonly __typename?: 'attachment_stddev_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Stddev_Pop_Fields = {
  readonly __typename?: 'attachment_stddev_pop_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Stddev_Samp_Fields = {
  readonly __typename?: 'attachment_stddev_samp_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Sum_Fields = {
  readonly __typename?: 'attachment_sum_fields';
  readonly size?: Maybe<Scalars['Int']>;
};

/** update columns of table "attachment" */
export enum Attachment_Update_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  RootEntityId = 'rootEntityId',
  /** column name */
  RootType = 'rootType',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Attachment_Var_Pop_Fields = {
  readonly __typename?: 'attachment_var_pop_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Var_Samp_Fields = {
  readonly __typename?: 'attachment_var_samp_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Variance_Fields = {
  readonly __typename?: 'attachment_variance_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "attendance_logs" */
export type Attendance_Logs = {
  readonly __typename?: 'attendance_logs';
  /** An object relationship */
  readonly attendance: Attendances;
  readonly attendance_id: Scalars['uuid'];
  readonly created_at: Scalars['timestamptz'];
  readonly id: Scalars['uuid'];
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "attendance_logs" */
export type Attendance_Logs_Aggregate = {
  readonly __typename?: 'attendance_logs_aggregate';
  readonly aggregate?: Maybe<Attendance_Logs_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Attendance_Logs>;
};

/** aggregate fields of "attendance_logs" */
export type Attendance_Logs_Aggregate_Fields = {
  readonly __typename?: 'attendance_logs_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Attendance_Logs_Max_Fields>;
  readonly min?: Maybe<Attendance_Logs_Min_Fields>;
};


/** aggregate fields of "attendance_logs" */
export type Attendance_Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "attendance_logs" */
export type Attendance_Logs_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Attendance_Logs_Max_Order_By>;
  readonly min?: Maybe<Attendance_Logs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "attendance_logs" */
export type Attendance_Logs_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Attendance_Logs_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Attendance_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "attendance_logs". All fields are combined with a logical 'AND'. */
export type Attendance_Logs_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Attendance_Logs_Bool_Exp>>;
  readonly _not?: Maybe<Attendance_Logs_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Attendance_Logs_Bool_Exp>>;
  readonly attendance?: Maybe<Attendances_Bool_Exp>;
  readonly attendance_id?: Maybe<Uuid_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "attendance_logs" */
export enum Attendance_Logs_Constraint {
  /** unique or primary key constraint */
  AttendanceLogsPkey = 'attendance_logs_pkey'
}

/** input type for inserting data into table "attendance_logs" */
export type Attendance_Logs_Insert_Input = {
  readonly attendance?: Maybe<Attendances_Obj_Rel_Insert_Input>;
  readonly attendance_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Attendance_Logs_Max_Fields = {
  readonly __typename?: 'attendance_logs_max_fields';
  readonly attendance_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "attendance_logs" */
export type Attendance_Logs_Max_Order_By = {
  readonly attendance_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Attendance_Logs_Min_Fields = {
  readonly __typename?: 'attendance_logs_min_fields';
  readonly attendance_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "attendance_logs" */
export type Attendance_Logs_Min_Order_By = {
  readonly attendance_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "attendance_logs" */
export type Attendance_Logs_Mutation_Response = {
  readonly __typename?: 'attendance_logs_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Attendance_Logs>;
};

/** on conflict condition type for table "attendance_logs" */
export type Attendance_Logs_On_Conflict = {
  readonly constraint: Attendance_Logs_Constraint;
  readonly update_columns?: ReadonlyArray<Attendance_Logs_Update_Column>;
  readonly where?: Maybe<Attendance_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "attendance_logs". */
export type Attendance_Logs_Order_By = {
  readonly attendance?: Maybe<Attendances_Order_By>;
  readonly attendance_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: attendance_logs */
export type Attendance_Logs_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "attendance_logs" */
export enum Attendance_Logs_Select_Column {
  /** column name */
  AttendanceId = 'attendance_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "attendance_logs" */
export type Attendance_Logs_Set_Input = {
  readonly attendance_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "attendance_logs" */
export enum Attendance_Logs_Update_Column {
  /** column name */
  AttendanceId = 'attendance_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "attendances" */
export type Attendances = {
  readonly __typename?: 'attendances';
  /** An array relationship */
  readonly attendance_logs: ReadonlyArray<Attendance_Logs>;
  /** An aggregate relationship */
  readonly attendance_logs_aggregate: Attendance_Logs_Aggregate;
  readonly created_at: Scalars['timestamptz'];
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly live_session: Course_Live_Sessions;
  readonly live_session_id: Scalars['bigint'];
};


/** columns and relationships of "attendances" */
export type AttendancesAttendance_LogsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};


/** columns and relationships of "attendances" */
export type AttendancesAttendance_Logs_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};

/** aggregated selection of "attendances" */
export type Attendances_Aggregate = {
  readonly __typename?: 'attendances_aggregate';
  readonly aggregate?: Maybe<Attendances_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Attendances>;
};

/** aggregate fields of "attendances" */
export type Attendances_Aggregate_Fields = {
  readonly __typename?: 'attendances_aggregate_fields';
  readonly avg?: Maybe<Attendances_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Attendances_Max_Fields>;
  readonly min?: Maybe<Attendances_Min_Fields>;
  readonly stddev?: Maybe<Attendances_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Attendances_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Attendances_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Attendances_Sum_Fields>;
  readonly var_pop?: Maybe<Attendances_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Attendances_Var_Samp_Fields>;
  readonly variance?: Maybe<Attendances_Variance_Fields>;
};


/** aggregate fields of "attendances" */
export type Attendances_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "attendances" */
export type Attendances_Aggregate_Order_By = {
  readonly avg?: Maybe<Attendances_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Attendances_Max_Order_By>;
  readonly min?: Maybe<Attendances_Min_Order_By>;
  readonly stddev?: Maybe<Attendances_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Attendances_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Attendances_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Attendances_Sum_Order_By>;
  readonly var_pop?: Maybe<Attendances_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Attendances_Var_Samp_Order_By>;
  readonly variance?: Maybe<Attendances_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attendances" */
export type Attendances_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Attendances_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Attendances_On_Conflict>;
};

/** aggregate avg on columns */
export type Attendances_Avg_Fields = {
  readonly __typename?: 'attendances_avg_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "attendances" */
export type Attendances_Avg_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attendances". All fields are combined with a logical 'AND'. */
export type Attendances_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Attendances_Bool_Exp>>;
  readonly _not?: Maybe<Attendances_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Attendances_Bool_Exp>>;
  readonly attendance_logs?: Maybe<Attendance_Logs_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly live_session?: Maybe<Course_Live_Sessions_Bool_Exp>;
  readonly live_session_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "attendances" */
export enum Attendances_Constraint {
  /** unique or primary key constraint */
  AttendancesPkey = 'attendances_pkey'
}

/** input type for incrementing numeric columns in table "attendances" */
export type Attendances_Inc_Input = {
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "attendances" */
export type Attendances_Insert_Input = {
  readonly attendance_logs?: Maybe<Attendance_Logs_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session?: Maybe<Course_Live_Sessions_Obj_Rel_Insert_Input>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Attendances_Max_Fields = {
  readonly __typename?: 'attendances_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "attendances" */
export type Attendances_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Attendances_Min_Fields = {
  readonly __typename?: 'attendances_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "attendances" */
export type Attendances_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "attendances" */
export type Attendances_Mutation_Response = {
  readonly __typename?: 'attendances_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Attendances>;
};

/** input type for inserting object relation for remote table "attendances" */
export type Attendances_Obj_Rel_Insert_Input = {
  readonly data: Attendances_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Attendances_On_Conflict>;
};

/** on conflict condition type for table "attendances" */
export type Attendances_On_Conflict = {
  readonly constraint: Attendances_Constraint;
  readonly update_columns?: ReadonlyArray<Attendances_Update_Column>;
  readonly where?: Maybe<Attendances_Bool_Exp>;
};

/** Ordering options when selecting data from "attendances". */
export type Attendances_Order_By = {
  readonly attendance_logs_aggregate?: Maybe<Attendance_Logs_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly live_session?: Maybe<Course_Live_Sessions_Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
};

/** primary key columns input for table: attendances */
export type Attendances_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "attendances" */
export enum Attendances_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LiveSessionId = 'live_session_id'
}

/** input type for updating data in table "attendances" */
export type Attendances_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Attendances_Stddev_Fields = {
  readonly __typename?: 'attendances_stddev_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "attendances" */
export type Attendances_Stddev_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attendances_Stddev_Pop_Fields = {
  readonly __typename?: 'attendances_stddev_pop_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "attendances" */
export type Attendances_Stddev_Pop_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attendances_Stddev_Samp_Fields = {
  readonly __typename?: 'attendances_stddev_samp_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "attendances" */
export type Attendances_Stddev_Samp_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Attendances_Sum_Fields = {
  readonly __typename?: 'attendances_sum_fields';
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "attendances" */
export type Attendances_Sum_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** update columns of table "attendances" */
export enum Attendances_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LiveSessionId = 'live_session_id'
}

/** aggregate var_pop on columns */
export type Attendances_Var_Pop_Fields = {
  readonly __typename?: 'attendances_var_pop_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "attendances" */
export type Attendances_Var_Pop_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attendances_Var_Samp_Fields = {
  readonly __typename?: 'attendances_var_samp_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "attendances" */
export type Attendances_Var_Samp_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Attendances_Variance_Fields = {
  readonly __typename?: 'attendances_variance_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "attendances" */
export type Attendances_Variance_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['bigint']>;
  readonly _gt?: Maybe<Scalars['bigint']>;
  readonly _gte?: Maybe<Scalars['bigint']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['bigint']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['bigint']>;
  readonly _lte?: Maybe<Scalars['bigint']>;
  readonly _neq?: Maybe<Scalars['bigint']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['bigint']>>;
};

/** columns and relationships of "breakout_group" */
export type Breakout_Group = {
  readonly __typename?: 'breakout_group';
  readonly breakoutRoomId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  readonly breakout_group_participants_event_participants: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
  /** An aggregate relationship */
  readonly breakout_group_participants_event_participants_aggregate: Breakout_Group_Participants_Event_Participant_Aggregate;
  /** An object relationship */
  readonly breakout_room?: Maybe<Breakout_Room>;
  readonly createdAt: Scalars['timestamp'];
  readonly id: Scalars['uuid'];
  readonly isActive: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "breakout_group" */
export type Breakout_GroupBreakout_Group_Participants_Event_ParticipantsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


/** columns and relationships of "breakout_group" */
export type Breakout_GroupBreakout_Group_Participants_Event_Participants_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};

/** aggregated selection of "breakout_group" */
export type Breakout_Group_Aggregate = {
  readonly __typename?: 'breakout_group_aggregate';
  readonly aggregate?: Maybe<Breakout_Group_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Breakout_Group>;
};

/** aggregate fields of "breakout_group" */
export type Breakout_Group_Aggregate_Fields = {
  readonly __typename?: 'breakout_group_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Breakout_Group_Max_Fields>;
  readonly min?: Maybe<Breakout_Group_Min_Fields>;
};


/** aggregate fields of "breakout_group" */
export type Breakout_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "breakout_group" */
export type Breakout_Group_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Breakout_Group_Max_Order_By>;
  readonly min?: Maybe<Breakout_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "breakout_group" */
export type Breakout_Group_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Breakout_Group_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Breakout_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "breakout_group". All fields are combined with a logical 'AND'. */
export type Breakout_Group_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Breakout_Group_Bool_Exp>>;
  readonly _not?: Maybe<Breakout_Group_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Breakout_Group_Bool_Exp>>;
  readonly breakoutRoomId?: Maybe<Uuid_Comparison_Exp>;
  readonly breakout_group_participants_event_participants?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
  readonly breakout_room?: Maybe<Breakout_Room_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isActive?: Maybe<Boolean_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "breakout_group" */
export enum Breakout_Group_Constraint {
  /** unique or primary key constraint */
  Pk_6e613493adf751273683a65facb = 'PK_6e613493adf751273683a65facb'
}

/** input type for inserting data into table "breakout_group" */
export type Breakout_Group_Insert_Input = {
  readonly breakoutRoomId?: Maybe<Scalars['uuid']>;
  readonly breakout_group_participants_event_participants?: Maybe<Breakout_Group_Participants_Event_Participant_Arr_Rel_Insert_Input>;
  readonly breakout_room?: Maybe<Breakout_Room_Obj_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Breakout_Group_Max_Fields = {
  readonly __typename?: 'breakout_group_max_fields';
  readonly breakoutRoomId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "breakout_group" */
export type Breakout_Group_Max_Order_By = {
  readonly breakoutRoomId?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Breakout_Group_Min_Fields = {
  readonly __typename?: 'breakout_group_min_fields';
  readonly breakoutRoomId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "breakout_group" */
export type Breakout_Group_Min_Order_By = {
  readonly breakoutRoomId?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "breakout_group" */
export type Breakout_Group_Mutation_Response = {
  readonly __typename?: 'breakout_group_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Breakout_Group>;
};

/** input type for inserting object relation for remote table "breakout_group" */
export type Breakout_Group_Obj_Rel_Insert_Input = {
  readonly data: Breakout_Group_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Breakout_Group_On_Conflict>;
};

/** on conflict condition type for table "breakout_group" */
export type Breakout_Group_On_Conflict = {
  readonly constraint: Breakout_Group_Constraint;
  readonly update_columns?: ReadonlyArray<Breakout_Group_Update_Column>;
  readonly where?: Maybe<Breakout_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "breakout_group". */
export type Breakout_Group_Order_By = {
  readonly breakoutRoomId?: Maybe<Order_By>;
  readonly breakout_group_participants_event_participants_aggregate?: Maybe<Breakout_Group_Participants_Event_Participant_Aggregate_Order_By>;
  readonly breakout_room?: Maybe<Breakout_Room_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isActive?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** columns and relationships of "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant = {
  readonly __typename?: 'breakout_group_participants_event_participant';
  readonly breakoutGroupId: Scalars['uuid'];
  /** An object relationship */
  readonly breakout_group: Breakout_Group;
  readonly eventParticipantId: Scalars['uuid'];
  /** An object relationship */
  readonly event_participant: Event_Participant;
};

/** aggregated selection of "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Aggregate = {
  readonly __typename?: 'breakout_group_participants_event_participant_aggregate';
  readonly aggregate?: Maybe<Breakout_Group_Participants_Event_Participant_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
};

/** aggregate fields of "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Aggregate_Fields = {
  readonly __typename?: 'breakout_group_participants_event_participant_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Breakout_Group_Participants_Event_Participant_Max_Fields>;
  readonly min?: Maybe<Breakout_Group_Participants_Event_Participant_Min_Fields>;
};


/** aggregate fields of "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Breakout_Group_Participants_Event_Participant_Max_Order_By>;
  readonly min?: Maybe<Breakout_Group_Participants_Event_Participant_Min_Order_By>;
};

/** input type for inserting array relation for remote table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Breakout_Group_Participants_Event_Participant_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Breakout_Group_Participants_Event_Participant_On_Conflict>;
};

/** Boolean expression to filter rows from the table "breakout_group_participants_event_participant". All fields are combined with a logical 'AND'. */
export type Breakout_Group_Participants_Event_Participant_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Bool_Exp>>;
  readonly _not?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Bool_Exp>>;
  readonly breakoutGroupId?: Maybe<Uuid_Comparison_Exp>;
  readonly breakout_group?: Maybe<Breakout_Group_Bool_Exp>;
  readonly eventParticipantId?: Maybe<Uuid_Comparison_Exp>;
  readonly event_participant?: Maybe<Event_Participant_Bool_Exp>;
};

/** unique or primary key constraints on table "breakout_group_participants_event_participant" */
export enum Breakout_Group_Participants_Event_Participant_Constraint {
  /** unique or primary key constraint */
  Pk_917501ca9f91024d7621127338a = 'PK_917501ca9f91024d7621127338a'
}

/** input type for inserting data into table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Insert_Input = {
  readonly breakoutGroupId?: Maybe<Scalars['uuid']>;
  readonly breakout_group?: Maybe<Breakout_Group_Obj_Rel_Insert_Input>;
  readonly eventParticipantId?: Maybe<Scalars['uuid']>;
  readonly event_participant?: Maybe<Event_Participant_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Breakout_Group_Participants_Event_Participant_Max_Fields = {
  readonly __typename?: 'breakout_group_participants_event_participant_max_fields';
  readonly breakoutGroupId?: Maybe<Scalars['uuid']>;
  readonly eventParticipantId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Max_Order_By = {
  readonly breakoutGroupId?: Maybe<Order_By>;
  readonly eventParticipantId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Breakout_Group_Participants_Event_Participant_Min_Fields = {
  readonly __typename?: 'breakout_group_participants_event_participant_min_fields';
  readonly breakoutGroupId?: Maybe<Scalars['uuid']>;
  readonly eventParticipantId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Min_Order_By = {
  readonly breakoutGroupId?: Maybe<Order_By>;
  readonly eventParticipantId?: Maybe<Order_By>;
};

/** response of any mutation on the table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Mutation_Response = {
  readonly __typename?: 'breakout_group_participants_event_participant_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
};

/** on conflict condition type for table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_On_Conflict = {
  readonly constraint: Breakout_Group_Participants_Event_Participant_Constraint;
  readonly update_columns?: ReadonlyArray<Breakout_Group_Participants_Event_Participant_Update_Column>;
  readonly where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};

/** Ordering options when selecting data from "breakout_group_participants_event_participant". */
export type Breakout_Group_Participants_Event_Participant_Order_By = {
  readonly breakoutGroupId?: Maybe<Order_By>;
  readonly breakout_group?: Maybe<Breakout_Group_Order_By>;
  readonly eventParticipantId?: Maybe<Order_By>;
  readonly event_participant?: Maybe<Event_Participant_Order_By>;
};

/** primary key columns input for table: breakout_group_participants_event_participant */
export type Breakout_Group_Participants_Event_Participant_Pk_Columns_Input = {
  readonly breakoutGroupId: Scalars['uuid'];
  readonly eventParticipantId: Scalars['uuid'];
};

/** select columns of table "breakout_group_participants_event_participant" */
export enum Breakout_Group_Participants_Event_Participant_Select_Column {
  /** column name */
  BreakoutGroupId = 'breakoutGroupId',
  /** column name */
  EventParticipantId = 'eventParticipantId'
}

/** input type for updating data in table "breakout_group_participants_event_participant" */
export type Breakout_Group_Participants_Event_Participant_Set_Input = {
  readonly breakoutGroupId?: Maybe<Scalars['uuid']>;
  readonly eventParticipantId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "breakout_group_participants_event_participant" */
export enum Breakout_Group_Participants_Event_Participant_Update_Column {
  /** column name */
  BreakoutGroupId = 'breakoutGroupId',
  /** column name */
  EventParticipantId = 'eventParticipantId'
}

/** primary key columns input for table: breakout_group */
export type Breakout_Group_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "breakout_group" */
export enum Breakout_Group_Select_Column {
  /** column name */
  BreakoutRoomId = 'breakoutRoomId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "breakout_group" */
export type Breakout_Group_Set_Input = {
  readonly breakoutRoomId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "breakout_group" */
export enum Breakout_Group_Update_Column {
  /** column name */
  BreakoutRoomId = 'breakoutRoomId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "breakout_room" */
export type Breakout_Room = {
  readonly __typename?: 'breakout_room';
  readonly breakoutType: Scalars['String'];
  /** An array relationship */
  readonly breakout_groups: ReadonlyArray<Breakout_Group>;
  /** An aggregate relationship */
  readonly breakout_groups_aggregate: Breakout_Group_Aggregate;
  readonly createdAt: Scalars['timestamp'];
  readonly id: Scalars['uuid'];
  readonly isActive: Scalars['Boolean'];
  readonly startedAt?: Maybe<Scalars['timestamp']>;
  readonly stoppedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  readonly stream?: Maybe<Stream>;
  readonly streamId?: Maybe<Scalars['uuid']>;
  readonly timeLimit: Scalars['Int'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "breakout_room" */
export type Breakout_RoomBreakout_GroupsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};


/** columns and relationships of "breakout_room" */
export type Breakout_RoomBreakout_Groups_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};

/** aggregated selection of "breakout_room" */
export type Breakout_Room_Aggregate = {
  readonly __typename?: 'breakout_room_aggregate';
  readonly aggregate?: Maybe<Breakout_Room_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Breakout_Room>;
};

/** aggregate fields of "breakout_room" */
export type Breakout_Room_Aggregate_Fields = {
  readonly __typename?: 'breakout_room_aggregate_fields';
  readonly avg?: Maybe<Breakout_Room_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Breakout_Room_Max_Fields>;
  readonly min?: Maybe<Breakout_Room_Min_Fields>;
  readonly stddev?: Maybe<Breakout_Room_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Breakout_Room_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Breakout_Room_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Breakout_Room_Sum_Fields>;
  readonly var_pop?: Maybe<Breakout_Room_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Breakout_Room_Var_Samp_Fields>;
  readonly variance?: Maybe<Breakout_Room_Variance_Fields>;
};


/** aggregate fields of "breakout_room" */
export type Breakout_Room_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "breakout_room" */
export type Breakout_Room_Aggregate_Order_By = {
  readonly avg?: Maybe<Breakout_Room_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Breakout_Room_Max_Order_By>;
  readonly min?: Maybe<Breakout_Room_Min_Order_By>;
  readonly stddev?: Maybe<Breakout_Room_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Breakout_Room_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Breakout_Room_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Breakout_Room_Sum_Order_By>;
  readonly var_pop?: Maybe<Breakout_Room_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Breakout_Room_Var_Samp_Order_By>;
  readonly variance?: Maybe<Breakout_Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "breakout_room" */
export type Breakout_Room_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Breakout_Room_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Breakout_Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Breakout_Room_Avg_Fields = {
  readonly __typename?: 'breakout_room_avg_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "breakout_room" */
export type Breakout_Room_Avg_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "breakout_room". All fields are combined with a logical 'AND'. */
export type Breakout_Room_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Breakout_Room_Bool_Exp>>;
  readonly _not?: Maybe<Breakout_Room_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Breakout_Room_Bool_Exp>>;
  readonly breakoutType?: Maybe<String_Comparison_Exp>;
  readonly breakout_groups?: Maybe<Breakout_Group_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isActive?: Maybe<Boolean_Comparison_Exp>;
  readonly startedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly stoppedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly stream?: Maybe<Stream_Bool_Exp>;
  readonly streamId?: Maybe<Uuid_Comparison_Exp>;
  readonly timeLimit?: Maybe<Int_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "breakout_room" */
export enum Breakout_Room_Constraint {
  /** unique or primary key constraint */
  Pk_6e85e13dd6425e9cc0c0fe3d095 = 'PK_6e85e13dd6425e9cc0c0fe3d095'
}

/** input type for incrementing numeric columns in table "breakout_room" */
export type Breakout_Room_Inc_Input = {
  readonly timeLimit?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "breakout_room" */
export type Breakout_Room_Insert_Input = {
  readonly breakoutType?: Maybe<Scalars['String']>;
  readonly breakout_groups?: Maybe<Breakout_Group_Arr_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly startedAt?: Maybe<Scalars['timestamp']>;
  readonly stoppedAt?: Maybe<Scalars['timestamp']>;
  readonly stream?: Maybe<Stream_Obj_Rel_Insert_Input>;
  readonly streamId?: Maybe<Scalars['uuid']>;
  readonly timeLimit?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Breakout_Room_Max_Fields = {
  readonly __typename?: 'breakout_room_max_fields';
  readonly breakoutType?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly startedAt?: Maybe<Scalars['timestamp']>;
  readonly stoppedAt?: Maybe<Scalars['timestamp']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
  readonly timeLimit?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "breakout_room" */
export type Breakout_Room_Max_Order_By = {
  readonly breakoutType?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly startedAt?: Maybe<Order_By>;
  readonly stoppedAt?: Maybe<Order_By>;
  readonly streamId?: Maybe<Order_By>;
  readonly timeLimit?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Breakout_Room_Min_Fields = {
  readonly __typename?: 'breakout_room_min_fields';
  readonly breakoutType?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly startedAt?: Maybe<Scalars['timestamp']>;
  readonly stoppedAt?: Maybe<Scalars['timestamp']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
  readonly timeLimit?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "breakout_room" */
export type Breakout_Room_Min_Order_By = {
  readonly breakoutType?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly startedAt?: Maybe<Order_By>;
  readonly stoppedAt?: Maybe<Order_By>;
  readonly streamId?: Maybe<Order_By>;
  readonly timeLimit?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "breakout_room" */
export type Breakout_Room_Mutation_Response = {
  readonly __typename?: 'breakout_room_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Breakout_Room>;
};

/** input type for inserting object relation for remote table "breakout_room" */
export type Breakout_Room_Obj_Rel_Insert_Input = {
  readonly data: Breakout_Room_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Breakout_Room_On_Conflict>;
};

/** on conflict condition type for table "breakout_room" */
export type Breakout_Room_On_Conflict = {
  readonly constraint: Breakout_Room_Constraint;
  readonly update_columns?: ReadonlyArray<Breakout_Room_Update_Column>;
  readonly where?: Maybe<Breakout_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "breakout_room". */
export type Breakout_Room_Order_By = {
  readonly breakoutType?: Maybe<Order_By>;
  readonly breakout_groups_aggregate?: Maybe<Breakout_Group_Aggregate_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isActive?: Maybe<Order_By>;
  readonly startedAt?: Maybe<Order_By>;
  readonly stoppedAt?: Maybe<Order_By>;
  readonly stream?: Maybe<Stream_Order_By>;
  readonly streamId?: Maybe<Order_By>;
  readonly timeLimit?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: breakout_room */
export type Breakout_Room_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "breakout_room" */
export enum Breakout_Room_Select_Column {
  /** column name */
  BreakoutType = 'breakoutType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  StreamId = 'streamId',
  /** column name */
  TimeLimit = 'timeLimit',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "breakout_room" */
export type Breakout_Room_Set_Input = {
  readonly breakoutType?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly startedAt?: Maybe<Scalars['timestamp']>;
  readonly stoppedAt?: Maybe<Scalars['timestamp']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
  readonly timeLimit?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Breakout_Room_Stddev_Fields = {
  readonly __typename?: 'breakout_room_stddev_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "breakout_room" */
export type Breakout_Room_Stddev_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Breakout_Room_Stddev_Pop_Fields = {
  readonly __typename?: 'breakout_room_stddev_pop_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "breakout_room" */
export type Breakout_Room_Stddev_Pop_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Breakout_Room_Stddev_Samp_Fields = {
  readonly __typename?: 'breakout_room_stddev_samp_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "breakout_room" */
export type Breakout_Room_Stddev_Samp_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Breakout_Room_Sum_Fields = {
  readonly __typename?: 'breakout_room_sum_fields';
  readonly timeLimit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "breakout_room" */
export type Breakout_Room_Sum_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** update columns of table "breakout_room" */
export enum Breakout_Room_Update_Column {
  /** column name */
  BreakoutType = 'breakoutType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  StreamId = 'streamId',
  /** column name */
  TimeLimit = 'timeLimit',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Breakout_Room_Var_Pop_Fields = {
  readonly __typename?: 'breakout_room_var_pop_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "breakout_room" */
export type Breakout_Room_Var_Pop_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Breakout_Room_Var_Samp_Fields = {
  readonly __typename?: 'breakout_room_var_samp_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "breakout_room" */
export type Breakout_Room_Var_Samp_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Breakout_Room_Variance_Fields = {
  readonly __typename?: 'breakout_room_variance_fields';
  readonly timeLimit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "breakout_room" */
export type Breakout_Room_Variance_Order_By = {
  readonly timeLimit?: Maybe<Order_By>;
};

/** columns and relationships of "bunny_upload" */
export type Bunny_Upload = {
  readonly __typename?: 'bunny_upload';
  readonly bunny_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly bunny_upload_state: Bunny_Upload_States;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly course_videos_status?: Maybe<Course_Videos_Statuses>;
  readonly id: Scalars['uuid'];
  readonly is_bunny_available: Scalars['Boolean'];
  readonly location?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly state: Bunny_Upload_States_Enum;
  readonly status?: Maybe<Course_Videos_Statuses_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bunny_upload" */
export type Bunny_Upload_Aggregate = {
  readonly __typename?: 'bunny_upload_aggregate';
  readonly aggregate?: Maybe<Bunny_Upload_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Bunny_Upload>;
};

/** aggregate fields of "bunny_upload" */
export type Bunny_Upload_Aggregate_Fields = {
  readonly __typename?: 'bunny_upload_aggregate_fields';
  readonly avg?: Maybe<Bunny_Upload_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Bunny_Upload_Max_Fields>;
  readonly min?: Maybe<Bunny_Upload_Min_Fields>;
  readonly stddev?: Maybe<Bunny_Upload_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Bunny_Upload_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Bunny_Upload_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Bunny_Upload_Sum_Fields>;
  readonly var_pop?: Maybe<Bunny_Upload_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Bunny_Upload_Var_Samp_Fields>;
  readonly variance?: Maybe<Bunny_Upload_Variance_Fields>;
};


/** aggregate fields of "bunny_upload" */
export type Bunny_Upload_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bunny_upload" */
export type Bunny_Upload_Aggregate_Order_By = {
  readonly avg?: Maybe<Bunny_Upload_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Bunny_Upload_Max_Order_By>;
  readonly min?: Maybe<Bunny_Upload_Min_Order_By>;
  readonly stddev?: Maybe<Bunny_Upload_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Bunny_Upload_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Bunny_Upload_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Bunny_Upload_Sum_Order_By>;
  readonly var_pop?: Maybe<Bunny_Upload_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Bunny_Upload_Var_Samp_Order_By>;
  readonly variance?: Maybe<Bunny_Upload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bunny_upload" */
export type Bunny_Upload_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Bunny_Upload_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Bunny_Upload_On_Conflict>;
};

/** aggregate avg on columns */
export type Bunny_Upload_Avg_Fields = {
  readonly __typename?: 'bunny_upload_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bunny_upload" */
export type Bunny_Upload_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bunny_upload". All fields are combined with a logical 'AND'. */
export type Bunny_Upload_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Bunny_Upload_Bool_Exp>>;
  readonly _not?: Maybe<Bunny_Upload_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Bunny_Upload_Bool_Exp>>;
  readonly bunny_id?: Maybe<String_Comparison_Exp>;
  readonly bunny_upload_state?: Maybe<Bunny_Upload_States_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_bunny_available?: Maybe<Boolean_Comparison_Exp>;
  readonly location?: Maybe<String_Comparison_Exp>;
  readonly provider_id?: Maybe<String_Comparison_Exp>;
  readonly state?: Maybe<Bunny_Upload_States_Enum_Comparison_Exp>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bunny_upload" */
export enum Bunny_Upload_Constraint {
  /** unique or primary key constraint */
  BunnyUploadBunnyIdKey = 'bunny_upload_bunny_id_key',
  /** unique or primary key constraint */
  BunnyUploadPkey = 'bunny_upload_pkey'
}

/** input type for incrementing numeric columns in table "bunny_upload" */
export type Bunny_Upload_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "bunny_upload" */
export type Bunny_Upload_Insert_Input = {
  readonly bunny_id?: Maybe<Scalars['String']>;
  readonly bunny_upload_state?: Maybe<Bunny_Upload_States_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_bunny_available?: Maybe<Scalars['Boolean']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Bunny_Upload_States_Enum>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bunny_Upload_Max_Fields = {
  readonly __typename?: 'bunny_upload_max_fields';
  readonly bunny_id?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bunny_upload" */
export type Bunny_Upload_Max_Order_By = {
  readonly bunny_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly location?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bunny_Upload_Min_Fields = {
  readonly __typename?: 'bunny_upload_min_fields';
  readonly bunny_id?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bunny_upload" */
export type Bunny_Upload_Min_Order_By = {
  readonly bunny_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly location?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "bunny_upload" */
export type Bunny_Upload_Mutation_Response = {
  readonly __typename?: 'bunny_upload_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Bunny_Upload>;
};

/** on conflict condition type for table "bunny_upload" */
export type Bunny_Upload_On_Conflict = {
  readonly constraint: Bunny_Upload_Constraint;
  readonly update_columns?: ReadonlyArray<Bunny_Upload_Update_Column>;
  readonly where?: Maybe<Bunny_Upload_Bool_Exp>;
};

/** Ordering options when selecting data from "bunny_upload". */
export type Bunny_Upload_Order_By = {
  readonly bunny_id?: Maybe<Order_By>;
  readonly bunny_upload_state?: Maybe<Bunny_Upload_States_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_bunny_available?: Maybe<Order_By>;
  readonly location?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly state?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: bunny_upload */
export type Bunny_Upload_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "bunny_upload" */
export enum Bunny_Upload_Select_Column {
  /** column name */
  BunnyId = 'bunny_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsBunnyAvailable = 'is_bunny_available',
  /** column name */
  Location = 'location',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bunny_upload" */
export type Bunny_Upload_Set_Input = {
  readonly bunny_id?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_bunny_available?: Maybe<Scalars['Boolean']>;
  readonly location?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Bunny_Upload_States_Enum>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "bunny_upload_states" */
export type Bunny_Upload_States = {
  readonly __typename?: 'bunny_upload_states';
  /** An array relationship */
  readonly bunny_uploads: ReadonlyArray<Bunny_Upload>;
  /** An aggregate relationship */
  readonly bunny_uploads_aggregate: Bunny_Upload_Aggregate;
  readonly comment: Scalars['String'];
  readonly value: Scalars['String'];
};


/** columns and relationships of "bunny_upload_states" */
export type Bunny_Upload_StatesBunny_UploadsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


/** columns and relationships of "bunny_upload_states" */
export type Bunny_Upload_StatesBunny_Uploads_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};

/** aggregated selection of "bunny_upload_states" */
export type Bunny_Upload_States_Aggregate = {
  readonly __typename?: 'bunny_upload_states_aggregate';
  readonly aggregate?: Maybe<Bunny_Upload_States_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Bunny_Upload_States>;
};

/** aggregate fields of "bunny_upload_states" */
export type Bunny_Upload_States_Aggregate_Fields = {
  readonly __typename?: 'bunny_upload_states_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Bunny_Upload_States_Max_Fields>;
  readonly min?: Maybe<Bunny_Upload_States_Min_Fields>;
};


/** aggregate fields of "bunny_upload_states" */
export type Bunny_Upload_States_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Bunny_Upload_States_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bunny_upload_states". All fields are combined with a logical 'AND'. */
export type Bunny_Upload_States_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Bunny_Upload_States_Bool_Exp>>;
  readonly _not?: Maybe<Bunny_Upload_States_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Bunny_Upload_States_Bool_Exp>>;
  readonly bunny_uploads?: Maybe<Bunny_Upload_Bool_Exp>;
  readonly comment?: Maybe<String_Comparison_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bunny_upload_states" */
export enum Bunny_Upload_States_Constraint {
  /** unique or primary key constraint */
  BunnyUploadStatesPkey = 'bunny_upload_states_pkey'
}

export enum Bunny_Upload_States_Enum {
  /** The stored video is now encoded by Bunny */
  Encoded = 'encoded',
  /** The video was not successfully stored in B2 Backblaze */
  Failed = 'failed',
  /** The user has clicked the upload button */
  Queued = 'queued',
  /** The encoded video is now transcoded by CF downloader */
  Transcoded = 'transcoded',
  /** The video is now stored in B2 Backblaze */
  Uploaded = 'uploaded'
}

/** Boolean expression to compare columns of type "bunny_upload_states_enum". All fields are combined with logical 'AND'. */
export type Bunny_Upload_States_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Bunny_Upload_States_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Bunny_Upload_States_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Bunny_Upload_States_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Bunny_Upload_States_Enum>>;
};

/** input type for inserting data into table "bunny_upload_states" */
export type Bunny_Upload_States_Insert_Input = {
  readonly bunny_uploads?: Maybe<Bunny_Upload_Arr_Rel_Insert_Input>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bunny_Upload_States_Max_Fields = {
  readonly __typename?: 'bunny_upload_states_max_fields';
  readonly comment?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bunny_Upload_States_Min_Fields = {
  readonly __typename?: 'bunny_upload_states_min_fields';
  readonly comment?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bunny_upload_states" */
export type Bunny_Upload_States_Mutation_Response = {
  readonly __typename?: 'bunny_upload_states_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Bunny_Upload_States>;
};

/** input type for inserting object relation for remote table "bunny_upload_states" */
export type Bunny_Upload_States_Obj_Rel_Insert_Input = {
  readonly data: Bunny_Upload_States_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Bunny_Upload_States_On_Conflict>;
};

/** on conflict condition type for table "bunny_upload_states" */
export type Bunny_Upload_States_On_Conflict = {
  readonly constraint: Bunny_Upload_States_Constraint;
  readonly update_columns?: ReadonlyArray<Bunny_Upload_States_Update_Column>;
  readonly where?: Maybe<Bunny_Upload_States_Bool_Exp>;
};

/** Ordering options when selecting data from "bunny_upload_states". */
export type Bunny_Upload_States_Order_By = {
  readonly bunny_uploads_aggregate?: Maybe<Bunny_Upload_Aggregate_Order_By>;
  readonly comment?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: bunny_upload_states */
export type Bunny_Upload_States_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "bunny_upload_states" */
export enum Bunny_Upload_States_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "bunny_upload_states" */
export type Bunny_Upload_States_Set_Input = {
  readonly comment?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "bunny_upload_states" */
export enum Bunny_Upload_States_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Bunny_Upload_Stddev_Fields = {
  readonly __typename?: 'bunny_upload_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bunny_upload" */
export type Bunny_Upload_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bunny_Upload_Stddev_Pop_Fields = {
  readonly __typename?: 'bunny_upload_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bunny_upload" */
export type Bunny_Upload_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bunny_Upload_Stddev_Samp_Fields = {
  readonly __typename?: 'bunny_upload_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bunny_upload" */
export type Bunny_Upload_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Bunny_Upload_Sum_Fields = {
  readonly __typename?: 'bunny_upload_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "bunny_upload" */
export type Bunny_Upload_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "bunny_upload" */
export enum Bunny_Upload_Update_Column {
  /** column name */
  BunnyId = 'bunny_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsBunnyAvailable = 'is_bunny_available',
  /** column name */
  Location = 'location',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Bunny_Upload_Var_Pop_Fields = {
  readonly __typename?: 'bunny_upload_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bunny_upload" */
export type Bunny_Upload_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bunny_Upload_Var_Samp_Fields = {
  readonly __typename?: 'bunny_upload_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bunny_upload" */
export type Bunny_Upload_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Bunny_Upload_Variance_Fields = {
  readonly __typename?: 'bunny_upload_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bunny_upload" */
export type Bunny_Upload_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** columns and relationships of "carousel_item" */
export type Carousel_Item = {
  readonly __typename?: 'carousel_item';
  readonly createdAt: Scalars['timestamp'];
  readonly deactivationDate?: Maybe<Scalars['timestamp']>;
  readonly displayPhotoUuid: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "carousel_item" */
export type Carousel_Item_Aggregate = {
  readonly __typename?: 'carousel_item_aggregate';
  readonly aggregate?: Maybe<Carousel_Item_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Carousel_Item>;
};

/** aggregate fields of "carousel_item" */
export type Carousel_Item_Aggregate_Fields = {
  readonly __typename?: 'carousel_item_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Carousel_Item_Max_Fields>;
  readonly min?: Maybe<Carousel_Item_Min_Fields>;
};


/** aggregate fields of "carousel_item" */
export type Carousel_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Carousel_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "carousel_item". All fields are combined with a logical 'AND'. */
export type Carousel_Item_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Carousel_Item_Bool_Exp>>;
  readonly _not?: Maybe<Carousel_Item_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Carousel_Item_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly deactivationDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly displayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly linkUrl?: Maybe<String_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "carousel_item" */
export enum Carousel_Item_Constraint {
  /** unique or primary key constraint */
  Pk_65f16fe43ab5fd9d477e88a02e1 = 'PK_65f16fe43ab5fd9d477e88a02e1'
}

/** input type for inserting data into table "carousel_item" */
export type Carousel_Item_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly deactivationDate?: Maybe<Scalars['timestamp']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Carousel_Item_Max_Fields = {
  readonly __typename?: 'carousel_item_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly deactivationDate?: Maybe<Scalars['timestamp']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Carousel_Item_Min_Fields = {
  readonly __typename?: 'carousel_item_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly deactivationDate?: Maybe<Scalars['timestamp']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "carousel_item" */
export type Carousel_Item_Mutation_Response = {
  readonly __typename?: 'carousel_item_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Carousel_Item>;
};

/** on conflict condition type for table "carousel_item" */
export type Carousel_Item_On_Conflict = {
  readonly constraint: Carousel_Item_Constraint;
  readonly update_columns?: ReadonlyArray<Carousel_Item_Update_Column>;
  readonly where?: Maybe<Carousel_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "carousel_item". */
export type Carousel_Item_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly deactivationDate?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly linkUrl?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: carousel_item */
export type Carousel_Item_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "carousel_item" */
export enum Carousel_Item_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeactivationDate = 'deactivationDate',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'linkUrl',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "carousel_item" */
export type Carousel_Item_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly deactivationDate?: Maybe<Scalars['timestamp']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly linkUrl?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "carousel_item" */
export enum Carousel_Item_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeactivationDate = 'deactivationDate',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'linkUrl',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "certificate_signatory" */
export type Certificate_Signatory = {
  readonly __typename?: 'certificate_signatory';
  /** An array relationship */
  readonly certificate_template_signatories: ReadonlyArray<Certificate_Template_Signatories>;
  /** An aggregate relationship */
  readonly certificate_template_signatories_aggregate: Certificate_Template_Signatories_Aggregate;
  readonly companyName: Scalars['String'];
  readonly honorific?: Maybe<Scalars['String']>;
  readonly id: Scalars['uuid'];
  readonly isActive: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly position: Scalars['String'];
  /** An object relationship */
  readonly provider: Provider;
  readonly providerId: Scalars['uuid'];
  /** An array relationship */
  readonly reward_certificate_signatories_certificate_signatories: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** An aggregate relationship */
  readonly reward_certificate_signatories_certificate_signatories_aggregate: Reward_Certificate_Signatories_Certificate_Signatory_Aggregate;
  readonly signatureImageUuid: Scalars['String'];
  readonly uploaderId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "certificate_signatory" */
export type Certificate_SignatoryCertificate_Template_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


/** columns and relationships of "certificate_signatory" */
export type Certificate_SignatoryCertificate_Template_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


/** columns and relationships of "certificate_signatory" */
export type Certificate_SignatoryReward_Certificate_Signatories_Certificate_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


/** columns and relationships of "certificate_signatory" */
export type Certificate_SignatoryReward_Certificate_Signatories_Certificate_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};

/** aggregated selection of "certificate_signatory" */
export type Certificate_Signatory_Aggregate = {
  readonly __typename?: 'certificate_signatory_aggregate';
  readonly aggregate?: Maybe<Certificate_Signatory_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Certificate_Signatory>;
};

/** aggregate fields of "certificate_signatory" */
export type Certificate_Signatory_Aggregate_Fields = {
  readonly __typename?: 'certificate_signatory_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Certificate_Signatory_Max_Fields>;
  readonly min?: Maybe<Certificate_Signatory_Min_Fields>;
};


/** aggregate fields of "certificate_signatory" */
export type Certificate_Signatory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "certificate_signatory" */
export type Certificate_Signatory_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Certificate_Signatory_Max_Order_By>;
  readonly min?: Maybe<Certificate_Signatory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "certificate_signatory" */
export type Certificate_Signatory_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Certificate_Signatory_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Certificate_Signatory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "certificate_signatory". All fields are combined with a logical 'AND'. */
export type Certificate_Signatory_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Certificate_Signatory_Bool_Exp>>;
  readonly _not?: Maybe<Certificate_Signatory_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Certificate_Signatory_Bool_Exp>>;
  readonly certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
  readonly companyName?: Maybe<String_Comparison_Exp>;
  readonly honorific?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isActive?: Maybe<Boolean_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly position?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly reward_certificate_signatories_certificate_signatories?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
  readonly signatureImageUuid?: Maybe<String_Comparison_Exp>;
  readonly uploaderId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "certificate_signatory" */
export enum Certificate_Signatory_Constraint {
  /** unique or primary key constraint */
  Pk_9435a6a874a6135c50775e6740d = 'PK_9435a6a874a6135c50775e6740d'
}

/** input type for inserting data into table "certificate_signatory" */
export type Certificate_Signatory_Insert_Input = {
  readonly certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Arr_Rel_Insert_Input>;
  readonly companyName?: Maybe<Scalars['String']>;
  readonly honorific?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly reward_certificate_signatories_certificate_signatories?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Arr_Rel_Insert_Input>;
  readonly signatureImageUuid?: Maybe<Scalars['String']>;
  readonly uploaderId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Certificate_Signatory_Max_Fields = {
  readonly __typename?: 'certificate_signatory_max_fields';
  readonly companyName?: Maybe<Scalars['String']>;
  readonly honorific?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly signatureImageUuid?: Maybe<Scalars['String']>;
  readonly uploaderId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "certificate_signatory" */
export type Certificate_Signatory_Max_Order_By = {
  readonly companyName?: Maybe<Order_By>;
  readonly honorific?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly signatureImageUuid?: Maybe<Order_By>;
  readonly uploaderId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Certificate_Signatory_Min_Fields = {
  readonly __typename?: 'certificate_signatory_min_fields';
  readonly companyName?: Maybe<Scalars['String']>;
  readonly honorific?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly signatureImageUuid?: Maybe<Scalars['String']>;
  readonly uploaderId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "certificate_signatory" */
export type Certificate_Signatory_Min_Order_By = {
  readonly companyName?: Maybe<Order_By>;
  readonly honorific?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly signatureImageUuid?: Maybe<Order_By>;
  readonly uploaderId?: Maybe<Order_By>;
};

/** response of any mutation on the table "certificate_signatory" */
export type Certificate_Signatory_Mutation_Response = {
  readonly __typename?: 'certificate_signatory_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Certificate_Signatory>;
};

/** input type for inserting object relation for remote table "certificate_signatory" */
export type Certificate_Signatory_Obj_Rel_Insert_Input = {
  readonly data: Certificate_Signatory_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Certificate_Signatory_On_Conflict>;
};

/** on conflict condition type for table "certificate_signatory" */
export type Certificate_Signatory_On_Conflict = {
  readonly constraint: Certificate_Signatory_Constraint;
  readonly update_columns?: ReadonlyArray<Certificate_Signatory_Update_Column>;
  readonly where?: Maybe<Certificate_Signatory_Bool_Exp>;
};

/** Ordering options when selecting data from "certificate_signatory". */
export type Certificate_Signatory_Order_By = {
  readonly certificate_template_signatories_aggregate?: Maybe<Certificate_Template_Signatories_Aggregate_Order_By>;
  readonly companyName?: Maybe<Order_By>;
  readonly honorific?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isActive?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly reward_certificate_signatories_certificate_signatories_aggregate?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_Order_By>;
  readonly signatureImageUuid?: Maybe<Order_By>;
  readonly uploaderId?: Maybe<Order_By>;
};

/** primary key columns input for table: certificate_signatory */
export type Certificate_Signatory_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "certificate_signatory" */
export enum Certificate_Signatory_Select_Column {
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Honorific = 'honorific',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  SignatureImageUuid = 'signatureImageUuid',
  /** column name */
  UploaderId = 'uploaderId'
}

/** input type for updating data in table "certificate_signatory" */
export type Certificate_Signatory_Set_Input = {
  readonly companyName?: Maybe<Scalars['String']>;
  readonly honorific?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isActive?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly signatureImageUuid?: Maybe<Scalars['String']>;
  readonly uploaderId?: Maybe<Scalars['String']>;
};

/** update columns of table "certificate_signatory" */
export enum Certificate_Signatory_Update_Column {
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Honorific = 'honorific',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  SignatureImageUuid = 'signatureImageUuid',
  /** column name */
  UploaderId = 'uploaderId'
}

/** columns and relationships of "certificate_template_signatories" */
export type Certificate_Template_Signatories = {
  readonly __typename?: 'certificate_template_signatories';
  /** An object relationship */
  readonly certificate_signatory: Certificate_Signatory;
  /** An object relationship */
  readonly certificate_template: Certificate_Templates;
  readonly certificate_template_id: Scalars['uuid'];
  readonly id: Scalars['uuid'];
  readonly is_superimposed: Scalars['Boolean'];
  readonly signatory_id: Scalars['uuid'];
};

/** aggregated selection of "certificate_template_signatories" */
export type Certificate_Template_Signatories_Aggregate = {
  readonly __typename?: 'certificate_template_signatories_aggregate';
  readonly aggregate?: Maybe<Certificate_Template_Signatories_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Certificate_Template_Signatories>;
};

/** aggregate fields of "certificate_template_signatories" */
export type Certificate_Template_Signatories_Aggregate_Fields = {
  readonly __typename?: 'certificate_template_signatories_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Certificate_Template_Signatories_Max_Fields>;
  readonly min?: Maybe<Certificate_Template_Signatories_Min_Fields>;
};


/** aggregate fields of "certificate_template_signatories" */
export type Certificate_Template_Signatories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Certificate_Template_Signatories_Max_Order_By>;
  readonly min?: Maybe<Certificate_Template_Signatories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Certificate_Template_Signatories_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Certificate_Template_Signatories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "certificate_template_signatories". All fields are combined with a logical 'AND'. */
export type Certificate_Template_Signatories_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Bool_Exp>>;
  readonly _not?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Bool_Exp>>;
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Bool_Exp>;
  readonly certificate_template?: Maybe<Certificate_Templates_Bool_Exp>;
  readonly certificate_template_id?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_superimposed?: Maybe<Boolean_Comparison_Exp>;
  readonly signatory_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "certificate_template_signatories" */
export enum Certificate_Template_Signatories_Constraint {
  /** unique or primary key constraint */
  CertificateTemplateSignatoriesPkey = 'certificate_template_signatories_pkey'
}

/** input type for inserting data into table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Insert_Input = {
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Obj_Rel_Insert_Input>;
  readonly certificate_template?: Maybe<Certificate_Templates_Obj_Rel_Insert_Input>;
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_superimposed?: Maybe<Scalars['Boolean']>;
  readonly signatory_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Certificate_Template_Signatories_Max_Fields = {
  readonly __typename?: 'certificate_template_signatories_max_fields';
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly signatory_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Max_Order_By = {
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly signatory_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Certificate_Template_Signatories_Min_Fields = {
  readonly __typename?: 'certificate_template_signatories_min_fields';
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly signatory_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Min_Order_By = {
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly signatory_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Mutation_Response = {
  readonly __typename?: 'certificate_template_signatories_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Certificate_Template_Signatories>;
};

/** on conflict condition type for table "certificate_template_signatories" */
export type Certificate_Template_Signatories_On_Conflict = {
  readonly constraint: Certificate_Template_Signatories_Constraint;
  readonly update_columns?: ReadonlyArray<Certificate_Template_Signatories_Update_Column>;
  readonly where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};

/** Ordering options when selecting data from "certificate_template_signatories". */
export type Certificate_Template_Signatories_Order_By = {
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Order_By>;
  readonly certificate_template?: Maybe<Certificate_Templates_Order_By>;
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_superimposed?: Maybe<Order_By>;
  readonly signatory_id?: Maybe<Order_By>;
};

/** primary key columns input for table: certificate_template_signatories */
export type Certificate_Template_Signatories_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "certificate_template_signatories" */
export enum Certificate_Template_Signatories_Select_Column {
  /** column name */
  CertificateTemplateId = 'certificate_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSuperimposed = 'is_superimposed',
  /** column name */
  SignatoryId = 'signatory_id'
}

/** input type for updating data in table "certificate_template_signatories" */
export type Certificate_Template_Signatories_Set_Input = {
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_superimposed?: Maybe<Scalars['Boolean']>;
  readonly signatory_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "certificate_template_signatories" */
export enum Certificate_Template_Signatories_Update_Column {
  /** column name */
  CertificateTemplateId = 'certificate_template_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSuperimposed = 'is_superimposed',
  /** column name */
  SignatoryId = 'signatory_id'
}

/** columns and relationships of "certificate_templates" */
export type Certificate_Templates = {
  readonly __typename?: 'certificate_templates';
  readonly accreditation_number?: Maybe<Scalars['String']>;
  readonly background_photo_uuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly certificate_template_signatories: ReadonlyArray<Certificate_Template_Signatories>;
  /** An aggregate relationship */
  readonly certificate_template_signatories_aggregate: Certificate_Template_Signatories_Aggregate;
  readonly color_theme?: Maybe<Scalars['String']>;
  readonly course_id: Scalars['bigint'];
  readonly created_at: Scalars['timestamp'];
  readonly date_held?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly has_awards_this?: Maybe<Scalars['Boolean']>;
  readonly has_logo?: Maybe<Scalars['Boolean']>;
  readonly has_provider_name?: Maybe<Scalars['Boolean']>;
  readonly has_to_divider?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['uuid'];
  readonly label?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly provider: Provider;
  readonly provider_id: Scalars['uuid'];
  readonly reward?: Maybe<Scalars['String']>;
  readonly type: Scalars['String'];
  /** An array relationship */
  readonly user_certificates: ReadonlyArray<User_Certificates>;
  /** An aggregate relationship */
  readonly user_certificates_aggregate: User_Certificates_Aggregate;
};


/** columns and relationships of "certificate_templates" */
export type Certificate_TemplatesCertificate_Template_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


/** columns and relationships of "certificate_templates" */
export type Certificate_TemplatesCertificate_Template_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


/** columns and relationships of "certificate_templates" */
export type Certificate_TemplatesUser_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "certificate_templates" */
export type Certificate_TemplatesUser_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};

/** aggregated selection of "certificate_templates" */
export type Certificate_Templates_Aggregate = {
  readonly __typename?: 'certificate_templates_aggregate';
  readonly aggregate?: Maybe<Certificate_Templates_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Certificate_Templates>;
};

/** aggregate fields of "certificate_templates" */
export type Certificate_Templates_Aggregate_Fields = {
  readonly __typename?: 'certificate_templates_aggregate_fields';
  readonly avg?: Maybe<Certificate_Templates_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Certificate_Templates_Max_Fields>;
  readonly min?: Maybe<Certificate_Templates_Min_Fields>;
  readonly stddev?: Maybe<Certificate_Templates_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Certificate_Templates_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Certificate_Templates_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Certificate_Templates_Sum_Fields>;
  readonly var_pop?: Maybe<Certificate_Templates_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Certificate_Templates_Var_Samp_Fields>;
  readonly variance?: Maybe<Certificate_Templates_Variance_Fields>;
};


/** aggregate fields of "certificate_templates" */
export type Certificate_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "certificate_templates" */
export type Certificate_Templates_Aggregate_Order_By = {
  readonly avg?: Maybe<Certificate_Templates_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Certificate_Templates_Max_Order_By>;
  readonly min?: Maybe<Certificate_Templates_Min_Order_By>;
  readonly stddev?: Maybe<Certificate_Templates_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Certificate_Templates_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Certificate_Templates_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Certificate_Templates_Sum_Order_By>;
  readonly var_pop?: Maybe<Certificate_Templates_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Certificate_Templates_Var_Samp_Order_By>;
  readonly variance?: Maybe<Certificate_Templates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "certificate_templates" */
export type Certificate_Templates_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Certificate_Templates_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Certificate_Templates_On_Conflict>;
};

/** aggregate avg on columns */
export type Certificate_Templates_Avg_Fields = {
  readonly __typename?: 'certificate_templates_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "certificate_templates" */
export type Certificate_Templates_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "certificate_templates". All fields are combined with a logical 'AND'. */
export type Certificate_Templates_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Certificate_Templates_Bool_Exp>>;
  readonly _not?: Maybe<Certificate_Templates_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Certificate_Templates_Bool_Exp>>;
  readonly accreditation_number?: Maybe<String_Comparison_Exp>;
  readonly background_photo_uuid?: Maybe<String_Comparison_Exp>;
  readonly certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
  readonly color_theme?: Maybe<String_Comparison_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly date_held?: Maybe<String_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly has_awards_this?: Maybe<Boolean_Comparison_Exp>;
  readonly has_logo?: Maybe<Boolean_Comparison_Exp>;
  readonly has_provider_name?: Maybe<Boolean_Comparison_Exp>;
  readonly has_to_divider?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly label?: Maybe<String_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly reward?: Maybe<String_Comparison_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
  readonly user_certificates?: Maybe<User_Certificates_Bool_Exp>;
};

/** unique or primary key constraints on table "certificate_templates" */
export enum Certificate_Templates_Constraint {
  /** unique or primary key constraint */
  CertificateTemplatesPkey = 'certificate_templates_pkey'
}

/** input type for incrementing numeric columns in table "certificate_templates" */
export type Certificate_Templates_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "certificate_templates" */
export type Certificate_Templates_Insert_Input = {
  readonly accreditation_number?: Maybe<Scalars['String']>;
  readonly background_photo_uuid?: Maybe<Scalars['String']>;
  readonly certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Arr_Rel_Insert_Input>;
  readonly color_theme?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly date_held?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly has_awards_this?: Maybe<Scalars['Boolean']>;
  readonly has_logo?: Maybe<Scalars['Boolean']>;
  readonly has_provider_name?: Maybe<Scalars['Boolean']>;
  readonly has_to_divider?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly reward?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly user_certificates?: Maybe<User_Certificates_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Certificate_Templates_Max_Fields = {
  readonly __typename?: 'certificate_templates_max_fields';
  readonly accreditation_number?: Maybe<Scalars['String']>;
  readonly background_photo_uuid?: Maybe<Scalars['String']>;
  readonly color_theme?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly date_held?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly reward?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "certificate_templates" */
export type Certificate_Templates_Max_Order_By = {
  readonly accreditation_number?: Maybe<Order_By>;
  readonly background_photo_uuid?: Maybe<Order_By>;
  readonly color_theme?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly date_held?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly label?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly reward?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Certificate_Templates_Min_Fields = {
  readonly __typename?: 'certificate_templates_min_fields';
  readonly accreditation_number?: Maybe<Scalars['String']>;
  readonly background_photo_uuid?: Maybe<Scalars['String']>;
  readonly color_theme?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly date_held?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly reward?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "certificate_templates" */
export type Certificate_Templates_Min_Order_By = {
  readonly accreditation_number?: Maybe<Order_By>;
  readonly background_photo_uuid?: Maybe<Order_By>;
  readonly color_theme?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly date_held?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly label?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly reward?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** response of any mutation on the table "certificate_templates" */
export type Certificate_Templates_Mutation_Response = {
  readonly __typename?: 'certificate_templates_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Certificate_Templates>;
};

/** input type for inserting object relation for remote table "certificate_templates" */
export type Certificate_Templates_Obj_Rel_Insert_Input = {
  readonly data: Certificate_Templates_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Certificate_Templates_On_Conflict>;
};

/** on conflict condition type for table "certificate_templates" */
export type Certificate_Templates_On_Conflict = {
  readonly constraint: Certificate_Templates_Constraint;
  readonly update_columns?: ReadonlyArray<Certificate_Templates_Update_Column>;
  readonly where?: Maybe<Certificate_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "certificate_templates". */
export type Certificate_Templates_Order_By = {
  readonly accreditation_number?: Maybe<Order_By>;
  readonly background_photo_uuid?: Maybe<Order_By>;
  readonly certificate_template_signatories_aggregate?: Maybe<Certificate_Template_Signatories_Aggregate_Order_By>;
  readonly color_theme?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly date_held?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly has_awards_this?: Maybe<Order_By>;
  readonly has_logo?: Maybe<Order_By>;
  readonly has_provider_name?: Maybe<Order_By>;
  readonly has_to_divider?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly label?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly reward?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly user_certificates_aggregate?: Maybe<User_Certificates_Aggregate_Order_By>;
};

/** primary key columns input for table: certificate_templates */
export type Certificate_Templates_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "certificate_templates" */
export enum Certificate_Templates_Select_Column {
  /** column name */
  AccreditationNumber = 'accreditation_number',
  /** column name */
  BackgroundPhotoUuid = 'background_photo_uuid',
  /** column name */
  ColorTheme = 'color_theme',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateHeld = 'date_held',
  /** column name */
  Description = 'description',
  /** column name */
  HasAwardsThis = 'has_awards_this',
  /** column name */
  HasLogo = 'has_logo',
  /** column name */
  HasProviderName = 'has_provider_name',
  /** column name */
  HasToDivider = 'has_to_divider',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Reward = 'reward',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "certificate_templates" */
export type Certificate_Templates_Set_Input = {
  readonly accreditation_number?: Maybe<Scalars['String']>;
  readonly background_photo_uuid?: Maybe<Scalars['String']>;
  readonly color_theme?: Maybe<Scalars['String']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly date_held?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly has_awards_this?: Maybe<Scalars['Boolean']>;
  readonly has_logo?: Maybe<Scalars['Boolean']>;
  readonly has_provider_name?: Maybe<Scalars['Boolean']>;
  readonly has_to_divider?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly reward?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Certificate_Templates_Stddev_Fields = {
  readonly __typename?: 'certificate_templates_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "certificate_templates" */
export type Certificate_Templates_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Certificate_Templates_Stddev_Pop_Fields = {
  readonly __typename?: 'certificate_templates_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "certificate_templates" */
export type Certificate_Templates_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Certificate_Templates_Stddev_Samp_Fields = {
  readonly __typename?: 'certificate_templates_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "certificate_templates" */
export type Certificate_Templates_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Certificate_Templates_Sum_Fields = {
  readonly __typename?: 'certificate_templates_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "certificate_templates" */
export type Certificate_Templates_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "certificate_templates" */
export enum Certificate_Templates_Update_Column {
  /** column name */
  AccreditationNumber = 'accreditation_number',
  /** column name */
  BackgroundPhotoUuid = 'background_photo_uuid',
  /** column name */
  ColorTheme = 'color_theme',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateHeld = 'date_held',
  /** column name */
  Description = 'description',
  /** column name */
  HasAwardsThis = 'has_awards_this',
  /** column name */
  HasLogo = 'has_logo',
  /** column name */
  HasProviderName = 'has_provider_name',
  /** column name */
  HasToDivider = 'has_to_divider',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Reward = 'reward',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Certificate_Templates_Var_Pop_Fields = {
  readonly __typename?: 'certificate_templates_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "certificate_templates" */
export type Certificate_Templates_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Certificate_Templates_Var_Samp_Fields = {
  readonly __typename?: 'certificate_templates_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "certificate_templates" */
export type Certificate_Templates_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Certificate_Templates_Variance_Fields = {
  readonly __typename?: 'certificate_templates_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "certificate_templates" */
export type Certificate_Templates_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_articles" */
export type Course_Articles = {
  readonly __typename?: 'course_articles';
  readonly body?: Maybe<Scalars['String']>;
  readonly id: Scalars['bigint'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "course_articles" */
export type Course_Articles_Aggregate = {
  readonly __typename?: 'course_articles_aggregate';
  readonly aggregate?: Maybe<Course_Articles_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Articles>;
};

/** aggregate fields of "course_articles" */
export type Course_Articles_Aggregate_Fields = {
  readonly __typename?: 'course_articles_aggregate_fields';
  readonly avg?: Maybe<Course_Articles_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Articles_Max_Fields>;
  readonly min?: Maybe<Course_Articles_Min_Fields>;
  readonly stddev?: Maybe<Course_Articles_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Articles_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Articles_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Articles_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Articles_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Articles_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Articles_Variance_Fields>;
};


/** aggregate fields of "course_articles" */
export type Course_Articles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_articles" */
export type Course_Articles_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Articles_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Articles_Max_Order_By>;
  readonly min?: Maybe<Course_Articles_Min_Order_By>;
  readonly stddev?: Maybe<Course_Articles_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Articles_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Articles_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Articles_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Articles_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Articles_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Articles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_articles" */
export type Course_Articles_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Articles_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Articles_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Articles_Avg_Fields = {
  readonly __typename?: 'course_articles_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_articles" */
export type Course_Articles_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_articles". All fields are combined with a logical 'AND'. */
export type Course_Articles_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Articles_Bool_Exp>>;
  readonly _not?: Maybe<Course_Articles_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Articles_Bool_Exp>>;
  readonly body?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_articles" */
export enum Course_Articles_Constraint {
  /** unique or primary key constraint */
  CourseArticlesPkey = 'course_articles_pkey'
}

/** input type for incrementing numeric columns in table "course_articles" */
export type Course_Articles_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_articles" */
export type Course_Articles_Insert_Input = {
  readonly body?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Course_Articles_Max_Fields = {
  readonly __typename?: 'course_articles_max_fields';
  readonly body?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "course_articles" */
export type Course_Articles_Max_Order_By = {
  readonly body?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Articles_Min_Fields = {
  readonly __typename?: 'course_articles_min_fields';
  readonly body?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "course_articles" */
export type Course_Articles_Min_Order_By = {
  readonly body?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_articles" */
export type Course_Articles_Mutation_Response = {
  readonly __typename?: 'course_articles_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Articles>;
};

/** on conflict condition type for table "course_articles" */
export type Course_Articles_On_Conflict = {
  readonly constraint: Course_Articles_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Articles_Update_Column>;
  readonly where?: Maybe<Course_Articles_Bool_Exp>;
};

/** Ordering options when selecting data from "course_articles". */
export type Course_Articles_Order_By = {
  readonly body?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_articles */
export type Course_Articles_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_articles" */
export enum Course_Articles_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** input type for updating data in table "course_articles" */
export type Course_Articles_Set_Input = {
  readonly body?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Course_Articles_Stddev_Fields = {
  readonly __typename?: 'course_articles_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_articles" */
export type Course_Articles_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Articles_Stddev_Pop_Fields = {
  readonly __typename?: 'course_articles_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_articles" */
export type Course_Articles_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Articles_Stddev_Samp_Fields = {
  readonly __typename?: 'course_articles_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_articles" */
export type Course_Articles_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Articles_Sum_Fields = {
  readonly __typename?: 'course_articles_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_articles" */
export type Course_Articles_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "course_articles" */
export enum Course_Articles_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** aggregate var_pop on columns */
export type Course_Articles_Var_Pop_Fields = {
  readonly __typename?: 'course_articles_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_articles" */
export type Course_Articles_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Articles_Var_Samp_Fields = {
  readonly __typename?: 'course_articles_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_articles" */
export type Course_Articles_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Articles_Variance_Fields = {
  readonly __typename?: 'course_articles_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_articles" */
export type Course_Articles_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_assessments" */
export type Course_Assessments = {
  readonly __typename?: 'course_assessments';
  readonly created_at: Scalars['timestamp'];
  readonly form_id?: Maybe<Scalars['String']>;
  readonly id: Scalars['bigint'];
  readonly is_locked: Scalars['Boolean'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at: Scalars['timestamp'];
};

/** aggregated selection of "course_assessments" */
export type Course_Assessments_Aggregate = {
  readonly __typename?: 'course_assessments_aggregate';
  readonly aggregate?: Maybe<Course_Assessments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Assessments>;
};

/** aggregate fields of "course_assessments" */
export type Course_Assessments_Aggregate_Fields = {
  readonly __typename?: 'course_assessments_aggregate_fields';
  readonly avg?: Maybe<Course_Assessments_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Assessments_Max_Fields>;
  readonly min?: Maybe<Course_Assessments_Min_Fields>;
  readonly stddev?: Maybe<Course_Assessments_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Assessments_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Assessments_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Assessments_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Assessments_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Assessments_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Assessments_Variance_Fields>;
};


/** aggregate fields of "course_assessments" */
export type Course_Assessments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_assessments" */
export type Course_Assessments_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Assessments_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Assessments_Max_Order_By>;
  readonly min?: Maybe<Course_Assessments_Min_Order_By>;
  readonly stddev?: Maybe<Course_Assessments_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Assessments_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Assessments_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Assessments_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Assessments_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Assessments_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Assessments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_assessments" */
export type Course_Assessments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Assessments_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Assessments_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Assessments_Avg_Fields = {
  readonly __typename?: 'course_assessments_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_assessments" */
export type Course_Assessments_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_assessments". All fields are combined with a logical 'AND'. */
export type Course_Assessments_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Assessments_Bool_Exp>>;
  readonly _not?: Maybe<Course_Assessments_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Assessments_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly form_id?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_locked?: Maybe<Boolean_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_assessments" */
export enum Course_Assessments_Constraint {
  /** unique or primary key constraint */
  CourseAssessmentsPkey = 'course_assessments_pkey'
}

/** input type for incrementing numeric columns in table "course_assessments" */
export type Course_Assessments_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_assessments" */
export type Course_Assessments_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Assessments_Max_Fields = {
  readonly __typename?: 'course_assessments_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "course_assessments" */
export type Course_Assessments_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Assessments_Min_Fields = {
  readonly __typename?: 'course_assessments_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "course_assessments" */
export type Course_Assessments_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_assessments" */
export type Course_Assessments_Mutation_Response = {
  readonly __typename?: 'course_assessments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Assessments>;
};

/** on conflict condition type for table "course_assessments" */
export type Course_Assessments_On_Conflict = {
  readonly constraint: Course_Assessments_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Assessments_Update_Column>;
  readonly where?: Maybe<Course_Assessments_Bool_Exp>;
};

/** Ordering options when selecting data from "course_assessments". */
export type Course_Assessments_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_locked?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_assessments */
export type Course_Assessments_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_assessments" */
export enum Course_Assessments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_assessments" */
export type Course_Assessments_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Assessments_Stddev_Fields = {
  readonly __typename?: 'course_assessments_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_assessments" */
export type Course_Assessments_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Assessments_Stddev_Pop_Fields = {
  readonly __typename?: 'course_assessments_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_assessments" */
export type Course_Assessments_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Assessments_Stddev_Samp_Fields = {
  readonly __typename?: 'course_assessments_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_assessments" */
export type Course_Assessments_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Assessments_Sum_Fields = {
  readonly __typename?: 'course_assessments_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_assessments" */
export type Course_Assessments_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "course_assessments" */
export enum Course_Assessments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Assessments_Var_Pop_Fields = {
  readonly __typename?: 'course_assessments_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_assessments" */
export type Course_Assessments_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Assessments_Var_Samp_Fields = {
  readonly __typename?: 'course_assessments_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_assessments" */
export type Course_Assessments_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Assessments_Variance_Fields = {
  readonly __typename?: 'course_assessments_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_assessments" */
export type Course_Assessments_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_attachments" */
export type Course_Attachments = {
  readonly __typename?: 'course_attachments';
  readonly created_at: Scalars['timestamp'];
  readonly id: Scalars['bigint'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at: Scalars['timestamp'];
  readonly url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "course_attachments" */
export type Course_Attachments_Aggregate = {
  readonly __typename?: 'course_attachments_aggregate';
  readonly aggregate?: Maybe<Course_Attachments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Attachments>;
};

/** aggregate fields of "course_attachments" */
export type Course_Attachments_Aggregate_Fields = {
  readonly __typename?: 'course_attachments_aggregate_fields';
  readonly avg?: Maybe<Course_Attachments_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Attachments_Max_Fields>;
  readonly min?: Maybe<Course_Attachments_Min_Fields>;
  readonly stddev?: Maybe<Course_Attachments_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Attachments_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Attachments_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Attachments_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Attachments_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Attachments_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Attachments_Variance_Fields>;
};


/** aggregate fields of "course_attachments" */
export type Course_Attachments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_attachments" */
export type Course_Attachments_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Attachments_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Attachments_Max_Order_By>;
  readonly min?: Maybe<Course_Attachments_Min_Order_By>;
  readonly stddev?: Maybe<Course_Attachments_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Attachments_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Attachments_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Attachments_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Attachments_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Attachments_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Attachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_attachments" */
export type Course_Attachments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Attachments_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Attachments_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Attachments_Avg_Fields = {
  readonly __typename?: 'course_attachments_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_attachments" */
export type Course_Attachments_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_attachments". All fields are combined with a logical 'AND'. */
export type Course_Attachments_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Attachments_Bool_Exp>>;
  readonly _not?: Maybe<Course_Attachments_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Attachments_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_attachments" */
export enum Course_Attachments_Constraint {
  /** unique or primary key constraint */
  CoursePdfsPkey = 'course_pdfs_pkey'
}

/** input type for incrementing numeric columns in table "course_attachments" */
export type Course_Attachments_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_attachments" */
export type Course_Attachments_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Attachments_Max_Fields = {
  readonly __typename?: 'course_attachments_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_attachments" */
export type Course_Attachments_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Attachments_Min_Fields = {
  readonly __typename?: 'course_attachments_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_attachments" */
export type Course_Attachments_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly url?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_attachments" */
export type Course_Attachments_Mutation_Response = {
  readonly __typename?: 'course_attachments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Attachments>;
};

/** on conflict condition type for table "course_attachments" */
export type Course_Attachments_On_Conflict = {
  readonly constraint: Course_Attachments_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Attachments_Update_Column>;
  readonly where?: Maybe<Course_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "course_attachments". */
export type Course_Attachments_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly url?: Maybe<Order_By>;
};

/** primary key columns input for table: course_attachments */
export type Course_Attachments_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_attachments" */
export enum Course_Attachments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "course_attachments" */
export type Course_Attachments_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Attachments_Stddev_Fields = {
  readonly __typename?: 'course_attachments_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_attachments" */
export type Course_Attachments_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Attachments_Stddev_Pop_Fields = {
  readonly __typename?: 'course_attachments_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_attachments" */
export type Course_Attachments_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Attachments_Stddev_Samp_Fields = {
  readonly __typename?: 'course_attachments_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_attachments" */
export type Course_Attachments_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Attachments_Sum_Fields = {
  readonly __typename?: 'course_attachments_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_attachments" */
export type Course_Attachments_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "course_attachments" */
export enum Course_Attachments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Course_Attachments_Var_Pop_Fields = {
  readonly __typename?: 'course_attachments_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_attachments" */
export type Course_Attachments_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Attachments_Var_Samp_Fields = {
  readonly __typename?: 'course_attachments_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_attachments" */
export type Course_Attachments_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Attachments_Variance_Fields = {
  readonly __typename?: 'course_attachments_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_attachments" */
export type Course_Attachments_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_certificates" */
export type Course_Certificates = {
  readonly __typename?: 'course_certificates';
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly course_reward?: Maybe<Course_Rewards>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly course_student?: Maybe<Course_Students>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly created_at: Scalars['timestamp'];
  readonly id: Scalars['bigint'];
  readonly identifier: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
};

/** aggregated selection of "course_certificates" */
export type Course_Certificates_Aggregate = {
  readonly __typename?: 'course_certificates_aggregate';
  readonly aggregate?: Maybe<Course_Certificates_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Certificates>;
};

/** aggregate fields of "course_certificates" */
export type Course_Certificates_Aggregate_Fields = {
  readonly __typename?: 'course_certificates_aggregate_fields';
  readonly avg?: Maybe<Course_Certificates_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Certificates_Max_Fields>;
  readonly min?: Maybe<Course_Certificates_Min_Fields>;
  readonly stddev?: Maybe<Course_Certificates_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Certificates_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Certificates_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Certificates_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Certificates_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Certificates_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Certificates_Variance_Fields>;
};


/** aggregate fields of "course_certificates" */
export type Course_Certificates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_certificates" */
export type Course_Certificates_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Certificates_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Certificates_Max_Order_By>;
  readonly min?: Maybe<Course_Certificates_Min_Order_By>;
  readonly stddev?: Maybe<Course_Certificates_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Certificates_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Certificates_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Certificates_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Certificates_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Certificates_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Certificates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_certificates" */
export type Course_Certificates_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Certificates_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Certificates_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Certificates_Avg_Fields = {
  readonly __typename?: 'course_certificates_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_certificates" */
export type Course_Certificates_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_certificates". All fields are combined with a logical 'AND'. */
export type Course_Certificates_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Certificates_Bool_Exp>>;
  readonly _not?: Maybe<Course_Certificates_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Certificates_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_reward?: Maybe<Course_Rewards_Bool_Exp>;
  readonly course_reward_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_student?: Maybe<Course_Students_Bool_Exp>;
  readonly course_student_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly identifier?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_certificates" */
export enum Course_Certificates_Constraint {
  /** unique or primary key constraint */
  CourseCertificatesPkey = 'course_certificates_pkey'
}

/** input type for incrementing numeric columns in table "course_certificates" */
export type Course_Certificates_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_certificates" */
export type Course_Certificates_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward?: Maybe<Course_Rewards_Obj_Rel_Insert_Input>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student?: Maybe<Course_Students_Obj_Rel_Insert_Input>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Certificates_Max_Fields = {
  readonly __typename?: 'course_certificates_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "course_certificates" */
export type Course_Certificates_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Certificates_Min_Fields = {
  readonly __typename?: 'course_certificates_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "course_certificates" */
export type Course_Certificates_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_certificates" */
export type Course_Certificates_Mutation_Response = {
  readonly __typename?: 'course_certificates_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Certificates>;
};

/** on conflict condition type for table "course_certificates" */
export type Course_Certificates_On_Conflict = {
  readonly constraint: Course_Certificates_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Certificates_Update_Column>;
  readonly where?: Maybe<Course_Certificates_Bool_Exp>;
};

/** Ordering options when selecting data from "course_certificates". */
export type Course_Certificates_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward?: Maybe<Course_Rewards_Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student?: Maybe<Course_Students_Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_certificates */
export type Course_Certificates_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_certificates" */
export enum Course_Certificates_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_certificates" */
export type Course_Certificates_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Certificates_Stddev_Fields = {
  readonly __typename?: 'course_certificates_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_certificates" */
export type Course_Certificates_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Certificates_Stddev_Pop_Fields = {
  readonly __typename?: 'course_certificates_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_certificates" */
export type Course_Certificates_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Certificates_Stddev_Samp_Fields = {
  readonly __typename?: 'course_certificates_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_certificates" */
export type Course_Certificates_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Certificates_Sum_Fields = {
  readonly __typename?: 'course_certificates_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_certificates" */
export type Course_Certificates_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** update columns of table "course_certificates" */
export enum Course_Certificates_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Certificates_Var_Pop_Fields = {
  readonly __typename?: 'course_certificates_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_certificates" */
export type Course_Certificates_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Certificates_Var_Samp_Fields = {
  readonly __typename?: 'course_certificates_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_certificates" */
export type Course_Certificates_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Certificates_Variance_Fields = {
  readonly __typename?: 'course_certificates_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_certificates" */
export type Course_Certificates_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** columns and relationships of "course_discount_types" */
export type Course_Discount_Types = {
  readonly __typename?: 'course_discount_types';
  /** An array relationship */
  readonly course_discounts: ReadonlyArray<Course_Discounts>;
  /** An aggregate relationship */
  readonly course_discounts_aggregate: Course_Discounts_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "course_discount_types" */
export type Course_Discount_TypesCourse_DiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


/** columns and relationships of "course_discount_types" */
export type Course_Discount_TypesCourse_Discounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};

/** aggregated selection of "course_discount_types" */
export type Course_Discount_Types_Aggregate = {
  readonly __typename?: 'course_discount_types_aggregate';
  readonly aggregate?: Maybe<Course_Discount_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Discount_Types>;
};

/** aggregate fields of "course_discount_types" */
export type Course_Discount_Types_Aggregate_Fields = {
  readonly __typename?: 'course_discount_types_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Discount_Types_Max_Fields>;
  readonly min?: Maybe<Course_Discount_Types_Min_Fields>;
};


/** aggregate fields of "course_discount_types" */
export type Course_Discount_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Discount_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_discount_types". All fields are combined with a logical 'AND'. */
export type Course_Discount_Types_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Discount_Types_Bool_Exp>>;
  readonly _not?: Maybe<Course_Discount_Types_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Discount_Types_Bool_Exp>>;
  readonly course_discounts?: Maybe<Course_Discounts_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_discount_types" */
export enum Course_Discount_Types_Constraint {
  /** unique or primary key constraint */
  CourseDiscountTypePkey = 'course_discount_type_pkey'
}

export enum Course_Discount_Types_Enum {
  Percent = 'percent',
  Static = 'static'
}

/** Boolean expression to compare columns of type "course_discount_types_enum". All fields are combined with logical 'AND'. */
export type Course_Discount_Types_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Course_Discount_Types_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Course_Discount_Types_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Course_Discount_Types_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Course_Discount_Types_Enum>>;
};

/** input type for inserting data into table "course_discount_types" */
export type Course_Discount_Types_Insert_Input = {
  readonly course_discounts?: Maybe<Course_Discounts_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Discount_Types_Max_Fields = {
  readonly __typename?: 'course_discount_types_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Discount_Types_Min_Fields = {
  readonly __typename?: 'course_discount_types_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_discount_types" */
export type Course_Discount_Types_Mutation_Response = {
  readonly __typename?: 'course_discount_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Discount_Types>;
};

/** input type for inserting object relation for remote table "course_discount_types" */
export type Course_Discount_Types_Obj_Rel_Insert_Input = {
  readonly data: Course_Discount_Types_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Discount_Types_On_Conflict>;
};

/** on conflict condition type for table "course_discount_types" */
export type Course_Discount_Types_On_Conflict = {
  readonly constraint: Course_Discount_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Discount_Types_Update_Column>;
  readonly where?: Maybe<Course_Discount_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "course_discount_types". */
export type Course_Discount_Types_Order_By = {
  readonly course_discounts_aggregate?: Maybe<Course_Discounts_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_discount_types */
export type Course_Discount_Types_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "course_discount_types" */
export enum Course_Discount_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_discount_types" */
export type Course_Discount_Types_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "course_discount_types" */
export enum Course_Discount_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "course_discounts" */
export type Course_Discounts = {
  readonly __typename?: 'course_discounts';
  readonly code: Scalars['String'];
  readonly count: Scalars['Int'];
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  /** An object relationship */
  readonly course_discount_type: Course_Discount_Types;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  readonly course_modules: ReadonlyArray<Course_Modules>;
  /** An aggregate relationship */
  readonly course_modules_aggregate: Course_Modules_Aggregate;
  /** An array relationship */
  readonly course_orders: ReadonlyArray<Course_Order>;
  /** An aggregate relationship */
  readonly course_orders_aggregate: Course_Order_Aggregate;
  readonly created_at: Scalars['timestamptz'];
  readonly expiration_date?: Maybe<Scalars['date']>;
  readonly id: Scalars['uuid'];
  readonly infinite_usage?: Maybe<Scalars['Boolean']>;
  readonly provider_id: Scalars['uuid'];
  readonly type: Course_Discount_Types_Enum;
  readonly updated_at: Scalars['timestamptz'];
  readonly value: Scalars['numeric'];
};


/** columns and relationships of "course_discounts" */
export type Course_DiscountsCourse_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "course_discounts" */
export type Course_DiscountsCourse_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "course_discounts" */
export type Course_DiscountsCourse_OrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "course_discounts" */
export type Course_DiscountsCourse_Orders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};

/** aggregated selection of "course_discounts" */
export type Course_Discounts_Aggregate = {
  readonly __typename?: 'course_discounts_aggregate';
  readonly aggregate?: Maybe<Course_Discounts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Discounts>;
};

/** aggregate fields of "course_discounts" */
export type Course_Discounts_Aggregate_Fields = {
  readonly __typename?: 'course_discounts_aggregate_fields';
  readonly avg?: Maybe<Course_Discounts_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Discounts_Max_Fields>;
  readonly min?: Maybe<Course_Discounts_Min_Fields>;
  readonly stddev?: Maybe<Course_Discounts_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Discounts_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Discounts_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Discounts_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Discounts_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Discounts_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Discounts_Variance_Fields>;
};


/** aggregate fields of "course_discounts" */
export type Course_Discounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_discounts" */
export type Course_Discounts_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Discounts_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Discounts_Max_Order_By>;
  readonly min?: Maybe<Course_Discounts_Min_Order_By>;
  readonly stddev?: Maybe<Course_Discounts_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Discounts_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Discounts_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Discounts_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Discounts_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Discounts_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Discounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_discounts" */
export type Course_Discounts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Discounts_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Discounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Discounts_Avg_Fields = {
  readonly __typename?: 'course_discounts_avg_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_discounts" */
export type Course_Discounts_Avg_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_discounts". All fields are combined with a logical 'AND'. */
export type Course_Discounts_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Discounts_Bool_Exp>>;
  readonly _not?: Maybe<Course_Discounts_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Discounts_Bool_Exp>>;
  readonly code?: Maybe<String_Comparison_Exp>;
  readonly count?: Maybe<Int_Comparison_Exp>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_discount_type?: Maybe<Course_Discount_Types_Bool_Exp>;
  readonly course_id?: Maybe<Int_Comparison_Exp>;
  readonly course_modules?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_orders?: Maybe<Course_Order_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly expiration_date?: Maybe<Date_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly infinite_usage?: Maybe<Boolean_Comparison_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly type?: Maybe<Course_Discount_Types_Enum_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly value?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_discounts" */
export enum Course_Discounts_Constraint {
  /** unique or primary key constraint */
  CourseDiscountsPkey = 'course_discounts_pkey'
}

/** input type for incrementing numeric columns in table "course_discounts" */
export type Course_Discounts_Inc_Input = {
  readonly count?: Maybe<Scalars['Int']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "course_discounts" */
export type Course_Discounts_Insert_Input = {
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_discount_type?: Maybe<Course_Discount_Types_Obj_Rel_Insert_Input>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly course_modules?: Maybe<Course_Modules_Arr_Rel_Insert_Input>;
  readonly course_orders?: Maybe<Course_Order_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiration_date?: Maybe<Scalars['date']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly infinite_usage?: Maybe<Scalars['Boolean']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly type?: Maybe<Course_Discount_Types_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Course_Discounts_Max_Fields = {
  readonly __typename?: 'course_discounts_max_fields';
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiration_date?: Maybe<Scalars['date']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "course_discounts" */
export type Course_Discounts_Max_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiration_date?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Discounts_Min_Fields = {
  readonly __typename?: 'course_discounts_min_fields';
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiration_date?: Maybe<Scalars['date']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "course_discounts" */
export type Course_Discounts_Min_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiration_date?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_discounts" */
export type Course_Discounts_Mutation_Response = {
  readonly __typename?: 'course_discounts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Discounts>;
};

/** input type for inserting object relation for remote table "course_discounts" */
export type Course_Discounts_Obj_Rel_Insert_Input = {
  readonly data: Course_Discounts_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Discounts_On_Conflict>;
};

/** on conflict condition type for table "course_discounts" */
export type Course_Discounts_On_Conflict = {
  readonly constraint: Course_Discounts_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Discounts_Update_Column>;
  readonly where?: Maybe<Course_Discounts_Bool_Exp>;
};

/** Ordering options when selecting data from "course_discounts". */
export type Course_Discounts_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_discount_type?: Maybe<Course_Discount_Types_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_modules_aggregate?: Maybe<Course_Modules_Aggregate_Order_By>;
  readonly course_orders_aggregate?: Maybe<Course_Order_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly expiration_date?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly infinite_usage?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_discounts */
export type Course_Discounts_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_discounts" */
export enum Course_Discounts_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Count = 'count',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  InfiniteUsage = 'infinite_usage',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_discounts" */
export type Course_Discounts_Set_Input = {
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly expiration_date?: Maybe<Scalars['date']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly infinite_usage?: Maybe<Scalars['Boolean']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly type?: Maybe<Course_Discount_Types_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Course_Discounts_Stddev_Fields = {
  readonly __typename?: 'course_discounts_stddev_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_discounts" */
export type Course_Discounts_Stddev_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Discounts_Stddev_Pop_Fields = {
  readonly __typename?: 'course_discounts_stddev_pop_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_discounts" */
export type Course_Discounts_Stddev_Pop_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Discounts_Stddev_Samp_Fields = {
  readonly __typename?: 'course_discounts_stddev_samp_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_discounts" */
export type Course_Discounts_Stddev_Samp_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Discounts_Sum_Fields = {
  readonly __typename?: 'course_discounts_sum_fields';
  readonly count?: Maybe<Scalars['Int']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "course_discounts" */
export type Course_Discounts_Sum_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** update columns of table "course_discounts" */
export enum Course_Discounts_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Count = 'count',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  InfiniteUsage = 'infinite_usage',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Course_Discounts_Var_Pop_Fields = {
  readonly __typename?: 'course_discounts_var_pop_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_discounts" */
export type Course_Discounts_Var_Pop_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Discounts_Var_Samp_Fields = {
  readonly __typename?: 'course_discounts_var_samp_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_discounts" */
export type Course_Discounts_Var_Samp_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Discounts_Variance_Fields = {
  readonly __typename?: 'course_discounts_variance_fields';
  readonly count?: Maybe<Scalars['Float']>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_discounts" */
export type Course_Discounts_Variance_Order_By = {
  readonly count?: Maybe<Order_By>;
  /** If NULL, it implies that the discount code can be applied on any course of the provider */
  readonly course_id?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** columns and relationships of "course_forms" */
export type Course_Forms = {
  readonly __typename?: 'course_forms';
  /** An object relationship */
  readonly form?: Maybe<Forms>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  readonly form_submissions: ReadonlyArray<Form_Submissions>;
  /** An aggregate relationship */
  readonly form_submissions_aggregate: Form_Submissions_Aggregate;
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "course_forms" */
export type Course_FormsForm_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


/** columns and relationships of "course_forms" */
export type Course_FormsForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};

/** aggregated selection of "course_forms" */
export type Course_Forms_Aggregate = {
  readonly __typename?: 'course_forms_aggregate';
  readonly aggregate?: Maybe<Course_Forms_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Forms>;
};

/** aggregate fields of "course_forms" */
export type Course_Forms_Aggregate_Fields = {
  readonly __typename?: 'course_forms_aggregate_fields';
  readonly avg?: Maybe<Course_Forms_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Forms_Max_Fields>;
  readonly min?: Maybe<Course_Forms_Min_Fields>;
  readonly stddev?: Maybe<Course_Forms_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Forms_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Forms_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Forms_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Forms_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Forms_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Forms_Variance_Fields>;
};


/** aggregate fields of "course_forms" */
export type Course_Forms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_forms" */
export type Course_Forms_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Forms_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Forms_Max_Order_By>;
  readonly min?: Maybe<Course_Forms_Min_Order_By>;
  readonly stddev?: Maybe<Course_Forms_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Forms_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Forms_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Forms_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Forms_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Forms_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Forms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_forms" */
export type Course_Forms_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Forms_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Forms_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Forms_Avg_Fields = {
  readonly __typename?: 'course_forms_avg_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_forms" */
export type Course_Forms_Avg_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_forms". All fields are combined with a logical 'AND'. */
export type Course_Forms_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Forms_Bool_Exp>>;
  readonly _not?: Maybe<Course_Forms_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Forms_Bool_Exp>>;
  readonly form?: Maybe<Forms_Bool_Exp>;
  readonly form_id?: Maybe<Uuid_Comparison_Exp>;
  readonly form_submissions?: Maybe<Form_Submissions_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_forms" */
export enum Course_Forms_Constraint {
  /** unique or primary key constraint */
  CourseFormsPkey = 'course_forms_pkey'
}

/** input type for incrementing numeric columns in table "course_forms" */
export type Course_Forms_Inc_Input = {
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_forms" */
export type Course_Forms_Insert_Input = {
  readonly form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly form_submissions?: Maybe<Form_Submissions_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Course_Forms_Max_Fields = {
  readonly __typename?: 'course_forms_max_fields';
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "course_forms" */
export type Course_Forms_Max_Order_By = {
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Forms_Min_Fields = {
  readonly __typename?: 'course_forms_min_fields';
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "course_forms" */
export type Course_Forms_Min_Order_By = {
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_forms" */
export type Course_Forms_Mutation_Response = {
  readonly __typename?: 'course_forms_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Forms>;
};

/** input type for inserting object relation for remote table "course_forms" */
export type Course_Forms_Obj_Rel_Insert_Input = {
  readonly data: Course_Forms_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Forms_On_Conflict>;
};

/** on conflict condition type for table "course_forms" */
export type Course_Forms_On_Conflict = {
  readonly constraint: Course_Forms_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Forms_Update_Column>;
  readonly where?: Maybe<Course_Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "course_forms". */
export type Course_Forms_Order_By = {
  readonly form?: Maybe<Forms_Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_forms */
export type Course_Forms_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_forms" */
export enum Course_Forms_Select_Column {
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** input type for updating data in table "course_forms" */
export type Course_Forms_Set_Input = {
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Course_Forms_Stddev_Fields = {
  readonly __typename?: 'course_forms_stddev_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_forms" */
export type Course_Forms_Stddev_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Forms_Stddev_Pop_Fields = {
  readonly __typename?: 'course_forms_stddev_pop_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_forms" */
export type Course_Forms_Stddev_Pop_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Forms_Stddev_Samp_Fields = {
  readonly __typename?: 'course_forms_stddev_samp_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_forms" */
export type Course_Forms_Stddev_Samp_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Forms_Sum_Fields = {
  readonly __typename?: 'course_forms_sum_fields';
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_forms" */
export type Course_Forms_Sum_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "course_forms" */
export enum Course_Forms_Update_Column {
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** aggregate var_pop on columns */
export type Course_Forms_Var_Pop_Fields = {
  readonly __typename?: 'course_forms_var_pop_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_forms" */
export type Course_Forms_Var_Pop_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Forms_Var_Samp_Fields = {
  readonly __typename?: 'course_forms_var_samp_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_forms" */
export type Course_Forms_Var_Samp_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Forms_Variance_Fields = {
  readonly __typename?: 'course_forms_variance_fields';
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_forms" */
export type Course_Forms_Variance_Order_By = {
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_gains" */
export type Course_Gains = {
  readonly __typename?: 'course_gains';
  /** An object relationship */
  readonly course: Courses;
  readonly course_id: Scalars['bigint'];
  readonly description: Scalars['String'];
  readonly id: Scalars['uuid'];
};

/** aggregated selection of "course_gains" */
export type Course_Gains_Aggregate = {
  readonly __typename?: 'course_gains_aggregate';
  readonly aggregate?: Maybe<Course_Gains_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Gains>;
};

/** aggregate fields of "course_gains" */
export type Course_Gains_Aggregate_Fields = {
  readonly __typename?: 'course_gains_aggregate_fields';
  readonly avg?: Maybe<Course_Gains_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Gains_Max_Fields>;
  readonly min?: Maybe<Course_Gains_Min_Fields>;
  readonly stddev?: Maybe<Course_Gains_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Gains_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Gains_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Gains_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Gains_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Gains_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Gains_Variance_Fields>;
};


/** aggregate fields of "course_gains" */
export type Course_Gains_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_gains" */
export type Course_Gains_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Gains_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Gains_Max_Order_By>;
  readonly min?: Maybe<Course_Gains_Min_Order_By>;
  readonly stddev?: Maybe<Course_Gains_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Gains_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Gains_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Gains_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Gains_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Gains_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Gains_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_gains" */
export type Course_Gains_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Gains_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Gains_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Gains_Avg_Fields = {
  readonly __typename?: 'course_gains_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_gains" */
export type Course_Gains_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_gains". All fields are combined with a logical 'AND'. */
export type Course_Gains_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Gains_Bool_Exp>>;
  readonly _not?: Maybe<Course_Gains_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Gains_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_gains" */
export enum Course_Gains_Constraint {
  /** unique or primary key constraint */
  CourseGainsPkey = 'course_gains_pkey'
}

/** input type for incrementing numeric columns in table "course_gains" */
export type Course_Gains_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_gains" */
export type Course_Gains_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Course_Gains_Max_Fields = {
  readonly __typename?: 'course_gains_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "course_gains" */
export type Course_Gains_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Gains_Min_Fields = {
  readonly __typename?: 'course_gains_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "course_gains" */
export type Course_Gains_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_gains" */
export type Course_Gains_Mutation_Response = {
  readonly __typename?: 'course_gains_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Gains>;
};

/** on conflict condition type for table "course_gains" */
export type Course_Gains_On_Conflict = {
  readonly constraint: Course_Gains_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Gains_Update_Column>;
  readonly where?: Maybe<Course_Gains_Bool_Exp>;
};

/** Ordering options when selecting data from "course_gains". */
export type Course_Gains_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_gains */
export type Course_Gains_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_gains" */
export enum Course_Gains_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "course_gains" */
export type Course_Gains_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Course_Gains_Stddev_Fields = {
  readonly __typename?: 'course_gains_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_gains" */
export type Course_Gains_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Gains_Stddev_Pop_Fields = {
  readonly __typename?: 'course_gains_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_gains" */
export type Course_Gains_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Gains_Stddev_Samp_Fields = {
  readonly __typename?: 'course_gains_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_gains" */
export type Course_Gains_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Gains_Sum_Fields = {
  readonly __typename?: 'course_gains_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_gains" */
export type Course_Gains_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "course_gains" */
export enum Course_Gains_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Course_Gains_Var_Pop_Fields = {
  readonly __typename?: 'course_gains_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_gains" */
export type Course_Gains_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Gains_Var_Samp_Fields = {
  readonly __typename?: 'course_gains_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_gains" */
export type Course_Gains_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Gains_Variance_Fields = {
  readonly __typename?: 'course_gains_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_gains" */
export type Course_Gains_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_instructors" */
export type Course_Instructors = {
  readonly __typename?: 'course_instructors';
  /** An object relationship */
  readonly course: Courses;
  readonly course_id: Scalars['bigint'];
  readonly created_at: Scalars['timestamptz'];
  readonly description?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly is_active: Scalars['Boolean'];
  readonly updated_at: Scalars['timestamptz'];
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "course_instructors" */
export type Course_Instructors_Aggregate = {
  readonly __typename?: 'course_instructors_aggregate';
  readonly aggregate?: Maybe<Course_Instructors_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Instructors>;
};

/** aggregate fields of "course_instructors" */
export type Course_Instructors_Aggregate_Fields = {
  readonly __typename?: 'course_instructors_aggregate_fields';
  readonly avg?: Maybe<Course_Instructors_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Instructors_Max_Fields>;
  readonly min?: Maybe<Course_Instructors_Min_Fields>;
  readonly stddev?: Maybe<Course_Instructors_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Instructors_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Instructors_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Instructors_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Instructors_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Instructors_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Instructors_Variance_Fields>;
};


/** aggregate fields of "course_instructors" */
export type Course_Instructors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_instructors" */
export type Course_Instructors_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Instructors_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Instructors_Max_Order_By>;
  readonly min?: Maybe<Course_Instructors_Min_Order_By>;
  readonly stddev?: Maybe<Course_Instructors_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Instructors_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Instructors_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Instructors_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Instructors_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Instructors_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Instructors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_instructors" */
export type Course_Instructors_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Instructors_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Instructors_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Instructors_Avg_Fields = {
  readonly __typename?: 'course_instructors_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_instructors" */
export type Course_Instructors_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_instructors". All fields are combined with a logical 'AND'. */
export type Course_Instructors_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Instructors_Bool_Exp>>;
  readonly _not?: Maybe<Course_Instructors_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Instructors_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_active?: Maybe<Boolean_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_instructors" */
export enum Course_Instructors_Constraint {
  /** unique or primary key constraint */
  CourseInstructorsPkey = 'course_instructors_pkey'
}

/** input type for incrementing numeric columns in table "course_instructors" */
export type Course_Instructors_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_instructors" */
export type Course_Instructors_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Instructors_Max_Fields = {
  readonly __typename?: 'course_instructors_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_instructors" */
export type Course_Instructors_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Instructors_Min_Fields = {
  readonly __typename?: 'course_instructors_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_instructors" */
export type Course_Instructors_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_instructors" */
export type Course_Instructors_Mutation_Response = {
  readonly __typename?: 'course_instructors_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Instructors>;
};

/** on conflict condition type for table "course_instructors" */
export type Course_Instructors_On_Conflict = {
  readonly constraint: Course_Instructors_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Instructors_Update_Column>;
  readonly where?: Maybe<Course_Instructors_Bool_Exp>;
};

/** Ordering options when selecting data from "course_instructors". */
export type Course_Instructors_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_active?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_instructors */
export type Course_Instructors_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_instructors" */
export enum Course_Instructors_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "course_instructors" */
export type Course_Instructors_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Instructors_Stddev_Fields = {
  readonly __typename?: 'course_instructors_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_instructors" */
export type Course_Instructors_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Instructors_Stddev_Pop_Fields = {
  readonly __typename?: 'course_instructors_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_instructors" */
export type Course_Instructors_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Instructors_Stddev_Samp_Fields = {
  readonly __typename?: 'course_instructors_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_instructors" */
export type Course_Instructors_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Instructors_Sum_Fields = {
  readonly __typename?: 'course_instructors_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_instructors" */
export type Course_Instructors_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "course_instructors" */
export enum Course_Instructors_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Course_Instructors_Var_Pop_Fields = {
  readonly __typename?: 'course_instructors_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_instructors" */
export type Course_Instructors_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Instructors_Var_Samp_Fields = {
  readonly __typename?: 'course_instructors_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_instructors" */
export type Course_Instructors_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Instructors_Variance_Fields = {
  readonly __typename?: 'course_instructors_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_instructors" */
export type Course_Instructors_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_invites" */
export type Course_Invites = {
  readonly __typename?: 'course_invites';
  /** An object relationship */
  readonly course: Courses;
  readonly course_id: Scalars['Int'];
  /** An array relationship */
  readonly course_orders: ReadonlyArray<Course_Order>;
  /** An aggregate relationship */
  readonly course_orders_aggregate: Course_Order_Aggregate;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly free_modules: Scalars['_text'];
  readonly give_proceeding_access: Scalars['Boolean'];
  readonly id: Scalars['uuid'];
  readonly is_course_free: Scalars['Boolean'];
  readonly is_used: Scalars['Boolean'];
  readonly used_at?: Maybe<Scalars['timestamptz']>;
  readonly user_display_name?: Maybe<Scalars['String']>;
  readonly user_email?: Maybe<Scalars['String']>;
};


/** columns and relationships of "course_invites" */
export type Course_InvitesCourse_OrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "course_invites" */
export type Course_InvitesCourse_Orders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};

/** aggregated selection of "course_invites" */
export type Course_Invites_Aggregate = {
  readonly __typename?: 'course_invites_aggregate';
  readonly aggregate?: Maybe<Course_Invites_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Invites>;
};

/** aggregate fields of "course_invites" */
export type Course_Invites_Aggregate_Fields = {
  readonly __typename?: 'course_invites_aggregate_fields';
  readonly avg?: Maybe<Course_Invites_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Invites_Max_Fields>;
  readonly min?: Maybe<Course_Invites_Min_Fields>;
  readonly stddev?: Maybe<Course_Invites_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Invites_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Invites_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Invites_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Invites_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Invites_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Invites_Variance_Fields>;
};


/** aggregate fields of "course_invites" */
export type Course_Invites_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_invites" */
export type Course_Invites_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Invites_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Invites_Max_Order_By>;
  readonly min?: Maybe<Course_Invites_Min_Order_By>;
  readonly stddev?: Maybe<Course_Invites_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Invites_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Invites_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Invites_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Invites_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Invites_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Invites_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_invites" */
export type Course_Invites_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Invites_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Invites_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Invites_Avg_Fields = {
  readonly __typename?: 'course_invites_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_invites" */
export type Course_Invites_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_invites". All fields are combined with a logical 'AND'. */
export type Course_Invites_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Invites_Bool_Exp>>;
  readonly _not?: Maybe<Course_Invites_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Invites_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Int_Comparison_Exp>;
  readonly course_orders?: Maybe<Course_Order_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly free_modules?: Maybe<_Text_Comparison_Exp>;
  readonly give_proceeding_access?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_course_free?: Maybe<Boolean_Comparison_Exp>;
  readonly is_used?: Maybe<Boolean_Comparison_Exp>;
  readonly used_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly user_display_name?: Maybe<String_Comparison_Exp>;
  readonly user_email?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_invites" */
export enum Course_Invites_Constraint {
  /** unique or primary key constraint */
  CourseInvitesIdKey = 'course_invites_id_key',
  /** unique or primary key constraint */
  CourseInvitesPkey = 'course_invites_pkey'
}

/** input type for incrementing numeric columns in table "course_invites" */
export type Course_Invites_Inc_Input = {
  readonly course_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_invites" */
export type Course_Invites_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly course_orders?: Maybe<Course_Order_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly free_modules?: Maybe<Scalars['_text']>;
  readonly give_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_course_free?: Maybe<Scalars['Boolean']>;
  readonly is_used?: Maybe<Scalars['Boolean']>;
  readonly used_at?: Maybe<Scalars['timestamptz']>;
  readonly user_display_name?: Maybe<Scalars['String']>;
  readonly user_email?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Invites_Max_Fields = {
  readonly __typename?: 'course_invites_max_fields';
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly used_at?: Maybe<Scalars['timestamptz']>;
  readonly user_display_name?: Maybe<Scalars['String']>;
  readonly user_email?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_invites" */
export type Course_Invites_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly used_at?: Maybe<Order_By>;
  readonly user_display_name?: Maybe<Order_By>;
  readonly user_email?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Invites_Min_Fields = {
  readonly __typename?: 'course_invites_min_fields';
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly used_at?: Maybe<Scalars['timestamptz']>;
  readonly user_display_name?: Maybe<Scalars['String']>;
  readonly user_email?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_invites" */
export type Course_Invites_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly used_at?: Maybe<Order_By>;
  readonly user_display_name?: Maybe<Order_By>;
  readonly user_email?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_invites" */
export type Course_Invites_Mutation_Response = {
  readonly __typename?: 'course_invites_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Invites>;
};

/** input type for inserting object relation for remote table "course_invites" */
export type Course_Invites_Obj_Rel_Insert_Input = {
  readonly data: Course_Invites_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Invites_On_Conflict>;
};

/** on conflict condition type for table "course_invites" */
export type Course_Invites_On_Conflict = {
  readonly constraint: Course_Invites_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Invites_Update_Column>;
  readonly where?: Maybe<Course_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "course_invites". */
export type Course_Invites_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_orders_aggregate?: Maybe<Course_Order_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly free_modules?: Maybe<Order_By>;
  readonly give_proceeding_access?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_course_free?: Maybe<Order_By>;
  readonly is_used?: Maybe<Order_By>;
  readonly used_at?: Maybe<Order_By>;
  readonly user_display_name?: Maybe<Order_By>;
  readonly user_email?: Maybe<Order_By>;
};

/** primary key columns input for table: course_invites */
export type Course_Invites_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_invites" */
export enum Course_Invites_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeModules = 'free_modules',
  /** column name */
  GiveProceedingAccess = 'give_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  IsCourseFree = 'is_course_free',
  /** column name */
  IsUsed = 'is_used',
  /** column name */
  UsedAt = 'used_at',
  /** column name */
  UserDisplayName = 'user_display_name',
  /** column name */
  UserEmail = 'user_email'
}

/** input type for updating data in table "course_invites" */
export type Course_Invites_Set_Input = {
  readonly course_id?: Maybe<Scalars['Int']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly free_modules?: Maybe<Scalars['_text']>;
  readonly give_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_course_free?: Maybe<Scalars['Boolean']>;
  readonly is_used?: Maybe<Scalars['Boolean']>;
  readonly used_at?: Maybe<Scalars['timestamptz']>;
  readonly user_display_name?: Maybe<Scalars['String']>;
  readonly user_email?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Invites_Stddev_Fields = {
  readonly __typename?: 'course_invites_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_invites" */
export type Course_Invites_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Invites_Stddev_Pop_Fields = {
  readonly __typename?: 'course_invites_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_invites" */
export type Course_Invites_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Invites_Stddev_Samp_Fields = {
  readonly __typename?: 'course_invites_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_invites" */
export type Course_Invites_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Invites_Sum_Fields = {
  readonly __typename?: 'course_invites_sum_fields';
  readonly course_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_invites" */
export type Course_Invites_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "course_invites" */
export enum Course_Invites_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FreeModules = 'free_modules',
  /** column name */
  GiveProceedingAccess = 'give_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  IsCourseFree = 'is_course_free',
  /** column name */
  IsUsed = 'is_used',
  /** column name */
  UsedAt = 'used_at',
  /** column name */
  UserDisplayName = 'user_display_name',
  /** column name */
  UserEmail = 'user_email'
}

/** aggregate var_pop on columns */
export type Course_Invites_Var_Pop_Fields = {
  readonly __typename?: 'course_invites_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_invites" */
export type Course_Invites_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Invites_Var_Samp_Fields = {
  readonly __typename?: 'course_invites_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_invites" */
export type Course_Invites_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Invites_Variance_Fields = {
  readonly __typename?: 'course_invites_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_invites" */
export type Course_Invites_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_lecturers" */
export type Course_Lecturers = {
  readonly __typename?: 'course_lecturers';
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly speaker?: Maybe<Speakers>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "course_lecturers" */
export type Course_Lecturers_Aggregate = {
  readonly __typename?: 'course_lecturers_aggregate';
  readonly aggregate?: Maybe<Course_Lecturers_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Lecturers>;
};

/** aggregate fields of "course_lecturers" */
export type Course_Lecturers_Aggregate_Fields = {
  readonly __typename?: 'course_lecturers_aggregate_fields';
  readonly avg?: Maybe<Course_Lecturers_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Lecturers_Max_Fields>;
  readonly min?: Maybe<Course_Lecturers_Min_Fields>;
  readonly stddev?: Maybe<Course_Lecturers_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Lecturers_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Lecturers_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Lecturers_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Lecturers_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Lecturers_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Lecturers_Variance_Fields>;
};


/** aggregate fields of "course_lecturers" */
export type Course_Lecturers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_lecturers" */
export type Course_Lecturers_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Lecturers_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Lecturers_Max_Order_By>;
  readonly min?: Maybe<Course_Lecturers_Min_Order_By>;
  readonly stddev?: Maybe<Course_Lecturers_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Lecturers_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Lecturers_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Lecturers_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Lecturers_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Lecturers_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Lecturers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_lecturers" */
export type Course_Lecturers_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Lecturers_Insert_Input>;
};

/** aggregate avg on columns */
export type Course_Lecturers_Avg_Fields = {
  readonly __typename?: 'course_lecturers_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_lecturers" */
export type Course_Lecturers_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_lecturers". All fields are combined with a logical 'AND'. */
export type Course_Lecturers_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Lecturers_Bool_Exp>>;
  readonly _not?: Maybe<Course_Lecturers_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Lecturers_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly speaker?: Maybe<Speakers_Bool_Exp>;
  readonly speaker_id?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "course_lecturers" */
export type Course_Lecturers_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_lecturers" */
export type Course_Lecturers_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker?: Maybe<Speakers_Obj_Rel_Insert_Input>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Course_Lecturers_Max_Fields = {
  readonly __typename?: 'course_lecturers_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "course_lecturers" */
export type Course_Lecturers_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Lecturers_Min_Fields = {
  readonly __typename?: 'course_lecturers_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "course_lecturers" */
export type Course_Lecturers_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_lecturers" */
export type Course_Lecturers_Mutation_Response = {
  readonly __typename?: 'course_lecturers_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Lecturers>;
};

/** Ordering options when selecting data from "course_lecturers". */
export type Course_Lecturers_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly speaker?: Maybe<Speakers_Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** select columns of table "course_lecturers" */
export enum Course_Lecturers_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  SpeakerId = 'speaker_id'
}

/** input type for updating data in table "course_lecturers" */
export type Course_Lecturers_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Course_Lecturers_Stddev_Fields = {
  readonly __typename?: 'course_lecturers_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_lecturers" */
export type Course_Lecturers_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Lecturers_Stddev_Pop_Fields = {
  readonly __typename?: 'course_lecturers_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_lecturers" */
export type Course_Lecturers_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Lecturers_Stddev_Samp_Fields = {
  readonly __typename?: 'course_lecturers_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_lecturers" */
export type Course_Lecturers_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Lecturers_Sum_Fields = {
  readonly __typename?: 'course_lecturers_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly speaker_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_lecturers" */
export type Course_Lecturers_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Lecturers_Var_Pop_Fields = {
  readonly __typename?: 'course_lecturers_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_lecturers" */
export type Course_Lecturers_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Lecturers_Var_Samp_Fields = {
  readonly __typename?: 'course_lecturers_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_lecturers" */
export type Course_Lecturers_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Lecturers_Variance_Fields = {
  readonly __typename?: 'course_lecturers_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly speaker_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_lecturers" */
export type Course_Lecturers_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly speaker_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_live_sessions" */
export type Course_Live_Sessions = {
  readonly __typename?: 'course_live_sessions';
  readonly attendance_enabled: Scalars['Boolean'];
  /** An array relationship */
  readonly attendances: ReadonlyArray<Attendances>;
  /** An aggregate relationship */
  readonly attendances_aggregate: Attendances_Aggregate;
  readonly created_at: Scalars['timestamptz'];
  readonly custom_link?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly email_notifications: ReadonlyArray<Email_Notifications>;
  /** An aggregate relationship */
  readonly email_notifications_aggregate: Email_Notifications_Aggregate;
  readonly id: Scalars['bigint'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly session_date?: Maybe<Scalars['timestamptz']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
  readonly time_end?: Maybe<Scalars['timestamptz']>;
  readonly time_start?: Maybe<Scalars['timestamptz']>;
  readonly updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "course_live_sessions" */
export type Course_Live_SessionsAttendancesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


/** columns and relationships of "course_live_sessions" */
export type Course_Live_SessionsAttendances_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


/** columns and relationships of "course_live_sessions" */
export type Course_Live_SessionsEmail_NotificationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


/** columns and relationships of "course_live_sessions" */
export type Course_Live_SessionsEmail_Notifications_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};

/** aggregated selection of "course_live_sessions" */
export type Course_Live_Sessions_Aggregate = {
  readonly __typename?: 'course_live_sessions_aggregate';
  readonly aggregate?: Maybe<Course_Live_Sessions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Live_Sessions>;
};

/** aggregate fields of "course_live_sessions" */
export type Course_Live_Sessions_Aggregate_Fields = {
  readonly __typename?: 'course_live_sessions_aggregate_fields';
  readonly avg?: Maybe<Course_Live_Sessions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Live_Sessions_Max_Fields>;
  readonly min?: Maybe<Course_Live_Sessions_Min_Fields>;
  readonly stddev?: Maybe<Course_Live_Sessions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Live_Sessions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Live_Sessions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Live_Sessions_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Live_Sessions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Live_Sessions_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Live_Sessions_Variance_Fields>;
};


/** aggregate fields of "course_live_sessions" */
export type Course_Live_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_live_sessions" */
export type Course_Live_Sessions_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Live_Sessions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Live_Sessions_Max_Order_By>;
  readonly min?: Maybe<Course_Live_Sessions_Min_Order_By>;
  readonly stddev?: Maybe<Course_Live_Sessions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Live_Sessions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Live_Sessions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Live_Sessions_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Live_Sessions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Live_Sessions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Live_Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_live_sessions" */
export type Course_Live_Sessions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Live_Sessions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Live_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Live_Sessions_Avg_Fields = {
  readonly __typename?: 'course_live_sessions_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_live_sessions". All fields are combined with a logical 'AND'. */
export type Course_Live_Sessions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Live_Sessions_Bool_Exp>>;
  readonly _not?: Maybe<Course_Live_Sessions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Live_Sessions_Bool_Exp>>;
  readonly attendance_enabled?: Maybe<Boolean_Comparison_Exp>;
  readonly attendances?: Maybe<Attendances_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly custom_link?: Maybe<String_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly email_notifications?: Maybe<Email_Notifications_Bool_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
  readonly seconds_before_reminder?: Maybe<Int_Comparison_Exp>;
  readonly session_date?: Maybe<Timestamptz_Comparison_Exp>;
  readonly streaming_service?: Maybe<Int_Comparison_Exp>;
  readonly time_end?: Maybe<Timestamptz_Comparison_Exp>;
  readonly time_start?: Maybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_live_sessions" */
export enum Course_Live_Sessions_Constraint {
  /** unique or primary key constraint */
  CourseLiveSessionsIdKey = 'course_live_sessions_id_key',
  /** unique or primary key constraint */
  CourseLiveSessionsPkey = 'course_live_sessions_pkey'
}

/** input type for incrementing numeric columns in table "course_live_sessions" */
export type Course_Live_Sessions_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_live_sessions" */
export type Course_Live_Sessions_Insert_Input = {
  readonly attendance_enabled?: Maybe<Scalars['Boolean']>;
  readonly attendances?: Maybe<Attendances_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly custom_link?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email_notifications?: Maybe<Email_Notifications_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly session_date?: Maybe<Scalars['timestamptz']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
  readonly time_end?: Maybe<Scalars['timestamptz']>;
  readonly time_start?: Maybe<Scalars['timestamptz']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Course_Live_Sessions_Max_Fields = {
  readonly __typename?: 'course_live_sessions_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly custom_link?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly session_date?: Maybe<Scalars['timestamptz']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
  readonly time_end?: Maybe<Scalars['timestamptz']>;
  readonly time_start?: Maybe<Scalars['timestamptz']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly custom_link?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly session_date?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
  readonly time_end?: Maybe<Order_By>;
  readonly time_start?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Live_Sessions_Min_Fields = {
  readonly __typename?: 'course_live_sessions_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly custom_link?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly session_date?: Maybe<Scalars['timestamptz']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
  readonly time_end?: Maybe<Scalars['timestamptz']>;
  readonly time_start?: Maybe<Scalars['timestamptz']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly custom_link?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly session_date?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
  readonly time_end?: Maybe<Order_By>;
  readonly time_start?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_live_sessions" */
export type Course_Live_Sessions_Mutation_Response = {
  readonly __typename?: 'course_live_sessions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Live_Sessions>;
};

/** input type for inserting object relation for remote table "course_live_sessions" */
export type Course_Live_Sessions_Obj_Rel_Insert_Input = {
  readonly data: Course_Live_Sessions_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Live_Sessions_On_Conflict>;
};

/** on conflict condition type for table "course_live_sessions" */
export type Course_Live_Sessions_On_Conflict = {
  readonly constraint: Course_Live_Sessions_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Live_Sessions_Update_Column>;
  readonly where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "course_live_sessions". */
export type Course_Live_Sessions_Order_By = {
  readonly attendance_enabled?: Maybe<Order_By>;
  readonly attendances_aggregate?: Maybe<Attendances_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly custom_link?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email_notifications_aggregate?: Maybe<Email_Notifications_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly session_date?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
  readonly time_end?: Maybe<Order_By>;
  readonly time_start?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_live_sessions */
export type Course_Live_Sessions_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_live_sessions" */
export enum Course_Live_Sessions_Select_Column {
  /** column name */
  AttendanceEnabled = 'attendance_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomLink = 'custom_link',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  SecondsBeforeReminder = 'seconds_before_reminder',
  /** column name */
  SessionDate = 'session_date',
  /** column name */
  StreamingService = 'streaming_service',
  /** column name */
  TimeEnd = 'time_end',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_live_sessions" */
export type Course_Live_Sessions_Set_Input = {
  readonly attendance_enabled?: Maybe<Scalars['Boolean']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly custom_link?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly session_date?: Maybe<Scalars['timestamptz']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
  readonly time_end?: Maybe<Scalars['timestamptz']>;
  readonly time_start?: Maybe<Scalars['timestamptz']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Course_Live_Sessions_Stddev_Fields = {
  readonly __typename?: 'course_live_sessions_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Live_Sessions_Stddev_Pop_Fields = {
  readonly __typename?: 'course_live_sessions_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Live_Sessions_Stddev_Samp_Fields = {
  readonly __typename?: 'course_live_sessions_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Live_Sessions_Sum_Fields = {
  readonly __typename?: 'course_live_sessions_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Int']>;
  readonly streaming_service?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** update columns of table "course_live_sessions" */
export enum Course_Live_Sessions_Update_Column {
  /** column name */
  AttendanceEnabled = 'attendance_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomLink = 'custom_link',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  SecondsBeforeReminder = 'seconds_before_reminder',
  /** column name */
  SessionDate = 'session_date',
  /** column name */
  StreamingService = 'streaming_service',
  /** column name */
  TimeEnd = 'time_end',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Live_Sessions_Var_Pop_Fields = {
  readonly __typename?: 'course_live_sessions_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Live_Sessions_Var_Samp_Fields = {
  readonly __typename?: 'course_live_sessions_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Live_Sessions_Variance_Fields = {
  readonly __typename?: 'course_live_sessions_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
  readonly seconds_before_reminder?: Maybe<Scalars['Float']>;
  readonly streaming_service?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_live_sessions" */
export type Course_Live_Sessions_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly seconds_before_reminder?: Maybe<Order_By>;
  readonly streaming_service?: Maybe<Order_By>;
};

/** columns and relationships of "course_module_students" */
export type Course_Module_Students = {
  readonly __typename?: 'course_module_students';
  /** An object relationship */
  readonly course_module: Course_Modules;
  readonly course_module_id: Scalars['bigint'];
  /** An object relationship */
  readonly course_student: Course_Students;
  readonly course_student_id: Scalars['bigint'];
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id: Scalars['uuid'];
  readonly registered_at: Scalars['timestamp'];
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "course_module_students" */
export type Course_Module_Students_Aggregate = {
  readonly __typename?: 'course_module_students_aggregate';
  readonly aggregate?: Maybe<Course_Module_Students_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Module_Students>;
};

/** aggregate fields of "course_module_students" */
export type Course_Module_Students_Aggregate_Fields = {
  readonly __typename?: 'course_module_students_aggregate_fields';
  readonly avg?: Maybe<Course_Module_Students_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Module_Students_Max_Fields>;
  readonly min?: Maybe<Course_Module_Students_Min_Fields>;
  readonly stddev?: Maybe<Course_Module_Students_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Module_Students_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Module_Students_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Module_Students_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Module_Students_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Module_Students_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Module_Students_Variance_Fields>;
};


/** aggregate fields of "course_module_students" */
export type Course_Module_Students_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_module_students" */
export type Course_Module_Students_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Module_Students_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Module_Students_Max_Order_By>;
  readonly min?: Maybe<Course_Module_Students_Min_Order_By>;
  readonly stddev?: Maybe<Course_Module_Students_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Module_Students_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Module_Students_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Module_Students_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Module_Students_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Module_Students_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Module_Students_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_module_students" */
export type Course_Module_Students_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Module_Students_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Module_Students_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Module_Students_Avg_Fields = {
  readonly __typename?: 'course_module_students_avg_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_module_students" */
export type Course_Module_Students_Avg_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_module_students". All fields are combined with a logical 'AND'. */
export type Course_Module_Students_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Module_Students_Bool_Exp>>;
  readonly _not?: Maybe<Course_Module_Students_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Module_Students_Bool_Exp>>;
  readonly course_module?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_module_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_student?: Maybe<Course_Students_Bool_Exp>;
  readonly course_student_id?: Maybe<Bigint_Comparison_Exp>;
  readonly expired_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly registered_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly started_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_module_students" */
export enum Course_Module_Students_Constraint {
  /** unique or primary key constraint */
  CourseModuleStudentsPkey = 'course_module_students_pkey'
}

/** input type for incrementing numeric columns in table "course_module_students" */
export type Course_Module_Students_Inc_Input = {
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_module_students" */
export type Course_Module_Students_Insert_Input = {
  readonly course_module?: Maybe<Course_Modules_Obj_Rel_Insert_Input>;
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student?: Maybe<Course_Students_Obj_Rel_Insert_Input>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Module_Students_Max_Fields = {
  readonly __typename?: 'course_module_students_max_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_module_students" */
export type Course_Module_Students_Max_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Module_Students_Min_Fields = {
  readonly __typename?: 'course_module_students_min_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_module_students" */
export type Course_Module_Students_Min_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_module_students" */
export type Course_Module_Students_Mutation_Response = {
  readonly __typename?: 'course_module_students_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Module_Students>;
};

/** on conflict condition type for table "course_module_students" */
export type Course_Module_Students_On_Conflict = {
  readonly constraint: Course_Module_Students_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Module_Students_Update_Column>;
  readonly where?: Maybe<Course_Module_Students_Bool_Exp>;
};

/** Ordering options when selecting data from "course_module_students". */
export type Course_Module_Students_Order_By = {
  readonly course_module?: Maybe<Course_Modules_Order_By>;
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student?: Maybe<Course_Students_Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_module_students */
export type Course_Module_Students_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_module_students" */
export enum Course_Module_Students_Select_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "course_module_students" */
export type Course_Module_Students_Set_Input = {
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Module_Students_Stddev_Fields = {
  readonly __typename?: 'course_module_students_stddev_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_module_students" */
export type Course_Module_Students_Stddev_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Module_Students_Stddev_Pop_Fields = {
  readonly __typename?: 'course_module_students_stddev_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_module_students" */
export type Course_Module_Students_Stddev_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Module_Students_Stddev_Samp_Fields = {
  readonly __typename?: 'course_module_students_stddev_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_module_students" */
export type Course_Module_Students_Stddev_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Module_Students_Sum_Fields = {
  readonly __typename?: 'course_module_students_sum_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_module_students" */
export type Course_Module_Students_Sum_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** update columns of table "course_module_students" */
export enum Course_Module_Students_Update_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Course_Module_Students_Var_Pop_Fields = {
  readonly __typename?: 'course_module_students_var_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_module_students" */
export type Course_Module_Students_Var_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Module_Students_Var_Samp_Fields = {
  readonly __typename?: 'course_module_students_var_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_module_students" */
export type Course_Module_Students_Var_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Module_Students_Variance_Fields = {
  readonly __typename?: 'course_module_students_variance_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly course_student_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_module_students" */
export type Course_Module_Students_Variance_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_modules" */
export type Course_Modules = {
  readonly __typename?: 'course_modules';
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  /** An object relationship */
  readonly course_discount?: Maybe<Course_Discounts>;
  readonly course_discout_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  readonly course_module?: Maybe<Course_Modules>;
  /** An array relationship */
  readonly course_module_students: ReadonlyArray<Course_Module_Students>;
  /** An aggregate relationship */
  readonly course_module_students_aggregate: Course_Module_Students_Aggregate;
  /** An array relationship */
  readonly course_modules: ReadonlyArray<Course_Modules>;
  /** An aggregate relationship */
  readonly course_modules_aggregate: Course_Modules_Aggregate;
  /** An array relationship */
  readonly course_order_modules: ReadonlyArray<Course_Order_Modules>;
  /** An aggregate relationship */
  readonly course_order_modules_aggregate: Course_Order_Modules_Aggregate;
  readonly created_at: Scalars['timestamp'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['bigint'];
  /** An array relationship */
  readonly module_items: ReadonlyArray<Module_Items>;
  /** An aggregate relationship */
  readonly module_items_aggregate: Module_Items_Aggregate;
  readonly order: Scalars['Int'];
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price: Scalars['numeric'];
  readonly require_sequential_progress: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_Module_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_Module_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_Order_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesCourse_Order_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesModule_ItemsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};


/** columns and relationships of "course_modules" */
export type Course_ModulesModule_Items_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};

/** aggregated selection of "course_modules" */
export type Course_Modules_Aggregate = {
  readonly __typename?: 'course_modules_aggregate';
  readonly aggregate?: Maybe<Course_Modules_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Modules>;
};

/** aggregate fields of "course_modules" */
export type Course_Modules_Aggregate_Fields = {
  readonly __typename?: 'course_modules_aggregate_fields';
  readonly avg?: Maybe<Course_Modules_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Modules_Max_Fields>;
  readonly min?: Maybe<Course_Modules_Min_Fields>;
  readonly stddev?: Maybe<Course_Modules_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Modules_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Modules_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Modules_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Modules_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Modules_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Modules_Variance_Fields>;
};


/** aggregate fields of "course_modules" */
export type Course_Modules_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_modules" */
export type Course_Modules_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Modules_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Modules_Max_Order_By>;
  readonly min?: Maybe<Course_Modules_Min_Order_By>;
  readonly stddev?: Maybe<Course_Modules_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Modules_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Modules_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Modules_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Modules_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Modules_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Modules_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_modules" */
export type Course_Modules_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Modules_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Modules_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Modules_Avg_Fields = {
  readonly __typename?: 'course_modules_avg_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_modules" */
export type Course_Modules_Avg_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_modules". All fields are combined with a logical 'AND'. */
export type Course_Modules_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Modules_Bool_Exp>>;
  readonly _not?: Maybe<Course_Modules_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Modules_Bool_Exp>>;
  readonly consumable_period?: Maybe<Numeric_Comparison_Exp>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_discount?: Maybe<Course_Discounts_Bool_Exp>;
  readonly course_discout_id?: Maybe<Uuid_Comparison_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_module?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_module_students?: Maybe<Course_Module_Students_Bool_Exp>;
  readonly course_modules?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_order_modules?: Maybe<Course_Order_Modules_Bool_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly module_items?: Maybe<Module_Items_Bool_Exp>;
  readonly order?: Maybe<Int_Comparison_Exp>;
  readonly prerequisite_module_id?: Maybe<Bigint_Comparison_Exp>;
  readonly price?: Maybe<Numeric_Comparison_Exp>;
  readonly require_sequential_progress?: Maybe<Boolean_Comparison_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_modules" */
export enum Course_Modules_Constraint {
  /** unique or primary key constraint */
  CourseModulesPkey = 'course_modules_pkey'
}

/** input type for incrementing numeric columns in table "course_modules" */
export type Course_Modules_Inc_Input = {
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "course_modules" */
export type Course_Modules_Insert_Input = {
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_discount?: Maybe<Course_Discounts_Obj_Rel_Insert_Input>;
  readonly course_discout_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_module?: Maybe<Course_Modules_Obj_Rel_Insert_Input>;
  readonly course_module_students?: Maybe<Course_Module_Students_Arr_Rel_Insert_Input>;
  readonly course_modules?: Maybe<Course_Modules_Arr_Rel_Insert_Input>;
  readonly course_order_modules?: Maybe<Course_Order_Modules_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_items?: Maybe<Module_Items_Arr_Rel_Insert_Input>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly require_sequential_progress?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Modules_Max_Fields = {
  readonly __typename?: 'course_modules_max_fields';
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_discout_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "course_modules" */
export type Course_Modules_Max_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_discout_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Modules_Min_Fields = {
  readonly __typename?: 'course_modules_min_fields';
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_discout_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "course_modules" */
export type Course_Modules_Min_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_discout_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_modules" */
export type Course_Modules_Mutation_Response = {
  readonly __typename?: 'course_modules_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Modules>;
};

/** input type for inserting object relation for remote table "course_modules" */
export type Course_Modules_Obj_Rel_Insert_Input = {
  readonly data: Course_Modules_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Modules_On_Conflict>;
};

/** on conflict condition type for table "course_modules" */
export type Course_Modules_On_Conflict = {
  readonly constraint: Course_Modules_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Modules_Update_Column>;
  readonly where?: Maybe<Course_Modules_Bool_Exp>;
};

/** Ordering options when selecting data from "course_modules". */
export type Course_Modules_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_discount?: Maybe<Course_Discounts_Order_By>;
  readonly course_discout_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_module?: Maybe<Course_Modules_Order_By>;
  readonly course_module_students_aggregate?: Maybe<Course_Module_Students_Aggregate_Order_By>;
  readonly course_modules_aggregate?: Maybe<Course_Modules_Aggregate_Order_By>;
  readonly course_order_modules_aggregate?: Maybe<Course_Order_Modules_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_items_aggregate?: Maybe<Module_Items_Aggregate_Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly require_sequential_progress?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_modules */
export type Course_Modules_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_modules" */
export enum Course_Modules_Select_Column {
  /** column name */
  ConsumablePeriod = 'consumable_period',
  /** column name */
  CourseDiscoutId = 'course_discout_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PrerequisiteModuleId = 'prerequisite_module_id',
  /** column name */
  Price = 'price',
  /** column name */
  RequireSequentialProgress = 'require_sequential_progress',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_modules" */
export type Course_Modules_Set_Input = {
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_discout_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly require_sequential_progress?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Modules_Stddev_Fields = {
  readonly __typename?: 'course_modules_stddev_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_modules" */
export type Course_Modules_Stddev_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Modules_Stddev_Pop_Fields = {
  readonly __typename?: 'course_modules_stddev_pop_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_modules" */
export type Course_Modules_Stddev_Pop_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Modules_Stddev_Samp_Fields = {
  readonly __typename?: 'course_modules_stddev_samp_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_modules" */
export type Course_Modules_Stddev_Samp_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Modules_Sum_Fields = {
  readonly __typename?: 'course_modules_sum_fields';
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly prerequisite_module_id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "course_modules" */
export type Course_Modules_Sum_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** update columns of table "course_modules" */
export enum Course_Modules_Update_Column {
  /** column name */
  ConsumablePeriod = 'consumable_period',
  /** column name */
  CourseDiscoutId = 'course_discout_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PrerequisiteModuleId = 'prerequisite_module_id',
  /** column name */
  Price = 'price',
  /** column name */
  RequireSequentialProgress = 'require_sequential_progress',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Modules_Var_Pop_Fields = {
  readonly __typename?: 'course_modules_var_pop_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_modules" */
export type Course_Modules_Var_Pop_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Modules_Var_Samp_Fields = {
  readonly __typename?: 'course_modules_var_samp_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_modules" */
export type Course_Modules_Var_Samp_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Modules_Variance_Fields = {
  readonly __typename?: 'course_modules_variance_fields';
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly prerequisite_module_id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_modules" */
export type Course_Modules_Variance_Order_By = {
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly prerequisite_module_id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
};

/** columns and relationships of "course_objectives" */
export type Course_Objectives = {
  readonly __typename?: 'course_objectives';
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly id: Scalars['bigint'];
  readonly updated_at: Scalars['timestamp'];
};

/** aggregated selection of "course_objectives" */
export type Course_Objectives_Aggregate = {
  readonly __typename?: 'course_objectives_aggregate';
  readonly aggregate?: Maybe<Course_Objectives_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Objectives>;
};

/** aggregate fields of "course_objectives" */
export type Course_Objectives_Aggregate_Fields = {
  readonly __typename?: 'course_objectives_aggregate_fields';
  readonly avg?: Maybe<Course_Objectives_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Objectives_Max_Fields>;
  readonly min?: Maybe<Course_Objectives_Min_Fields>;
  readonly stddev?: Maybe<Course_Objectives_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Objectives_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Objectives_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Objectives_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Objectives_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Objectives_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Objectives_Variance_Fields>;
};


/** aggregate fields of "course_objectives" */
export type Course_Objectives_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_objectives" */
export type Course_Objectives_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Objectives_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Objectives_Max_Order_By>;
  readonly min?: Maybe<Course_Objectives_Min_Order_By>;
  readonly stddev?: Maybe<Course_Objectives_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Objectives_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Objectives_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Objectives_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Objectives_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Objectives_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Objectives_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_objectives" */
export type Course_Objectives_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Objectives_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Objectives_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Objectives_Avg_Fields = {
  readonly __typename?: 'course_objectives_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_objectives" */
export type Course_Objectives_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_objectives". All fields are combined with a logical 'AND'. */
export type Course_Objectives_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Objectives_Bool_Exp>>;
  readonly _not?: Maybe<Course_Objectives_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Objectives_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_objectives" */
export enum Course_Objectives_Constraint {
  /** unique or primary key constraint */
  CourseObjectivesPkey = 'course_objectives_pkey'
}

/** input type for incrementing numeric columns in table "course_objectives" */
export type Course_Objectives_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_objectives" */
export type Course_Objectives_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Objectives_Max_Fields = {
  readonly __typename?: 'course_objectives_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "course_objectives" */
export type Course_Objectives_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Objectives_Min_Fields = {
  readonly __typename?: 'course_objectives_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "course_objectives" */
export type Course_Objectives_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_objectives" */
export type Course_Objectives_Mutation_Response = {
  readonly __typename?: 'course_objectives_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Objectives>;
};

/** on conflict condition type for table "course_objectives" */
export type Course_Objectives_On_Conflict = {
  readonly constraint: Course_Objectives_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Objectives_Update_Column>;
  readonly where?: Maybe<Course_Objectives_Bool_Exp>;
};

/** Ordering options when selecting data from "course_objectives". */
export type Course_Objectives_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_objectives */
export type Course_Objectives_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_objectives" */
export enum Course_Objectives_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_objectives" */
export type Course_Objectives_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Objectives_Stddev_Fields = {
  readonly __typename?: 'course_objectives_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_objectives" */
export type Course_Objectives_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Objectives_Stddev_Pop_Fields = {
  readonly __typename?: 'course_objectives_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_objectives" */
export type Course_Objectives_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Objectives_Stddev_Samp_Fields = {
  readonly __typename?: 'course_objectives_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_objectives" */
export type Course_Objectives_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Objectives_Sum_Fields = {
  readonly __typename?: 'course_objectives_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_objectives" */
export type Course_Objectives_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** update columns of table "course_objectives" */
export enum Course_Objectives_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Objectives_Var_Pop_Fields = {
  readonly __typename?: 'course_objectives_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_objectives" */
export type Course_Objectives_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Objectives_Var_Samp_Fields = {
  readonly __typename?: 'course_objectives_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_objectives" */
export type Course_Objectives_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Objectives_Variance_Fields = {
  readonly __typename?: 'course_objectives_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_objectives" */
export type Course_Objectives_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** columns and relationships of "course_order" */
export type Course_Order = {
  readonly __typename?: 'course_order';
  readonly acknowledged_at?: Maybe<Scalars['timestamptz']>;
  readonly acknowledger_id?: Maybe<Scalars['String']>;
  readonly acknowledger_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly course: Courses;
  /** An object relationship */
  readonly course_discount?: Maybe<Course_Discounts>;
  readonly course_discount_id?: Maybe<Scalars['uuid']>;
  readonly course_id: Scalars['bigint'];
  /** An object relationship */
  readonly course_invite?: Maybe<Course_Invites>;
  readonly course_invite_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  readonly course_order_modules: ReadonlyArray<Course_Order_Modules>;
  /** An aggregate relationship */
  readonly course_order_modules_aggregate: Course_Order_Modules_Aggregate;
  /** An object relationship */
  readonly course_order_status: Course_Order_Statuses;
  /** An object relationship */
  readonly course_payment_method?: Maybe<Course_Payment_Methods>;
  readonly created_at: Scalars['timestamptz'];
  readonly has_proceeding_access: Scalars['Boolean'];
  readonly id: Scalars['uuid'];
  readonly payment_method?: Maybe<Scalars['String']>;
  readonly proof_payment_uuid?: Maybe<Scalars['String']>;
  readonly purchase_date: Scalars['timestamptz'];
  readonly ref_no?: Maybe<Scalars['String']>;
  readonly status: Course_Order_Statuses_Enum;
  readonly total_price: Scalars['numeric'];
  readonly updated_at: Scalars['timestamptz'];
  readonly user_id: Scalars['String'];
};


/** columns and relationships of "course_order" */
export type Course_OrderCourse_Order_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


/** columns and relationships of "course_order" */
export type Course_OrderCourse_Order_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};

/** aggregated selection of "course_order" */
export type Course_Order_Aggregate = {
  readonly __typename?: 'course_order_aggregate';
  readonly aggregate?: Maybe<Course_Order_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Order>;
};

/** aggregate fields of "course_order" */
export type Course_Order_Aggregate_Fields = {
  readonly __typename?: 'course_order_aggregate_fields';
  readonly avg?: Maybe<Course_Order_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Order_Max_Fields>;
  readonly min?: Maybe<Course_Order_Min_Fields>;
  readonly stddev?: Maybe<Course_Order_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Order_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Order_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Order_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Order_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Order_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Order_Variance_Fields>;
};


/** aggregate fields of "course_order" */
export type Course_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_order" */
export type Course_Order_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Order_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Order_Max_Order_By>;
  readonly min?: Maybe<Course_Order_Min_Order_By>;
  readonly stddev?: Maybe<Course_Order_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Order_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Order_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Order_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Order_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Order_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_order" */
export type Course_Order_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Order_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Order_Avg_Fields = {
  readonly __typename?: 'course_order_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_order" */
export type Course_Order_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_order". All fields are combined with a logical 'AND'. */
export type Course_Order_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Order_Bool_Exp>>;
  readonly _not?: Maybe<Course_Order_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Order_Bool_Exp>>;
  readonly acknowledged_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly acknowledger_id?: Maybe<String_Comparison_Exp>;
  readonly acknowledger_name?: Maybe<String_Comparison_Exp>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_discount?: Maybe<Course_Discounts_Bool_Exp>;
  readonly course_discount_id?: Maybe<Uuid_Comparison_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_invite?: Maybe<Course_Invites_Bool_Exp>;
  readonly course_invite_id?: Maybe<Uuid_Comparison_Exp>;
  readonly course_order_modules?: Maybe<Course_Order_Modules_Bool_Exp>;
  readonly course_order_status?: Maybe<Course_Order_Statuses_Bool_Exp>;
  readonly course_payment_method?: Maybe<Course_Payment_Methods_Bool_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly has_proceeding_access?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly payment_method?: Maybe<String_Comparison_Exp>;
  readonly proof_payment_uuid?: Maybe<String_Comparison_Exp>;
  readonly purchase_date?: Maybe<Timestamptz_Comparison_Exp>;
  readonly ref_no?: Maybe<String_Comparison_Exp>;
  readonly status?: Maybe<Course_Order_Statuses_Enum_Comparison_Exp>;
  readonly total_price?: Maybe<Numeric_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_order" */
export enum Course_Order_Constraint {
  /** unique or primary key constraint */
  CourseOrderPkey = 'course_order_pkey'
}

/** input type for incrementing numeric columns in table "course_order" */
export type Course_Order_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly total_price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "course_order" */
export type Course_Order_Insert_Input = {
  readonly acknowledged_at?: Maybe<Scalars['timestamptz']>;
  readonly acknowledger_id?: Maybe<Scalars['String']>;
  readonly acknowledger_name?: Maybe<Scalars['String']>;
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_discount?: Maybe<Course_Discounts_Obj_Rel_Insert_Input>;
  readonly course_discount_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_invite?: Maybe<Course_Invites_Obj_Rel_Insert_Input>;
  readonly course_invite_id?: Maybe<Scalars['uuid']>;
  readonly course_order_modules?: Maybe<Course_Order_Modules_Arr_Rel_Insert_Input>;
  readonly course_order_status?: Maybe<Course_Order_Statuses_Obj_Rel_Insert_Input>;
  readonly course_payment_method?: Maybe<Course_Payment_Methods_Obj_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly has_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly payment_method?: Maybe<Scalars['String']>;
  readonly proof_payment_uuid?: Maybe<Scalars['String']>;
  readonly purchase_date?: Maybe<Scalars['timestamptz']>;
  readonly ref_no?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Course_Order_Statuses_Enum>;
  readonly total_price?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Order_Max_Fields = {
  readonly __typename?: 'course_order_max_fields';
  readonly acknowledged_at?: Maybe<Scalars['timestamptz']>;
  readonly acknowledger_id?: Maybe<Scalars['String']>;
  readonly acknowledger_name?: Maybe<Scalars['String']>;
  readonly course_discount_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_invite_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly payment_method?: Maybe<Scalars['String']>;
  readonly proof_payment_uuid?: Maybe<Scalars['String']>;
  readonly purchase_date?: Maybe<Scalars['timestamptz']>;
  readonly ref_no?: Maybe<Scalars['String']>;
  readonly total_price?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_order" */
export type Course_Order_Max_Order_By = {
  readonly acknowledged_at?: Maybe<Order_By>;
  readonly acknowledger_id?: Maybe<Order_By>;
  readonly acknowledger_name?: Maybe<Order_By>;
  readonly course_discount_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_invite_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly payment_method?: Maybe<Order_By>;
  readonly proof_payment_uuid?: Maybe<Order_By>;
  readonly purchase_date?: Maybe<Order_By>;
  readonly ref_no?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Order_Min_Fields = {
  readonly __typename?: 'course_order_min_fields';
  readonly acknowledged_at?: Maybe<Scalars['timestamptz']>;
  readonly acknowledger_id?: Maybe<Scalars['String']>;
  readonly acknowledger_name?: Maybe<Scalars['String']>;
  readonly course_discount_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_invite_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly payment_method?: Maybe<Scalars['String']>;
  readonly proof_payment_uuid?: Maybe<Scalars['String']>;
  readonly purchase_date?: Maybe<Scalars['timestamptz']>;
  readonly ref_no?: Maybe<Scalars['String']>;
  readonly total_price?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_order" */
export type Course_Order_Min_Order_By = {
  readonly acknowledged_at?: Maybe<Order_By>;
  readonly acknowledger_id?: Maybe<Order_By>;
  readonly acknowledger_name?: Maybe<Order_By>;
  readonly course_discount_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_invite_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly payment_method?: Maybe<Order_By>;
  readonly proof_payment_uuid?: Maybe<Order_By>;
  readonly purchase_date?: Maybe<Order_By>;
  readonly ref_no?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_order_modules" */
export type Course_Order_Modules = {
  readonly __typename?: 'course_order_modules';
  /** An object relationship */
  readonly course_module?: Maybe<Course_Modules>;
  readonly course_module_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  readonly course_order: Course_Order;
  readonly course_order_id: Scalars['uuid'];
  readonly id: Scalars['uuid'];
};

/** aggregated selection of "course_order_modules" */
export type Course_Order_Modules_Aggregate = {
  readonly __typename?: 'course_order_modules_aggregate';
  readonly aggregate?: Maybe<Course_Order_Modules_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Order_Modules>;
};

/** aggregate fields of "course_order_modules" */
export type Course_Order_Modules_Aggregate_Fields = {
  readonly __typename?: 'course_order_modules_aggregate_fields';
  readonly avg?: Maybe<Course_Order_Modules_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Order_Modules_Max_Fields>;
  readonly min?: Maybe<Course_Order_Modules_Min_Fields>;
  readonly stddev?: Maybe<Course_Order_Modules_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Order_Modules_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Order_Modules_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Order_Modules_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Order_Modules_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Order_Modules_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Order_Modules_Variance_Fields>;
};


/** aggregate fields of "course_order_modules" */
export type Course_Order_Modules_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_order_modules" */
export type Course_Order_Modules_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Order_Modules_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Order_Modules_Max_Order_By>;
  readonly min?: Maybe<Course_Order_Modules_Min_Order_By>;
  readonly stddev?: Maybe<Course_Order_Modules_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Order_Modules_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Order_Modules_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Order_Modules_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Order_Modules_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Order_Modules_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Order_Modules_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_order_modules" */
export type Course_Order_Modules_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Order_Modules_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Order_Modules_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Order_Modules_Avg_Fields = {
  readonly __typename?: 'course_order_modules_avg_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_order_modules" */
export type Course_Order_Modules_Avg_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_order_modules". All fields are combined with a logical 'AND'. */
export type Course_Order_Modules_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Order_Modules_Bool_Exp>>;
  readonly _not?: Maybe<Course_Order_Modules_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Order_Modules_Bool_Exp>>;
  readonly course_module?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_module_id?: Maybe<Int_Comparison_Exp>;
  readonly course_order?: Maybe<Course_Order_Bool_Exp>;
  readonly course_order_id?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_order_modules" */
export enum Course_Order_Modules_Constraint {
  /** unique or primary key constraint */
  CourseOrderModulesPkey = 'course_order_modules_pkey'
}

/** input type for incrementing numeric columns in table "course_order_modules" */
export type Course_Order_Modules_Inc_Input = {
  readonly course_module_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_order_modules" */
export type Course_Order_Modules_Insert_Input = {
  readonly course_module?: Maybe<Course_Modules_Obj_Rel_Insert_Input>;
  readonly course_module_id?: Maybe<Scalars['Int']>;
  readonly course_order?: Maybe<Course_Order_Obj_Rel_Insert_Input>;
  readonly course_order_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Course_Order_Modules_Max_Fields = {
  readonly __typename?: 'course_order_modules_max_fields';
  readonly course_module_id?: Maybe<Scalars['Int']>;
  readonly course_order_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "course_order_modules" */
export type Course_Order_Modules_Max_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_order_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Order_Modules_Min_Fields = {
  readonly __typename?: 'course_order_modules_min_fields';
  readonly course_module_id?: Maybe<Scalars['Int']>;
  readonly course_order_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "course_order_modules" */
export type Course_Order_Modules_Min_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_order_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_order_modules" */
export type Course_Order_Modules_Mutation_Response = {
  readonly __typename?: 'course_order_modules_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Order_Modules>;
};

/** on conflict condition type for table "course_order_modules" */
export type Course_Order_Modules_On_Conflict = {
  readonly constraint: Course_Order_Modules_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Order_Modules_Update_Column>;
  readonly where?: Maybe<Course_Order_Modules_Bool_Exp>;
};

/** Ordering options when selecting data from "course_order_modules". */
export type Course_Order_Modules_Order_By = {
  readonly course_module?: Maybe<Course_Modules_Order_By>;
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_order?: Maybe<Course_Order_Order_By>;
  readonly course_order_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_order_modules */
export type Course_Order_Modules_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_order_modules" */
export enum Course_Order_Modules_Select_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CourseOrderId = 'course_order_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "course_order_modules" */
export type Course_Order_Modules_Set_Input = {
  readonly course_module_id?: Maybe<Scalars['Int']>;
  readonly course_order_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Course_Order_Modules_Stddev_Fields = {
  readonly __typename?: 'course_order_modules_stddev_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_order_modules" */
export type Course_Order_Modules_Stddev_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Order_Modules_Stddev_Pop_Fields = {
  readonly __typename?: 'course_order_modules_stddev_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_order_modules" */
export type Course_Order_Modules_Stddev_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Order_Modules_Stddev_Samp_Fields = {
  readonly __typename?: 'course_order_modules_stddev_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_order_modules" */
export type Course_Order_Modules_Stddev_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Order_Modules_Sum_Fields = {
  readonly __typename?: 'course_order_modules_sum_fields';
  readonly course_module_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_order_modules" */
export type Course_Order_Modules_Sum_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** update columns of table "course_order_modules" */
export enum Course_Order_Modules_Update_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CourseOrderId = 'course_order_id',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Course_Order_Modules_Var_Pop_Fields = {
  readonly __typename?: 'course_order_modules_var_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_order_modules" */
export type Course_Order_Modules_Var_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Order_Modules_Var_Samp_Fields = {
  readonly __typename?: 'course_order_modules_var_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_order_modules" */
export type Course_Order_Modules_Var_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Order_Modules_Variance_Fields = {
  readonly __typename?: 'course_order_modules_variance_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_order_modules" */
export type Course_Order_Modules_Variance_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_order" */
export type Course_Order_Mutation_Response = {
  readonly __typename?: 'course_order_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Order>;
};

/** input type for inserting object relation for remote table "course_order" */
export type Course_Order_Obj_Rel_Insert_Input = {
  readonly data: Course_Order_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Order_On_Conflict>;
};

/** on conflict condition type for table "course_order" */
export type Course_Order_On_Conflict = {
  readonly constraint: Course_Order_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Order_Update_Column>;
  readonly where?: Maybe<Course_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "course_order". */
export type Course_Order_Order_By = {
  readonly acknowledged_at?: Maybe<Order_By>;
  readonly acknowledger_id?: Maybe<Order_By>;
  readonly acknowledger_name?: Maybe<Order_By>;
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_discount?: Maybe<Course_Discounts_Order_By>;
  readonly course_discount_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_invite?: Maybe<Course_Invites_Order_By>;
  readonly course_invite_id?: Maybe<Order_By>;
  readonly course_order_modules_aggregate?: Maybe<Course_Order_Modules_Aggregate_Order_By>;
  readonly course_order_status?: Maybe<Course_Order_Statuses_Order_By>;
  readonly course_payment_method?: Maybe<Course_Payment_Methods_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly has_proceeding_access?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly payment_method?: Maybe<Order_By>;
  readonly proof_payment_uuid?: Maybe<Order_By>;
  readonly purchase_date?: Maybe<Order_By>;
  readonly ref_no?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_order */
export type Course_Order_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_order" */
export enum Course_Order_Select_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  AcknowledgerId = 'acknowledger_id',
  /** column name */
  AcknowledgerName = 'acknowledger_name',
  /** column name */
  CourseDiscountId = 'course_discount_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CourseInviteId = 'course_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasProceedingAccess = 'has_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProofPaymentUuid = 'proof_payment_uuid',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  RefNo = 'ref_no',
  /** column name */
  Status = 'status',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "course_order" */
export type Course_Order_Set_Input = {
  readonly acknowledged_at?: Maybe<Scalars['timestamptz']>;
  readonly acknowledger_id?: Maybe<Scalars['String']>;
  readonly acknowledger_name?: Maybe<Scalars['String']>;
  readonly course_discount_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_invite_id?: Maybe<Scalars['uuid']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly has_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly payment_method?: Maybe<Scalars['String']>;
  readonly proof_payment_uuid?: Maybe<Scalars['String']>;
  readonly purchase_date?: Maybe<Scalars['timestamptz']>;
  readonly ref_no?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Course_Order_Statuses_Enum>;
  readonly total_price?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "course_order_statuses" */
export type Course_Order_Statuses = {
  readonly __typename?: 'course_order_statuses';
  /** An array relationship */
  readonly course_orders: ReadonlyArray<Course_Order>;
  /** An aggregate relationship */
  readonly course_orders_aggregate: Course_Order_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "course_order_statuses" */
export type Course_Order_StatusesCourse_OrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "course_order_statuses" */
export type Course_Order_StatusesCourse_Orders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};

/** aggregated selection of "course_order_statuses" */
export type Course_Order_Statuses_Aggregate = {
  readonly __typename?: 'course_order_statuses_aggregate';
  readonly aggregate?: Maybe<Course_Order_Statuses_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Order_Statuses>;
};

/** aggregate fields of "course_order_statuses" */
export type Course_Order_Statuses_Aggregate_Fields = {
  readonly __typename?: 'course_order_statuses_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Order_Statuses_Max_Fields>;
  readonly min?: Maybe<Course_Order_Statuses_Min_Fields>;
};


/** aggregate fields of "course_order_statuses" */
export type Course_Order_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Order_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_order_statuses". All fields are combined with a logical 'AND'. */
export type Course_Order_Statuses_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Order_Statuses_Bool_Exp>>;
  readonly _not?: Maybe<Course_Order_Statuses_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Order_Statuses_Bool_Exp>>;
  readonly course_orders?: Maybe<Course_Order_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_order_statuses" */
export enum Course_Order_Statuses_Constraint {
  /** unique or primary key constraint */
  CourseOrderStatusesPkey = 'course_order_statuses_pkey',
  /** unique or primary key constraint */
  CourseOrderStatusesValueKey = 'course_order_statuses_value_key'
}

export enum Course_Order_Statuses_Enum {
  A = 'A',
  F = 'F',
  K = 'K',
  P = 'P',
  R = 'R',
  S = 'S',
  U = 'U',
  V = 'V'
}

/** Boolean expression to compare columns of type "course_order_statuses_enum". All fields are combined with logical 'AND'. */
export type Course_Order_Statuses_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Course_Order_Statuses_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Course_Order_Statuses_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Course_Order_Statuses_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Course_Order_Statuses_Enum>>;
};

/** input type for inserting data into table "course_order_statuses" */
export type Course_Order_Statuses_Insert_Input = {
  readonly course_orders?: Maybe<Course_Order_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Order_Statuses_Max_Fields = {
  readonly __typename?: 'course_order_statuses_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Order_Statuses_Min_Fields = {
  readonly __typename?: 'course_order_statuses_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_order_statuses" */
export type Course_Order_Statuses_Mutation_Response = {
  readonly __typename?: 'course_order_statuses_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Order_Statuses>;
};

/** input type for inserting object relation for remote table "course_order_statuses" */
export type Course_Order_Statuses_Obj_Rel_Insert_Input = {
  readonly data: Course_Order_Statuses_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Order_Statuses_On_Conflict>;
};

/** on conflict condition type for table "course_order_statuses" */
export type Course_Order_Statuses_On_Conflict = {
  readonly constraint: Course_Order_Statuses_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Order_Statuses_Update_Column>;
  readonly where?: Maybe<Course_Order_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "course_order_statuses". */
export type Course_Order_Statuses_Order_By = {
  readonly course_orders_aggregate?: Maybe<Course_Order_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_order_statuses */
export type Course_Order_Statuses_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "course_order_statuses" */
export enum Course_Order_Statuses_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_order_statuses" */
export type Course_Order_Statuses_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "course_order_statuses" */
export enum Course_Order_Statuses_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Course_Order_Stddev_Fields = {
  readonly __typename?: 'course_order_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_order" */
export type Course_Order_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Order_Stddev_Pop_Fields = {
  readonly __typename?: 'course_order_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_order" */
export type Course_Order_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Order_Stddev_Samp_Fields = {
  readonly __typename?: 'course_order_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_order" */
export type Course_Order_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Order_Sum_Fields = {
  readonly __typename?: 'course_order_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly total_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "course_order" */
export type Course_Order_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** update columns of table "course_order" */
export enum Course_Order_Update_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  AcknowledgerId = 'acknowledger_id',
  /** column name */
  AcknowledgerName = 'acknowledger_name',
  /** column name */
  CourseDiscountId = 'course_discount_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CourseInviteId = 'course_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasProceedingAccess = 'has_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProofPaymentUuid = 'proof_payment_uuid',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  RefNo = 'ref_no',
  /** column name */
  Status = 'status',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Course_Order_Var_Pop_Fields = {
  readonly __typename?: 'course_order_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_order" */
export type Course_Order_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Order_Var_Samp_Fields = {
  readonly __typename?: 'course_order_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_order" */
export type Course_Order_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Order_Variance_Fields = {
  readonly __typename?: 'course_order_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly total_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_order" */
export type Course_Order_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly total_price?: Maybe<Order_By>;
};

/** columns and relationships of "course_payment_methods" */
export type Course_Payment_Methods = {
  readonly __typename?: 'course_payment_methods';
  /** An array relationship */
  readonly course_orders: ReadonlyArray<Course_Order>;
  /** An aggregate relationship */
  readonly course_orders_aggregate: Course_Order_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "course_payment_methods" */
export type Course_Payment_MethodsCourse_OrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "course_payment_methods" */
export type Course_Payment_MethodsCourse_Orders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};

/** aggregated selection of "course_payment_methods" */
export type Course_Payment_Methods_Aggregate = {
  readonly __typename?: 'course_payment_methods_aggregate';
  readonly aggregate?: Maybe<Course_Payment_Methods_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Payment_Methods>;
};

/** aggregate fields of "course_payment_methods" */
export type Course_Payment_Methods_Aggregate_Fields = {
  readonly __typename?: 'course_payment_methods_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Payment_Methods_Max_Fields>;
  readonly min?: Maybe<Course_Payment_Methods_Min_Fields>;
};


/** aggregate fields of "course_payment_methods" */
export type Course_Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Payment_Methods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_payment_methods". All fields are combined with a logical 'AND'. */
export type Course_Payment_Methods_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Payment_Methods_Bool_Exp>>;
  readonly _not?: Maybe<Course_Payment_Methods_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Payment_Methods_Bool_Exp>>;
  readonly course_orders?: Maybe<Course_Order_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_payment_methods" */
export enum Course_Payment_Methods_Constraint {
  /** unique or primary key constraint */
  CoursePaymentMethodPkey = 'course_payment_method_pkey',
  /** unique or primary key constraint */
  CoursePaymentMethodValueKey = 'course_payment_method_value_key'
}

/** input type for inserting data into table "course_payment_methods" */
export type Course_Payment_Methods_Insert_Input = {
  readonly course_orders?: Maybe<Course_Order_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Payment_Methods_Max_Fields = {
  readonly __typename?: 'course_payment_methods_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Payment_Methods_Min_Fields = {
  readonly __typename?: 'course_payment_methods_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_payment_methods" */
export type Course_Payment_Methods_Mutation_Response = {
  readonly __typename?: 'course_payment_methods_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Payment_Methods>;
};

/** input type for inserting object relation for remote table "course_payment_methods" */
export type Course_Payment_Methods_Obj_Rel_Insert_Input = {
  readonly data: Course_Payment_Methods_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Payment_Methods_On_Conflict>;
};

/** on conflict condition type for table "course_payment_methods" */
export type Course_Payment_Methods_On_Conflict = {
  readonly constraint: Course_Payment_Methods_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Payment_Methods_Update_Column>;
  readonly where?: Maybe<Course_Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "course_payment_methods". */
export type Course_Payment_Methods_Order_By = {
  readonly course_orders_aggregate?: Maybe<Course_Order_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_payment_methods */
export type Course_Payment_Methods_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "course_payment_methods" */
export enum Course_Payment_Methods_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_payment_methods" */
export type Course_Payment_Methods_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "course_payment_methods" */
export enum Course_Payment_Methods_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "course_reminders" */
export type Course_Reminders = {
  readonly __typename?: 'course_reminders';
  /** An object relationship */
  readonly course: Courses;
  readonly course_id: Scalars['bigint'];
  readonly created_at: Scalars['timestamptz'];
  readonly entity_id: Scalars['bigint'];
  readonly entity_name: Scalars['String'];
  readonly entity_type: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly student_email: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "course_reminders" */
export type Course_Reminders_Aggregate = {
  readonly __typename?: 'course_reminders_aggregate';
  readonly aggregate?: Maybe<Course_Reminders_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Reminders>;
};

/** aggregate fields of "course_reminders" */
export type Course_Reminders_Aggregate_Fields = {
  readonly __typename?: 'course_reminders_aggregate_fields';
  readonly avg?: Maybe<Course_Reminders_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Reminders_Max_Fields>;
  readonly min?: Maybe<Course_Reminders_Min_Fields>;
  readonly stddev?: Maybe<Course_Reminders_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Reminders_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Reminders_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Reminders_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Reminders_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Reminders_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Reminders_Variance_Fields>;
};


/** aggregate fields of "course_reminders" */
export type Course_Reminders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_reminders" */
export type Course_Reminders_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Reminders_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Reminders_Max_Order_By>;
  readonly min?: Maybe<Course_Reminders_Min_Order_By>;
  readonly stddev?: Maybe<Course_Reminders_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Reminders_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Reminders_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Reminders_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Reminders_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Reminders_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Reminders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_reminders" */
export type Course_Reminders_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Reminders_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Reminders_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Reminders_Avg_Fields = {
  readonly __typename?: 'course_reminders_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_reminders" */
export type Course_Reminders_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_reminders". All fields are combined with a logical 'AND'. */
export type Course_Reminders_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Reminders_Bool_Exp>>;
  readonly _not?: Maybe<Course_Reminders_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Reminders_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly entity_id?: Maybe<Bigint_Comparison_Exp>;
  readonly entity_name?: Maybe<String_Comparison_Exp>;
  readonly entity_type?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly student_email?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_reminders" */
export enum Course_Reminders_Constraint {
  /** unique or primary key constraint */
  CourseRemindersPkey = 'course_reminders_pkey'
}

/** input type for incrementing numeric columns in table "course_reminders" */
export type Course_Reminders_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_reminders" */
export type Course_Reminders_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
  readonly entity_name?: Maybe<Scalars['String']>;
  readonly entity_type?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly student_email?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Course_Reminders_Max_Fields = {
  readonly __typename?: 'course_reminders_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
  readonly entity_name?: Maybe<Scalars['String']>;
  readonly entity_type?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly student_email?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course_reminders" */
export type Course_Reminders_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
  readonly entity_name?: Maybe<Order_By>;
  readonly entity_type?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly student_email?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Reminders_Min_Fields = {
  readonly __typename?: 'course_reminders_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
  readonly entity_name?: Maybe<Scalars['String']>;
  readonly entity_type?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly student_email?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course_reminders" */
export type Course_Reminders_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
  readonly entity_name?: Maybe<Order_By>;
  readonly entity_type?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly student_email?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_reminders" */
export type Course_Reminders_Mutation_Response = {
  readonly __typename?: 'course_reminders_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Reminders>;
};

/** on conflict condition type for table "course_reminders" */
export type Course_Reminders_On_Conflict = {
  readonly constraint: Course_Reminders_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Reminders_Update_Column>;
  readonly where?: Maybe<Course_Reminders_Bool_Exp>;
};

/** Ordering options when selecting data from "course_reminders". */
export type Course_Reminders_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
  readonly entity_name?: Maybe<Order_By>;
  readonly entity_type?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly student_email?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_reminders */
export type Course_Reminders_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "course_reminders" */
export enum Course_Reminders_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  StudentEmail = 'student_email',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_reminders" */
export type Course_Reminders_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
  readonly entity_name?: Maybe<Scalars['String']>;
  readonly entity_type?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly student_email?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Course_Reminders_Stddev_Fields = {
  readonly __typename?: 'course_reminders_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_reminders" */
export type Course_Reminders_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Reminders_Stddev_Pop_Fields = {
  readonly __typename?: 'course_reminders_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_reminders" */
export type Course_Reminders_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Reminders_Stddev_Samp_Fields = {
  readonly __typename?: 'course_reminders_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_reminders" */
export type Course_Reminders_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Reminders_Sum_Fields = {
  readonly __typename?: 'course_reminders_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly entity_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_reminders" */
export type Course_Reminders_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** update columns of table "course_reminders" */
export enum Course_Reminders_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  StudentEmail = 'student_email',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Reminders_Var_Pop_Fields = {
  readonly __typename?: 'course_reminders_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_reminders" */
export type Course_Reminders_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Reminders_Var_Samp_Fields = {
  readonly __typename?: 'course_reminders_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_reminders" */
export type Course_Reminders_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Reminders_Variance_Fields = {
  readonly __typename?: 'course_reminders_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly entity_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_reminders" */
export type Course_Reminders_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly entity_id?: Maybe<Order_By>;
};

/** columns and relationships of "course_requirements" */
export type Course_Requirements = {
  readonly __typename?: 'course_requirements';
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly id: Scalars['bigint'];
  readonly updated_at: Scalars['timestamp'];
};

/** aggregated selection of "course_requirements" */
export type Course_Requirements_Aggregate = {
  readonly __typename?: 'course_requirements_aggregate';
  readonly aggregate?: Maybe<Course_Requirements_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Requirements>;
};

/** aggregate fields of "course_requirements" */
export type Course_Requirements_Aggregate_Fields = {
  readonly __typename?: 'course_requirements_aggregate_fields';
  readonly avg?: Maybe<Course_Requirements_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Requirements_Max_Fields>;
  readonly min?: Maybe<Course_Requirements_Min_Fields>;
  readonly stddev?: Maybe<Course_Requirements_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Requirements_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Requirements_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Requirements_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Requirements_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Requirements_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Requirements_Variance_Fields>;
};


/** aggregate fields of "course_requirements" */
export type Course_Requirements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_requirements" */
export type Course_Requirements_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Requirements_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Requirements_Max_Order_By>;
  readonly min?: Maybe<Course_Requirements_Min_Order_By>;
  readonly stddev?: Maybe<Course_Requirements_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Requirements_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Requirements_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Requirements_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Requirements_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Requirements_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Requirements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_requirements" */
export type Course_Requirements_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Requirements_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Requirements_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Requirements_Avg_Fields = {
  readonly __typename?: 'course_requirements_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_requirements" */
export type Course_Requirements_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_requirements". All fields are combined with a logical 'AND'. */
export type Course_Requirements_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Requirements_Bool_Exp>>;
  readonly _not?: Maybe<Course_Requirements_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Requirements_Bool_Exp>>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_requirements" */
export enum Course_Requirements_Constraint {
  /** unique or primary key constraint */
  CourseRequirementsPkey = 'course_requirements_pkey'
}

/** input type for incrementing numeric columns in table "course_requirements" */
export type Course_Requirements_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_requirements" */
export type Course_Requirements_Insert_Input = {
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Requirements_Max_Fields = {
  readonly __typename?: 'course_requirements_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "course_requirements" */
export type Course_Requirements_Max_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Requirements_Min_Fields = {
  readonly __typename?: 'course_requirements_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "course_requirements" */
export type Course_Requirements_Min_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_requirements" */
export type Course_Requirements_Mutation_Response = {
  readonly __typename?: 'course_requirements_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Requirements>;
};

/** on conflict condition type for table "course_requirements" */
export type Course_Requirements_On_Conflict = {
  readonly constraint: Course_Requirements_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Requirements_Update_Column>;
  readonly where?: Maybe<Course_Requirements_Bool_Exp>;
};

/** Ordering options when selecting data from "course_requirements". */
export type Course_Requirements_Order_By = {
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_requirements */
export type Course_Requirements_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_requirements" */
export enum Course_Requirements_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_requirements" */
export type Course_Requirements_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Requirements_Stddev_Fields = {
  readonly __typename?: 'course_requirements_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_requirements" */
export type Course_Requirements_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Requirements_Stddev_Pop_Fields = {
  readonly __typename?: 'course_requirements_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_requirements" */
export type Course_Requirements_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Requirements_Stddev_Samp_Fields = {
  readonly __typename?: 'course_requirements_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_requirements" */
export type Course_Requirements_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Requirements_Sum_Fields = {
  readonly __typename?: 'course_requirements_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_requirements" */
export type Course_Requirements_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** update columns of table "course_requirements" */
export enum Course_Requirements_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Requirements_Var_Pop_Fields = {
  readonly __typename?: 'course_requirements_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_requirements" */
export type Course_Requirements_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Requirements_Var_Samp_Fields = {
  readonly __typename?: 'course_requirements_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_requirements" */
export type Course_Requirements_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Requirements_Variance_Fields = {
  readonly __typename?: 'course_requirements_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_requirements" */
export type Course_Requirements_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** columns and relationships of "course_rewards" */
export type Course_Rewards = {
  readonly __typename?: 'course_rewards';
  readonly certificate_type: Scalars['Int'];
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  /** An array relationship */
  readonly course_certificates: ReadonlyArray<Course_Certificates>;
  /** An aggregate relationship */
  readonly course_certificates_aggregate: Course_Certificates_Aggregate;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  readonly cpd_unit_rewards: ReadonlyArray<Cpd_Unit_Rewards>;
  /** An aggregate relationship */
  readonly cpd_unit_rewards_aggregate: Cpd_Unit_Rewards_Aggregate;
  readonly id: Scalars['bigint'];
  /** An array relationship */
  readonly other_unit_rewards: ReadonlyArray<Other_Unit_Rewards>;
  /** An aggregate relationship */
  readonly other_unit_rewards_aggregate: Other_Unit_Rewards_Aggregate;
  /** An array relationship */
  readonly ranking_rewards: ReadonlyArray<Ranking_Rewards>;
  /** An aggregate relationship */
  readonly ranking_rewards_aggregate: Ranking_Rewards_Aggregate;
  readonly reward_type: Scalars['Int'];
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsCourse_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsCourse_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsCpd_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsCpd_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsOther_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsOther_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsRanking_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};


/** columns and relationships of "course_rewards" */
export type Course_RewardsRanking_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};

/** aggregated selection of "course_rewards" */
export type Course_Rewards_Aggregate = {
  readonly __typename?: 'course_rewards_aggregate';
  readonly aggregate?: Maybe<Course_Rewards_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Rewards>;
};

/** aggregate fields of "course_rewards" */
export type Course_Rewards_Aggregate_Fields = {
  readonly __typename?: 'course_rewards_aggregate_fields';
  readonly avg?: Maybe<Course_Rewards_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Rewards_Max_Fields>;
  readonly min?: Maybe<Course_Rewards_Min_Fields>;
  readonly stddev?: Maybe<Course_Rewards_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Rewards_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Rewards_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Rewards_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Rewards_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Rewards_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Rewards_Variance_Fields>;
};


/** aggregate fields of "course_rewards" */
export type Course_Rewards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_rewards" */
export type Course_Rewards_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Rewards_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Rewards_Max_Order_By>;
  readonly min?: Maybe<Course_Rewards_Min_Order_By>;
  readonly stddev?: Maybe<Course_Rewards_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Rewards_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Rewards_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Rewards_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Rewards_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Rewards_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Rewards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_rewards" */
export type Course_Rewards_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Rewards_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Rewards_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Rewards_Avg_Fields = {
  readonly __typename?: 'course_rewards_avg_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_rewards" */
export type Course_Rewards_Avg_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_rewards". All fields are combined with a logical 'AND'. */
export type Course_Rewards_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Rewards_Bool_Exp>>;
  readonly _not?: Maybe<Course_Rewards_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Rewards_Bool_Exp>>;
  readonly certificate_type?: Maybe<Int_Comparison_Exp>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_certificates?: Maybe<Course_Certificates_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly cpd_unit_rewards?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly other_unit_rewards?: Maybe<Other_Unit_Rewards_Bool_Exp>;
  readonly ranking_rewards?: Maybe<Ranking_Rewards_Bool_Exp>;
  readonly reward_type?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_rewards" */
export enum Course_Rewards_Constraint {
  /** unique or primary key constraint */
  CourseRewardsPkey = 'course_rewards_pkey'
}

/** input type for incrementing numeric columns in table "course_rewards" */
export type Course_Rewards_Inc_Input = {
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_rewards" */
export type Course_Rewards_Insert_Input = {
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_certificates?: Maybe<Course_Certificates_Arr_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly cpd_unit_rewards?: Maybe<Cpd_Unit_Rewards_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly other_unit_rewards?: Maybe<Other_Unit_Rewards_Arr_Rel_Insert_Input>;
  readonly ranking_rewards?: Maybe<Ranking_Rewards_Arr_Rel_Insert_Input>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Course_Rewards_Max_Fields = {
  readonly __typename?: 'course_rewards_max_fields';
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "course_rewards" */
export type Course_Rewards_Max_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Rewards_Min_Fields = {
  readonly __typename?: 'course_rewards_min_fields';
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "course_rewards" */
export type Course_Rewards_Min_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_rewards" */
export type Course_Rewards_Mutation_Response = {
  readonly __typename?: 'course_rewards_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Rewards>;
};

/** input type for inserting object relation for remote table "course_rewards" */
export type Course_Rewards_Obj_Rel_Insert_Input = {
  readonly data: Course_Rewards_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Rewards_On_Conflict>;
};

/** on conflict condition type for table "course_rewards" */
export type Course_Rewards_On_Conflict = {
  readonly constraint: Course_Rewards_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Rewards_Update_Column>;
  readonly where?: Maybe<Course_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "course_rewards". */
export type Course_Rewards_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_certificates_aggregate?: Maybe<Course_Certificates_Aggregate_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly cpd_unit_rewards_aggregate?: Maybe<Cpd_Unit_Rewards_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly other_unit_rewards_aggregate?: Maybe<Other_Unit_Rewards_Aggregate_Order_By>;
  readonly ranking_rewards_aggregate?: Maybe<Ranking_Rewards_Aggregate_Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** primary key columns input for table: course_rewards */
export type Course_Rewards_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_rewards" */
export enum Course_Rewards_Select_Column {
  /** column name */
  CertificateType = 'certificate_type',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  RewardType = 'reward_type'
}

/** input type for updating data in table "course_rewards" */
export type Course_Rewards_Set_Input = {
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Course_Rewards_Stddev_Fields = {
  readonly __typename?: 'course_rewards_stddev_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_rewards" */
export type Course_Rewards_Stddev_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Rewards_Stddev_Pop_Fields = {
  readonly __typename?: 'course_rewards_stddev_pop_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_rewards" */
export type Course_Rewards_Stddev_Pop_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Rewards_Stddev_Samp_Fields = {
  readonly __typename?: 'course_rewards_stddev_samp_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_rewards" */
export type Course_Rewards_Stddev_Samp_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Rewards_Sum_Fields = {
  readonly __typename?: 'course_rewards_sum_fields';
  readonly certificate_type?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly reward_type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_rewards" */
export type Course_Rewards_Sum_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** update columns of table "course_rewards" */
export enum Course_Rewards_Update_Column {
  /** column name */
  CertificateType = 'certificate_type',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  RewardType = 'reward_type'
}

/** aggregate var_pop on columns */
export type Course_Rewards_Var_Pop_Fields = {
  readonly __typename?: 'course_rewards_var_pop_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_rewards" */
export type Course_Rewards_Var_Pop_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Rewards_Var_Samp_Fields = {
  readonly __typename?: 'course_rewards_var_samp_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_rewards" */
export type Course_Rewards_Var_Samp_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Rewards_Variance_Fields = {
  readonly __typename?: 'course_rewards_variance_fields';
  readonly certificate_type?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly reward_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_rewards" */
export type Course_Rewards_Variance_Order_By = {
  readonly certificate_type?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly reward_type?: Maybe<Order_By>;
};

/** columns and relationships of "course_sell_types" */
export type Course_Sell_Types = {
  readonly __typename?: 'course_sell_types';
  /** An array relationship */
  readonly courses: ReadonlyArray<Courses>;
  /** An aggregate relationship */
  readonly courses_aggregate: Courses_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "course_sell_types" */
export type Course_Sell_TypesCoursesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


/** columns and relationships of "course_sell_types" */
export type Course_Sell_TypesCourses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};

/** aggregated selection of "course_sell_types" */
export type Course_Sell_Types_Aggregate = {
  readonly __typename?: 'course_sell_types_aggregate';
  readonly aggregate?: Maybe<Course_Sell_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Sell_Types>;
};

/** aggregate fields of "course_sell_types" */
export type Course_Sell_Types_Aggregate_Fields = {
  readonly __typename?: 'course_sell_types_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Sell_Types_Max_Fields>;
  readonly min?: Maybe<Course_Sell_Types_Min_Fields>;
};


/** aggregate fields of "course_sell_types" */
export type Course_Sell_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Sell_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_sell_types". All fields are combined with a logical 'AND'. */
export type Course_Sell_Types_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Sell_Types_Bool_Exp>>;
  readonly _not?: Maybe<Course_Sell_Types_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Sell_Types_Bool_Exp>>;
  readonly courses?: Maybe<Courses_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_sell_types" */
export enum Course_Sell_Types_Constraint {
  /** unique or primary key constraint */
  CourseSellTypePkey = 'course_sell_type_pkey'
}

export enum Course_Sell_Types_Enum {
  Link = 'link',
  Modular = 'modular',
  Whole = 'whole'
}

/** Boolean expression to compare columns of type "course_sell_types_enum". All fields are combined with logical 'AND'. */
export type Course_Sell_Types_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Course_Sell_Types_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Course_Sell_Types_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Course_Sell_Types_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Course_Sell_Types_Enum>>;
};

/** input type for inserting data into table "course_sell_types" */
export type Course_Sell_Types_Insert_Input = {
  readonly courses?: Maybe<Courses_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Sell_Types_Max_Fields = {
  readonly __typename?: 'course_sell_types_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Sell_Types_Min_Fields = {
  readonly __typename?: 'course_sell_types_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_sell_types" */
export type Course_Sell_Types_Mutation_Response = {
  readonly __typename?: 'course_sell_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Sell_Types>;
};

/** input type for inserting object relation for remote table "course_sell_types" */
export type Course_Sell_Types_Obj_Rel_Insert_Input = {
  readonly data: Course_Sell_Types_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Sell_Types_On_Conflict>;
};

/** on conflict condition type for table "course_sell_types" */
export type Course_Sell_Types_On_Conflict = {
  readonly constraint: Course_Sell_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Sell_Types_Update_Column>;
  readonly where?: Maybe<Course_Sell_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "course_sell_types". */
export type Course_Sell_Types_Order_By = {
  readonly courses_aggregate?: Maybe<Courses_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_sell_types */
export type Course_Sell_Types_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "course_sell_types" */
export enum Course_Sell_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_sell_types" */
export type Course_Sell_Types_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "course_sell_types" */
export enum Course_Sell_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "course_signatories" */
export type Course_Signatories = {
  readonly __typename?: 'course_signatories';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at: Scalars['timestamp'];
  readonly id: Scalars['bigint'];
  readonly signatory_id: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
};

/** aggregated selection of "course_signatories" */
export type Course_Signatories_Aggregate = {
  readonly __typename?: 'course_signatories_aggregate';
  readonly aggregate?: Maybe<Course_Signatories_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Signatories>;
};

/** aggregate fields of "course_signatories" */
export type Course_Signatories_Aggregate_Fields = {
  readonly __typename?: 'course_signatories_aggregate_fields';
  readonly avg?: Maybe<Course_Signatories_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Signatories_Max_Fields>;
  readonly min?: Maybe<Course_Signatories_Min_Fields>;
  readonly stddev?: Maybe<Course_Signatories_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Signatories_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Signatories_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Signatories_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Signatories_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Signatories_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Signatories_Variance_Fields>;
};


/** aggregate fields of "course_signatories" */
export type Course_Signatories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Signatories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Course_Signatories_Avg_Fields = {
  readonly __typename?: 'course_signatories_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "course_signatories". All fields are combined with a logical 'AND'. */
export type Course_Signatories_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Signatories_Bool_Exp>>;
  readonly _not?: Maybe<Course_Signatories_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Signatories_Bool_Exp>>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly signatory_id?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_signatories" */
export enum Course_Signatories_Constraint {
  /** unique or primary key constraint */
  CourseSignatoriesPkey = 'course_signatories_pkey'
}

/** input type for incrementing numeric columns in table "course_signatories" */
export type Course_Signatories_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_signatories" */
export type Course_Signatories_Insert_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly signatory_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Course_Signatories_Max_Fields = {
  readonly __typename?: 'course_signatories_max_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly signatory_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Course_Signatories_Min_Fields = {
  readonly __typename?: 'course_signatories_min_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly signatory_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "course_signatories" */
export type Course_Signatories_Mutation_Response = {
  readonly __typename?: 'course_signatories_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Signatories>;
};

/** on conflict condition type for table "course_signatories" */
export type Course_Signatories_On_Conflict = {
  readonly constraint: Course_Signatories_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Signatories_Update_Column>;
  readonly where?: Maybe<Course_Signatories_Bool_Exp>;
};

/** Ordering options when selecting data from "course_signatories". */
export type Course_Signatories_Order_By = {
  readonly course_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly signatory_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: course_signatories */
export type Course_Signatories_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_signatories" */
export enum Course_Signatories_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SignatoryId = 'signatory_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "course_signatories" */
export type Course_Signatories_Set_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly signatory_id?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Course_Signatories_Stddev_Fields = {
  readonly __typename?: 'course_signatories_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Course_Signatories_Stddev_Pop_Fields = {
  readonly __typename?: 'course_signatories_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Course_Signatories_Stddev_Samp_Fields = {
  readonly __typename?: 'course_signatories_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Course_Signatories_Sum_Fields = {
  readonly __typename?: 'course_signatories_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "course_signatories" */
export enum Course_Signatories_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SignatoryId = 'signatory_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Course_Signatories_Var_Pop_Fields = {
  readonly __typename?: 'course_signatories_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Course_Signatories_Var_Samp_Fields = {
  readonly __typename?: 'course_signatories_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Course_Signatories_Variance_Fields = {
  readonly __typename?: 'course_signatories_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "course_students" */
export type Course_Students = {
  readonly __typename?: 'course_students';
  readonly approval_status?: Maybe<Scalars['Int']>;
  /** An object relationship */
  readonly course?: Maybe<Courses>;
  /** An array relationship */
  readonly course_certificates: ReadonlyArray<Course_Certificates>;
  /** An aggregate relationship */
  readonly course_certificates_aggregate: Course_Certificates_Aggregate;
  readonly course_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  readonly course_module_students: ReadonlyArray<Course_Module_Students>;
  /** An aggregate relationship */
  readonly course_module_students_aggregate: Course_Module_Students_Aggregate;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly has_proceeding_access: Scalars['Boolean'];
  readonly id: Scalars['bigint'];
  readonly is_active: Scalars['Boolean'];
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Scalars['String']>;
  readonly registered_at: Scalars['timestamp'];
  readonly started_at?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  readonly student_progresses: ReadonlyArray<Student_Progress>;
  /** An aggregate relationship */
  readonly student_progresses_aggregate: Student_Progress_Aggregate;
  readonly user_email?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsCourse_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsCourse_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsCourse_Module_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsCourse_Module_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsStudent_ProgressesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


/** columns and relationships of "course_students" */
export type Course_StudentsStudent_Progresses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};

/** aggregated selection of "course_students" */
export type Course_Students_Aggregate = {
  readonly __typename?: 'course_students_aggregate';
  readonly aggregate?: Maybe<Course_Students_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Students>;
};

/** aggregate fields of "course_students" */
export type Course_Students_Aggregate_Fields = {
  readonly __typename?: 'course_students_aggregate_fields';
  readonly avg?: Maybe<Course_Students_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Students_Max_Fields>;
  readonly min?: Maybe<Course_Students_Min_Fields>;
  readonly stddev?: Maybe<Course_Students_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Students_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Students_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Students_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Students_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Students_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Students_Variance_Fields>;
};


/** aggregate fields of "course_students" */
export type Course_Students_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_students" */
export type Course_Students_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Students_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Students_Max_Order_By>;
  readonly min?: Maybe<Course_Students_Min_Order_By>;
  readonly stddev?: Maybe<Course_Students_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Students_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Students_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Students_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Students_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Students_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Students_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_students" */
export type Course_Students_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Students_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Students_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Students_Avg_Fields = {
  readonly __typename?: 'course_students_avg_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_students" */
export type Course_Students_Avg_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_students". All fields are combined with a logical 'AND'. */
export type Course_Students_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Students_Bool_Exp>>;
  readonly _not?: Maybe<Course_Students_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Students_Bool_Exp>>;
  readonly approval_status?: Maybe<Int_Comparison_Exp>;
  readonly course?: Maybe<Courses_Bool_Exp>;
  readonly course_certificates?: Maybe<Course_Certificates_Bool_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_module_students?: Maybe<Course_Module_Students_Bool_Exp>;
  readonly expired_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly has_proceeding_access?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_active?: Maybe<Boolean_Comparison_Exp>;
  readonly order_id?: Maybe<String_Comparison_Exp>;
  readonly registered_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly started_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly student_progresses?: Maybe<Student_Progress_Bool_Exp>;
  readonly user_email?: Maybe<String_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_students" */
export enum Course_Students_Constraint {
  /** unique or primary key constraint */
  CourseStudentsPkey = 'course_students_pkey'
}

/** input type for incrementing numeric columns in table "course_students" */
export type Course_Students_Inc_Input = {
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_students" */
export type Course_Students_Insert_Input = {
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course?: Maybe<Courses_Obj_Rel_Insert_Input>;
  readonly course_certificates?: Maybe<Course_Certificates_Arr_Rel_Insert_Input>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly course_module_students?: Maybe<Course_Module_Students_Arr_Rel_Insert_Input>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly has_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Scalars['String']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly student_progresses?: Maybe<Student_Progress_Arr_Rel_Insert_Input>;
  readonly user_email?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Students_Max_Fields = {
  readonly __typename?: 'course_students_max_fields';
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Scalars['String']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_email?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_students" */
export type Course_Students_Max_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_email?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Students_Min_Fields = {
  readonly __typename?: 'course_students_min_fields';
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Scalars['String']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_email?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_students" */
export type Course_Students_Min_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_email?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_students" */
export type Course_Students_Mutation_Response = {
  readonly __typename?: 'course_students_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Students>;
};

/** input type for inserting object relation for remote table "course_students" */
export type Course_Students_Obj_Rel_Insert_Input = {
  readonly data: Course_Students_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Students_On_Conflict>;
};

/** on conflict condition type for table "course_students" */
export type Course_Students_On_Conflict = {
  readonly constraint: Course_Students_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Students_Update_Column>;
  readonly where?: Maybe<Course_Students_Bool_Exp>;
};

/** Ordering options when selecting data from "course_students". */
export type Course_Students_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course?: Maybe<Courses_Order_By>;
  readonly course_certificates_aggregate?: Maybe<Course_Certificates_Aggregate_Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly course_module_students_aggregate?: Maybe<Course_Module_Students_Aggregate_Order_By>;
  readonly expired_at?: Maybe<Order_By>;
  readonly has_proceeding_access?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_active?: Maybe<Order_By>;
  readonly order_id?: Maybe<Order_By>;
  readonly registered_at?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly student_progresses_aggregate?: Maybe<Student_Progress_Aggregate_Order_By>;
  readonly user_email?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: course_students */
export type Course_Students_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_students" */
export enum Course_Students_Select_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  HasProceedingAccess = 'has_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "course_students" */
export type Course_Students_Set_Input = {
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly expired_at?: Maybe<Scalars['timestamp']>;
  readonly has_proceeding_access?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  /** DEPRECATED from previous payment business logic */
  readonly order_id?: Maybe<Scalars['String']>;
  readonly registered_at?: Maybe<Scalars['timestamp']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_email?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Students_Stddev_Fields = {
  readonly __typename?: 'course_students_stddev_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_students" */
export type Course_Students_Stddev_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Students_Stddev_Pop_Fields = {
  readonly __typename?: 'course_students_stddev_pop_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_students" */
export type Course_Students_Stddev_Pop_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Students_Stddev_Samp_Fields = {
  readonly __typename?: 'course_students_stddev_samp_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_students" */
export type Course_Students_Stddev_Samp_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Students_Sum_Fields = {
  readonly __typename?: 'course_students_sum_fields';
  readonly approval_status?: Maybe<Scalars['Int']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_students" */
export type Course_Students_Sum_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** update columns of table "course_students" */
export enum Course_Students_Update_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  HasProceedingAccess = 'has_proceeding_access',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Course_Students_Var_Pop_Fields = {
  readonly __typename?: 'course_students_var_pop_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_students" */
export type Course_Students_Var_Pop_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Students_Var_Samp_Fields = {
  readonly __typename?: 'course_students_var_samp_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_students" */
export type Course_Students_Var_Samp_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Students_Variance_Fields = {
  readonly __typename?: 'course_students_variance_fields';
  readonly approval_status?: Maybe<Scalars['Float']>;
  readonly course_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_students" */
export type Course_Students_Variance_Order_By = {
  readonly approval_status?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
};

/** columns and relationships of "course_videos" */
export type Course_Videos = {
  readonly __typename?: 'course_videos';
  /** An object relationship */
  readonly course_videos_status: Course_Videos_Statuses;
  readonly created_at: Scalars['timestamp'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['bigint'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly source: Scalars['String'];
  readonly status: Course_Videos_Statuses_Enum;
  readonly updated_at: Scalars['timestamp'];
  readonly video_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "course_videos" */
export type Course_Videos_Aggregate = {
  readonly __typename?: 'course_videos_aggregate';
  readonly aggregate?: Maybe<Course_Videos_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Videos>;
};

/** aggregate fields of "course_videos" */
export type Course_Videos_Aggregate_Fields = {
  readonly __typename?: 'course_videos_aggregate_fields';
  readonly avg?: Maybe<Course_Videos_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Videos_Max_Fields>;
  readonly min?: Maybe<Course_Videos_Min_Fields>;
  readonly stddev?: Maybe<Course_Videos_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Course_Videos_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Course_Videos_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Course_Videos_Sum_Fields>;
  readonly var_pop?: Maybe<Course_Videos_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Course_Videos_Var_Samp_Fields>;
  readonly variance?: Maybe<Course_Videos_Variance_Fields>;
};


/** aggregate fields of "course_videos" */
export type Course_Videos_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_videos" */
export type Course_Videos_Aggregate_Order_By = {
  readonly avg?: Maybe<Course_Videos_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Course_Videos_Max_Order_By>;
  readonly min?: Maybe<Course_Videos_Min_Order_By>;
  readonly stddev?: Maybe<Course_Videos_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Course_Videos_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Course_Videos_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Course_Videos_Sum_Order_By>;
  readonly var_pop?: Maybe<Course_Videos_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Course_Videos_Var_Samp_Order_By>;
  readonly variance?: Maybe<Course_Videos_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_videos" */
export type Course_Videos_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Course_Videos_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Videos_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Videos_Avg_Fields = {
  readonly __typename?: 'course_videos_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_videos" */
export type Course_Videos_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_videos". All fields are combined with a logical 'AND'. */
export type Course_Videos_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Videos_Bool_Exp>>;
  readonly _not?: Maybe<Course_Videos_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Videos_Bool_Exp>>;
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Bool_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
  readonly source?: Maybe<String_Comparison_Exp>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly video_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_videos" */
export enum Course_Videos_Constraint {
  /** unique or primary key constraint */
  CourseVideosPkey = 'course_videos_pkey'
}

/** input type for incrementing numeric columns in table "course_videos" */
export type Course_Videos_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "course_videos" */
export type Course_Videos_Insert_Input = {
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Obj_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly video_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Videos_Max_Fields = {
  readonly __typename?: 'course_videos_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly video_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_videos" */
export type Course_Videos_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly source?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly video_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Videos_Min_Fields = {
  readonly __typename?: 'course_videos_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly video_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_videos" */
export type Course_Videos_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly source?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly video_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "course_videos" */
export type Course_Videos_Mutation_Response = {
  readonly __typename?: 'course_videos_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Videos>;
};

/** on conflict condition type for table "course_videos" */
export type Course_Videos_On_Conflict = {
  readonly constraint: Course_Videos_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Videos_Update_Column>;
  readonly where?: Maybe<Course_Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "course_videos". */
export type Course_Videos_Order_By = {
  readonly course_videos_status?: Maybe<Course_Videos_Statuses_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly source?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly video_url?: Maybe<Order_By>;
};

/** primary key columns input for table: course_videos */
export type Course_Videos_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "course_videos" */
export enum Course_Videos_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "course_videos" */
export type Course_Videos_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Course_Videos_Statuses_Enum>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly video_url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "course_videos_statuses" */
export type Course_Videos_Statuses = {
  readonly __typename?: 'course_videos_statuses';
  /** An array relationship */
  readonly bunny_uploads: ReadonlyArray<Bunny_Upload>;
  /** An aggregate relationship */
  readonly bunny_uploads_aggregate: Bunny_Upload_Aggregate;
  /** An array relationship */
  readonly course_videos: ReadonlyArray<Course_Videos>;
  /** An aggregate relationship */
  readonly course_videos_aggregate: Course_Videos_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "course_videos_statuses" */
export type Course_Videos_StatusesBunny_UploadsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


/** columns and relationships of "course_videos_statuses" */
export type Course_Videos_StatusesBunny_Uploads_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


/** columns and relationships of "course_videos_statuses" */
export type Course_Videos_StatusesCourse_VideosArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


/** columns and relationships of "course_videos_statuses" */
export type Course_Videos_StatusesCourse_Videos_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};

/** aggregated selection of "course_videos_statuses" */
export type Course_Videos_Statuses_Aggregate = {
  readonly __typename?: 'course_videos_statuses_aggregate';
  readonly aggregate?: Maybe<Course_Videos_Statuses_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Course_Videos_Statuses>;
};

/** aggregate fields of "course_videos_statuses" */
export type Course_Videos_Statuses_Aggregate_Fields = {
  readonly __typename?: 'course_videos_statuses_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Course_Videos_Statuses_Max_Fields>;
  readonly min?: Maybe<Course_Videos_Statuses_Min_Fields>;
};


/** aggregate fields of "course_videos_statuses" */
export type Course_Videos_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Course_Videos_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_videos_statuses". All fields are combined with a logical 'AND'. */
export type Course_Videos_Statuses_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Course_Videos_Statuses_Bool_Exp>>;
  readonly _not?: Maybe<Course_Videos_Statuses_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Course_Videos_Statuses_Bool_Exp>>;
  readonly bunny_uploads?: Maybe<Bunny_Upload_Bool_Exp>;
  readonly course_videos?: Maybe<Course_Videos_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_videos_statuses" */
export enum Course_Videos_Statuses_Constraint {
  /** unique or primary key constraint */
  CourseVideosStatusPkey = 'course_videos_status_pkey',
  /** unique or primary key constraint */
  CourseVideosStatusesValueKey = 'course_videos_statuses_value_key'
}

export enum Course_Videos_Statuses_Enum {
  Downloading = 'downloading',
  Hacked = 'hacked',
  Packaging = 'packaging',
  Unhacked = 'unhacked',
  Uploading = 'uploading'
}

/** Boolean expression to compare columns of type "course_videos_statuses_enum". All fields are combined with logical 'AND'. */
export type Course_Videos_Statuses_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Course_Videos_Statuses_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Course_Videos_Statuses_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Course_Videos_Statuses_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Course_Videos_Statuses_Enum>>;
};

/** input type for inserting data into table "course_videos_statuses" */
export type Course_Videos_Statuses_Insert_Input = {
  readonly bunny_uploads?: Maybe<Bunny_Upload_Arr_Rel_Insert_Input>;
  readonly course_videos?: Maybe<Course_Videos_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Videos_Statuses_Max_Fields = {
  readonly __typename?: 'course_videos_statuses_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Course_Videos_Statuses_Min_Fields = {
  readonly __typename?: 'course_videos_statuses_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_videos_statuses" */
export type Course_Videos_Statuses_Mutation_Response = {
  readonly __typename?: 'course_videos_statuses_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Course_Videos_Statuses>;
};

/** input type for inserting object relation for remote table "course_videos_statuses" */
export type Course_Videos_Statuses_Obj_Rel_Insert_Input = {
  readonly data: Course_Videos_Statuses_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Course_Videos_Statuses_On_Conflict>;
};

/** on conflict condition type for table "course_videos_statuses" */
export type Course_Videos_Statuses_On_Conflict = {
  readonly constraint: Course_Videos_Statuses_Constraint;
  readonly update_columns?: ReadonlyArray<Course_Videos_Statuses_Update_Column>;
  readonly where?: Maybe<Course_Videos_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "course_videos_statuses". */
export type Course_Videos_Statuses_Order_By = {
  readonly bunny_uploads_aggregate?: Maybe<Bunny_Upload_Aggregate_Order_By>;
  readonly course_videos_aggregate?: Maybe<Course_Videos_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: course_videos_statuses */
export type Course_Videos_Statuses_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "course_videos_statuses" */
export enum Course_Videos_Statuses_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "course_videos_statuses" */
export type Course_Videos_Statuses_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "course_videos_statuses" */
export enum Course_Videos_Statuses_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Course_Videos_Stddev_Fields = {
  readonly __typename?: 'course_videos_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_videos" */
export type Course_Videos_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Videos_Stddev_Pop_Fields = {
  readonly __typename?: 'course_videos_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_videos" */
export type Course_Videos_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Videos_Stddev_Samp_Fields = {
  readonly __typename?: 'course_videos_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_videos" */
export type Course_Videos_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Videos_Sum_Fields = {
  readonly __typename?: 'course_videos_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "course_videos" */
export type Course_Videos_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "course_videos" */
export enum Course_Videos_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoUrl = 'video_url'
}

/** aggregate var_pop on columns */
export type Course_Videos_Var_Pop_Fields = {
  readonly __typename?: 'course_videos_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_videos" */
export type Course_Videos_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Videos_Var_Samp_Fields = {
  readonly __typename?: 'course_videos_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_videos" */
export type Course_Videos_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Videos_Variance_Fields = {
  readonly __typename?: 'course_videos_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_videos" */
export type Course_Videos_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "courses" */
export type Courses = {
  readonly __typename?: 'courses';
  readonly community_description?: Maybe<Scalars['String']>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  readonly courseSellTypeByCourseSellType: Course_Sell_Types;
  /** An array relationship */
  readonly course_certificates: ReadonlyArray<Course_Certificates>;
  /** An aggregate relationship */
  readonly course_certificates_aggregate: Course_Certificates_Aggregate;
  /** An array relationship */
  readonly course_discounts: ReadonlyArray<Course_Discounts>;
  /** An aggregate relationship */
  readonly course_discounts_aggregate: Course_Discounts_Aggregate;
  readonly course_display_photo_uuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly course_gains: ReadonlyArray<Course_Gains>;
  /** An aggregate relationship */
  readonly course_gains_aggregate: Course_Gains_Aggregate;
  /** An array relationship */
  readonly course_instructors: ReadonlyArray<Course_Instructors>;
  /** An aggregate relationship */
  readonly course_instructors_aggregate: Course_Instructors_Aggregate;
  /** An array relationship */
  readonly course_invites: ReadonlyArray<Course_Invites>;
  /** An aggregate relationship */
  readonly course_invites_aggregate: Course_Invites_Aggregate;
  /** An array relationship */
  readonly course_lecturers: ReadonlyArray<Course_Lecturers>;
  /** An aggregate relationship */
  readonly course_lecturers_aggregate: Course_Lecturers_Aggregate;
  /** An array relationship */
  readonly course_modules: ReadonlyArray<Course_Modules>;
  /** An aggregate relationship */
  readonly course_modules_aggregate: Course_Modules_Aggregate;
  /** An array relationship */
  readonly course_objectives: ReadonlyArray<Course_Objectives>;
  /** An aggregate relationship */
  readonly course_objectives_aggregate: Course_Objectives_Aggregate;
  /** An array relationship */
  readonly course_orders: ReadonlyArray<Course_Order>;
  /** An aggregate relationship */
  readonly course_orders_aggregate: Course_Order_Aggregate;
  readonly course_preview_video_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly course_reminders: ReadonlyArray<Course_Reminders>;
  /** An aggregate relationship */
  readonly course_reminders_aggregate: Course_Reminders_Aggregate;
  /** An array relationship */
  readonly course_requirements: ReadonlyArray<Course_Requirements>;
  /** An aggregate relationship */
  readonly course_requirements_aggregate: Course_Requirements_Aggregate;
  /** An array relationship */
  readonly course_rewards: ReadonlyArray<Course_Rewards>;
  /** An aggregate relationship */
  readonly course_rewards_aggregate: Course_Rewards_Aggregate;
  readonly course_sell_type: Course_Sell_Types_Enum;
  /** An array relationship */
  readonly course_students: ReadonlyArray<Course_Students>;
  /** An aggregate relationship */
  readonly course_students_aggregate: Course_Students_Aggregate;
  readonly created_at: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly display_students: Scalars['Boolean'];
  readonly id: Scalars['bigint'];
  readonly is_archived: Scalars['Boolean'];
  readonly is_pinned: Scalars['Boolean'];
  readonly is_private: Scalars['Boolean'];
  readonly lyon_community?: Maybe<Scalars['String']>;
  readonly payment_link?: Maybe<Scalars['String']>;
  readonly prc_accreditation_number?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly provider_id: Scalars['String'];
  readonly publish_status: Scalars['Int'];
  readonly require_sequential_progress: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
};


/** columns and relationships of "courses" */
export type CoursesCourse_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_DiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Discounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_GainsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Gains_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_InstructorsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Instructors_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_InvitesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Invites_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_LecturersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Lecturers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_ObjectivesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Objectives_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_OrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Orders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_RemindersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Reminders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_RequirementsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Requirements_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};

/** aggregated selection of "courses" */
export type Courses_Aggregate = {
  readonly __typename?: 'courses_aggregate';
  readonly aggregate?: Maybe<Courses_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Courses>;
};

/** aggregate fields of "courses" */
export type Courses_Aggregate_Fields = {
  readonly __typename?: 'courses_aggregate_fields';
  readonly avg?: Maybe<Courses_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Courses_Max_Fields>;
  readonly min?: Maybe<Courses_Min_Fields>;
  readonly stddev?: Maybe<Courses_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Courses_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Courses_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Courses_Sum_Fields>;
  readonly var_pop?: Maybe<Courses_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Courses_Var_Samp_Fields>;
  readonly variance?: Maybe<Courses_Variance_Fields>;
};


/** aggregate fields of "courses" */
export type Courses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses" */
export type Courses_Aggregate_Order_By = {
  readonly avg?: Maybe<Courses_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Courses_Max_Order_By>;
  readonly min?: Maybe<Courses_Min_Order_By>;
  readonly stddev?: Maybe<Courses_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Courses_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Courses_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Courses_Sum_Order_By>;
  readonly var_pop?: Maybe<Courses_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Courses_Var_Samp_Order_By>;
  readonly variance?: Maybe<Courses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "courses" */
export type Courses_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Courses_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Courses_On_Conflict>;
};

/** aggregate avg on columns */
export type Courses_Avg_Fields = {
  readonly __typename?: 'courses_avg_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "courses" */
export type Courses_Avg_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export type Courses_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Courses_Bool_Exp>>;
  readonly _not?: Maybe<Courses_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Courses_Bool_Exp>>;
  readonly community_description?: Maybe<String_Comparison_Exp>;
  readonly consumable_period?: Maybe<Numeric_Comparison_Exp>;
  readonly courseSellTypeByCourseSellType?: Maybe<Course_Sell_Types_Bool_Exp>;
  readonly course_certificates?: Maybe<Course_Certificates_Bool_Exp>;
  readonly course_discounts?: Maybe<Course_Discounts_Bool_Exp>;
  readonly course_display_photo_uuid?: Maybe<String_Comparison_Exp>;
  readonly course_gains?: Maybe<Course_Gains_Bool_Exp>;
  readonly course_instructors?: Maybe<Course_Instructors_Bool_Exp>;
  readonly course_invites?: Maybe<Course_Invites_Bool_Exp>;
  readonly course_lecturers?: Maybe<Course_Lecturers_Bool_Exp>;
  readonly course_modules?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_objectives?: Maybe<Course_Objectives_Bool_Exp>;
  readonly course_orders?: Maybe<Course_Order_Bool_Exp>;
  readonly course_preview_video_url?: Maybe<String_Comparison_Exp>;
  readonly course_reminders?: Maybe<Course_Reminders_Bool_Exp>;
  readonly course_requirements?: Maybe<Course_Requirements_Bool_Exp>;
  readonly course_rewards?: Maybe<Course_Rewards_Bool_Exp>;
  readonly course_sell_type?: Maybe<Course_Sell_Types_Enum_Comparison_Exp>;
  readonly course_students?: Maybe<Course_Students_Bool_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly display_students?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_archived?: Maybe<Boolean_Comparison_Exp>;
  readonly is_pinned?: Maybe<Boolean_Comparison_Exp>;
  readonly is_private?: Maybe<Boolean_Comparison_Exp>;
  readonly lyon_community?: Maybe<String_Comparison_Exp>;
  readonly payment_link?: Maybe<String_Comparison_Exp>;
  readonly prc_accreditation_number?: Maybe<String_Comparison_Exp>;
  readonly price?: Maybe<Numeric_Comparison_Exp>;
  readonly provider_id?: Maybe<String_Comparison_Exp>;
  readonly publish_status?: Maybe<Int_Comparison_Exp>;
  readonly require_sequential_progress?: Maybe<Boolean_Comparison_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "courses" */
export enum Courses_Constraint {
  /** unique or primary key constraint */
  CoursesPkey = 'courses_pkey'
}

/** input type for incrementing numeric columns in table "courses" */
export type Courses_Inc_Input = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "courses" */
export type Courses_Insert_Input = {
  readonly community_description?: Maybe<Scalars['String']>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly courseSellTypeByCourseSellType?: Maybe<Course_Sell_Types_Obj_Rel_Insert_Input>;
  readonly course_certificates?: Maybe<Course_Certificates_Arr_Rel_Insert_Input>;
  readonly course_discounts?: Maybe<Course_Discounts_Arr_Rel_Insert_Input>;
  readonly course_display_photo_uuid?: Maybe<Scalars['String']>;
  readonly course_gains?: Maybe<Course_Gains_Arr_Rel_Insert_Input>;
  readonly course_instructors?: Maybe<Course_Instructors_Arr_Rel_Insert_Input>;
  readonly course_invites?: Maybe<Course_Invites_Arr_Rel_Insert_Input>;
  readonly course_lecturers?: Maybe<Course_Lecturers_Arr_Rel_Insert_Input>;
  readonly course_modules?: Maybe<Course_Modules_Arr_Rel_Insert_Input>;
  readonly course_objectives?: Maybe<Course_Objectives_Arr_Rel_Insert_Input>;
  readonly course_orders?: Maybe<Course_Order_Arr_Rel_Insert_Input>;
  readonly course_preview_video_url?: Maybe<Scalars['String']>;
  readonly course_reminders?: Maybe<Course_Reminders_Arr_Rel_Insert_Input>;
  readonly course_requirements?: Maybe<Course_Requirements_Arr_Rel_Insert_Input>;
  readonly course_rewards?: Maybe<Course_Rewards_Arr_Rel_Insert_Input>;
  readonly course_sell_type?: Maybe<Course_Sell_Types_Enum>;
  readonly course_students?: Maybe<Course_Students_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly display_students?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_archived?: Maybe<Scalars['Boolean']>;
  readonly is_pinned?: Maybe<Scalars['Boolean']>;
  readonly is_private?: Maybe<Scalars['Boolean']>;
  readonly lyon_community?: Maybe<Scalars['String']>;
  readonly payment_link?: Maybe<Scalars['String']>;
  readonly prc_accreditation_number?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
  readonly require_sequential_progress?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Courses_Max_Fields = {
  readonly __typename?: 'courses_max_fields';
  readonly community_description?: Maybe<Scalars['String']>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_display_photo_uuid?: Maybe<Scalars['String']>;
  readonly course_preview_video_url?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly lyon_community?: Maybe<Scalars['String']>;
  readonly payment_link?: Maybe<Scalars['String']>;
  readonly prc_accreditation_number?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "courses" */
export type Courses_Max_Order_By = {
  readonly community_description?: Maybe<Order_By>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_display_photo_uuid?: Maybe<Order_By>;
  readonly course_preview_video_url?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly lyon_community?: Maybe<Order_By>;
  readonly payment_link?: Maybe<Order_By>;
  readonly prc_accreditation_number?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Courses_Min_Fields = {
  readonly __typename?: 'courses_min_fields';
  readonly community_description?: Maybe<Scalars['String']>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_display_photo_uuid?: Maybe<Scalars['String']>;
  readonly course_preview_video_url?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly lyon_community?: Maybe<Scalars['String']>;
  readonly payment_link?: Maybe<Scalars['String']>;
  readonly prc_accreditation_number?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "courses" */
export type Courses_Min_Order_By = {
  readonly community_description?: Maybe<Order_By>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly course_display_photo_uuid?: Maybe<Order_By>;
  readonly course_preview_video_url?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly lyon_community?: Maybe<Order_By>;
  readonly payment_link?: Maybe<Order_By>;
  readonly prc_accreditation_number?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "courses" */
export type Courses_Mutation_Response = {
  readonly __typename?: 'courses_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Courses>;
};

/** input type for inserting object relation for remote table "courses" */
export type Courses_Obj_Rel_Insert_Input = {
  readonly data: Courses_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Courses_On_Conflict>;
};

/** on conflict condition type for table "courses" */
export type Courses_On_Conflict = {
  readonly constraint: Courses_Constraint;
  readonly update_columns?: ReadonlyArray<Courses_Update_Column>;
  readonly where?: Maybe<Courses_Bool_Exp>;
};

/** Ordering options when selecting data from "courses". */
export type Courses_Order_By = {
  readonly community_description?: Maybe<Order_By>;
  readonly consumable_period?: Maybe<Order_By>;
  readonly courseSellTypeByCourseSellType?: Maybe<Course_Sell_Types_Order_By>;
  readonly course_certificates_aggregate?: Maybe<Course_Certificates_Aggregate_Order_By>;
  readonly course_discounts_aggregate?: Maybe<Course_Discounts_Aggregate_Order_By>;
  readonly course_display_photo_uuid?: Maybe<Order_By>;
  readonly course_gains_aggregate?: Maybe<Course_Gains_Aggregate_Order_By>;
  readonly course_instructors_aggregate?: Maybe<Course_Instructors_Aggregate_Order_By>;
  readonly course_invites_aggregate?: Maybe<Course_Invites_Aggregate_Order_By>;
  readonly course_lecturers_aggregate?: Maybe<Course_Lecturers_Aggregate_Order_By>;
  readonly course_modules_aggregate?: Maybe<Course_Modules_Aggregate_Order_By>;
  readonly course_objectives_aggregate?: Maybe<Course_Objectives_Aggregate_Order_By>;
  readonly course_orders_aggregate?: Maybe<Course_Order_Aggregate_Order_By>;
  readonly course_preview_video_url?: Maybe<Order_By>;
  readonly course_reminders_aggregate?: Maybe<Course_Reminders_Aggregate_Order_By>;
  readonly course_requirements_aggregate?: Maybe<Course_Requirements_Aggregate_Order_By>;
  readonly course_rewards_aggregate?: Maybe<Course_Rewards_Aggregate_Order_By>;
  readonly course_sell_type?: Maybe<Order_By>;
  readonly course_students_aggregate?: Maybe<Course_Students_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly display_students?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_archived?: Maybe<Order_By>;
  readonly is_pinned?: Maybe<Order_By>;
  readonly is_private?: Maybe<Order_By>;
  readonly lyon_community?: Maybe<Order_By>;
  readonly payment_link?: Maybe<Order_By>;
  readonly prc_accreditation_number?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
  readonly require_sequential_progress?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: courses */
export type Courses_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "courses" */
export enum Courses_Select_Column {
  /** column name */
  CommunityDescription = 'community_description',
  /** column name */
  ConsumablePeriod = 'consumable_period',
  /** column name */
  CourseDisplayPhotoUuid = 'course_display_photo_uuid',
  /** column name */
  CoursePreviewVideoUrl = 'course_preview_video_url',
  /** column name */
  CourseSellType = 'course_sell_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayStudents = 'display_students',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  IsPrivate = 'is_private',
  /** column name */
  LyonCommunity = 'lyon_community',
  /** column name */
  PaymentLink = 'payment_link',
  /** column name */
  PrcAccreditationNumber = 'prc_accreditation_number',
  /** column name */
  Price = 'price',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  PublishStatus = 'publish_status',
  /** column name */
  RequireSequentialProgress = 'require_sequential_progress',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "courses" */
export type Courses_Set_Input = {
  readonly community_description?: Maybe<Scalars['String']>;
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly course_display_photo_uuid?: Maybe<Scalars['String']>;
  readonly course_preview_video_url?: Maybe<Scalars['String']>;
  readonly course_sell_type?: Maybe<Course_Sell_Types_Enum>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly display_students?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_archived?: Maybe<Scalars['Boolean']>;
  readonly is_pinned?: Maybe<Scalars['Boolean']>;
  readonly is_private?: Maybe<Scalars['Boolean']>;
  readonly lyon_community?: Maybe<Scalars['String']>;
  readonly payment_link?: Maybe<Scalars['String']>;
  readonly prc_accreditation_number?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly provider_id?: Maybe<Scalars['String']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
  readonly require_sequential_progress?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Courses_Stddev_Fields = {
  readonly __typename?: 'courses_stddev_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "courses" */
export type Courses_Stddev_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Courses_Stddev_Pop_Fields = {
  readonly __typename?: 'courses_stddev_pop_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "courses" */
export type Courses_Stddev_Pop_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Courses_Stddev_Samp_Fields = {
  readonly __typename?: 'courses_stddev_samp_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "courses" */
export type Courses_Stddev_Samp_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Courses_Sum_Fields = {
  readonly __typename?: 'courses_sum_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['numeric']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly price?: Maybe<Scalars['numeric']>;
  readonly publish_status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "courses" */
export type Courses_Sum_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** update columns of table "courses" */
export enum Courses_Update_Column {
  /** column name */
  CommunityDescription = 'community_description',
  /** column name */
  ConsumablePeriod = 'consumable_period',
  /** column name */
  CourseDisplayPhotoUuid = 'course_display_photo_uuid',
  /** column name */
  CoursePreviewVideoUrl = 'course_preview_video_url',
  /** column name */
  CourseSellType = 'course_sell_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayStudents = 'display_students',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  IsPrivate = 'is_private',
  /** column name */
  LyonCommunity = 'lyon_community',
  /** column name */
  PaymentLink = 'payment_link',
  /** column name */
  PrcAccreditationNumber = 'prc_accreditation_number',
  /** column name */
  Price = 'price',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  PublishStatus = 'publish_status',
  /** column name */
  RequireSequentialProgress = 'require_sequential_progress',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Courses_Var_Pop_Fields = {
  readonly __typename?: 'courses_var_pop_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "courses" */
export type Courses_Var_Pop_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Courses_Var_Samp_Fields = {
  readonly __typename?: 'courses_var_samp_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "courses" */
export type Courses_Var_Samp_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Courses_Variance_Fields = {
  readonly __typename?: 'courses_variance_fields';
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly publish_status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "courses" */
export type Courses_Variance_Order_By = {
  /** Measured in seconds */
  readonly consumable_period?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly publish_status?: Maybe<Order_By>;
};

/** columns and relationships of "cpd_unit_rewards" */
export type Cpd_Unit_Rewards = {
  readonly __typename?: 'cpd_unit_rewards';
  /** An object relationship */
  readonly course_reward?: Maybe<Course_Rewards>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id: Scalars['bigint'];
  readonly industry: Scalars['String'];
  readonly is_self_directed: Scalars['Boolean'];
  readonly units: Scalars['Int'];
};

/** aggregated selection of "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Aggregate = {
  readonly __typename?: 'cpd_unit_rewards_aggregate';
  readonly aggregate?: Maybe<Cpd_Unit_Rewards_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Cpd_Unit_Rewards>;
};

/** aggregate fields of "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Aggregate_Fields = {
  readonly __typename?: 'cpd_unit_rewards_aggregate_fields';
  readonly avg?: Maybe<Cpd_Unit_Rewards_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Cpd_Unit_Rewards_Max_Fields>;
  readonly min?: Maybe<Cpd_Unit_Rewards_Min_Fields>;
  readonly stddev?: Maybe<Cpd_Unit_Rewards_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Cpd_Unit_Rewards_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Cpd_Unit_Rewards_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Cpd_Unit_Rewards_Sum_Fields>;
  readonly var_pop?: Maybe<Cpd_Unit_Rewards_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Cpd_Unit_Rewards_Var_Samp_Fields>;
  readonly variance?: Maybe<Cpd_Unit_Rewards_Variance_Fields>;
};


/** aggregate fields of "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Aggregate_Order_By = {
  readonly avg?: Maybe<Cpd_Unit_Rewards_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Cpd_Unit_Rewards_Max_Order_By>;
  readonly min?: Maybe<Cpd_Unit_Rewards_Min_Order_By>;
  readonly stddev?: Maybe<Cpd_Unit_Rewards_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Cpd_Unit_Rewards_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Cpd_Unit_Rewards_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Cpd_Unit_Rewards_Sum_Order_By>;
  readonly var_pop?: Maybe<Cpd_Unit_Rewards_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Cpd_Unit_Rewards_Var_Samp_Order_By>;
  readonly variance?: Maybe<Cpd_Unit_Rewards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Cpd_Unit_Rewards_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Cpd_Unit_Rewards_On_Conflict>;
};

/** aggregate avg on columns */
export type Cpd_Unit_Rewards_Avg_Fields = {
  readonly __typename?: 'cpd_unit_rewards_avg_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Avg_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cpd_unit_rewards". All fields are combined with a logical 'AND'. */
export type Cpd_Unit_Rewards_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Bool_Exp>>;
  readonly _not?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Bool_Exp>>;
  readonly course_reward?: Maybe<Course_Rewards_Bool_Exp>;
  readonly course_reward_id?: Maybe<Bigint_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly industry?: Maybe<String_Comparison_Exp>;
  readonly is_self_directed?: Maybe<Boolean_Comparison_Exp>;
  readonly units?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "cpd_unit_rewards" */
export enum Cpd_Unit_Rewards_Constraint {
  /** unique or primary key constraint */
  CpdUnitRewardsPkey = 'cpd_unit_rewards_pkey'
}

/** input type for incrementing numeric columns in table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Inc_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Insert_Input = {
  readonly course_reward?: Maybe<Course_Rewards_Obj_Rel_Insert_Input>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly is_self_directed?: Maybe<Scalars['Boolean']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Cpd_Unit_Rewards_Max_Fields = {
  readonly __typename?: 'cpd_unit_rewards_max_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Max_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industry?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cpd_Unit_Rewards_Min_Fields = {
  readonly __typename?: 'cpd_unit_rewards_min_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Min_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industry?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** response of any mutation on the table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Mutation_Response = {
  readonly __typename?: 'cpd_unit_rewards_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Cpd_Unit_Rewards>;
};

/** on conflict condition type for table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_On_Conflict = {
  readonly constraint: Cpd_Unit_Rewards_Constraint;
  readonly update_columns?: ReadonlyArray<Cpd_Unit_Rewards_Update_Column>;
  readonly where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "cpd_unit_rewards". */
export type Cpd_Unit_Rewards_Order_By = {
  readonly course_reward?: Maybe<Course_Rewards_Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industry?: Maybe<Order_By>;
  readonly is_self_directed?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** primary key columns input for table: cpd_unit_rewards */
export type Cpd_Unit_Rewards_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "cpd_unit_rewards" */
export enum Cpd_Unit_Rewards_Select_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IsSelfDirected = 'is_self_directed',
  /** column name */
  Units = 'units'
}

/** input type for updating data in table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Set_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly industry?: Maybe<Scalars['String']>;
  readonly is_self_directed?: Maybe<Scalars['Boolean']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Cpd_Unit_Rewards_Stddev_Fields = {
  readonly __typename?: 'cpd_unit_rewards_stddev_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Stddev_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cpd_Unit_Rewards_Stddev_Pop_Fields = {
  readonly __typename?: 'cpd_unit_rewards_stddev_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Stddev_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cpd_Unit_Rewards_Stddev_Samp_Fields = {
  readonly __typename?: 'cpd_unit_rewards_stddev_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Stddev_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cpd_Unit_Rewards_Sum_Fields = {
  readonly __typename?: 'cpd_unit_rewards_sum_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Sum_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** update columns of table "cpd_unit_rewards" */
export enum Cpd_Unit_Rewards_Update_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IsSelfDirected = 'is_self_directed',
  /** column name */
  Units = 'units'
}

/** aggregate var_pop on columns */
export type Cpd_Unit_Rewards_Var_Pop_Fields = {
  readonly __typename?: 'cpd_unit_rewards_var_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Var_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cpd_Unit_Rewards_Var_Samp_Fields = {
  readonly __typename?: 'cpd_unit_rewards_var_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Var_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cpd_Unit_Rewards_Variance_Fields = {
  readonly __typename?: 'cpd_unit_rewards_variance_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cpd_unit_rewards" */
export type Cpd_Unit_Rewards_Variance_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** columns and relationships of "cpd_units_reward" */
export type Cpd_Units_Reward = {
  readonly __typename?: 'cpd_units_reward';
  /** An object relationship */
  readonly event?: Maybe<Event>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id: Scalars['uuid'];
  /** An array relationship */
  readonly industries: ReadonlyArray<Industry>;
  /** An aggregate relationship */
  readonly industries_aggregate: Industry_Aggregate;
  /** An object relationship */
  readonly industry?: Maybe<Industry>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly isSelfDirected: Scalars['Boolean'];
  readonly rewardCertificateType: Scalars['String'];
  /** An array relationship */
  readonly reward_certificates: ReadonlyArray<Reward_Certificate>;
  /** An aggregate relationship */
  readonly reward_certificates_aggregate: Reward_Certificate_Aggregate;
  readonly units: Scalars['Int'];
};


/** columns and relationships of "cpd_units_reward" */
export type Cpd_Units_RewardIndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** columns and relationships of "cpd_units_reward" */
export type Cpd_Units_RewardIndustries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** columns and relationships of "cpd_units_reward" */
export type Cpd_Units_RewardReward_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "cpd_units_reward" */
export type Cpd_Units_RewardReward_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};

/** aggregated selection of "cpd_units_reward" */
export type Cpd_Units_Reward_Aggregate = {
  readonly __typename?: 'cpd_units_reward_aggregate';
  readonly aggregate?: Maybe<Cpd_Units_Reward_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Cpd_Units_Reward>;
};

/** aggregate fields of "cpd_units_reward" */
export type Cpd_Units_Reward_Aggregate_Fields = {
  readonly __typename?: 'cpd_units_reward_aggregate_fields';
  readonly avg?: Maybe<Cpd_Units_Reward_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Cpd_Units_Reward_Max_Fields>;
  readonly min?: Maybe<Cpd_Units_Reward_Min_Fields>;
  readonly stddev?: Maybe<Cpd_Units_Reward_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Cpd_Units_Reward_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Cpd_Units_Reward_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Cpd_Units_Reward_Sum_Fields>;
  readonly var_pop?: Maybe<Cpd_Units_Reward_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Cpd_Units_Reward_Var_Samp_Fields>;
  readonly variance?: Maybe<Cpd_Units_Reward_Variance_Fields>;
};


/** aggregate fields of "cpd_units_reward" */
export type Cpd_Units_Reward_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cpd_units_reward" */
export type Cpd_Units_Reward_Aggregate_Order_By = {
  readonly avg?: Maybe<Cpd_Units_Reward_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Cpd_Units_Reward_Max_Order_By>;
  readonly min?: Maybe<Cpd_Units_Reward_Min_Order_By>;
  readonly stddev?: Maybe<Cpd_Units_Reward_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Cpd_Units_Reward_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Cpd_Units_Reward_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Cpd_Units_Reward_Sum_Order_By>;
  readonly var_pop?: Maybe<Cpd_Units_Reward_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Cpd_Units_Reward_Var_Samp_Order_By>;
  readonly variance?: Maybe<Cpd_Units_Reward_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cpd_units_reward" */
export type Cpd_Units_Reward_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Cpd_Units_Reward_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Cpd_Units_Reward_On_Conflict>;
};

/** aggregate avg on columns */
export type Cpd_Units_Reward_Avg_Fields = {
  readonly __typename?: 'cpd_units_reward_avg_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Avg_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cpd_units_reward". All fields are combined with a logical 'AND'. */
export type Cpd_Units_Reward_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Cpd_Units_Reward_Bool_Exp>>;
  readonly _not?: Maybe<Cpd_Units_Reward_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Cpd_Units_Reward_Bool_Exp>>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly industries?: Maybe<Industry_Bool_Exp>;
  readonly industry?: Maybe<Industry_Bool_Exp>;
  readonly industryName?: Maybe<String_Comparison_Exp>;
  readonly isSelfDirected?: Maybe<Boolean_Comparison_Exp>;
  readonly rewardCertificateType?: Maybe<String_Comparison_Exp>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly units?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "cpd_units_reward" */
export enum Cpd_Units_Reward_Constraint {
  /** unique or primary key constraint */
  Pk_4f4cb78b2ff620fae9335317676 = 'PK_4f4cb78b2ff620fae9335317676'
}

/** input type for incrementing numeric columns in table "cpd_units_reward" */
export type Cpd_Units_Reward_Inc_Input = {
  readonly units?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cpd_units_reward" */
export type Cpd_Units_Reward_Insert_Input = {
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly industries?: Maybe<Industry_Arr_Rel_Insert_Input>;
  readonly industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly isSelfDirected?: Maybe<Scalars['Boolean']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Arr_Rel_Insert_Input>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Cpd_Units_Reward_Max_Fields = {
  readonly __typename?: 'cpd_units_reward_max_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Max_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cpd_Units_Reward_Min_Fields = {
  readonly __typename?: 'cpd_units_reward_min_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Min_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** response of any mutation on the table "cpd_units_reward" */
export type Cpd_Units_Reward_Mutation_Response = {
  readonly __typename?: 'cpd_units_reward_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Cpd_Units_Reward>;
};

/** input type for inserting object relation for remote table "cpd_units_reward" */
export type Cpd_Units_Reward_Obj_Rel_Insert_Input = {
  readonly data: Cpd_Units_Reward_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Cpd_Units_Reward_On_Conflict>;
};

/** on conflict condition type for table "cpd_units_reward" */
export type Cpd_Units_Reward_On_Conflict = {
  readonly constraint: Cpd_Units_Reward_Constraint;
  readonly update_columns?: ReadonlyArray<Cpd_Units_Reward_Update_Column>;
  readonly where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};

/** Ordering options when selecting data from "cpd_units_reward". */
export type Cpd_Units_Reward_Order_By = {
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly industries_aggregate?: Maybe<Industry_Aggregate_Order_By>;
  readonly industry?: Maybe<Industry_Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly isSelfDirected?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly reward_certificates_aggregate?: Maybe<Reward_Certificate_Aggregate_Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** primary key columns input for table: cpd_units_reward */
export type Cpd_Units_Reward_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "cpd_units_reward" */
export enum Cpd_Units_Reward_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  IsSelfDirected = 'isSelfDirected',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  Units = 'units'
}

/** input type for updating data in table "cpd_units_reward" */
export type Cpd_Units_Reward_Set_Input = {
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly isSelfDirected?: Maybe<Scalars['Boolean']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Cpd_Units_Reward_Stddev_Fields = {
  readonly __typename?: 'cpd_units_reward_stddev_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Stddev_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cpd_Units_Reward_Stddev_Pop_Fields = {
  readonly __typename?: 'cpd_units_reward_stddev_pop_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Stddev_Pop_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cpd_Units_Reward_Stddev_Samp_Fields = {
  readonly __typename?: 'cpd_units_reward_stddev_samp_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Stddev_Samp_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cpd_Units_Reward_Sum_Fields = {
  readonly __typename?: 'cpd_units_reward_sum_fields';
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Sum_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** update columns of table "cpd_units_reward" */
export enum Cpd_Units_Reward_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  IsSelfDirected = 'isSelfDirected',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  Units = 'units'
}

/** aggregate var_pop on columns */
export type Cpd_Units_Reward_Var_Pop_Fields = {
  readonly __typename?: 'cpd_units_reward_var_pop_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Var_Pop_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cpd_Units_Reward_Var_Samp_Fields = {
  readonly __typename?: 'cpd_units_reward_var_samp_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Var_Samp_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cpd_Units_Reward_Variance_Fields = {
  readonly __typename?: 'cpd_units_reward_variance_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cpd_units_reward" */
export type Cpd_Units_Reward_Variance_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** columns and relationships of "customer" */
export type Customer = {
  readonly __typename?: 'customer';
  readonly businessAddress: Scalars['String'];
  readonly contactNumber: Scalars['String'];
  readonly createdAt: Scalars['timestamp'];
  /** An array relationship */
  readonly discounts: ReadonlyArray<Discount>;
  /** An aggregate relationship */
  readonly discounts_aggregate: Discount_Aggregate;
  readonly email: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly name: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "customer" */
export type CustomerDiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


/** columns and relationships of "customer" */
export type CustomerDiscounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};

/** aggregated selection of "customer" */
export type Customer_Aggregate = {
  readonly __typename?: 'customer_aggregate';
  readonly aggregate?: Maybe<Customer_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Customer>;
};

/** aggregate fields of "customer" */
export type Customer_Aggregate_Fields = {
  readonly __typename?: 'customer_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Customer_Max_Fields>;
  readonly min?: Maybe<Customer_Min_Fields>;
};


/** aggregate fields of "customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Customer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Customer_Bool_Exp>>;
  readonly _not?: Maybe<Customer_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Customer_Bool_Exp>>;
  readonly businessAddress?: Maybe<String_Comparison_Exp>;
  readonly contactNumber?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly discounts?: Maybe<Discount_Bool_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint */
  PkA7a13f4cacb744524e44dfdad32 = 'PK_a7a13f4cacb744524e44dfdad32',
  /** unique or primary key constraint */
  UqAc1455877a69957f7466d5dc78e = 'UQ_ac1455877a69957f7466d5dc78e',
  /** unique or primary key constraint */
  UqFdb2f3ad8115da4c7718109a6eb = 'UQ_fdb2f3ad8115da4c7718109a6eb'
}

/** input type for inserting data into table "customer" */
export type Customer_Insert_Input = {
  readonly businessAddress?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly discounts?: Maybe<Discount_Arr_Rel_Insert_Input>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Customer_Max_Fields = {
  readonly __typename?: 'customer_max_fields';
  readonly businessAddress?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Customer_Min_Fields = {
  readonly __typename?: 'customer_min_fields';
  readonly businessAddress?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "customer" */
export type Customer_Mutation_Response = {
  readonly __typename?: 'customer_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Customer>;
};

/** input type for inserting object relation for remote table "customer" */
export type Customer_Obj_Rel_Insert_Input = {
  readonly data: Customer_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Customer_On_Conflict>;
};

/** on conflict condition type for table "customer" */
export type Customer_On_Conflict = {
  readonly constraint: Customer_Constraint;
  readonly update_columns?: ReadonlyArray<Customer_Update_Column>;
  readonly where?: Maybe<Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "customer". */
export type Customer_Order_By = {
  readonly businessAddress?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly discounts_aggregate?: Maybe<Discount_Aggregate_Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: customer */
export type Customer_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "customer" */
export enum Customer_Select_Column {
  /** column name */
  BusinessAddress = 'businessAddress',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "customer" */
export type Customer_Set_Input = {
  readonly businessAddress?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "customer" */
export enum Customer_Update_Column {
  /** column name */
  BusinessAddress = 'businessAddress',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['date']>;
  readonly _gt?: Maybe<Scalars['date']>;
  readonly _gte?: Maybe<Scalars['date']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['date']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['date']>;
  readonly _lte?: Maybe<Scalars['date']>;
  readonly _neq?: Maybe<Scalars['date']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['date']>>;
};

/** columns and relationships of "discount" */
export type Discount = {
  readonly __typename?: 'discount';
  readonly code: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly createdAt: Scalars['timestamp'];
  /** An object relationship */
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['uuid']>;
  readonly expirationDate?: Maybe<Scalars['timestamp']>;
  readonly id: Scalars['uuid'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Order>;
  /** An aggregate relationship */
  readonly orders_aggregate: Order_Aggregate;
  /** An object relationship */
  readonly product?: Maybe<Product>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly startDate: Scalars['timestamp'];
  readonly total: Scalars['Int'];
  readonly type: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
  readonly value: Scalars['Int'];
};


/** columns and relationships of "discount" */
export type DiscountOrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountOrders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};

/** aggregated selection of "discount" */
export type Discount_Aggregate = {
  readonly __typename?: 'discount_aggregate';
  readonly aggregate?: Maybe<Discount_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Discount>;
};

/** aggregate fields of "discount" */
export type Discount_Aggregate_Fields = {
  readonly __typename?: 'discount_aggregate_fields';
  readonly avg?: Maybe<Discount_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Discount_Max_Fields>;
  readonly min?: Maybe<Discount_Min_Fields>;
  readonly stddev?: Maybe<Discount_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Discount_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Discount_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Discount_Sum_Fields>;
  readonly var_pop?: Maybe<Discount_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Discount_Var_Samp_Fields>;
  readonly variance?: Maybe<Discount_Variance_Fields>;
};


/** aggregate fields of "discount" */
export type Discount_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "discount" */
export type Discount_Aggregate_Order_By = {
  readonly avg?: Maybe<Discount_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Discount_Max_Order_By>;
  readonly min?: Maybe<Discount_Min_Order_By>;
  readonly stddev?: Maybe<Discount_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Discount_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Discount_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Discount_Sum_Order_By>;
  readonly var_pop?: Maybe<Discount_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Discount_Var_Samp_Order_By>;
  readonly variance?: Maybe<Discount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "discount" */
export type Discount_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Discount_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Discount_On_Conflict>;
};

/** aggregate avg on columns */
export type Discount_Avg_Fields = {
  readonly __typename?: 'discount_avg_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "discount" */
export type Discount_Avg_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "discount". All fields are combined with a logical 'AND'. */
export type Discount_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Discount_Bool_Exp>>;
  readonly _not?: Maybe<Discount_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Discount_Bool_Exp>>;
  readonly code?: Maybe<String_Comparison_Exp>;
  readonly count?: Maybe<Int_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly customer?: Maybe<Customer_Bool_Exp>;
  readonly customerId?: Maybe<Uuid_Comparison_Exp>;
  readonly expirationDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly orders?: Maybe<Order_Bool_Exp>;
  readonly product?: Maybe<Product_Bool_Exp>;
  readonly productId?: Maybe<Uuid_Comparison_Exp>;
  readonly startDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly total?: Maybe<Int_Comparison_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly value?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "discount" */
export enum Discount_Constraint {
  /** unique or primary key constraint */
  PkD05d8712e429673e459e7f1cddb = 'PK_d05d8712e429673e459e7f1cddb'
}

/** input type for incrementing numeric columns in table "discount" */
export type Discount_Inc_Input = {
  readonly count?: Maybe<Scalars['Int']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "discount" */
export type Discount_Insert_Input = {
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  readonly customerId?: Maybe<Scalars['uuid']>;
  readonly expirationDate?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  readonly product?: Maybe<Product_Obj_Rel_Insert_Input>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly startDate?: Maybe<Scalars['timestamp']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Discount_Max_Fields = {
  readonly __typename?: 'discount_max_fields';
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['uuid']>;
  readonly expirationDate?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly startDate?: Maybe<Scalars['timestamp']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "discount" */
export type Discount_Max_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly expirationDate?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly startDate?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Discount_Min_Fields = {
  readonly __typename?: 'discount_min_fields';
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['uuid']>;
  readonly expirationDate?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly startDate?: Maybe<Scalars['timestamp']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "discount" */
export type Discount_Min_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly expirationDate?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly startDate?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** response of any mutation on the table "discount" */
export type Discount_Mutation_Response = {
  readonly __typename?: 'discount_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Discount>;
};

/** input type for inserting object relation for remote table "discount" */
export type Discount_Obj_Rel_Insert_Input = {
  readonly data: Discount_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Discount_On_Conflict>;
};

/** on conflict condition type for table "discount" */
export type Discount_On_Conflict = {
  readonly constraint: Discount_Constraint;
  readonly update_columns?: ReadonlyArray<Discount_Update_Column>;
  readonly where?: Maybe<Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "discount". */
export type Discount_Order_By = {
  readonly code?: Maybe<Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customer?: Maybe<Customer_Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly expirationDate?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  readonly product?: Maybe<Product_Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly startDate?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: discount */
export type Discount_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "discount" */
export enum Discount_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Total = 'total',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "discount" */
export type Discount_Set_Input = {
  readonly code?: Maybe<Scalars['String']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['uuid']>;
  readonly expirationDate?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly startDate?: Maybe<Scalars['timestamp']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Discount_Stddev_Fields = {
  readonly __typename?: 'discount_stddev_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "discount" */
export type Discount_Stddev_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Discount_Stddev_Pop_Fields = {
  readonly __typename?: 'discount_stddev_pop_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "discount" */
export type Discount_Stddev_Pop_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Discount_Stddev_Samp_Fields = {
  readonly __typename?: 'discount_stddev_samp_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "discount" */
export type Discount_Stddev_Samp_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Discount_Sum_Fields = {
  readonly __typename?: 'discount_sum_fields';
  readonly count?: Maybe<Scalars['Int']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "discount" */
export type Discount_Sum_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** update columns of table "discount" */
export enum Discount_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Total = 'total',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Discount_Var_Pop_Fields = {
  readonly __typename?: 'discount_var_pop_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "discount" */
export type Discount_Var_Pop_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Discount_Var_Samp_Fields = {
  readonly __typename?: 'discount_var_samp_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "discount" */
export type Discount_Var_Samp_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Discount_Variance_Fields = {
  readonly __typename?: 'discount_variance_fields';
  readonly count?: Maybe<Scalars['Float']>;
  readonly total?: Maybe<Scalars['Float']>;
  readonly value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "discount" */
export type Discount_Variance_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** columns and relationships of "email_notification_types" */
export type Email_Notification_Types = {
  readonly __typename?: 'email_notification_types';
  /** An array relationship */
  readonly email_notifications: ReadonlyArray<Email_Notifications>;
  /** An aggregate relationship */
  readonly email_notifications_aggregate: Email_Notifications_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "email_notification_types" */
export type Email_Notification_TypesEmail_NotificationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


/** columns and relationships of "email_notification_types" */
export type Email_Notification_TypesEmail_Notifications_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};

/** aggregated selection of "email_notification_types" */
export type Email_Notification_Types_Aggregate = {
  readonly __typename?: 'email_notification_types_aggregate';
  readonly aggregate?: Maybe<Email_Notification_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Email_Notification_Types>;
};

/** aggregate fields of "email_notification_types" */
export type Email_Notification_Types_Aggregate_Fields = {
  readonly __typename?: 'email_notification_types_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Email_Notification_Types_Max_Fields>;
  readonly min?: Maybe<Email_Notification_Types_Min_Fields>;
};


/** aggregate fields of "email_notification_types" */
export type Email_Notification_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Email_Notification_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "email_notification_types". All fields are combined with a logical 'AND'. */
export type Email_Notification_Types_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Email_Notification_Types_Bool_Exp>>;
  readonly _not?: Maybe<Email_Notification_Types_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Email_Notification_Types_Bool_Exp>>;
  readonly email_notifications?: Maybe<Email_Notifications_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_notification_types" */
export enum Email_Notification_Types_Constraint {
  /** unique or primary key constraint */
  EmailNotificationTypesPkey = 'email_notification_types_pkey'
}

export enum Email_Notification_Types_Enum {
  DeadlineReminder = 'deadline_reminder',
  ExpirationReminder = 'expiration_reminder',
  StartReminder = 'start_reminder'
}

/** Boolean expression to compare columns of type "email_notification_types_enum". All fields are combined with logical 'AND'. */
export type Email_Notification_Types_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Email_Notification_Types_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Email_Notification_Types_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Email_Notification_Types_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Email_Notification_Types_Enum>>;
};

/** input type for inserting data into table "email_notification_types" */
export type Email_Notification_Types_Insert_Input = {
  readonly email_notifications?: Maybe<Email_Notifications_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Notification_Types_Max_Fields = {
  readonly __typename?: 'email_notification_types_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Email_Notification_Types_Min_Fields = {
  readonly __typename?: 'email_notification_types_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "email_notification_types" */
export type Email_Notification_Types_Mutation_Response = {
  readonly __typename?: 'email_notification_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Email_Notification_Types>;
};

/** input type for inserting object relation for remote table "email_notification_types" */
export type Email_Notification_Types_Obj_Rel_Insert_Input = {
  readonly data: Email_Notification_Types_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Email_Notification_Types_On_Conflict>;
};

/** on conflict condition type for table "email_notification_types" */
export type Email_Notification_Types_On_Conflict = {
  readonly constraint: Email_Notification_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Email_Notification_Types_Update_Column>;
  readonly where?: Maybe<Email_Notification_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "email_notification_types". */
export type Email_Notification_Types_Order_By = {
  readonly email_notifications_aggregate?: Maybe<Email_Notifications_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: email_notification_types */
export type Email_Notification_Types_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "email_notification_types" */
export enum Email_Notification_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "email_notification_types" */
export type Email_Notification_Types_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "email_notification_types" */
export enum Email_Notification_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "email_notifications" */
export type Email_Notifications = {
  readonly __typename?: 'email_notifications';
  /** An object relationship */
  readonly course_live_session?: Maybe<Course_Live_Sessions>;
  /** An object relationship */
  readonly email_notification_type: Email_Notification_Types;
  readonly id: Scalars['uuid'];
  readonly live_session_id?: Maybe<Scalars['bigint']>;
  readonly schedule_event_id?: Maybe<Scalars['uuid']>;
  readonly type: Email_Notification_Types_Enum;
};

/** aggregated selection of "email_notifications" */
export type Email_Notifications_Aggregate = {
  readonly __typename?: 'email_notifications_aggregate';
  readonly aggregate?: Maybe<Email_Notifications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Email_Notifications>;
};

/** aggregate fields of "email_notifications" */
export type Email_Notifications_Aggregate_Fields = {
  readonly __typename?: 'email_notifications_aggregate_fields';
  readonly avg?: Maybe<Email_Notifications_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Email_Notifications_Max_Fields>;
  readonly min?: Maybe<Email_Notifications_Min_Fields>;
  readonly stddev?: Maybe<Email_Notifications_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Email_Notifications_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Email_Notifications_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Email_Notifications_Sum_Fields>;
  readonly var_pop?: Maybe<Email_Notifications_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Email_Notifications_Var_Samp_Fields>;
  readonly variance?: Maybe<Email_Notifications_Variance_Fields>;
};


/** aggregate fields of "email_notifications" */
export type Email_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_notifications" */
export type Email_Notifications_Aggregate_Order_By = {
  readonly avg?: Maybe<Email_Notifications_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Email_Notifications_Max_Order_By>;
  readonly min?: Maybe<Email_Notifications_Min_Order_By>;
  readonly stddev?: Maybe<Email_Notifications_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Email_Notifications_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Email_Notifications_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Email_Notifications_Sum_Order_By>;
  readonly var_pop?: Maybe<Email_Notifications_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Email_Notifications_Var_Samp_Order_By>;
  readonly variance?: Maybe<Email_Notifications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "email_notifications" */
export type Email_Notifications_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Email_Notifications_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Email_Notifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Email_Notifications_Avg_Fields = {
  readonly __typename?: 'email_notifications_avg_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "email_notifications" */
export type Email_Notifications_Avg_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "email_notifications". All fields are combined with a logical 'AND'. */
export type Email_Notifications_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Email_Notifications_Bool_Exp>>;
  readonly _not?: Maybe<Email_Notifications_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Email_Notifications_Bool_Exp>>;
  readonly course_live_session?: Maybe<Course_Live_Sessions_Bool_Exp>;
  readonly email_notification_type?: Maybe<Email_Notification_Types_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly live_session_id?: Maybe<Bigint_Comparison_Exp>;
  readonly schedule_event_id?: Maybe<Uuid_Comparison_Exp>;
  readonly type?: Maybe<Email_Notification_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_notifications" */
export enum Email_Notifications_Constraint {
  /** unique or primary key constraint */
  EmailNotificationsPkey = 'email_notifications_pkey'
}

/** input type for incrementing numeric columns in table "email_notifications" */
export type Email_Notifications_Inc_Input = {
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "email_notifications" */
export type Email_Notifications_Insert_Input = {
  readonly course_live_session?: Maybe<Course_Live_Sessions_Obj_Rel_Insert_Input>;
  readonly email_notification_type?: Maybe<Email_Notification_Types_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
  readonly schedule_event_id?: Maybe<Scalars['uuid']>;
  readonly type?: Maybe<Email_Notification_Types_Enum>;
};

/** aggregate max on columns */
export type Email_Notifications_Max_Fields = {
  readonly __typename?: 'email_notifications_max_fields';
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
  readonly schedule_event_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "email_notifications" */
export type Email_Notifications_Max_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
  readonly schedule_event_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Notifications_Min_Fields = {
  readonly __typename?: 'email_notifications_min_fields';
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
  readonly schedule_event_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "email_notifications" */
export type Email_Notifications_Min_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
  readonly schedule_event_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_notifications" */
export type Email_Notifications_Mutation_Response = {
  readonly __typename?: 'email_notifications_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Email_Notifications>;
};

/** on conflict condition type for table "email_notifications" */
export type Email_Notifications_On_Conflict = {
  readonly constraint: Email_Notifications_Constraint;
  readonly update_columns?: ReadonlyArray<Email_Notifications_Update_Column>;
  readonly where?: Maybe<Email_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "email_notifications". */
export type Email_Notifications_Order_By = {
  readonly course_live_session?: Maybe<Course_Live_Sessions_Order_By>;
  readonly email_notification_type?: Maybe<Email_Notification_Types_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly live_session_id?: Maybe<Order_By>;
  readonly schedule_event_id?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: email_notifications */
export type Email_Notifications_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "email_notifications" */
export enum Email_Notifications_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LiveSessionId = 'live_session_id',
  /** column name */
  ScheduleEventId = 'schedule_event_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "email_notifications" */
export type Email_Notifications_Set_Input = {
  readonly id?: Maybe<Scalars['uuid']>;
  readonly live_session_id?: Maybe<Scalars['bigint']>;
  readonly schedule_event_id?: Maybe<Scalars['uuid']>;
  readonly type?: Maybe<Email_Notification_Types_Enum>;
};

/** aggregate stddev on columns */
export type Email_Notifications_Stddev_Fields = {
  readonly __typename?: 'email_notifications_stddev_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "email_notifications" */
export type Email_Notifications_Stddev_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Email_Notifications_Stddev_Pop_Fields = {
  readonly __typename?: 'email_notifications_stddev_pop_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "email_notifications" */
export type Email_Notifications_Stddev_Pop_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Email_Notifications_Stddev_Samp_Fields = {
  readonly __typename?: 'email_notifications_stddev_samp_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "email_notifications" */
export type Email_Notifications_Stddev_Samp_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Email_Notifications_Sum_Fields = {
  readonly __typename?: 'email_notifications_sum_fields';
  readonly live_session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "email_notifications" */
export type Email_Notifications_Sum_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** update columns of table "email_notifications" */
export enum Email_Notifications_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LiveSessionId = 'live_session_id',
  /** column name */
  ScheduleEventId = 'schedule_event_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Email_Notifications_Var_Pop_Fields = {
  readonly __typename?: 'email_notifications_var_pop_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "email_notifications" */
export type Email_Notifications_Var_Pop_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Email_Notifications_Var_Samp_Fields = {
  readonly __typename?: 'email_notifications_var_samp_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "email_notifications" */
export type Email_Notifications_Var_Samp_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Email_Notifications_Variance_Fields = {
  readonly __typename?: 'email_notifications_variance_fields';
  readonly live_session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "email_notifications" */
export type Email_Notifications_Variance_Order_By = {
  readonly live_session_id?: Maybe<Order_By>;
};

/** columns and relationships of "event" */
export type Event = {
  readonly __typename?: 'event';
  /** An array relationship */
  readonly cpd_units_rewards: ReadonlyArray<Cpd_Units_Reward>;
  /** An aggregate relationship */
  readonly cpd_units_rewards_aggregate: Cpd_Units_Reward_Aggregate;
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly eventDisplayPhotoUuid: Scalars['String'];
  /** An array relationship */
  readonly event_industries_industries: ReadonlyArray<Event_Industries_Industry>;
  /** An aggregate relationship */
  readonly event_industries_industries_aggregate: Event_Industries_Industry_Aggregate;
  /** An array relationship */
  readonly event_managing_users_users: ReadonlyArray<Event_Managing_Users_User>;
  /** An aggregate relationship */
  readonly event_managing_users_users_aggregate: Event_Managing_Users_User_Aggregate;
  /** An array relationship */
  readonly event_participants: ReadonlyArray<Event_Participant>;
  /** An aggregate relationship */
  readonly event_participants_aggregate: Event_Participant_Aggregate;
  readonly id: Scalars['uuid'];
  readonly isArchived: Scalars['Boolean'];
  /** An array relationship */
  readonly other_rewards: ReadonlyArray<Other_Reward>;
  /** An aggregate relationship */
  readonly other_rewards_aggregate: Other_Reward_Aggregate;
  readonly parentProviderId?: Maybe<Scalars['uuid']>;
  readonly price: Scalars['Int'];
  /** An object relationship */
  readonly provider?: Maybe<Provider>;
  /** An array relationship */
  readonly ranking_rewards: ReadonlyArray<Ranking_Reward>;
  /** An aggregate relationship */
  readonly ranking_rewards_aggregate: Ranking_Reward_Aggregate;
  /** An array relationship */
  readonly reward_certificates: ReadonlyArray<Reward_Certificate>;
  /** An aggregate relationship */
  readonly reward_certificates_aggregate: Reward_Certificate_Aggregate;
  /** An array relationship */
  readonly streams: ReadonlyArray<Stream>;
  /** An aggregate relationship */
  readonly streams_aggregate: Stream_Aggregate;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
  /** An array relationship */
  readonly user_purchased_events_events: ReadonlyArray<User_Purchased_Events_Event>;
  /** An aggregate relationship */
  readonly user_purchased_events_events_aggregate: User_Purchased_Events_Event_Aggregate;
};


/** columns and relationships of "event" */
export type EventCpd_Units_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventCpd_Units_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Managing_Users_UsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Managing_Users_Users_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_ParticipantsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Participants_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventOther_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventOther_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventRanking_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventRanking_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventReward_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventReward_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventStreamsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventStreams_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventUser_Purchased_Events_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventUser_Purchased_Events_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};

/** aggregated selection of "event" */
export type Event_Aggregate = {
  readonly __typename?: 'event_aggregate';
  readonly aggregate?: Maybe<Event_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Event>;
};

/** aggregate fields of "event" */
export type Event_Aggregate_Fields = {
  readonly __typename?: 'event_aggregate_fields';
  readonly avg?: Maybe<Event_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Event_Max_Fields>;
  readonly min?: Maybe<Event_Min_Fields>;
  readonly stddev?: Maybe<Event_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Event_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Event_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Event_Sum_Fields>;
  readonly var_pop?: Maybe<Event_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Event_Var_Samp_Fields>;
  readonly variance?: Maybe<Event_Variance_Fields>;
};


/** aggregate fields of "event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  readonly avg?: Maybe<Event_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Event_Max_Order_By>;
  readonly min?: Maybe<Event_Min_Order_By>;
  readonly stddev?: Maybe<Event_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Event_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Event_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Event_Sum_Order_By>;
  readonly var_pop?: Maybe<Event_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Event_Var_Samp_Order_By>;
  readonly variance?: Maybe<Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event" */
export type Event_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Event_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Avg_Fields = {
  readonly __typename?: 'event_avg_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event" */
export type Event_Avg_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Event_Bool_Exp>>;
  readonly _not?: Maybe<Event_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Event_Bool_Exp>>;
  readonly cpd_units_rewards?: Maybe<Cpd_Units_Reward_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly eventDisplayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly event_industries_industries?: Maybe<Event_Industries_Industry_Bool_Exp>;
  readonly event_managing_users_users?: Maybe<Event_Managing_Users_User_Bool_Exp>;
  readonly event_participants?: Maybe<Event_Participant_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly other_rewards?: Maybe<Other_Reward_Bool_Exp>;
  readonly parentProviderId?: Maybe<Uuid_Comparison_Exp>;
  readonly price?: Maybe<Int_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly ranking_rewards?: Maybe<Ranking_Reward_Bool_Exp>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly streams?: Maybe<Stream_Bool_Exp>;
  readonly timeEnd?: Maybe<Timestamp_Comparison_Exp>;
  readonly timeStart?: Maybe<Timestamp_Comparison_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user_purchased_events_events?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};

/** unique or primary key constraints on table "event" */
export enum Event_Constraint {
  /** unique or primary key constraint */
  Pk_30c2f3bbaf6d34a55f8ae6e4614 = 'PK_30c2f3bbaf6d34a55f8ae6e4614'
}

/** input type for incrementing numeric columns in table "event" */
export type Event_Inc_Input = {
  readonly price?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "event_industries_industry" */
export type Event_Industries_Industry = {
  readonly __typename?: 'event_industries_industry';
  /** An object relationship */
  readonly event: Event;
  readonly eventId: Scalars['uuid'];
  /** An object relationship */
  readonly industry: Industry;
  readonly industryName: Scalars['String'];
};

/** aggregated selection of "event_industries_industry" */
export type Event_Industries_Industry_Aggregate = {
  readonly __typename?: 'event_industries_industry_aggregate';
  readonly aggregate?: Maybe<Event_Industries_Industry_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Event_Industries_Industry>;
};

/** aggregate fields of "event_industries_industry" */
export type Event_Industries_Industry_Aggregate_Fields = {
  readonly __typename?: 'event_industries_industry_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Event_Industries_Industry_Max_Fields>;
  readonly min?: Maybe<Event_Industries_Industry_Min_Fields>;
};


/** aggregate fields of "event_industries_industry" */
export type Event_Industries_Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_industries_industry" */
export type Event_Industries_Industry_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Event_Industries_Industry_Max_Order_By>;
  readonly min?: Maybe<Event_Industries_Industry_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_industries_industry" */
export type Event_Industries_Industry_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Event_Industries_Industry_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_Industries_Industry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_industries_industry". All fields are combined with a logical 'AND'. */
export type Event_Industries_Industry_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Event_Industries_Industry_Bool_Exp>>;
  readonly _not?: Maybe<Event_Industries_Industry_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Event_Industries_Industry_Bool_Exp>>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly industry?: Maybe<Industry_Bool_Exp>;
  readonly industryName?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_industries_industry" */
export enum Event_Industries_Industry_Constraint {
  /** unique or primary key constraint */
  PkB885b8f0777efdd25ff0a5f25a8 = 'PK_b885b8f0777efdd25ff0a5f25a8'
}

/** input type for inserting data into table "event_industries_industry" */
export type Event_Industries_Industry_Insert_Input = {
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  readonly industryName?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Industries_Industry_Max_Fields = {
  readonly __typename?: 'event_industries_industry_max_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_industries_industry" */
export type Event_Industries_Industry_Max_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly industryName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Industries_Industry_Min_Fields = {
  readonly __typename?: 'event_industries_industry_min_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_industries_industry" */
export type Event_Industries_Industry_Min_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly industryName?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_industries_industry" */
export type Event_Industries_Industry_Mutation_Response = {
  readonly __typename?: 'event_industries_industry_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Event_Industries_Industry>;
};

/** on conflict condition type for table "event_industries_industry" */
export type Event_Industries_Industry_On_Conflict = {
  readonly constraint: Event_Industries_Industry_Constraint;
  readonly update_columns?: ReadonlyArray<Event_Industries_Industry_Update_Column>;
  readonly where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "event_industries_industry". */
export type Event_Industries_Industry_Order_By = {
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly industry?: Maybe<Industry_Order_By>;
  readonly industryName?: Maybe<Order_By>;
};

/** primary key columns input for table: event_industries_industry */
export type Event_Industries_Industry_Pk_Columns_Input = {
  readonly eventId: Scalars['uuid'];
  readonly industryName: Scalars['String'];
};

/** select columns of table "event_industries_industry" */
export enum Event_Industries_Industry_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  IndustryName = 'industryName'
}

/** input type for updating data in table "event_industries_industry" */
export type Event_Industries_Industry_Set_Input = {
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly industryName?: Maybe<Scalars['String']>;
};

/** update columns of table "event_industries_industry" */
export enum Event_Industries_Industry_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  IndustryName = 'industryName'
}

/** input type for inserting data into table "event" */
export type Event_Insert_Input = {
  readonly cpd_units_rewards?: Maybe<Cpd_Units_Reward_Arr_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly eventDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly event_industries_industries?: Maybe<Event_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly event_managing_users_users?: Maybe<Event_Managing_Users_User_Arr_Rel_Insert_Input>;
  readonly event_participants?: Maybe<Event_Participant_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly other_rewards?: Maybe<Other_Reward_Arr_Rel_Insert_Input>;
  readonly parentProviderId?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly ranking_rewards?: Maybe<Ranking_Reward_Arr_Rel_Insert_Input>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Arr_Rel_Insert_Input>;
  readonly streams?: Maybe<Stream_Arr_Rel_Insert_Input>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user_purchased_events_events?: Maybe<User_Purchased_Events_Event_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "event_managing_users_user" */
export type Event_Managing_Users_User = {
  readonly __typename?: 'event_managing_users_user';
  /** An object relationship */
  readonly event: Event;
  readonly eventId: Scalars['uuid'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};

/** aggregated selection of "event_managing_users_user" */
export type Event_Managing_Users_User_Aggregate = {
  readonly __typename?: 'event_managing_users_user_aggregate';
  readonly aggregate?: Maybe<Event_Managing_Users_User_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Event_Managing_Users_User>;
};

/** aggregate fields of "event_managing_users_user" */
export type Event_Managing_Users_User_Aggregate_Fields = {
  readonly __typename?: 'event_managing_users_user_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Event_Managing_Users_User_Max_Fields>;
  readonly min?: Maybe<Event_Managing_Users_User_Min_Fields>;
};


/** aggregate fields of "event_managing_users_user" */
export type Event_Managing_Users_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_managing_users_user" */
export type Event_Managing_Users_User_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Event_Managing_Users_User_Max_Order_By>;
  readonly min?: Maybe<Event_Managing_Users_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_managing_users_user" */
export type Event_Managing_Users_User_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Event_Managing_Users_User_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_Managing_Users_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_managing_users_user". All fields are combined with a logical 'AND'. */
export type Event_Managing_Users_User_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Event_Managing_Users_User_Bool_Exp>>;
  readonly _not?: Maybe<Event_Managing_Users_User_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Event_Managing_Users_User_Bool_Exp>>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_managing_users_user" */
export enum Event_Managing_Users_User_Constraint {
  /** unique or primary key constraint */
  PkEaf19e232b5c75dce8f5bee7474 = 'PK_eaf19e232b5c75dce8f5bee7474'
}

/** input type for inserting data into table "event_managing_users_user" */
export type Event_Managing_Users_User_Insert_Input = {
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Managing_Users_User_Max_Fields = {
  readonly __typename?: 'event_managing_users_user_max_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_managing_users_user" */
export type Event_Managing_Users_User_Max_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Managing_Users_User_Min_Fields = {
  readonly __typename?: 'event_managing_users_user_min_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_managing_users_user" */
export type Event_Managing_Users_User_Min_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_managing_users_user" */
export type Event_Managing_Users_User_Mutation_Response = {
  readonly __typename?: 'event_managing_users_user_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Event_Managing_Users_User>;
};

/** on conflict condition type for table "event_managing_users_user" */
export type Event_Managing_Users_User_On_Conflict = {
  readonly constraint: Event_Managing_Users_User_Constraint;
  readonly update_columns?: ReadonlyArray<Event_Managing_Users_User_Update_Column>;
  readonly where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};

/** Ordering options when selecting data from "event_managing_users_user". */
export type Event_Managing_Users_User_Order_By = {
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: event_managing_users_user */
export type Event_Managing_Users_User_Pk_Columns_Input = {
  readonly eventId: Scalars['uuid'];
  readonly userId: Scalars['String'];
};

/** select columns of table "event_managing_users_user" */
export enum Event_Managing_Users_User_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "event_managing_users_user" */
export type Event_Managing_Users_User_Set_Input = {
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "event_managing_users_user" */
export enum Event_Managing_Users_User_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  UserId = 'userId'
}

/** aggregate max on columns */
export type Event_Max_Fields = {
  readonly __typename?: 'event_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly eventDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentProviderId?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly eventDisplayPhotoUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentProviderId?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  readonly __typename?: 'event_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly eventDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentProviderId?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly eventDisplayPhotoUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentProviderId?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "event" */
export type Event_Mutation_Response = {
  readonly __typename?: 'event_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Event>;
};

/** input type for inserting object relation for remote table "event" */
export type Event_Obj_Rel_Insert_Input = {
  readonly data: Event_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_On_Conflict>;
};

/** on conflict condition type for table "event" */
export type Event_On_Conflict = {
  readonly constraint: Event_Constraint;
  readonly update_columns?: ReadonlyArray<Event_Update_Column>;
  readonly where?: Maybe<Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  readonly cpd_units_rewards_aggregate?: Maybe<Cpd_Units_Reward_Aggregate_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly eventDisplayPhotoUuid?: Maybe<Order_By>;
  readonly event_industries_industries_aggregate?: Maybe<Event_Industries_Industry_Aggregate_Order_By>;
  readonly event_managing_users_users_aggregate?: Maybe<Event_Managing_Users_User_Aggregate_Order_By>;
  readonly event_participants_aggregate?: Maybe<Event_Participant_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly other_rewards_aggregate?: Maybe<Other_Reward_Aggregate_Order_By>;
  readonly parentProviderId?: Maybe<Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly ranking_rewards_aggregate?: Maybe<Ranking_Reward_Aggregate_Order_By>;
  readonly reward_certificates_aggregate?: Maybe<Reward_Certificate_Aggregate_Order_By>;
  readonly streams_aggregate?: Maybe<Stream_Aggregate_Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user_purchased_events_events_aggregate?: Maybe<User_Purchased_Events_Event_Aggregate_Order_By>;
};

/** columns and relationships of "event_participant" */
export type Event_Participant = {
  readonly __typename?: 'event_participant';
  readonly approvalStatus: Scalars['String'];
  /** An array relationship */
  readonly breakout_group_participants_event_participants: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
  /** An aggregate relationship */
  readonly breakout_group_participants_event_participants_aggregate: Breakout_Group_Participants_Event_Participant_Aggregate;
  readonly createdAt: Scalars['timestamp'];
  /** An object relationship */
  readonly event: Event;
  readonly eventId: Scalars['uuid'];
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly order: Order;
  readonly orderId: Scalars['uuid'];
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};


/** columns and relationships of "event_participant" */
export type Event_ParticipantBreakout_Group_Participants_Event_ParticipantsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


/** columns and relationships of "event_participant" */
export type Event_ParticipantBreakout_Group_Participants_Event_Participants_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};

/** aggregated selection of "event_participant" */
export type Event_Participant_Aggregate = {
  readonly __typename?: 'event_participant_aggregate';
  readonly aggregate?: Maybe<Event_Participant_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Event_Participant>;
};

/** aggregate fields of "event_participant" */
export type Event_Participant_Aggregate_Fields = {
  readonly __typename?: 'event_participant_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Event_Participant_Max_Fields>;
  readonly min?: Maybe<Event_Participant_Min_Fields>;
};


/** aggregate fields of "event_participant" */
export type Event_Participant_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_participant" */
export type Event_Participant_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Event_Participant_Max_Order_By>;
  readonly min?: Maybe<Event_Participant_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_participant" */
export type Event_Participant_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Event_Participant_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_Participant_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_participant". All fields are combined with a logical 'AND'. */
export type Event_Participant_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Event_Participant_Bool_Exp>>;
  readonly _not?: Maybe<Event_Participant_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Event_Participant_Bool_Exp>>;
  readonly approvalStatus?: Maybe<String_Comparison_Exp>;
  readonly breakout_group_participants_event_participants?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly order?: Maybe<Order_Bool_Exp>;
  readonly orderId?: Maybe<Uuid_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_participant" */
export enum Event_Participant_Constraint {
  /** unique or primary key constraint */
  PkF1e5f8e9b2e1f4838c66f6a96c6 = 'PK_f1e5f8e9b2e1f4838c66f6a96c6',
  /** unique or primary key constraint */
  Uq_11b9f3f95a7955d47bc506d0e0b = 'UQ_11b9f3f95a7955d47bc506d0e0b'
}

/** input type for inserting data into table "event_participant" */
export type Event_Participant_Insert_Input = {
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly breakout_group_participants_event_participants?: Maybe<Breakout_Group_Participants_Event_Participant_Arr_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly order?: Maybe<Order_Obj_Rel_Insert_Input>;
  readonly orderId?: Maybe<Scalars['uuid']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Participant_Max_Fields = {
  readonly __typename?: 'event_participant_max_fields';
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly orderId?: Maybe<Scalars['uuid']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_participant" */
export type Event_Participant_Max_Order_By = {
  readonly approvalStatus?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly orderId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Participant_Min_Fields = {
  readonly __typename?: 'event_participant_min_fields';
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly orderId?: Maybe<Scalars['uuid']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_participant" */
export type Event_Participant_Min_Order_By = {
  readonly approvalStatus?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly orderId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_participant" */
export type Event_Participant_Mutation_Response = {
  readonly __typename?: 'event_participant_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Event_Participant>;
};

/** input type for inserting object relation for remote table "event_participant" */
export type Event_Participant_Obj_Rel_Insert_Input = {
  readonly data: Event_Participant_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Event_Participant_On_Conflict>;
};

/** on conflict condition type for table "event_participant" */
export type Event_Participant_On_Conflict = {
  readonly constraint: Event_Participant_Constraint;
  readonly update_columns?: ReadonlyArray<Event_Participant_Update_Column>;
  readonly where?: Maybe<Event_Participant_Bool_Exp>;
};

/** Ordering options when selecting data from "event_participant". */
export type Event_Participant_Order_By = {
  readonly approvalStatus?: Maybe<Order_By>;
  readonly breakout_group_participants_event_participants_aggregate?: Maybe<Breakout_Group_Participants_Event_Participant_Aggregate_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly order?: Maybe<Order_Order_By>;
  readonly orderId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: event_participant */
export type Event_Participant_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "event_participant" */
export enum Event_Participant_Select_Column {
  /** column name */
  ApprovalStatus = 'approvalStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "event_participant" */
export type Event_Participant_Set_Input = {
  readonly approvalStatus?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly orderId?: Maybe<Scalars['uuid']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "event_participant" */
export enum Event_Participant_Update_Column {
  /** column name */
  ApprovalStatus = 'approvalStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** primary key columns input for table: event */
export type Event_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EventDisplayPhotoUuid = 'eventDisplayPhotoUuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  ParentProviderId = 'parentProviderId',
  /** column name */
  Price = 'price',
  /** column name */
  TimeEnd = 'timeEnd',
  /** column name */
  TimeStart = 'timeStart',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "event" */
export type Event_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly eventDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly parentProviderId?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Event_Stddev_Fields = {
  readonly __typename?: 'event_stddev_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event" */
export type Event_Stddev_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Stddev_Pop_Fields = {
  readonly __typename?: 'event_stddev_pop_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event" */
export type Event_Stddev_Pop_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Stddev_Samp_Fields = {
  readonly __typename?: 'event_stddev_samp_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event" */
export type Event_Stddev_Samp_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Event_Sum_Fields = {
  readonly __typename?: 'event_sum_fields';
  readonly price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "event" */
export type Event_Sum_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** update columns of table "event" */
export enum Event_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EventDisplayPhotoUuid = 'eventDisplayPhotoUuid',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  ParentProviderId = 'parentProviderId',
  /** column name */
  Price = 'price',
  /** column name */
  TimeEnd = 'timeEnd',
  /** column name */
  TimeStart = 'timeStart',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Event_Var_Pop_Fields = {
  readonly __typename?: 'event_var_pop_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event" */
export type Event_Var_Pop_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Var_Samp_Fields = {
  readonly __typename?: 'event_var_samp_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event" */
export type Event_Var_Samp_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Variance_Fields = {
  readonly __typename?: 'event_variance_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event" */
export type Event_Variance_Order_By = {
  readonly price?: Maybe<Order_By>;
};

/** columns and relationships of "faq_item" */
export type Faq_Item = {
  readonly __typename?: 'faq_item';
  readonly answer: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly question: Scalars['String'];
};

/** aggregated selection of "faq_item" */
export type Faq_Item_Aggregate = {
  readonly __typename?: 'faq_item_aggregate';
  readonly aggregate?: Maybe<Faq_Item_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Faq_Item>;
};

/** aggregate fields of "faq_item" */
export type Faq_Item_Aggregate_Fields = {
  readonly __typename?: 'faq_item_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Faq_Item_Max_Fields>;
  readonly min?: Maybe<Faq_Item_Min_Fields>;
};


/** aggregate fields of "faq_item" */
export type Faq_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Faq_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "faq_item". All fields are combined with a logical 'AND'. */
export type Faq_Item_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Faq_Item_Bool_Exp>>;
  readonly _not?: Maybe<Faq_Item_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Faq_Item_Bool_Exp>>;
  readonly answer?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly question?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq_item" */
export enum Faq_Item_Constraint {
  /** unique or primary key constraint */
  PkCe6ab657867e488a12af6cc1c9b = 'PK_ce6ab657867e488a12af6cc1c9b'
}

/** input type for inserting data into table "faq_item" */
export type Faq_Item_Insert_Input = {
  readonly answer?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly question?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Faq_Item_Max_Fields = {
  readonly __typename?: 'faq_item_max_fields';
  readonly answer?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly question?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Faq_Item_Min_Fields = {
  readonly __typename?: 'faq_item_min_fields';
  readonly answer?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly question?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "faq_item" */
export type Faq_Item_Mutation_Response = {
  readonly __typename?: 'faq_item_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Faq_Item>;
};

/** on conflict condition type for table "faq_item" */
export type Faq_Item_On_Conflict = {
  readonly constraint: Faq_Item_Constraint;
  readonly update_columns?: ReadonlyArray<Faq_Item_Update_Column>;
  readonly where?: Maybe<Faq_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "faq_item". */
export type Faq_Item_Order_By = {
  readonly answer?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly question?: Maybe<Order_By>;
};

/** primary key columns input for table: faq_item */
export type Faq_Item_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "faq_item" */
export enum Faq_Item_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question'
}

/** input type for updating data in table "faq_item" */
export type Faq_Item_Set_Input = {
  readonly answer?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly question?: Maybe<Scalars['String']>;
};

/** update columns of table "faq_item" */
export enum Faq_Item_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question'
}

/** columns and relationships of "form" */
export type Form = {
  readonly __typename?: 'form';
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  /** An array relationship */
  readonly form_instances: ReadonlyArray<Form_Instance>;
  /** An aggregate relationship */
  readonly form_instances_aggregate: Form_Instance_Aggregate;
  readonly id: Scalars['uuid'];
  readonly isArchived: Scalars['Boolean'];
  readonly message: Scalars['String'];
  /** An object relationship */
  readonly provider?: Maybe<Provider>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly questionId: Scalars['String'];
  /** An array relationship */
  readonly stream_forms_forms: ReadonlyArray<Stream_Forms_Form>;
  /** An aggregate relationship */
  readonly stream_forms_forms_aggregate: Stream_Forms_Form_Aggregate;
  readonly title: Scalars['String'];
  readonly unlockedStreams: Scalars['_text'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "form" */
export type FormForm_InstancesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormForm_Instances_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormStream_Forms_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormStream_Forms_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};

/** aggregated selection of "form" */
export type Form_Aggregate = {
  readonly __typename?: 'form_aggregate';
  readonly aggregate?: Maybe<Form_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form>;
};

/** aggregate fields of "form" */
export type Form_Aggregate_Fields = {
  readonly __typename?: 'form_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Max_Fields>;
  readonly min?: Maybe<Form_Min_Fields>;
};


/** aggregate fields of "form" */
export type Form_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form" */
export type Form_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Max_Order_By>;
  readonly min?: Maybe<Form_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form" */
export type Form_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form". All fields are combined with a logical 'AND'. */
export type Form_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Bool_Exp>>;
  readonly _not?: Maybe<Form_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly form_instances?: Maybe<Form_Instance_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly message?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly questionId?: Maybe<String_Comparison_Exp>;
  readonly stream_forms_forms?: Maybe<Stream_Forms_Form_Bool_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly unlockedStreams?: Maybe<_Text_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "form" */
export enum Form_Constraint {
  /** unique or primary key constraint */
  Pk_8f72b95aa2f8ba82cf95dc7579e = 'PK_8f72b95aa2f8ba82cf95dc7579e',
  /** unique or primary key constraint */
  UqA0be8ec129ca060d756c83339de = 'UQ_a0be8ec129ca060d756c83339de'
}

/** columns and relationships of "form_event_names" */
export type Form_Event_Names = {
  readonly __typename?: 'form_event_names';
  /** An array relationship */
  readonly form_events: ReadonlyArray<Form_Events>;
  /** An aggregate relationship */
  readonly form_events_aggregate: Form_Events_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "form_event_names" */
export type Form_Event_NamesForm_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


/** columns and relationships of "form_event_names" */
export type Form_Event_NamesForm_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};

/** aggregated selection of "form_event_names" */
export type Form_Event_Names_Aggregate = {
  readonly __typename?: 'form_event_names_aggregate';
  readonly aggregate?: Maybe<Form_Event_Names_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Event_Names>;
};

/** aggregate fields of "form_event_names" */
export type Form_Event_Names_Aggregate_Fields = {
  readonly __typename?: 'form_event_names_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Event_Names_Max_Fields>;
  readonly min?: Maybe<Form_Event_Names_Min_Fields>;
};


/** aggregate fields of "form_event_names" */
export type Form_Event_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Event_Names_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_event_names". All fields are combined with a logical 'AND'. */
export type Form_Event_Names_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Event_Names_Bool_Exp>>;
  readonly _not?: Maybe<Form_Event_Names_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Event_Names_Bool_Exp>>;
  readonly form_events?: Maybe<Form_Events_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_event_names" */
export enum Form_Event_Names_Constraint {
  /** unique or primary key constraint */
  FormEventNamesPkey = 'form_event_names_pkey'
}

export enum Form_Event_Names_Enum {
  PageBlurred = 'page_blurred',
  PageFocused = 'page_focused'
}

/** Boolean expression to compare columns of type "form_event_names_enum". All fields are combined with logical 'AND'. */
export type Form_Event_Names_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Event_Names_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Event_Names_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Event_Names_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Event_Names_Enum>>;
};

/** input type for inserting data into table "form_event_names" */
export type Form_Event_Names_Insert_Input = {
  readonly form_events?: Maybe<Form_Events_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Event_Names_Max_Fields = {
  readonly __typename?: 'form_event_names_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Event_Names_Min_Fields = {
  readonly __typename?: 'form_event_names_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_event_names" */
export type Form_Event_Names_Mutation_Response = {
  readonly __typename?: 'form_event_names_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Event_Names>;
};

/** input type for inserting object relation for remote table "form_event_names" */
export type Form_Event_Names_Obj_Rel_Insert_Input = {
  readonly data: Form_Event_Names_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Event_Names_On_Conflict>;
};

/** on conflict condition type for table "form_event_names" */
export type Form_Event_Names_On_Conflict = {
  readonly constraint: Form_Event_Names_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Event_Names_Update_Column>;
  readonly where?: Maybe<Form_Event_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "form_event_names". */
export type Form_Event_Names_Order_By = {
  readonly form_events_aggregate?: Maybe<Form_Events_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_event_names */
export type Form_Event_Names_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_event_names" */
export enum Form_Event_Names_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_event_names" */
export type Form_Event_Names_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_event_names" */
export enum Form_Event_Names_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "form_events" */
export type Form_Events = {
  readonly __typename?: 'form_events';
  readonly created_at: Scalars['timestamp'];
  /** An object relationship */
  readonly form: Forms;
  /** An object relationship */
  readonly form_event_name: Form_Event_Names;
  readonly form_id: Scalars['uuid'];
  readonly id: Scalars['uuid'];
  readonly name: Form_Event_Names_Enum;
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "form_events" */
export type Form_Events_Aggregate = {
  readonly __typename?: 'form_events_aggregate';
  readonly aggregate?: Maybe<Form_Events_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Events>;
};

/** aggregate fields of "form_events" */
export type Form_Events_Aggregate_Fields = {
  readonly __typename?: 'form_events_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Events_Max_Fields>;
  readonly min?: Maybe<Form_Events_Min_Fields>;
};


/** aggregate fields of "form_events" */
export type Form_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_events" */
export type Form_Events_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Events_Max_Order_By>;
  readonly min?: Maybe<Form_Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form_events" */
export type Form_Events_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Events_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_events". All fields are combined with a logical 'AND'. */
export type Form_Events_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Events_Bool_Exp>>;
  readonly _not?: Maybe<Form_Events_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Events_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly form?: Maybe<Forms_Bool_Exp>;
  readonly form_event_name?: Maybe<Form_Event_Names_Bool_Exp>;
  readonly form_id?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<Form_Event_Names_Enum_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_events" */
export enum Form_Events_Constraint {
  /** unique or primary key constraint */
  FormEventsPkey = 'form_events_pkey'
}

/** input type for inserting data into table "form_events" */
export type Form_Events_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  readonly form_event_name?: Maybe<Form_Event_Names_Obj_Rel_Insert_Input>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Form_Event_Names_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Events_Max_Fields = {
  readonly __typename?: 'form_events_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_events" */
export type Form_Events_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Events_Min_Fields = {
  readonly __typename?: 'form_events_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_events" */
export type Form_Events_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_events" */
export type Form_Events_Mutation_Response = {
  readonly __typename?: 'form_events_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Events>;
};

/** on conflict condition type for table "form_events" */
export type Form_Events_On_Conflict = {
  readonly constraint: Form_Events_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Events_Update_Column>;
  readonly where?: Maybe<Form_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "form_events". */
export type Form_Events_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly form?: Maybe<Forms_Order_By>;
  readonly form_event_name?: Maybe<Form_Event_Names_Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: form_events */
export type Form_Events_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_events" */
export enum Form_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "form_events" */
export type Form_Events_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Form_Event_Names_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "form_events" */
export enum Form_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for inserting data into table "form" */
export type Form_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly form_instances?: Maybe<Form_Instance_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly questionId?: Maybe<Scalars['String']>;
  readonly stream_forms_forms?: Maybe<Stream_Forms_Form_Arr_Rel_Insert_Input>;
  readonly title?: Maybe<Scalars['String']>;
  readonly unlockedStreams?: Maybe<Scalars['_text']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "form_instance" */
export type Form_Instance = {
  readonly __typename?: 'form_instance';
  readonly createdAt: Scalars['timestamp'];
  /** An object relationship */
  readonly form?: Maybe<Form>;
  readonly formId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  readonly form_scores: ReadonlyArray<Form_Score>;
  /** An aggregate relationship */
  readonly form_scores_aggregate: Form_Score_Aggregate;
  readonly id: Scalars['uuid'];
  readonly instanceEntityId: Scalars['String'];
  readonly instanceType: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "form_instance" */
export type Form_InstanceForm_ScoresArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


/** columns and relationships of "form_instance" */
export type Form_InstanceForm_Scores_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};

/** aggregated selection of "form_instance" */
export type Form_Instance_Aggregate = {
  readonly __typename?: 'form_instance_aggregate';
  readonly aggregate?: Maybe<Form_Instance_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Instance>;
};

/** aggregate fields of "form_instance" */
export type Form_Instance_Aggregate_Fields = {
  readonly __typename?: 'form_instance_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Instance_Max_Fields>;
  readonly min?: Maybe<Form_Instance_Min_Fields>;
};


/** aggregate fields of "form_instance" */
export type Form_Instance_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_instance" */
export type Form_Instance_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Instance_Max_Order_By>;
  readonly min?: Maybe<Form_Instance_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form_instance" */
export type Form_Instance_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Instance_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Instance_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_instance". All fields are combined with a logical 'AND'. */
export type Form_Instance_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Instance_Bool_Exp>>;
  readonly _not?: Maybe<Form_Instance_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Instance_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly form?: Maybe<Form_Bool_Exp>;
  readonly formId?: Maybe<Uuid_Comparison_Exp>;
  readonly form_scores?: Maybe<Form_Score_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly instanceEntityId?: Maybe<String_Comparison_Exp>;
  readonly instanceType?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_instance" */
export enum Form_Instance_Constraint {
  /** unique or primary key constraint */
  Pk_0a76f8cb94fd278e2e1275b1170 = 'PK_0a76f8cb94fd278e2e1275b1170'
}

/** input type for inserting data into table "form_instance" */
export type Form_Instance_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly form?: Maybe<Form_Obj_Rel_Insert_Input>;
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly form_scores?: Maybe<Form_Score_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly instanceEntityId?: Maybe<Scalars['String']>;
  readonly instanceType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Form_Instance_Max_Fields = {
  readonly __typename?: 'form_instance_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly instanceEntityId?: Maybe<Scalars['String']>;
  readonly instanceType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "form_instance" */
export type Form_Instance_Max_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly formId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly instanceEntityId?: Maybe<Order_By>;
  readonly instanceType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Instance_Min_Fields = {
  readonly __typename?: 'form_instance_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly instanceEntityId?: Maybe<Scalars['String']>;
  readonly instanceType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "form_instance" */
export type Form_Instance_Min_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly formId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly instanceEntityId?: Maybe<Order_By>;
  readonly instanceType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_instance" */
export type Form_Instance_Mutation_Response = {
  readonly __typename?: 'form_instance_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Instance>;
};

/** input type for inserting object relation for remote table "form_instance" */
export type Form_Instance_Obj_Rel_Insert_Input = {
  readonly data: Form_Instance_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Instance_On_Conflict>;
};

/** on conflict condition type for table "form_instance" */
export type Form_Instance_On_Conflict = {
  readonly constraint: Form_Instance_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Instance_Update_Column>;
  readonly where?: Maybe<Form_Instance_Bool_Exp>;
};

/** Ordering options when selecting data from "form_instance". */
export type Form_Instance_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly form?: Maybe<Form_Order_By>;
  readonly formId?: Maybe<Order_By>;
  readonly form_scores_aggregate?: Maybe<Form_Score_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly instanceEntityId?: Maybe<Order_By>;
  readonly instanceType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: form_instance */
export type Form_Instance_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_instance" */
export enum Form_Instance_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceEntityId = 'instanceEntityId',
  /** column name */
  InstanceType = 'instanceType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "form_instance" */
export type Form_Instance_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly instanceEntityId?: Maybe<Scalars['String']>;
  readonly instanceType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "form_instance" */
export enum Form_Instance_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceEntityId = 'instanceEntityId',
  /** column name */
  InstanceType = 'instanceType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate max on columns */
export type Form_Max_Fields = {
  readonly __typename?: 'form_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly questionId?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "form" */
export type Form_Max_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly questionId?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Min_Fields = {
  readonly __typename?: 'form_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly questionId?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "form" */
export type Form_Min_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly questionId?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "form" */
export type Form_Mutation_Response = {
  readonly __typename?: 'form_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form>;
};

/** input type for inserting object relation for remote table "form" */
export type Form_Obj_Rel_Insert_Input = {
  readonly data: Form_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_On_Conflict>;
};

/** on conflict condition type for table "form" */
export type Form_On_Conflict = {
  readonly constraint: Form_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Update_Column>;
  readonly where?: Maybe<Form_Bool_Exp>;
};

/** Ordering options when selecting data from "form". */
export type Form_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly form_instances_aggregate?: Maybe<Form_Instance_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly questionId?: Maybe<Order_By>;
  readonly stream_forms_forms_aggregate?: Maybe<Stream_Forms_Form_Aggregate_Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly unlockedStreams?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: form */
export type Form_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** columns and relationships of "form_question_answers" */
export type Form_Question_Answers = {
  readonly __typename?: 'form_question_answers';
  readonly created_at: Scalars['timestamptz'];
  readonly feedback?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly form_question: Form_Questions;
  readonly form_question_id: Scalars['uuid'];
  readonly id: Scalars['uuid'];
  readonly name: Scalars['String'];
  readonly points: Scalars['numeric'];
  readonly updated_at: Scalars['timestamptz'];
  readonly weight: Scalars['numeric'];
};

/** aggregated selection of "form_question_answers" */
export type Form_Question_Answers_Aggregate = {
  readonly __typename?: 'form_question_answers_aggregate';
  readonly aggregate?: Maybe<Form_Question_Answers_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Question_Answers>;
};

/** aggregate fields of "form_question_answers" */
export type Form_Question_Answers_Aggregate_Fields = {
  readonly __typename?: 'form_question_answers_aggregate_fields';
  readonly avg?: Maybe<Form_Question_Answers_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Question_Answers_Max_Fields>;
  readonly min?: Maybe<Form_Question_Answers_Min_Fields>;
  readonly stddev?: Maybe<Form_Question_Answers_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Form_Question_Answers_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Form_Question_Answers_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Form_Question_Answers_Sum_Fields>;
  readonly var_pop?: Maybe<Form_Question_Answers_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Form_Question_Answers_Var_Samp_Fields>;
  readonly variance?: Maybe<Form_Question_Answers_Variance_Fields>;
};


/** aggregate fields of "form_question_answers" */
export type Form_Question_Answers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_question_answers" */
export type Form_Question_Answers_Aggregate_Order_By = {
  readonly avg?: Maybe<Form_Question_Answers_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Question_Answers_Max_Order_By>;
  readonly min?: Maybe<Form_Question_Answers_Min_Order_By>;
  readonly stddev?: Maybe<Form_Question_Answers_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Form_Question_Answers_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Form_Question_Answers_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Form_Question_Answers_Sum_Order_By>;
  readonly var_pop?: Maybe<Form_Question_Answers_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Form_Question_Answers_Var_Samp_Order_By>;
  readonly variance?: Maybe<Form_Question_Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_question_answers" */
export type Form_Question_Answers_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Question_Answers_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Question_Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Question_Answers_Avg_Fields = {
  readonly __typename?: 'form_question_answers_avg_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_question_answers" */
export type Form_Question_Answers_Avg_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_question_answers". All fields are combined with a logical 'AND'. */
export type Form_Question_Answers_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Question_Answers_Bool_Exp>>;
  readonly _not?: Maybe<Form_Question_Answers_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Question_Answers_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly feedback?: Maybe<String_Comparison_Exp>;
  readonly form_question?: Maybe<Form_Questions_Bool_Exp>;
  readonly form_question_id?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly points?: Maybe<Numeric_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly weight?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_question_answers" */
export enum Form_Question_Answers_Constraint {
  /** unique or primary key constraint */
  FormQuestionAnswersPkey = 'form_question_answers_pkey'
}

/** input type for incrementing numeric columns in table "form_question_answers" */
export type Form_Question_Answers_Inc_Input = {
  readonly points?: Maybe<Scalars['numeric']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "form_question_answers" */
export type Form_Question_Answers_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly feedback?: Maybe<Scalars['String']>;
  readonly form_question?: Maybe<Form_Questions_Obj_Rel_Insert_Input>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Form_Question_Answers_Max_Fields = {
  readonly __typename?: 'form_question_answers_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly feedback?: Maybe<Scalars['String']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "form_question_answers" */
export type Form_Question_Answers_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly feedback?: Maybe<Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Question_Answers_Min_Fields = {
  readonly __typename?: 'form_question_answers_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly feedback?: Maybe<Scalars['String']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "form_question_answers" */
export type Form_Question_Answers_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly feedback?: Maybe<Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_question_answers" */
export type Form_Question_Answers_Mutation_Response = {
  readonly __typename?: 'form_question_answers_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Question_Answers>;
};

/** on conflict condition type for table "form_question_answers" */
export type Form_Question_Answers_On_Conflict = {
  readonly constraint: Form_Question_Answers_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Question_Answers_Update_Column>;
  readonly where?: Maybe<Form_Question_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "form_question_answers". */
export type Form_Question_Answers_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly feedback?: Maybe<Order_By>;
  readonly form_question?: Maybe<Form_Questions_Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** primary key columns input for table: form_question_answers */
export type Form_Question_Answers_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_question_answers" */
export enum Form_Question_Answers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  FormQuestionId = 'form_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "form_question_answers" */
export type Form_Question_Answers_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly feedback?: Maybe<Scalars['String']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points?: Maybe<Scalars['numeric']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Form_Question_Answers_Stddev_Fields = {
  readonly __typename?: 'form_question_answers_stddev_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_question_answers" */
export type Form_Question_Answers_Stddev_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Question_Answers_Stddev_Pop_Fields = {
  readonly __typename?: 'form_question_answers_stddev_pop_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_question_answers" */
export type Form_Question_Answers_Stddev_Pop_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Question_Answers_Stddev_Samp_Fields = {
  readonly __typename?: 'form_question_answers_stddev_samp_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_question_answers" */
export type Form_Question_Answers_Stddev_Samp_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Question_Answers_Sum_Fields = {
  readonly __typename?: 'form_question_answers_sum_fields';
  readonly points?: Maybe<Scalars['numeric']>;
  readonly weight?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "form_question_answers" */
export type Form_Question_Answers_Sum_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** update columns of table "form_question_answers" */
export enum Form_Question_Answers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  FormQuestionId = 'form_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

/** aggregate var_pop on columns */
export type Form_Question_Answers_Var_Pop_Fields = {
  readonly __typename?: 'form_question_answers_var_pop_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_question_answers" */
export type Form_Question_Answers_Var_Pop_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Question_Answers_Var_Samp_Fields = {
  readonly __typename?: 'form_question_answers_var_samp_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_question_answers" */
export type Form_Question_Answers_Var_Samp_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Question_Answers_Variance_Fields = {
  readonly __typename?: 'form_question_answers_variance_fields';
  readonly points?: Maybe<Scalars['Float']>;
  readonly weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_question_answers" */
export type Form_Question_Answers_Variance_Order_By = {
  readonly points?: Maybe<Order_By>;
  readonly weight?: Maybe<Order_By>;
};

/** columns and relationships of "form_question_submission_states" */
export type Form_Question_Submission_States = {
  readonly __typename?: 'form_question_submission_states';
  /** An array relationship */
  readonly form_question_submissions: ReadonlyArray<Form_Question_Submissions>;
  /** An aggregate relationship */
  readonly form_question_submissions_aggregate: Form_Question_Submissions_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "form_question_submission_states" */
export type Form_Question_Submission_StatesForm_Question_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


/** columns and relationships of "form_question_submission_states" */
export type Form_Question_Submission_StatesForm_Question_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};

/** aggregated selection of "form_question_submission_states" */
export type Form_Question_Submission_States_Aggregate = {
  readonly __typename?: 'form_question_submission_states_aggregate';
  readonly aggregate?: Maybe<Form_Question_Submission_States_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Question_Submission_States>;
};

/** aggregate fields of "form_question_submission_states" */
export type Form_Question_Submission_States_Aggregate_Fields = {
  readonly __typename?: 'form_question_submission_states_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Question_Submission_States_Max_Fields>;
  readonly min?: Maybe<Form_Question_Submission_States_Min_Fields>;
};


/** aggregate fields of "form_question_submission_states" */
export type Form_Question_Submission_States_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Question_Submission_States_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_question_submission_states". All fields are combined with a logical 'AND'. */
export type Form_Question_Submission_States_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Question_Submission_States_Bool_Exp>>;
  readonly _not?: Maybe<Form_Question_Submission_States_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Question_Submission_States_Bool_Exp>>;
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_question_submission_states" */
export enum Form_Question_Submission_States_Constraint {
  /** unique or primary key constraint */
  FormQuestionSubmissionStatesPkey = 'form_question_submission_states_pkey'
}

export enum Form_Question_Submission_States_Enum {
  Correct = 'correct',
  Incorrect = 'incorrect',
  Indeterminate = 'indeterminate'
}

/** Boolean expression to compare columns of type "form_question_submission_states_enum". All fields are combined with logical 'AND'. */
export type Form_Question_Submission_States_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Question_Submission_States_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Question_Submission_States_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Question_Submission_States_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Question_Submission_States_Enum>>;
};

/** input type for inserting data into table "form_question_submission_states" */
export type Form_Question_Submission_States_Insert_Input = {
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Question_Submission_States_Max_Fields = {
  readonly __typename?: 'form_question_submission_states_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Question_Submission_States_Min_Fields = {
  readonly __typename?: 'form_question_submission_states_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_question_submission_states" */
export type Form_Question_Submission_States_Mutation_Response = {
  readonly __typename?: 'form_question_submission_states_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Question_Submission_States>;
};

/** input type for inserting object relation for remote table "form_question_submission_states" */
export type Form_Question_Submission_States_Obj_Rel_Insert_Input = {
  readonly data: Form_Question_Submission_States_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Question_Submission_States_On_Conflict>;
};

/** on conflict condition type for table "form_question_submission_states" */
export type Form_Question_Submission_States_On_Conflict = {
  readonly constraint: Form_Question_Submission_States_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Question_Submission_States_Update_Column>;
  readonly where?: Maybe<Form_Question_Submission_States_Bool_Exp>;
};

/** Ordering options when selecting data from "form_question_submission_states". */
export type Form_Question_Submission_States_Order_By = {
  readonly form_question_submissions_aggregate?: Maybe<Form_Question_Submissions_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_question_submission_states */
export type Form_Question_Submission_States_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_question_submission_states" */
export enum Form_Question_Submission_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_question_submission_states" */
export type Form_Question_Submission_States_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_question_submission_states" */
export enum Form_Question_Submission_States_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "form_question_submissions" */
export type Form_Question_Submissions = {
  readonly __typename?: 'form_question_submissions';
  readonly answers: Scalars['_text'];
  readonly final_score?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  readonly form_question: Form_Questions;
  readonly form_question_id: Scalars['uuid'];
  /** An object relationship */
  readonly form_question_submission_state: Form_Question_Submission_States;
  /** An object relationship */
  readonly form_submission: Form_Submissions;
  readonly form_submission_id: Scalars['uuid'];
  readonly id: Scalars['uuid'];
  readonly last_answered_at: Scalars['timestamptz'];
  readonly remark?: Maybe<Scalars['String']>;
  readonly score_before_regrade: Scalars['numeric'];
  readonly state: Form_Question_Submission_States_Enum;
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "form_question_submissions" */
export type Form_Question_Submissions_Aggregate = {
  readonly __typename?: 'form_question_submissions_aggregate';
  readonly aggregate?: Maybe<Form_Question_Submissions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Question_Submissions>;
};

/** aggregate fields of "form_question_submissions" */
export type Form_Question_Submissions_Aggregate_Fields = {
  readonly __typename?: 'form_question_submissions_aggregate_fields';
  readonly avg?: Maybe<Form_Question_Submissions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Question_Submissions_Max_Fields>;
  readonly min?: Maybe<Form_Question_Submissions_Min_Fields>;
  readonly stddev?: Maybe<Form_Question_Submissions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Form_Question_Submissions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Form_Question_Submissions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Form_Question_Submissions_Sum_Fields>;
  readonly var_pop?: Maybe<Form_Question_Submissions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Form_Question_Submissions_Var_Samp_Fields>;
  readonly variance?: Maybe<Form_Question_Submissions_Variance_Fields>;
};


/** aggregate fields of "form_question_submissions" */
export type Form_Question_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_question_submissions" */
export type Form_Question_Submissions_Aggregate_Order_By = {
  readonly avg?: Maybe<Form_Question_Submissions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Question_Submissions_Max_Order_By>;
  readonly min?: Maybe<Form_Question_Submissions_Min_Order_By>;
  readonly stddev?: Maybe<Form_Question_Submissions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Form_Question_Submissions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Form_Question_Submissions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Form_Question_Submissions_Sum_Order_By>;
  readonly var_pop?: Maybe<Form_Question_Submissions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Form_Question_Submissions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Form_Question_Submissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_question_submissions" */
export type Form_Question_Submissions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Question_Submissions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Question_Submissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Question_Submissions_Avg_Fields = {
  readonly __typename?: 'form_question_submissions_avg_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Avg_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_question_submissions". All fields are combined with a logical 'AND'. */
export type Form_Question_Submissions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Question_Submissions_Bool_Exp>>;
  readonly _not?: Maybe<Form_Question_Submissions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Question_Submissions_Bool_Exp>>;
  readonly answers?: Maybe<_Text_Comparison_Exp>;
  readonly final_score?: Maybe<Numeric_Comparison_Exp>;
  readonly form_question?: Maybe<Form_Questions_Bool_Exp>;
  readonly form_question_id?: Maybe<Uuid_Comparison_Exp>;
  readonly form_question_submission_state?: Maybe<Form_Question_Submission_States_Bool_Exp>;
  readonly form_submission?: Maybe<Form_Submissions_Bool_Exp>;
  readonly form_submission_id?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly last_answered_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly remark?: Maybe<String_Comparison_Exp>;
  readonly score_before_regrade?: Maybe<Numeric_Comparison_Exp>;
  readonly state?: Maybe<Form_Question_Submission_States_Enum_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_question_submissions" */
export enum Form_Question_Submissions_Constraint {
  /** unique or primary key constraint */
  FormQuestionSubmissionsFormSubmissionIdFormQuestionIdU = 'form_question_submissions_form_submission_id_form_question_id_u',
  /** unique or primary key constraint */
  FormQuestionSubmissionsPkey = 'form_question_submissions_pkey'
}

/** input type for incrementing numeric columns in table "form_question_submissions" */
export type Form_Question_Submissions_Inc_Input = {
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "form_question_submissions" */
export type Form_Question_Submissions_Insert_Input = {
  readonly answers?: Maybe<Scalars['_text']>;
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly form_question?: Maybe<Form_Questions_Obj_Rel_Insert_Input>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly form_question_submission_state?: Maybe<Form_Question_Submission_States_Obj_Rel_Insert_Input>;
  readonly form_submission?: Maybe<Form_Submissions_Obj_Rel_Insert_Input>;
  readonly form_submission_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly last_answered_at?: Maybe<Scalars['timestamptz']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
  readonly state?: Maybe<Form_Question_Submission_States_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Question_Submissions_Max_Fields = {
  readonly __typename?: 'form_question_submissions_max_fields';
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly form_submission_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly last_answered_at?: Maybe<Scalars['timestamptz']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Max_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly form_submission_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly last_answered_at?: Maybe<Order_By>;
  readonly remark?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Question_Submissions_Min_Fields = {
  readonly __typename?: 'form_question_submissions_min_fields';
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly form_submission_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly last_answered_at?: Maybe<Scalars['timestamptz']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Min_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly form_submission_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly last_answered_at?: Maybe<Order_By>;
  readonly remark?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_question_submissions" */
export type Form_Question_Submissions_Mutation_Response = {
  readonly __typename?: 'form_question_submissions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Question_Submissions>;
};

/** on conflict condition type for table "form_question_submissions" */
export type Form_Question_Submissions_On_Conflict = {
  readonly constraint: Form_Question_Submissions_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Question_Submissions_Update_Column>;
  readonly where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "form_question_submissions". */
export type Form_Question_Submissions_Order_By = {
  readonly answers?: Maybe<Order_By>;
  readonly final_score?: Maybe<Order_By>;
  readonly form_question?: Maybe<Form_Questions_Order_By>;
  readonly form_question_id?: Maybe<Order_By>;
  readonly form_question_submission_state?: Maybe<Form_Question_Submission_States_Order_By>;
  readonly form_submission?: Maybe<Form_Submissions_Order_By>;
  readonly form_submission_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly last_answered_at?: Maybe<Order_By>;
  readonly remark?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
  readonly state?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: form_question_submissions */
export type Form_Question_Submissions_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_question_submissions" */
export enum Form_Question_Submissions_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  FinalScore = 'final_score',
  /** column name */
  FormQuestionId = 'form_question_id',
  /** column name */
  FormSubmissionId = 'form_submission_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastAnsweredAt = 'last_answered_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  ScoreBeforeRegrade = 'score_before_regrade',
  /** column name */
  State = 'state',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "form_question_submissions" */
export type Form_Question_Submissions_Set_Input = {
  readonly answers?: Maybe<Scalars['_text']>;
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly form_question_id?: Maybe<Scalars['uuid']>;
  readonly form_submission_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly last_answered_at?: Maybe<Scalars['timestamptz']>;
  readonly remark?: Maybe<Scalars['String']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
  readonly state?: Maybe<Form_Question_Submission_States_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Form_Question_Submissions_Stddev_Fields = {
  readonly __typename?: 'form_question_submissions_stddev_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Stddev_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Question_Submissions_Stddev_Pop_Fields = {
  readonly __typename?: 'form_question_submissions_stddev_pop_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Stddev_Pop_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Question_Submissions_Stddev_Samp_Fields = {
  readonly __typename?: 'form_question_submissions_stddev_samp_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Stddev_Samp_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Question_Submissions_Sum_Fields = {
  readonly __typename?: 'form_question_submissions_sum_fields';
  readonly final_score?: Maybe<Scalars['numeric']>;
  readonly score_before_regrade?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Sum_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** update columns of table "form_question_submissions" */
export enum Form_Question_Submissions_Update_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  FinalScore = 'final_score',
  /** column name */
  FormQuestionId = 'form_question_id',
  /** column name */
  FormSubmissionId = 'form_submission_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastAnsweredAt = 'last_answered_at',
  /** column name */
  Remark = 'remark',
  /** column name */
  ScoreBeforeRegrade = 'score_before_regrade',
  /** column name */
  State = 'state',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Form_Question_Submissions_Var_Pop_Fields = {
  readonly __typename?: 'form_question_submissions_var_pop_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Var_Pop_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Question_Submissions_Var_Samp_Fields = {
  readonly __typename?: 'form_question_submissions_var_samp_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Var_Samp_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Question_Submissions_Variance_Fields = {
  readonly __typename?: 'form_question_submissions_variance_fields';
  readonly final_score?: Maybe<Scalars['Float']>;
  readonly score_before_regrade?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_question_submissions" */
export type Form_Question_Submissions_Variance_Order_By = {
  readonly final_score?: Maybe<Order_By>;
  readonly score_before_regrade?: Maybe<Order_By>;
};

/** columns and relationships of "form_question_types" */
export type Form_Question_Types = {
  readonly __typename?: 'form_question_types';
  /** An array relationship */
  readonly form_questions: ReadonlyArray<Form_Questions>;
  /** An aggregate relationship */
  readonly form_questions_aggregate: Form_Questions_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "form_question_types" */
export type Form_Question_TypesForm_QuestionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


/** columns and relationships of "form_question_types" */
export type Form_Question_TypesForm_Questions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};

/** aggregated selection of "form_question_types" */
export type Form_Question_Types_Aggregate = {
  readonly __typename?: 'form_question_types_aggregate';
  readonly aggregate?: Maybe<Form_Question_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Question_Types>;
};

/** aggregate fields of "form_question_types" */
export type Form_Question_Types_Aggregate_Fields = {
  readonly __typename?: 'form_question_types_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Question_Types_Max_Fields>;
  readonly min?: Maybe<Form_Question_Types_Min_Fields>;
};


/** aggregate fields of "form_question_types" */
export type Form_Question_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Question_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_question_types". All fields are combined with a logical 'AND'. */
export type Form_Question_Types_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Question_Types_Bool_Exp>>;
  readonly _not?: Maybe<Form_Question_Types_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Question_Types_Bool_Exp>>;
  readonly form_questions?: Maybe<Form_Questions_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_question_types" */
export enum Form_Question_Types_Constraint {
  /** unique or primary key constraint */
  FormQuestionTypesPkey = 'form_question_types_pkey'
}

export enum Form_Question_Types_Enum {
  Attachment = 'attachment',
  CheckboxAnswer = 'checkbox_answer',
  Essay = 'essay',
  MultipleChoice = 'multiple_choice',
  ShortAnswer = 'short_answer',
  Spacer = 'spacer'
}

/** Boolean expression to compare columns of type "form_question_types_enum". All fields are combined with logical 'AND'. */
export type Form_Question_Types_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Question_Types_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Question_Types_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Question_Types_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Question_Types_Enum>>;
};

/** input type for inserting data into table "form_question_types" */
export type Form_Question_Types_Insert_Input = {
  readonly form_questions?: Maybe<Form_Questions_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Question_Types_Max_Fields = {
  readonly __typename?: 'form_question_types_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Question_Types_Min_Fields = {
  readonly __typename?: 'form_question_types_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_question_types" */
export type Form_Question_Types_Mutation_Response = {
  readonly __typename?: 'form_question_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Question_Types>;
};

/** input type for inserting object relation for remote table "form_question_types" */
export type Form_Question_Types_Obj_Rel_Insert_Input = {
  readonly data: Form_Question_Types_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Question_Types_On_Conflict>;
};

/** on conflict condition type for table "form_question_types" */
export type Form_Question_Types_On_Conflict = {
  readonly constraint: Form_Question_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Question_Types_Update_Column>;
  readonly where?: Maybe<Form_Question_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "form_question_types". */
export type Form_Question_Types_Order_By = {
  readonly form_questions_aggregate?: Maybe<Form_Questions_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_question_types */
export type Form_Question_Types_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_question_types" */
export enum Form_Question_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_question_types" */
export type Form_Question_Types_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_question_types" */
export enum Form_Question_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "form_questions" */
export type Form_Questions = {
  readonly __typename?: 'form_questions';
  readonly caption?: Maybe<Scalars['String']>;
  readonly correct_feedback?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly form: Forms;
  readonly form_id: Scalars['uuid'];
  /** An array relationship */
  readonly form_question_answers: ReadonlyArray<Form_Question_Answers>;
  /** An aggregate relationship */
  readonly form_question_answers_aggregate: Form_Question_Answers_Aggregate;
  /** An array relationship */
  readonly form_question_submissions: ReadonlyArray<Form_Question_Submissions>;
  /** An aggregate relationship */
  readonly form_question_submissions_aggregate: Form_Question_Submissions_Aggregate;
  /** An object relationship */
  readonly form_question_type: Form_Question_Types;
  readonly id: Scalars['uuid'];
  readonly incorrect_feedback?: Maybe<Scalars['String']>;
  readonly indeterminate_feedback?: Maybe<Scalars['String']>;
  readonly is_case_sensitive: Scalars['Boolean'];
  readonly is_open_ended: Scalars['Boolean'];
  readonly maximum_answer_count: Scalars['numeric'];
  readonly name: Scalars['String'];
  readonly points_possible: Scalars['numeric'];
  readonly position: Scalars['numeric'];
  readonly type: Form_Question_Types_Enum;
};


/** columns and relationships of "form_questions" */
export type Form_QuestionsForm_Question_AnswersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


/** columns and relationships of "form_questions" */
export type Form_QuestionsForm_Question_Answers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


/** columns and relationships of "form_questions" */
export type Form_QuestionsForm_Question_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


/** columns and relationships of "form_questions" */
export type Form_QuestionsForm_Question_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};

/** aggregated selection of "form_questions" */
export type Form_Questions_Aggregate = {
  readonly __typename?: 'form_questions_aggregate';
  readonly aggregate?: Maybe<Form_Questions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Questions>;
};

/** aggregate fields of "form_questions" */
export type Form_Questions_Aggregate_Fields = {
  readonly __typename?: 'form_questions_aggregate_fields';
  readonly avg?: Maybe<Form_Questions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Questions_Max_Fields>;
  readonly min?: Maybe<Form_Questions_Min_Fields>;
  readonly stddev?: Maybe<Form_Questions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Form_Questions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Form_Questions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Form_Questions_Sum_Fields>;
  readonly var_pop?: Maybe<Form_Questions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Form_Questions_Var_Samp_Fields>;
  readonly variance?: Maybe<Form_Questions_Variance_Fields>;
};


/** aggregate fields of "form_questions" */
export type Form_Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_questions" */
export type Form_Questions_Aggregate_Order_By = {
  readonly avg?: Maybe<Form_Questions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Questions_Max_Order_By>;
  readonly min?: Maybe<Form_Questions_Min_Order_By>;
  readonly stddev?: Maybe<Form_Questions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Form_Questions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Form_Questions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Form_Questions_Sum_Order_By>;
  readonly var_pop?: Maybe<Form_Questions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Form_Questions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Form_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_questions" */
export type Form_Questions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Questions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Questions_Avg_Fields = {
  readonly __typename?: 'form_questions_avg_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_questions" */
export type Form_Questions_Avg_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_questions". All fields are combined with a logical 'AND'. */
export type Form_Questions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Questions_Bool_Exp>>;
  readonly _not?: Maybe<Form_Questions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Questions_Bool_Exp>>;
  readonly caption?: Maybe<String_Comparison_Exp>;
  readonly correct_feedback?: Maybe<String_Comparison_Exp>;
  readonly form?: Maybe<Forms_Bool_Exp>;
  readonly form_id?: Maybe<Uuid_Comparison_Exp>;
  readonly form_question_answers?: Maybe<Form_Question_Answers_Bool_Exp>;
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Bool_Exp>;
  readonly form_question_type?: Maybe<Form_Question_Types_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly incorrect_feedback?: Maybe<String_Comparison_Exp>;
  readonly indeterminate_feedback?: Maybe<String_Comparison_Exp>;
  readonly is_case_sensitive?: Maybe<Boolean_Comparison_Exp>;
  readonly is_open_ended?: Maybe<Boolean_Comparison_Exp>;
  readonly maximum_answer_count?: Maybe<Numeric_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly points_possible?: Maybe<Numeric_Comparison_Exp>;
  readonly position?: Maybe<Numeric_Comparison_Exp>;
  readonly type?: Maybe<Form_Question_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_questions" */
export enum Form_Questions_Constraint {
  /** unique or primary key constraint */
  FormQuestionsPkey = 'form_questions_pkey'
}

/** input type for incrementing numeric columns in table "form_questions" */
export type Form_Questions_Inc_Input = {
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "form_questions" */
export type Form_Questions_Insert_Input = {
  readonly caption?: Maybe<Scalars['String']>;
  readonly correct_feedback?: Maybe<Scalars['String']>;
  readonly form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly form_question_answers?: Maybe<Form_Question_Answers_Arr_Rel_Insert_Input>;
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Arr_Rel_Insert_Input>;
  readonly form_question_type?: Maybe<Form_Question_Types_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly incorrect_feedback?: Maybe<Scalars['String']>;
  readonly indeterminate_feedback?: Maybe<Scalars['String']>;
  readonly is_case_sensitive?: Maybe<Scalars['Boolean']>;
  readonly is_open_ended?: Maybe<Scalars['Boolean']>;
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
  readonly type?: Maybe<Form_Question_Types_Enum>;
};

/** aggregate max on columns */
export type Form_Questions_Max_Fields = {
  readonly __typename?: 'form_questions_max_fields';
  readonly caption?: Maybe<Scalars['String']>;
  readonly correct_feedback?: Maybe<Scalars['String']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly incorrect_feedback?: Maybe<Scalars['String']>;
  readonly indeterminate_feedback?: Maybe<Scalars['String']>;
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "form_questions" */
export type Form_Questions_Max_Order_By = {
  readonly caption?: Maybe<Order_By>;
  readonly correct_feedback?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly incorrect_feedback?: Maybe<Order_By>;
  readonly indeterminate_feedback?: Maybe<Order_By>;
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Questions_Min_Fields = {
  readonly __typename?: 'form_questions_min_fields';
  readonly caption?: Maybe<Scalars['String']>;
  readonly correct_feedback?: Maybe<Scalars['String']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly incorrect_feedback?: Maybe<Scalars['String']>;
  readonly indeterminate_feedback?: Maybe<Scalars['String']>;
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "form_questions" */
export type Form_Questions_Min_Order_By = {
  readonly caption?: Maybe<Order_By>;
  readonly correct_feedback?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly incorrect_feedback?: Maybe<Order_By>;
  readonly indeterminate_feedback?: Maybe<Order_By>;
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_questions" */
export type Form_Questions_Mutation_Response = {
  readonly __typename?: 'form_questions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Questions>;
};

/** input type for inserting object relation for remote table "form_questions" */
export type Form_Questions_Obj_Rel_Insert_Input = {
  readonly data: Form_Questions_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Questions_On_Conflict>;
};

/** on conflict condition type for table "form_questions" */
export type Form_Questions_On_Conflict = {
  readonly constraint: Form_Questions_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Questions_Update_Column>;
  readonly where?: Maybe<Form_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "form_questions". */
export type Form_Questions_Order_By = {
  readonly caption?: Maybe<Order_By>;
  readonly correct_feedback?: Maybe<Order_By>;
  readonly form?: Maybe<Forms_Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly form_question_answers_aggregate?: Maybe<Form_Question_Answers_Aggregate_Order_By>;
  readonly form_question_submissions_aggregate?: Maybe<Form_Question_Submissions_Aggregate_Order_By>;
  readonly form_question_type?: Maybe<Form_Question_Types_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly incorrect_feedback?: Maybe<Order_By>;
  readonly indeterminate_feedback?: Maybe<Order_By>;
  readonly is_case_sensitive?: Maybe<Order_By>;
  readonly is_open_ended?: Maybe<Order_By>;
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: form_questions */
export type Form_Questions_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_questions" */
export enum Form_Questions_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CorrectFeedback = 'correct_feedback',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncorrectFeedback = 'incorrect_feedback',
  /** column name */
  IndeterminateFeedback = 'indeterminate_feedback',
  /** column name */
  IsCaseSensitive = 'is_case_sensitive',
  /** column name */
  IsOpenEnded = 'is_open_ended',
  /** column name */
  MaximumAnswerCount = 'maximum_answer_count',
  /** column name */
  Name = 'name',
  /** column name */
  PointsPossible = 'points_possible',
  /** column name */
  Position = 'position',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "form_questions" */
export type Form_Questions_Set_Input = {
  readonly caption?: Maybe<Scalars['String']>;
  readonly correct_feedback?: Maybe<Scalars['String']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly incorrect_feedback?: Maybe<Scalars['String']>;
  readonly indeterminate_feedback?: Maybe<Scalars['String']>;
  readonly is_case_sensitive?: Maybe<Scalars['Boolean']>;
  readonly is_open_ended?: Maybe<Scalars['Boolean']>;
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
  readonly type?: Maybe<Form_Question_Types_Enum>;
};

/** aggregate stddev on columns */
export type Form_Questions_Stddev_Fields = {
  readonly __typename?: 'form_questions_stddev_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_questions" */
export type Form_Questions_Stddev_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Questions_Stddev_Pop_Fields = {
  readonly __typename?: 'form_questions_stddev_pop_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_questions" */
export type Form_Questions_Stddev_Pop_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Questions_Stddev_Samp_Fields = {
  readonly __typename?: 'form_questions_stddev_samp_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_questions" */
export type Form_Questions_Stddev_Samp_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Questions_Sum_Fields = {
  readonly __typename?: 'form_questions_sum_fields';
  readonly maximum_answer_count?: Maybe<Scalars['numeric']>;
  readonly points_possible?: Maybe<Scalars['numeric']>;
  readonly position?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "form_questions" */
export type Form_Questions_Sum_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** update columns of table "form_questions" */
export enum Form_Questions_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CorrectFeedback = 'correct_feedback',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncorrectFeedback = 'incorrect_feedback',
  /** column name */
  IndeterminateFeedback = 'indeterminate_feedback',
  /** column name */
  IsCaseSensitive = 'is_case_sensitive',
  /** column name */
  IsOpenEnded = 'is_open_ended',
  /** column name */
  MaximumAnswerCount = 'maximum_answer_count',
  /** column name */
  Name = 'name',
  /** column name */
  PointsPossible = 'points_possible',
  /** column name */
  Position = 'position',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Form_Questions_Var_Pop_Fields = {
  readonly __typename?: 'form_questions_var_pop_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_questions" */
export type Form_Questions_Var_Pop_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Questions_Var_Samp_Fields = {
  readonly __typename?: 'form_questions_var_samp_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_questions" */
export type Form_Questions_Var_Samp_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Questions_Variance_Fields = {
  readonly __typename?: 'form_questions_variance_fields';
  readonly maximum_answer_count?: Maybe<Scalars['Float']>;
  readonly points_possible?: Maybe<Scalars['Float']>;
  readonly position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_questions" */
export type Form_Questions_Variance_Order_By = {
  readonly maximum_answer_count?: Maybe<Order_By>;
  readonly points_possible?: Maybe<Order_By>;
  readonly position?: Maybe<Order_By>;
};

/** columns and relationships of "form_score" */
export type Form_Score = {
  readonly __typename?: 'form_score';
  readonly createdAt: Scalars['timestamp'];
  readonly formInstanceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly form_instance?: Maybe<Form_Instance>;
  readonly id: Scalars['uuid'];
  readonly score: Scalars['Int'];
  readonly timeStarted: Scalars['timestamp'];
  readonly timeSubmitted: Scalars['timestamp'];
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user?: Maybe<User>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "form_score" */
export type Form_Score_Aggregate = {
  readonly __typename?: 'form_score_aggregate';
  readonly aggregate?: Maybe<Form_Score_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Score>;
};

/** aggregate fields of "form_score" */
export type Form_Score_Aggregate_Fields = {
  readonly __typename?: 'form_score_aggregate_fields';
  readonly avg?: Maybe<Form_Score_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Score_Max_Fields>;
  readonly min?: Maybe<Form_Score_Min_Fields>;
  readonly stddev?: Maybe<Form_Score_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Form_Score_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Form_Score_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Form_Score_Sum_Fields>;
  readonly var_pop?: Maybe<Form_Score_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Form_Score_Var_Samp_Fields>;
  readonly variance?: Maybe<Form_Score_Variance_Fields>;
};


/** aggregate fields of "form_score" */
export type Form_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_score" */
export type Form_Score_Aggregate_Order_By = {
  readonly avg?: Maybe<Form_Score_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Score_Max_Order_By>;
  readonly min?: Maybe<Form_Score_Min_Order_By>;
  readonly stddev?: Maybe<Form_Score_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Form_Score_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Form_Score_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Form_Score_Sum_Order_By>;
  readonly var_pop?: Maybe<Form_Score_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Form_Score_Var_Samp_Order_By>;
  readonly variance?: Maybe<Form_Score_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_score" */
export type Form_Score_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Score_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Score_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Score_Avg_Fields = {
  readonly __typename?: 'form_score_avg_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_score" */
export type Form_Score_Avg_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_score". All fields are combined with a logical 'AND'. */
export type Form_Score_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Score_Bool_Exp>>;
  readonly _not?: Maybe<Form_Score_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Score_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly formInstanceId?: Maybe<Uuid_Comparison_Exp>;
  readonly form_instance?: Maybe<Form_Instance_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly score?: Maybe<Int_Comparison_Exp>;
  readonly timeStarted?: Maybe<Timestamp_Comparison_Exp>;
  readonly timeSubmitted?: Maybe<Timestamp_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_score" */
export enum Form_Score_Constraint {
  /** unique or primary key constraint */
  Pk_62dce56eef29aa3bc8eaed33576 = 'PK_62dce56eef29aa3bc8eaed33576'
}

/** input type for incrementing numeric columns in table "form_score" */
export type Form_Score_Inc_Input = {
  readonly score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "form_score" */
export type Form_Score_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formInstanceId?: Maybe<Scalars['uuid']>;
  readonly form_instance?: Maybe<Form_Instance_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly score?: Maybe<Scalars['Int']>;
  readonly timeStarted?: Maybe<Scalars['timestamp']>;
  readonly timeSubmitted?: Maybe<Scalars['timestamp']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Score_Max_Fields = {
  readonly __typename?: 'form_score_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formInstanceId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly score?: Maybe<Scalars['Int']>;
  readonly timeStarted?: Maybe<Scalars['timestamp']>;
  readonly timeSubmitted?: Maybe<Scalars['timestamp']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_score" */
export type Form_Score_Max_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly formInstanceId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly score?: Maybe<Order_By>;
  readonly timeStarted?: Maybe<Order_By>;
  readonly timeSubmitted?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Score_Min_Fields = {
  readonly __typename?: 'form_score_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formInstanceId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly score?: Maybe<Scalars['Int']>;
  readonly timeStarted?: Maybe<Scalars['timestamp']>;
  readonly timeSubmitted?: Maybe<Scalars['timestamp']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_score" */
export type Form_Score_Min_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly formInstanceId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly score?: Maybe<Order_By>;
  readonly timeStarted?: Maybe<Order_By>;
  readonly timeSubmitted?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_score" */
export type Form_Score_Mutation_Response = {
  readonly __typename?: 'form_score_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Score>;
};

/** on conflict condition type for table "form_score" */
export type Form_Score_On_Conflict = {
  readonly constraint: Form_Score_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Score_Update_Column>;
  readonly where?: Maybe<Form_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "form_score". */
export type Form_Score_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly formInstanceId?: Maybe<Order_By>;
  readonly form_instance?: Maybe<Form_Instance_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly score?: Maybe<Order_By>;
  readonly timeStarted?: Maybe<Order_By>;
  readonly timeSubmitted?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: form_score */
export type Form_Score_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_score" */
export enum Form_Score_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormInstanceId = 'formInstanceId',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  TimeStarted = 'timeStarted',
  /** column name */
  TimeSubmitted = 'timeSubmitted',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "form_score" */
export type Form_Score_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly formInstanceId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly score?: Maybe<Scalars['Int']>;
  readonly timeStarted?: Maybe<Scalars['timestamp']>;
  readonly timeSubmitted?: Maybe<Scalars['timestamp']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Form_Score_Stddev_Fields = {
  readonly __typename?: 'form_score_stddev_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_score" */
export type Form_Score_Stddev_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Score_Stddev_Pop_Fields = {
  readonly __typename?: 'form_score_stddev_pop_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_score" */
export type Form_Score_Stddev_Pop_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Score_Stddev_Samp_Fields = {
  readonly __typename?: 'form_score_stddev_samp_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_score" */
export type Form_Score_Stddev_Samp_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Score_Sum_Fields = {
  readonly __typename?: 'form_score_sum_fields';
  readonly score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "form_score" */
export type Form_Score_Sum_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** update columns of table "form_score" */
export enum Form_Score_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormInstanceId = 'formInstanceId',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  TimeStarted = 'timeStarted',
  /** column name */
  TimeSubmitted = 'timeSubmitted',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Form_Score_Var_Pop_Fields = {
  readonly __typename?: 'form_score_var_pop_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_score" */
export type Form_Score_Var_Pop_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Score_Var_Samp_Fields = {
  readonly __typename?: 'form_score_var_samp_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_score" */
export type Form_Score_Var_Samp_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Score_Variance_Fields = {
  readonly __typename?: 'form_score_variance_fields';
  readonly score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_score" */
export type Form_Score_Variance_Order_By = {
  readonly score?: Maybe<Order_By>;
};

/** columns and relationships of "form_scoring_policies" */
export type Form_Scoring_Policies = {
  readonly __typename?: 'form_scoring_policies';
  /** An array relationship */
  readonly forms: ReadonlyArray<Forms>;
  /** An aggregate relationship */
  readonly forms_aggregate: Forms_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "form_scoring_policies" */
export type Form_Scoring_PoliciesFormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


/** columns and relationships of "form_scoring_policies" */
export type Form_Scoring_PoliciesForms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};

/** aggregated selection of "form_scoring_policies" */
export type Form_Scoring_Policies_Aggregate = {
  readonly __typename?: 'form_scoring_policies_aggregate';
  readonly aggregate?: Maybe<Form_Scoring_Policies_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Scoring_Policies>;
};

/** aggregate fields of "form_scoring_policies" */
export type Form_Scoring_Policies_Aggregate_Fields = {
  readonly __typename?: 'form_scoring_policies_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Scoring_Policies_Max_Fields>;
  readonly min?: Maybe<Form_Scoring_Policies_Min_Fields>;
};


/** aggregate fields of "form_scoring_policies" */
export type Form_Scoring_Policies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Scoring_Policies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_scoring_policies". All fields are combined with a logical 'AND'. */
export type Form_Scoring_Policies_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Scoring_Policies_Bool_Exp>>;
  readonly _not?: Maybe<Form_Scoring_Policies_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Scoring_Policies_Bool_Exp>>;
  readonly forms?: Maybe<Forms_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_scoring_policies" */
export enum Form_Scoring_Policies_Constraint {
  /** unique or primary key constraint */
  FormScoringPoliciesPkey = 'form_scoring_policies_pkey'
}

export enum Form_Scoring_Policies_Enum {
  Highest = 'highest',
  Latest = 'latest'
}

/** Boolean expression to compare columns of type "form_scoring_policies_enum". All fields are combined with logical 'AND'. */
export type Form_Scoring_Policies_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Scoring_Policies_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Scoring_Policies_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Scoring_Policies_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Scoring_Policies_Enum>>;
};

/** input type for inserting data into table "form_scoring_policies" */
export type Form_Scoring_Policies_Insert_Input = {
  readonly forms?: Maybe<Forms_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Scoring_Policies_Max_Fields = {
  readonly __typename?: 'form_scoring_policies_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Scoring_Policies_Min_Fields = {
  readonly __typename?: 'form_scoring_policies_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_scoring_policies" */
export type Form_Scoring_Policies_Mutation_Response = {
  readonly __typename?: 'form_scoring_policies_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Scoring_Policies>;
};

/** input type for inserting object relation for remote table "form_scoring_policies" */
export type Form_Scoring_Policies_Obj_Rel_Insert_Input = {
  readonly data: Form_Scoring_Policies_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Scoring_Policies_On_Conflict>;
};

/** on conflict condition type for table "form_scoring_policies" */
export type Form_Scoring_Policies_On_Conflict = {
  readonly constraint: Form_Scoring_Policies_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Scoring_Policies_Update_Column>;
  readonly where?: Maybe<Form_Scoring_Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "form_scoring_policies". */
export type Form_Scoring_Policies_Order_By = {
  readonly forms_aggregate?: Maybe<Forms_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_scoring_policies */
export type Form_Scoring_Policies_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_scoring_policies" */
export enum Form_Scoring_Policies_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_scoring_policies" */
export type Form_Scoring_Policies_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_scoring_policies" */
export enum Form_Scoring_Policies_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "form" */
export enum Form_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Message = 'message',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  Title = 'title',
  /** column name */
  UnlockedStreams = 'unlockedStreams',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "form" */
export type Form_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly questionId?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly unlockedStreams?: Maybe<Scalars['_text']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "form_submission_statuses" */
export type Form_Submission_Statuses = {
  readonly __typename?: 'form_submission_statuses';
  readonly description?: Maybe<Scalars['String']>;
  readonly value: Scalars['String'];
};

/** aggregated selection of "form_submission_statuses" */
export type Form_Submission_Statuses_Aggregate = {
  readonly __typename?: 'form_submission_statuses_aggregate';
  readonly aggregate?: Maybe<Form_Submission_Statuses_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Submission_Statuses>;
};

/** aggregate fields of "form_submission_statuses" */
export type Form_Submission_Statuses_Aggregate_Fields = {
  readonly __typename?: 'form_submission_statuses_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Submission_Statuses_Max_Fields>;
  readonly min?: Maybe<Form_Submission_Statuses_Min_Fields>;
};


/** aggregate fields of "form_submission_statuses" */
export type Form_Submission_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Submission_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_submission_statuses". All fields are combined with a logical 'AND'. */
export type Form_Submission_Statuses_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Submission_Statuses_Bool_Exp>>;
  readonly _not?: Maybe<Form_Submission_Statuses_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Submission_Statuses_Bool_Exp>>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_submission_statuses" */
export enum Form_Submission_Statuses_Constraint {
  /** unique or primary key constraint */
  FormSubmissionStatusesPkey = 'form_submission_statuses_pkey'
}

export enum Form_Submission_Statuses_Enum {
  Completed = 'COMPLETED',
  CompletedByDeadline = 'COMPLETED_BY_DEADLINE',
  InProgress = 'IN_PROGRESS'
}

/** Boolean expression to compare columns of type "form_submission_statuses_enum". All fields are combined with logical 'AND'. */
export type Form_Submission_Statuses_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Submission_Statuses_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Submission_Statuses_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Submission_Statuses_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Submission_Statuses_Enum>>;
};

/** input type for inserting data into table "form_submission_statuses" */
export type Form_Submission_Statuses_Insert_Input = {
  readonly description?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Submission_Statuses_Max_Fields = {
  readonly __typename?: 'form_submission_statuses_max_fields';
  readonly description?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Submission_Statuses_Min_Fields = {
  readonly __typename?: 'form_submission_statuses_min_fields';
  readonly description?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_submission_statuses" */
export type Form_Submission_Statuses_Mutation_Response = {
  readonly __typename?: 'form_submission_statuses_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Submission_Statuses>;
};

/** on conflict condition type for table "form_submission_statuses" */
export type Form_Submission_Statuses_On_Conflict = {
  readonly constraint: Form_Submission_Statuses_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Submission_Statuses_Update_Column>;
  readonly where?: Maybe<Form_Submission_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "form_submission_statuses". */
export type Form_Submission_Statuses_Order_By = {
  readonly description?: Maybe<Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_submission_statuses */
export type Form_Submission_Statuses_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_submission_statuses" */
export enum Form_Submission_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_submission_statuses" */
export type Form_Submission_Statuses_Set_Input = {
  readonly description?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_submission_statuses" */
export enum Form_Submission_Statuses_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "form_submissions" */
export type Form_Submissions = {
  readonly __typename?: 'form_submissions';
  readonly attempt: Scalars['numeric'];
  /** An object relationship */
  readonly course_form?: Maybe<Course_Forms>;
  readonly course_form_id?: Maybe<Scalars['uuid']>;
  readonly deadline_at?: Maybe<Scalars['timestamptz']>;
  readonly finished_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  readonly form: Forms;
  readonly form_id: Scalars['uuid'];
  /** An array relationship */
  readonly form_question_submissions: ReadonlyArray<Form_Question_Submissions>;
  /** An aggregate relationship */
  readonly form_question_submissions_aggregate: Form_Question_Submissions_Aggregate;
  readonly id: Scalars['uuid'];
  readonly started_at: Scalars['timestamp'];
  readonly status?: Maybe<Form_Submission_Statuses_Enum>;
  readonly user_id: Scalars['String'];
};


/** columns and relationships of "form_submissions" */
export type Form_SubmissionsForm_Question_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


/** columns and relationships of "form_submissions" */
export type Form_SubmissionsForm_Question_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};

/** aggregated selection of "form_submissions" */
export type Form_Submissions_Aggregate = {
  readonly __typename?: 'form_submissions_aggregate';
  readonly aggregate?: Maybe<Form_Submissions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Submissions>;
};

/** aggregate fields of "form_submissions" */
export type Form_Submissions_Aggregate_Fields = {
  readonly __typename?: 'form_submissions_aggregate_fields';
  readonly avg?: Maybe<Form_Submissions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Submissions_Max_Fields>;
  readonly min?: Maybe<Form_Submissions_Min_Fields>;
  readonly stddev?: Maybe<Form_Submissions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Form_Submissions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Form_Submissions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Form_Submissions_Sum_Fields>;
  readonly var_pop?: Maybe<Form_Submissions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Form_Submissions_Var_Samp_Fields>;
  readonly variance?: Maybe<Form_Submissions_Variance_Fields>;
};


/** aggregate fields of "form_submissions" */
export type Form_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_submissions" */
export type Form_Submissions_Aggregate_Order_By = {
  readonly avg?: Maybe<Form_Submissions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Form_Submissions_Max_Order_By>;
  readonly min?: Maybe<Form_Submissions_Min_Order_By>;
  readonly stddev?: Maybe<Form_Submissions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Form_Submissions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Form_Submissions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Form_Submissions_Sum_Order_By>;
  readonly var_pop?: Maybe<Form_Submissions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Form_Submissions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Form_Submissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_submissions" */
export type Form_Submissions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Form_Submissions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Submissions_Avg_Fields = {
  readonly __typename?: 'form_submissions_avg_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_submissions" */
export type Form_Submissions_Avg_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_submissions". All fields are combined with a logical 'AND'. */
export type Form_Submissions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Submissions_Bool_Exp>>;
  readonly _not?: Maybe<Form_Submissions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Submissions_Bool_Exp>>;
  readonly attempt?: Maybe<Numeric_Comparison_Exp>;
  readonly course_form?: Maybe<Course_Forms_Bool_Exp>;
  readonly course_form_id?: Maybe<Uuid_Comparison_Exp>;
  readonly deadline_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly finished_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly form?: Maybe<Forms_Bool_Exp>;
  readonly form_id?: Maybe<Uuid_Comparison_Exp>;
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly started_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly status?: Maybe<Form_Submission_Statuses_Enum_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_submissions" */
export enum Form_Submissions_Constraint {
  /** unique or primary key constraint */
  FormSubmissionsPkey = 'form_submissions_pkey'
}

/** input type for incrementing numeric columns in table "form_submissions" */
export type Form_Submissions_Inc_Input = {
  readonly attempt?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "form_submissions" */
export type Form_Submissions_Insert_Input = {
  readonly attempt?: Maybe<Scalars['numeric']>;
  readonly course_form?: Maybe<Course_Forms_Obj_Rel_Insert_Input>;
  readonly course_form_id?: Maybe<Scalars['uuid']>;
  readonly deadline_at?: Maybe<Scalars['timestamptz']>;
  readonly finished_at?: Maybe<Scalars['timestamp']>;
  readonly form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly form_question_submissions?: Maybe<Form_Question_Submissions_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly status?: Maybe<Form_Submission_Statuses_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Submissions_Max_Fields = {
  readonly __typename?: 'form_submissions_max_fields';
  readonly attempt?: Maybe<Scalars['numeric']>;
  readonly course_form_id?: Maybe<Scalars['uuid']>;
  readonly deadline_at?: Maybe<Scalars['timestamptz']>;
  readonly finished_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_submissions" */
export type Form_Submissions_Max_Order_By = {
  readonly attempt?: Maybe<Order_By>;
  readonly course_form_id?: Maybe<Order_By>;
  readonly deadline_at?: Maybe<Order_By>;
  readonly finished_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Submissions_Min_Fields = {
  readonly __typename?: 'form_submissions_min_fields';
  readonly attempt?: Maybe<Scalars['numeric']>;
  readonly course_form_id?: Maybe<Scalars['uuid']>;
  readonly deadline_at?: Maybe<Scalars['timestamptz']>;
  readonly finished_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_submissions" */
export type Form_Submissions_Min_Order_By = {
  readonly attempt?: Maybe<Order_By>;
  readonly course_form_id?: Maybe<Order_By>;
  readonly deadline_at?: Maybe<Order_By>;
  readonly finished_at?: Maybe<Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_submissions" */
export type Form_Submissions_Mutation_Response = {
  readonly __typename?: 'form_submissions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Submissions>;
};

/** input type for inserting object relation for remote table "form_submissions" */
export type Form_Submissions_Obj_Rel_Insert_Input = {
  readonly data: Form_Submissions_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};

/** on conflict condition type for table "form_submissions" */
export type Form_Submissions_On_Conflict = {
  readonly constraint: Form_Submissions_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Submissions_Update_Column>;
  readonly where?: Maybe<Form_Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "form_submissions". */
export type Form_Submissions_Order_By = {
  readonly attempt?: Maybe<Order_By>;
  readonly course_form?: Maybe<Course_Forms_Order_By>;
  readonly course_form_id?: Maybe<Order_By>;
  readonly deadline_at?: Maybe<Order_By>;
  readonly finished_at?: Maybe<Order_By>;
  readonly form?: Maybe<Forms_Order_By>;
  readonly form_id?: Maybe<Order_By>;
  readonly form_question_submissions_aggregate?: Maybe<Form_Question_Submissions_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly started_at?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: form_submissions */
export type Form_Submissions_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "form_submissions" */
export enum Form_Submissions_Select_Column {
  /** column name */
  Attempt = 'attempt',
  /** column name */
  CourseFormId = 'course_form_id',
  /** column name */
  DeadlineAt = 'deadline_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "form_submissions" */
export type Form_Submissions_Set_Input = {
  readonly attempt?: Maybe<Scalars['numeric']>;
  readonly course_form_id?: Maybe<Scalars['uuid']>;
  readonly deadline_at?: Maybe<Scalars['timestamptz']>;
  readonly finished_at?: Maybe<Scalars['timestamp']>;
  readonly form_id?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly started_at?: Maybe<Scalars['timestamp']>;
  readonly status?: Maybe<Form_Submission_Statuses_Enum>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Form_Submissions_Stddev_Fields = {
  readonly __typename?: 'form_submissions_stddev_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_submissions" */
export type Form_Submissions_Stddev_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Submissions_Stddev_Pop_Fields = {
  readonly __typename?: 'form_submissions_stddev_pop_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_submissions" */
export type Form_Submissions_Stddev_Pop_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Submissions_Stddev_Samp_Fields = {
  readonly __typename?: 'form_submissions_stddev_samp_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_submissions" */
export type Form_Submissions_Stddev_Samp_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Submissions_Sum_Fields = {
  readonly __typename?: 'form_submissions_sum_fields';
  readonly attempt?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "form_submissions" */
export type Form_Submissions_Sum_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** update columns of table "form_submissions" */
export enum Form_Submissions_Update_Column {
  /** column name */
  Attempt = 'attempt',
  /** column name */
  CourseFormId = 'course_form_id',
  /** column name */
  DeadlineAt = 'deadline_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Form_Submissions_Var_Pop_Fields = {
  readonly __typename?: 'form_submissions_var_pop_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_submissions" */
export type Form_Submissions_Var_Pop_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Submissions_Var_Samp_Fields = {
  readonly __typename?: 'form_submissions_var_samp_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_submissions" */
export type Form_Submissions_Var_Samp_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Submissions_Variance_Fields = {
  readonly __typename?: 'form_submissions_variance_fields';
  readonly attempt?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_submissions" */
export type Form_Submissions_Variance_Order_By = {
  readonly attempt?: Maybe<Order_By>;
};

/** columns and relationships of "form_types" */
export type Form_Types = {
  readonly __typename?: 'form_types';
  /** An array relationship */
  readonly forms: ReadonlyArray<Forms>;
  /** An aggregate relationship */
  readonly forms_aggregate: Forms_Aggregate;
  readonly value: Scalars['String'];
};


/** columns and relationships of "form_types" */
export type Form_TypesFormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


/** columns and relationships of "form_types" */
export type Form_TypesForms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};

/** aggregated selection of "form_types" */
export type Form_Types_Aggregate = {
  readonly __typename?: 'form_types_aggregate';
  readonly aggregate?: Maybe<Form_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Form_Types>;
};

/** aggregate fields of "form_types" */
export type Form_Types_Aggregate_Fields = {
  readonly __typename?: 'form_types_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Form_Types_Max_Fields>;
  readonly min?: Maybe<Form_Types_Min_Fields>;
};


/** aggregate fields of "form_types" */
export type Form_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Form_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_types". All fields are combined with a logical 'AND'. */
export type Form_Types_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Form_Types_Bool_Exp>>;
  readonly _not?: Maybe<Form_Types_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Form_Types_Bool_Exp>>;
  readonly forms?: Maybe<Forms_Bool_Exp>;
  readonly value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_types" */
export enum Form_Types_Constraint {
  /** unique or primary key constraint */
  FormTypesPkey = 'form_types_pkey'
}

export enum Form_Types_Enum {
  Quiz = 'quiz',
  Survey = 'survey'
}

/** Boolean expression to compare columns of type "form_types_enum". All fields are combined with logical 'AND'. */
export type Form_Types_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Form_Types_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Form_Types_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Form_Types_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Form_Types_Enum>>;
};

/** input type for inserting data into table "form_types" */
export type Form_Types_Insert_Input = {
  readonly forms?: Maybe<Forms_Arr_Rel_Insert_Input>;
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Types_Max_Fields = {
  readonly __typename?: 'form_types_max_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Types_Min_Fields = {
  readonly __typename?: 'form_types_min_fields';
  readonly value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_types" */
export type Form_Types_Mutation_Response = {
  readonly __typename?: 'form_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Form_Types>;
};

/** input type for inserting object relation for remote table "form_types" */
export type Form_Types_Obj_Rel_Insert_Input = {
  readonly data: Form_Types_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Form_Types_On_Conflict>;
};

/** on conflict condition type for table "form_types" */
export type Form_Types_On_Conflict = {
  readonly constraint: Form_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Form_Types_Update_Column>;
  readonly where?: Maybe<Form_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "form_types". */
export type Form_Types_Order_By = {
  readonly forms_aggregate?: Maybe<Forms_Aggregate_Order_By>;
  readonly value?: Maybe<Order_By>;
};

/** primary key columns input for table: form_types */
export type Form_Types_Pk_Columns_Input = {
  readonly value: Scalars['String'];
};

/** select columns of table "form_types" */
export enum Form_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_types" */
export type Form_Types_Set_Input = {
  readonly value?: Maybe<Scalars['String']>;
};

/** update columns of table "form_types" */
export enum Form_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "form" */
export enum Form_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Message = 'message',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  Title = 'title',
  /** column name */
  UnlockedStreams = 'unlockedStreams',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "forms" */
export type Forms = {
  readonly __typename?: 'forms';
  readonly access_code?: Maybe<Scalars['String']>;
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly can_go_back: Scalars['Boolean'];
  /** An array relationship */
  readonly course_forms: ReadonlyArray<Course_Forms>;
  /** An aggregate relationship */
  readonly course_forms_aggregate: Course_Forms_Aggregate;
  readonly created_at: Scalars['timestamp'];
  readonly description?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly form_events: ReadonlyArray<Form_Events>;
  /** An aggregate relationship */
  readonly form_events_aggregate: Form_Events_Aggregate;
  /** An array relationship */
  readonly form_questions: ReadonlyArray<Form_Questions>;
  /** An aggregate relationship */
  readonly form_questions_aggregate: Form_Questions_Aggregate;
  /** An object relationship */
  readonly form_scoring_policy: Form_Scoring_Policies;
  /** An array relationship */
  readonly form_submissions: ReadonlyArray<Form_Submissions>;
  /** An aggregate relationship */
  readonly form_submissions_aggregate: Form_Submissions_Aggregate;
  /** An object relationship */
  readonly form_type: Form_Types;
  readonly id: Scalars['uuid'];
  readonly is_locked: Scalars['Boolean'];
  readonly is_practice: Scalars['Boolean'];
  readonly one_time_result: Scalars['Boolean'];
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly scoring_policy: Form_Scoring_Policies_Enum;
  readonly show_after_regrade: Scalars['Boolean'];
  readonly show_correct_response: Scalars['Boolean'];
  readonly show_rank_percentile: Scalars['Boolean'];
  readonly show_score: Scalars['Boolean'];
  readonly shuffle_question: Scalars['Boolean'];
  readonly text_selection: Scalars['Boolean'];
  readonly time_limit?: Maybe<Scalars['numeric']>;
  readonly title: Scalars['String'];
  readonly type: Form_Types_Enum;
};


/** columns and relationships of "forms" */
export type FormsCourse_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsCourse_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_QuestionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_Questions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  readonly __typename?: 'forms_aggregate';
  readonly aggregate?: Maybe<Forms_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Forms>;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  readonly __typename?: 'forms_aggregate_fields';
  readonly avg?: Maybe<Forms_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Forms_Max_Fields>;
  readonly min?: Maybe<Forms_Min_Fields>;
  readonly stddev?: Maybe<Forms_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Forms_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Forms_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Forms_Sum_Fields>;
  readonly var_pop?: Maybe<Forms_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Forms_Var_Samp_Fields>;
  readonly variance?: Maybe<Forms_Variance_Fields>;
};


/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forms" */
export type Forms_Aggregate_Order_By = {
  readonly avg?: Maybe<Forms_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Forms_Max_Order_By>;
  readonly min?: Maybe<Forms_Min_Order_By>;
  readonly stddev?: Maybe<Forms_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Forms_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Forms_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Forms_Sum_Order_By>;
  readonly var_pop?: Maybe<Forms_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Forms_Var_Samp_Order_By>;
  readonly variance?: Maybe<Forms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "forms" */
export type Forms_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Forms_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Forms_On_Conflict>;
};

/** aggregate avg on columns */
export type Forms_Avg_Fields = {
  readonly __typename?: 'forms_avg_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "forms" */
export type Forms_Avg_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Forms_Bool_Exp>>;
  readonly _not?: Maybe<Forms_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Forms_Bool_Exp>>;
  readonly access_code?: Maybe<String_Comparison_Exp>;
  readonly allowed_attempts?: Maybe<Numeric_Comparison_Exp>;
  readonly can_go_back?: Maybe<Boolean_Comparison_Exp>;
  readonly course_forms?: Maybe<Course_Forms_Bool_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly form_events?: Maybe<Form_Events_Bool_Exp>;
  readonly form_questions?: Maybe<Form_Questions_Bool_Exp>;
  readonly form_scoring_policy?: Maybe<Form_Scoring_Policies_Bool_Exp>;
  readonly form_submissions?: Maybe<Form_Submissions_Bool_Exp>;
  readonly form_type?: Maybe<Form_Types_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_locked?: Maybe<Boolean_Comparison_Exp>;
  readonly is_practice?: Maybe<Boolean_Comparison_Exp>;
  readonly one_time_result?: Maybe<Boolean_Comparison_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly scoring_policy?: Maybe<Form_Scoring_Policies_Enum_Comparison_Exp>;
  readonly show_after_regrade?: Maybe<Boolean_Comparison_Exp>;
  readonly show_correct_response?: Maybe<Boolean_Comparison_Exp>;
  readonly show_rank_percentile?: Maybe<Boolean_Comparison_Exp>;
  readonly show_score?: Maybe<Boolean_Comparison_Exp>;
  readonly shuffle_question?: Maybe<Boolean_Comparison_Exp>;
  readonly text_selection?: Maybe<Boolean_Comparison_Exp>;
  readonly time_limit?: Maybe<Numeric_Comparison_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly type?: Maybe<Form_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint */
  FormsPkey = 'forms_pkey'
}

/** input type for incrementing numeric columns in table "forms" */
export type Forms_Inc_Input = {
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  readonly access_code?: Maybe<Scalars['String']>;
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly can_go_back?: Maybe<Scalars['Boolean']>;
  readonly course_forms?: Maybe<Course_Forms_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly form_events?: Maybe<Form_Events_Arr_Rel_Insert_Input>;
  readonly form_questions?: Maybe<Form_Questions_Arr_Rel_Insert_Input>;
  readonly form_scoring_policy?: Maybe<Form_Scoring_Policies_Obj_Rel_Insert_Input>;
  readonly form_submissions?: Maybe<Form_Submissions_Arr_Rel_Insert_Input>;
  readonly form_type?: Maybe<Form_Types_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly is_practice?: Maybe<Scalars['Boolean']>;
  readonly one_time_result?: Maybe<Scalars['Boolean']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly scoring_policy?: Maybe<Form_Scoring_Policies_Enum>;
  readonly show_after_regrade?: Maybe<Scalars['Boolean']>;
  readonly show_correct_response?: Maybe<Scalars['Boolean']>;
  readonly show_rank_percentile?: Maybe<Scalars['Boolean']>;
  readonly show_score?: Maybe<Scalars['Boolean']>;
  readonly shuffle_question?: Maybe<Scalars['Boolean']>;
  readonly text_selection?: Maybe<Scalars['Boolean']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Form_Types_Enum>;
};

/** aggregate max on columns */
export type Forms_Max_Fields = {
  readonly __typename?: 'forms_max_fields';
  readonly access_code?: Maybe<Scalars['String']>;
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "forms" */
export type Forms_Max_Order_By = {
  readonly access_code?: Maybe<Order_By>;
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forms_Min_Fields = {
  readonly __typename?: 'forms_min_fields';
  readonly access_code?: Maybe<Scalars['String']>;
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "forms" */
export type Forms_Min_Order_By = {
  readonly access_code?: Maybe<Order_By>;
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  readonly __typename?: 'forms_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Forms>;
};

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  readonly data: Forms_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Forms_On_Conflict>;
};

/** on conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  readonly constraint: Forms_Constraint;
  readonly update_columns?: ReadonlyArray<Forms_Update_Column>;
  readonly where?: Maybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  readonly access_code?: Maybe<Order_By>;
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly can_go_back?: Maybe<Order_By>;
  readonly course_forms_aggregate?: Maybe<Course_Forms_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly form_events_aggregate?: Maybe<Form_Events_Aggregate_Order_By>;
  readonly form_questions_aggregate?: Maybe<Form_Questions_Aggregate_Order_By>;
  readonly form_scoring_policy?: Maybe<Form_Scoring_Policies_Order_By>;
  readonly form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Order_By>;
  readonly form_type?: Maybe<Form_Types_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_locked?: Maybe<Order_By>;
  readonly is_practice?: Maybe<Order_By>;
  readonly one_time_result?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly scoring_policy?: Maybe<Order_By>;
  readonly show_after_regrade?: Maybe<Order_By>;
  readonly show_correct_response?: Maybe<Order_By>;
  readonly show_rank_percentile?: Maybe<Order_By>;
  readonly show_score?: Maybe<Order_By>;
  readonly shuffle_question?: Maybe<Order_By>;
  readonly text_selection?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  AccessCode = 'access_code',
  /** column name */
  AllowedAttempts = 'allowed_attempts',
  /** column name */
  CanGoBack = 'can_go_back',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  IsPractice = 'is_practice',
  /** column name */
  OneTimeResult = 'one_time_result',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ScoringPolicy = 'scoring_policy',
  /** column name */
  ShowAfterRegrade = 'show_after_regrade',
  /** column name */
  ShowCorrectResponse = 'show_correct_response',
  /** column name */
  ShowRankPercentile = 'show_rank_percentile',
  /** column name */
  ShowScore = 'show_score',
  /** column name */
  ShuffleQuestion = 'shuffle_question',
  /** column name */
  TextSelection = 'text_selection',
  /** column name */
  TimeLimit = 'time_limit',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  readonly access_code?: Maybe<Scalars['String']>;
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly can_go_back?: Maybe<Scalars['Boolean']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly is_practice?: Maybe<Scalars['Boolean']>;
  readonly one_time_result?: Maybe<Scalars['Boolean']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly scoring_policy?: Maybe<Form_Scoring_Policies_Enum>;
  readonly show_after_regrade?: Maybe<Scalars['Boolean']>;
  readonly show_correct_response?: Maybe<Scalars['Boolean']>;
  readonly show_rank_percentile?: Maybe<Scalars['Boolean']>;
  readonly show_score?: Maybe<Scalars['Boolean']>;
  readonly shuffle_question?: Maybe<Scalars['Boolean']>;
  readonly text_selection?: Maybe<Scalars['Boolean']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Form_Types_Enum>;
};

/** aggregate stddev on columns */
export type Forms_Stddev_Fields = {
  readonly __typename?: 'forms_stddev_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "forms" */
export type Forms_Stddev_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Forms_Stddev_Pop_Fields = {
  readonly __typename?: 'forms_stddev_pop_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "forms" */
export type Forms_Stddev_Pop_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Forms_Stddev_Samp_Fields = {
  readonly __typename?: 'forms_stddev_samp_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "forms" */
export type Forms_Stddev_Samp_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Forms_Sum_Fields = {
  readonly __typename?: 'forms_sum_fields';
  readonly allowed_attempts?: Maybe<Scalars['numeric']>;
  readonly time_limit?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "forms" */
export type Forms_Sum_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  AccessCode = 'access_code',
  /** column name */
  AllowedAttempts = 'allowed_attempts',
  /** column name */
  CanGoBack = 'can_go_back',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  IsPractice = 'is_practice',
  /** column name */
  OneTimeResult = 'one_time_result',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ScoringPolicy = 'scoring_policy',
  /** column name */
  ShowAfterRegrade = 'show_after_regrade',
  /** column name */
  ShowCorrectResponse = 'show_correct_response',
  /** column name */
  ShowRankPercentile = 'show_rank_percentile',
  /** column name */
  ShowScore = 'show_score',
  /** column name */
  ShuffleQuestion = 'shuffle_question',
  /** column name */
  TextSelection = 'text_selection',
  /** column name */
  TimeLimit = 'time_limit',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Forms_Var_Pop_Fields = {
  readonly __typename?: 'forms_var_pop_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "forms" */
export type Forms_Var_Pop_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Forms_Var_Samp_Fields = {
  readonly __typename?: 'forms_var_samp_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "forms" */
export type Forms_Var_Samp_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Forms_Variance_Fields = {
  readonly __typename?: 'forms_variance_fields';
  readonly allowed_attempts?: Maybe<Scalars['Float']>;
  readonly time_limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "forms" */
export type Forms_Variance_Order_By = {
  readonly allowed_attempts?: Maybe<Order_By>;
  readonly time_limit?: Maybe<Order_By>;
};

/** columns and relationships of "images" */
export type Images = {
  readonly __typename?: 'images';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id: Scalars['uuid'];
  readonly name?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly provider?: Maybe<Provider>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  readonly user?: Maybe<User>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  readonly __typename?: 'images_aggregate';
  readonly aggregate?: Maybe<Images_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Images>;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  readonly __typename?: 'images_aggregate_fields';
  readonly avg?: Maybe<Images_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Images_Max_Fields>;
  readonly min?: Maybe<Images_Min_Fields>;
  readonly stddev?: Maybe<Images_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Images_Sum_Fields>;
  readonly var_pop?: Maybe<Images_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Images_Var_Samp_Fields>;
  readonly variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Images_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "images" */
export type Images_Aggregate_Order_By = {
  readonly avg?: Maybe<Images_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Images_Max_Order_By>;
  readonly min?: Maybe<Images_Min_Order_By>;
  readonly stddev?: Maybe<Images_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Images_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Images_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Images_Sum_Order_By>;
  readonly var_pop?: Maybe<Images_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Images_Var_Samp_Order_By>;
  readonly variance?: Maybe<Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "images" */
export type Images_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Images_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  readonly __typename?: 'images_avg_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "images" */
export type Images_Avg_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Images_Bool_Exp>>;
  readonly _not?: Maybe<Images_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Images_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly size?: Maybe<Int_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum Images_Constraint {
  /** unique or primary key constraint */
  ImagesPkey = 'images_pkey'
}

/** input type for incrementing numeric columns in table "images" */
export type Images_Inc_Input = {
  readonly size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "images" */
export type Images_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  readonly __typename?: 'images_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "images" */
export type Images_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly size?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  readonly __typename?: 'images_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "images" */
export type Images_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly size?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "images" */
export type Images_Mutation_Response = {
  readonly __typename?: 'images_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Images>;
};

/** on conflict condition type for table "images" */
export type Images_On_Conflict = {
  readonly constraint: Images_Constraint;
  readonly update_columns?: ReadonlyArray<Images_Update_Column>;
  readonly where?: Maybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly size?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: images */
export type Images_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "images" */
export type Images_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  readonly __typename?: 'images_stddev_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "images" */
export type Images_Stddev_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  readonly __typename?: 'images_stddev_pop_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "images" */
export type Images_Stddev_Pop_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  readonly __typename?: 'images_stddev_samp_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "images" */
export type Images_Stddev_Samp_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  readonly __typename?: 'images_sum_fields';
  readonly size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "images" */
export type Images_Sum_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** update columns of table "images" */
export enum Images_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  readonly __typename?: 'images_var_pop_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "images" */
export type Images_Var_Pop_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  readonly __typename?: 'images_var_samp_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "images" */
export type Images_Var_Samp_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  readonly __typename?: 'images_variance_fields';
  readonly size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "images" */
export type Images_Variance_Order_By = {
  readonly size?: Maybe<Order_By>;
};

/** columns and relationships of "industry" */
export type Industry = {
  readonly __typename?: 'industry';
  readonly categoryName?: Maybe<Scalars['String']>;
  readonly cpdUnitsRewardsId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward>;
  /** An array relationship */
  readonly cpd_units_rewards: ReadonlyArray<Cpd_Units_Reward>;
  /** An aggregate relationship */
  readonly cpd_units_rewards_aggregate: Cpd_Units_Reward_Aggregate;
  /** An array relationship */
  readonly event_industries_industries: ReadonlyArray<Event_Industries_Industry>;
  /** An aggregate relationship */
  readonly event_industries_industries_aggregate: Event_Industries_Industry_Aggregate;
  /** An object relationship */
  readonly industry_category?: Maybe<Industry_Category>;
  /** An object relationship */
  readonly industry_type?: Maybe<Industry_Type>;
  readonly isArchived: Scalars['Boolean'];
  readonly name: Scalars['String'];
  /** An array relationship */
  readonly provider_application_industries_industries: ReadonlyArray<Provider_Application_Industries_Industry>;
  /** An aggregate relationship */
  readonly provider_application_industries_industries_aggregate: Provider_Application_Industries_Industry_Aggregate;
  /** An array relationship */
  readonly provider_frequently_used_industries_industries: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
  /** An aggregate relationship */
  readonly provider_frequently_used_industries_industries_aggregate: Provider_Frequently_Used_Industries_Industry_Aggregate;
  readonly typeName?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly user_followed_industries_industries: ReadonlyArray<User_Followed_Industries_Industry>;
  /** An aggregate relationship */
  readonly user_followed_industries_industries_aggregate: User_Followed_Industries_Industry_Aggregate;
};


/** columns and relationships of "industry" */
export type IndustryCpd_Units_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryCpd_Units_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryEvent_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryEvent_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryProvider_Application_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryProvider_Application_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryProvider_Frequently_Used_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryProvider_Frequently_Used_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryUser_Followed_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryUser_Followed_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};

/** aggregated selection of "industry" */
export type Industry_Aggregate = {
  readonly __typename?: 'industry_aggregate';
  readonly aggregate?: Maybe<Industry_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Industry>;
};

/** aggregate fields of "industry" */
export type Industry_Aggregate_Fields = {
  readonly __typename?: 'industry_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Industry_Max_Fields>;
  readonly min?: Maybe<Industry_Min_Fields>;
};


/** aggregate fields of "industry" */
export type Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "industry" */
export type Industry_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Industry_Max_Order_By>;
  readonly min?: Maybe<Industry_Min_Order_By>;
};

/** input type for inserting array relation for remote table "industry" */
export type Industry_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Industry_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Industry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "industry". All fields are combined with a logical 'AND'. */
export type Industry_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Industry_Bool_Exp>>;
  readonly _not?: Maybe<Industry_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Industry_Bool_Exp>>;
  readonly categoryName?: Maybe<String_Comparison_Exp>;
  readonly cpdUnitsRewardsId?: Maybe<Uuid_Comparison_Exp>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Bool_Exp>;
  readonly cpd_units_rewards?: Maybe<Cpd_Units_Reward_Bool_Exp>;
  readonly event_industries_industries?: Maybe<Event_Industries_Industry_Bool_Exp>;
  readonly industry_category?: Maybe<Industry_Category_Bool_Exp>;
  readonly industry_type?: Maybe<Industry_Type_Bool_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly provider_application_industries_industries?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
  readonly provider_frequently_used_industries_industries?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
  readonly typeName?: Maybe<String_Comparison_Exp>;
  readonly user_followed_industries_industries?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};

/** columns and relationships of "industry_category" */
export type Industry_Category = {
  readonly __typename?: 'industry_category';
  /** An array relationship */
  readonly industries: ReadonlyArray<Industry>;
  /** An aggregate relationship */
  readonly industries_aggregate: Industry_Aggregate;
  readonly isArchived: Scalars['Boolean'];
  readonly name: Scalars['String'];
};


/** columns and relationships of "industry_category" */
export type Industry_CategoryIndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** columns and relationships of "industry_category" */
export type Industry_CategoryIndustries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};

/** aggregated selection of "industry_category" */
export type Industry_Category_Aggregate = {
  readonly __typename?: 'industry_category_aggregate';
  readonly aggregate?: Maybe<Industry_Category_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Industry_Category>;
};

/** aggregate fields of "industry_category" */
export type Industry_Category_Aggregate_Fields = {
  readonly __typename?: 'industry_category_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Industry_Category_Max_Fields>;
  readonly min?: Maybe<Industry_Category_Min_Fields>;
};


/** aggregate fields of "industry_category" */
export type Industry_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Industry_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "industry_category". All fields are combined with a logical 'AND'. */
export type Industry_Category_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Industry_Category_Bool_Exp>>;
  readonly _not?: Maybe<Industry_Category_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Industry_Category_Bool_Exp>>;
  readonly industries?: Maybe<Industry_Bool_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "industry_category" */
export enum Industry_Category_Constraint {
  /** unique or primary key constraint */
  Pk_9f26e3c0d649615ec7849aea05e = 'PK_9f26e3c0d649615ec7849aea05e'
}

/** input type for inserting data into table "industry_category" */
export type Industry_Category_Insert_Input = {
  readonly industries?: Maybe<Industry_Arr_Rel_Insert_Input>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Industry_Category_Max_Fields = {
  readonly __typename?: 'industry_category_max_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Industry_Category_Min_Fields = {
  readonly __typename?: 'industry_category_min_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "industry_category" */
export type Industry_Category_Mutation_Response = {
  readonly __typename?: 'industry_category_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Industry_Category>;
};

/** input type for inserting object relation for remote table "industry_category" */
export type Industry_Category_Obj_Rel_Insert_Input = {
  readonly data: Industry_Category_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Industry_Category_On_Conflict>;
};

/** on conflict condition type for table "industry_category" */
export type Industry_Category_On_Conflict = {
  readonly constraint: Industry_Category_Constraint;
  readonly update_columns?: ReadonlyArray<Industry_Category_Update_Column>;
  readonly where?: Maybe<Industry_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "industry_category". */
export type Industry_Category_Order_By = {
  readonly industries_aggregate?: Maybe<Industry_Aggregate_Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
};

/** primary key columns input for table: industry_category */
export type Industry_Category_Pk_Columns_Input = {
  readonly name: Scalars['String'];
};

/** select columns of table "industry_category" */
export enum Industry_Category_Select_Column {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "industry_category" */
export type Industry_Category_Set_Input = {
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** update columns of table "industry_category" */
export enum Industry_Category_Update_Column {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name'
}

/** unique or primary key constraints on table "industry" */
export enum Industry_Constraint {
  /** unique or primary key constraint */
  PkE756cbed5e9f27221c238f11fcc = 'PK_e756cbed5e9f27221c238f11fcc'
}

/** input type for inserting data into table "industry" */
export type Industry_Insert_Input = {
  readonly categoryName?: Maybe<Scalars['String']>;
  readonly cpdUnitsRewardsId?: Maybe<Scalars['uuid']>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Obj_Rel_Insert_Input>;
  readonly cpd_units_rewards?: Maybe<Cpd_Units_Reward_Arr_Rel_Insert_Input>;
  readonly event_industries_industries?: Maybe<Event_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly industry_category?: Maybe<Industry_Category_Obj_Rel_Insert_Input>;
  readonly industry_type?: Maybe<Industry_Type_Obj_Rel_Insert_Input>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_application_industries_industries?: Maybe<Provider_Application_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly provider_frequently_used_industries_industries?: Maybe<Provider_Frequently_Used_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly user_followed_industries_industries?: Maybe<User_Followed_Industries_Industry_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Industry_Max_Fields = {
  readonly __typename?: 'industry_max_fields';
  readonly categoryName?: Maybe<Scalars['String']>;
  readonly cpdUnitsRewardsId?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "industry" */
export type Industry_Max_Order_By = {
  readonly categoryName?: Maybe<Order_By>;
  readonly cpdUnitsRewardsId?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly typeName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Industry_Min_Fields = {
  readonly __typename?: 'industry_min_fields';
  readonly categoryName?: Maybe<Scalars['String']>;
  readonly cpdUnitsRewardsId?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "industry" */
export type Industry_Min_Order_By = {
  readonly categoryName?: Maybe<Order_By>;
  readonly cpdUnitsRewardsId?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly typeName?: Maybe<Order_By>;
};

/** response of any mutation on the table "industry" */
export type Industry_Mutation_Response = {
  readonly __typename?: 'industry_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Industry>;
};

/** input type for inserting object relation for remote table "industry" */
export type Industry_Obj_Rel_Insert_Input = {
  readonly data: Industry_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Industry_On_Conflict>;
};

/** on conflict condition type for table "industry" */
export type Industry_On_Conflict = {
  readonly constraint: Industry_Constraint;
  readonly update_columns?: ReadonlyArray<Industry_Update_Column>;
  readonly where?: Maybe<Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "industry". */
export type Industry_Order_By = {
  readonly categoryName?: Maybe<Order_By>;
  readonly cpdUnitsRewardsId?: Maybe<Order_By>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Order_By>;
  readonly cpd_units_rewards_aggregate?: Maybe<Cpd_Units_Reward_Aggregate_Order_By>;
  readonly event_industries_industries_aggregate?: Maybe<Event_Industries_Industry_Aggregate_Order_By>;
  readonly industry_category?: Maybe<Industry_Category_Order_By>;
  readonly industry_type?: Maybe<Industry_Type_Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_application_industries_industries_aggregate?: Maybe<Provider_Application_Industries_Industry_Aggregate_Order_By>;
  readonly provider_frequently_used_industries_industries_aggregate?: Maybe<Provider_Frequently_Used_Industries_Industry_Aggregate_Order_By>;
  readonly typeName?: Maybe<Order_By>;
  readonly user_followed_industries_industries_aggregate?: Maybe<User_Followed_Industries_Industry_Aggregate_Order_By>;
};

/** primary key columns input for table: industry */
export type Industry_Pk_Columns_Input = {
  readonly name: Scalars['String'];
};

/** select columns of table "industry" */
export enum Industry_Select_Column {
  /** column name */
  CategoryName = 'categoryName',
  /** column name */
  CpdUnitsRewardsId = 'cpdUnitsRewardsId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name',
  /** column name */
  TypeName = 'typeName'
}

/** input type for updating data in table "industry" */
export type Industry_Set_Input = {
  readonly categoryName?: Maybe<Scalars['String']>;
  readonly cpdUnitsRewardsId?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
};

/** columns and relationships of "industry_type" */
export type Industry_Type = {
  readonly __typename?: 'industry_type';
  /** An array relationship */
  readonly industries: ReadonlyArray<Industry>;
  /** An aggregate relationship */
  readonly industries_aggregate: Industry_Aggregate;
  readonly isArchived: Scalars['Boolean'];
  readonly name: Scalars['String'];
};


/** columns and relationships of "industry_type" */
export type Industry_TypeIndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** columns and relationships of "industry_type" */
export type Industry_TypeIndustries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};

/** aggregated selection of "industry_type" */
export type Industry_Type_Aggregate = {
  readonly __typename?: 'industry_type_aggregate';
  readonly aggregate?: Maybe<Industry_Type_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Industry_Type>;
};

/** aggregate fields of "industry_type" */
export type Industry_Type_Aggregate_Fields = {
  readonly __typename?: 'industry_type_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Industry_Type_Max_Fields>;
  readonly min?: Maybe<Industry_Type_Min_Fields>;
};


/** aggregate fields of "industry_type" */
export type Industry_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Industry_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "industry_type". All fields are combined with a logical 'AND'. */
export type Industry_Type_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Industry_Type_Bool_Exp>>;
  readonly _not?: Maybe<Industry_Type_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Industry_Type_Bool_Exp>>;
  readonly industries?: Maybe<Industry_Bool_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "industry_type" */
export enum Industry_Type_Constraint {
  /** unique or primary key constraint */
  Pk_4b859f24cdd5887f734164b79df = 'PK_4b859f24cdd5887f734164b79df'
}

/** input type for inserting data into table "industry_type" */
export type Industry_Type_Insert_Input = {
  readonly industries?: Maybe<Industry_Arr_Rel_Insert_Input>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Industry_Type_Max_Fields = {
  readonly __typename?: 'industry_type_max_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Industry_Type_Min_Fields = {
  readonly __typename?: 'industry_type_min_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "industry_type" */
export type Industry_Type_Mutation_Response = {
  readonly __typename?: 'industry_type_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Industry_Type>;
};

/** input type for inserting object relation for remote table "industry_type" */
export type Industry_Type_Obj_Rel_Insert_Input = {
  readonly data: Industry_Type_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Industry_Type_On_Conflict>;
};

/** on conflict condition type for table "industry_type" */
export type Industry_Type_On_Conflict = {
  readonly constraint: Industry_Type_Constraint;
  readonly update_columns?: ReadonlyArray<Industry_Type_Update_Column>;
  readonly where?: Maybe<Industry_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "industry_type". */
export type Industry_Type_Order_By = {
  readonly industries_aggregate?: Maybe<Industry_Aggregate_Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
};

/** primary key columns input for table: industry_type */
export type Industry_Type_Pk_Columns_Input = {
  readonly name: Scalars['String'];
};

/** select columns of table "industry_type" */
export enum Industry_Type_Select_Column {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "industry_type" */
export type Industry_Type_Set_Input = {
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

/** update columns of table "industry_type" */
export enum Industry_Type_Update_Column {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name'
}

/** update columns of table "industry" */
export enum Industry_Update_Column {
  /** column name */
  CategoryName = 'categoryName',
  /** column name */
  CpdUnitsRewardsId = 'cpdUnitsRewardsId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name',
  /** column name */
  TypeName = 'typeName'
}

/** columns and relationships of "migrations" */
export type Migrations = {
  readonly __typename?: 'migrations';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly timestamp: Scalars['bigint'];
};

/** aggregated selection of "migrations" */
export type Migrations_Aggregate = {
  readonly __typename?: 'migrations_aggregate';
  readonly aggregate?: Maybe<Migrations_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Migrations>;
};

/** aggregate fields of "migrations" */
export type Migrations_Aggregate_Fields = {
  readonly __typename?: 'migrations_aggregate_fields';
  readonly avg?: Maybe<Migrations_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Migrations_Max_Fields>;
  readonly min?: Maybe<Migrations_Min_Fields>;
  readonly stddev?: Maybe<Migrations_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Migrations_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Migrations_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Migrations_Sum_Fields>;
  readonly var_pop?: Maybe<Migrations_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Migrations_Var_Samp_Fields>;
  readonly variance?: Maybe<Migrations_Variance_Fields>;
};


/** aggregate fields of "migrations" */
export type Migrations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Migrations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Migrations_Avg_Fields = {
  readonly __typename?: 'migrations_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "migrations". All fields are combined with a logical 'AND'. */
export type Migrations_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Migrations_Bool_Exp>>;
  readonly _not?: Maybe<Migrations_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Migrations_Bool_Exp>>;
  readonly id?: Maybe<Int_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly timestamp?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "migrations" */
export enum Migrations_Constraint {
  /** unique or primary key constraint */
  Pk_8c82d7f526340ab734260ea46be = 'PK_8c82d7f526340ab734260ea46be'
}

/** input type for incrementing numeric columns in table "migrations" */
export type Migrations_Inc_Input = {
  readonly id?: Maybe<Scalars['Int']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "migrations" */
export type Migrations_Insert_Input = {
  readonly id?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Migrations_Max_Fields = {
  readonly __typename?: 'migrations_max_fields';
  readonly id?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Migrations_Min_Fields = {
  readonly __typename?: 'migrations_min_fields';
  readonly id?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "migrations" */
export type Migrations_Mutation_Response = {
  readonly __typename?: 'migrations_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Migrations>;
};

/** on conflict condition type for table "migrations" */
export type Migrations_On_Conflict = {
  readonly constraint: Migrations_Constraint;
  readonly update_columns?: ReadonlyArray<Migrations_Update_Column>;
  readonly where?: Maybe<Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "migrations". */
export type Migrations_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly timestamp?: Maybe<Order_By>;
};

/** primary key columns input for table: migrations */
export type Migrations_Pk_Columns_Input = {
  readonly id: Scalars['Int'];
};

/** select columns of table "migrations" */
export enum Migrations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "migrations" */
export type Migrations_Set_Input = {
  readonly id?: Maybe<Scalars['Int']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Migrations_Stddev_Fields = {
  readonly __typename?: 'migrations_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Migrations_Stddev_Pop_Fields = {
  readonly __typename?: 'migrations_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Migrations_Stddev_Samp_Fields = {
  readonly __typename?: 'migrations_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Migrations_Sum_Fields = {
  readonly __typename?: 'migrations_sum_fields';
  readonly id?: Maybe<Scalars['Int']>;
  readonly timestamp?: Maybe<Scalars['bigint']>;
};

/** update columns of table "migrations" */
export enum Migrations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate var_pop on columns */
export type Migrations_Var_Pop_Fields = {
  readonly __typename?: 'migrations_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Migrations_Var_Samp_Fields = {
  readonly __typename?: 'migrations_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Migrations_Variance_Fields = {
  readonly __typename?: 'migrations_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly timestamp?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mock_order" */
export type Mock_Order = {
  readonly __typename?: 'mock_order';
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly purchaseDate: Scalars['timestamp'];
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total: Scalars['Int'];
  readonly updatedAt: Scalars['timestamp'];
};

/** columns and relationships of "mock_order2" */
export type Mock_Order2 = {
  readonly __typename?: 'mock_order2';
  readonly createdAt: Scalars['timestamp'];
  readonly customerId?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly purchaseDate: Scalars['timestamp'];
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total: Scalars['Int'];
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user?: Maybe<User>;
};

/** aggregated selection of "mock_order2" */
export type Mock_Order2_Aggregate = {
  readonly __typename?: 'mock_order2_aggregate';
  readonly aggregate?: Maybe<Mock_Order2_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Mock_Order2>;
};

/** aggregate fields of "mock_order2" */
export type Mock_Order2_Aggregate_Fields = {
  readonly __typename?: 'mock_order2_aggregate_fields';
  readonly avg?: Maybe<Mock_Order2_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Mock_Order2_Max_Fields>;
  readonly min?: Maybe<Mock_Order2_Min_Fields>;
  readonly stddev?: Maybe<Mock_Order2_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Mock_Order2_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Mock_Order2_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Mock_Order2_Sum_Fields>;
  readonly var_pop?: Maybe<Mock_Order2_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Mock_Order2_Var_Samp_Fields>;
  readonly variance?: Maybe<Mock_Order2_Variance_Fields>;
};


/** aggregate fields of "mock_order2" */
export type Mock_Order2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mock_order2" */
export type Mock_Order2_Aggregate_Order_By = {
  readonly avg?: Maybe<Mock_Order2_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Mock_Order2_Max_Order_By>;
  readonly min?: Maybe<Mock_Order2_Min_Order_By>;
  readonly stddev?: Maybe<Mock_Order2_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Mock_Order2_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Mock_Order2_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Mock_Order2_Sum_Order_By>;
  readonly var_pop?: Maybe<Mock_Order2_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Mock_Order2_Var_Samp_Order_By>;
  readonly variance?: Maybe<Mock_Order2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mock_order2" */
export type Mock_Order2_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Mock_Order2_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Mock_Order2_On_Conflict>;
};

/** aggregate avg on columns */
export type Mock_Order2_Avg_Fields = {
  readonly __typename?: 'mock_order2_avg_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mock_order2" */
export type Mock_Order2_Avg_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mock_order2". All fields are combined with a logical 'AND'. */
export type Mock_Order2_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Mock_Order2_Bool_Exp>>;
  readonly _not?: Maybe<Mock_Order2_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Mock_Order2_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly customerId?: Maybe<String_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly purchaseDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly refno?: Maybe<String_Comparison_Exp>;
  readonly status?: Maybe<String_Comparison_Exp>;
  readonly total?: Maybe<Int_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "mock_order2" */
export enum Mock_Order2_Constraint {
  /** unique or primary key constraint */
  Pk_48b92faa9ec805320f33948553b = 'PK_48b92faa9ec805320f33948553b'
}

/** input type for incrementing numeric columns in table "mock_order2" */
export type Mock_Order2_Inc_Input = {
  readonly total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mock_order2" */
export type Mock_Order2_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mock_Order2_Max_Fields = {
  readonly __typename?: 'mock_order2_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "mock_order2" */
export type Mock_Order2_Max_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mock_Order2_Min_Fields = {
  readonly __typename?: 'mock_order2_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "mock_order2" */
export type Mock_Order2_Min_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "mock_order2" */
export type Mock_Order2_Mutation_Response = {
  readonly __typename?: 'mock_order2_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Mock_Order2>;
};

/** on conflict condition type for table "mock_order2" */
export type Mock_Order2_On_Conflict = {
  readonly constraint: Mock_Order2_Constraint;
  readonly update_columns?: ReadonlyArray<Mock_Order2_Update_Column>;
  readonly where?: Maybe<Mock_Order2_Bool_Exp>;
};

/** Ordering options when selecting data from "mock_order2". */
export type Mock_Order2_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: mock_order2 */
export type Mock_Order2_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "mock_order2" */
export enum Mock_Order2_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "mock_order2" */
export type Mock_Order2_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Mock_Order2_Stddev_Fields = {
  readonly __typename?: 'mock_order2_stddev_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mock_order2" */
export type Mock_Order2_Stddev_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mock_Order2_Stddev_Pop_Fields = {
  readonly __typename?: 'mock_order2_stddev_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mock_order2" */
export type Mock_Order2_Stddev_Pop_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mock_Order2_Stddev_Samp_Fields = {
  readonly __typename?: 'mock_order2_stddev_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mock_order2" */
export type Mock_Order2_Stddev_Samp_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mock_Order2_Sum_Fields = {
  readonly __typename?: 'mock_order2_sum_fields';
  readonly total?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mock_order2" */
export type Mock_Order2_Sum_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** update columns of table "mock_order2" */
export enum Mock_Order2_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Mock_Order2_Var_Pop_Fields = {
  readonly __typename?: 'mock_order2_var_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mock_order2" */
export type Mock_Order2_Var_Pop_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mock_Order2_Var_Samp_Fields = {
  readonly __typename?: 'mock_order2_var_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mock_order2" */
export type Mock_Order2_Var_Samp_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mock_Order2_Variance_Fields = {
  readonly __typename?: 'mock_order2_variance_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mock_order2" */
export type Mock_Order2_Variance_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregated selection of "mock_order" */
export type Mock_Order_Aggregate = {
  readonly __typename?: 'mock_order_aggregate';
  readonly aggregate?: Maybe<Mock_Order_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Mock_Order>;
};

/** aggregate fields of "mock_order" */
export type Mock_Order_Aggregate_Fields = {
  readonly __typename?: 'mock_order_aggregate_fields';
  readonly avg?: Maybe<Mock_Order_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Mock_Order_Max_Fields>;
  readonly min?: Maybe<Mock_Order_Min_Fields>;
  readonly stddev?: Maybe<Mock_Order_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Mock_Order_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Mock_Order_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Mock_Order_Sum_Fields>;
  readonly var_pop?: Maybe<Mock_Order_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Mock_Order_Var_Samp_Fields>;
  readonly variance?: Maybe<Mock_Order_Variance_Fields>;
};


/** aggregate fields of "mock_order" */
export type Mock_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Mock_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mock_Order_Avg_Fields = {
  readonly __typename?: 'mock_order_avg_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mock_order". All fields are combined with a logical 'AND'. */
export type Mock_Order_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Mock_Order_Bool_Exp>>;
  readonly _not?: Maybe<Mock_Order_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Mock_Order_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly purchaseDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly refno?: Maybe<String_Comparison_Exp>;
  readonly status?: Maybe<String_Comparison_Exp>;
  readonly total?: Maybe<Int_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "mock_order" */
export enum Mock_Order_Constraint {
  /** unique or primary key constraint */
  Pk_700ef8db119557812cfcc05a217 = 'PK_700ef8db119557812cfcc05a217'
}

/** input type for incrementing numeric columns in table "mock_order" */
export type Mock_Order_Inc_Input = {
  readonly total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mock_order" */
export type Mock_Order_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Mock_Order_Max_Fields = {
  readonly __typename?: 'mock_order_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Mock_Order_Min_Fields = {
  readonly __typename?: 'mock_order_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "mock_order" */
export type Mock_Order_Mutation_Response = {
  readonly __typename?: 'mock_order_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Mock_Order>;
};

/** on conflict condition type for table "mock_order" */
export type Mock_Order_On_Conflict = {
  readonly constraint: Mock_Order_Constraint;
  readonly update_columns?: ReadonlyArray<Mock_Order_Update_Column>;
  readonly where?: Maybe<Mock_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "mock_order". */
export type Mock_Order_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: mock_order */
export type Mock_Order_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "mock_order" */
export enum Mock_Order_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "mock_order" */
export type Mock_Order_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Mock_Order_Stddev_Fields = {
  readonly __typename?: 'mock_order_stddev_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mock_Order_Stddev_Pop_Fields = {
  readonly __typename?: 'mock_order_stddev_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mock_Order_Stddev_Samp_Fields = {
  readonly __typename?: 'mock_order_stddev_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Mock_Order_Sum_Fields = {
  readonly __typename?: 'mock_order_sum_fields';
  readonly total?: Maybe<Scalars['Int']>;
};

/** update columns of table "mock_order" */
export enum Mock_Order_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Mock_Order_Var_Pop_Fields = {
  readonly __typename?: 'mock_order_var_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mock_Order_Var_Samp_Fields = {
  readonly __typename?: 'mock_order_var_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mock_Order_Variance_Fields = {
  readonly __typename?: 'mock_order_variance_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "module_item_comments" */
export type Module_Item_Comments = {
  readonly __typename?: 'module_item_comments';
  readonly created_at: Scalars['timestamp'];
  readonly id: Scalars['bigint'];
  readonly is_provider?: Maybe<Scalars['Boolean']>;
  readonly is_visible: Scalars['Boolean'];
  readonly message: Scalars['String'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at: Scalars['timestamp'];
  readonly user_id: Scalars['String'];
};

/** aggregated selection of "module_item_comments" */
export type Module_Item_Comments_Aggregate = {
  readonly __typename?: 'module_item_comments_aggregate';
  readonly aggregate?: Maybe<Module_Item_Comments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Module_Item_Comments>;
};

/** aggregate fields of "module_item_comments" */
export type Module_Item_Comments_Aggregate_Fields = {
  readonly __typename?: 'module_item_comments_aggregate_fields';
  readonly avg?: Maybe<Module_Item_Comments_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Module_Item_Comments_Max_Fields>;
  readonly min?: Maybe<Module_Item_Comments_Min_Fields>;
  readonly stddev?: Maybe<Module_Item_Comments_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Module_Item_Comments_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Module_Item_Comments_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Module_Item_Comments_Sum_Fields>;
  readonly var_pop?: Maybe<Module_Item_Comments_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Module_Item_Comments_Var_Samp_Fields>;
  readonly variance?: Maybe<Module_Item_Comments_Variance_Fields>;
};


/** aggregate fields of "module_item_comments" */
export type Module_Item_Comments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "module_item_comments" */
export type Module_Item_Comments_Aggregate_Order_By = {
  readonly avg?: Maybe<Module_Item_Comments_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Module_Item_Comments_Max_Order_By>;
  readonly min?: Maybe<Module_Item_Comments_Min_Order_By>;
  readonly stddev?: Maybe<Module_Item_Comments_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Module_Item_Comments_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Module_Item_Comments_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Module_Item_Comments_Sum_Order_By>;
  readonly var_pop?: Maybe<Module_Item_Comments_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Module_Item_Comments_Var_Samp_Order_By>;
  readonly variance?: Maybe<Module_Item_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "module_item_comments" */
export type Module_Item_Comments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Module_Item_Comments_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Module_Item_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Module_Item_Comments_Avg_Fields = {
  readonly __typename?: 'module_item_comments_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "module_item_comments" */
export type Module_Item_Comments_Avg_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "module_item_comments". All fields are combined with a logical 'AND'. */
export type Module_Item_Comments_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Module_Item_Comments_Bool_Exp>>;
  readonly _not?: Maybe<Module_Item_Comments_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Module_Item_Comments_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_provider?: Maybe<Boolean_Comparison_Exp>;
  readonly is_visible?: Maybe<Boolean_Comparison_Exp>;
  readonly message?: Maybe<String_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "module_item_comments" */
export enum Module_Item_Comments_Constraint {
  /** unique or primary key constraint */
  ModuleItemCommentsPkey = 'module_item_comments_pkey'
}

/** input type for incrementing numeric columns in table "module_item_comments" */
export type Module_Item_Comments_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "module_item_comments" */
export type Module_Item_Comments_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_provider?: Maybe<Scalars['Boolean']>;
  readonly is_visible?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Module_Item_Comments_Max_Fields = {
  readonly __typename?: 'module_item_comments_max_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "module_item_comments" */
export type Module_Item_Comments_Max_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Module_Item_Comments_Min_Fields = {
  readonly __typename?: 'module_item_comments_min_fields';
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "module_item_comments" */
export type Module_Item_Comments_Min_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "module_item_comments" */
export type Module_Item_Comments_Mutation_Response = {
  readonly __typename?: 'module_item_comments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Module_Item_Comments>;
};

/** on conflict condition type for table "module_item_comments" */
export type Module_Item_Comments_On_Conflict = {
  readonly constraint: Module_Item_Comments_Constraint;
  readonly update_columns?: ReadonlyArray<Module_Item_Comments_Update_Column>;
  readonly where?: Maybe<Module_Item_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "module_item_comments". */
export type Module_Item_Comments_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_provider?: Maybe<Order_By>;
  readonly is_visible?: Maybe<Order_By>;
  readonly message?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: module_item_comments */
export type Module_Item_Comments_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "module_item_comments" */
export enum Module_Item_Comments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProvider = 'is_provider',
  /** column name */
  IsVisible = 'is_visible',
  /** column name */
  Message = 'message',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "module_item_comments" */
export type Module_Item_Comments_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_provider?: Maybe<Scalars['Boolean']>;
  readonly is_visible?: Maybe<Scalars['Boolean']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Module_Item_Comments_Stddev_Fields = {
  readonly __typename?: 'module_item_comments_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "module_item_comments" */
export type Module_Item_Comments_Stddev_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Module_Item_Comments_Stddev_Pop_Fields = {
  readonly __typename?: 'module_item_comments_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "module_item_comments" */
export type Module_Item_Comments_Stddev_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Module_Item_Comments_Stddev_Samp_Fields = {
  readonly __typename?: 'module_item_comments_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "module_item_comments" */
export type Module_Item_Comments_Stddev_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Module_Item_Comments_Sum_Fields = {
  readonly __typename?: 'module_item_comments_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "module_item_comments" */
export type Module_Item_Comments_Sum_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "module_item_comments" */
export enum Module_Item_Comments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProvider = 'is_provider',
  /** column name */
  IsVisible = 'is_visible',
  /** column name */
  Message = 'message',
  /** column name */
  ModuleItemId = 'module_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Module_Item_Comments_Var_Pop_Fields = {
  readonly __typename?: 'module_item_comments_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "module_item_comments" */
export type Module_Item_Comments_Var_Pop_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Module_Item_Comments_Var_Samp_Fields = {
  readonly __typename?: 'module_item_comments_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "module_item_comments" */
export type Module_Item_Comments_Var_Samp_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Module_Item_Comments_Variance_Fields = {
  readonly __typename?: 'module_item_comments_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "module_item_comments" */
export type Module_Item_Comments_Variance_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** columns and relationships of "module_items" */
export type Module_Items = {
  readonly __typename?: 'module_items';
  /** fetch data from the table: "course_articles" */
  readonly course_articles: ReadonlyArray<Course_Articles>;
  /** An aggregate relationship */
  readonly course_articles_aggregate: Course_Articles_Aggregate;
  /** An array relationship */
  readonly course_assessments: ReadonlyArray<Course_Assessments>;
  /** An aggregate relationship */
  readonly course_assessments_aggregate: Course_Assessments_Aggregate;
  /** An array relationship */
  readonly course_attachments: ReadonlyArray<Course_Attachments>;
  /** An aggregate relationship */
  readonly course_attachments_aggregate: Course_Attachments_Aggregate;
  /** An array relationship */
  readonly course_forms: ReadonlyArray<Course_Forms>;
  /** An aggregate relationship */
  readonly course_forms_aggregate: Course_Forms_Aggregate;
  /** An array relationship */
  readonly course_live_sessions: ReadonlyArray<Course_Live_Sessions>;
  /** An aggregate relationship */
  readonly course_live_sessions_aggregate: Course_Live_Sessions_Aggregate;
  /** An object relationship */
  readonly course_module?: Maybe<Course_Modules>;
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  readonly course_videos: ReadonlyArray<Course_Videos>;
  /** An aggregate relationship */
  readonly course_videos_aggregate: Course_Videos_Aggregate;
  readonly created_at: Scalars['timestamp'];
  readonly enable_comment_section: Scalars['Boolean'];
  readonly id: Scalars['bigint'];
  readonly is_free: Scalars['Boolean'];
  readonly is_locked: Scalars['Boolean'];
  readonly item_type: Scalars['Int'];
  /** An array relationship */
  readonly module_item_comments: ReadonlyArray<Module_Item_Comments>;
  /** An aggregate relationship */
  readonly module_item_comments_aggregate: Module_Item_Comments_Aggregate;
  readonly order: Scalars['Int'];
  /** An array relationship */
  readonly student_progresses: ReadonlyArray<Student_Progress>;
  /** An aggregate relationship */
  readonly student_progresses_aggregate: Student_Progress_Aggregate;
  readonly title: Scalars['String'];
  readonly updated_at: Scalars['timestamp'];
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_ArticlesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Articles_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_AssessmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Assessments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_AttachmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Attachments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Live_SessionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Live_Sessions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_VideosArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsCourse_Videos_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsModule_Item_CommentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsModule_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsStudent_ProgressesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


/** columns and relationships of "module_items" */
export type Module_ItemsStudent_Progresses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};

/** aggregated selection of "module_items" */
export type Module_Items_Aggregate = {
  readonly __typename?: 'module_items_aggregate';
  readonly aggregate?: Maybe<Module_Items_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Module_Items>;
};

/** aggregate fields of "module_items" */
export type Module_Items_Aggregate_Fields = {
  readonly __typename?: 'module_items_aggregate_fields';
  readonly avg?: Maybe<Module_Items_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Module_Items_Max_Fields>;
  readonly min?: Maybe<Module_Items_Min_Fields>;
  readonly stddev?: Maybe<Module_Items_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Module_Items_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Module_Items_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Module_Items_Sum_Fields>;
  readonly var_pop?: Maybe<Module_Items_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Module_Items_Var_Samp_Fields>;
  readonly variance?: Maybe<Module_Items_Variance_Fields>;
};


/** aggregate fields of "module_items" */
export type Module_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "module_items" */
export type Module_Items_Aggregate_Order_By = {
  readonly avg?: Maybe<Module_Items_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Module_Items_Max_Order_By>;
  readonly min?: Maybe<Module_Items_Min_Order_By>;
  readonly stddev?: Maybe<Module_Items_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Module_Items_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Module_Items_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Module_Items_Sum_Order_By>;
  readonly var_pop?: Maybe<Module_Items_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Module_Items_Var_Samp_Order_By>;
  readonly variance?: Maybe<Module_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "module_items" */
export type Module_Items_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Module_Items_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Module_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Module_Items_Avg_Fields = {
  readonly __typename?: 'module_items_avg_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "module_items" */
export type Module_Items_Avg_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "module_items". All fields are combined with a logical 'AND'. */
export type Module_Items_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Module_Items_Bool_Exp>>;
  readonly _not?: Maybe<Module_Items_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Module_Items_Bool_Exp>>;
  readonly course_articles?: Maybe<Course_Articles_Bool_Exp>;
  readonly course_assessments?: Maybe<Course_Assessments_Bool_Exp>;
  readonly course_attachments?: Maybe<Course_Attachments_Bool_Exp>;
  readonly course_forms?: Maybe<Course_Forms_Bool_Exp>;
  readonly course_live_sessions?: Maybe<Course_Live_Sessions_Bool_Exp>;
  readonly course_module?: Maybe<Course_Modules_Bool_Exp>;
  readonly course_module_id?: Maybe<Bigint_Comparison_Exp>;
  readonly course_videos?: Maybe<Course_Videos_Bool_Exp>;
  readonly created_at?: Maybe<Timestamp_Comparison_Exp>;
  readonly enable_comment_section?: Maybe<Boolean_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_free?: Maybe<Boolean_Comparison_Exp>;
  readonly is_locked?: Maybe<Boolean_Comparison_Exp>;
  readonly item_type?: Maybe<Int_Comparison_Exp>;
  readonly module_item_comments?: Maybe<Module_Item_Comments_Bool_Exp>;
  readonly order?: Maybe<Int_Comparison_Exp>;
  readonly student_progresses?: Maybe<Student_Progress_Bool_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "module_items" */
export enum Module_Items_Constraint {
  /** unique or primary key constraint */
  ModuleItemsPkey = 'module_items_pkey'
}

/** input type for incrementing numeric columns in table "module_items" */
export type Module_Items_Inc_Input = {
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module_items" */
export type Module_Items_Insert_Input = {
  readonly course_articles?: Maybe<Course_Articles_Arr_Rel_Insert_Input>;
  readonly course_assessments?: Maybe<Course_Assessments_Arr_Rel_Insert_Input>;
  readonly course_attachments?: Maybe<Course_Attachments_Arr_Rel_Insert_Input>;
  readonly course_forms?: Maybe<Course_Forms_Arr_Rel_Insert_Input>;
  readonly course_live_sessions?: Maybe<Course_Live_Sessions_Arr_Rel_Insert_Input>;
  readonly course_module?: Maybe<Course_Modules_Obj_Rel_Insert_Input>;
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly course_videos?: Maybe<Course_Videos_Arr_Rel_Insert_Input>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly enable_comment_section?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_free?: Maybe<Scalars['Boolean']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly module_item_comments?: Maybe<Module_Item_Comments_Arr_Rel_Insert_Input>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly student_progresses?: Maybe<Student_Progress_Arr_Rel_Insert_Input>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Module_Items_Max_Fields = {
  readonly __typename?: 'module_items_max_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "module_items" */
export type Module_Items_Max_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Module_Items_Min_Fields = {
  readonly __typename?: 'module_items_min_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "module_items" */
export type Module_Items_Min_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "module_items" */
export type Module_Items_Mutation_Response = {
  readonly __typename?: 'module_items_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Module_Items>;
};

/** input type for inserting object relation for remote table "module_items" */
export type Module_Items_Obj_Rel_Insert_Input = {
  readonly data: Module_Items_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Module_Items_On_Conflict>;
};

/** on conflict condition type for table "module_items" */
export type Module_Items_On_Conflict = {
  readonly constraint: Module_Items_Constraint;
  readonly update_columns?: ReadonlyArray<Module_Items_Update_Column>;
  readonly where?: Maybe<Module_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "module_items". */
export type Module_Items_Order_By = {
  readonly course_articles_aggregate?: Maybe<Course_Articles_Aggregate_Order_By>;
  readonly course_assessments_aggregate?: Maybe<Course_Assessments_Aggregate_Order_By>;
  readonly course_attachments_aggregate?: Maybe<Course_Attachments_Aggregate_Order_By>;
  readonly course_forms_aggregate?: Maybe<Course_Forms_Aggregate_Order_By>;
  readonly course_live_sessions_aggregate?: Maybe<Course_Live_Sessions_Aggregate_Order_By>;
  readonly course_module?: Maybe<Course_Modules_Order_By>;
  readonly course_module_id?: Maybe<Order_By>;
  readonly course_videos_aggregate?: Maybe<Course_Videos_Aggregate_Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly enable_comment_section?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_free?: Maybe<Order_By>;
  readonly is_locked?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly module_item_comments_aggregate?: Maybe<Module_Item_Comments_Aggregate_Order_By>;
  readonly order?: Maybe<Order_By>;
  readonly student_progresses_aggregate?: Maybe<Student_Progress_Aggregate_Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: module_items */
export type Module_Items_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "module_items" */
export enum Module_Items_Select_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnableCommentSection = 'enable_comment_section',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "module_items" */
export type Module_Items_Set_Input = {
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly created_at?: Maybe<Scalars['timestamp']>;
  readonly enable_comment_section?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_free?: Maybe<Scalars['Boolean']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Module_Items_Stddev_Fields = {
  readonly __typename?: 'module_items_stddev_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "module_items" */
export type Module_Items_Stddev_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Module_Items_Stddev_Pop_Fields = {
  readonly __typename?: 'module_items_stddev_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "module_items" */
export type Module_Items_Stddev_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Module_Items_Stddev_Samp_Fields = {
  readonly __typename?: 'module_items_stddev_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "module_items" */
export type Module_Items_Stddev_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Module_Items_Sum_Fields = {
  readonly __typename?: 'module_items_sum_fields';
  readonly course_module_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly item_type?: Maybe<Scalars['Int']>;
  readonly order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "module_items" */
export type Module_Items_Sum_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** update columns of table "module_items" */
export enum Module_Items_Update_Column {
  /** column name */
  CourseModuleId = 'course_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnableCommentSection = 'enable_comment_section',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Module_Items_Var_Pop_Fields = {
  readonly __typename?: 'module_items_var_pop_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "module_items" */
export type Module_Items_Var_Pop_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Module_Items_Var_Samp_Fields = {
  readonly __typename?: 'module_items_var_samp_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "module_items" */
export type Module_Items_Var_Samp_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Module_Items_Variance_Fields = {
  readonly __typename?: 'module_items_variance_fields';
  readonly course_module_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly item_type?: Maybe<Scalars['Float']>;
  readonly order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "module_items" */
export type Module_Items_Variance_Order_By = {
  readonly course_module_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly item_type?: Maybe<Order_By>;
  readonly order?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  readonly __typename?: 'mutation_root';
  readonly createCoursePaymentRequest?: Maybe<CoursePaymentResponse>;
  /** delete data from the table: "account_google" */
  readonly delete_account_google?: Maybe<Account_Google_Mutation_Response>;
  /** delete single row from the table: "account_google" */
  readonly delete_account_google_by_pk?: Maybe<Account_Google>;
  /** delete data from the table: "account_subscription_plans" */
  readonly delete_account_subscription_plans?: Maybe<Account_Subscription_Plans_Mutation_Response>;
  /** delete single row from the table: "account_subscription_plans" */
  readonly delete_account_subscription_plans_by_pk?: Maybe<Account_Subscription_Plans>;
  /** delete data from the table: "account_subscriptions" */
  readonly delete_account_subscriptions?: Maybe<Account_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "account_subscriptions" */
  readonly delete_account_subscriptions_by_pk?: Maybe<Account_Subscriptions>;
  /** delete data from the table: "account_zoom" */
  readonly delete_account_zoom?: Maybe<Account_Zoom_Mutation_Response>;
  /** delete single row from the table: "account_zoom" */
  readonly delete_account_zoom_by_pk?: Maybe<Account_Zoom>;
  /** delete data from the table: "accounts" */
  readonly delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  readonly delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "ar_internal_metadata" */
  readonly delete_ar_internal_metadata?: Maybe<Ar_Internal_Metadata_Mutation_Response>;
  /** delete single row from the table: "ar_internal_metadata" */
  readonly delete_ar_internal_metadata_by_pk?: Maybe<Ar_Internal_Metadata>;
  /** delete data from the table: "attachment" */
  readonly delete_attachment?: Maybe<Attachment_Mutation_Response>;
  /** delete single row from the table: "attachment" */
  readonly delete_attachment_by_pk?: Maybe<Attachment>;
  /** delete data from the table: "attendance_logs" */
  readonly delete_attendance_logs?: Maybe<Attendance_Logs_Mutation_Response>;
  /** delete single row from the table: "attendance_logs" */
  readonly delete_attendance_logs_by_pk?: Maybe<Attendance_Logs>;
  /** delete data from the table: "attendances" */
  readonly delete_attendances?: Maybe<Attendances_Mutation_Response>;
  /** delete single row from the table: "attendances" */
  readonly delete_attendances_by_pk?: Maybe<Attendances>;
  /** delete data from the table: "breakout_group" */
  readonly delete_breakout_group?: Maybe<Breakout_Group_Mutation_Response>;
  /** delete single row from the table: "breakout_group" */
  readonly delete_breakout_group_by_pk?: Maybe<Breakout_Group>;
  /** delete data from the table: "breakout_group_participants_event_participant" */
  readonly delete_breakout_group_participants_event_participant?: Maybe<Breakout_Group_Participants_Event_Participant_Mutation_Response>;
  /** delete single row from the table: "breakout_group_participants_event_participant" */
  readonly delete_breakout_group_participants_event_participant_by_pk?: Maybe<Breakout_Group_Participants_Event_Participant>;
  /** delete data from the table: "breakout_room" */
  readonly delete_breakout_room?: Maybe<Breakout_Room_Mutation_Response>;
  /** delete single row from the table: "breakout_room" */
  readonly delete_breakout_room_by_pk?: Maybe<Breakout_Room>;
  /** delete data from the table: "bunny_upload" */
  readonly delete_bunny_upload?: Maybe<Bunny_Upload_Mutation_Response>;
  /** delete single row from the table: "bunny_upload" */
  readonly delete_bunny_upload_by_pk?: Maybe<Bunny_Upload>;
  /** delete data from the table: "bunny_upload_states" */
  readonly delete_bunny_upload_states?: Maybe<Bunny_Upload_States_Mutation_Response>;
  /** delete single row from the table: "bunny_upload_states" */
  readonly delete_bunny_upload_states_by_pk?: Maybe<Bunny_Upload_States>;
  /** delete data from the table: "carousel_item" */
  readonly delete_carousel_item?: Maybe<Carousel_Item_Mutation_Response>;
  /** delete single row from the table: "carousel_item" */
  readonly delete_carousel_item_by_pk?: Maybe<Carousel_Item>;
  /** delete data from the table: "certificate_signatory" */
  readonly delete_certificate_signatory?: Maybe<Certificate_Signatory_Mutation_Response>;
  /** delete single row from the table: "certificate_signatory" */
  readonly delete_certificate_signatory_by_pk?: Maybe<Certificate_Signatory>;
  /** delete data from the table: "certificate_template_signatories" */
  readonly delete_certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Mutation_Response>;
  /** delete single row from the table: "certificate_template_signatories" */
  readonly delete_certificate_template_signatories_by_pk?: Maybe<Certificate_Template_Signatories>;
  /** delete data from the table: "certificate_templates" */
  readonly delete_certificate_templates?: Maybe<Certificate_Templates_Mutation_Response>;
  /** delete single row from the table: "certificate_templates" */
  readonly delete_certificate_templates_by_pk?: Maybe<Certificate_Templates>;
  /** delete data from the table: "course_articles" */
  readonly delete_course_articles?: Maybe<Course_Articles_Mutation_Response>;
  /** delete single row from the table: "course_articles" */
  readonly delete_course_articles_by_pk?: Maybe<Course_Articles>;
  /** delete data from the table: "course_assessments" */
  readonly delete_course_assessments?: Maybe<Course_Assessments_Mutation_Response>;
  /** delete single row from the table: "course_assessments" */
  readonly delete_course_assessments_by_pk?: Maybe<Course_Assessments>;
  /** delete data from the table: "course_attachments" */
  readonly delete_course_attachments?: Maybe<Course_Attachments_Mutation_Response>;
  /** delete single row from the table: "course_attachments" */
  readonly delete_course_attachments_by_pk?: Maybe<Course_Attachments>;
  /** delete data from the table: "course_certificates" */
  readonly delete_course_certificates?: Maybe<Course_Certificates_Mutation_Response>;
  /** delete single row from the table: "course_certificates" */
  readonly delete_course_certificates_by_pk?: Maybe<Course_Certificates>;
  /** delete data from the table: "course_discount_types" */
  readonly delete_course_discount_types?: Maybe<Course_Discount_Types_Mutation_Response>;
  /** delete single row from the table: "course_discount_types" */
  readonly delete_course_discount_types_by_pk?: Maybe<Course_Discount_Types>;
  /** delete data from the table: "course_discounts" */
  readonly delete_course_discounts?: Maybe<Course_Discounts_Mutation_Response>;
  /** delete single row from the table: "course_discounts" */
  readonly delete_course_discounts_by_pk?: Maybe<Course_Discounts>;
  /** delete data from the table: "course_forms" */
  readonly delete_course_forms?: Maybe<Course_Forms_Mutation_Response>;
  /** delete single row from the table: "course_forms" */
  readonly delete_course_forms_by_pk?: Maybe<Course_Forms>;
  /** delete data from the table: "course_gains" */
  readonly delete_course_gains?: Maybe<Course_Gains_Mutation_Response>;
  /** delete single row from the table: "course_gains" */
  readonly delete_course_gains_by_pk?: Maybe<Course_Gains>;
  /** delete data from the table: "course_instructors" */
  readonly delete_course_instructors?: Maybe<Course_Instructors_Mutation_Response>;
  /** delete single row from the table: "course_instructors" */
  readonly delete_course_instructors_by_pk?: Maybe<Course_Instructors>;
  /** delete data from the table: "course_invites" */
  readonly delete_course_invites?: Maybe<Course_Invites_Mutation_Response>;
  /** delete single row from the table: "course_invites" */
  readonly delete_course_invites_by_pk?: Maybe<Course_Invites>;
  /** delete data from the table: "course_lecturers" */
  readonly delete_course_lecturers?: Maybe<Course_Lecturers_Mutation_Response>;
  /** delete data from the table: "course_live_sessions" */
  readonly delete_course_live_sessions?: Maybe<Course_Live_Sessions_Mutation_Response>;
  /** delete single row from the table: "course_live_sessions" */
  readonly delete_course_live_sessions_by_pk?: Maybe<Course_Live_Sessions>;
  /** delete data from the table: "course_module_students" */
  readonly delete_course_module_students?: Maybe<Course_Module_Students_Mutation_Response>;
  /** delete single row from the table: "course_module_students" */
  readonly delete_course_module_students_by_pk?: Maybe<Course_Module_Students>;
  /** delete data from the table: "course_modules" */
  readonly delete_course_modules?: Maybe<Course_Modules_Mutation_Response>;
  /** delete single row from the table: "course_modules" */
  readonly delete_course_modules_by_pk?: Maybe<Course_Modules>;
  /** delete data from the table: "course_objectives" */
  readonly delete_course_objectives?: Maybe<Course_Objectives_Mutation_Response>;
  /** delete single row from the table: "course_objectives" */
  readonly delete_course_objectives_by_pk?: Maybe<Course_Objectives>;
  /** delete data from the table: "course_order" */
  readonly delete_course_order?: Maybe<Course_Order_Mutation_Response>;
  /** delete single row from the table: "course_order" */
  readonly delete_course_order_by_pk?: Maybe<Course_Order>;
  /** delete data from the table: "course_order_modules" */
  readonly delete_course_order_modules?: Maybe<Course_Order_Modules_Mutation_Response>;
  /** delete single row from the table: "course_order_modules" */
  readonly delete_course_order_modules_by_pk?: Maybe<Course_Order_Modules>;
  /** delete data from the table: "course_order_statuses" */
  readonly delete_course_order_statuses?: Maybe<Course_Order_Statuses_Mutation_Response>;
  /** delete single row from the table: "course_order_statuses" */
  readonly delete_course_order_statuses_by_pk?: Maybe<Course_Order_Statuses>;
  /** delete data from the table: "course_payment_methods" */
  readonly delete_course_payment_methods?: Maybe<Course_Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "course_payment_methods" */
  readonly delete_course_payment_methods_by_pk?: Maybe<Course_Payment_Methods>;
  /** delete data from the table: "course_reminders" */
  readonly delete_course_reminders?: Maybe<Course_Reminders_Mutation_Response>;
  /** delete single row from the table: "course_reminders" */
  readonly delete_course_reminders_by_pk?: Maybe<Course_Reminders>;
  /** delete data from the table: "course_requirements" */
  readonly delete_course_requirements?: Maybe<Course_Requirements_Mutation_Response>;
  /** delete single row from the table: "course_requirements" */
  readonly delete_course_requirements_by_pk?: Maybe<Course_Requirements>;
  /** delete data from the table: "course_rewards" */
  readonly delete_course_rewards?: Maybe<Course_Rewards_Mutation_Response>;
  /** delete single row from the table: "course_rewards" */
  readonly delete_course_rewards_by_pk?: Maybe<Course_Rewards>;
  /** delete data from the table: "course_sell_types" */
  readonly delete_course_sell_types?: Maybe<Course_Sell_Types_Mutation_Response>;
  /** delete single row from the table: "course_sell_types" */
  readonly delete_course_sell_types_by_pk?: Maybe<Course_Sell_Types>;
  /** delete data from the table: "course_signatories" */
  readonly delete_course_signatories?: Maybe<Course_Signatories_Mutation_Response>;
  /** delete single row from the table: "course_signatories" */
  readonly delete_course_signatories_by_pk?: Maybe<Course_Signatories>;
  /** delete data from the table: "course_students" */
  readonly delete_course_students?: Maybe<Course_Students_Mutation_Response>;
  /** delete single row from the table: "course_students" */
  readonly delete_course_students_by_pk?: Maybe<Course_Students>;
  /** delete data from the table: "course_videos" */
  readonly delete_course_videos?: Maybe<Course_Videos_Mutation_Response>;
  /** delete single row from the table: "course_videos" */
  readonly delete_course_videos_by_pk?: Maybe<Course_Videos>;
  /** delete data from the table: "course_videos_statuses" */
  readonly delete_course_videos_statuses?: Maybe<Course_Videos_Statuses_Mutation_Response>;
  /** delete single row from the table: "course_videos_statuses" */
  readonly delete_course_videos_statuses_by_pk?: Maybe<Course_Videos_Statuses>;
  /** delete data from the table: "courses" */
  readonly delete_courses?: Maybe<Courses_Mutation_Response>;
  /** delete single row from the table: "courses" */
  readonly delete_courses_by_pk?: Maybe<Courses>;
  /** delete data from the table: "cpd_unit_rewards" */
  readonly delete_cpd_unit_rewards?: Maybe<Cpd_Unit_Rewards_Mutation_Response>;
  /** delete single row from the table: "cpd_unit_rewards" */
  readonly delete_cpd_unit_rewards_by_pk?: Maybe<Cpd_Unit_Rewards>;
  /** delete data from the table: "cpd_units_reward" */
  readonly delete_cpd_units_reward?: Maybe<Cpd_Units_Reward_Mutation_Response>;
  /** delete single row from the table: "cpd_units_reward" */
  readonly delete_cpd_units_reward_by_pk?: Maybe<Cpd_Units_Reward>;
  /** delete data from the table: "customer" */
  readonly delete_customer?: Maybe<Customer_Mutation_Response>;
  /** delete single row from the table: "customer" */
  readonly delete_customer_by_pk?: Maybe<Customer>;
  /** delete data from the table: "discount" */
  readonly delete_discount?: Maybe<Discount_Mutation_Response>;
  /** delete single row from the table: "discount" */
  readonly delete_discount_by_pk?: Maybe<Discount>;
  /** delete data from the table: "email_notification_types" */
  readonly delete_email_notification_types?: Maybe<Email_Notification_Types_Mutation_Response>;
  /** delete single row from the table: "email_notification_types" */
  readonly delete_email_notification_types_by_pk?: Maybe<Email_Notification_Types>;
  /** delete data from the table: "email_notifications" */
  readonly delete_email_notifications?: Maybe<Email_Notifications_Mutation_Response>;
  /** delete single row from the table: "email_notifications" */
  readonly delete_email_notifications_by_pk?: Maybe<Email_Notifications>;
  /** delete data from the table: "event" */
  readonly delete_event?: Maybe<Event_Mutation_Response>;
  /** delete single row from the table: "event" */
  readonly delete_event_by_pk?: Maybe<Event>;
  /** delete data from the table: "event_industries_industry" */
  readonly delete_event_industries_industry?: Maybe<Event_Industries_Industry_Mutation_Response>;
  /** delete single row from the table: "event_industries_industry" */
  readonly delete_event_industries_industry_by_pk?: Maybe<Event_Industries_Industry>;
  /** delete data from the table: "event_managing_users_user" */
  readonly delete_event_managing_users_user?: Maybe<Event_Managing_Users_User_Mutation_Response>;
  /** delete single row from the table: "event_managing_users_user" */
  readonly delete_event_managing_users_user_by_pk?: Maybe<Event_Managing_Users_User>;
  /** delete data from the table: "event_participant" */
  readonly delete_event_participant?: Maybe<Event_Participant_Mutation_Response>;
  /** delete single row from the table: "event_participant" */
  readonly delete_event_participant_by_pk?: Maybe<Event_Participant>;
  /** delete data from the table: "faq_item" */
  readonly delete_faq_item?: Maybe<Faq_Item_Mutation_Response>;
  /** delete single row from the table: "faq_item" */
  readonly delete_faq_item_by_pk?: Maybe<Faq_Item>;
  /** delete data from the table: "form" */
  readonly delete_form?: Maybe<Form_Mutation_Response>;
  /** delete single row from the table: "form" */
  readonly delete_form_by_pk?: Maybe<Form>;
  /** delete data from the table: "form_event_names" */
  readonly delete_form_event_names?: Maybe<Form_Event_Names_Mutation_Response>;
  /** delete single row from the table: "form_event_names" */
  readonly delete_form_event_names_by_pk?: Maybe<Form_Event_Names>;
  /** delete data from the table: "form_events" */
  readonly delete_form_events?: Maybe<Form_Events_Mutation_Response>;
  /** delete single row from the table: "form_events" */
  readonly delete_form_events_by_pk?: Maybe<Form_Events>;
  /** delete data from the table: "form_instance" */
  readonly delete_form_instance?: Maybe<Form_Instance_Mutation_Response>;
  /** delete single row from the table: "form_instance" */
  readonly delete_form_instance_by_pk?: Maybe<Form_Instance>;
  /** delete data from the table: "form_question_answers" */
  readonly delete_form_question_answers?: Maybe<Form_Question_Answers_Mutation_Response>;
  /** delete single row from the table: "form_question_answers" */
  readonly delete_form_question_answers_by_pk?: Maybe<Form_Question_Answers>;
  /** delete data from the table: "form_question_submission_states" */
  readonly delete_form_question_submission_states?: Maybe<Form_Question_Submission_States_Mutation_Response>;
  /** delete single row from the table: "form_question_submission_states" */
  readonly delete_form_question_submission_states_by_pk?: Maybe<Form_Question_Submission_States>;
  /** delete data from the table: "form_question_submissions" */
  readonly delete_form_question_submissions?: Maybe<Form_Question_Submissions_Mutation_Response>;
  /** delete single row from the table: "form_question_submissions" */
  readonly delete_form_question_submissions_by_pk?: Maybe<Form_Question_Submissions>;
  /** delete data from the table: "form_question_types" */
  readonly delete_form_question_types?: Maybe<Form_Question_Types_Mutation_Response>;
  /** delete single row from the table: "form_question_types" */
  readonly delete_form_question_types_by_pk?: Maybe<Form_Question_Types>;
  /** delete data from the table: "form_questions" */
  readonly delete_form_questions?: Maybe<Form_Questions_Mutation_Response>;
  /** delete single row from the table: "form_questions" */
  readonly delete_form_questions_by_pk?: Maybe<Form_Questions>;
  /** delete data from the table: "form_score" */
  readonly delete_form_score?: Maybe<Form_Score_Mutation_Response>;
  /** delete single row from the table: "form_score" */
  readonly delete_form_score_by_pk?: Maybe<Form_Score>;
  /** delete data from the table: "form_scoring_policies" */
  readonly delete_form_scoring_policies?: Maybe<Form_Scoring_Policies_Mutation_Response>;
  /** delete single row from the table: "form_scoring_policies" */
  readonly delete_form_scoring_policies_by_pk?: Maybe<Form_Scoring_Policies>;
  /** delete data from the table: "form_submission_statuses" */
  readonly delete_form_submission_statuses?: Maybe<Form_Submission_Statuses_Mutation_Response>;
  /** delete single row from the table: "form_submission_statuses" */
  readonly delete_form_submission_statuses_by_pk?: Maybe<Form_Submission_Statuses>;
  /** delete data from the table: "form_submissions" */
  readonly delete_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** delete single row from the table: "form_submissions" */
  readonly delete_form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** delete data from the table: "form_types" */
  readonly delete_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** delete single row from the table: "form_types" */
  readonly delete_form_types_by_pk?: Maybe<Form_Types>;
  /** delete data from the table: "forms" */
  readonly delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  readonly delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "images" */
  readonly delete_images?: Maybe<Images_Mutation_Response>;
  /** delete single row from the table: "images" */
  readonly delete_images_by_pk?: Maybe<Images>;
  /** delete data from the table: "industry" */
  readonly delete_industry?: Maybe<Industry_Mutation_Response>;
  /** delete single row from the table: "industry" */
  readonly delete_industry_by_pk?: Maybe<Industry>;
  /** delete data from the table: "industry_category" */
  readonly delete_industry_category?: Maybe<Industry_Category_Mutation_Response>;
  /** delete single row from the table: "industry_category" */
  readonly delete_industry_category_by_pk?: Maybe<Industry_Category>;
  /** delete data from the table: "industry_type" */
  readonly delete_industry_type?: Maybe<Industry_Type_Mutation_Response>;
  /** delete single row from the table: "industry_type" */
  readonly delete_industry_type_by_pk?: Maybe<Industry_Type>;
  /** delete data from the table: "migrations" */
  readonly delete_migrations?: Maybe<Migrations_Mutation_Response>;
  /** delete single row from the table: "migrations" */
  readonly delete_migrations_by_pk?: Maybe<Migrations>;
  /** delete data from the table: "mock_order" */
  readonly delete_mock_order?: Maybe<Mock_Order_Mutation_Response>;
  /** delete data from the table: "mock_order2" */
  readonly delete_mock_order2?: Maybe<Mock_Order2_Mutation_Response>;
  /** delete single row from the table: "mock_order2" */
  readonly delete_mock_order2_by_pk?: Maybe<Mock_Order2>;
  /** delete single row from the table: "mock_order" */
  readonly delete_mock_order_by_pk?: Maybe<Mock_Order>;
  /** delete data from the table: "module_item_comments" */
  readonly delete_module_item_comments?: Maybe<Module_Item_Comments_Mutation_Response>;
  /** delete single row from the table: "module_item_comments" */
  readonly delete_module_item_comments_by_pk?: Maybe<Module_Item_Comments>;
  /** delete data from the table: "module_items" */
  readonly delete_module_items?: Maybe<Module_Items_Mutation_Response>;
  /** delete single row from the table: "module_items" */
  readonly delete_module_items_by_pk?: Maybe<Module_Items>;
  /** delete data from the table: "order" */
  readonly delete_order?: Maybe<Order_Mutation_Response>;
  /** delete single row from the table: "order" */
  readonly delete_order_by_pk?: Maybe<Order>;
  /** delete data from the table: "other_reward" */
  readonly delete_other_reward?: Maybe<Other_Reward_Mutation_Response>;
  /** delete single row from the table: "other_reward" */
  readonly delete_other_reward_by_pk?: Maybe<Other_Reward>;
  /** delete data from the table: "other_reward_type" */
  readonly delete_other_reward_type?: Maybe<Other_Reward_Type_Mutation_Response>;
  /** delete single row from the table: "other_reward_type" */
  readonly delete_other_reward_type_by_pk?: Maybe<Other_Reward_Type>;
  /** delete data from the table: "other_unit_rewards" */
  readonly delete_other_unit_rewards?: Maybe<Other_Unit_Rewards_Mutation_Response>;
  /** delete single row from the table: "other_unit_rewards" */
  readonly delete_other_unit_rewards_by_pk?: Maybe<Other_Unit_Rewards>;
  /** delete data from the table: "product" */
  readonly delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  readonly delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "provider" */
  readonly delete_provider?: Maybe<Provider_Mutation_Response>;
  /** delete data from the table: "provider_application" */
  readonly delete_provider_application?: Maybe<Provider_Application_Mutation_Response>;
  /** delete single row from the table: "provider_application" */
  readonly delete_provider_application_by_pk?: Maybe<Provider_Application>;
  /** delete data from the table: "provider_application_industries_industry" */
  readonly delete_provider_application_industries_industry?: Maybe<Provider_Application_Industries_Industry_Mutation_Response>;
  /** delete single row from the table: "provider_application_industries_industry" */
  readonly delete_provider_application_industries_industry_by_pk?: Maybe<Provider_Application_Industries_Industry>;
  /** delete single row from the table: "provider" */
  readonly delete_provider_by_pk?: Maybe<Provider>;
  /** delete data from the table: "provider_dragonpay_credentials" */
  readonly delete_provider_dragonpay_credentials?: Maybe<Provider_Dragonpay_Credentials_Mutation_Response>;
  /** delete single row from the table: "provider_dragonpay_credentials" */
  readonly delete_provider_dragonpay_credentials_by_pk?: Maybe<Provider_Dragonpay_Credentials>;
  /** delete data from the table: "provider_frequently_used_industries_industry" */
  readonly delete_provider_frequently_used_industries_industry?: Maybe<Provider_Frequently_Used_Industries_Industry_Mutation_Response>;
  /** delete single row from the table: "provider_frequently_used_industries_industry" */
  readonly delete_provider_frequently_used_industries_industry_by_pk?: Maybe<Provider_Frequently_Used_Industries_Industry>;
  /** delete data from the table: "provider_google" */
  readonly delete_provider_google?: Maybe<Provider_Google_Mutation_Response>;
  /** delete single row from the table: "provider_google" */
  readonly delete_provider_google_by_pk?: Maybe<Provider_Google>;
  /** delete data from the table: "provider_managing_users_user" */
  readonly delete_provider_managing_users_user?: Maybe<Provider_Managing_Users_User_Mutation_Response>;
  /** delete single row from the table: "provider_managing_users_user" */
  readonly delete_provider_managing_users_user_by_pk?: Maybe<Provider_Managing_Users_User>;
  /** delete data from the table: "provider_subscription_plan" */
  readonly delete_provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Mutation_Response>;
  /** delete single row from the table: "provider_subscription_plan" */
  readonly delete_provider_subscription_plan_by_pk?: Maybe<Provider_Subscription_Plan>;
  /** delete data from the table: "provider_subscription_plans" */
  readonly delete_provider_subscription_plans?: Maybe<Provider_Subscription_Plans_Mutation_Response>;
  /** delete single row from the table: "provider_subscription_plans" */
  readonly delete_provider_subscription_plans_by_pk?: Maybe<Provider_Subscription_Plans>;
  /** delete data from the table: "provider_subscriptions" */
  readonly delete_provider_subscriptions?: Maybe<Provider_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "provider_subscriptions" */
  readonly delete_provider_subscriptions_by_pk?: Maybe<Provider_Subscriptions>;
  /** delete data from the table: "provider_zoom" */
  readonly delete_provider_zoom?: Maybe<Provider_Zoom_Mutation_Response>;
  /** delete single row from the table: "provider_zoom" */
  readonly delete_provider_zoom_by_pk?: Maybe<Provider_Zoom>;
  /** delete data from the table: "ranking_reward" */
  readonly delete_ranking_reward?: Maybe<Ranking_Reward_Mutation_Response>;
  /** delete single row from the table: "ranking_reward" */
  readonly delete_ranking_reward_by_pk?: Maybe<Ranking_Reward>;
  /** delete data from the table: "ranking_rewards" */
  readonly delete_ranking_rewards?: Maybe<Ranking_Rewards_Mutation_Response>;
  /** delete single row from the table: "ranking_rewards" */
  readonly delete_ranking_rewards_by_pk?: Maybe<Ranking_Rewards>;
  /** delete data from the table: "reward_certificate" */
  readonly delete_reward_certificate?: Maybe<Reward_Certificate_Mutation_Response>;
  /** delete single row from the table: "reward_certificate" */
  readonly delete_reward_certificate_by_pk?: Maybe<Reward_Certificate>;
  /** delete data from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly delete_reward_certificate_signatories_certificate_signatory?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Mutation_Response>;
  /** delete single row from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly delete_reward_certificate_signatories_certificate_signatory_by_pk?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** delete data from the table: "schema_migrations" */
  readonly delete_schema_migrations?: Maybe<Schema_Migrations_Mutation_Response>;
  /** delete single row from the table: "schema_migrations" */
  readonly delete_schema_migrations_by_pk?: Maybe<Schema_Migrations>;
  /** delete data from the table: "speakers" */
  readonly delete_speakers?: Maybe<Speakers_Mutation_Response>;
  /** delete single row from the table: "speakers" */
  readonly delete_speakers_by_pk?: Maybe<Speakers>;
  /** delete data from the table: "stream" */
  readonly delete_stream?: Maybe<Stream_Mutation_Response>;
  /** delete data from the table: "stream_attachment" */
  readonly delete_stream_attachment?: Maybe<Stream_Attachment_Mutation_Response>;
  /** delete single row from the table: "stream_attachment" */
  readonly delete_stream_attachment_by_pk?: Maybe<Stream_Attachment>;
  /** delete single row from the table: "stream" */
  readonly delete_stream_by_pk?: Maybe<Stream>;
  /** delete data from the table: "stream_forms_form" */
  readonly delete_stream_forms_form?: Maybe<Stream_Forms_Form_Mutation_Response>;
  /** delete single row from the table: "stream_forms_form" */
  readonly delete_stream_forms_form_by_pk?: Maybe<Stream_Forms_Form>;
  /** delete data from the table: "student_progress" */
  readonly delete_student_progress?: Maybe<Student_Progress_Mutation_Response>;
  /** delete single row from the table: "student_progress" */
  readonly delete_student_progress_by_pk?: Maybe<Student_Progress>;
  /** delete data from the table: "user" */
  readonly delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  readonly delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_certificates" */
  readonly delete_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** delete single row from the table: "user_certificates" */
  readonly delete_user_certificates_by_pk?: Maybe<User_Certificates>;
  /** delete data from the table: "user_followed_industries_industry" */
  readonly delete_user_followed_industries_industry?: Maybe<User_Followed_Industries_Industry_Mutation_Response>;
  /** delete single row from the table: "user_followed_industries_industry" */
  readonly delete_user_followed_industries_industry_by_pk?: Maybe<User_Followed_Industries_Industry>;
  /** delete data from the table: "user_provider_subscriptions_provider" */
  readonly delete_user_provider_subscriptions_provider?: Maybe<User_Provider_Subscriptions_Provider_Mutation_Response>;
  /** delete single row from the table: "user_provider_subscriptions_provider" */
  readonly delete_user_provider_subscriptions_provider_by_pk?: Maybe<User_Provider_Subscriptions_Provider>;
  /** delete data from the table: "user_purchased_events_event" */
  readonly delete_user_purchased_events_event?: Maybe<User_Purchased_Events_Event_Mutation_Response>;
  /** delete single row from the table: "user_purchased_events_event" */
  readonly delete_user_purchased_events_event_by_pk?: Maybe<User_Purchased_Events_Event>;
  /** delete data from the table: "users" */
  readonly delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  readonly delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "account_google" */
  readonly insert_account_google?: Maybe<Account_Google_Mutation_Response>;
  /** insert a single row into the table: "account_google" */
  readonly insert_account_google_one?: Maybe<Account_Google>;
  /** insert data into the table: "account_subscription_plans" */
  readonly insert_account_subscription_plans?: Maybe<Account_Subscription_Plans_Mutation_Response>;
  /** insert a single row into the table: "account_subscription_plans" */
  readonly insert_account_subscription_plans_one?: Maybe<Account_Subscription_Plans>;
  /** insert data into the table: "account_subscriptions" */
  readonly insert_account_subscriptions?: Maybe<Account_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "account_subscriptions" */
  readonly insert_account_subscriptions_one?: Maybe<Account_Subscriptions>;
  /** insert data into the table: "account_zoom" */
  readonly insert_account_zoom?: Maybe<Account_Zoom_Mutation_Response>;
  /** insert a single row into the table: "account_zoom" */
  readonly insert_account_zoom_one?: Maybe<Account_Zoom>;
  /** insert data into the table: "accounts" */
  readonly insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  readonly insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "ar_internal_metadata" */
  readonly insert_ar_internal_metadata?: Maybe<Ar_Internal_Metadata_Mutation_Response>;
  /** insert a single row into the table: "ar_internal_metadata" */
  readonly insert_ar_internal_metadata_one?: Maybe<Ar_Internal_Metadata>;
  /** insert data into the table: "attachment" */
  readonly insert_attachment?: Maybe<Attachment_Mutation_Response>;
  /** insert a single row into the table: "attachment" */
  readonly insert_attachment_one?: Maybe<Attachment>;
  /** insert data into the table: "attendance_logs" */
  readonly insert_attendance_logs?: Maybe<Attendance_Logs_Mutation_Response>;
  /** insert a single row into the table: "attendance_logs" */
  readonly insert_attendance_logs_one?: Maybe<Attendance_Logs>;
  /** insert data into the table: "attendances" */
  readonly insert_attendances?: Maybe<Attendances_Mutation_Response>;
  /** insert a single row into the table: "attendances" */
  readonly insert_attendances_one?: Maybe<Attendances>;
  /** insert data into the table: "breakout_group" */
  readonly insert_breakout_group?: Maybe<Breakout_Group_Mutation_Response>;
  /** insert a single row into the table: "breakout_group" */
  readonly insert_breakout_group_one?: Maybe<Breakout_Group>;
  /** insert data into the table: "breakout_group_participants_event_participant" */
  readonly insert_breakout_group_participants_event_participant?: Maybe<Breakout_Group_Participants_Event_Participant_Mutation_Response>;
  /** insert a single row into the table: "breakout_group_participants_event_participant" */
  readonly insert_breakout_group_participants_event_participant_one?: Maybe<Breakout_Group_Participants_Event_Participant>;
  /** insert data into the table: "breakout_room" */
  readonly insert_breakout_room?: Maybe<Breakout_Room_Mutation_Response>;
  /** insert a single row into the table: "breakout_room" */
  readonly insert_breakout_room_one?: Maybe<Breakout_Room>;
  /** insert data into the table: "bunny_upload" */
  readonly insert_bunny_upload?: Maybe<Bunny_Upload_Mutation_Response>;
  /** insert a single row into the table: "bunny_upload" */
  readonly insert_bunny_upload_one?: Maybe<Bunny_Upload>;
  /** insert data into the table: "bunny_upload_states" */
  readonly insert_bunny_upload_states?: Maybe<Bunny_Upload_States_Mutation_Response>;
  /** insert a single row into the table: "bunny_upload_states" */
  readonly insert_bunny_upload_states_one?: Maybe<Bunny_Upload_States>;
  /** insert data into the table: "carousel_item" */
  readonly insert_carousel_item?: Maybe<Carousel_Item_Mutation_Response>;
  /** insert a single row into the table: "carousel_item" */
  readonly insert_carousel_item_one?: Maybe<Carousel_Item>;
  /** insert data into the table: "certificate_signatory" */
  readonly insert_certificate_signatory?: Maybe<Certificate_Signatory_Mutation_Response>;
  /** insert a single row into the table: "certificate_signatory" */
  readonly insert_certificate_signatory_one?: Maybe<Certificate_Signatory>;
  /** insert data into the table: "certificate_template_signatories" */
  readonly insert_certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Mutation_Response>;
  /** insert a single row into the table: "certificate_template_signatories" */
  readonly insert_certificate_template_signatories_one?: Maybe<Certificate_Template_Signatories>;
  /** insert data into the table: "certificate_templates" */
  readonly insert_certificate_templates?: Maybe<Certificate_Templates_Mutation_Response>;
  /** insert a single row into the table: "certificate_templates" */
  readonly insert_certificate_templates_one?: Maybe<Certificate_Templates>;
  /** insert data into the table: "course_articles" */
  readonly insert_course_articles?: Maybe<Course_Articles_Mutation_Response>;
  /** insert a single row into the table: "course_articles" */
  readonly insert_course_articles_one?: Maybe<Course_Articles>;
  /** insert data into the table: "course_assessments" */
  readonly insert_course_assessments?: Maybe<Course_Assessments_Mutation_Response>;
  /** insert a single row into the table: "course_assessments" */
  readonly insert_course_assessments_one?: Maybe<Course_Assessments>;
  /** insert data into the table: "course_attachments" */
  readonly insert_course_attachments?: Maybe<Course_Attachments_Mutation_Response>;
  /** insert a single row into the table: "course_attachments" */
  readonly insert_course_attachments_one?: Maybe<Course_Attachments>;
  /** insert data into the table: "course_certificates" */
  readonly insert_course_certificates?: Maybe<Course_Certificates_Mutation_Response>;
  /** insert a single row into the table: "course_certificates" */
  readonly insert_course_certificates_one?: Maybe<Course_Certificates>;
  /** insert data into the table: "course_discount_types" */
  readonly insert_course_discount_types?: Maybe<Course_Discount_Types_Mutation_Response>;
  /** insert a single row into the table: "course_discount_types" */
  readonly insert_course_discount_types_one?: Maybe<Course_Discount_Types>;
  /** insert data into the table: "course_discounts" */
  readonly insert_course_discounts?: Maybe<Course_Discounts_Mutation_Response>;
  /** insert a single row into the table: "course_discounts" */
  readonly insert_course_discounts_one?: Maybe<Course_Discounts>;
  /** insert data into the table: "course_forms" */
  readonly insert_course_forms?: Maybe<Course_Forms_Mutation_Response>;
  /** insert a single row into the table: "course_forms" */
  readonly insert_course_forms_one?: Maybe<Course_Forms>;
  /** insert data into the table: "course_gains" */
  readonly insert_course_gains?: Maybe<Course_Gains_Mutation_Response>;
  /** insert a single row into the table: "course_gains" */
  readonly insert_course_gains_one?: Maybe<Course_Gains>;
  /** insert data into the table: "course_instructors" */
  readonly insert_course_instructors?: Maybe<Course_Instructors_Mutation_Response>;
  /** insert a single row into the table: "course_instructors" */
  readonly insert_course_instructors_one?: Maybe<Course_Instructors>;
  /** insert data into the table: "course_invites" */
  readonly insert_course_invites?: Maybe<Course_Invites_Mutation_Response>;
  /** insert a single row into the table: "course_invites" */
  readonly insert_course_invites_one?: Maybe<Course_Invites>;
  /** insert data into the table: "course_lecturers" */
  readonly insert_course_lecturers?: Maybe<Course_Lecturers_Mutation_Response>;
  /** insert a single row into the table: "course_lecturers" */
  readonly insert_course_lecturers_one?: Maybe<Course_Lecturers>;
  /** insert data into the table: "course_live_sessions" */
  readonly insert_course_live_sessions?: Maybe<Course_Live_Sessions_Mutation_Response>;
  /** insert a single row into the table: "course_live_sessions" */
  readonly insert_course_live_sessions_one?: Maybe<Course_Live_Sessions>;
  /** insert data into the table: "course_module_students" */
  readonly insert_course_module_students?: Maybe<Course_Module_Students_Mutation_Response>;
  /** insert a single row into the table: "course_module_students" */
  readonly insert_course_module_students_one?: Maybe<Course_Module_Students>;
  /** insert data into the table: "course_modules" */
  readonly insert_course_modules?: Maybe<Course_Modules_Mutation_Response>;
  /** insert a single row into the table: "course_modules" */
  readonly insert_course_modules_one?: Maybe<Course_Modules>;
  /** insert data into the table: "course_objectives" */
  readonly insert_course_objectives?: Maybe<Course_Objectives_Mutation_Response>;
  /** insert a single row into the table: "course_objectives" */
  readonly insert_course_objectives_one?: Maybe<Course_Objectives>;
  /** insert data into the table: "course_order" */
  readonly insert_course_order?: Maybe<Course_Order_Mutation_Response>;
  /** insert data into the table: "course_order_modules" */
  readonly insert_course_order_modules?: Maybe<Course_Order_Modules_Mutation_Response>;
  /** insert a single row into the table: "course_order_modules" */
  readonly insert_course_order_modules_one?: Maybe<Course_Order_Modules>;
  /** insert a single row into the table: "course_order" */
  readonly insert_course_order_one?: Maybe<Course_Order>;
  /** insert data into the table: "course_order_statuses" */
  readonly insert_course_order_statuses?: Maybe<Course_Order_Statuses_Mutation_Response>;
  /** insert a single row into the table: "course_order_statuses" */
  readonly insert_course_order_statuses_one?: Maybe<Course_Order_Statuses>;
  /** insert data into the table: "course_payment_methods" */
  readonly insert_course_payment_methods?: Maybe<Course_Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "course_payment_methods" */
  readonly insert_course_payment_methods_one?: Maybe<Course_Payment_Methods>;
  /** insert data into the table: "course_reminders" */
  readonly insert_course_reminders?: Maybe<Course_Reminders_Mutation_Response>;
  /** insert a single row into the table: "course_reminders" */
  readonly insert_course_reminders_one?: Maybe<Course_Reminders>;
  /** insert data into the table: "course_requirements" */
  readonly insert_course_requirements?: Maybe<Course_Requirements_Mutation_Response>;
  /** insert a single row into the table: "course_requirements" */
  readonly insert_course_requirements_one?: Maybe<Course_Requirements>;
  /** insert data into the table: "course_rewards" */
  readonly insert_course_rewards?: Maybe<Course_Rewards_Mutation_Response>;
  /** insert a single row into the table: "course_rewards" */
  readonly insert_course_rewards_one?: Maybe<Course_Rewards>;
  /** insert data into the table: "course_sell_types" */
  readonly insert_course_sell_types?: Maybe<Course_Sell_Types_Mutation_Response>;
  /** insert a single row into the table: "course_sell_types" */
  readonly insert_course_sell_types_one?: Maybe<Course_Sell_Types>;
  /** insert data into the table: "course_signatories" */
  readonly insert_course_signatories?: Maybe<Course_Signatories_Mutation_Response>;
  /** insert a single row into the table: "course_signatories" */
  readonly insert_course_signatories_one?: Maybe<Course_Signatories>;
  /** insert data into the table: "course_students" */
  readonly insert_course_students?: Maybe<Course_Students_Mutation_Response>;
  /** insert a single row into the table: "course_students" */
  readonly insert_course_students_one?: Maybe<Course_Students>;
  /** insert data into the table: "course_videos" */
  readonly insert_course_videos?: Maybe<Course_Videos_Mutation_Response>;
  /** insert a single row into the table: "course_videos" */
  readonly insert_course_videos_one?: Maybe<Course_Videos>;
  /** insert data into the table: "course_videos_statuses" */
  readonly insert_course_videos_statuses?: Maybe<Course_Videos_Statuses_Mutation_Response>;
  /** insert a single row into the table: "course_videos_statuses" */
  readonly insert_course_videos_statuses_one?: Maybe<Course_Videos_Statuses>;
  /** insert data into the table: "courses" */
  readonly insert_courses?: Maybe<Courses_Mutation_Response>;
  /** insert a single row into the table: "courses" */
  readonly insert_courses_one?: Maybe<Courses>;
  /** insert data into the table: "cpd_unit_rewards" */
  readonly insert_cpd_unit_rewards?: Maybe<Cpd_Unit_Rewards_Mutation_Response>;
  /** insert a single row into the table: "cpd_unit_rewards" */
  readonly insert_cpd_unit_rewards_one?: Maybe<Cpd_Unit_Rewards>;
  /** insert data into the table: "cpd_units_reward" */
  readonly insert_cpd_units_reward?: Maybe<Cpd_Units_Reward_Mutation_Response>;
  /** insert a single row into the table: "cpd_units_reward" */
  readonly insert_cpd_units_reward_one?: Maybe<Cpd_Units_Reward>;
  /** insert data into the table: "customer" */
  readonly insert_customer?: Maybe<Customer_Mutation_Response>;
  /** insert a single row into the table: "customer" */
  readonly insert_customer_one?: Maybe<Customer>;
  /** insert data into the table: "discount" */
  readonly insert_discount?: Maybe<Discount_Mutation_Response>;
  /** insert a single row into the table: "discount" */
  readonly insert_discount_one?: Maybe<Discount>;
  /** insert data into the table: "email_notification_types" */
  readonly insert_email_notification_types?: Maybe<Email_Notification_Types_Mutation_Response>;
  /** insert a single row into the table: "email_notification_types" */
  readonly insert_email_notification_types_one?: Maybe<Email_Notification_Types>;
  /** insert data into the table: "email_notifications" */
  readonly insert_email_notifications?: Maybe<Email_Notifications_Mutation_Response>;
  /** insert a single row into the table: "email_notifications" */
  readonly insert_email_notifications_one?: Maybe<Email_Notifications>;
  /** insert data into the table: "event" */
  readonly insert_event?: Maybe<Event_Mutation_Response>;
  /** insert data into the table: "event_industries_industry" */
  readonly insert_event_industries_industry?: Maybe<Event_Industries_Industry_Mutation_Response>;
  /** insert a single row into the table: "event_industries_industry" */
  readonly insert_event_industries_industry_one?: Maybe<Event_Industries_Industry>;
  /** insert data into the table: "event_managing_users_user" */
  readonly insert_event_managing_users_user?: Maybe<Event_Managing_Users_User_Mutation_Response>;
  /** insert a single row into the table: "event_managing_users_user" */
  readonly insert_event_managing_users_user_one?: Maybe<Event_Managing_Users_User>;
  /** insert a single row into the table: "event" */
  readonly insert_event_one?: Maybe<Event>;
  /** insert data into the table: "event_participant" */
  readonly insert_event_participant?: Maybe<Event_Participant_Mutation_Response>;
  /** insert a single row into the table: "event_participant" */
  readonly insert_event_participant_one?: Maybe<Event_Participant>;
  /** insert data into the table: "faq_item" */
  readonly insert_faq_item?: Maybe<Faq_Item_Mutation_Response>;
  /** insert a single row into the table: "faq_item" */
  readonly insert_faq_item_one?: Maybe<Faq_Item>;
  /** insert data into the table: "form" */
  readonly insert_form?: Maybe<Form_Mutation_Response>;
  /** insert data into the table: "form_event_names" */
  readonly insert_form_event_names?: Maybe<Form_Event_Names_Mutation_Response>;
  /** insert a single row into the table: "form_event_names" */
  readonly insert_form_event_names_one?: Maybe<Form_Event_Names>;
  /** insert data into the table: "form_events" */
  readonly insert_form_events?: Maybe<Form_Events_Mutation_Response>;
  /** insert a single row into the table: "form_events" */
  readonly insert_form_events_one?: Maybe<Form_Events>;
  /** insert data into the table: "form_instance" */
  readonly insert_form_instance?: Maybe<Form_Instance_Mutation_Response>;
  /** insert a single row into the table: "form_instance" */
  readonly insert_form_instance_one?: Maybe<Form_Instance>;
  /** insert a single row into the table: "form" */
  readonly insert_form_one?: Maybe<Form>;
  /** insert data into the table: "form_question_answers" */
  readonly insert_form_question_answers?: Maybe<Form_Question_Answers_Mutation_Response>;
  /** insert a single row into the table: "form_question_answers" */
  readonly insert_form_question_answers_one?: Maybe<Form_Question_Answers>;
  /** insert data into the table: "form_question_submission_states" */
  readonly insert_form_question_submission_states?: Maybe<Form_Question_Submission_States_Mutation_Response>;
  /** insert a single row into the table: "form_question_submission_states" */
  readonly insert_form_question_submission_states_one?: Maybe<Form_Question_Submission_States>;
  /** insert data into the table: "form_question_submissions" */
  readonly insert_form_question_submissions?: Maybe<Form_Question_Submissions_Mutation_Response>;
  /** insert a single row into the table: "form_question_submissions" */
  readonly insert_form_question_submissions_one?: Maybe<Form_Question_Submissions>;
  /** insert data into the table: "form_question_types" */
  readonly insert_form_question_types?: Maybe<Form_Question_Types_Mutation_Response>;
  /** insert a single row into the table: "form_question_types" */
  readonly insert_form_question_types_one?: Maybe<Form_Question_Types>;
  /** insert data into the table: "form_questions" */
  readonly insert_form_questions?: Maybe<Form_Questions_Mutation_Response>;
  /** insert a single row into the table: "form_questions" */
  readonly insert_form_questions_one?: Maybe<Form_Questions>;
  /** insert data into the table: "form_score" */
  readonly insert_form_score?: Maybe<Form_Score_Mutation_Response>;
  /** insert a single row into the table: "form_score" */
  readonly insert_form_score_one?: Maybe<Form_Score>;
  /** insert data into the table: "form_scoring_policies" */
  readonly insert_form_scoring_policies?: Maybe<Form_Scoring_Policies_Mutation_Response>;
  /** insert a single row into the table: "form_scoring_policies" */
  readonly insert_form_scoring_policies_one?: Maybe<Form_Scoring_Policies>;
  /** insert data into the table: "form_submission_statuses" */
  readonly insert_form_submission_statuses?: Maybe<Form_Submission_Statuses_Mutation_Response>;
  /** insert a single row into the table: "form_submission_statuses" */
  readonly insert_form_submission_statuses_one?: Maybe<Form_Submission_Statuses>;
  /** insert data into the table: "form_submissions" */
  readonly insert_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** insert a single row into the table: "form_submissions" */
  readonly insert_form_submissions_one?: Maybe<Form_Submissions>;
  /** insert data into the table: "form_types" */
  readonly insert_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** insert a single row into the table: "form_types" */
  readonly insert_form_types_one?: Maybe<Form_Types>;
  /** insert data into the table: "forms" */
  readonly insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  readonly insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "images" */
  readonly insert_images?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "images" */
  readonly insert_images_one?: Maybe<Images>;
  /** insert data into the table: "industry" */
  readonly insert_industry?: Maybe<Industry_Mutation_Response>;
  /** insert data into the table: "industry_category" */
  readonly insert_industry_category?: Maybe<Industry_Category_Mutation_Response>;
  /** insert a single row into the table: "industry_category" */
  readonly insert_industry_category_one?: Maybe<Industry_Category>;
  /** insert a single row into the table: "industry" */
  readonly insert_industry_one?: Maybe<Industry>;
  /** insert data into the table: "industry_type" */
  readonly insert_industry_type?: Maybe<Industry_Type_Mutation_Response>;
  /** insert a single row into the table: "industry_type" */
  readonly insert_industry_type_one?: Maybe<Industry_Type>;
  /** insert data into the table: "migrations" */
  readonly insert_migrations?: Maybe<Migrations_Mutation_Response>;
  /** insert a single row into the table: "migrations" */
  readonly insert_migrations_one?: Maybe<Migrations>;
  /** insert data into the table: "mock_order" */
  readonly insert_mock_order?: Maybe<Mock_Order_Mutation_Response>;
  /** insert data into the table: "mock_order2" */
  readonly insert_mock_order2?: Maybe<Mock_Order2_Mutation_Response>;
  /** insert a single row into the table: "mock_order2" */
  readonly insert_mock_order2_one?: Maybe<Mock_Order2>;
  /** insert a single row into the table: "mock_order" */
  readonly insert_mock_order_one?: Maybe<Mock_Order>;
  /** insert data into the table: "module_item_comments" */
  readonly insert_module_item_comments?: Maybe<Module_Item_Comments_Mutation_Response>;
  /** insert a single row into the table: "module_item_comments" */
  readonly insert_module_item_comments_one?: Maybe<Module_Item_Comments>;
  /** insert data into the table: "module_items" */
  readonly insert_module_items?: Maybe<Module_Items_Mutation_Response>;
  /** insert a single row into the table: "module_items" */
  readonly insert_module_items_one?: Maybe<Module_Items>;
  /** insert data into the table: "order" */
  readonly insert_order?: Maybe<Order_Mutation_Response>;
  /** insert a single row into the table: "order" */
  readonly insert_order_one?: Maybe<Order>;
  /** insert data into the table: "other_reward" */
  readonly insert_other_reward?: Maybe<Other_Reward_Mutation_Response>;
  /** insert a single row into the table: "other_reward" */
  readonly insert_other_reward_one?: Maybe<Other_Reward>;
  /** insert data into the table: "other_reward_type" */
  readonly insert_other_reward_type?: Maybe<Other_Reward_Type_Mutation_Response>;
  /** insert a single row into the table: "other_reward_type" */
  readonly insert_other_reward_type_one?: Maybe<Other_Reward_Type>;
  /** insert data into the table: "other_unit_rewards" */
  readonly insert_other_unit_rewards?: Maybe<Other_Unit_Rewards_Mutation_Response>;
  /** insert a single row into the table: "other_unit_rewards" */
  readonly insert_other_unit_rewards_one?: Maybe<Other_Unit_Rewards>;
  /** insert data into the table: "product" */
  readonly insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  readonly insert_product_one?: Maybe<Product>;
  /** insert data into the table: "provider" */
  readonly insert_provider?: Maybe<Provider_Mutation_Response>;
  /** insert data into the table: "provider_application" */
  readonly insert_provider_application?: Maybe<Provider_Application_Mutation_Response>;
  /** insert data into the table: "provider_application_industries_industry" */
  readonly insert_provider_application_industries_industry?: Maybe<Provider_Application_Industries_Industry_Mutation_Response>;
  /** insert a single row into the table: "provider_application_industries_industry" */
  readonly insert_provider_application_industries_industry_one?: Maybe<Provider_Application_Industries_Industry>;
  /** insert a single row into the table: "provider_application" */
  readonly insert_provider_application_one?: Maybe<Provider_Application>;
  /** insert data into the table: "provider_dragonpay_credentials" */
  readonly insert_provider_dragonpay_credentials?: Maybe<Provider_Dragonpay_Credentials_Mutation_Response>;
  /** insert a single row into the table: "provider_dragonpay_credentials" */
  readonly insert_provider_dragonpay_credentials_one?: Maybe<Provider_Dragonpay_Credentials>;
  /** insert data into the table: "provider_frequently_used_industries_industry" */
  readonly insert_provider_frequently_used_industries_industry?: Maybe<Provider_Frequently_Used_Industries_Industry_Mutation_Response>;
  /** insert a single row into the table: "provider_frequently_used_industries_industry" */
  readonly insert_provider_frequently_used_industries_industry_one?: Maybe<Provider_Frequently_Used_Industries_Industry>;
  /** insert data into the table: "provider_google" */
  readonly insert_provider_google?: Maybe<Provider_Google_Mutation_Response>;
  /** insert a single row into the table: "provider_google" */
  readonly insert_provider_google_one?: Maybe<Provider_Google>;
  /** insert data into the table: "provider_managing_users_user" */
  readonly insert_provider_managing_users_user?: Maybe<Provider_Managing_Users_User_Mutation_Response>;
  /** insert a single row into the table: "provider_managing_users_user" */
  readonly insert_provider_managing_users_user_one?: Maybe<Provider_Managing_Users_User>;
  /** insert a single row into the table: "provider" */
  readonly insert_provider_one?: Maybe<Provider>;
  /** insert data into the table: "provider_subscription_plan" */
  readonly insert_provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Mutation_Response>;
  /** insert a single row into the table: "provider_subscription_plan" */
  readonly insert_provider_subscription_plan_one?: Maybe<Provider_Subscription_Plan>;
  /** insert data into the table: "provider_subscription_plans" */
  readonly insert_provider_subscription_plans?: Maybe<Provider_Subscription_Plans_Mutation_Response>;
  /** insert a single row into the table: "provider_subscription_plans" */
  readonly insert_provider_subscription_plans_one?: Maybe<Provider_Subscription_Plans>;
  /** insert data into the table: "provider_subscriptions" */
  readonly insert_provider_subscriptions?: Maybe<Provider_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "provider_subscriptions" */
  readonly insert_provider_subscriptions_one?: Maybe<Provider_Subscriptions>;
  /** insert data into the table: "provider_zoom" */
  readonly insert_provider_zoom?: Maybe<Provider_Zoom_Mutation_Response>;
  /** insert a single row into the table: "provider_zoom" */
  readonly insert_provider_zoom_one?: Maybe<Provider_Zoom>;
  /** insert data into the table: "ranking_reward" */
  readonly insert_ranking_reward?: Maybe<Ranking_Reward_Mutation_Response>;
  /** insert a single row into the table: "ranking_reward" */
  readonly insert_ranking_reward_one?: Maybe<Ranking_Reward>;
  /** insert data into the table: "ranking_rewards" */
  readonly insert_ranking_rewards?: Maybe<Ranking_Rewards_Mutation_Response>;
  /** insert a single row into the table: "ranking_rewards" */
  readonly insert_ranking_rewards_one?: Maybe<Ranking_Rewards>;
  /** insert data into the table: "reward_certificate" */
  readonly insert_reward_certificate?: Maybe<Reward_Certificate_Mutation_Response>;
  /** insert a single row into the table: "reward_certificate" */
  readonly insert_reward_certificate_one?: Maybe<Reward_Certificate>;
  /** insert data into the table: "reward_certificate_signatories_certificate_signatory" */
  readonly insert_reward_certificate_signatories_certificate_signatory?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Mutation_Response>;
  /** insert a single row into the table: "reward_certificate_signatories_certificate_signatory" */
  readonly insert_reward_certificate_signatories_certificate_signatory_one?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** insert data into the table: "schema_migrations" */
  readonly insert_schema_migrations?: Maybe<Schema_Migrations_Mutation_Response>;
  /** insert a single row into the table: "schema_migrations" */
  readonly insert_schema_migrations_one?: Maybe<Schema_Migrations>;
  /** insert data into the table: "speakers" */
  readonly insert_speakers?: Maybe<Speakers_Mutation_Response>;
  /** insert a single row into the table: "speakers" */
  readonly insert_speakers_one?: Maybe<Speakers>;
  /** insert data into the table: "stream" */
  readonly insert_stream?: Maybe<Stream_Mutation_Response>;
  /** insert data into the table: "stream_attachment" */
  readonly insert_stream_attachment?: Maybe<Stream_Attachment_Mutation_Response>;
  /** insert a single row into the table: "stream_attachment" */
  readonly insert_stream_attachment_one?: Maybe<Stream_Attachment>;
  /** insert data into the table: "stream_forms_form" */
  readonly insert_stream_forms_form?: Maybe<Stream_Forms_Form_Mutation_Response>;
  /** insert a single row into the table: "stream_forms_form" */
  readonly insert_stream_forms_form_one?: Maybe<Stream_Forms_Form>;
  /** insert a single row into the table: "stream" */
  readonly insert_stream_one?: Maybe<Stream>;
  /** insert data into the table: "student_progress" */
  readonly insert_student_progress?: Maybe<Student_Progress_Mutation_Response>;
  /** insert a single row into the table: "student_progress" */
  readonly insert_student_progress_one?: Maybe<Student_Progress>;
  /** insert data into the table: "user" */
  readonly insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_certificates" */
  readonly insert_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** insert a single row into the table: "user_certificates" */
  readonly insert_user_certificates_one?: Maybe<User_Certificates>;
  /** insert data into the table: "user_followed_industries_industry" */
  readonly insert_user_followed_industries_industry?: Maybe<User_Followed_Industries_Industry_Mutation_Response>;
  /** insert a single row into the table: "user_followed_industries_industry" */
  readonly insert_user_followed_industries_industry_one?: Maybe<User_Followed_Industries_Industry>;
  /** insert a single row into the table: "user" */
  readonly insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_provider_subscriptions_provider" */
  readonly insert_user_provider_subscriptions_provider?: Maybe<User_Provider_Subscriptions_Provider_Mutation_Response>;
  /** insert a single row into the table: "user_provider_subscriptions_provider" */
  readonly insert_user_provider_subscriptions_provider_one?: Maybe<User_Provider_Subscriptions_Provider>;
  /** insert data into the table: "user_purchased_events_event" */
  readonly insert_user_purchased_events_event?: Maybe<User_Purchased_Events_Event_Mutation_Response>;
  /** insert a single row into the table: "user_purchased_events_event" */
  readonly insert_user_purchased_events_event_one?: Maybe<User_Purchased_Events_Event>;
  /** insert data into the table: "users" */
  readonly insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  readonly insert_users_one?: Maybe<Users>;
  /** update data of the table: "account_google" */
  readonly update_account_google?: Maybe<Account_Google_Mutation_Response>;
  /** update single row of the table: "account_google" */
  readonly update_account_google_by_pk?: Maybe<Account_Google>;
  /** update data of the table: "account_subscription_plans" */
  readonly update_account_subscription_plans?: Maybe<Account_Subscription_Plans_Mutation_Response>;
  /** update single row of the table: "account_subscription_plans" */
  readonly update_account_subscription_plans_by_pk?: Maybe<Account_Subscription_Plans>;
  /** update data of the table: "account_subscriptions" */
  readonly update_account_subscriptions?: Maybe<Account_Subscriptions_Mutation_Response>;
  /** update single row of the table: "account_subscriptions" */
  readonly update_account_subscriptions_by_pk?: Maybe<Account_Subscriptions>;
  /** update data of the table: "account_zoom" */
  readonly update_account_zoom?: Maybe<Account_Zoom_Mutation_Response>;
  /** update single row of the table: "account_zoom" */
  readonly update_account_zoom_by_pk?: Maybe<Account_Zoom>;
  /** update data of the table: "accounts" */
  readonly update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  readonly update_accounts_by_pk?: Maybe<Accounts>;
  /** update data of the table: "ar_internal_metadata" */
  readonly update_ar_internal_metadata?: Maybe<Ar_Internal_Metadata_Mutation_Response>;
  /** update single row of the table: "ar_internal_metadata" */
  readonly update_ar_internal_metadata_by_pk?: Maybe<Ar_Internal_Metadata>;
  /** update data of the table: "attachment" */
  readonly update_attachment?: Maybe<Attachment_Mutation_Response>;
  /** update single row of the table: "attachment" */
  readonly update_attachment_by_pk?: Maybe<Attachment>;
  /** update data of the table: "attendance_logs" */
  readonly update_attendance_logs?: Maybe<Attendance_Logs_Mutation_Response>;
  /** update single row of the table: "attendance_logs" */
  readonly update_attendance_logs_by_pk?: Maybe<Attendance_Logs>;
  /** update data of the table: "attendances" */
  readonly update_attendances?: Maybe<Attendances_Mutation_Response>;
  /** update single row of the table: "attendances" */
  readonly update_attendances_by_pk?: Maybe<Attendances>;
  /** update data of the table: "breakout_group" */
  readonly update_breakout_group?: Maybe<Breakout_Group_Mutation_Response>;
  /** update single row of the table: "breakout_group" */
  readonly update_breakout_group_by_pk?: Maybe<Breakout_Group>;
  /** update data of the table: "breakout_group_participants_event_participant" */
  readonly update_breakout_group_participants_event_participant?: Maybe<Breakout_Group_Participants_Event_Participant_Mutation_Response>;
  /** update single row of the table: "breakout_group_participants_event_participant" */
  readonly update_breakout_group_participants_event_participant_by_pk?: Maybe<Breakout_Group_Participants_Event_Participant>;
  /** update data of the table: "breakout_room" */
  readonly update_breakout_room?: Maybe<Breakout_Room_Mutation_Response>;
  /** update single row of the table: "breakout_room" */
  readonly update_breakout_room_by_pk?: Maybe<Breakout_Room>;
  /** update data of the table: "bunny_upload" */
  readonly update_bunny_upload?: Maybe<Bunny_Upload_Mutation_Response>;
  /** update single row of the table: "bunny_upload" */
  readonly update_bunny_upload_by_pk?: Maybe<Bunny_Upload>;
  /** update data of the table: "bunny_upload_states" */
  readonly update_bunny_upload_states?: Maybe<Bunny_Upload_States_Mutation_Response>;
  /** update single row of the table: "bunny_upload_states" */
  readonly update_bunny_upload_states_by_pk?: Maybe<Bunny_Upload_States>;
  /** update data of the table: "carousel_item" */
  readonly update_carousel_item?: Maybe<Carousel_Item_Mutation_Response>;
  /** update single row of the table: "carousel_item" */
  readonly update_carousel_item_by_pk?: Maybe<Carousel_Item>;
  /** update data of the table: "certificate_signatory" */
  readonly update_certificate_signatory?: Maybe<Certificate_Signatory_Mutation_Response>;
  /** update single row of the table: "certificate_signatory" */
  readonly update_certificate_signatory_by_pk?: Maybe<Certificate_Signatory>;
  /** update data of the table: "certificate_template_signatories" */
  readonly update_certificate_template_signatories?: Maybe<Certificate_Template_Signatories_Mutation_Response>;
  /** update single row of the table: "certificate_template_signatories" */
  readonly update_certificate_template_signatories_by_pk?: Maybe<Certificate_Template_Signatories>;
  /** update data of the table: "certificate_templates" */
  readonly update_certificate_templates?: Maybe<Certificate_Templates_Mutation_Response>;
  /** update single row of the table: "certificate_templates" */
  readonly update_certificate_templates_by_pk?: Maybe<Certificate_Templates>;
  /** update data of the table: "course_articles" */
  readonly update_course_articles?: Maybe<Course_Articles_Mutation_Response>;
  /** update single row of the table: "course_articles" */
  readonly update_course_articles_by_pk?: Maybe<Course_Articles>;
  /** update data of the table: "course_assessments" */
  readonly update_course_assessments?: Maybe<Course_Assessments_Mutation_Response>;
  /** update single row of the table: "course_assessments" */
  readonly update_course_assessments_by_pk?: Maybe<Course_Assessments>;
  /** update data of the table: "course_attachments" */
  readonly update_course_attachments?: Maybe<Course_Attachments_Mutation_Response>;
  /** update single row of the table: "course_attachments" */
  readonly update_course_attachments_by_pk?: Maybe<Course_Attachments>;
  /** update data of the table: "course_certificates" */
  readonly update_course_certificates?: Maybe<Course_Certificates_Mutation_Response>;
  /** update single row of the table: "course_certificates" */
  readonly update_course_certificates_by_pk?: Maybe<Course_Certificates>;
  /** update data of the table: "course_discount_types" */
  readonly update_course_discount_types?: Maybe<Course_Discount_Types_Mutation_Response>;
  /** update single row of the table: "course_discount_types" */
  readonly update_course_discount_types_by_pk?: Maybe<Course_Discount_Types>;
  /** update data of the table: "course_discounts" */
  readonly update_course_discounts?: Maybe<Course_Discounts_Mutation_Response>;
  /** update single row of the table: "course_discounts" */
  readonly update_course_discounts_by_pk?: Maybe<Course_Discounts>;
  /** update data of the table: "course_forms" */
  readonly update_course_forms?: Maybe<Course_Forms_Mutation_Response>;
  /** update single row of the table: "course_forms" */
  readonly update_course_forms_by_pk?: Maybe<Course_Forms>;
  /** update data of the table: "course_gains" */
  readonly update_course_gains?: Maybe<Course_Gains_Mutation_Response>;
  /** update single row of the table: "course_gains" */
  readonly update_course_gains_by_pk?: Maybe<Course_Gains>;
  /** update data of the table: "course_instructors" */
  readonly update_course_instructors?: Maybe<Course_Instructors_Mutation_Response>;
  /** update single row of the table: "course_instructors" */
  readonly update_course_instructors_by_pk?: Maybe<Course_Instructors>;
  /** update data of the table: "course_invites" */
  readonly update_course_invites?: Maybe<Course_Invites_Mutation_Response>;
  /** update single row of the table: "course_invites" */
  readonly update_course_invites_by_pk?: Maybe<Course_Invites>;
  /** update data of the table: "course_lecturers" */
  readonly update_course_lecturers?: Maybe<Course_Lecturers_Mutation_Response>;
  /** update data of the table: "course_live_sessions" */
  readonly update_course_live_sessions?: Maybe<Course_Live_Sessions_Mutation_Response>;
  /** update single row of the table: "course_live_sessions" */
  readonly update_course_live_sessions_by_pk?: Maybe<Course_Live_Sessions>;
  /** update data of the table: "course_module_students" */
  readonly update_course_module_students?: Maybe<Course_Module_Students_Mutation_Response>;
  /** update single row of the table: "course_module_students" */
  readonly update_course_module_students_by_pk?: Maybe<Course_Module_Students>;
  /** update data of the table: "course_modules" */
  readonly update_course_modules?: Maybe<Course_Modules_Mutation_Response>;
  /** update single row of the table: "course_modules" */
  readonly update_course_modules_by_pk?: Maybe<Course_Modules>;
  /** update data of the table: "course_objectives" */
  readonly update_course_objectives?: Maybe<Course_Objectives_Mutation_Response>;
  /** update single row of the table: "course_objectives" */
  readonly update_course_objectives_by_pk?: Maybe<Course_Objectives>;
  /** update data of the table: "course_order" */
  readonly update_course_order?: Maybe<Course_Order_Mutation_Response>;
  /** update single row of the table: "course_order" */
  readonly update_course_order_by_pk?: Maybe<Course_Order>;
  /** update data of the table: "course_order_modules" */
  readonly update_course_order_modules?: Maybe<Course_Order_Modules_Mutation_Response>;
  /** update single row of the table: "course_order_modules" */
  readonly update_course_order_modules_by_pk?: Maybe<Course_Order_Modules>;
  /** update data of the table: "course_order_statuses" */
  readonly update_course_order_statuses?: Maybe<Course_Order_Statuses_Mutation_Response>;
  /** update single row of the table: "course_order_statuses" */
  readonly update_course_order_statuses_by_pk?: Maybe<Course_Order_Statuses>;
  /** update data of the table: "course_payment_methods" */
  readonly update_course_payment_methods?: Maybe<Course_Payment_Methods_Mutation_Response>;
  /** update single row of the table: "course_payment_methods" */
  readonly update_course_payment_methods_by_pk?: Maybe<Course_Payment_Methods>;
  /** update data of the table: "course_reminders" */
  readonly update_course_reminders?: Maybe<Course_Reminders_Mutation_Response>;
  /** update single row of the table: "course_reminders" */
  readonly update_course_reminders_by_pk?: Maybe<Course_Reminders>;
  /** update data of the table: "course_requirements" */
  readonly update_course_requirements?: Maybe<Course_Requirements_Mutation_Response>;
  /** update single row of the table: "course_requirements" */
  readonly update_course_requirements_by_pk?: Maybe<Course_Requirements>;
  /** update data of the table: "course_rewards" */
  readonly update_course_rewards?: Maybe<Course_Rewards_Mutation_Response>;
  /** update single row of the table: "course_rewards" */
  readonly update_course_rewards_by_pk?: Maybe<Course_Rewards>;
  /** update data of the table: "course_sell_types" */
  readonly update_course_sell_types?: Maybe<Course_Sell_Types_Mutation_Response>;
  /** update single row of the table: "course_sell_types" */
  readonly update_course_sell_types_by_pk?: Maybe<Course_Sell_Types>;
  /** update data of the table: "course_signatories" */
  readonly update_course_signatories?: Maybe<Course_Signatories_Mutation_Response>;
  /** update single row of the table: "course_signatories" */
  readonly update_course_signatories_by_pk?: Maybe<Course_Signatories>;
  /** update data of the table: "course_students" */
  readonly update_course_students?: Maybe<Course_Students_Mutation_Response>;
  /** update single row of the table: "course_students" */
  readonly update_course_students_by_pk?: Maybe<Course_Students>;
  /** update data of the table: "course_videos" */
  readonly update_course_videos?: Maybe<Course_Videos_Mutation_Response>;
  /** update single row of the table: "course_videos" */
  readonly update_course_videos_by_pk?: Maybe<Course_Videos>;
  /** update data of the table: "course_videos_statuses" */
  readonly update_course_videos_statuses?: Maybe<Course_Videos_Statuses_Mutation_Response>;
  /** update single row of the table: "course_videos_statuses" */
  readonly update_course_videos_statuses_by_pk?: Maybe<Course_Videos_Statuses>;
  /** update data of the table: "courses" */
  readonly update_courses?: Maybe<Courses_Mutation_Response>;
  /** update single row of the table: "courses" */
  readonly update_courses_by_pk?: Maybe<Courses>;
  /** update data of the table: "cpd_unit_rewards" */
  readonly update_cpd_unit_rewards?: Maybe<Cpd_Unit_Rewards_Mutation_Response>;
  /** update single row of the table: "cpd_unit_rewards" */
  readonly update_cpd_unit_rewards_by_pk?: Maybe<Cpd_Unit_Rewards>;
  /** update data of the table: "cpd_units_reward" */
  readonly update_cpd_units_reward?: Maybe<Cpd_Units_Reward_Mutation_Response>;
  /** update single row of the table: "cpd_units_reward" */
  readonly update_cpd_units_reward_by_pk?: Maybe<Cpd_Units_Reward>;
  /** update data of the table: "customer" */
  readonly update_customer?: Maybe<Customer_Mutation_Response>;
  /** update single row of the table: "customer" */
  readonly update_customer_by_pk?: Maybe<Customer>;
  /** update data of the table: "discount" */
  readonly update_discount?: Maybe<Discount_Mutation_Response>;
  /** update single row of the table: "discount" */
  readonly update_discount_by_pk?: Maybe<Discount>;
  /** update data of the table: "email_notification_types" */
  readonly update_email_notification_types?: Maybe<Email_Notification_Types_Mutation_Response>;
  /** update single row of the table: "email_notification_types" */
  readonly update_email_notification_types_by_pk?: Maybe<Email_Notification_Types>;
  /** update data of the table: "email_notifications" */
  readonly update_email_notifications?: Maybe<Email_Notifications_Mutation_Response>;
  /** update single row of the table: "email_notifications" */
  readonly update_email_notifications_by_pk?: Maybe<Email_Notifications>;
  /** update data of the table: "event" */
  readonly update_event?: Maybe<Event_Mutation_Response>;
  /** update single row of the table: "event" */
  readonly update_event_by_pk?: Maybe<Event>;
  /** update data of the table: "event_industries_industry" */
  readonly update_event_industries_industry?: Maybe<Event_Industries_Industry_Mutation_Response>;
  /** update single row of the table: "event_industries_industry" */
  readonly update_event_industries_industry_by_pk?: Maybe<Event_Industries_Industry>;
  /** update data of the table: "event_managing_users_user" */
  readonly update_event_managing_users_user?: Maybe<Event_Managing_Users_User_Mutation_Response>;
  /** update single row of the table: "event_managing_users_user" */
  readonly update_event_managing_users_user_by_pk?: Maybe<Event_Managing_Users_User>;
  /** update data of the table: "event_participant" */
  readonly update_event_participant?: Maybe<Event_Participant_Mutation_Response>;
  /** update single row of the table: "event_participant" */
  readonly update_event_participant_by_pk?: Maybe<Event_Participant>;
  /** update data of the table: "faq_item" */
  readonly update_faq_item?: Maybe<Faq_Item_Mutation_Response>;
  /** update single row of the table: "faq_item" */
  readonly update_faq_item_by_pk?: Maybe<Faq_Item>;
  /** update data of the table: "form" */
  readonly update_form?: Maybe<Form_Mutation_Response>;
  /** update single row of the table: "form" */
  readonly update_form_by_pk?: Maybe<Form>;
  /** update data of the table: "form_event_names" */
  readonly update_form_event_names?: Maybe<Form_Event_Names_Mutation_Response>;
  /** update single row of the table: "form_event_names" */
  readonly update_form_event_names_by_pk?: Maybe<Form_Event_Names>;
  /** update data of the table: "form_events" */
  readonly update_form_events?: Maybe<Form_Events_Mutation_Response>;
  /** update single row of the table: "form_events" */
  readonly update_form_events_by_pk?: Maybe<Form_Events>;
  /** update data of the table: "form_instance" */
  readonly update_form_instance?: Maybe<Form_Instance_Mutation_Response>;
  /** update single row of the table: "form_instance" */
  readonly update_form_instance_by_pk?: Maybe<Form_Instance>;
  /** update data of the table: "form_question_answers" */
  readonly update_form_question_answers?: Maybe<Form_Question_Answers_Mutation_Response>;
  /** update single row of the table: "form_question_answers" */
  readonly update_form_question_answers_by_pk?: Maybe<Form_Question_Answers>;
  /** update data of the table: "form_question_submission_states" */
  readonly update_form_question_submission_states?: Maybe<Form_Question_Submission_States_Mutation_Response>;
  /** update single row of the table: "form_question_submission_states" */
  readonly update_form_question_submission_states_by_pk?: Maybe<Form_Question_Submission_States>;
  /** update data of the table: "form_question_submissions" */
  readonly update_form_question_submissions?: Maybe<Form_Question_Submissions_Mutation_Response>;
  /** update single row of the table: "form_question_submissions" */
  readonly update_form_question_submissions_by_pk?: Maybe<Form_Question_Submissions>;
  /** update data of the table: "form_question_types" */
  readonly update_form_question_types?: Maybe<Form_Question_Types_Mutation_Response>;
  /** update single row of the table: "form_question_types" */
  readonly update_form_question_types_by_pk?: Maybe<Form_Question_Types>;
  /** update data of the table: "form_questions" */
  readonly update_form_questions?: Maybe<Form_Questions_Mutation_Response>;
  /** update single row of the table: "form_questions" */
  readonly update_form_questions_by_pk?: Maybe<Form_Questions>;
  /** update data of the table: "form_score" */
  readonly update_form_score?: Maybe<Form_Score_Mutation_Response>;
  /** update single row of the table: "form_score" */
  readonly update_form_score_by_pk?: Maybe<Form_Score>;
  /** update data of the table: "form_scoring_policies" */
  readonly update_form_scoring_policies?: Maybe<Form_Scoring_Policies_Mutation_Response>;
  /** update single row of the table: "form_scoring_policies" */
  readonly update_form_scoring_policies_by_pk?: Maybe<Form_Scoring_Policies>;
  /** update data of the table: "form_submission_statuses" */
  readonly update_form_submission_statuses?: Maybe<Form_Submission_Statuses_Mutation_Response>;
  /** update single row of the table: "form_submission_statuses" */
  readonly update_form_submission_statuses_by_pk?: Maybe<Form_Submission_Statuses>;
  /** update data of the table: "form_submissions" */
  readonly update_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** update single row of the table: "form_submissions" */
  readonly update_form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** update data of the table: "form_types" */
  readonly update_form_types?: Maybe<Form_Types_Mutation_Response>;
  /** update single row of the table: "form_types" */
  readonly update_form_types_by_pk?: Maybe<Form_Types>;
  /** update data of the table: "forms" */
  readonly update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  readonly update_forms_by_pk?: Maybe<Forms>;
  /** update data of the table: "images" */
  readonly update_images?: Maybe<Images_Mutation_Response>;
  /** update single row of the table: "images" */
  readonly update_images_by_pk?: Maybe<Images>;
  /** update data of the table: "industry" */
  readonly update_industry?: Maybe<Industry_Mutation_Response>;
  /** update single row of the table: "industry" */
  readonly update_industry_by_pk?: Maybe<Industry>;
  /** update data of the table: "industry_category" */
  readonly update_industry_category?: Maybe<Industry_Category_Mutation_Response>;
  /** update single row of the table: "industry_category" */
  readonly update_industry_category_by_pk?: Maybe<Industry_Category>;
  /** update data of the table: "industry_type" */
  readonly update_industry_type?: Maybe<Industry_Type_Mutation_Response>;
  /** update single row of the table: "industry_type" */
  readonly update_industry_type_by_pk?: Maybe<Industry_Type>;
  /** update data of the table: "migrations" */
  readonly update_migrations?: Maybe<Migrations_Mutation_Response>;
  /** update single row of the table: "migrations" */
  readonly update_migrations_by_pk?: Maybe<Migrations>;
  /** update data of the table: "mock_order" */
  readonly update_mock_order?: Maybe<Mock_Order_Mutation_Response>;
  /** update data of the table: "mock_order2" */
  readonly update_mock_order2?: Maybe<Mock_Order2_Mutation_Response>;
  /** update single row of the table: "mock_order2" */
  readonly update_mock_order2_by_pk?: Maybe<Mock_Order2>;
  /** update single row of the table: "mock_order" */
  readonly update_mock_order_by_pk?: Maybe<Mock_Order>;
  /** update data of the table: "module_item_comments" */
  readonly update_module_item_comments?: Maybe<Module_Item_Comments_Mutation_Response>;
  /** update single row of the table: "module_item_comments" */
  readonly update_module_item_comments_by_pk?: Maybe<Module_Item_Comments>;
  /** update data of the table: "module_items" */
  readonly update_module_items?: Maybe<Module_Items_Mutation_Response>;
  /** update single row of the table: "module_items" */
  readonly update_module_items_by_pk?: Maybe<Module_Items>;
  /** update data of the table: "order" */
  readonly update_order?: Maybe<Order_Mutation_Response>;
  /** update single row of the table: "order" */
  readonly update_order_by_pk?: Maybe<Order>;
  /** update data of the table: "other_reward" */
  readonly update_other_reward?: Maybe<Other_Reward_Mutation_Response>;
  /** update single row of the table: "other_reward" */
  readonly update_other_reward_by_pk?: Maybe<Other_Reward>;
  /** update data of the table: "other_reward_type" */
  readonly update_other_reward_type?: Maybe<Other_Reward_Type_Mutation_Response>;
  /** update single row of the table: "other_reward_type" */
  readonly update_other_reward_type_by_pk?: Maybe<Other_Reward_Type>;
  /** update data of the table: "other_unit_rewards" */
  readonly update_other_unit_rewards?: Maybe<Other_Unit_Rewards_Mutation_Response>;
  /** update single row of the table: "other_unit_rewards" */
  readonly update_other_unit_rewards_by_pk?: Maybe<Other_Unit_Rewards>;
  /** update data of the table: "product" */
  readonly update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  readonly update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "provider" */
  readonly update_provider?: Maybe<Provider_Mutation_Response>;
  /** update data of the table: "provider_application" */
  readonly update_provider_application?: Maybe<Provider_Application_Mutation_Response>;
  /** update single row of the table: "provider_application" */
  readonly update_provider_application_by_pk?: Maybe<Provider_Application>;
  /** update data of the table: "provider_application_industries_industry" */
  readonly update_provider_application_industries_industry?: Maybe<Provider_Application_Industries_Industry_Mutation_Response>;
  /** update single row of the table: "provider_application_industries_industry" */
  readonly update_provider_application_industries_industry_by_pk?: Maybe<Provider_Application_Industries_Industry>;
  /** update single row of the table: "provider" */
  readonly update_provider_by_pk?: Maybe<Provider>;
  /** update data of the table: "provider_dragonpay_credentials" */
  readonly update_provider_dragonpay_credentials?: Maybe<Provider_Dragonpay_Credentials_Mutation_Response>;
  /** update single row of the table: "provider_dragonpay_credentials" */
  readonly update_provider_dragonpay_credentials_by_pk?: Maybe<Provider_Dragonpay_Credentials>;
  /** update data of the table: "provider_frequently_used_industries_industry" */
  readonly update_provider_frequently_used_industries_industry?: Maybe<Provider_Frequently_Used_Industries_Industry_Mutation_Response>;
  /** update single row of the table: "provider_frequently_used_industries_industry" */
  readonly update_provider_frequently_used_industries_industry_by_pk?: Maybe<Provider_Frequently_Used_Industries_Industry>;
  /** update data of the table: "provider_google" */
  readonly update_provider_google?: Maybe<Provider_Google_Mutation_Response>;
  /** update single row of the table: "provider_google" */
  readonly update_provider_google_by_pk?: Maybe<Provider_Google>;
  /** update data of the table: "provider_managing_users_user" */
  readonly update_provider_managing_users_user?: Maybe<Provider_Managing_Users_User_Mutation_Response>;
  /** update single row of the table: "provider_managing_users_user" */
  readonly update_provider_managing_users_user_by_pk?: Maybe<Provider_Managing_Users_User>;
  /** update data of the table: "provider_subscription_plan" */
  readonly update_provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Mutation_Response>;
  /** update single row of the table: "provider_subscription_plan" */
  readonly update_provider_subscription_plan_by_pk?: Maybe<Provider_Subscription_Plan>;
  /** update data of the table: "provider_subscription_plans" */
  readonly update_provider_subscription_plans?: Maybe<Provider_Subscription_Plans_Mutation_Response>;
  /** update single row of the table: "provider_subscription_plans" */
  readonly update_provider_subscription_plans_by_pk?: Maybe<Provider_Subscription_Plans>;
  /** update data of the table: "provider_subscriptions" */
  readonly update_provider_subscriptions?: Maybe<Provider_Subscriptions_Mutation_Response>;
  /** update single row of the table: "provider_subscriptions" */
  readonly update_provider_subscriptions_by_pk?: Maybe<Provider_Subscriptions>;
  /** update data of the table: "provider_zoom" */
  readonly update_provider_zoom?: Maybe<Provider_Zoom_Mutation_Response>;
  /** update single row of the table: "provider_zoom" */
  readonly update_provider_zoom_by_pk?: Maybe<Provider_Zoom>;
  /** update data of the table: "ranking_reward" */
  readonly update_ranking_reward?: Maybe<Ranking_Reward_Mutation_Response>;
  /** update single row of the table: "ranking_reward" */
  readonly update_ranking_reward_by_pk?: Maybe<Ranking_Reward>;
  /** update data of the table: "ranking_rewards" */
  readonly update_ranking_rewards?: Maybe<Ranking_Rewards_Mutation_Response>;
  /** update single row of the table: "ranking_rewards" */
  readonly update_ranking_rewards_by_pk?: Maybe<Ranking_Rewards>;
  /** update data of the table: "reward_certificate" */
  readonly update_reward_certificate?: Maybe<Reward_Certificate_Mutation_Response>;
  /** update single row of the table: "reward_certificate" */
  readonly update_reward_certificate_by_pk?: Maybe<Reward_Certificate>;
  /** update data of the table: "reward_certificate_signatories_certificate_signatory" */
  readonly update_reward_certificate_signatories_certificate_signatory?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Mutation_Response>;
  /** update single row of the table: "reward_certificate_signatories_certificate_signatory" */
  readonly update_reward_certificate_signatories_certificate_signatory_by_pk?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** update data of the table: "schema_migrations" */
  readonly update_schema_migrations?: Maybe<Schema_Migrations_Mutation_Response>;
  /** update single row of the table: "schema_migrations" */
  readonly update_schema_migrations_by_pk?: Maybe<Schema_Migrations>;
  /** update data of the table: "speakers" */
  readonly update_speakers?: Maybe<Speakers_Mutation_Response>;
  /** update single row of the table: "speakers" */
  readonly update_speakers_by_pk?: Maybe<Speakers>;
  /** update data of the table: "stream" */
  readonly update_stream?: Maybe<Stream_Mutation_Response>;
  /** update data of the table: "stream_attachment" */
  readonly update_stream_attachment?: Maybe<Stream_Attachment_Mutation_Response>;
  /** update single row of the table: "stream_attachment" */
  readonly update_stream_attachment_by_pk?: Maybe<Stream_Attachment>;
  /** update single row of the table: "stream" */
  readonly update_stream_by_pk?: Maybe<Stream>;
  /** update data of the table: "stream_forms_form" */
  readonly update_stream_forms_form?: Maybe<Stream_Forms_Form_Mutation_Response>;
  /** update single row of the table: "stream_forms_form" */
  readonly update_stream_forms_form_by_pk?: Maybe<Stream_Forms_Form>;
  /** update data of the table: "student_progress" */
  readonly update_student_progress?: Maybe<Student_Progress_Mutation_Response>;
  /** update single row of the table: "student_progress" */
  readonly update_student_progress_by_pk?: Maybe<Student_Progress>;
  /** update data of the table: "user" */
  readonly update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  readonly update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_certificates" */
  readonly update_user_certificates?: Maybe<User_Certificates_Mutation_Response>;
  /** update single row of the table: "user_certificates" */
  readonly update_user_certificates_by_pk?: Maybe<User_Certificates>;
  /** update data of the table: "user_followed_industries_industry" */
  readonly update_user_followed_industries_industry?: Maybe<User_Followed_Industries_Industry_Mutation_Response>;
  /** update single row of the table: "user_followed_industries_industry" */
  readonly update_user_followed_industries_industry_by_pk?: Maybe<User_Followed_Industries_Industry>;
  /** update data of the table: "user_provider_subscriptions_provider" */
  readonly update_user_provider_subscriptions_provider?: Maybe<User_Provider_Subscriptions_Provider_Mutation_Response>;
  /** update single row of the table: "user_provider_subscriptions_provider" */
  readonly update_user_provider_subscriptions_provider_by_pk?: Maybe<User_Provider_Subscriptions_Provider>;
  /** update data of the table: "user_purchased_events_event" */
  readonly update_user_purchased_events_event?: Maybe<User_Purchased_Events_Event_Mutation_Response>;
  /** update single row of the table: "user_purchased_events_event" */
  readonly update_user_purchased_events_event_by_pk?: Maybe<User_Purchased_Events_Event>;
  /** update data of the table: "users" */
  readonly update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  readonly update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootCreateCoursePaymentRequestArgs = {
  courseInviteId?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  paymentChannel?: Maybe<Scalars['String']>;
  proofPaymentUuid?: Maybe<Scalars['String']>;
  selectedModules: ReadonlyArray<Maybe<Scalars['Int']>>;
};


/** mutation root */
export type Mutation_RootDelete_Account_GoogleArgs = {
  where: Account_Google_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Subscription_PlansArgs = {
  where: Account_Subscription_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_SubscriptionsArgs = {
  where: Account_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_ZoomArgs = {
  where: Account_Zoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ar_Internal_MetadataArgs = {
  where: Ar_Internal_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ar_Internal_Metadata_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AttachmentArgs = {
  where: Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Attendance_LogsArgs = {
  where: Attendance_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attendance_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AttendancesArgs = {
  where: Attendances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attendances_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Breakout_GroupArgs = {
  where: Breakout_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Breakout_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Breakout_Group_Participants_Event_ParticipantArgs = {
  where: Breakout_Group_Participants_Event_Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Breakout_Group_Participants_Event_Participant_By_PkArgs = {
  breakoutGroupId: Scalars['uuid'];
  eventParticipantId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Breakout_RoomArgs = {
  where: Breakout_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Breakout_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bunny_UploadArgs = {
  where: Bunny_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bunny_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bunny_Upload_StatesArgs = {
  where: Bunny_Upload_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bunny_Upload_States_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Carousel_ItemArgs = {
  where: Carousel_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Carousel_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Certificate_SignatoryArgs = {
  where: Certificate_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Signatory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Template_SignatoriesArgs = {
  where: Certificate_Template_Signatories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Template_Signatories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Certificate_TemplatesArgs = {
  where: Certificate_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_ArticlesArgs = {
  where: Course_Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Articles_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_AssessmentsArgs = {
  where: Course_Assessments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Assessments_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_AttachmentsArgs = {
  where: Course_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_CertificatesArgs = {
  where: Course_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Certificates_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Discount_TypesArgs = {
  where: Course_Discount_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Discount_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Course_DiscountsArgs = {
  where: Course_Discounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Discounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_FormsArgs = {
  where: Course_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Forms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_GainsArgs = {
  where: Course_Gains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Gains_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_InstructorsArgs = {
  where: Course_Instructors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Instructors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_InvitesArgs = {
  where: Course_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_LecturersArgs = {
  where: Course_Lecturers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Live_SessionsArgs = {
  where: Course_Live_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Live_Sessions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Module_StudentsArgs = {
  where: Course_Module_Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Module_Students_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_ModulesArgs = {
  where: Course_Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Modules_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_ObjectivesArgs = {
  where: Course_Objectives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Objectives_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_OrderArgs = {
  where: Course_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Order_ModulesArgs = {
  where: Course_Order_Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Order_Modules_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Order_StatusesArgs = {
  where: Course_Order_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Order_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Payment_MethodsArgs = {
  where: Course_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Payment_Methods_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Course_RemindersArgs = {
  where: Course_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Reminders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Course_RequirementsArgs = {
  where: Course_Requirements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Requirements_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_RewardsArgs = {
  where: Course_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Sell_TypesArgs = {
  where: Course_Sell_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Sell_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Course_SignatoriesArgs = {
  where: Course_Signatories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Signatories_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_StudentsArgs = {
  where: Course_Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Students_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_VideosArgs = {
  where: Course_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Videos_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Course_Videos_StatusesArgs = {
  where: Course_Videos_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Videos_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CoursesArgs = {
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Courses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Cpd_Unit_RewardsArgs = {
  where: Cpd_Unit_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cpd_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Cpd_Units_RewardArgs = {
  where: Cpd_Units_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cpd_Units_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomerArgs = {
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DiscountArgs = {
  where: Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discount_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Notification_TypesArgs = {
  where: Email_Notification_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Notification_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_NotificationsArgs = {
  where: Email_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventArgs = {
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Industries_IndustryArgs = {
  where: Event_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Industries_Industry_By_PkArgs = {
  eventId: Scalars['uuid'];
  industryName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Managing_Users_UserArgs = {
  where: Event_Managing_Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Managing_Users_User_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_ParticipantArgs = {
  where: Event_Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Faq_ItemArgs = {
  where: Faq_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FormArgs = {
  where: Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Event_NamesArgs = {
  where: Form_Event_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Event_Names_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_EventsArgs = {
  where: Form_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_InstanceArgs = {
  where: Form_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Instance_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_AnswersArgs = {
  where: Form_Question_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_Submission_StatesArgs = {
  where: Form_Question_Submission_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_Submission_States_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_SubmissionsArgs = {
  where: Form_Question_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_TypesArgs = {
  where: Form_Question_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Question_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_QuestionsArgs = {
  where: Form_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_ScoreArgs = {
  where: Form_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Score_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Scoring_PoliciesArgs = {
  where: Form_Scoring_Policies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Scoring_Policies_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Submission_StatusesArgs = {
  where: Form_Submission_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Submission_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_SubmissionsArgs = {
  where: Form_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_TypesArgs = {
  where: Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImagesArgs = {
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IndustryArgs = {
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industry_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Industry_CategoryArgs = {
  where: Industry_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industry_Category_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Industry_TypeArgs = {
  where: Industry_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industry_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MigrationsArgs = {
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mock_OrderArgs = {
  where: Mock_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mock_Order2Args = {
  where: Mock_Order2_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mock_Order2_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mock_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Module_Item_CommentsArgs = {
  where: Module_Item_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_Item_Comments_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Module_ItemsArgs = {
  where: Module_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_Items_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Other_RewardArgs = {
  where: Other_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Other_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Other_Reward_TypeArgs = {
  where: Other_Reward_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Other_Reward_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Other_Unit_RewardsArgs = {
  where: Other_Unit_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Other_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProviderArgs = {
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_ApplicationArgs = {
  where: Provider_Application_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Application_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Application_Industries_IndustryArgs = {
  where: Provider_Application_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Application_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerApplicationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Dragonpay_CredentialsArgs = {
  where: Provider_Dragonpay_Credentials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Dragonpay_Credentials_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Frequently_Used_Industries_IndustryArgs = {
  where: Provider_Frequently_Used_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Frequently_Used_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_GoogleArgs = {
  where: Provider_Google_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Managing_Users_UserArgs = {
  where: Provider_Managing_Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Managing_Users_User_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Subscription_PlanArgs = {
  where: Provider_Subscription_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Subscription_Plan_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_Subscription_PlansArgs = {
  where: Provider_Subscription_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_SubscriptionsArgs = {
  where: Provider_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_ZoomArgs = {
  where: Provider_Zoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ranking_RewardArgs = {
  where: Ranking_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ranking_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ranking_RewardsArgs = {
  where: Ranking_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ranking_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Reward_CertificateArgs = {
  where: Reward_Certificate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reward_Certificate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reward_Certificate_Signatories_Certificate_SignatoryArgs = {
  where: Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reward_Certificate_Signatories_Certificate_Signatory_By_PkArgs = {
  certificateSignatoryId: Scalars['uuid'];
  rewardCertificateId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Schema_MigrationsArgs = {
  where: Schema_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Schema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SpeakersArgs = {
  where: Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Speakers_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_StreamArgs = {
  where: Stream_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stream_AttachmentArgs = {
  where: Stream_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stream_Attachment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stream_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stream_Forms_FormArgs = {
  where: Stream_Forms_Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stream_Forms_Form_By_PkArgs = {
  formId: Scalars['uuid'];
  streamId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_ProgressArgs = {
  where: Student_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Progress_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_CertificatesArgs = {
  where: User_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Certificates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Followed_Industries_IndustryArgs = {
  where: User_Followed_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Followed_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Provider_Subscriptions_ProviderArgs = {
  where: User_Provider_Subscriptions_Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Provider_Subscriptions_Provider_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Purchased_Events_EventArgs = {
  where: User_Purchased_Events_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Purchased_Events_Event_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Account_GoogleArgs = {
  objects: ReadonlyArray<Account_Google_Insert_Input>;
  on_conflict?: Maybe<Account_Google_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Google_OneArgs = {
  object: Account_Google_Insert_Input;
  on_conflict?: Maybe<Account_Google_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Subscription_PlansArgs = {
  objects: ReadonlyArray<Account_Subscription_Plans_Insert_Input>;
  on_conflict?: Maybe<Account_Subscription_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Subscription_Plans_OneArgs = {
  object: Account_Subscription_Plans_Insert_Input;
  on_conflict?: Maybe<Account_Subscription_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_SubscriptionsArgs = {
  objects: ReadonlyArray<Account_Subscriptions_Insert_Input>;
  on_conflict?: Maybe<Account_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Subscriptions_OneArgs = {
  object: Account_Subscriptions_Insert_Input;
  on_conflict?: Maybe<Account_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_ZoomArgs = {
  objects: ReadonlyArray<Account_Zoom_Insert_Input>;
  on_conflict?: Maybe<Account_Zoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Zoom_OneArgs = {
  object: Account_Zoom_Insert_Input;
  on_conflict?: Maybe<Account_Zoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: ReadonlyArray<Accounts_Insert_Input>;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ar_Internal_MetadataArgs = {
  objects: ReadonlyArray<Ar_Internal_Metadata_Insert_Input>;
  on_conflict?: Maybe<Ar_Internal_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ar_Internal_Metadata_OneArgs = {
  object: Ar_Internal_Metadata_Insert_Input;
  on_conflict?: Maybe<Ar_Internal_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttachmentArgs = {
  objects: ReadonlyArray<Attachment_Insert_Input>;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_OneArgs = {
  object: Attachment_Insert_Input;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attendance_LogsArgs = {
  objects: ReadonlyArray<Attendance_Logs_Insert_Input>;
  on_conflict?: Maybe<Attendance_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attendance_Logs_OneArgs = {
  object: Attendance_Logs_Insert_Input;
  on_conflict?: Maybe<Attendance_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttendancesArgs = {
  objects: ReadonlyArray<Attendances_Insert_Input>;
  on_conflict?: Maybe<Attendances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attendances_OneArgs = {
  object: Attendances_Insert_Input;
  on_conflict?: Maybe<Attendances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_GroupArgs = {
  objects: ReadonlyArray<Breakout_Group_Insert_Input>;
  on_conflict?: Maybe<Breakout_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_Group_OneArgs = {
  object: Breakout_Group_Insert_Input;
  on_conflict?: Maybe<Breakout_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_Group_Participants_Event_ParticipantArgs = {
  objects: ReadonlyArray<Breakout_Group_Participants_Event_Participant_Insert_Input>;
  on_conflict?: Maybe<Breakout_Group_Participants_Event_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_Group_Participants_Event_Participant_OneArgs = {
  object: Breakout_Group_Participants_Event_Participant_Insert_Input;
  on_conflict?: Maybe<Breakout_Group_Participants_Event_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_RoomArgs = {
  objects: ReadonlyArray<Breakout_Room_Insert_Input>;
  on_conflict?: Maybe<Breakout_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakout_Room_OneArgs = {
  object: Breakout_Room_Insert_Input;
  on_conflict?: Maybe<Breakout_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bunny_UploadArgs = {
  objects: ReadonlyArray<Bunny_Upload_Insert_Input>;
  on_conflict?: Maybe<Bunny_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bunny_Upload_OneArgs = {
  object: Bunny_Upload_Insert_Input;
  on_conflict?: Maybe<Bunny_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bunny_Upload_StatesArgs = {
  objects: ReadonlyArray<Bunny_Upload_States_Insert_Input>;
  on_conflict?: Maybe<Bunny_Upload_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bunny_Upload_States_OneArgs = {
  object: Bunny_Upload_States_Insert_Input;
  on_conflict?: Maybe<Bunny_Upload_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carousel_ItemArgs = {
  objects: ReadonlyArray<Carousel_Item_Insert_Input>;
  on_conflict?: Maybe<Carousel_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carousel_Item_OneArgs = {
  object: Carousel_Item_Insert_Input;
  on_conflict?: Maybe<Carousel_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_SignatoryArgs = {
  objects: ReadonlyArray<Certificate_Signatory_Insert_Input>;
  on_conflict?: Maybe<Certificate_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Signatory_OneArgs = {
  object: Certificate_Signatory_Insert_Input;
  on_conflict?: Maybe<Certificate_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Template_SignatoriesArgs = {
  objects: ReadonlyArray<Certificate_Template_Signatories_Insert_Input>;
  on_conflict?: Maybe<Certificate_Template_Signatories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Template_Signatories_OneArgs = {
  object: Certificate_Template_Signatories_Insert_Input;
  on_conflict?: Maybe<Certificate_Template_Signatories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_TemplatesArgs = {
  objects: ReadonlyArray<Certificate_Templates_Insert_Input>;
  on_conflict?: Maybe<Certificate_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Templates_OneArgs = {
  object: Certificate_Templates_Insert_Input;
  on_conflict?: Maybe<Certificate_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_ArticlesArgs = {
  objects: ReadonlyArray<Course_Articles_Insert_Input>;
  on_conflict?: Maybe<Course_Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Articles_OneArgs = {
  object: Course_Articles_Insert_Input;
  on_conflict?: Maybe<Course_Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_AssessmentsArgs = {
  objects: ReadonlyArray<Course_Assessments_Insert_Input>;
  on_conflict?: Maybe<Course_Assessments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Assessments_OneArgs = {
  object: Course_Assessments_Insert_Input;
  on_conflict?: Maybe<Course_Assessments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_AttachmentsArgs = {
  objects: ReadonlyArray<Course_Attachments_Insert_Input>;
  on_conflict?: Maybe<Course_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Attachments_OneArgs = {
  object: Course_Attachments_Insert_Input;
  on_conflict?: Maybe<Course_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_CertificatesArgs = {
  objects: ReadonlyArray<Course_Certificates_Insert_Input>;
  on_conflict?: Maybe<Course_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Certificates_OneArgs = {
  object: Course_Certificates_Insert_Input;
  on_conflict?: Maybe<Course_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Discount_TypesArgs = {
  objects: ReadonlyArray<Course_Discount_Types_Insert_Input>;
  on_conflict?: Maybe<Course_Discount_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Discount_Types_OneArgs = {
  object: Course_Discount_Types_Insert_Input;
  on_conflict?: Maybe<Course_Discount_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_DiscountsArgs = {
  objects: ReadonlyArray<Course_Discounts_Insert_Input>;
  on_conflict?: Maybe<Course_Discounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Discounts_OneArgs = {
  object: Course_Discounts_Insert_Input;
  on_conflict?: Maybe<Course_Discounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_FormsArgs = {
  objects: ReadonlyArray<Course_Forms_Insert_Input>;
  on_conflict?: Maybe<Course_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Forms_OneArgs = {
  object: Course_Forms_Insert_Input;
  on_conflict?: Maybe<Course_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_GainsArgs = {
  objects: ReadonlyArray<Course_Gains_Insert_Input>;
  on_conflict?: Maybe<Course_Gains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Gains_OneArgs = {
  object: Course_Gains_Insert_Input;
  on_conflict?: Maybe<Course_Gains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_InstructorsArgs = {
  objects: ReadonlyArray<Course_Instructors_Insert_Input>;
  on_conflict?: Maybe<Course_Instructors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Instructors_OneArgs = {
  object: Course_Instructors_Insert_Input;
  on_conflict?: Maybe<Course_Instructors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_InvitesArgs = {
  objects: ReadonlyArray<Course_Invites_Insert_Input>;
  on_conflict?: Maybe<Course_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Invites_OneArgs = {
  object: Course_Invites_Insert_Input;
  on_conflict?: Maybe<Course_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_LecturersArgs = {
  objects: ReadonlyArray<Course_Lecturers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Lecturers_OneArgs = {
  object: Course_Lecturers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Course_Live_SessionsArgs = {
  objects: ReadonlyArray<Course_Live_Sessions_Insert_Input>;
  on_conflict?: Maybe<Course_Live_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Live_Sessions_OneArgs = {
  object: Course_Live_Sessions_Insert_Input;
  on_conflict?: Maybe<Course_Live_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Module_StudentsArgs = {
  objects: ReadonlyArray<Course_Module_Students_Insert_Input>;
  on_conflict?: Maybe<Course_Module_Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Module_Students_OneArgs = {
  object: Course_Module_Students_Insert_Input;
  on_conflict?: Maybe<Course_Module_Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_ModulesArgs = {
  objects: ReadonlyArray<Course_Modules_Insert_Input>;
  on_conflict?: Maybe<Course_Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Modules_OneArgs = {
  object: Course_Modules_Insert_Input;
  on_conflict?: Maybe<Course_Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_ObjectivesArgs = {
  objects: ReadonlyArray<Course_Objectives_Insert_Input>;
  on_conflict?: Maybe<Course_Objectives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Objectives_OneArgs = {
  object: Course_Objectives_Insert_Input;
  on_conflict?: Maybe<Course_Objectives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_OrderArgs = {
  objects: ReadonlyArray<Course_Order_Insert_Input>;
  on_conflict?: Maybe<Course_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Order_ModulesArgs = {
  objects: ReadonlyArray<Course_Order_Modules_Insert_Input>;
  on_conflict?: Maybe<Course_Order_Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Order_Modules_OneArgs = {
  object: Course_Order_Modules_Insert_Input;
  on_conflict?: Maybe<Course_Order_Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Order_OneArgs = {
  object: Course_Order_Insert_Input;
  on_conflict?: Maybe<Course_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Order_StatusesArgs = {
  objects: ReadonlyArray<Course_Order_Statuses_Insert_Input>;
  on_conflict?: Maybe<Course_Order_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Order_Statuses_OneArgs = {
  object: Course_Order_Statuses_Insert_Input;
  on_conflict?: Maybe<Course_Order_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Payment_MethodsArgs = {
  objects: ReadonlyArray<Course_Payment_Methods_Insert_Input>;
  on_conflict?: Maybe<Course_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Payment_Methods_OneArgs = {
  object: Course_Payment_Methods_Insert_Input;
  on_conflict?: Maybe<Course_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_RemindersArgs = {
  objects: ReadonlyArray<Course_Reminders_Insert_Input>;
  on_conflict?: Maybe<Course_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Reminders_OneArgs = {
  object: Course_Reminders_Insert_Input;
  on_conflict?: Maybe<Course_Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_RequirementsArgs = {
  objects: ReadonlyArray<Course_Requirements_Insert_Input>;
  on_conflict?: Maybe<Course_Requirements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Requirements_OneArgs = {
  object: Course_Requirements_Insert_Input;
  on_conflict?: Maybe<Course_Requirements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_RewardsArgs = {
  objects: ReadonlyArray<Course_Rewards_Insert_Input>;
  on_conflict?: Maybe<Course_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Rewards_OneArgs = {
  object: Course_Rewards_Insert_Input;
  on_conflict?: Maybe<Course_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Sell_TypesArgs = {
  objects: ReadonlyArray<Course_Sell_Types_Insert_Input>;
  on_conflict?: Maybe<Course_Sell_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Sell_Types_OneArgs = {
  object: Course_Sell_Types_Insert_Input;
  on_conflict?: Maybe<Course_Sell_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_SignatoriesArgs = {
  objects: ReadonlyArray<Course_Signatories_Insert_Input>;
  on_conflict?: Maybe<Course_Signatories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Signatories_OneArgs = {
  object: Course_Signatories_Insert_Input;
  on_conflict?: Maybe<Course_Signatories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_StudentsArgs = {
  objects: ReadonlyArray<Course_Students_Insert_Input>;
  on_conflict?: Maybe<Course_Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Students_OneArgs = {
  object: Course_Students_Insert_Input;
  on_conflict?: Maybe<Course_Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_VideosArgs = {
  objects: ReadonlyArray<Course_Videos_Insert_Input>;
  on_conflict?: Maybe<Course_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Videos_OneArgs = {
  object: Course_Videos_Insert_Input;
  on_conflict?: Maybe<Course_Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Videos_StatusesArgs = {
  objects: ReadonlyArray<Course_Videos_Statuses_Insert_Input>;
  on_conflict?: Maybe<Course_Videos_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Videos_Statuses_OneArgs = {
  object: Course_Videos_Statuses_Insert_Input;
  on_conflict?: Maybe<Course_Videos_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoursesArgs = {
  objects: ReadonlyArray<Courses_Insert_Input>;
  on_conflict?: Maybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Courses_OneArgs = {
  object: Courses_Insert_Input;
  on_conflict?: Maybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cpd_Unit_RewardsArgs = {
  objects: ReadonlyArray<Cpd_Unit_Rewards_Insert_Input>;
  on_conflict?: Maybe<Cpd_Unit_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cpd_Unit_Rewards_OneArgs = {
  object: Cpd_Unit_Rewards_Insert_Input;
  on_conflict?: Maybe<Cpd_Unit_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cpd_Units_RewardArgs = {
  objects: ReadonlyArray<Cpd_Units_Reward_Insert_Input>;
  on_conflict?: Maybe<Cpd_Units_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cpd_Units_Reward_OneArgs = {
  object: Cpd_Units_Reward_Insert_Input;
  on_conflict?: Maybe<Cpd_Units_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomerArgs = {
  objects: ReadonlyArray<Customer_Insert_Input>;
  on_conflict?: Maybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_OneArgs = {
  object: Customer_Insert_Input;
  on_conflict?: Maybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiscountArgs = {
  objects: ReadonlyArray<Discount_Insert_Input>;
  on_conflict?: Maybe<Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_OneArgs = {
  object: Discount_Insert_Input;
  on_conflict?: Maybe<Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Notification_TypesArgs = {
  objects: ReadonlyArray<Email_Notification_Types_Insert_Input>;
  on_conflict?: Maybe<Email_Notification_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Notification_Types_OneArgs = {
  object: Email_Notification_Types_Insert_Input;
  on_conflict?: Maybe<Email_Notification_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_NotificationsArgs = {
  objects: ReadonlyArray<Email_Notifications_Insert_Input>;
  on_conflict?: Maybe<Email_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Notifications_OneArgs = {
  object: Email_Notifications_Insert_Input;
  on_conflict?: Maybe<Email_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventArgs = {
  objects: ReadonlyArray<Event_Insert_Input>;
  on_conflict?: Maybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Industries_IndustryArgs = {
  objects: ReadonlyArray<Event_Industries_Industry_Insert_Input>;
  on_conflict?: Maybe<Event_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Industries_Industry_OneArgs = {
  object: Event_Industries_Industry_Insert_Input;
  on_conflict?: Maybe<Event_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Managing_Users_UserArgs = {
  objects: ReadonlyArray<Event_Managing_Users_User_Insert_Input>;
  on_conflict?: Maybe<Event_Managing_Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Managing_Users_User_OneArgs = {
  object: Event_Managing_Users_User_Insert_Input;
  on_conflict?: Maybe<Event_Managing_Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OneArgs = {
  object: Event_Insert_Input;
  on_conflict?: Maybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_ParticipantArgs = {
  objects: ReadonlyArray<Event_Participant_Insert_Input>;
  on_conflict?: Maybe<Event_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Participant_OneArgs = {
  object: Event_Participant_Insert_Input;
  on_conflict?: Maybe<Event_Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_ItemArgs = {
  objects: ReadonlyArray<Faq_Item_Insert_Input>;
  on_conflict?: Maybe<Faq_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_Item_OneArgs = {
  object: Faq_Item_Insert_Input;
  on_conflict?: Maybe<Faq_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormArgs = {
  objects: ReadonlyArray<Form_Insert_Input>;
  on_conflict?: Maybe<Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Event_NamesArgs = {
  objects: ReadonlyArray<Form_Event_Names_Insert_Input>;
  on_conflict?: Maybe<Form_Event_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Event_Names_OneArgs = {
  object: Form_Event_Names_Insert_Input;
  on_conflict?: Maybe<Form_Event_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_EventsArgs = {
  objects: ReadonlyArray<Form_Events_Insert_Input>;
  on_conflict?: Maybe<Form_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Events_OneArgs = {
  object: Form_Events_Insert_Input;
  on_conflict?: Maybe<Form_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_InstanceArgs = {
  objects: ReadonlyArray<Form_Instance_Insert_Input>;
  on_conflict?: Maybe<Form_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Instance_OneArgs = {
  object: Form_Instance_Insert_Input;
  on_conflict?: Maybe<Form_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_OneArgs = {
  object: Form_Insert_Input;
  on_conflict?: Maybe<Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_AnswersArgs = {
  objects: ReadonlyArray<Form_Question_Answers_Insert_Input>;
  on_conflict?: Maybe<Form_Question_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_Answers_OneArgs = {
  object: Form_Question_Answers_Insert_Input;
  on_conflict?: Maybe<Form_Question_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_Submission_StatesArgs = {
  objects: ReadonlyArray<Form_Question_Submission_States_Insert_Input>;
  on_conflict?: Maybe<Form_Question_Submission_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_Submission_States_OneArgs = {
  object: Form_Question_Submission_States_Insert_Input;
  on_conflict?: Maybe<Form_Question_Submission_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_SubmissionsArgs = {
  objects: ReadonlyArray<Form_Question_Submissions_Insert_Input>;
  on_conflict?: Maybe<Form_Question_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_Submissions_OneArgs = {
  object: Form_Question_Submissions_Insert_Input;
  on_conflict?: Maybe<Form_Question_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_TypesArgs = {
  objects: ReadonlyArray<Form_Question_Types_Insert_Input>;
  on_conflict?: Maybe<Form_Question_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Question_Types_OneArgs = {
  object: Form_Question_Types_Insert_Input;
  on_conflict?: Maybe<Form_Question_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_QuestionsArgs = {
  objects: ReadonlyArray<Form_Questions_Insert_Input>;
  on_conflict?: Maybe<Form_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Questions_OneArgs = {
  object: Form_Questions_Insert_Input;
  on_conflict?: Maybe<Form_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_ScoreArgs = {
  objects: ReadonlyArray<Form_Score_Insert_Input>;
  on_conflict?: Maybe<Form_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Score_OneArgs = {
  object: Form_Score_Insert_Input;
  on_conflict?: Maybe<Form_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Scoring_PoliciesArgs = {
  objects: ReadonlyArray<Form_Scoring_Policies_Insert_Input>;
  on_conflict?: Maybe<Form_Scoring_Policies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Scoring_Policies_OneArgs = {
  object: Form_Scoring_Policies_Insert_Input;
  on_conflict?: Maybe<Form_Scoring_Policies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Submission_StatusesArgs = {
  objects: ReadonlyArray<Form_Submission_Statuses_Insert_Input>;
  on_conflict?: Maybe<Form_Submission_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Submission_Statuses_OneArgs = {
  object: Form_Submission_Statuses_Insert_Input;
  on_conflict?: Maybe<Form_Submission_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_SubmissionsArgs = {
  objects: ReadonlyArray<Form_Submissions_Insert_Input>;
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Submissions_OneArgs = {
  object: Form_Submissions_Insert_Input;
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_TypesArgs = {
  objects: ReadonlyArray<Form_Types_Insert_Input>;
  on_conflict?: Maybe<Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Types_OneArgs = {
  object: Form_Types_Insert_Input;
  on_conflict?: Maybe<Form_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: ReadonlyArray<Forms_Insert_Input>;
  on_conflict?: Maybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: Maybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImagesArgs = {
  objects: ReadonlyArray<Images_Insert_Input>;
  on_conflict?: Maybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Images_OneArgs = {
  object: Images_Insert_Input;
  on_conflict?: Maybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IndustryArgs = {
  objects: ReadonlyArray<Industry_Insert_Input>;
  on_conflict?: Maybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_CategoryArgs = {
  objects: ReadonlyArray<Industry_Category_Insert_Input>;
  on_conflict?: Maybe<Industry_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_Category_OneArgs = {
  object: Industry_Category_Insert_Input;
  on_conflict?: Maybe<Industry_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_OneArgs = {
  object: Industry_Insert_Input;
  on_conflict?: Maybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_TypeArgs = {
  objects: ReadonlyArray<Industry_Type_Insert_Input>;
  on_conflict?: Maybe<Industry_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_Type_OneArgs = {
  object: Industry_Type_Insert_Input;
  on_conflict?: Maybe<Industry_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MigrationsArgs = {
  objects: ReadonlyArray<Migrations_Insert_Input>;
  on_conflict?: Maybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Migrations_OneArgs = {
  object: Migrations_Insert_Input;
  on_conflict?: Maybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mock_OrderArgs = {
  objects: ReadonlyArray<Mock_Order_Insert_Input>;
  on_conflict?: Maybe<Mock_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mock_Order2Args = {
  objects: ReadonlyArray<Mock_Order2_Insert_Input>;
  on_conflict?: Maybe<Mock_Order2_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mock_Order2_OneArgs = {
  object: Mock_Order2_Insert_Input;
  on_conflict?: Maybe<Mock_Order2_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mock_Order_OneArgs = {
  object: Mock_Order_Insert_Input;
  on_conflict?: Maybe<Mock_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Item_CommentsArgs = {
  objects: ReadonlyArray<Module_Item_Comments_Insert_Input>;
  on_conflict?: Maybe<Module_Item_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Item_Comments_OneArgs = {
  object: Module_Item_Comments_Insert_Input;
  on_conflict?: Maybe<Module_Item_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_ItemsArgs = {
  objects: ReadonlyArray<Module_Items_Insert_Input>;
  on_conflict?: Maybe<Module_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Items_OneArgs = {
  object: Module_Items_Insert_Input;
  on_conflict?: Maybe<Module_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: ReadonlyArray<Order_Insert_Input>;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_RewardArgs = {
  objects: ReadonlyArray<Other_Reward_Insert_Input>;
  on_conflict?: Maybe<Other_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_Reward_OneArgs = {
  object: Other_Reward_Insert_Input;
  on_conflict?: Maybe<Other_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_Reward_TypeArgs = {
  objects: ReadonlyArray<Other_Reward_Type_Insert_Input>;
  on_conflict?: Maybe<Other_Reward_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_Reward_Type_OneArgs = {
  object: Other_Reward_Type_Insert_Input;
  on_conflict?: Maybe<Other_Reward_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_Unit_RewardsArgs = {
  objects: ReadonlyArray<Other_Unit_Rewards_Insert_Input>;
  on_conflict?: Maybe<Other_Unit_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Other_Unit_Rewards_OneArgs = {
  object: Other_Unit_Rewards_Insert_Input;
  on_conflict?: Maybe<Other_Unit_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: ReadonlyArray<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProviderArgs = {
  objects: ReadonlyArray<Provider_Insert_Input>;
  on_conflict?: Maybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_ApplicationArgs = {
  objects: ReadonlyArray<Provider_Application_Insert_Input>;
  on_conflict?: Maybe<Provider_Application_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Application_Industries_IndustryArgs = {
  objects: ReadonlyArray<Provider_Application_Industries_Industry_Insert_Input>;
  on_conflict?: Maybe<Provider_Application_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Application_Industries_Industry_OneArgs = {
  object: Provider_Application_Industries_Industry_Insert_Input;
  on_conflict?: Maybe<Provider_Application_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Application_OneArgs = {
  object: Provider_Application_Insert_Input;
  on_conflict?: Maybe<Provider_Application_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Dragonpay_CredentialsArgs = {
  objects: ReadonlyArray<Provider_Dragonpay_Credentials_Insert_Input>;
  on_conflict?: Maybe<Provider_Dragonpay_Credentials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Dragonpay_Credentials_OneArgs = {
  object: Provider_Dragonpay_Credentials_Insert_Input;
  on_conflict?: Maybe<Provider_Dragonpay_Credentials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Frequently_Used_Industries_IndustryArgs = {
  objects: ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Insert_Input>;
  on_conflict?: Maybe<Provider_Frequently_Used_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Frequently_Used_Industries_Industry_OneArgs = {
  object: Provider_Frequently_Used_Industries_Industry_Insert_Input;
  on_conflict?: Maybe<Provider_Frequently_Used_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_GoogleArgs = {
  objects: ReadonlyArray<Provider_Google_Insert_Input>;
  on_conflict?: Maybe<Provider_Google_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Google_OneArgs = {
  object: Provider_Google_Insert_Input;
  on_conflict?: Maybe<Provider_Google_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Managing_Users_UserArgs = {
  objects: ReadonlyArray<Provider_Managing_Users_User_Insert_Input>;
  on_conflict?: Maybe<Provider_Managing_Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Managing_Users_User_OneArgs = {
  object: Provider_Managing_Users_User_Insert_Input;
  on_conflict?: Maybe<Provider_Managing_Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_OneArgs = {
  object: Provider_Insert_Input;
  on_conflict?: Maybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Subscription_PlanArgs = {
  objects: ReadonlyArray<Provider_Subscription_Plan_Insert_Input>;
  on_conflict?: Maybe<Provider_Subscription_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Subscription_Plan_OneArgs = {
  object: Provider_Subscription_Plan_Insert_Input;
  on_conflict?: Maybe<Provider_Subscription_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Subscription_PlansArgs = {
  objects: ReadonlyArray<Provider_Subscription_Plans_Insert_Input>;
  on_conflict?: Maybe<Provider_Subscription_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Subscription_Plans_OneArgs = {
  object: Provider_Subscription_Plans_Insert_Input;
  on_conflict?: Maybe<Provider_Subscription_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_SubscriptionsArgs = {
  objects: ReadonlyArray<Provider_Subscriptions_Insert_Input>;
  on_conflict?: Maybe<Provider_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Subscriptions_OneArgs = {
  object: Provider_Subscriptions_Insert_Input;
  on_conflict?: Maybe<Provider_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_ZoomArgs = {
  objects: ReadonlyArray<Provider_Zoom_Insert_Input>;
  on_conflict?: Maybe<Provider_Zoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Zoom_OneArgs = {
  object: Provider_Zoom_Insert_Input;
  on_conflict?: Maybe<Provider_Zoom_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ranking_RewardArgs = {
  objects: ReadonlyArray<Ranking_Reward_Insert_Input>;
  on_conflict?: Maybe<Ranking_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ranking_Reward_OneArgs = {
  object: Ranking_Reward_Insert_Input;
  on_conflict?: Maybe<Ranking_Reward_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ranking_RewardsArgs = {
  objects: ReadonlyArray<Ranking_Rewards_Insert_Input>;
  on_conflict?: Maybe<Ranking_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ranking_Rewards_OneArgs = {
  object: Ranking_Rewards_Insert_Input;
  on_conflict?: Maybe<Ranking_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_CertificateArgs = {
  objects: ReadonlyArray<Reward_Certificate_Insert_Input>;
  on_conflict?: Maybe<Reward_Certificate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Certificate_OneArgs = {
  object: Reward_Certificate_Insert_Input;
  on_conflict?: Maybe<Reward_Certificate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Certificate_Signatories_Certificate_SignatoryArgs = {
  objects: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Insert_Input>;
  on_conflict?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reward_Certificate_Signatories_Certificate_Signatory_OneArgs = {
  object: Reward_Certificate_Signatories_Certificate_Signatory_Insert_Input;
  on_conflict?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Schema_MigrationsArgs = {
  objects: ReadonlyArray<Schema_Migrations_Insert_Input>;
  on_conflict?: Maybe<Schema_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Schema_Migrations_OneArgs = {
  object: Schema_Migrations_Insert_Input;
  on_conflict?: Maybe<Schema_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SpeakersArgs = {
  objects: ReadonlyArray<Speakers_Insert_Input>;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Speakers_OneArgs = {
  object: Speakers_Insert_Input;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StreamArgs = {
  objects: ReadonlyArray<Stream_Insert_Input>;
  on_conflict?: Maybe<Stream_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stream_AttachmentArgs = {
  objects: ReadonlyArray<Stream_Attachment_Insert_Input>;
  on_conflict?: Maybe<Stream_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stream_Attachment_OneArgs = {
  object: Stream_Attachment_Insert_Input;
  on_conflict?: Maybe<Stream_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stream_Forms_FormArgs = {
  objects: ReadonlyArray<Stream_Forms_Form_Insert_Input>;
  on_conflict?: Maybe<Stream_Forms_Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stream_Forms_Form_OneArgs = {
  object: Stream_Forms_Form_Insert_Input;
  on_conflict?: Maybe<Stream_Forms_Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stream_OneArgs = {
  object: Stream_Insert_Input;
  on_conflict?: Maybe<Stream_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_ProgressArgs = {
  objects: ReadonlyArray<Student_Progress_Insert_Input>;
  on_conflict?: Maybe<Student_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Progress_OneArgs = {
  object: Student_Progress_Insert_Input;
  on_conflict?: Maybe<Student_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: ReadonlyArray<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_CertificatesArgs = {
  objects: ReadonlyArray<User_Certificates_Insert_Input>;
  on_conflict?: Maybe<User_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Certificates_OneArgs = {
  object: User_Certificates_Insert_Input;
  on_conflict?: Maybe<User_Certificates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Followed_Industries_IndustryArgs = {
  objects: ReadonlyArray<User_Followed_Industries_Industry_Insert_Input>;
  on_conflict?: Maybe<User_Followed_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Followed_Industries_Industry_OneArgs = {
  object: User_Followed_Industries_Industry_Insert_Input;
  on_conflict?: Maybe<User_Followed_Industries_Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Provider_Subscriptions_ProviderArgs = {
  objects: ReadonlyArray<User_Provider_Subscriptions_Provider_Insert_Input>;
  on_conflict?: Maybe<User_Provider_Subscriptions_Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Provider_Subscriptions_Provider_OneArgs = {
  object: User_Provider_Subscriptions_Provider_Insert_Input;
  on_conflict?: Maybe<User_Provider_Subscriptions_Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Purchased_Events_EventArgs = {
  objects: ReadonlyArray<User_Purchased_Events_Event_Insert_Input>;
  on_conflict?: Maybe<User_Purchased_Events_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Purchased_Events_Event_OneArgs = {
  object: User_Purchased_Events_Event_Insert_Input;
  on_conflict?: Maybe<User_Purchased_Events_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: ReadonlyArray<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_GoogleArgs = {
  _inc?: Maybe<Account_Google_Inc_Input>;
  _set?: Maybe<Account_Google_Set_Input>;
  where: Account_Google_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Google_By_PkArgs = {
  _inc?: Maybe<Account_Google_Inc_Input>;
  _set?: Maybe<Account_Google_Set_Input>;
  pk_columns: Account_Google_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Subscription_PlansArgs = {
  _set?: Maybe<Account_Subscription_Plans_Set_Input>;
  where: Account_Subscription_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Subscription_Plans_By_PkArgs = {
  _set?: Maybe<Account_Subscription_Plans_Set_Input>;
  pk_columns: Account_Subscription_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_SubscriptionsArgs = {
  _inc?: Maybe<Account_Subscriptions_Inc_Input>;
  _set?: Maybe<Account_Subscriptions_Set_Input>;
  where: Account_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Subscriptions_By_PkArgs = {
  _inc?: Maybe<Account_Subscriptions_Inc_Input>;
  _set?: Maybe<Account_Subscriptions_Set_Input>;
  pk_columns: Account_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ZoomArgs = {
  _set?: Maybe<Account_Zoom_Set_Input>;
  where: Account_Zoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Zoom_By_PkArgs = {
  _set?: Maybe<Account_Zoom_Set_Input>;
  pk_columns: Account_Zoom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _inc?: Maybe<Accounts_Inc_Input>;
  _set?: Maybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _inc?: Maybe<Accounts_Inc_Input>;
  _set?: Maybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ar_Internal_MetadataArgs = {
  _set?: Maybe<Ar_Internal_Metadata_Set_Input>;
  where: Ar_Internal_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ar_Internal_Metadata_By_PkArgs = {
  _set?: Maybe<Ar_Internal_Metadata_Set_Input>;
  pk_columns: Ar_Internal_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AttachmentArgs = {
  _inc?: Maybe<Attachment_Inc_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  where: Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_By_PkArgs = {
  _inc?: Maybe<Attachment_Inc_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  pk_columns: Attachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attendance_LogsArgs = {
  _set?: Maybe<Attendance_Logs_Set_Input>;
  where: Attendance_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attendance_Logs_By_PkArgs = {
  _set?: Maybe<Attendance_Logs_Set_Input>;
  pk_columns: Attendance_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AttendancesArgs = {
  _inc?: Maybe<Attendances_Inc_Input>;
  _set?: Maybe<Attendances_Set_Input>;
  where: Attendances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attendances_By_PkArgs = {
  _inc?: Maybe<Attendances_Inc_Input>;
  _set?: Maybe<Attendances_Set_Input>;
  pk_columns: Attendances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_GroupArgs = {
  _set?: Maybe<Breakout_Group_Set_Input>;
  where: Breakout_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_Group_By_PkArgs = {
  _set?: Maybe<Breakout_Group_Set_Input>;
  pk_columns: Breakout_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_Group_Participants_Event_ParticipantArgs = {
  _set?: Maybe<Breakout_Group_Participants_Event_Participant_Set_Input>;
  where: Breakout_Group_Participants_Event_Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_Group_Participants_Event_Participant_By_PkArgs = {
  _set?: Maybe<Breakout_Group_Participants_Event_Participant_Set_Input>;
  pk_columns: Breakout_Group_Participants_Event_Participant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_RoomArgs = {
  _inc?: Maybe<Breakout_Room_Inc_Input>;
  _set?: Maybe<Breakout_Room_Set_Input>;
  where: Breakout_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Breakout_Room_By_PkArgs = {
  _inc?: Maybe<Breakout_Room_Inc_Input>;
  _set?: Maybe<Breakout_Room_Set_Input>;
  pk_columns: Breakout_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bunny_UploadArgs = {
  _inc?: Maybe<Bunny_Upload_Inc_Input>;
  _set?: Maybe<Bunny_Upload_Set_Input>;
  where: Bunny_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bunny_Upload_By_PkArgs = {
  _inc?: Maybe<Bunny_Upload_Inc_Input>;
  _set?: Maybe<Bunny_Upload_Set_Input>;
  pk_columns: Bunny_Upload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bunny_Upload_StatesArgs = {
  _set?: Maybe<Bunny_Upload_States_Set_Input>;
  where: Bunny_Upload_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bunny_Upload_States_By_PkArgs = {
  _set?: Maybe<Bunny_Upload_States_Set_Input>;
  pk_columns: Bunny_Upload_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Carousel_ItemArgs = {
  _set?: Maybe<Carousel_Item_Set_Input>;
  where: Carousel_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Carousel_Item_By_PkArgs = {
  _set?: Maybe<Carousel_Item_Set_Input>;
  pk_columns: Carousel_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_SignatoryArgs = {
  _set?: Maybe<Certificate_Signatory_Set_Input>;
  where: Certificate_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Signatory_By_PkArgs = {
  _set?: Maybe<Certificate_Signatory_Set_Input>;
  pk_columns: Certificate_Signatory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Template_SignatoriesArgs = {
  _set?: Maybe<Certificate_Template_Signatories_Set_Input>;
  where: Certificate_Template_Signatories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Template_Signatories_By_PkArgs = {
  _set?: Maybe<Certificate_Template_Signatories_Set_Input>;
  pk_columns: Certificate_Template_Signatories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_TemplatesArgs = {
  _inc?: Maybe<Certificate_Templates_Inc_Input>;
  _set?: Maybe<Certificate_Templates_Set_Input>;
  where: Certificate_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Templates_By_PkArgs = {
  _inc?: Maybe<Certificate_Templates_Inc_Input>;
  _set?: Maybe<Certificate_Templates_Set_Input>;
  pk_columns: Certificate_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_ArticlesArgs = {
  _inc?: Maybe<Course_Articles_Inc_Input>;
  _set?: Maybe<Course_Articles_Set_Input>;
  where: Course_Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Articles_By_PkArgs = {
  _inc?: Maybe<Course_Articles_Inc_Input>;
  _set?: Maybe<Course_Articles_Set_Input>;
  pk_columns: Course_Articles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_AssessmentsArgs = {
  _inc?: Maybe<Course_Assessments_Inc_Input>;
  _set?: Maybe<Course_Assessments_Set_Input>;
  where: Course_Assessments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Assessments_By_PkArgs = {
  _inc?: Maybe<Course_Assessments_Inc_Input>;
  _set?: Maybe<Course_Assessments_Set_Input>;
  pk_columns: Course_Assessments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_AttachmentsArgs = {
  _inc?: Maybe<Course_Attachments_Inc_Input>;
  _set?: Maybe<Course_Attachments_Set_Input>;
  where: Course_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Attachments_By_PkArgs = {
  _inc?: Maybe<Course_Attachments_Inc_Input>;
  _set?: Maybe<Course_Attachments_Set_Input>;
  pk_columns: Course_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_CertificatesArgs = {
  _inc?: Maybe<Course_Certificates_Inc_Input>;
  _set?: Maybe<Course_Certificates_Set_Input>;
  where: Course_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Certificates_By_PkArgs = {
  _inc?: Maybe<Course_Certificates_Inc_Input>;
  _set?: Maybe<Course_Certificates_Set_Input>;
  pk_columns: Course_Certificates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Discount_TypesArgs = {
  _set?: Maybe<Course_Discount_Types_Set_Input>;
  where: Course_Discount_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Discount_Types_By_PkArgs = {
  _set?: Maybe<Course_Discount_Types_Set_Input>;
  pk_columns: Course_Discount_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_DiscountsArgs = {
  _inc?: Maybe<Course_Discounts_Inc_Input>;
  _set?: Maybe<Course_Discounts_Set_Input>;
  where: Course_Discounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Discounts_By_PkArgs = {
  _inc?: Maybe<Course_Discounts_Inc_Input>;
  _set?: Maybe<Course_Discounts_Set_Input>;
  pk_columns: Course_Discounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_FormsArgs = {
  _inc?: Maybe<Course_Forms_Inc_Input>;
  _set?: Maybe<Course_Forms_Set_Input>;
  where: Course_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Forms_By_PkArgs = {
  _inc?: Maybe<Course_Forms_Inc_Input>;
  _set?: Maybe<Course_Forms_Set_Input>;
  pk_columns: Course_Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_GainsArgs = {
  _inc?: Maybe<Course_Gains_Inc_Input>;
  _set?: Maybe<Course_Gains_Set_Input>;
  where: Course_Gains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Gains_By_PkArgs = {
  _inc?: Maybe<Course_Gains_Inc_Input>;
  _set?: Maybe<Course_Gains_Set_Input>;
  pk_columns: Course_Gains_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_InstructorsArgs = {
  _inc?: Maybe<Course_Instructors_Inc_Input>;
  _set?: Maybe<Course_Instructors_Set_Input>;
  where: Course_Instructors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Instructors_By_PkArgs = {
  _inc?: Maybe<Course_Instructors_Inc_Input>;
  _set?: Maybe<Course_Instructors_Set_Input>;
  pk_columns: Course_Instructors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_InvitesArgs = {
  _inc?: Maybe<Course_Invites_Inc_Input>;
  _set?: Maybe<Course_Invites_Set_Input>;
  where: Course_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Invites_By_PkArgs = {
  _inc?: Maybe<Course_Invites_Inc_Input>;
  _set?: Maybe<Course_Invites_Set_Input>;
  pk_columns: Course_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_LecturersArgs = {
  _inc?: Maybe<Course_Lecturers_Inc_Input>;
  _set?: Maybe<Course_Lecturers_Set_Input>;
  where: Course_Lecturers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Live_SessionsArgs = {
  _inc?: Maybe<Course_Live_Sessions_Inc_Input>;
  _set?: Maybe<Course_Live_Sessions_Set_Input>;
  where: Course_Live_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Live_Sessions_By_PkArgs = {
  _inc?: Maybe<Course_Live_Sessions_Inc_Input>;
  _set?: Maybe<Course_Live_Sessions_Set_Input>;
  pk_columns: Course_Live_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Module_StudentsArgs = {
  _inc?: Maybe<Course_Module_Students_Inc_Input>;
  _set?: Maybe<Course_Module_Students_Set_Input>;
  where: Course_Module_Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Module_Students_By_PkArgs = {
  _inc?: Maybe<Course_Module_Students_Inc_Input>;
  _set?: Maybe<Course_Module_Students_Set_Input>;
  pk_columns: Course_Module_Students_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_ModulesArgs = {
  _inc?: Maybe<Course_Modules_Inc_Input>;
  _set?: Maybe<Course_Modules_Set_Input>;
  where: Course_Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Modules_By_PkArgs = {
  _inc?: Maybe<Course_Modules_Inc_Input>;
  _set?: Maybe<Course_Modules_Set_Input>;
  pk_columns: Course_Modules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_ObjectivesArgs = {
  _inc?: Maybe<Course_Objectives_Inc_Input>;
  _set?: Maybe<Course_Objectives_Set_Input>;
  where: Course_Objectives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Objectives_By_PkArgs = {
  _inc?: Maybe<Course_Objectives_Inc_Input>;
  _set?: Maybe<Course_Objectives_Set_Input>;
  pk_columns: Course_Objectives_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_OrderArgs = {
  _inc?: Maybe<Course_Order_Inc_Input>;
  _set?: Maybe<Course_Order_Set_Input>;
  where: Course_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Order_By_PkArgs = {
  _inc?: Maybe<Course_Order_Inc_Input>;
  _set?: Maybe<Course_Order_Set_Input>;
  pk_columns: Course_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Order_ModulesArgs = {
  _inc?: Maybe<Course_Order_Modules_Inc_Input>;
  _set?: Maybe<Course_Order_Modules_Set_Input>;
  where: Course_Order_Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Order_Modules_By_PkArgs = {
  _inc?: Maybe<Course_Order_Modules_Inc_Input>;
  _set?: Maybe<Course_Order_Modules_Set_Input>;
  pk_columns: Course_Order_Modules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Order_StatusesArgs = {
  _set?: Maybe<Course_Order_Statuses_Set_Input>;
  where: Course_Order_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Order_Statuses_By_PkArgs = {
  _set?: Maybe<Course_Order_Statuses_Set_Input>;
  pk_columns: Course_Order_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Payment_MethodsArgs = {
  _set?: Maybe<Course_Payment_Methods_Set_Input>;
  where: Course_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Payment_Methods_By_PkArgs = {
  _set?: Maybe<Course_Payment_Methods_Set_Input>;
  pk_columns: Course_Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_RemindersArgs = {
  _inc?: Maybe<Course_Reminders_Inc_Input>;
  _set?: Maybe<Course_Reminders_Set_Input>;
  where: Course_Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Reminders_By_PkArgs = {
  _inc?: Maybe<Course_Reminders_Inc_Input>;
  _set?: Maybe<Course_Reminders_Set_Input>;
  pk_columns: Course_Reminders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_RequirementsArgs = {
  _inc?: Maybe<Course_Requirements_Inc_Input>;
  _set?: Maybe<Course_Requirements_Set_Input>;
  where: Course_Requirements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Requirements_By_PkArgs = {
  _inc?: Maybe<Course_Requirements_Inc_Input>;
  _set?: Maybe<Course_Requirements_Set_Input>;
  pk_columns: Course_Requirements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_RewardsArgs = {
  _inc?: Maybe<Course_Rewards_Inc_Input>;
  _set?: Maybe<Course_Rewards_Set_Input>;
  where: Course_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Rewards_By_PkArgs = {
  _inc?: Maybe<Course_Rewards_Inc_Input>;
  _set?: Maybe<Course_Rewards_Set_Input>;
  pk_columns: Course_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Sell_TypesArgs = {
  _set?: Maybe<Course_Sell_Types_Set_Input>;
  where: Course_Sell_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Sell_Types_By_PkArgs = {
  _set?: Maybe<Course_Sell_Types_Set_Input>;
  pk_columns: Course_Sell_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_SignatoriesArgs = {
  _inc?: Maybe<Course_Signatories_Inc_Input>;
  _set?: Maybe<Course_Signatories_Set_Input>;
  where: Course_Signatories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Signatories_By_PkArgs = {
  _inc?: Maybe<Course_Signatories_Inc_Input>;
  _set?: Maybe<Course_Signatories_Set_Input>;
  pk_columns: Course_Signatories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_StudentsArgs = {
  _inc?: Maybe<Course_Students_Inc_Input>;
  _set?: Maybe<Course_Students_Set_Input>;
  where: Course_Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Students_By_PkArgs = {
  _inc?: Maybe<Course_Students_Inc_Input>;
  _set?: Maybe<Course_Students_Set_Input>;
  pk_columns: Course_Students_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_VideosArgs = {
  _inc?: Maybe<Course_Videos_Inc_Input>;
  _set?: Maybe<Course_Videos_Set_Input>;
  where: Course_Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Videos_By_PkArgs = {
  _inc?: Maybe<Course_Videos_Inc_Input>;
  _set?: Maybe<Course_Videos_Set_Input>;
  pk_columns: Course_Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Videos_StatusesArgs = {
  _set?: Maybe<Course_Videos_Statuses_Set_Input>;
  where: Course_Videos_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Videos_Statuses_By_PkArgs = {
  _set?: Maybe<Course_Videos_Statuses_Set_Input>;
  pk_columns: Course_Videos_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoursesArgs = {
  _inc?: Maybe<Courses_Inc_Input>;
  _set?: Maybe<Courses_Set_Input>;
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Courses_By_PkArgs = {
  _inc?: Maybe<Courses_Inc_Input>;
  _set?: Maybe<Courses_Set_Input>;
  pk_columns: Courses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cpd_Unit_RewardsArgs = {
  _inc?: Maybe<Cpd_Unit_Rewards_Inc_Input>;
  _set?: Maybe<Cpd_Unit_Rewards_Set_Input>;
  where: Cpd_Unit_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cpd_Unit_Rewards_By_PkArgs = {
  _inc?: Maybe<Cpd_Unit_Rewards_Inc_Input>;
  _set?: Maybe<Cpd_Unit_Rewards_Set_Input>;
  pk_columns: Cpd_Unit_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cpd_Units_RewardArgs = {
  _inc?: Maybe<Cpd_Units_Reward_Inc_Input>;
  _set?: Maybe<Cpd_Units_Reward_Set_Input>;
  where: Cpd_Units_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cpd_Units_Reward_By_PkArgs = {
  _inc?: Maybe<Cpd_Units_Reward_Inc_Input>;
  _set?: Maybe<Cpd_Units_Reward_Set_Input>;
  pk_columns: Cpd_Units_Reward_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomerArgs = {
  _set?: Maybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_By_PkArgs = {
  _set?: Maybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DiscountArgs = {
  _inc?: Maybe<Discount_Inc_Input>;
  _set?: Maybe<Discount_Set_Input>;
  where: Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_By_PkArgs = {
  _inc?: Maybe<Discount_Inc_Input>;
  _set?: Maybe<Discount_Set_Input>;
  pk_columns: Discount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Notification_TypesArgs = {
  _set?: Maybe<Email_Notification_Types_Set_Input>;
  where: Email_Notification_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Notification_Types_By_PkArgs = {
  _set?: Maybe<Email_Notification_Types_Set_Input>;
  pk_columns: Email_Notification_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_NotificationsArgs = {
  _inc?: Maybe<Email_Notifications_Inc_Input>;
  _set?: Maybe<Email_Notifications_Set_Input>;
  where: Email_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Notifications_By_PkArgs = {
  _inc?: Maybe<Email_Notifications_Inc_Input>;
  _set?: Maybe<Email_Notifications_Set_Input>;
  pk_columns: Email_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventArgs = {
  _inc?: Maybe<Event_Inc_Input>;
  _set?: Maybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_By_PkArgs = {
  _inc?: Maybe<Event_Inc_Input>;
  _set?: Maybe<Event_Set_Input>;
  pk_columns: Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Industries_IndustryArgs = {
  _set?: Maybe<Event_Industries_Industry_Set_Input>;
  where: Event_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Industries_Industry_By_PkArgs = {
  _set?: Maybe<Event_Industries_Industry_Set_Input>;
  pk_columns: Event_Industries_Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Managing_Users_UserArgs = {
  _set?: Maybe<Event_Managing_Users_User_Set_Input>;
  where: Event_Managing_Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Managing_Users_User_By_PkArgs = {
  _set?: Maybe<Event_Managing_Users_User_Set_Input>;
  pk_columns: Event_Managing_Users_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ParticipantArgs = {
  _set?: Maybe<Event_Participant_Set_Input>;
  where: Event_Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Participant_By_PkArgs = {
  _set?: Maybe<Event_Participant_Set_Input>;
  pk_columns: Event_Participant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_ItemArgs = {
  _set?: Maybe<Faq_Item_Set_Input>;
  where: Faq_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_Item_By_PkArgs = {
  _set?: Maybe<Faq_Item_Set_Input>;
  pk_columns: Faq_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormArgs = {
  _set?: Maybe<Form_Set_Input>;
  where: Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_By_PkArgs = {
  _set?: Maybe<Form_Set_Input>;
  pk_columns: Form_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Event_NamesArgs = {
  _set?: Maybe<Form_Event_Names_Set_Input>;
  where: Form_Event_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Event_Names_By_PkArgs = {
  _set?: Maybe<Form_Event_Names_Set_Input>;
  pk_columns: Form_Event_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_EventsArgs = {
  _set?: Maybe<Form_Events_Set_Input>;
  where: Form_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Events_By_PkArgs = {
  _set?: Maybe<Form_Events_Set_Input>;
  pk_columns: Form_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_InstanceArgs = {
  _set?: Maybe<Form_Instance_Set_Input>;
  where: Form_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Instance_By_PkArgs = {
  _set?: Maybe<Form_Instance_Set_Input>;
  pk_columns: Form_Instance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_AnswersArgs = {
  _inc?: Maybe<Form_Question_Answers_Inc_Input>;
  _set?: Maybe<Form_Question_Answers_Set_Input>;
  where: Form_Question_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_Answers_By_PkArgs = {
  _inc?: Maybe<Form_Question_Answers_Inc_Input>;
  _set?: Maybe<Form_Question_Answers_Set_Input>;
  pk_columns: Form_Question_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_Submission_StatesArgs = {
  _set?: Maybe<Form_Question_Submission_States_Set_Input>;
  where: Form_Question_Submission_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_Submission_States_By_PkArgs = {
  _set?: Maybe<Form_Question_Submission_States_Set_Input>;
  pk_columns: Form_Question_Submission_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_SubmissionsArgs = {
  _inc?: Maybe<Form_Question_Submissions_Inc_Input>;
  _set?: Maybe<Form_Question_Submissions_Set_Input>;
  where: Form_Question_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_Submissions_By_PkArgs = {
  _inc?: Maybe<Form_Question_Submissions_Inc_Input>;
  _set?: Maybe<Form_Question_Submissions_Set_Input>;
  pk_columns: Form_Question_Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_TypesArgs = {
  _set?: Maybe<Form_Question_Types_Set_Input>;
  where: Form_Question_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Question_Types_By_PkArgs = {
  _set?: Maybe<Form_Question_Types_Set_Input>;
  pk_columns: Form_Question_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_QuestionsArgs = {
  _inc?: Maybe<Form_Questions_Inc_Input>;
  _set?: Maybe<Form_Questions_Set_Input>;
  where: Form_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Questions_By_PkArgs = {
  _inc?: Maybe<Form_Questions_Inc_Input>;
  _set?: Maybe<Form_Questions_Set_Input>;
  pk_columns: Form_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_ScoreArgs = {
  _inc?: Maybe<Form_Score_Inc_Input>;
  _set?: Maybe<Form_Score_Set_Input>;
  where: Form_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Score_By_PkArgs = {
  _inc?: Maybe<Form_Score_Inc_Input>;
  _set?: Maybe<Form_Score_Set_Input>;
  pk_columns: Form_Score_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Scoring_PoliciesArgs = {
  _set?: Maybe<Form_Scoring_Policies_Set_Input>;
  where: Form_Scoring_Policies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Scoring_Policies_By_PkArgs = {
  _set?: Maybe<Form_Scoring_Policies_Set_Input>;
  pk_columns: Form_Scoring_Policies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Submission_StatusesArgs = {
  _set?: Maybe<Form_Submission_Statuses_Set_Input>;
  where: Form_Submission_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Submission_Statuses_By_PkArgs = {
  _set?: Maybe<Form_Submission_Statuses_Set_Input>;
  pk_columns: Form_Submission_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_SubmissionsArgs = {
  _inc?: Maybe<Form_Submissions_Inc_Input>;
  _set?: Maybe<Form_Submissions_Set_Input>;
  where: Form_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Submissions_By_PkArgs = {
  _inc?: Maybe<Form_Submissions_Inc_Input>;
  _set?: Maybe<Form_Submissions_Set_Input>;
  pk_columns: Form_Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_TypesArgs = {
  _set?: Maybe<Form_Types_Set_Input>;
  where: Form_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Types_By_PkArgs = {
  _set?: Maybe<Form_Types_Set_Input>;
  pk_columns: Form_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _inc?: Maybe<Forms_Inc_Input>;
  _set?: Maybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _inc?: Maybe<Forms_Inc_Input>;
  _set?: Maybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImagesArgs = {
  _inc?: Maybe<Images_Inc_Input>;
  _set?: Maybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Images_By_PkArgs = {
  _inc?: Maybe<Images_Inc_Input>;
  _set?: Maybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IndustryArgs = {
  _set?: Maybe<Industry_Set_Input>;
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_By_PkArgs = {
  _set?: Maybe<Industry_Set_Input>;
  pk_columns: Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_CategoryArgs = {
  _set?: Maybe<Industry_Category_Set_Input>;
  where: Industry_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_Category_By_PkArgs = {
  _set?: Maybe<Industry_Category_Set_Input>;
  pk_columns: Industry_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_TypeArgs = {
  _set?: Maybe<Industry_Type_Set_Input>;
  where: Industry_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_Type_By_PkArgs = {
  _set?: Maybe<Industry_Type_Set_Input>;
  pk_columns: Industry_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationsArgs = {
  _inc?: Maybe<Migrations_Inc_Input>;
  _set?: Maybe<Migrations_Set_Input>;
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Migrations_By_PkArgs = {
  _inc?: Maybe<Migrations_Inc_Input>;
  _set?: Maybe<Migrations_Set_Input>;
  pk_columns: Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mock_OrderArgs = {
  _inc?: Maybe<Mock_Order_Inc_Input>;
  _set?: Maybe<Mock_Order_Set_Input>;
  where: Mock_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mock_Order2Args = {
  _inc?: Maybe<Mock_Order2_Inc_Input>;
  _set?: Maybe<Mock_Order2_Set_Input>;
  where: Mock_Order2_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mock_Order2_By_PkArgs = {
  _inc?: Maybe<Mock_Order2_Inc_Input>;
  _set?: Maybe<Mock_Order2_Set_Input>;
  pk_columns: Mock_Order2_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mock_Order_By_PkArgs = {
  _inc?: Maybe<Mock_Order_Inc_Input>;
  _set?: Maybe<Mock_Order_Set_Input>;
  pk_columns: Mock_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Item_CommentsArgs = {
  _inc?: Maybe<Module_Item_Comments_Inc_Input>;
  _set?: Maybe<Module_Item_Comments_Set_Input>;
  where: Module_Item_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Item_Comments_By_PkArgs = {
  _inc?: Maybe<Module_Item_Comments_Inc_Input>;
  _set?: Maybe<Module_Item_Comments_Set_Input>;
  pk_columns: Module_Item_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_ItemsArgs = {
  _inc?: Maybe<Module_Items_Inc_Input>;
  _set?: Maybe<Module_Items_Set_Input>;
  where: Module_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Items_By_PkArgs = {
  _inc?: Maybe<Module_Items_Inc_Input>;
  _set?: Maybe<Module_Items_Set_Input>;
  pk_columns: Module_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _inc?: Maybe<Order_Inc_Input>;
  _set?: Maybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _inc?: Maybe<Order_Inc_Input>;
  _set?: Maybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Other_RewardArgs = {
  _inc?: Maybe<Other_Reward_Inc_Input>;
  _set?: Maybe<Other_Reward_Set_Input>;
  where: Other_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Other_Reward_By_PkArgs = {
  _inc?: Maybe<Other_Reward_Inc_Input>;
  _set?: Maybe<Other_Reward_Set_Input>;
  pk_columns: Other_Reward_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Other_Reward_TypeArgs = {
  _set?: Maybe<Other_Reward_Type_Set_Input>;
  where: Other_Reward_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Other_Reward_Type_By_PkArgs = {
  _set?: Maybe<Other_Reward_Type_Set_Input>;
  pk_columns: Other_Reward_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Other_Unit_RewardsArgs = {
  _inc?: Maybe<Other_Unit_Rewards_Inc_Input>;
  _set?: Maybe<Other_Unit_Rewards_Set_Input>;
  where: Other_Unit_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Other_Unit_Rewards_By_PkArgs = {
  _inc?: Maybe<Other_Unit_Rewards_Inc_Input>;
  _set?: Maybe<Other_Unit_Rewards_Set_Input>;
  pk_columns: Other_Unit_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderArgs = {
  _set?: Maybe<Provider_Set_Input>;
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_ApplicationArgs = {
  _inc?: Maybe<Provider_Application_Inc_Input>;
  _set?: Maybe<Provider_Application_Set_Input>;
  where: Provider_Application_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Application_By_PkArgs = {
  _inc?: Maybe<Provider_Application_Inc_Input>;
  _set?: Maybe<Provider_Application_Set_Input>;
  pk_columns: Provider_Application_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Application_Industries_IndustryArgs = {
  _set?: Maybe<Provider_Application_Industries_Industry_Set_Input>;
  where: Provider_Application_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Application_Industries_Industry_By_PkArgs = {
  _set?: Maybe<Provider_Application_Industries_Industry_Set_Input>;
  pk_columns: Provider_Application_Industries_Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_By_PkArgs = {
  _set?: Maybe<Provider_Set_Input>;
  pk_columns: Provider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Dragonpay_CredentialsArgs = {
  _set?: Maybe<Provider_Dragonpay_Credentials_Set_Input>;
  where: Provider_Dragonpay_Credentials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Dragonpay_Credentials_By_PkArgs = {
  _set?: Maybe<Provider_Dragonpay_Credentials_Set_Input>;
  pk_columns: Provider_Dragonpay_Credentials_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Frequently_Used_Industries_IndustryArgs = {
  _set?: Maybe<Provider_Frequently_Used_Industries_Industry_Set_Input>;
  where: Provider_Frequently_Used_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Frequently_Used_Industries_Industry_By_PkArgs = {
  _set?: Maybe<Provider_Frequently_Used_Industries_Industry_Set_Input>;
  pk_columns: Provider_Frequently_Used_Industries_Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_GoogleArgs = {
  _inc?: Maybe<Provider_Google_Inc_Input>;
  _set?: Maybe<Provider_Google_Set_Input>;
  where: Provider_Google_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Google_By_PkArgs = {
  _inc?: Maybe<Provider_Google_Inc_Input>;
  _set?: Maybe<Provider_Google_Set_Input>;
  pk_columns: Provider_Google_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Managing_Users_UserArgs = {
  _set?: Maybe<Provider_Managing_Users_User_Set_Input>;
  where: Provider_Managing_Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Managing_Users_User_By_PkArgs = {
  _set?: Maybe<Provider_Managing_Users_User_Set_Input>;
  pk_columns: Provider_Managing_Users_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Subscription_PlanArgs = {
  _set?: Maybe<Provider_Subscription_Plan_Set_Input>;
  where: Provider_Subscription_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Subscription_Plan_By_PkArgs = {
  _set?: Maybe<Provider_Subscription_Plan_Set_Input>;
  pk_columns: Provider_Subscription_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Subscription_PlansArgs = {
  _set?: Maybe<Provider_Subscription_Plans_Set_Input>;
  where: Provider_Subscription_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Subscription_Plans_By_PkArgs = {
  _set?: Maybe<Provider_Subscription_Plans_Set_Input>;
  pk_columns: Provider_Subscription_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_SubscriptionsArgs = {
  _inc?: Maybe<Provider_Subscriptions_Inc_Input>;
  _set?: Maybe<Provider_Subscriptions_Set_Input>;
  where: Provider_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Subscriptions_By_PkArgs = {
  _inc?: Maybe<Provider_Subscriptions_Inc_Input>;
  _set?: Maybe<Provider_Subscriptions_Set_Input>;
  pk_columns: Provider_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_ZoomArgs = {
  _set?: Maybe<Provider_Zoom_Set_Input>;
  where: Provider_Zoom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Zoom_By_PkArgs = {
  _set?: Maybe<Provider_Zoom_Set_Input>;
  pk_columns: Provider_Zoom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ranking_RewardArgs = {
  _inc?: Maybe<Ranking_Reward_Inc_Input>;
  _set?: Maybe<Ranking_Reward_Set_Input>;
  where: Ranking_Reward_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ranking_Reward_By_PkArgs = {
  _inc?: Maybe<Ranking_Reward_Inc_Input>;
  _set?: Maybe<Ranking_Reward_Set_Input>;
  pk_columns: Ranking_Reward_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ranking_RewardsArgs = {
  _inc?: Maybe<Ranking_Rewards_Inc_Input>;
  _set?: Maybe<Ranking_Rewards_Set_Input>;
  where: Ranking_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ranking_Rewards_By_PkArgs = {
  _inc?: Maybe<Ranking_Rewards_Inc_Input>;
  _set?: Maybe<Ranking_Rewards_Set_Input>;
  pk_columns: Ranking_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_CertificateArgs = {
  _set?: Maybe<Reward_Certificate_Set_Input>;
  where: Reward_Certificate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Certificate_By_PkArgs = {
  _set?: Maybe<Reward_Certificate_Set_Input>;
  pk_columns: Reward_Certificate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Certificate_Signatories_Certificate_SignatoryArgs = {
  _set?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Set_Input>;
  where: Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reward_Certificate_Signatories_Certificate_Signatory_By_PkArgs = {
  _set?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Set_Input>;
  pk_columns: Reward_Certificate_Signatories_Certificate_Signatory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Schema_MigrationsArgs = {
  _set?: Maybe<Schema_Migrations_Set_Input>;
  where: Schema_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Schema_Migrations_By_PkArgs = {
  _set?: Maybe<Schema_Migrations_Set_Input>;
  pk_columns: Schema_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SpeakersArgs = {
  _inc?: Maybe<Speakers_Inc_Input>;
  _set?: Maybe<Speakers_Set_Input>;
  where: Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Speakers_By_PkArgs = {
  _inc?: Maybe<Speakers_Inc_Input>;
  _set?: Maybe<Speakers_Set_Input>;
  pk_columns: Speakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StreamArgs = {
  _set?: Maybe<Stream_Set_Input>;
  where: Stream_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stream_AttachmentArgs = {
  _set?: Maybe<Stream_Attachment_Set_Input>;
  where: Stream_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stream_Attachment_By_PkArgs = {
  _set?: Maybe<Stream_Attachment_Set_Input>;
  pk_columns: Stream_Attachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stream_By_PkArgs = {
  _set?: Maybe<Stream_Set_Input>;
  pk_columns: Stream_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stream_Forms_FormArgs = {
  _set?: Maybe<Stream_Forms_Form_Set_Input>;
  where: Stream_Forms_Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stream_Forms_Form_By_PkArgs = {
  _set?: Maybe<Stream_Forms_Form_Set_Input>;
  pk_columns: Stream_Forms_Form_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_ProgressArgs = {
  _inc?: Maybe<Student_Progress_Inc_Input>;
  _set?: Maybe<Student_Progress_Set_Input>;
  where: Student_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Progress_By_PkArgs = {
  _inc?: Maybe<Student_Progress_Inc_Input>;
  _set?: Maybe<Student_Progress_Set_Input>;
  pk_columns: Student_Progress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_CertificatesArgs = {
  _inc?: Maybe<User_Certificates_Inc_Input>;
  _set?: Maybe<User_Certificates_Set_Input>;
  where: User_Certificates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Certificates_By_PkArgs = {
  _inc?: Maybe<User_Certificates_Inc_Input>;
  _set?: Maybe<User_Certificates_Set_Input>;
  pk_columns: User_Certificates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Followed_Industries_IndustryArgs = {
  _set?: Maybe<User_Followed_Industries_Industry_Set_Input>;
  where: User_Followed_Industries_Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Followed_Industries_Industry_By_PkArgs = {
  _set?: Maybe<User_Followed_Industries_Industry_Set_Input>;
  pk_columns: User_Followed_Industries_Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Provider_Subscriptions_ProviderArgs = {
  _set?: Maybe<User_Provider_Subscriptions_Provider_Set_Input>;
  where: User_Provider_Subscriptions_Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Provider_Subscriptions_Provider_By_PkArgs = {
  _set?: Maybe<User_Provider_Subscriptions_Provider_Set_Input>;
  pk_columns: User_Provider_Subscriptions_Provider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Purchased_Events_EventArgs = {
  _set?: Maybe<User_Purchased_Events_Event_Set_Input>;
  where: User_Purchased_Events_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Purchased_Events_Event_By_PkArgs = {
  _set?: Maybe<User_Purchased_Events_Event_Set_Input>;
  pk_columns: User_Purchased_Events_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['numeric']>;
  readonly _gt?: Maybe<Scalars['numeric']>;
  readonly _gte?: Maybe<Scalars['numeric']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['numeric']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['numeric']>;
  readonly _lte?: Maybe<Scalars['numeric']>;
  readonly _neq?: Maybe<Scalars['numeric']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['numeric']>>;
};

/** columns and relationships of "order" */
export type Order = {
  readonly __typename?: 'order';
  readonly channel?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['timestamp'];
  readonly customerId?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly discount?: Maybe<Discount>;
  readonly discountId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  readonly event_participants: ReadonlyArray<Event_Participant>;
  /** An aggregate relationship */
  readonly event_participants_aggregate: Event_Participant_Aggregate;
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly product?: Maybe<Product>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly purchaseDate: Scalars['timestamp'];
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total: Scalars['Int'];
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user?: Maybe<User>;
};


/** columns and relationships of "order" */
export type OrderEvent_ParticipantsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


/** columns and relationships of "order" */
export type OrderEvent_Participants_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
  readonly __typename?: 'order_aggregate';
  readonly aggregate?: Maybe<Order_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  readonly __typename?: 'order_aggregate_fields';
  readonly avg?: Maybe<Order_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Order_Max_Fields>;
  readonly min?: Maybe<Order_Min_Fields>;
  readonly stddev?: Maybe<Order_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Order_Sum_Fields>;
  readonly var_pop?: Maybe<Order_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Order_Var_Samp_Fields>;
  readonly variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  readonly avg?: Maybe<Order_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Order_Max_Order_By>;
  readonly min?: Maybe<Order_Min_Order_By>;
  readonly stddev?: Maybe<Order_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Order_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Order_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Order_Sum_Order_By>;
  readonly var_pop?: Maybe<Order_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Order_Var_Samp_Order_By>;
  readonly variance?: Maybe<Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Order_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  readonly __typename?: 'order_avg_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Order_Bool_Exp>>;
  readonly _not?: Maybe<Order_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Order_Bool_Exp>>;
  readonly channel?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly customerId?: Maybe<String_Comparison_Exp>;
  readonly discount?: Maybe<Discount_Bool_Exp>;
  readonly discountId?: Maybe<Uuid_Comparison_Exp>;
  readonly event_participants?: Maybe<Event_Participant_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly product?: Maybe<Product_Bool_Exp>;
  readonly productId?: Maybe<Uuid_Comparison_Exp>;
  readonly purchaseDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly refno?: Maybe<String_Comparison_Exp>;
  readonly status?: Maybe<String_Comparison_Exp>;
  readonly total?: Maybe<Int_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint */
  Pk_1031171c13130102495201e3e20 = 'PK_1031171c13130102495201e3e20'
}

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  readonly total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  readonly channel?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly discount?: Maybe<Discount_Obj_Rel_Insert_Input>;
  readonly discountId?: Maybe<Scalars['uuid']>;
  readonly event_participants?: Maybe<Event_Participant_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly product?: Maybe<Product_Obj_Rel_Insert_Input>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  readonly __typename?: 'order_max_fields';
  readonly channel?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly discountId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  readonly channel?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly discountId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  readonly __typename?: 'order_min_fields';
  readonly channel?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly discountId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  readonly channel?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly discountId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  readonly __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  readonly data: Order_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Order_On_Conflict>;
};

/** on conflict condition type for table "order" */
export type Order_On_Conflict = {
  readonly constraint: Order_Constraint;
  readonly update_columns?: ReadonlyArray<Order_Update_Column>;
  readonly where?: Maybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  readonly channel?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly customerId?: Maybe<Order_By>;
  readonly discount?: Maybe<Discount_Order_By>;
  readonly discountId?: Maybe<Order_By>;
  readonly event_participants_aggregate?: Maybe<Event_Participant_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly product?: Maybe<Product_Order_By>;
  readonly productId?: Maybe<Order_By>;
  readonly purchaseDate?: Maybe<Order_By>;
  readonly refno?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly total?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DiscountId = 'discountId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  readonly channel?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly customerId?: Maybe<Scalars['String']>;
  readonly discountId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly productId?: Maybe<Scalars['uuid']>;
  readonly purchaseDate?: Maybe<Scalars['timestamp']>;
  readonly refno?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly total?: Maybe<Scalars['Int']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  readonly __typename?: 'order_stddev_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  readonly __typename?: 'order_stddev_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  readonly __typename?: 'order_stddev_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  readonly __typename?: 'order_sum_fields';
  readonly total?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  DiscountId = 'discountId',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  PurchaseDate = 'purchaseDate',
  /** column name */
  Refno = 'refno',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  readonly __typename?: 'order_var_pop_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  readonly __typename?: 'order_var_samp_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  readonly __typename?: 'order_variance_fields';
  readonly total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  readonly total?: Maybe<Order_By>;
};

/** columns and relationships of "other_reward" */
export type Other_Reward = {
  readonly __typename?: 'other_reward';
  /** An object relationship */
  readonly event?: Maybe<Event>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly other_reward_type?: Maybe<Other_Reward_Type>;
  readonly rewardCertificateType: Scalars['String'];
  /** An array relationship */
  readonly reward_certificates: ReadonlyArray<Reward_Certificate>;
  /** An aggregate relationship */
  readonly reward_certificates_aggregate: Reward_Certificate_Aggregate;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly units: Scalars['Int'];
};


/** columns and relationships of "other_reward" */
export type Other_RewardReward_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "other_reward" */
export type Other_RewardReward_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};

/** aggregated selection of "other_reward" */
export type Other_Reward_Aggregate = {
  readonly __typename?: 'other_reward_aggregate';
  readonly aggregate?: Maybe<Other_Reward_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Other_Reward>;
};

/** aggregate fields of "other_reward" */
export type Other_Reward_Aggregate_Fields = {
  readonly __typename?: 'other_reward_aggregate_fields';
  readonly avg?: Maybe<Other_Reward_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Other_Reward_Max_Fields>;
  readonly min?: Maybe<Other_Reward_Min_Fields>;
  readonly stddev?: Maybe<Other_Reward_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Other_Reward_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Other_Reward_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Other_Reward_Sum_Fields>;
  readonly var_pop?: Maybe<Other_Reward_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Other_Reward_Var_Samp_Fields>;
  readonly variance?: Maybe<Other_Reward_Variance_Fields>;
};


/** aggregate fields of "other_reward" */
export type Other_Reward_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "other_reward" */
export type Other_Reward_Aggregate_Order_By = {
  readonly avg?: Maybe<Other_Reward_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Other_Reward_Max_Order_By>;
  readonly min?: Maybe<Other_Reward_Min_Order_By>;
  readonly stddev?: Maybe<Other_Reward_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Other_Reward_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Other_Reward_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Other_Reward_Sum_Order_By>;
  readonly var_pop?: Maybe<Other_Reward_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Other_Reward_Var_Samp_Order_By>;
  readonly variance?: Maybe<Other_Reward_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "other_reward" */
export type Other_Reward_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Other_Reward_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Other_Reward_On_Conflict>;
};

/** aggregate avg on columns */
export type Other_Reward_Avg_Fields = {
  readonly __typename?: 'other_reward_avg_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "other_reward" */
export type Other_Reward_Avg_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "other_reward". All fields are combined with a logical 'AND'. */
export type Other_Reward_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Other_Reward_Bool_Exp>>;
  readonly _not?: Maybe<Other_Reward_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Other_Reward_Bool_Exp>>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly other_reward_type?: Maybe<Other_Reward_Type_Bool_Exp>;
  readonly rewardCertificateType?: Maybe<String_Comparison_Exp>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly typeName?: Maybe<String_Comparison_Exp>;
  readonly units?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "other_reward" */
export enum Other_Reward_Constraint {
  /** unique or primary key constraint */
  PkBa591b96ba8a39af5c2034b73a7 = 'PK_ba591b96ba8a39af5c2034b73a7',
  /** unique or primary key constraint */
  UqOtherReward = 'UQ_OTHER_REWARD'
}

/** input type for incrementing numeric columns in table "other_reward" */
export type Other_Reward_Inc_Input = {
  readonly units?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "other_reward" */
export type Other_Reward_Insert_Input = {
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly other_reward_type?: Maybe<Other_Reward_Type_Obj_Rel_Insert_Input>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Arr_Rel_Insert_Input>;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Other_Reward_Max_Fields = {
  readonly __typename?: 'other_reward_max_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "other_reward" */
export type Other_Reward_Max_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly typeName?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Other_Reward_Min_Fields = {
  readonly __typename?: 'other_reward_min_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "other_reward" */
export type Other_Reward_Min_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly typeName?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** response of any mutation on the table "other_reward" */
export type Other_Reward_Mutation_Response = {
  readonly __typename?: 'other_reward_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Other_Reward>;
};

/** input type for inserting object relation for remote table "other_reward" */
export type Other_Reward_Obj_Rel_Insert_Input = {
  readonly data: Other_Reward_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Other_Reward_On_Conflict>;
};

/** on conflict condition type for table "other_reward" */
export type Other_Reward_On_Conflict = {
  readonly constraint: Other_Reward_Constraint;
  readonly update_columns?: ReadonlyArray<Other_Reward_Update_Column>;
  readonly where?: Maybe<Other_Reward_Bool_Exp>;
};

/** Ordering options when selecting data from "other_reward". */
export type Other_Reward_Order_By = {
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly other_reward_type?: Maybe<Other_Reward_Type_Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly reward_certificates_aggregate?: Maybe<Reward_Certificate_Aggregate_Order_By>;
  readonly typeName?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** primary key columns input for table: other_reward */
export type Other_Reward_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "other_reward" */
export enum Other_Reward_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  Units = 'units'
}

/** input type for updating data in table "other_reward" */
export type Other_Reward_Set_Input = {
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly typeName?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Other_Reward_Stddev_Fields = {
  readonly __typename?: 'other_reward_stddev_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "other_reward" */
export type Other_Reward_Stddev_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Other_Reward_Stddev_Pop_Fields = {
  readonly __typename?: 'other_reward_stddev_pop_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "other_reward" */
export type Other_Reward_Stddev_Pop_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Other_Reward_Stddev_Samp_Fields = {
  readonly __typename?: 'other_reward_stddev_samp_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "other_reward" */
export type Other_Reward_Stddev_Samp_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Other_Reward_Sum_Fields = {
  readonly __typename?: 'other_reward_sum_fields';
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "other_reward" */
export type Other_Reward_Sum_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** columns and relationships of "other_reward_type" */
export type Other_Reward_Type = {
  readonly __typename?: 'other_reward_type';
  readonly name: Scalars['String'];
  /** An array relationship */
  readonly other_rewards: ReadonlyArray<Other_Reward>;
  /** An aggregate relationship */
  readonly other_rewards_aggregate: Other_Reward_Aggregate;
};


/** columns and relationships of "other_reward_type" */
export type Other_Reward_TypeOther_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


/** columns and relationships of "other_reward_type" */
export type Other_Reward_TypeOther_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};

/** aggregated selection of "other_reward_type" */
export type Other_Reward_Type_Aggregate = {
  readonly __typename?: 'other_reward_type_aggregate';
  readonly aggregate?: Maybe<Other_Reward_Type_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Other_Reward_Type>;
};

/** aggregate fields of "other_reward_type" */
export type Other_Reward_Type_Aggregate_Fields = {
  readonly __typename?: 'other_reward_type_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Other_Reward_Type_Max_Fields>;
  readonly min?: Maybe<Other_Reward_Type_Min_Fields>;
};


/** aggregate fields of "other_reward_type" */
export type Other_Reward_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Other_Reward_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "other_reward_type". All fields are combined with a logical 'AND'. */
export type Other_Reward_Type_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Other_Reward_Type_Bool_Exp>>;
  readonly _not?: Maybe<Other_Reward_Type_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Other_Reward_Type_Bool_Exp>>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly other_rewards?: Maybe<Other_Reward_Bool_Exp>;
};

/** unique or primary key constraints on table "other_reward_type" */
export enum Other_Reward_Type_Constraint {
  /** unique or primary key constraint */
  Pk_9f214b3bb8c9e8846f9e43da258 = 'PK_9f214b3bb8c9e8846f9e43da258'
}

/** input type for inserting data into table "other_reward_type" */
export type Other_Reward_Type_Insert_Input = {
  readonly name?: Maybe<Scalars['String']>;
  readonly other_rewards?: Maybe<Other_Reward_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Other_Reward_Type_Max_Fields = {
  readonly __typename?: 'other_reward_type_max_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Other_Reward_Type_Min_Fields = {
  readonly __typename?: 'other_reward_type_min_fields';
  readonly name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "other_reward_type" */
export type Other_Reward_Type_Mutation_Response = {
  readonly __typename?: 'other_reward_type_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Other_Reward_Type>;
};

/** input type for inserting object relation for remote table "other_reward_type" */
export type Other_Reward_Type_Obj_Rel_Insert_Input = {
  readonly data: Other_Reward_Type_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Other_Reward_Type_On_Conflict>;
};

/** on conflict condition type for table "other_reward_type" */
export type Other_Reward_Type_On_Conflict = {
  readonly constraint: Other_Reward_Type_Constraint;
  readonly update_columns?: ReadonlyArray<Other_Reward_Type_Update_Column>;
  readonly where?: Maybe<Other_Reward_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "other_reward_type". */
export type Other_Reward_Type_Order_By = {
  readonly name?: Maybe<Order_By>;
  readonly other_rewards_aggregate?: Maybe<Other_Reward_Aggregate_Order_By>;
};

/** primary key columns input for table: other_reward_type */
export type Other_Reward_Type_Pk_Columns_Input = {
  readonly name: Scalars['String'];
};

/** select columns of table "other_reward_type" */
export enum Other_Reward_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "other_reward_type" */
export type Other_Reward_Type_Set_Input = {
  readonly name?: Maybe<Scalars['String']>;
};

/** update columns of table "other_reward_type" */
export enum Other_Reward_Type_Update_Column {
  /** column name */
  Name = 'name'
}

/** update columns of table "other_reward" */
export enum Other_Reward_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  Units = 'units'
}

/** aggregate var_pop on columns */
export type Other_Reward_Var_Pop_Fields = {
  readonly __typename?: 'other_reward_var_pop_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "other_reward" */
export type Other_Reward_Var_Pop_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Other_Reward_Var_Samp_Fields = {
  readonly __typename?: 'other_reward_var_samp_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "other_reward" */
export type Other_Reward_Var_Samp_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Other_Reward_Variance_Fields = {
  readonly __typename?: 'other_reward_variance_fields';
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "other_reward" */
export type Other_Reward_Variance_Order_By = {
  readonly units?: Maybe<Order_By>;
};

/** columns and relationships of "other_unit_rewards" */
export type Other_Unit_Rewards = {
  readonly __typename?: 'other_unit_rewards';
  /** An object relationship */
  readonly course_reward?: Maybe<Course_Rewards>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id: Scalars['bigint'];
  readonly other_unit_type: Scalars['String'];
  readonly units: Scalars['Int'];
};

/** aggregated selection of "other_unit_rewards" */
export type Other_Unit_Rewards_Aggregate = {
  readonly __typename?: 'other_unit_rewards_aggregate';
  readonly aggregate?: Maybe<Other_Unit_Rewards_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Other_Unit_Rewards>;
};

/** aggregate fields of "other_unit_rewards" */
export type Other_Unit_Rewards_Aggregate_Fields = {
  readonly __typename?: 'other_unit_rewards_aggregate_fields';
  readonly avg?: Maybe<Other_Unit_Rewards_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Other_Unit_Rewards_Max_Fields>;
  readonly min?: Maybe<Other_Unit_Rewards_Min_Fields>;
  readonly stddev?: Maybe<Other_Unit_Rewards_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Other_Unit_Rewards_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Other_Unit_Rewards_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Other_Unit_Rewards_Sum_Fields>;
  readonly var_pop?: Maybe<Other_Unit_Rewards_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Other_Unit_Rewards_Var_Samp_Fields>;
  readonly variance?: Maybe<Other_Unit_Rewards_Variance_Fields>;
};


/** aggregate fields of "other_unit_rewards" */
export type Other_Unit_Rewards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "other_unit_rewards" */
export type Other_Unit_Rewards_Aggregate_Order_By = {
  readonly avg?: Maybe<Other_Unit_Rewards_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Other_Unit_Rewards_Max_Order_By>;
  readonly min?: Maybe<Other_Unit_Rewards_Min_Order_By>;
  readonly stddev?: Maybe<Other_Unit_Rewards_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Other_Unit_Rewards_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Other_Unit_Rewards_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Other_Unit_Rewards_Sum_Order_By>;
  readonly var_pop?: Maybe<Other_Unit_Rewards_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Other_Unit_Rewards_Var_Samp_Order_By>;
  readonly variance?: Maybe<Other_Unit_Rewards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "other_unit_rewards" */
export type Other_Unit_Rewards_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Other_Unit_Rewards_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Other_Unit_Rewards_On_Conflict>;
};

/** aggregate avg on columns */
export type Other_Unit_Rewards_Avg_Fields = {
  readonly __typename?: 'other_unit_rewards_avg_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Avg_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "other_unit_rewards". All fields are combined with a logical 'AND'. */
export type Other_Unit_Rewards_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Other_Unit_Rewards_Bool_Exp>>;
  readonly _not?: Maybe<Other_Unit_Rewards_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Other_Unit_Rewards_Bool_Exp>>;
  readonly course_reward?: Maybe<Course_Rewards_Bool_Exp>;
  readonly course_reward_id?: Maybe<Bigint_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly other_unit_type?: Maybe<String_Comparison_Exp>;
  readonly units?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "other_unit_rewards" */
export enum Other_Unit_Rewards_Constraint {
  /** unique or primary key constraint */
  OtherUnitRewardsPkey = 'other_unit_rewards_pkey'
}

/** input type for incrementing numeric columns in table "other_unit_rewards" */
export type Other_Unit_Rewards_Inc_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "other_unit_rewards" */
export type Other_Unit_Rewards_Insert_Input = {
  readonly course_reward?: Maybe<Course_Rewards_Obj_Rel_Insert_Input>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly other_unit_type?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Other_Unit_Rewards_Max_Fields = {
  readonly __typename?: 'other_unit_rewards_max_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly other_unit_type?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Max_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly other_unit_type?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Other_Unit_Rewards_Min_Fields = {
  readonly __typename?: 'other_unit_rewards_min_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly other_unit_type?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Min_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly other_unit_type?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** response of any mutation on the table "other_unit_rewards" */
export type Other_Unit_Rewards_Mutation_Response = {
  readonly __typename?: 'other_unit_rewards_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Other_Unit_Rewards>;
};

/** on conflict condition type for table "other_unit_rewards" */
export type Other_Unit_Rewards_On_Conflict = {
  readonly constraint: Other_Unit_Rewards_Constraint;
  readonly update_columns?: ReadonlyArray<Other_Unit_Rewards_Update_Column>;
  readonly where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "other_unit_rewards". */
export type Other_Unit_Rewards_Order_By = {
  readonly course_reward?: Maybe<Course_Rewards_Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly other_unit_type?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** primary key columns input for table: other_unit_rewards */
export type Other_Unit_Rewards_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "other_unit_rewards" */
export enum Other_Unit_Rewards_Select_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Id = 'id',
  /** column name */
  OtherUnitType = 'other_unit_type',
  /** column name */
  Units = 'units'
}

/** input type for updating data in table "other_unit_rewards" */
export type Other_Unit_Rewards_Set_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly other_unit_type?: Maybe<Scalars['String']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Other_Unit_Rewards_Stddev_Fields = {
  readonly __typename?: 'other_unit_rewards_stddev_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Stddev_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Other_Unit_Rewards_Stddev_Pop_Fields = {
  readonly __typename?: 'other_unit_rewards_stddev_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Stddev_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Other_Unit_Rewards_Stddev_Samp_Fields = {
  readonly __typename?: 'other_unit_rewards_stddev_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Stddev_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Other_Unit_Rewards_Sum_Fields = {
  readonly __typename?: 'other_unit_rewards_sum_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly units?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Sum_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** update columns of table "other_unit_rewards" */
export enum Other_Unit_Rewards_Update_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Id = 'id',
  /** column name */
  OtherUnitType = 'other_unit_type',
  /** column name */
  Units = 'units'
}

/** aggregate var_pop on columns */
export type Other_Unit_Rewards_Var_Pop_Fields = {
  readonly __typename?: 'other_unit_rewards_var_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Var_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Other_Unit_Rewards_Var_Samp_Fields = {
  readonly __typename?: 'other_unit_rewards_var_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Var_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Other_Unit_Rewards_Variance_Fields = {
  readonly __typename?: 'other_unit_rewards_variance_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "other_unit_rewards" */
export type Other_Unit_Rewards_Variance_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly units?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
  readonly __typename?: 'product';
  readonly createdAt: Scalars['timestamp'];
  /** An array relationship */
  readonly discounts: ReadonlyArray<Discount>;
  /** An aggregate relationship */
  readonly discounts_aggregate: Discount_Aggregate;
  readonly id: Scalars['uuid'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Order>;
  /** An aggregate relationship */
  readonly orders_aggregate: Order_Aggregate;
  readonly price: Scalars['Int'];
  readonly productEntityId: Scalars['String'];
  readonly type: Scalars['String'];
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "product" */
export type ProductDiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductDiscounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductOrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductOrders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  readonly __typename?: 'product_aggregate';
  readonly aggregate?: Maybe<Product_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  readonly __typename?: 'product_aggregate_fields';
  readonly avg?: Maybe<Product_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Product_Max_Fields>;
  readonly min?: Maybe<Product_Min_Fields>;
  readonly stddev?: Maybe<Product_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Product_Sum_Fields>;
  readonly var_pop?: Maybe<Product_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Product_Var_Samp_Fields>;
  readonly variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  readonly __typename?: 'product_avg_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Product_Bool_Exp>>;
  readonly _not?: Maybe<Product_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Product_Bool_Exp>>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly discounts?: Maybe<Discount_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly orders?: Maybe<Order_Bool_Exp>;
  readonly price?: Maybe<Int_Comparison_Exp>;
  readonly productEntityId?: Maybe<String_Comparison_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  PkBebc9158e480b949565b4dc7a82 = 'PK_bebc9158e480b949565b4dc7a82'
}

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  readonly price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly discounts?: Maybe<Discount_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly productEntityId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  readonly __typename?: 'product_max_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly productEntityId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  readonly __typename?: 'product_min_fields';
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly productEntityId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  readonly __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  readonly data: Product_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  readonly constraint: Product_Constraint;
  readonly update_columns?: ReadonlyArray<Product_Update_Column>;
  readonly where?: Maybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  readonly createdAt?: Maybe<Order_By>;
  readonly discounts_aggregate?: Maybe<Discount_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  readonly price?: Maybe<Order_By>;
  readonly productEntityId?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductEntityId = 'productEntityId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly price?: Maybe<Scalars['Int']>;
  readonly productEntityId?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  readonly __typename?: 'product_stddev_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  readonly __typename?: 'product_stddev_pop_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  readonly __typename?: 'product_stddev_samp_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  readonly __typename?: 'product_sum_fields';
  readonly price?: Maybe<Scalars['Int']>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductEntityId = 'productEntityId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  readonly __typename?: 'product_var_pop_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  readonly __typename?: 'product_var_samp_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  readonly __typename?: 'product_variance_fields';
  readonly price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "provider" */
export type Provider = {
  readonly __typename?: 'provider';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly certificate_signatories: ReadonlyArray<Certificate_Signatory>;
  /** An aggregate relationship */
  readonly certificate_signatories_aggregate: Certificate_Signatory_Aggregate;
  /** An array relationship */
  readonly certificate_templates: ReadonlyArray<Certificate_Templates>;
  /** An aggregate relationship */
  readonly certificate_templates_aggregate: Certificate_Templates_Aggregate;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly events: ReadonlyArray<Event>;
  /** An aggregate relationship */
  readonly events_aggregate: Event_Aggregate;
  /** An array relationship */
  readonly forms: ReadonlyArray<Form>;
  /** An aggregate relationship */
  readonly forms_aggregate: Form_Aggregate;
  readonly id: Scalars['uuid'];
  /** An array relationship */
  readonly images: ReadonlyArray<Images>;
  /** An aggregate relationship */
  readonly images_aggregate: Images_Aggregate;
  readonly isArchived: Scalars['Boolean'];
  readonly isLightTheme: Scalars['Boolean'];
  readonly lyonApiKey?: Maybe<Scalars['String']>;
  readonly lyonCommunity?: Maybe<Scalars['String']>;
  readonly lyonMerchantId?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly prcAccreditationNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly provider_dragonpay_credential: Provider_Dragonpay_Credentials;
  /** An array relationship */
  readonly provider_frequently_used_industries_industries: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
  /** An aggregate relationship */
  readonly provider_frequently_used_industries_industries_aggregate: Provider_Frequently_Used_Industries_Industry_Aggregate;
  /** An array relationship */
  readonly provider_googles: ReadonlyArray<Provider_Google>;
  /** An aggregate relationship */
  readonly provider_googles_aggregate: Provider_Google_Aggregate;
  /** An array relationship */
  readonly provider_managing_users_users: ReadonlyArray<Provider_Managing_Users_User>;
  /** An aggregate relationship */
  readonly provider_managing_users_users_aggregate: Provider_Managing_Users_User_Aggregate;
  /** An array relationship */
  readonly provider_subscriptions: ReadonlyArray<Provider_Subscriptions>;
  /** An aggregate relationship */
  readonly provider_subscriptions_aggregate: Provider_Subscriptions_Aggregate;
  /** An array relationship */
  readonly provider_zooms: ReadonlyArray<Provider_Zoom>;
  /** An aggregate relationship */
  readonly provider_zooms_aggregate: Provider_Zoom_Aggregate;
  readonly updatedAt: Scalars['timestamp'];
  /** An array relationship */
  readonly user_provider_subscriptions_providers: ReadonlyArray<User_Provider_Subscriptions_Provider>;
  /** An aggregate relationship */
  readonly user_provider_subscriptions_providers_aggregate: User_Provider_Subscriptions_Provider_Aggregate;
  readonly website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "provider" */
export type ProviderCertificate_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderCertificate_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderCertificate_TemplatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderCertificate_Templates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderEventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderEvents_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderFormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderForms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderImagesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderImages_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Frequently_Used_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Frequently_Used_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_GooglesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Googles_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Managing_Users_UsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Managing_Users_Users_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_ZoomsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Zooms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderUser_Provider_Subscriptions_ProvidersArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderUser_Provider_Subscriptions_Providers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};

/** aggregated selection of "provider" */
export type Provider_Aggregate = {
  readonly __typename?: 'provider_aggregate';
  readonly aggregate?: Maybe<Provider_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider>;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_Fields = {
  readonly __typename?: 'provider_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Max_Fields>;
  readonly min?: Maybe<Provider_Min_Fields>;
};


/** aggregate fields of "provider" */
export type Provider_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "provider_application" */
export type Provider_Application = {
  readonly __typename?: 'provider_application';
  readonly address: Scalars['String'];
  readonly contactNumber: Scalars['String'];
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  readonly email: Scalars['String'];
  readonly eventsAveragePrice: Scalars['Int'];
  readonly eventsPerMonth: Scalars['Int'];
  readonly id: Scalars['uuid'];
  readonly name: Scalars['String'];
  /** An array relationship */
  readonly provider_application_industries_industries: ReadonlyArray<Provider_Application_Industries_Industry>;
  /** An aggregate relationship */
  readonly provider_application_industries_industries_aggregate: Provider_Application_Industries_Industry_Aggregate;
  readonly status: Scalars['String'];
  readonly submitterId?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user?: Maybe<User>;
};


/** columns and relationships of "provider_application" */
export type Provider_ApplicationProvider_Application_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "provider_application" */
export type Provider_ApplicationProvider_Application_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};

/** aggregated selection of "provider_application" */
export type Provider_Application_Aggregate = {
  readonly __typename?: 'provider_application_aggregate';
  readonly aggregate?: Maybe<Provider_Application_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Application>;
};

/** aggregate fields of "provider_application" */
export type Provider_Application_Aggregate_Fields = {
  readonly __typename?: 'provider_application_aggregate_fields';
  readonly avg?: Maybe<Provider_Application_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Application_Max_Fields>;
  readonly min?: Maybe<Provider_Application_Min_Fields>;
  readonly stddev?: Maybe<Provider_Application_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Provider_Application_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Provider_Application_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Provider_Application_Sum_Fields>;
  readonly var_pop?: Maybe<Provider_Application_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Provider_Application_Var_Samp_Fields>;
  readonly variance?: Maybe<Provider_Application_Variance_Fields>;
};


/** aggregate fields of "provider_application" */
export type Provider_Application_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_application" */
export type Provider_Application_Aggregate_Order_By = {
  readonly avg?: Maybe<Provider_Application_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Application_Max_Order_By>;
  readonly min?: Maybe<Provider_Application_Min_Order_By>;
  readonly stddev?: Maybe<Provider_Application_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Provider_Application_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Provider_Application_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Provider_Application_Sum_Order_By>;
  readonly var_pop?: Maybe<Provider_Application_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Provider_Application_Var_Samp_Order_By>;
  readonly variance?: Maybe<Provider_Application_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "provider_application" */
export type Provider_Application_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Application_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Application_On_Conflict>;
};

/** aggregate avg on columns */
export type Provider_Application_Avg_Fields = {
  readonly __typename?: 'provider_application_avg_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "provider_application" */
export type Provider_Application_Avg_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "provider_application". All fields are combined with a logical 'AND'. */
export type Provider_Application_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Application_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Application_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Application_Bool_Exp>>;
  readonly address?: Maybe<String_Comparison_Exp>;
  readonly contactNumber?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly eventsAveragePrice?: Maybe<Int_Comparison_Exp>;
  readonly eventsPerMonth?: Maybe<Int_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly provider_application_industries_industries?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
  readonly status?: Maybe<String_Comparison_Exp>;
  readonly submitterId?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "provider_application" */
export enum Provider_Application_Constraint {
  /** unique or primary key constraint */
  Pk_69ee8fda2256bfc7b307ad4a151 = 'PK_69ee8fda2256bfc7b307ad4a151'
}

/** input type for incrementing numeric columns in table "provider_application" */
export type Provider_Application_Inc_Input = {
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry = {
  readonly __typename?: 'provider_application_industries_industry';
  /** An object relationship */
  readonly industry: Industry;
  readonly industryName: Scalars['String'];
  readonly providerApplicationId: Scalars['uuid'];
  /** An object relationship */
  readonly provider_application: Provider_Application;
};

/** aggregated selection of "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Aggregate = {
  readonly __typename?: 'provider_application_industries_industry_aggregate';
  readonly aggregate?: Maybe<Provider_Application_Industries_Industry_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Application_Industries_Industry>;
};

/** aggregate fields of "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Aggregate_Fields = {
  readonly __typename?: 'provider_application_industries_industry_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Application_Industries_Industry_Max_Fields>;
  readonly min?: Maybe<Provider_Application_Industries_Industry_Min_Fields>;
};


/** aggregate fields of "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Application_Industries_Industry_Max_Order_By>;
  readonly min?: Maybe<Provider_Application_Industries_Industry_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Application_Industries_Industry_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Application_Industries_Industry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_application_industries_industry". All fields are combined with a logical 'AND'. */
export type Provider_Application_Industries_Industry_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Bool_Exp>>;
  readonly industry?: Maybe<Industry_Bool_Exp>;
  readonly industryName?: Maybe<String_Comparison_Exp>;
  readonly providerApplicationId?: Maybe<Uuid_Comparison_Exp>;
  readonly provider_application?: Maybe<Provider_Application_Bool_Exp>;
};

/** unique or primary key constraints on table "provider_application_industries_industry" */
export enum Provider_Application_Industries_Industry_Constraint {
  /** unique or primary key constraint */
  Pk_3778590848b32318381fd71974e = 'PK_3778590848b32318381fd71974e'
}

/** input type for inserting data into table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Insert_Input = {
  readonly industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerApplicationId?: Maybe<Scalars['uuid']>;
  readonly provider_application?: Maybe<Provider_Application_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Provider_Application_Industries_Industry_Max_Fields = {
  readonly __typename?: 'provider_application_industries_industry_max_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerApplicationId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Max_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly providerApplicationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Application_Industries_Industry_Min_Fields = {
  readonly __typename?: 'provider_application_industries_industry_min_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerApplicationId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Min_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly providerApplicationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Mutation_Response = {
  readonly __typename?: 'provider_application_industries_industry_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Application_Industries_Industry>;
};

/** on conflict condition type for table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_On_Conflict = {
  readonly constraint: Provider_Application_Industries_Industry_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Application_Industries_Industry_Update_Column>;
  readonly where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_application_industries_industry". */
export type Provider_Application_Industries_Industry_Order_By = {
  readonly industry?: Maybe<Industry_Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly providerApplicationId?: Maybe<Order_By>;
  readonly provider_application?: Maybe<Provider_Application_Order_By>;
};

/** primary key columns input for table: provider_application_industries_industry */
export type Provider_Application_Industries_Industry_Pk_Columns_Input = {
  readonly industryName: Scalars['String'];
  readonly providerApplicationId: Scalars['uuid'];
};

/** select columns of table "provider_application_industries_industry" */
export enum Provider_Application_Industries_Industry_Select_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  ProviderApplicationId = 'providerApplicationId'
}

/** input type for updating data in table "provider_application_industries_industry" */
export type Provider_Application_Industries_Industry_Set_Input = {
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerApplicationId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "provider_application_industries_industry" */
export enum Provider_Application_Industries_Industry_Update_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  ProviderApplicationId = 'providerApplicationId'
}

/** input type for inserting data into table "provider_application" */
export type Provider_Application_Insert_Input = {
  readonly address?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly provider_application_industries_industries?: Maybe<Provider_Application_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly status?: Maybe<Scalars['String']>;
  readonly submitterId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Provider_Application_Max_Fields = {
  readonly __typename?: 'provider_application_max_fields';
  readonly address?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly submitterId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "provider_application" */
export type Provider_Application_Max_Order_By = {
  readonly address?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly submitterId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Application_Min_Fields = {
  readonly __typename?: 'provider_application_min_fields';
  readonly address?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly submitterId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "provider_application" */
export type Provider_Application_Min_Order_By = {
  readonly address?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly submitterId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_application" */
export type Provider_Application_Mutation_Response = {
  readonly __typename?: 'provider_application_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Application>;
};

/** input type for inserting object relation for remote table "provider_application" */
export type Provider_Application_Obj_Rel_Insert_Input = {
  readonly data: Provider_Application_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Application_On_Conflict>;
};

/** on conflict condition type for table "provider_application" */
export type Provider_Application_On_Conflict = {
  readonly constraint: Provider_Application_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Application_Update_Column>;
  readonly where?: Maybe<Provider_Application_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_application". */
export type Provider_Application_Order_By = {
  readonly address?: Maybe<Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly provider_application_industries_industries_aggregate?: Maybe<Provider_Application_Industries_Industry_Aggregate_Order_By>;
  readonly status?: Maybe<Order_By>;
  readonly submitterId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: provider_application */
export type Provider_Application_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider_application" */
export enum Provider_Application_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EventsAveragePrice = 'eventsAveragePrice',
  /** column name */
  EventsPerMonth = 'eventsPerMonth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitterId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "provider_application" */
export type Provider_Application_Set_Input = {
  readonly address?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly submitterId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Provider_Application_Stddev_Fields = {
  readonly __typename?: 'provider_application_stddev_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "provider_application" */
export type Provider_Application_Stddev_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Provider_Application_Stddev_Pop_Fields = {
  readonly __typename?: 'provider_application_stddev_pop_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "provider_application" */
export type Provider_Application_Stddev_Pop_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Provider_Application_Stddev_Samp_Fields = {
  readonly __typename?: 'provider_application_stddev_samp_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "provider_application" */
export type Provider_Application_Stddev_Samp_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Provider_Application_Sum_Fields = {
  readonly __typename?: 'provider_application_sum_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Int']>;
  readonly eventsPerMonth?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "provider_application" */
export type Provider_Application_Sum_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** update columns of table "provider_application" */
export enum Provider_Application_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EventsAveragePrice = 'eventsAveragePrice',
  /** column name */
  EventsPerMonth = 'eventsPerMonth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitterId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Provider_Application_Var_Pop_Fields = {
  readonly __typename?: 'provider_application_var_pop_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "provider_application" */
export type Provider_Application_Var_Pop_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Provider_Application_Var_Samp_Fields = {
  readonly __typename?: 'provider_application_var_samp_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "provider_application" */
export type Provider_Application_Var_Samp_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Provider_Application_Variance_Fields = {
  readonly __typename?: 'provider_application_variance_fields';
  readonly eventsAveragePrice?: Maybe<Scalars['Float']>;
  readonly eventsPerMonth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "provider_application" */
export type Provider_Application_Variance_Order_By = {
  readonly eventsAveragePrice?: Maybe<Order_By>;
  readonly eventsPerMonth?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "provider". All fields are combined with a logical 'AND'. */
export type Provider_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Bool_Exp>>;
  readonly aboutVideoUuid?: Maybe<String_Comparison_Exp>;
  readonly address?: Maybe<String_Comparison_Exp>;
  readonly certificateLogoUuid?: Maybe<String_Comparison_Exp>;
  readonly certificate_signatories?: Maybe<Certificate_Signatory_Bool_Exp>;
  readonly certificate_templates?: Maybe<Certificate_Templates_Bool_Exp>;
  readonly contactNumber?: Maybe<String_Comparison_Exp>;
  readonly coverPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly displayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly events?: Maybe<Event_Bool_Exp>;
  readonly forms?: Maybe<Form_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly images?: Maybe<Images_Bool_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly isLightTheme?: Maybe<Boolean_Comparison_Exp>;
  readonly lyonApiKey?: Maybe<String_Comparison_Exp>;
  readonly lyonCommunity?: Maybe<String_Comparison_Exp>;
  readonly lyonMerchantId?: Maybe<String_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly prcAccreditationNumber?: Maybe<String_Comparison_Exp>;
  readonly provider_dragonpay_credential?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
  readonly provider_frequently_used_industries_industries?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
  readonly provider_googles?: Maybe<Provider_Google_Bool_Exp>;
  readonly provider_managing_users_users?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
  readonly provider_subscriptions?: Maybe<Provider_Subscriptions_Bool_Exp>;
  readonly provider_zooms?: Maybe<Provider_Zoom_Bool_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user_provider_subscriptions_providers?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
  readonly website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider" */
export enum Provider_Constraint {
  /** unique or primary key constraint */
  Pk_6ab2f66d8987bf1bfdd6136a2d5 = 'PK_6ab2f66d8987bf1bfdd6136a2d5'
}

/** columns and relationships of "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials = {
  readonly __typename?: 'provider_dragonpay_credentials';
  readonly created_at: Scalars['timestamptz'];
  readonly id: Scalars['uuid'];
  readonly password: Scalars['String'];
  /** An object relationship */
  readonly provider: Provider;
  readonly provider_id: Scalars['uuid'];
  readonly updated_at: Scalars['timestamptz'];
  readonly username: Scalars['String'];
};

/** aggregated selection of "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Aggregate = {
  readonly __typename?: 'provider_dragonpay_credentials_aggregate';
  readonly aggregate?: Maybe<Provider_Dragonpay_Credentials_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Dragonpay_Credentials>;
};

/** aggregate fields of "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Aggregate_Fields = {
  readonly __typename?: 'provider_dragonpay_credentials_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Dragonpay_Credentials_Max_Fields>;
  readonly min?: Maybe<Provider_Dragonpay_Credentials_Min_Fields>;
};


/** aggregate fields of "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "provider_dragonpay_credentials". All fields are combined with a logical 'AND'. */
export type Provider_Dragonpay_Credentials_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Bool_Exp>>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly password?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_dragonpay_credentials" */
export enum Provider_Dragonpay_Credentials_Constraint {
  /** unique or primary key constraint */
  ProviderDragonpayCredentialsPkey = 'provider_dragonpay_credentials_pkey',
  /** unique or primary key constraint */
  ProviderDragonpayCredentialsProviderIdKey = 'provider_dragonpay_credentials_provider_id_key'
}

/** input type for inserting data into table "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Insert_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly username?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Dragonpay_Credentials_Max_Fields = {
  readonly __typename?: 'provider_dragonpay_credentials_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provider_Dragonpay_Credentials_Min_Fields = {
  readonly __typename?: 'provider_dragonpay_credentials_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Mutation_Response = {
  readonly __typename?: 'provider_dragonpay_credentials_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Dragonpay_Credentials>;
};

/** input type for inserting object relation for remote table "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Obj_Rel_Insert_Input = {
  readonly data: Provider_Dragonpay_Credentials_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Dragonpay_Credentials_On_Conflict>;
};

/** on conflict condition type for table "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_On_Conflict = {
  readonly constraint: Provider_Dragonpay_Credentials_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Dragonpay_Credentials_Update_Column>;
  readonly where?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_dragonpay_credentials". */
export type Provider_Dragonpay_Credentials_Order_By = {
  readonly created_at?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly password?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
  readonly username?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_dragonpay_credentials */
export type Provider_Dragonpay_Credentials_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider_dragonpay_credentials" */
export enum Provider_Dragonpay_Credentials_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "provider_dragonpay_credentials" */
export type Provider_Dragonpay_Credentials_Set_Input = {
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly password?: Maybe<Scalars['String']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
  readonly username?: Maybe<Scalars['String']>;
};

/** update columns of table "provider_dragonpay_credentials" */
export enum Provider_Dragonpay_Credentials_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** columns and relationships of "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry = {
  readonly __typename?: 'provider_frequently_used_industries_industry';
  /** An object relationship */
  readonly industry: Industry;
  readonly industryName: Scalars['String'];
  /** An object relationship */
  readonly provider: Provider;
  readonly providerId: Scalars['uuid'];
};

/** aggregated selection of "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Aggregate = {
  readonly __typename?: 'provider_frequently_used_industries_industry_aggregate';
  readonly aggregate?: Maybe<Provider_Frequently_Used_Industries_Industry_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
};

/** aggregate fields of "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Aggregate_Fields = {
  readonly __typename?: 'provider_frequently_used_industries_industry_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Frequently_Used_Industries_Industry_Max_Fields>;
  readonly min?: Maybe<Provider_Frequently_Used_Industries_Industry_Min_Fields>;
};


/** aggregate fields of "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Frequently_Used_Industries_Industry_Max_Order_By>;
  readonly min?: Maybe<Provider_Frequently_Used_Industries_Industry_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Frequently_Used_Industries_Industry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_frequently_used_industries_industry". All fields are combined with a logical 'AND'. */
export type Provider_Frequently_Used_Industries_Industry_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Bool_Exp>>;
  readonly industry?: Maybe<Industry_Bool_Exp>;
  readonly industryName?: Maybe<String_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_frequently_used_industries_industry" */
export enum Provider_Frequently_Used_Industries_Industry_Constraint {
  /** unique or primary key constraint */
  PkFef2dfe7d6ebd348f186ce5c90b = 'PK_fef2dfe7d6ebd348f186ce5c90b'
}

/** input type for inserting data into table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Insert_Input = {
  readonly industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Provider_Frequently_Used_Industries_Industry_Max_Fields = {
  readonly __typename?: 'provider_frequently_used_industries_industry_max_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Max_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Frequently_Used_Industries_Industry_Min_Fields = {
  readonly __typename?: 'provider_frequently_used_industries_industry_min_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Min_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Mutation_Response = {
  readonly __typename?: 'provider_frequently_used_industries_industry_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
};

/** on conflict condition type for table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_On_Conflict = {
  readonly constraint: Provider_Frequently_Used_Industries_Industry_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Update_Column>;
  readonly where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_frequently_used_industries_industry". */
export type Provider_Frequently_Used_Industries_Industry_Order_By = {
  readonly industry?: Maybe<Industry_Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_frequently_used_industries_industry */
export type Provider_Frequently_Used_Industries_Industry_Pk_Columns_Input = {
  readonly industryName: Scalars['String'];
  readonly providerId: Scalars['uuid'];
};

/** select columns of table "provider_frequently_used_industries_industry" */
export enum Provider_Frequently_Used_Industries_Industry_Select_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  ProviderId = 'providerId'
}

/** input type for updating data in table "provider_frequently_used_industries_industry" */
export type Provider_Frequently_Used_Industries_Industry_Set_Input = {
  readonly industryName?: Maybe<Scalars['String']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "provider_frequently_used_industries_industry" */
export enum Provider_Frequently_Used_Industries_Industry_Update_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  ProviderId = 'providerId'
}

/** columns and relationships of "provider_google" */
export type Provider_Google = {
  readonly __typename?: 'provider_google';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['timestamp'];
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly provider?: Maybe<Provider>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "provider_google" */
export type Provider_Google_Aggregate = {
  readonly __typename?: 'provider_google_aggregate';
  readonly aggregate?: Maybe<Provider_Google_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Google>;
};

/** aggregate fields of "provider_google" */
export type Provider_Google_Aggregate_Fields = {
  readonly __typename?: 'provider_google_aggregate_fields';
  readonly avg?: Maybe<Provider_Google_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Google_Max_Fields>;
  readonly min?: Maybe<Provider_Google_Min_Fields>;
  readonly stddev?: Maybe<Provider_Google_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Provider_Google_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Provider_Google_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Provider_Google_Sum_Fields>;
  readonly var_pop?: Maybe<Provider_Google_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Provider_Google_Var_Samp_Fields>;
  readonly variance?: Maybe<Provider_Google_Variance_Fields>;
};


/** aggregate fields of "provider_google" */
export type Provider_Google_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_google" */
export type Provider_Google_Aggregate_Order_By = {
  readonly avg?: Maybe<Provider_Google_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Google_Max_Order_By>;
  readonly min?: Maybe<Provider_Google_Min_Order_By>;
  readonly stddev?: Maybe<Provider_Google_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Provider_Google_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Provider_Google_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Provider_Google_Sum_Order_By>;
  readonly var_pop?: Maybe<Provider_Google_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Provider_Google_Var_Samp_Order_By>;
  readonly variance?: Maybe<Provider_Google_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "provider_google" */
export type Provider_Google_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Google_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Google_On_Conflict>;
};

/** aggregate avg on columns */
export type Provider_Google_Avg_Fields = {
  readonly __typename?: 'provider_google_avg_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "provider_google" */
export type Provider_Google_Avg_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "provider_google". All fields are combined with a logical 'AND'. */
export type Provider_Google_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Google_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Google_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Google_Bool_Exp>>;
  readonly accessToken?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly expiresIn?: Maybe<Int_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly refreshToken?: Maybe<String_Comparison_Exp>;
  readonly scope?: Maybe<String_Comparison_Exp>;
  readonly tokenType?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_google" */
export enum Provider_Google_Constraint {
  /** unique or primary key constraint */
  ProviderGooglePkey = 'provider_google_pkey'
}

/** input type for incrementing numeric columns in table "provider_google" */
export type Provider_Google_Inc_Input = {
  readonly expiresIn?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "provider_google" */
export type Provider_Google_Insert_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Provider_Google_Max_Fields = {
  readonly __typename?: 'provider_google_max_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "provider_google" */
export type Provider_Google_Max_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Google_Min_Fields = {
  readonly __typename?: 'provider_google_min_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "provider_google" */
export type Provider_Google_Min_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_google" */
export type Provider_Google_Mutation_Response = {
  readonly __typename?: 'provider_google_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Google>;
};

/** on conflict condition type for table "provider_google" */
export type Provider_Google_On_Conflict = {
  readonly constraint: Provider_Google_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Google_Update_Column>;
  readonly where?: Maybe<Provider_Google_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_google". */
export type Provider_Google_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly expiresIn?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly scope?: Maybe<Order_By>;
  readonly tokenType?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_google */
export type Provider_Google_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider_google" */
export enum Provider_Google_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  TokenType = 'tokenType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "provider_google" */
export type Provider_Google_Set_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly expiresIn?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly scope?: Maybe<Scalars['String']>;
  readonly tokenType?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Provider_Google_Stddev_Fields = {
  readonly __typename?: 'provider_google_stddev_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "provider_google" */
export type Provider_Google_Stddev_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Provider_Google_Stddev_Pop_Fields = {
  readonly __typename?: 'provider_google_stddev_pop_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "provider_google" */
export type Provider_Google_Stddev_Pop_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Provider_Google_Stddev_Samp_Fields = {
  readonly __typename?: 'provider_google_stddev_samp_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "provider_google" */
export type Provider_Google_Stddev_Samp_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Provider_Google_Sum_Fields = {
  readonly __typename?: 'provider_google_sum_fields';
  readonly expiresIn?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "provider_google" */
export type Provider_Google_Sum_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** update columns of table "provider_google" */
export enum Provider_Google_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Scope = 'scope',
  /** column name */
  TokenType = 'tokenType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Provider_Google_Var_Pop_Fields = {
  readonly __typename?: 'provider_google_var_pop_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "provider_google" */
export type Provider_Google_Var_Pop_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Provider_Google_Var_Samp_Fields = {
  readonly __typename?: 'provider_google_var_samp_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "provider_google" */
export type Provider_Google_Var_Samp_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Provider_Google_Variance_Fields = {
  readonly __typename?: 'provider_google_variance_fields';
  readonly expiresIn?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "provider_google" */
export type Provider_Google_Variance_Order_By = {
  readonly expiresIn?: Maybe<Order_By>;
};

/** input type for inserting data into table "provider" */
export type Provider_Insert_Input = {
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly certificate_signatories?: Maybe<Certificate_Signatory_Arr_Rel_Insert_Input>;
  readonly certificate_templates?: Maybe<Certificate_Templates_Arr_Rel_Insert_Input>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly events?: Maybe<Event_Arr_Rel_Insert_Input>;
  readonly forms?: Maybe<Form_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly images?: Maybe<Images_Arr_Rel_Insert_Input>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly isLightTheme?: Maybe<Scalars['Boolean']>;
  readonly lyonApiKey?: Maybe<Scalars['String']>;
  readonly lyonCommunity?: Maybe<Scalars['String']>;
  readonly lyonMerchantId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly prcAccreditationNumber?: Maybe<Scalars['String']>;
  readonly provider_dragonpay_credential?: Maybe<Provider_Dragonpay_Credentials_Obj_Rel_Insert_Input>;
  readonly provider_frequently_used_industries_industries?: Maybe<Provider_Frequently_Used_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly provider_googles?: Maybe<Provider_Google_Arr_Rel_Insert_Input>;
  readonly provider_managing_users_users?: Maybe<Provider_Managing_Users_User_Arr_Rel_Insert_Input>;
  readonly provider_subscriptions?: Maybe<Provider_Subscriptions_Arr_Rel_Insert_Input>;
  readonly provider_zooms?: Maybe<Provider_Zoom_Arr_Rel_Insert_Input>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user_provider_subscriptions_providers?: Maybe<User_Provider_Subscriptions_Provider_Arr_Rel_Insert_Input>;
  readonly website?: Maybe<Scalars['String']>;
};

/** columns and relationships of "provider_managing_users_user" */
export type Provider_Managing_Users_User = {
  readonly __typename?: 'provider_managing_users_user';
  /** An object relationship */
  readonly provider: Provider;
  readonly providerId: Scalars['uuid'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};

/** aggregated selection of "provider_managing_users_user" */
export type Provider_Managing_Users_User_Aggregate = {
  readonly __typename?: 'provider_managing_users_user_aggregate';
  readonly aggregate?: Maybe<Provider_Managing_Users_User_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Managing_Users_User>;
};

/** aggregate fields of "provider_managing_users_user" */
export type Provider_Managing_Users_User_Aggregate_Fields = {
  readonly __typename?: 'provider_managing_users_user_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Managing_Users_User_Max_Fields>;
  readonly min?: Maybe<Provider_Managing_Users_User_Min_Fields>;
};


/** aggregate fields of "provider_managing_users_user" */
export type Provider_Managing_Users_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Managing_Users_User_Max_Order_By>;
  readonly min?: Maybe<Provider_Managing_Users_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Managing_Users_User_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Managing_Users_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_managing_users_user". All fields are combined with a logical 'AND'. */
export type Provider_Managing_Users_User_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Bool_Exp>>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_managing_users_user" */
export enum Provider_Managing_Users_User_Constraint {
  /** unique or primary key constraint */
  Pk_149337f54fa19d47f2574a93d4b = 'PK_149337f54fa19d47f2574a93d4b'
}

/** input type for inserting data into table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Insert_Input = {
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Managing_Users_User_Max_Fields = {
  readonly __typename?: 'provider_managing_users_user_max_fields';
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Max_Order_By = {
  readonly providerId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Managing_Users_User_Min_Fields = {
  readonly __typename?: 'provider_managing_users_user_min_fields';
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Min_Order_By = {
  readonly providerId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Mutation_Response = {
  readonly __typename?: 'provider_managing_users_user_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Managing_Users_User>;
};

/** on conflict condition type for table "provider_managing_users_user" */
export type Provider_Managing_Users_User_On_Conflict = {
  readonly constraint: Provider_Managing_Users_User_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Managing_Users_User_Update_Column>;
  readonly where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_managing_users_user". */
export type Provider_Managing_Users_User_Order_By = {
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_managing_users_user */
export type Provider_Managing_Users_User_Pk_Columns_Input = {
  readonly providerId: Scalars['uuid'];
  readonly userId: Scalars['String'];
};

/** select columns of table "provider_managing_users_user" */
export enum Provider_Managing_Users_User_Select_Column {
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "provider_managing_users_user" */
export type Provider_Managing_Users_User_Set_Input = {
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "provider_managing_users_user" */
export enum Provider_Managing_Users_User_Update_Column {
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UserId = 'userId'
}

/** aggregate max on columns */
export type Provider_Max_Fields = {
  readonly __typename?: 'provider_max_fields';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly lyonApiKey?: Maybe<Scalars['String']>;
  readonly lyonCommunity?: Maybe<Scalars['String']>;
  readonly lyonMerchantId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly prcAccreditationNumber?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provider_Min_Fields = {
  readonly __typename?: 'provider_min_fields';
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly lyonApiKey?: Maybe<Scalars['String']>;
  readonly lyonCommunity?: Maybe<Scalars['String']>;
  readonly lyonMerchantId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly prcAccreditationNumber?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provider" */
export type Provider_Mutation_Response = {
  readonly __typename?: 'provider_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider>;
};

/** input type for inserting object relation for remote table "provider" */
export type Provider_Obj_Rel_Insert_Input = {
  readonly data: Provider_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_On_Conflict>;
};

/** on conflict condition type for table "provider" */
export type Provider_On_Conflict = {
  readonly constraint: Provider_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Update_Column>;
  readonly where?: Maybe<Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "provider". */
export type Provider_Order_By = {
  readonly aboutVideoUuid?: Maybe<Order_By>;
  readonly address?: Maybe<Order_By>;
  readonly certificateLogoUuid?: Maybe<Order_By>;
  readonly certificate_signatories_aggregate?: Maybe<Certificate_Signatory_Aggregate_Order_By>;
  readonly certificate_templates_aggregate?: Maybe<Certificate_Templates_Aggregate_Order_By>;
  readonly contactNumber?: Maybe<Order_By>;
  readonly coverPhotoUuid?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly events_aggregate?: Maybe<Event_Aggregate_Order_By>;
  readonly forms_aggregate?: Maybe<Form_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly images_aggregate?: Maybe<Images_Aggregate_Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly isLightTheme?: Maybe<Order_By>;
  readonly lyonApiKey?: Maybe<Order_By>;
  readonly lyonCommunity?: Maybe<Order_By>;
  readonly lyonMerchantId?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly prcAccreditationNumber?: Maybe<Order_By>;
  readonly provider_dragonpay_credential?: Maybe<Provider_Dragonpay_Credentials_Order_By>;
  readonly provider_frequently_used_industries_industries_aggregate?: Maybe<Provider_Frequently_Used_Industries_Industry_Aggregate_Order_By>;
  readonly provider_googles_aggregate?: Maybe<Provider_Google_Aggregate_Order_By>;
  readonly provider_managing_users_users_aggregate?: Maybe<Provider_Managing_Users_User_Aggregate_Order_By>;
  readonly provider_subscriptions_aggregate?: Maybe<Provider_Subscriptions_Aggregate_Order_By>;
  readonly provider_zooms_aggregate?: Maybe<Provider_Zoom_Aggregate_Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user_provider_subscriptions_providers_aggregate?: Maybe<User_Provider_Subscriptions_Provider_Aggregate_Order_By>;
  readonly website?: Maybe<Order_By>;
};

/** primary key columns input for table: provider */
export type Provider_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider" */
export enum Provider_Select_Column {
  /** column name */
  AboutVideoUuid = 'aboutVideoUuid',
  /** column name */
  Address = 'address',
  /** column name */
  CertificateLogoUuid = 'certificateLogoUuid',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CoverPhotoUuid = 'coverPhotoUuid',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsLightTheme = 'isLightTheme',
  /** column name */
  LyonApiKey = 'lyonApiKey',
  /** column name */
  LyonCommunity = 'lyonCommunity',
  /** column name */
  LyonMerchantId = 'lyonMerchantId',
  /** column name */
  Name = 'name',
  /** column name */
  PrcAccreditationNumber = 'prcAccreditationNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "provider" */
export type Provider_Set_Input = {
  readonly aboutVideoUuid?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly certificateLogoUuid?: Maybe<Scalars['String']>;
  readonly contactNumber?: Maybe<Scalars['String']>;
  readonly coverPhotoUuid?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly isLightTheme?: Maybe<Scalars['Boolean']>;
  readonly lyonApiKey?: Maybe<Scalars['String']>;
  readonly lyonCommunity?: Maybe<Scalars['String']>;
  readonly lyonMerchantId?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly prcAccreditationNumber?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly website?: Maybe<Scalars['String']>;
};

/** columns and relationships of "provider_subscription_plan" */
export type Provider_Subscription_Plan = {
  readonly __typename?: 'provider_subscription_plan';
  /** An array relationship */
  readonly provider_subscriptions: ReadonlyArray<Provider_Subscriptions>;
  /** An aggregate relationship */
  readonly provider_subscriptions_aggregate: Provider_Subscriptions_Aggregate;
  readonly type: Scalars['String'];
};


/** columns and relationships of "provider_subscription_plan" */
export type Provider_Subscription_PlanProvider_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "provider_subscription_plan" */
export type Provider_Subscription_PlanProvider_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};

/** aggregated selection of "provider_subscription_plan" */
export type Provider_Subscription_Plan_Aggregate = {
  readonly __typename?: 'provider_subscription_plan_aggregate';
  readonly aggregate?: Maybe<Provider_Subscription_Plan_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Subscription_Plan>;
};

/** aggregate fields of "provider_subscription_plan" */
export type Provider_Subscription_Plan_Aggregate_Fields = {
  readonly __typename?: 'provider_subscription_plan_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Subscription_Plan_Max_Fields>;
  readonly min?: Maybe<Provider_Subscription_Plan_Min_Fields>;
};


/** aggregate fields of "provider_subscription_plan" */
export type Provider_Subscription_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "provider_subscription_plan". All fields are combined with a logical 'AND'. */
export type Provider_Subscription_Plan_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Bool_Exp>>;
  readonly provider_subscriptions?: Maybe<Provider_Subscriptions_Bool_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_subscription_plan" */
export enum Provider_Subscription_Plan_Constraint {
  /** unique or primary key constraint */
  ProviderSubscriptionPlanPkey1 = 'provider_subscription_plan_pkey1'
}

export enum Provider_Subscription_Plan_Enum {
  Enterprise = 'enterprise',
  Pro = 'pro',
  Starter = 'starter',
  Trial = 'trial'
}

/** Boolean expression to compare columns of type "provider_subscription_plan_enum". All fields are combined with logical 'AND'. */
export type Provider_Subscription_Plan_Enum_Comparison_Exp = {
  readonly _eq?: Maybe<Provider_Subscription_Plan_Enum>;
  readonly _in?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Enum>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _neq?: Maybe<Provider_Subscription_Plan_Enum>;
  readonly _nin?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Enum>>;
};

/** input type for inserting data into table "provider_subscription_plan" */
export type Provider_Subscription_Plan_Insert_Input = {
  readonly provider_subscriptions?: Maybe<Provider_Subscriptions_Arr_Rel_Insert_Input>;
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Subscription_Plan_Max_Fields = {
  readonly __typename?: 'provider_subscription_plan_max_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provider_Subscription_Plan_Min_Fields = {
  readonly __typename?: 'provider_subscription_plan_min_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provider_subscription_plan" */
export type Provider_Subscription_Plan_Mutation_Response = {
  readonly __typename?: 'provider_subscription_plan_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Subscription_Plan>;
};

/** input type for inserting object relation for remote table "provider_subscription_plan" */
export type Provider_Subscription_Plan_Obj_Rel_Insert_Input = {
  readonly data: Provider_Subscription_Plan_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Subscription_Plan_On_Conflict>;
};

/** on conflict condition type for table "provider_subscription_plan" */
export type Provider_Subscription_Plan_On_Conflict = {
  readonly constraint: Provider_Subscription_Plan_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Subscription_Plan_Update_Column>;
  readonly where?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_subscription_plan". */
export type Provider_Subscription_Plan_Order_By = {
  readonly provider_subscriptions_aggregate?: Maybe<Provider_Subscriptions_Aggregate_Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_subscription_plan */
export type Provider_Subscription_Plan_Pk_Columns_Input = {
  readonly type: Scalars['String'];
};

/** select columns of table "provider_subscription_plan" */
export enum Provider_Subscription_Plan_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "provider_subscription_plan" */
export type Provider_Subscription_Plan_Set_Input = {
  readonly type?: Maybe<Scalars['String']>;
};

/** update columns of table "provider_subscription_plan" */
export enum Provider_Subscription_Plan_Update_Column {
  /** column name */
  Type = 'type'
}

/** columns and relationships of "provider_subscription_plans" */
export type Provider_Subscription_Plans = {
  readonly __typename?: 'provider_subscription_plans';
  readonly type: Scalars['String'];
};

/** aggregated selection of "provider_subscription_plans" */
export type Provider_Subscription_Plans_Aggregate = {
  readonly __typename?: 'provider_subscription_plans_aggregate';
  readonly aggregate?: Maybe<Provider_Subscription_Plans_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Subscription_Plans>;
};

/** aggregate fields of "provider_subscription_plans" */
export type Provider_Subscription_Plans_Aggregate_Fields = {
  readonly __typename?: 'provider_subscription_plans_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Subscription_Plans_Max_Fields>;
  readonly min?: Maybe<Provider_Subscription_Plans_Min_Fields>;
};


/** aggregate fields of "provider_subscription_plans" */
export type Provider_Subscription_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "provider_subscription_plans". All fields are combined with a logical 'AND'. */
export type Provider_Subscription_Plans_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Bool_Exp>>;
  readonly type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_subscription_plans" */
export enum Provider_Subscription_Plans_Constraint {
  /** unique or primary key constraint */
  ProviderSubscriptionPlanPkey = 'provider_subscription_plan_pkey'
}

/** input type for inserting data into table "provider_subscription_plans" */
export type Provider_Subscription_Plans_Insert_Input = {
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Subscription_Plans_Max_Fields = {
  readonly __typename?: 'provider_subscription_plans_max_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provider_Subscription_Plans_Min_Fields = {
  readonly __typename?: 'provider_subscription_plans_min_fields';
  readonly type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provider_subscription_plans" */
export type Provider_Subscription_Plans_Mutation_Response = {
  readonly __typename?: 'provider_subscription_plans_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Subscription_Plans>;
};

/** on conflict condition type for table "provider_subscription_plans" */
export type Provider_Subscription_Plans_On_Conflict = {
  readonly constraint: Provider_Subscription_Plans_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Subscription_Plans_Update_Column>;
  readonly where?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_subscription_plans". */
export type Provider_Subscription_Plans_Order_By = {
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_subscription_plans */
export type Provider_Subscription_Plans_Pk_Columns_Input = {
  readonly type: Scalars['String'];
};

/** select columns of table "provider_subscription_plans" */
export enum Provider_Subscription_Plans_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "provider_subscription_plans" */
export type Provider_Subscription_Plans_Set_Input = {
  readonly type?: Maybe<Scalars['String']>;
};

/** update columns of table "provider_subscription_plans" */
export enum Provider_Subscription_Plans_Update_Column {
  /** column name */
  Type = 'type'
}

/** columns and relationships of "provider_subscriptions" */
export type Provider_Subscriptions = {
  readonly __typename?: 'provider_subscriptions';
  readonly features: Scalars['_text'];
  readonly id: Scalars['uuid'];
  readonly is_active: Scalars['Boolean'];
  readonly max_admin_seat: Scalars['Int'];
  readonly plan: Provider_Subscription_Plan_Enum;
  /** An object relationship */
  readonly provider: Provider;
  readonly provider_id: Scalars['uuid'];
  /** An object relationship */
  readonly provider_subscription_plan: Provider_Subscription_Plan;
  readonly will_expire_at: Scalars['timestamp'];
};

/** aggregated selection of "provider_subscriptions" */
export type Provider_Subscriptions_Aggregate = {
  readonly __typename?: 'provider_subscriptions_aggregate';
  readonly aggregate?: Maybe<Provider_Subscriptions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Subscriptions>;
};

/** aggregate fields of "provider_subscriptions" */
export type Provider_Subscriptions_Aggregate_Fields = {
  readonly __typename?: 'provider_subscriptions_aggregate_fields';
  readonly avg?: Maybe<Provider_Subscriptions_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Subscriptions_Max_Fields>;
  readonly min?: Maybe<Provider_Subscriptions_Min_Fields>;
  readonly stddev?: Maybe<Provider_Subscriptions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Provider_Subscriptions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Provider_Subscriptions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Provider_Subscriptions_Sum_Fields>;
  readonly var_pop?: Maybe<Provider_Subscriptions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Provider_Subscriptions_Var_Samp_Fields>;
  readonly variance?: Maybe<Provider_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "provider_subscriptions" */
export type Provider_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_subscriptions" */
export type Provider_Subscriptions_Aggregate_Order_By = {
  readonly avg?: Maybe<Provider_Subscriptions_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Subscriptions_Max_Order_By>;
  readonly min?: Maybe<Provider_Subscriptions_Min_Order_By>;
  readonly stddev?: Maybe<Provider_Subscriptions_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Provider_Subscriptions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Provider_Subscriptions_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Provider_Subscriptions_Sum_Order_By>;
  readonly var_pop?: Maybe<Provider_Subscriptions_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Provider_Subscriptions_Var_Samp_Order_By>;
  readonly variance?: Maybe<Provider_Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "provider_subscriptions" */
export type Provider_Subscriptions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Subscriptions_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Provider_Subscriptions_Avg_Fields = {
  readonly __typename?: 'provider_subscriptions_avg_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Avg_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "provider_subscriptions". All fields are combined with a logical 'AND'. */
export type Provider_Subscriptions_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Subscriptions_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Subscriptions_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Subscriptions_Bool_Exp>>;
  readonly features?: Maybe<_Text_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly is_active?: Maybe<Boolean_Comparison_Exp>;
  readonly max_admin_seat?: Maybe<Int_Comparison_Exp>;
  readonly plan?: Maybe<Provider_Subscription_Plan_Enum_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly provider_id?: Maybe<Uuid_Comparison_Exp>;
  readonly provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
  readonly will_expire_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_subscriptions" */
export enum Provider_Subscriptions_Constraint {
  /** unique or primary key constraint */
  ProviderSubscriptionPkey = 'provider_subscription_pkey'
}

/** input type for incrementing numeric columns in table "provider_subscriptions" */
export type Provider_Subscriptions_Inc_Input = {
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "provider_subscriptions" */
export type Provider_Subscriptions_Insert_Input = {
  readonly features?: Maybe<Scalars['_text']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
  readonly plan?: Maybe<Provider_Subscription_Plan_Enum>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Obj_Rel_Insert_Input>;
  readonly will_expire_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Provider_Subscriptions_Max_Fields = {
  readonly __typename?: 'provider_subscriptions_max_fields';
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly will_expire_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Max_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly max_admin_seat?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly will_expire_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Subscriptions_Min_Fields = {
  readonly __typename?: 'provider_subscriptions_min_fields';
  readonly id?: Maybe<Scalars['uuid']>;
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly will_expire_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Min_Order_By = {
  readonly id?: Maybe<Order_By>;
  readonly max_admin_seat?: Maybe<Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly will_expire_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_subscriptions" */
export type Provider_Subscriptions_Mutation_Response = {
  readonly __typename?: 'provider_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Subscriptions>;
};

/** on conflict condition type for table "provider_subscriptions" */
export type Provider_Subscriptions_On_Conflict = {
  readonly constraint: Provider_Subscriptions_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Subscriptions_Update_Column>;
  readonly where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_subscriptions". */
export type Provider_Subscriptions_Order_By = {
  readonly features?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_active?: Maybe<Order_By>;
  readonly max_admin_seat?: Maybe<Order_By>;
  readonly plan?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly provider_id?: Maybe<Order_By>;
  readonly provider_subscription_plan?: Maybe<Provider_Subscription_Plan_Order_By>;
  readonly will_expire_at?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_subscriptions */
export type Provider_Subscriptions_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider_subscriptions" */
export enum Provider_Subscriptions_Select_Column {
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MaxAdminSeat = 'max_admin_seat',
  /** column name */
  Plan = 'plan',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  WillExpireAt = 'will_expire_at'
}

/** input type for updating data in table "provider_subscriptions" */
export type Provider_Subscriptions_Set_Input = {
  readonly features?: Maybe<Scalars['_text']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly is_active?: Maybe<Scalars['Boolean']>;
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
  readonly plan?: Maybe<Provider_Subscription_Plan_Enum>;
  readonly provider_id?: Maybe<Scalars['uuid']>;
  readonly will_expire_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Provider_Subscriptions_Stddev_Fields = {
  readonly __typename?: 'provider_subscriptions_stddev_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Stddev_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Provider_Subscriptions_Stddev_Pop_Fields = {
  readonly __typename?: 'provider_subscriptions_stddev_pop_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Stddev_Pop_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Provider_Subscriptions_Stddev_Samp_Fields = {
  readonly __typename?: 'provider_subscriptions_stddev_samp_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Stddev_Samp_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Provider_Subscriptions_Sum_Fields = {
  readonly __typename?: 'provider_subscriptions_sum_fields';
  readonly max_admin_seat?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Sum_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** update columns of table "provider_subscriptions" */
export enum Provider_Subscriptions_Update_Column {
  /** column name */
  Features = 'features',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MaxAdminSeat = 'max_admin_seat',
  /** column name */
  Plan = 'plan',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  WillExpireAt = 'will_expire_at'
}

/** aggregate var_pop on columns */
export type Provider_Subscriptions_Var_Pop_Fields = {
  readonly __typename?: 'provider_subscriptions_var_pop_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Var_Pop_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Provider_Subscriptions_Var_Samp_Fields = {
  readonly __typename?: 'provider_subscriptions_var_samp_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Var_Samp_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Provider_Subscriptions_Variance_Fields = {
  readonly __typename?: 'provider_subscriptions_variance_fields';
  readonly max_admin_seat?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "provider_subscriptions" */
export type Provider_Subscriptions_Variance_Order_By = {
  readonly max_admin_seat?: Maybe<Order_By>;
};

/** update columns of table "provider" */
export enum Provider_Update_Column {
  /** column name */
  AboutVideoUuid = 'aboutVideoUuid',
  /** column name */
  Address = 'address',
  /** column name */
  CertificateLogoUuid = 'certificateLogoUuid',
  /** column name */
  ContactNumber = 'contactNumber',
  /** column name */
  CoverPhotoUuid = 'coverPhotoUuid',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsLightTheme = 'isLightTheme',
  /** column name */
  LyonApiKey = 'lyonApiKey',
  /** column name */
  LyonCommunity = 'lyonCommunity',
  /** column name */
  LyonMerchantId = 'lyonMerchantId',
  /** column name */
  Name = 'name',
  /** column name */
  PrcAccreditationNumber = 'prcAccreditationNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website'
}

/** columns and relationships of "provider_zoom" */
export type Provider_Zoom = {
  readonly __typename?: 'provider_zoom';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['timestamp'];
  readonly id: Scalars['uuid'];
  /** An object relationship */
  readonly provider?: Maybe<Provider>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['timestamp'];
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "provider_zoom" */
export type Provider_Zoom_Aggregate = {
  readonly __typename?: 'provider_zoom_aggregate';
  readonly aggregate?: Maybe<Provider_Zoom_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Provider_Zoom>;
};

/** aggregate fields of "provider_zoom" */
export type Provider_Zoom_Aggregate_Fields = {
  readonly __typename?: 'provider_zoom_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Provider_Zoom_Max_Fields>;
  readonly min?: Maybe<Provider_Zoom_Min_Fields>;
};


/** aggregate fields of "provider_zoom" */
export type Provider_Zoom_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_zoom" */
export type Provider_Zoom_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Provider_Zoom_Max_Order_By>;
  readonly min?: Maybe<Provider_Zoom_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_zoom" */
export type Provider_Zoom_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Provider_Zoom_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Provider_Zoom_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_zoom". All fields are combined with a logical 'AND'. */
export type Provider_Zoom_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Provider_Zoom_Bool_Exp>>;
  readonly _not?: Maybe<Provider_Zoom_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Provider_Zoom_Bool_Exp>>;
  readonly accessToken?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly refreshToken?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly zoomAccountId?: Maybe<String_Comparison_Exp>;
  readonly zoomUserId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_zoom" */
export enum Provider_Zoom_Constraint {
  /** unique or primary key constraint */
  Pk_048cdac1ca116bb7900e78d1237 = 'PK_048cdac1ca116bb7900e78d1237',
  /** unique or primary key constraint */
  UqFd3e6e3578ff973a4b1b38bbed2 = 'UQ_fd3e6e3578ff973a4b1b38bbed2'
}

/** input type for inserting data into table "provider_zoom" */
export type Provider_Zoom_Insert_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Zoom_Max_Fields = {
  readonly __typename?: 'provider_zoom_max_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "provider_zoom" */
export type Provider_Zoom_Max_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Zoom_Min_Fields = {
  readonly __typename?: 'provider_zoom_min_fields';
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "provider_zoom" */
export type Provider_Zoom_Min_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_zoom" */
export type Provider_Zoom_Mutation_Response = {
  readonly __typename?: 'provider_zoom_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Provider_Zoom>;
};

/** on conflict condition type for table "provider_zoom" */
export type Provider_Zoom_On_Conflict = {
  readonly constraint: Provider_Zoom_Constraint;
  readonly update_columns?: ReadonlyArray<Provider_Zoom_Update_Column>;
  readonly where?: Maybe<Provider_Zoom_Bool_Exp>;
};

/** Ordering options when selecting data from "provider_zoom". */
export type Provider_Zoom_Order_By = {
  readonly accessToken?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly refreshToken?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly zoomAccountId?: Maybe<Order_By>;
  readonly zoomUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: provider_zoom */
export type Provider_Zoom_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "provider_zoom" */
export enum Provider_Zoom_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

/** input type for updating data in table "provider_zoom" */
export type Provider_Zoom_Set_Input = {
  readonly accessToken?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly refreshToken?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly zoomAccountId?: Maybe<Scalars['String']>;
  readonly zoomUserId?: Maybe<Scalars['String']>;
};

/** update columns of table "provider_zoom" */
export enum Provider_Zoom_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomUserId = 'zoomUserId'
}

export type Query_Root = {
  readonly __typename?: 'query_root';
  /** fetch data from the table: "account_google" */
  readonly account_google: ReadonlyArray<Account_Google>;
  /** fetch aggregated fields from the table: "account_google" */
  readonly account_google_aggregate: Account_Google_Aggregate;
  /** fetch data from the table: "account_google" using primary key columns */
  readonly account_google_by_pk?: Maybe<Account_Google>;
  /** fetch data from the table: "account_subscription_plans" */
  readonly account_subscription_plans: ReadonlyArray<Account_Subscription_Plans>;
  /** fetch aggregated fields from the table: "account_subscription_plans" */
  readonly account_subscription_plans_aggregate: Account_Subscription_Plans_Aggregate;
  /** fetch data from the table: "account_subscription_plans" using primary key columns */
  readonly account_subscription_plans_by_pk?: Maybe<Account_Subscription_Plans>;
  /** An array relationship */
  readonly account_subscriptions: ReadonlyArray<Account_Subscriptions>;
  /** An aggregate relationship */
  readonly account_subscriptions_aggregate: Account_Subscriptions_Aggregate;
  /** fetch data from the table: "account_subscriptions" using primary key columns */
  readonly account_subscriptions_by_pk?: Maybe<Account_Subscriptions>;
  /** fetch data from the table: "account_zoom" */
  readonly account_zoom: ReadonlyArray<Account_Zoom>;
  /** fetch aggregated fields from the table: "account_zoom" */
  readonly account_zoom_aggregate: Account_Zoom_Aggregate;
  /** fetch data from the table: "account_zoom" using primary key columns */
  readonly account_zoom_by_pk?: Maybe<Account_Zoom>;
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "ar_internal_metadata" */
  readonly ar_internal_metadata: ReadonlyArray<Ar_Internal_Metadata>;
  /** fetch aggregated fields from the table: "ar_internal_metadata" */
  readonly ar_internal_metadata_aggregate: Ar_Internal_Metadata_Aggregate;
  /** fetch data from the table: "ar_internal_metadata" using primary key columns */
  readonly ar_internal_metadata_by_pk?: Maybe<Ar_Internal_Metadata>;
  /** fetch data from the table: "attachment" */
  readonly attachment: ReadonlyArray<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  readonly attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  readonly attachment_by_pk?: Maybe<Attachment>;
  /** An array relationship */
  readonly attendance_logs: ReadonlyArray<Attendance_Logs>;
  /** An aggregate relationship */
  readonly attendance_logs_aggregate: Attendance_Logs_Aggregate;
  /** fetch data from the table: "attendance_logs" using primary key columns */
  readonly attendance_logs_by_pk?: Maybe<Attendance_Logs>;
  /** An array relationship */
  readonly attendances: ReadonlyArray<Attendances>;
  /** An aggregate relationship */
  readonly attendances_aggregate: Attendances_Aggregate;
  /** fetch data from the table: "attendances" using primary key columns */
  readonly attendances_by_pk?: Maybe<Attendances>;
  /** fetch data from the table: "breakout_group" */
  readonly breakout_group: ReadonlyArray<Breakout_Group>;
  /** fetch aggregated fields from the table: "breakout_group" */
  readonly breakout_group_aggregate: Breakout_Group_Aggregate;
  /** fetch data from the table: "breakout_group" using primary key columns */
  readonly breakout_group_by_pk?: Maybe<Breakout_Group>;
  /** fetch data from the table: "breakout_group_participants_event_participant" */
  readonly breakout_group_participants_event_participant: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
  /** fetch aggregated fields from the table: "breakout_group_participants_event_participant" */
  readonly breakout_group_participants_event_participant_aggregate: Breakout_Group_Participants_Event_Participant_Aggregate;
  /** fetch data from the table: "breakout_group_participants_event_participant" using primary key columns */
  readonly breakout_group_participants_event_participant_by_pk?: Maybe<Breakout_Group_Participants_Event_Participant>;
  /** fetch data from the table: "breakout_room" */
  readonly breakout_room: ReadonlyArray<Breakout_Room>;
  /** fetch aggregated fields from the table: "breakout_room" */
  readonly breakout_room_aggregate: Breakout_Room_Aggregate;
  /** fetch data from the table: "breakout_room" using primary key columns */
  readonly breakout_room_by_pk?: Maybe<Breakout_Room>;
  /** fetch data from the table: "bunny_upload" */
  readonly bunny_upload: ReadonlyArray<Bunny_Upload>;
  /** fetch aggregated fields from the table: "bunny_upload" */
  readonly bunny_upload_aggregate: Bunny_Upload_Aggregate;
  /** fetch data from the table: "bunny_upload" using primary key columns */
  readonly bunny_upload_by_pk?: Maybe<Bunny_Upload>;
  /** fetch data from the table: "bunny_upload_states" */
  readonly bunny_upload_states: ReadonlyArray<Bunny_Upload_States>;
  /** fetch aggregated fields from the table: "bunny_upload_states" */
  readonly bunny_upload_states_aggregate: Bunny_Upload_States_Aggregate;
  /** fetch data from the table: "bunny_upload_states" using primary key columns */
  readonly bunny_upload_states_by_pk?: Maybe<Bunny_Upload_States>;
  /** fetch data from the table: "carousel_item" */
  readonly carousel_item: ReadonlyArray<Carousel_Item>;
  /** fetch aggregated fields from the table: "carousel_item" */
  readonly carousel_item_aggregate: Carousel_Item_Aggregate;
  /** fetch data from the table: "carousel_item" using primary key columns */
  readonly carousel_item_by_pk?: Maybe<Carousel_Item>;
  /** fetch data from the table: "certificate_signatory" */
  readonly certificate_signatory: ReadonlyArray<Certificate_Signatory>;
  /** fetch aggregated fields from the table: "certificate_signatory" */
  readonly certificate_signatory_aggregate: Certificate_Signatory_Aggregate;
  /** fetch data from the table: "certificate_signatory" using primary key columns */
  readonly certificate_signatory_by_pk?: Maybe<Certificate_Signatory>;
  /** An array relationship */
  readonly certificate_template_signatories: ReadonlyArray<Certificate_Template_Signatories>;
  /** An aggregate relationship */
  readonly certificate_template_signatories_aggregate: Certificate_Template_Signatories_Aggregate;
  /** fetch data from the table: "certificate_template_signatories" using primary key columns */
  readonly certificate_template_signatories_by_pk?: Maybe<Certificate_Template_Signatories>;
  /** An array relationship */
  readonly certificate_templates: ReadonlyArray<Certificate_Templates>;
  /** An aggregate relationship */
  readonly certificate_templates_aggregate: Certificate_Templates_Aggregate;
  /** fetch data from the table: "certificate_templates" using primary key columns */
  readonly certificate_templates_by_pk?: Maybe<Certificate_Templates>;
  /** fetch data from the table: "course_articles" */
  readonly course_articles: ReadonlyArray<Course_Articles>;
  /** An aggregate relationship */
  readonly course_articles_aggregate: Course_Articles_Aggregate;
  /** fetch data from the table: "course_articles" using primary key columns */
  readonly course_articles_by_pk?: Maybe<Course_Articles>;
  /** An array relationship */
  readonly course_assessments: ReadonlyArray<Course_Assessments>;
  /** An aggregate relationship */
  readonly course_assessments_aggregate: Course_Assessments_Aggregate;
  /** fetch data from the table: "course_assessments" using primary key columns */
  readonly course_assessments_by_pk?: Maybe<Course_Assessments>;
  /** An array relationship */
  readonly course_attachments: ReadonlyArray<Course_Attachments>;
  /** An aggregate relationship */
  readonly course_attachments_aggregate: Course_Attachments_Aggregate;
  /** fetch data from the table: "course_attachments" using primary key columns */
  readonly course_attachments_by_pk?: Maybe<Course_Attachments>;
  /** An array relationship */
  readonly course_certificates: ReadonlyArray<Course_Certificates>;
  /** An aggregate relationship */
  readonly course_certificates_aggregate: Course_Certificates_Aggregate;
  /** fetch data from the table: "course_certificates" using primary key columns */
  readonly course_certificates_by_pk?: Maybe<Course_Certificates>;
  /** fetch data from the table: "course_discount_types" */
  readonly course_discount_types: ReadonlyArray<Course_Discount_Types>;
  /** fetch aggregated fields from the table: "course_discount_types" */
  readonly course_discount_types_aggregate: Course_Discount_Types_Aggregate;
  /** fetch data from the table: "course_discount_types" using primary key columns */
  readonly course_discount_types_by_pk?: Maybe<Course_Discount_Types>;
  /** An array relationship */
  readonly course_discounts: ReadonlyArray<Course_Discounts>;
  /** An aggregate relationship */
  readonly course_discounts_aggregate: Course_Discounts_Aggregate;
  /** fetch data from the table: "course_discounts" using primary key columns */
  readonly course_discounts_by_pk?: Maybe<Course_Discounts>;
  /** An array relationship */
  readonly course_forms: ReadonlyArray<Course_Forms>;
  /** An aggregate relationship */
  readonly course_forms_aggregate: Course_Forms_Aggregate;
  /** fetch data from the table: "course_forms" using primary key columns */
  readonly course_forms_by_pk?: Maybe<Course_Forms>;
  /** An array relationship */
  readonly course_gains: ReadonlyArray<Course_Gains>;
  /** An aggregate relationship */
  readonly course_gains_aggregate: Course_Gains_Aggregate;
  /** fetch data from the table: "course_gains" using primary key columns */
  readonly course_gains_by_pk?: Maybe<Course_Gains>;
  /** An array relationship */
  readonly course_instructors: ReadonlyArray<Course_Instructors>;
  /** An aggregate relationship */
  readonly course_instructors_aggregate: Course_Instructors_Aggregate;
  /** fetch data from the table: "course_instructors" using primary key columns */
  readonly course_instructors_by_pk?: Maybe<Course_Instructors>;
  /** An array relationship */
  readonly course_invites: ReadonlyArray<Course_Invites>;
  /** An aggregate relationship */
  readonly course_invites_aggregate: Course_Invites_Aggregate;
  /** fetch data from the table: "course_invites" using primary key columns */
  readonly course_invites_by_pk?: Maybe<Course_Invites>;
  /** An array relationship */
  readonly course_lecturers: ReadonlyArray<Course_Lecturers>;
  /** An aggregate relationship */
  readonly course_lecturers_aggregate: Course_Lecturers_Aggregate;
  /** An array relationship */
  readonly course_live_sessions: ReadonlyArray<Course_Live_Sessions>;
  /** An aggregate relationship */
  readonly course_live_sessions_aggregate: Course_Live_Sessions_Aggregate;
  /** fetch data from the table: "course_live_sessions" using primary key columns */
  readonly course_live_sessions_by_pk?: Maybe<Course_Live_Sessions>;
  /** An array relationship */
  readonly course_module_students: ReadonlyArray<Course_Module_Students>;
  /** An aggregate relationship */
  readonly course_module_students_aggregate: Course_Module_Students_Aggregate;
  /** fetch data from the table: "course_module_students" using primary key columns */
  readonly course_module_students_by_pk?: Maybe<Course_Module_Students>;
  /** An array relationship */
  readonly course_modules: ReadonlyArray<Course_Modules>;
  /** An aggregate relationship */
  readonly course_modules_aggregate: Course_Modules_Aggregate;
  /** fetch data from the table: "course_modules" using primary key columns */
  readonly course_modules_by_pk?: Maybe<Course_Modules>;
  /** An array relationship */
  readonly course_objectives: ReadonlyArray<Course_Objectives>;
  /** An aggregate relationship */
  readonly course_objectives_aggregate: Course_Objectives_Aggregate;
  /** fetch data from the table: "course_objectives" using primary key columns */
  readonly course_objectives_by_pk?: Maybe<Course_Objectives>;
  /** fetch data from the table: "course_order" */
  readonly course_order: ReadonlyArray<Course_Order>;
  /** fetch aggregated fields from the table: "course_order" */
  readonly course_order_aggregate: Course_Order_Aggregate;
  /** fetch data from the table: "course_order" using primary key columns */
  readonly course_order_by_pk?: Maybe<Course_Order>;
  /** An array relationship */
  readonly course_order_modules: ReadonlyArray<Course_Order_Modules>;
  /** An aggregate relationship */
  readonly course_order_modules_aggregate: Course_Order_Modules_Aggregate;
  /** fetch data from the table: "course_order_modules" using primary key columns */
  readonly course_order_modules_by_pk?: Maybe<Course_Order_Modules>;
  /** fetch data from the table: "course_order_statuses" */
  readonly course_order_statuses: ReadonlyArray<Course_Order_Statuses>;
  /** fetch aggregated fields from the table: "course_order_statuses" */
  readonly course_order_statuses_aggregate: Course_Order_Statuses_Aggregate;
  /** fetch data from the table: "course_order_statuses" using primary key columns */
  readonly course_order_statuses_by_pk?: Maybe<Course_Order_Statuses>;
  /** fetch data from the table: "course_payment_methods" */
  readonly course_payment_methods: ReadonlyArray<Course_Payment_Methods>;
  /** fetch aggregated fields from the table: "course_payment_methods" */
  readonly course_payment_methods_aggregate: Course_Payment_Methods_Aggregate;
  /** fetch data from the table: "course_payment_methods" using primary key columns */
  readonly course_payment_methods_by_pk?: Maybe<Course_Payment_Methods>;
  /** An array relationship */
  readonly course_reminders: ReadonlyArray<Course_Reminders>;
  /** An aggregate relationship */
  readonly course_reminders_aggregate: Course_Reminders_Aggregate;
  /** fetch data from the table: "course_reminders" using primary key columns */
  readonly course_reminders_by_pk?: Maybe<Course_Reminders>;
  /** An array relationship */
  readonly course_requirements: ReadonlyArray<Course_Requirements>;
  /** An aggregate relationship */
  readonly course_requirements_aggregate: Course_Requirements_Aggregate;
  /** fetch data from the table: "course_requirements" using primary key columns */
  readonly course_requirements_by_pk?: Maybe<Course_Requirements>;
  /** An array relationship */
  readonly course_rewards: ReadonlyArray<Course_Rewards>;
  /** An aggregate relationship */
  readonly course_rewards_aggregate: Course_Rewards_Aggregate;
  /** fetch data from the table: "course_rewards" using primary key columns */
  readonly course_rewards_by_pk?: Maybe<Course_Rewards>;
  /** fetch data from the table: "course_sell_types" */
  readonly course_sell_types: ReadonlyArray<Course_Sell_Types>;
  /** fetch aggregated fields from the table: "course_sell_types" */
  readonly course_sell_types_aggregate: Course_Sell_Types_Aggregate;
  /** fetch data from the table: "course_sell_types" using primary key columns */
  readonly course_sell_types_by_pk?: Maybe<Course_Sell_Types>;
  /** fetch data from the table: "course_signatories" */
  readonly course_signatories: ReadonlyArray<Course_Signatories>;
  /** fetch aggregated fields from the table: "course_signatories" */
  readonly course_signatories_aggregate: Course_Signatories_Aggregate;
  /** fetch data from the table: "course_signatories" using primary key columns */
  readonly course_signatories_by_pk?: Maybe<Course_Signatories>;
  /** An array relationship */
  readonly course_students: ReadonlyArray<Course_Students>;
  /** An aggregate relationship */
  readonly course_students_aggregate: Course_Students_Aggregate;
  /** fetch data from the table: "course_students" using primary key columns */
  readonly course_students_by_pk?: Maybe<Course_Students>;
  /** An array relationship */
  readonly course_videos: ReadonlyArray<Course_Videos>;
  /** An aggregate relationship */
  readonly course_videos_aggregate: Course_Videos_Aggregate;
  /** fetch data from the table: "course_videos" using primary key columns */
  readonly course_videos_by_pk?: Maybe<Course_Videos>;
  /** fetch data from the table: "course_videos_statuses" */
  readonly course_videos_statuses: ReadonlyArray<Course_Videos_Statuses>;
  /** fetch aggregated fields from the table: "course_videos_statuses" */
  readonly course_videos_statuses_aggregate: Course_Videos_Statuses_Aggregate;
  /** fetch data from the table: "course_videos_statuses" using primary key columns */
  readonly course_videos_statuses_by_pk?: Maybe<Course_Videos_Statuses>;
  /** An array relationship */
  readonly courses: ReadonlyArray<Courses>;
  /** An aggregate relationship */
  readonly courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  readonly courses_by_pk?: Maybe<Courses>;
  /** An array relationship */
  readonly cpd_unit_rewards: ReadonlyArray<Cpd_Unit_Rewards>;
  /** An aggregate relationship */
  readonly cpd_unit_rewards_aggregate: Cpd_Unit_Rewards_Aggregate;
  /** fetch data from the table: "cpd_unit_rewards" using primary key columns */
  readonly cpd_unit_rewards_by_pk?: Maybe<Cpd_Unit_Rewards>;
  /** fetch data from the table: "cpd_units_reward" */
  readonly cpd_units_reward: ReadonlyArray<Cpd_Units_Reward>;
  /** fetch aggregated fields from the table: "cpd_units_reward" */
  readonly cpd_units_reward_aggregate: Cpd_Units_Reward_Aggregate;
  /** fetch data from the table: "cpd_units_reward" using primary key columns */
  readonly cpd_units_reward_by_pk?: Maybe<Cpd_Units_Reward>;
  /** fetch data from the table: "customer" */
  readonly customer: ReadonlyArray<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  readonly customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  readonly customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table: "discount" */
  readonly discount: ReadonlyArray<Discount>;
  /** fetch aggregated fields from the table: "discount" */
  readonly discount_aggregate: Discount_Aggregate;
  /** fetch data from the table: "discount" using primary key columns */
  readonly discount_by_pk?: Maybe<Discount>;
  /** fetch data from the table: "email_notification_types" */
  readonly email_notification_types: ReadonlyArray<Email_Notification_Types>;
  /** fetch aggregated fields from the table: "email_notification_types" */
  readonly email_notification_types_aggregate: Email_Notification_Types_Aggregate;
  /** fetch data from the table: "email_notification_types" using primary key columns */
  readonly email_notification_types_by_pk?: Maybe<Email_Notification_Types>;
  /** An array relationship */
  readonly email_notifications: ReadonlyArray<Email_Notifications>;
  /** An aggregate relationship */
  readonly email_notifications_aggregate: Email_Notifications_Aggregate;
  /** fetch data from the table: "email_notifications" using primary key columns */
  readonly email_notifications_by_pk?: Maybe<Email_Notifications>;
  /** fetch data from the table: "event" */
  readonly event: ReadonlyArray<Event>;
  /** fetch aggregated fields from the table: "event" */
  readonly event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  readonly event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "event_industries_industry" */
  readonly event_industries_industry: ReadonlyArray<Event_Industries_Industry>;
  /** fetch aggregated fields from the table: "event_industries_industry" */
  readonly event_industries_industry_aggregate: Event_Industries_Industry_Aggregate;
  /** fetch data from the table: "event_industries_industry" using primary key columns */
  readonly event_industries_industry_by_pk?: Maybe<Event_Industries_Industry>;
  /** fetch data from the table: "event_managing_users_user" */
  readonly event_managing_users_user: ReadonlyArray<Event_Managing_Users_User>;
  /** fetch aggregated fields from the table: "event_managing_users_user" */
  readonly event_managing_users_user_aggregate: Event_Managing_Users_User_Aggregate;
  /** fetch data from the table: "event_managing_users_user" using primary key columns */
  readonly event_managing_users_user_by_pk?: Maybe<Event_Managing_Users_User>;
  /** fetch data from the table: "event_participant" */
  readonly event_participant: ReadonlyArray<Event_Participant>;
  /** fetch aggregated fields from the table: "event_participant" */
  readonly event_participant_aggregate: Event_Participant_Aggregate;
  /** fetch data from the table: "event_participant" using primary key columns */
  readonly event_participant_by_pk?: Maybe<Event_Participant>;
  /** fetch data from the table: "faq_item" */
  readonly faq_item: ReadonlyArray<Faq_Item>;
  /** fetch aggregated fields from the table: "faq_item" */
  readonly faq_item_aggregate: Faq_Item_Aggregate;
  /** fetch data from the table: "faq_item" using primary key columns */
  readonly faq_item_by_pk?: Maybe<Faq_Item>;
  /** fetch data from the table: "form" */
  readonly form: ReadonlyArray<Form>;
  /** fetch aggregated fields from the table: "form" */
  readonly form_aggregate: Form_Aggregate;
  /** fetch data from the table: "form" using primary key columns */
  readonly form_by_pk?: Maybe<Form>;
  /** fetch data from the table: "form_event_names" */
  readonly form_event_names: ReadonlyArray<Form_Event_Names>;
  /** fetch aggregated fields from the table: "form_event_names" */
  readonly form_event_names_aggregate: Form_Event_Names_Aggregate;
  /** fetch data from the table: "form_event_names" using primary key columns */
  readonly form_event_names_by_pk?: Maybe<Form_Event_Names>;
  /** An array relationship */
  readonly form_events: ReadonlyArray<Form_Events>;
  /** An aggregate relationship */
  readonly form_events_aggregate: Form_Events_Aggregate;
  /** fetch data from the table: "form_events" using primary key columns */
  readonly form_events_by_pk?: Maybe<Form_Events>;
  /** fetch data from the table: "form_instance" */
  readonly form_instance: ReadonlyArray<Form_Instance>;
  /** fetch aggregated fields from the table: "form_instance" */
  readonly form_instance_aggregate: Form_Instance_Aggregate;
  /** fetch data from the table: "form_instance" using primary key columns */
  readonly form_instance_by_pk?: Maybe<Form_Instance>;
  /** An array relationship */
  readonly form_question_answers: ReadonlyArray<Form_Question_Answers>;
  /** An aggregate relationship */
  readonly form_question_answers_aggregate: Form_Question_Answers_Aggregate;
  /** fetch data from the table: "form_question_answers" using primary key columns */
  readonly form_question_answers_by_pk?: Maybe<Form_Question_Answers>;
  /** fetch data from the table: "form_question_submission_states" */
  readonly form_question_submission_states: ReadonlyArray<Form_Question_Submission_States>;
  /** fetch aggregated fields from the table: "form_question_submission_states" */
  readonly form_question_submission_states_aggregate: Form_Question_Submission_States_Aggregate;
  /** fetch data from the table: "form_question_submission_states" using primary key columns */
  readonly form_question_submission_states_by_pk?: Maybe<Form_Question_Submission_States>;
  /** An array relationship */
  readonly form_question_submissions: ReadonlyArray<Form_Question_Submissions>;
  /** An aggregate relationship */
  readonly form_question_submissions_aggregate: Form_Question_Submissions_Aggregate;
  /** fetch data from the table: "form_question_submissions" using primary key columns */
  readonly form_question_submissions_by_pk?: Maybe<Form_Question_Submissions>;
  /** fetch data from the table: "form_question_types" */
  readonly form_question_types: ReadonlyArray<Form_Question_Types>;
  /** fetch aggregated fields from the table: "form_question_types" */
  readonly form_question_types_aggregate: Form_Question_Types_Aggregate;
  /** fetch data from the table: "form_question_types" using primary key columns */
  readonly form_question_types_by_pk?: Maybe<Form_Question_Types>;
  /** An array relationship */
  readonly form_questions: ReadonlyArray<Form_Questions>;
  /** An aggregate relationship */
  readonly form_questions_aggregate: Form_Questions_Aggregate;
  /** fetch data from the table: "form_questions" using primary key columns */
  readonly form_questions_by_pk?: Maybe<Form_Questions>;
  /** fetch data from the table: "form_score" */
  readonly form_score: ReadonlyArray<Form_Score>;
  /** fetch aggregated fields from the table: "form_score" */
  readonly form_score_aggregate: Form_Score_Aggregate;
  /** fetch data from the table: "form_score" using primary key columns */
  readonly form_score_by_pk?: Maybe<Form_Score>;
  /** fetch data from the table: "form_scoring_policies" */
  readonly form_scoring_policies: ReadonlyArray<Form_Scoring_Policies>;
  /** fetch aggregated fields from the table: "form_scoring_policies" */
  readonly form_scoring_policies_aggregate: Form_Scoring_Policies_Aggregate;
  /** fetch data from the table: "form_scoring_policies" using primary key columns */
  readonly form_scoring_policies_by_pk?: Maybe<Form_Scoring_Policies>;
  /** fetch data from the table: "form_submission_statuses" */
  readonly form_submission_statuses: ReadonlyArray<Form_Submission_Statuses>;
  /** fetch aggregated fields from the table: "form_submission_statuses" */
  readonly form_submission_statuses_aggregate: Form_Submission_Statuses_Aggregate;
  /** fetch data from the table: "form_submission_statuses" using primary key columns */
  readonly form_submission_statuses_by_pk?: Maybe<Form_Submission_Statuses>;
  /** An array relationship */
  readonly form_submissions: ReadonlyArray<Form_Submissions>;
  /** An aggregate relationship */
  readonly form_submissions_aggregate: Form_Submissions_Aggregate;
  /** fetch data from the table: "form_submissions" using primary key columns */
  readonly form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** fetch data from the table: "form_types" */
  readonly form_types: ReadonlyArray<Form_Types>;
  /** fetch aggregated fields from the table: "form_types" */
  readonly form_types_aggregate: Form_Types_Aggregate;
  /** fetch data from the table: "form_types" using primary key columns */
  readonly form_types_by_pk?: Maybe<Form_Types>;
  /** An array relationship */
  readonly forms: ReadonlyArray<Forms>;
  /** An aggregate relationship */
  readonly forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  readonly forms_by_pk?: Maybe<Forms>;
  /** An array relationship */
  readonly images: ReadonlyArray<Images>;
  /** An aggregate relationship */
  readonly images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  readonly images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "industry" */
  readonly industry: ReadonlyArray<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  readonly industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  readonly industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table: "industry_category" */
  readonly industry_category: ReadonlyArray<Industry_Category>;
  /** fetch aggregated fields from the table: "industry_category" */
  readonly industry_category_aggregate: Industry_Category_Aggregate;
  /** fetch data from the table: "industry_category" using primary key columns */
  readonly industry_category_by_pk?: Maybe<Industry_Category>;
  /** fetch data from the table: "industry_type" */
  readonly industry_type: ReadonlyArray<Industry_Type>;
  /** fetch aggregated fields from the table: "industry_type" */
  readonly industry_type_aggregate: Industry_Type_Aggregate;
  /** fetch data from the table: "industry_type" using primary key columns */
  readonly industry_type_by_pk?: Maybe<Industry_Type>;
  /** fetch data from the table: "migrations" */
  readonly migrations: ReadonlyArray<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  readonly migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  readonly migrations_by_pk?: Maybe<Migrations>;
  /** fetch data from the table: "mock_order" */
  readonly mock_order: ReadonlyArray<Mock_Order>;
  /** fetch data from the table: "mock_order2" */
  readonly mock_order2: ReadonlyArray<Mock_Order2>;
  /** fetch aggregated fields from the table: "mock_order2" */
  readonly mock_order2_aggregate: Mock_Order2_Aggregate;
  /** fetch data from the table: "mock_order2" using primary key columns */
  readonly mock_order2_by_pk?: Maybe<Mock_Order2>;
  /** fetch aggregated fields from the table: "mock_order" */
  readonly mock_order_aggregate: Mock_Order_Aggregate;
  /** fetch data from the table: "mock_order" using primary key columns */
  readonly mock_order_by_pk?: Maybe<Mock_Order>;
  /** An array relationship */
  readonly module_item_comments: ReadonlyArray<Module_Item_Comments>;
  /** An aggregate relationship */
  readonly module_item_comments_aggregate: Module_Item_Comments_Aggregate;
  /** fetch data from the table: "module_item_comments" using primary key columns */
  readonly module_item_comments_by_pk?: Maybe<Module_Item_Comments>;
  /** An array relationship */
  readonly module_items: ReadonlyArray<Module_Items>;
  /** An aggregate relationship */
  readonly module_items_aggregate: Module_Items_Aggregate;
  /** fetch data from the table: "module_items" using primary key columns */
  readonly module_items_by_pk?: Maybe<Module_Items>;
  /** fetch data from the table: "order" */
  readonly order: ReadonlyArray<Order>;
  /** fetch aggregated fields from the table: "order" */
  readonly order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  readonly order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "other_reward" */
  readonly other_reward: ReadonlyArray<Other_Reward>;
  /** fetch aggregated fields from the table: "other_reward" */
  readonly other_reward_aggregate: Other_Reward_Aggregate;
  /** fetch data from the table: "other_reward" using primary key columns */
  readonly other_reward_by_pk?: Maybe<Other_Reward>;
  /** fetch data from the table: "other_reward_type" */
  readonly other_reward_type: ReadonlyArray<Other_Reward_Type>;
  /** fetch aggregated fields from the table: "other_reward_type" */
  readonly other_reward_type_aggregate: Other_Reward_Type_Aggregate;
  /** fetch data from the table: "other_reward_type" using primary key columns */
  readonly other_reward_type_by_pk?: Maybe<Other_Reward_Type>;
  /** An array relationship */
  readonly other_unit_rewards: ReadonlyArray<Other_Unit_Rewards>;
  /** An aggregate relationship */
  readonly other_unit_rewards_aggregate: Other_Unit_Rewards_Aggregate;
  /** fetch data from the table: "other_unit_rewards" using primary key columns */
  readonly other_unit_rewards_by_pk?: Maybe<Other_Unit_Rewards>;
  /** fetch data from the table: "product" */
  readonly product: ReadonlyArray<Product>;
  /** fetch aggregated fields from the table: "product" */
  readonly product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  readonly product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "provider" */
  readonly provider: ReadonlyArray<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  readonly provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider_application" */
  readonly provider_application: ReadonlyArray<Provider_Application>;
  /** fetch aggregated fields from the table: "provider_application" */
  readonly provider_application_aggregate: Provider_Application_Aggregate;
  /** fetch data from the table: "provider_application" using primary key columns */
  readonly provider_application_by_pk?: Maybe<Provider_Application>;
  /** fetch data from the table: "provider_application_industries_industry" */
  readonly provider_application_industries_industry: ReadonlyArray<Provider_Application_Industries_Industry>;
  /** fetch aggregated fields from the table: "provider_application_industries_industry" */
  readonly provider_application_industries_industry_aggregate: Provider_Application_Industries_Industry_Aggregate;
  /** fetch data from the table: "provider_application_industries_industry" using primary key columns */
  readonly provider_application_industries_industry_by_pk?: Maybe<Provider_Application_Industries_Industry>;
  /** fetch data from the table: "provider" using primary key columns */
  readonly provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "provider_dragonpay_credentials" */
  readonly provider_dragonpay_credentials: ReadonlyArray<Provider_Dragonpay_Credentials>;
  /** fetch aggregated fields from the table: "provider_dragonpay_credentials" */
  readonly provider_dragonpay_credentials_aggregate: Provider_Dragonpay_Credentials_Aggregate;
  /** fetch data from the table: "provider_dragonpay_credentials" using primary key columns */
  readonly provider_dragonpay_credentials_by_pk?: Maybe<Provider_Dragonpay_Credentials>;
  /** fetch data from the table: "provider_frequently_used_industries_industry" */
  readonly provider_frequently_used_industries_industry: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
  /** fetch aggregated fields from the table: "provider_frequently_used_industries_industry" */
  readonly provider_frequently_used_industries_industry_aggregate: Provider_Frequently_Used_Industries_Industry_Aggregate;
  /** fetch data from the table: "provider_frequently_used_industries_industry" using primary key columns */
  readonly provider_frequently_used_industries_industry_by_pk?: Maybe<Provider_Frequently_Used_Industries_Industry>;
  /** fetch data from the table: "provider_google" */
  readonly provider_google: ReadonlyArray<Provider_Google>;
  /** fetch aggregated fields from the table: "provider_google" */
  readonly provider_google_aggregate: Provider_Google_Aggregate;
  /** fetch data from the table: "provider_google" using primary key columns */
  readonly provider_google_by_pk?: Maybe<Provider_Google>;
  /** fetch data from the table: "provider_managing_users_user" */
  readonly provider_managing_users_user: ReadonlyArray<Provider_Managing_Users_User>;
  /** fetch aggregated fields from the table: "provider_managing_users_user" */
  readonly provider_managing_users_user_aggregate: Provider_Managing_Users_User_Aggregate;
  /** fetch data from the table: "provider_managing_users_user" using primary key columns */
  readonly provider_managing_users_user_by_pk?: Maybe<Provider_Managing_Users_User>;
  /** fetch data from the table: "provider_subscription_plan" */
  readonly provider_subscription_plan: ReadonlyArray<Provider_Subscription_Plan>;
  /** fetch aggregated fields from the table: "provider_subscription_plan" */
  readonly provider_subscription_plan_aggregate: Provider_Subscription_Plan_Aggregate;
  /** fetch data from the table: "provider_subscription_plan" using primary key columns */
  readonly provider_subscription_plan_by_pk?: Maybe<Provider_Subscription_Plan>;
  /** fetch data from the table: "provider_subscription_plans" */
  readonly provider_subscription_plans: ReadonlyArray<Provider_Subscription_Plans>;
  /** fetch aggregated fields from the table: "provider_subscription_plans" */
  readonly provider_subscription_plans_aggregate: Provider_Subscription_Plans_Aggregate;
  /** fetch data from the table: "provider_subscription_plans" using primary key columns */
  readonly provider_subscription_plans_by_pk?: Maybe<Provider_Subscription_Plans>;
  /** An array relationship */
  readonly provider_subscriptions: ReadonlyArray<Provider_Subscriptions>;
  /** An aggregate relationship */
  readonly provider_subscriptions_aggregate: Provider_Subscriptions_Aggregate;
  /** fetch data from the table: "provider_subscriptions" using primary key columns */
  readonly provider_subscriptions_by_pk?: Maybe<Provider_Subscriptions>;
  /** fetch data from the table: "provider_zoom" */
  readonly provider_zoom: ReadonlyArray<Provider_Zoom>;
  /** fetch aggregated fields from the table: "provider_zoom" */
  readonly provider_zoom_aggregate: Provider_Zoom_Aggregate;
  /** fetch data from the table: "provider_zoom" using primary key columns */
  readonly provider_zoom_by_pk?: Maybe<Provider_Zoom>;
  /** fetch data from the table: "ranking_reward" */
  readonly ranking_reward: ReadonlyArray<Ranking_Reward>;
  /** fetch aggregated fields from the table: "ranking_reward" */
  readonly ranking_reward_aggregate: Ranking_Reward_Aggregate;
  /** fetch data from the table: "ranking_reward" using primary key columns */
  readonly ranking_reward_by_pk?: Maybe<Ranking_Reward>;
  /** An array relationship */
  readonly ranking_rewards: ReadonlyArray<Ranking_Rewards>;
  /** An aggregate relationship */
  readonly ranking_rewards_aggregate: Ranking_Rewards_Aggregate;
  /** fetch data from the table: "ranking_rewards" using primary key columns */
  readonly ranking_rewards_by_pk?: Maybe<Ranking_Rewards>;
  /** fetch data from the table: "reward_certificate" */
  readonly reward_certificate: ReadonlyArray<Reward_Certificate>;
  /** fetch aggregated fields from the table: "reward_certificate" */
  readonly reward_certificate_aggregate: Reward_Certificate_Aggregate;
  /** fetch data from the table: "reward_certificate" using primary key columns */
  readonly reward_certificate_by_pk?: Maybe<Reward_Certificate>;
  /** fetch data from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly reward_certificate_signatories_certificate_signatory: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** fetch aggregated fields from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly reward_certificate_signatories_certificate_signatory_aggregate: Reward_Certificate_Signatories_Certificate_Signatory_Aggregate;
  /** fetch data from the table: "reward_certificate_signatories_certificate_signatory" using primary key columns */
  readonly reward_certificate_signatories_certificate_signatory_by_pk?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** fetch data from the table: "schema_migrations" */
  readonly schema_migrations: ReadonlyArray<Schema_Migrations>;
  /** fetch aggregated fields from the table: "schema_migrations" */
  readonly schema_migrations_aggregate: Schema_Migrations_Aggregate;
  /** fetch data from the table: "schema_migrations" using primary key columns */
  readonly schema_migrations_by_pk?: Maybe<Schema_Migrations>;
  /** fetch data from the table: "speakers" */
  readonly speakers: ReadonlyArray<Speakers>;
  /** fetch aggregated fields from the table: "speakers" */
  readonly speakers_aggregate: Speakers_Aggregate;
  /** fetch data from the table: "speakers" using primary key columns */
  readonly speakers_by_pk?: Maybe<Speakers>;
  /** fetch data from the table: "stream" */
  readonly stream: ReadonlyArray<Stream>;
  /** fetch aggregated fields from the table: "stream" */
  readonly stream_aggregate: Stream_Aggregate;
  /** fetch data from the table: "stream_attachment" */
  readonly stream_attachment: ReadonlyArray<Stream_Attachment>;
  /** fetch aggregated fields from the table: "stream_attachment" */
  readonly stream_attachment_aggregate: Stream_Attachment_Aggregate;
  /** fetch data from the table: "stream_attachment" using primary key columns */
  readonly stream_attachment_by_pk?: Maybe<Stream_Attachment>;
  /** fetch data from the table: "stream" using primary key columns */
  readonly stream_by_pk?: Maybe<Stream>;
  /** fetch data from the table: "stream_forms_form" */
  readonly stream_forms_form: ReadonlyArray<Stream_Forms_Form>;
  /** fetch aggregated fields from the table: "stream_forms_form" */
  readonly stream_forms_form_aggregate: Stream_Forms_Form_Aggregate;
  /** fetch data from the table: "stream_forms_form" using primary key columns */
  readonly stream_forms_form_by_pk?: Maybe<Stream_Forms_Form>;
  /** fetch data from the table: "student_progress" */
  readonly student_progress: ReadonlyArray<Student_Progress>;
  /** fetch aggregated fields from the table: "student_progress" */
  readonly student_progress_aggregate: Student_Progress_Aggregate;
  /** fetch data from the table: "student_progress" using primary key columns */
  readonly student_progress_by_pk?: Maybe<Student_Progress>;
  /** fetch data from the table: "user" */
  readonly user: ReadonlyArray<User>;
  /** fetch aggregated fields from the table: "user" */
  readonly user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  readonly user_by_pk?: Maybe<User>;
  /** An array relationship */
  readonly user_certificates: ReadonlyArray<User_Certificates>;
  /** An aggregate relationship */
  readonly user_certificates_aggregate: User_Certificates_Aggregate;
  /** fetch data from the table: "user_certificates" using primary key columns */
  readonly user_certificates_by_pk?: Maybe<User_Certificates>;
  /** fetch data from the table: "user_followed_industries_industry" */
  readonly user_followed_industries_industry: ReadonlyArray<User_Followed_Industries_Industry>;
  /** fetch aggregated fields from the table: "user_followed_industries_industry" */
  readonly user_followed_industries_industry_aggregate: User_Followed_Industries_Industry_Aggregate;
  /** fetch data from the table: "user_followed_industries_industry" using primary key columns */
  readonly user_followed_industries_industry_by_pk?: Maybe<User_Followed_Industries_Industry>;
  /** fetch data from the table: "user_provider_subscriptions_provider" */
  readonly user_provider_subscriptions_provider: ReadonlyArray<User_Provider_Subscriptions_Provider>;
  /** fetch aggregated fields from the table: "user_provider_subscriptions_provider" */
  readonly user_provider_subscriptions_provider_aggregate: User_Provider_Subscriptions_Provider_Aggregate;
  /** fetch data from the table: "user_provider_subscriptions_provider" using primary key columns */
  readonly user_provider_subscriptions_provider_by_pk?: Maybe<User_Provider_Subscriptions_Provider>;
  /** fetch data from the table: "user_purchased_events_event" */
  readonly user_purchased_events_event: ReadonlyArray<User_Purchased_Events_Event>;
  /** fetch aggregated fields from the table: "user_purchased_events_event" */
  readonly user_purchased_events_event_aggregate: User_Purchased_Events_Event_Aggregate;
  /** fetch data from the table: "user_purchased_events_event" using primary key columns */
  readonly user_purchased_events_event_by_pk?: Maybe<User_Purchased_Events_Event>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch aggregated fields from the table: "users" */
  readonly users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
  /** This action checks whether the code given by the student matches the access code defined by the form creator. */
  readonly validateFormAccessCode?: Maybe<ValidateFormAccessCodeOutput>;
};


export type Query_RootAccount_GoogleArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


export type Query_RootAccount_Google_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


export type Query_RootAccount_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_Subscription_PlansArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscription_Plans_Order_By>>;
  where?: Maybe<Account_Subscription_Plans_Bool_Exp>;
};


export type Query_RootAccount_Subscription_Plans_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscription_Plans_Order_By>>;
  where?: Maybe<Account_Subscription_Plans_Bool_Exp>;
};


export type Query_RootAccount_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootAccount_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


export type Query_RootAccount_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


export type Query_RootAccount_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_ZoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


export type Query_RootAccount_Zoom_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


export type Query_RootAccount_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAr_Internal_MetadataArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Order_By>>;
  where?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
};


export type Query_RootAr_Internal_Metadata_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Order_By>>;
  where?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
};


export type Query_RootAr_Internal_Metadata_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootAttachmentArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Query_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Query_RootAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAttendance_LogsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};


export type Query_RootAttendance_Logs_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};


export type Query_RootAttendance_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAttendancesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


export type Query_RootAttendances_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


export type Query_RootAttendances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBreakout_GroupArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};


export type Query_RootBreakout_Group_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};


export type Query_RootBreakout_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBreakout_Group_Participants_Event_ParticipantArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


export type Query_RootBreakout_Group_Participants_Event_Participant_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


export type Query_RootBreakout_Group_Participants_Event_Participant_By_PkArgs = {
  breakoutGroupId: Scalars['uuid'];
  eventParticipantId: Scalars['uuid'];
};


export type Query_RootBreakout_RoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


export type Query_RootBreakout_Room_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


export type Query_RootBreakout_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBunny_UploadArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


export type Query_RootBunny_Upload_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


export type Query_RootBunny_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBunny_Upload_StatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_States_Order_By>>;
  where?: Maybe<Bunny_Upload_States_Bool_Exp>;
};


export type Query_RootBunny_Upload_States_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_States_Order_By>>;
  where?: Maybe<Bunny_Upload_States_Bool_Exp>;
};


export type Query_RootBunny_Upload_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCarousel_ItemArgs = {
  distinct_on?: Maybe<ReadonlyArray<Carousel_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Carousel_Item_Order_By>>;
  where?: Maybe<Carousel_Item_Bool_Exp>;
};


export type Query_RootCarousel_Item_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Carousel_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Carousel_Item_Order_By>>;
  where?: Maybe<Carousel_Item_Bool_Exp>;
};


export type Query_RootCarousel_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCertificate_SignatoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


export type Query_RootCertificate_Signatory_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


export type Query_RootCertificate_Signatory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCertificate_Template_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


export type Query_RootCertificate_Template_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


export type Query_RootCertificate_Template_Signatories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCertificate_TemplatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


export type Query_RootCertificate_Templates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


export type Query_RootCertificate_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_ArticlesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


export type Query_RootCourse_Articles_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


export type Query_RootCourse_Articles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_AssessmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


export type Query_RootCourse_Assessments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


export type Query_RootCourse_Assessments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_AttachmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


export type Query_RootCourse_Attachments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


export type Query_RootCourse_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


export type Query_RootCourse_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


export type Query_RootCourse_Certificates_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_Discount_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discount_Types_Order_By>>;
  where?: Maybe<Course_Discount_Types_Bool_Exp>;
};


export type Query_RootCourse_Discount_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discount_Types_Order_By>>;
  where?: Maybe<Course_Discount_Types_Bool_Exp>;
};


export type Query_RootCourse_Discount_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCourse_DiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


export type Query_RootCourse_Discounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


export type Query_RootCourse_Discounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


export type Query_RootCourse_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


export type Query_RootCourse_Forms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_GainsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


export type Query_RootCourse_Gains_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


export type Query_RootCourse_Gains_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_InstructorsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


export type Query_RootCourse_Instructors_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


export type Query_RootCourse_Instructors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_InvitesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


export type Query_RootCourse_Invites_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


export type Query_RootCourse_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_LecturersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


export type Query_RootCourse_Lecturers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


export type Query_RootCourse_Live_SessionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


export type Query_RootCourse_Live_Sessions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


export type Query_RootCourse_Live_Sessions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_Module_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


export type Query_RootCourse_Module_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


export type Query_RootCourse_Module_Students_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


export type Query_RootCourse_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


export type Query_RootCourse_Modules_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_ObjectivesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


export type Query_RootCourse_Objectives_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


export type Query_RootCourse_Objectives_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_OrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


export type Query_RootCourse_Order_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


export type Query_RootCourse_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_Order_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


export type Query_RootCourse_Order_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


export type Query_RootCourse_Order_Modules_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_Order_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Statuses_Order_By>>;
  where?: Maybe<Course_Order_Statuses_Bool_Exp>;
};


export type Query_RootCourse_Order_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Statuses_Order_By>>;
  where?: Maybe<Course_Order_Statuses_Bool_Exp>;
};


export type Query_RootCourse_Order_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCourse_Payment_MethodsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Payment_Methods_Order_By>>;
  where?: Maybe<Course_Payment_Methods_Bool_Exp>;
};


export type Query_RootCourse_Payment_Methods_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Payment_Methods_Order_By>>;
  where?: Maybe<Course_Payment_Methods_Bool_Exp>;
};


export type Query_RootCourse_Payment_Methods_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCourse_RemindersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


export type Query_RootCourse_Reminders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


export type Query_RootCourse_Reminders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCourse_RequirementsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


export type Query_RootCourse_Requirements_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


export type Query_RootCourse_Requirements_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


export type Query_RootCourse_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


export type Query_RootCourse_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_Sell_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Sell_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Sell_Types_Order_By>>;
  where?: Maybe<Course_Sell_Types_Bool_Exp>;
};


export type Query_RootCourse_Sell_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Sell_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Sell_Types_Order_By>>;
  where?: Maybe<Course_Sell_Types_Bool_Exp>;
};


export type Query_RootCourse_Sell_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCourse_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Signatories_Order_By>>;
  where?: Maybe<Course_Signatories_Bool_Exp>;
};


export type Query_RootCourse_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Signatories_Order_By>>;
  where?: Maybe<Course_Signatories_Bool_Exp>;
};


export type Query_RootCourse_Signatories_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};


export type Query_RootCourse_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};


export type Query_RootCourse_Students_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_VideosArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


export type Query_RootCourse_Videos_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


export type Query_RootCourse_Videos_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCourse_Videos_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Statuses_Order_By>>;
  where?: Maybe<Course_Videos_Statuses_Bool_Exp>;
};


export type Query_RootCourse_Videos_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Statuses_Order_By>>;
  where?: Maybe<Course_Videos_Statuses_Bool_Exp>;
};


export type Query_RootCourse_Videos_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCoursesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCpd_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


export type Query_RootCpd_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


export type Query_RootCpd_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCpd_Units_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


export type Query_RootCpd_Units_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


export type Query_RootCpd_Units_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomerArgs = {
  distinct_on?: Maybe<ReadonlyArray<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiscountArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


export type Query_RootDiscount_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


export type Query_RootDiscount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmail_Notification_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notification_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notification_Types_Order_By>>;
  where?: Maybe<Email_Notification_Types_Bool_Exp>;
};


export type Query_RootEmail_Notification_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notification_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notification_Types_Order_By>>;
  where?: Maybe<Email_Notification_Types_Bool_Exp>;
};


export type Query_RootEmail_Notification_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEmail_NotificationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


export type Query_RootEmail_Notifications_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


export type Query_RootEmail_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEventArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Query_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEvent_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


export type Query_RootEvent_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


export type Query_RootEvent_Industries_Industry_By_PkArgs = {
  eventId: Scalars['uuid'];
  industryName: Scalars['String'];
};


export type Query_RootEvent_Managing_Users_UserArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


export type Query_RootEvent_Managing_Users_User_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


export type Query_RootEvent_Managing_Users_User_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Query_RootEvent_ParticipantArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


export type Query_RootEvent_Participant_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


export type Query_RootEvent_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFaq_ItemArgs = {
  distinct_on?: Maybe<ReadonlyArray<Faq_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Faq_Item_Order_By>>;
  where?: Maybe<Faq_Item_Bool_Exp>;
};


export type Query_RootFaq_Item_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Faq_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Faq_Item_Order_By>>;
  where?: Maybe<Faq_Item_Bool_Exp>;
};


export type Query_RootFaq_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFormArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


export type Query_RootForm_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


export type Query_RootForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_Event_NamesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Event_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Event_Names_Order_By>>;
  where?: Maybe<Form_Event_Names_Bool_Exp>;
};


export type Query_RootForm_Event_Names_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Event_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Event_Names_Order_By>>;
  where?: Maybe<Form_Event_Names_Bool_Exp>;
};


export type Query_RootForm_Event_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootForm_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


export type Query_RootForm_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


export type Query_RootForm_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_InstanceArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


export type Query_RootForm_Instance_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


export type Query_RootForm_Instance_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_Question_AnswersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


export type Query_RootForm_Question_Answers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


export type Query_RootForm_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_Question_Submission_StatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submission_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submission_States_Order_By>>;
  where?: Maybe<Form_Question_Submission_States_Bool_Exp>;
};


export type Query_RootForm_Question_Submission_States_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submission_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submission_States_Order_By>>;
  where?: Maybe<Form_Question_Submission_States_Bool_Exp>;
};


export type Query_RootForm_Question_Submission_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootForm_Question_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


export type Query_RootForm_Question_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


export type Query_RootForm_Question_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_Question_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Types_Order_By>>;
  where?: Maybe<Form_Question_Types_Bool_Exp>;
};


export type Query_RootForm_Question_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Types_Order_By>>;
  where?: Maybe<Form_Question_Types_Bool_Exp>;
};


export type Query_RootForm_Question_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootForm_QuestionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


export type Query_RootForm_Questions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


export type Query_RootForm_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_ScoreArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


export type Query_RootForm_Score_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


export type Query_RootForm_Score_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_Scoring_PoliciesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Scoring_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Scoring_Policies_Order_By>>;
  where?: Maybe<Form_Scoring_Policies_Bool_Exp>;
};


export type Query_RootForm_Scoring_Policies_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Scoring_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Scoring_Policies_Order_By>>;
  where?: Maybe<Form_Scoring_Policies_Bool_Exp>;
};


export type Query_RootForm_Scoring_Policies_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootForm_Submission_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submission_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submission_Statuses_Order_By>>;
  where?: Maybe<Form_Submission_Statuses_Bool_Exp>;
};


export type Query_RootForm_Submission_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submission_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submission_Statuses_Order_By>>;
  where?: Maybe<Form_Submission_Statuses_Bool_Exp>;
};


export type Query_RootForm_Submission_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootForm_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Query_RootForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Query_RootForm_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Types_Order_By>>;
  where?: Maybe<Form_Types_Bool_Exp>;
};


export type Query_RootForm_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Types_Order_By>>;
  where?: Maybe<Form_Types_Bool_Exp>;
};


export type Query_RootForm_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Query_RootForms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Query_RootForms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImagesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


export type Query_RootImages_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


export type Query_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


export type Query_RootIndustry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


export type Query_RootIndustry_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootIndustry_CategoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Category_Order_By>>;
  where?: Maybe<Industry_Category_Bool_Exp>;
};


export type Query_RootIndustry_Category_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Category_Order_By>>;
  where?: Maybe<Industry_Category_Bool_Exp>;
};


export type Query_RootIndustry_Category_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootIndustry_TypeArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Type_Order_By>>;
  where?: Maybe<Industry_Type_Bool_Exp>;
};


export type Query_RootIndustry_Type_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Type_Order_By>>;
  where?: Maybe<Industry_Type_Bool_Exp>;
};


export type Query_RootIndustry_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootMigrationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMock_OrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order_Order_By>>;
  where?: Maybe<Mock_Order_Bool_Exp>;
};


export type Query_RootMock_Order2Args = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


export type Query_RootMock_Order2_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


export type Query_RootMock_Order2_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMock_Order_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order_Order_By>>;
  where?: Maybe<Mock_Order_Bool_Exp>;
};


export type Query_RootMock_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootModule_Item_CommentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


export type Query_RootModule_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


export type Query_RootModule_Item_Comments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootModule_ItemsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};


export type Query_RootModule_Items_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};


export type Query_RootModule_Items_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootOrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOther_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


export type Query_RootOther_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


export type Query_RootOther_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOther_Reward_TypeArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Type_Order_By>>;
  where?: Maybe<Other_Reward_Type_Bool_Exp>;
};


export type Query_RootOther_Reward_Type_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Type_Order_By>>;
  where?: Maybe<Other_Reward_Type_Bool_Exp>;
};


export type Query_RootOther_Reward_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootOther_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


export type Query_RootOther_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


export type Query_RootOther_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProductArgs = {
  distinct_on?: Maybe<ReadonlyArray<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProviderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Order_By>>;
  where?: Maybe<Provider_Bool_Exp>;
};


export type Query_RootProvider_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Order_By>>;
  where?: Maybe<Provider_Bool_Exp>;
};


export type Query_RootProvider_ApplicationArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


export type Query_RootProvider_Application_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


export type Query_RootProvider_Application_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_Application_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


export type Query_RootProvider_Application_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


export type Query_RootProvider_Application_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerApplicationId: Scalars['uuid'];
};


export type Query_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_Dragonpay_CredentialsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Order_By>>;
  where?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
};


export type Query_RootProvider_Dragonpay_Credentials_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Order_By>>;
  where?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
};


export type Query_RootProvider_Dragonpay_Credentials_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_Frequently_Used_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


export type Query_RootProvider_Frequently_Used_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


export type Query_RootProvider_Frequently_Used_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerId: Scalars['uuid'];
};


export type Query_RootProvider_GoogleArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


export type Query_RootProvider_Google_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


export type Query_RootProvider_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_Managing_Users_UserArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


export type Query_RootProvider_Managing_Users_User_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


export type Query_RootProvider_Managing_Users_User_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Query_RootProvider_Subscription_PlanArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Order_By>>;
  where?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
};


export type Query_RootProvider_Subscription_Plan_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Order_By>>;
  where?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
};


export type Query_RootProvider_Subscription_Plan_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootProvider_Subscription_PlansArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Order_By>>;
  where?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
};


export type Query_RootProvider_Subscription_Plans_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Order_By>>;
  where?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
};


export type Query_RootProvider_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootProvider_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


export type Query_RootProvider_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


export type Query_RootProvider_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_ZoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


export type Query_RootProvider_Zoom_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


export type Query_RootProvider_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRanking_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


export type Query_RootRanking_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


export type Query_RootRanking_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRanking_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};


export type Query_RootRanking_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};


export type Query_RootRanking_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootReward_CertificateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


export type Query_RootReward_Certificate_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


export type Query_RootReward_Certificate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReward_Certificate_Signatories_Certificate_SignatoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


export type Query_RootReward_Certificate_Signatories_Certificate_Signatory_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


export type Query_RootReward_Certificate_Signatories_Certificate_Signatory_By_PkArgs = {
  certificateSignatoryId: Scalars['uuid'];
  rewardCertificateId: Scalars['uuid'];
};


export type Query_RootSchema_MigrationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Schema_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Schema_Migrations_Order_By>>;
  where?: Maybe<Schema_Migrations_Bool_Exp>;
};


export type Query_RootSchema_Migrations_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Schema_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Schema_Migrations_Order_By>>;
  where?: Maybe<Schema_Migrations_Bool_Exp>;
};


export type Query_RootSchema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


export type Query_RootSpeakersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


export type Query_RootSpeakers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


export type Query_RootSpeakers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStreamArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


export type Query_RootStream_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


export type Query_RootStream_AttachmentArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


export type Query_RootStream_Attachment_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


export type Query_RootStream_Attachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStream_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStream_Forms_FormArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


export type Query_RootStream_Forms_Form_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


export type Query_RootStream_Forms_Form_By_PkArgs = {
  formId: Scalars['uuid'];
  streamId: Scalars['uuid'];
};


export type Query_RootStudent_ProgressArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


export type Query_RootStudent_Progress_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


export type Query_RootStudent_Progress_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


export type Query_RootUser_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


export type Query_RootUser_Certificates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Followed_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


export type Query_RootUser_Followed_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


export type Query_RootUser_Followed_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  userId: Scalars['String'];
};


export type Query_RootUser_Provider_Subscriptions_ProviderArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


export type Query_RootUser_Provider_Subscriptions_Provider_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


export type Query_RootUser_Provider_Subscriptions_Provider_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Query_RootUser_Purchased_Events_EventArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


export type Query_RootUser_Purchased_Events_Event_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


export type Query_RootUser_Purchased_Events_Event_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootValidateFormAccessCodeArgs = {
  code: Scalars['String'];
  formId: Scalars['uuid'];
};

/** columns and relationships of "ranking_reward" */
export type Ranking_Reward = {
  readonly __typename?: 'ranking_reward';
  readonly days: Scalars['Int'];
  /** An object relationship */
  readonly event?: Maybe<Event>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id: Scalars['uuid'];
  readonly rewardCertificateType: Scalars['String'];
  /** An array relationship */
  readonly reward_certificates: ReadonlyArray<Reward_Certificate>;
  /** An aggregate relationship */
  readonly reward_certificates_aggregate: Reward_Certificate_Aggregate;
  readonly type: Scalars['String'];
};


/** columns and relationships of "ranking_reward" */
export type Ranking_RewardReward_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "ranking_reward" */
export type Ranking_RewardReward_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};

/** aggregated selection of "ranking_reward" */
export type Ranking_Reward_Aggregate = {
  readonly __typename?: 'ranking_reward_aggregate';
  readonly aggregate?: Maybe<Ranking_Reward_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Ranking_Reward>;
};

/** aggregate fields of "ranking_reward" */
export type Ranking_Reward_Aggregate_Fields = {
  readonly __typename?: 'ranking_reward_aggregate_fields';
  readonly avg?: Maybe<Ranking_Reward_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Ranking_Reward_Max_Fields>;
  readonly min?: Maybe<Ranking_Reward_Min_Fields>;
  readonly stddev?: Maybe<Ranking_Reward_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Ranking_Reward_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Ranking_Reward_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Ranking_Reward_Sum_Fields>;
  readonly var_pop?: Maybe<Ranking_Reward_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Ranking_Reward_Var_Samp_Fields>;
  readonly variance?: Maybe<Ranking_Reward_Variance_Fields>;
};


/** aggregate fields of "ranking_reward" */
export type Ranking_Reward_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ranking_reward" */
export type Ranking_Reward_Aggregate_Order_By = {
  readonly avg?: Maybe<Ranking_Reward_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Ranking_Reward_Max_Order_By>;
  readonly min?: Maybe<Ranking_Reward_Min_Order_By>;
  readonly stddev?: Maybe<Ranking_Reward_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Ranking_Reward_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Ranking_Reward_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Ranking_Reward_Sum_Order_By>;
  readonly var_pop?: Maybe<Ranking_Reward_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Ranking_Reward_Var_Samp_Order_By>;
  readonly variance?: Maybe<Ranking_Reward_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ranking_reward" */
export type Ranking_Reward_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Ranking_Reward_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Ranking_Reward_On_Conflict>;
};

/** aggregate avg on columns */
export type Ranking_Reward_Avg_Fields = {
  readonly __typename?: 'ranking_reward_avg_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ranking_reward" */
export type Ranking_Reward_Avg_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ranking_reward". All fields are combined with a logical 'AND'. */
export type Ranking_Reward_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Ranking_Reward_Bool_Exp>>;
  readonly _not?: Maybe<Ranking_Reward_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Ranking_Reward_Bool_Exp>>;
  readonly days?: Maybe<Int_Comparison_Exp>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly rewardCertificateType?: Maybe<String_Comparison_Exp>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ranking_reward" */
export enum Ranking_Reward_Constraint {
  /** unique or primary key constraint */
  Pk_6f3fc5aa42b7f919471bf32a1f4 = 'PK_6f3fc5aa42b7f919471bf32a1f4',
  /** unique or primary key constraint */
  UqRankingReward = 'UQ_RANKING_REWARD'
}

/** input type for incrementing numeric columns in table "ranking_reward" */
export type Ranking_Reward_Inc_Input = {
  readonly days?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ranking_reward" */
export type Ranking_Reward_Insert_Input = {
  readonly days?: Maybe<Scalars['Int']>;
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Arr_Rel_Insert_Input>;
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ranking_Reward_Max_Fields = {
  readonly __typename?: 'ranking_reward_max_fields';
  readonly days?: Maybe<Scalars['Int']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ranking_reward" */
export type Ranking_Reward_Max_Order_By = {
  readonly days?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ranking_Reward_Min_Fields = {
  readonly __typename?: 'ranking_reward_min_fields';
  readonly days?: Maybe<Scalars['Int']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ranking_reward" */
export type Ranking_Reward_Min_Order_By = {
  readonly days?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** response of any mutation on the table "ranking_reward" */
export type Ranking_Reward_Mutation_Response = {
  readonly __typename?: 'ranking_reward_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Ranking_Reward>;
};

/** input type for inserting object relation for remote table "ranking_reward" */
export type Ranking_Reward_Obj_Rel_Insert_Input = {
  readonly data: Ranking_Reward_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Ranking_Reward_On_Conflict>;
};

/** on conflict condition type for table "ranking_reward" */
export type Ranking_Reward_On_Conflict = {
  readonly constraint: Ranking_Reward_Constraint;
  readonly update_columns?: ReadonlyArray<Ranking_Reward_Update_Column>;
  readonly where?: Maybe<Ranking_Reward_Bool_Exp>;
};

/** Ordering options when selecting data from "ranking_reward". */
export type Ranking_Reward_Order_By = {
  readonly days?: Maybe<Order_By>;
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly rewardCertificateType?: Maybe<Order_By>;
  readonly reward_certificates_aggregate?: Maybe<Reward_Certificate_Aggregate_Order_By>;
  readonly type?: Maybe<Order_By>;
};

/** primary key columns input for table: ranking_reward */
export type Ranking_Reward_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "ranking_reward" */
export enum Ranking_Reward_Select_Column {
  /** column name */
  Days = 'days',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "ranking_reward" */
export type Ranking_Reward_Set_Input = {
  readonly days?: Maybe<Scalars['Int']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateType?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ranking_Reward_Stddev_Fields = {
  readonly __typename?: 'ranking_reward_stddev_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ranking_reward" */
export type Ranking_Reward_Stddev_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ranking_Reward_Stddev_Pop_Fields = {
  readonly __typename?: 'ranking_reward_stddev_pop_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ranking_reward" */
export type Ranking_Reward_Stddev_Pop_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ranking_Reward_Stddev_Samp_Fields = {
  readonly __typename?: 'ranking_reward_stddev_samp_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ranking_reward" */
export type Ranking_Reward_Stddev_Samp_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ranking_Reward_Sum_Fields = {
  readonly __typename?: 'ranking_reward_sum_fields';
  readonly days?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ranking_reward" */
export type Ranking_Reward_Sum_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** update columns of table "ranking_reward" */
export enum Ranking_Reward_Update_Column {
  /** column name */
  Days = 'days',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  RewardCertificateType = 'rewardCertificateType',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Ranking_Reward_Var_Pop_Fields = {
  readonly __typename?: 'ranking_reward_var_pop_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ranking_reward" */
export type Ranking_Reward_Var_Pop_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ranking_Reward_Var_Samp_Fields = {
  readonly __typename?: 'ranking_reward_var_samp_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ranking_reward" */
export type Ranking_Reward_Var_Samp_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ranking_Reward_Variance_Fields = {
  readonly __typename?: 'ranking_reward_variance_fields';
  readonly days?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ranking_reward" */
export type Ranking_Reward_Variance_Order_By = {
  readonly days?: Maybe<Order_By>;
};

/** columns and relationships of "ranking_rewards" */
export type Ranking_Rewards = {
  readonly __typename?: 'ranking_rewards';
  /** An object relationship */
  readonly course_reward?: Maybe<Course_Rewards>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days: Scalars['Int'];
  readonly id: Scalars['bigint'];
  readonly ranking_type: Scalars['Int'];
};

/** aggregated selection of "ranking_rewards" */
export type Ranking_Rewards_Aggregate = {
  readonly __typename?: 'ranking_rewards_aggregate';
  readonly aggregate?: Maybe<Ranking_Rewards_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Ranking_Rewards>;
};

/** aggregate fields of "ranking_rewards" */
export type Ranking_Rewards_Aggregate_Fields = {
  readonly __typename?: 'ranking_rewards_aggregate_fields';
  readonly avg?: Maybe<Ranking_Rewards_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Ranking_Rewards_Max_Fields>;
  readonly min?: Maybe<Ranking_Rewards_Min_Fields>;
  readonly stddev?: Maybe<Ranking_Rewards_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Ranking_Rewards_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Ranking_Rewards_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Ranking_Rewards_Sum_Fields>;
  readonly var_pop?: Maybe<Ranking_Rewards_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Ranking_Rewards_Var_Samp_Fields>;
  readonly variance?: Maybe<Ranking_Rewards_Variance_Fields>;
};


/** aggregate fields of "ranking_rewards" */
export type Ranking_Rewards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ranking_rewards" */
export type Ranking_Rewards_Aggregate_Order_By = {
  readonly avg?: Maybe<Ranking_Rewards_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Ranking_Rewards_Max_Order_By>;
  readonly min?: Maybe<Ranking_Rewards_Min_Order_By>;
  readonly stddev?: Maybe<Ranking_Rewards_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Ranking_Rewards_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Ranking_Rewards_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Ranking_Rewards_Sum_Order_By>;
  readonly var_pop?: Maybe<Ranking_Rewards_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Ranking_Rewards_Var_Samp_Order_By>;
  readonly variance?: Maybe<Ranking_Rewards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ranking_rewards" */
export type Ranking_Rewards_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Ranking_Rewards_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Ranking_Rewards_On_Conflict>;
};

/** aggregate avg on columns */
export type Ranking_Rewards_Avg_Fields = {
  readonly __typename?: 'ranking_rewards_avg_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Avg_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ranking_rewards". All fields are combined with a logical 'AND'. */
export type Ranking_Rewards_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Ranking_Rewards_Bool_Exp>>;
  readonly _not?: Maybe<Ranking_Rewards_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Ranking_Rewards_Bool_Exp>>;
  readonly course_reward?: Maybe<Course_Rewards_Bool_Exp>;
  readonly course_reward_id?: Maybe<Bigint_Comparison_Exp>;
  readonly days?: Maybe<Int_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly ranking_type?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ranking_rewards" */
export enum Ranking_Rewards_Constraint {
  /** unique or primary key constraint */
  RankingRewardsPkey = 'ranking_rewards_pkey'
}

/** input type for incrementing numeric columns in table "ranking_rewards" */
export type Ranking_Rewards_Inc_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ranking_rewards" */
export type Ranking_Rewards_Insert_Input = {
  readonly course_reward?: Maybe<Course_Rewards_Obj_Rel_Insert_Input>;
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ranking_Rewards_Max_Fields = {
  readonly __typename?: 'ranking_rewards_max_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Max_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ranking_Rewards_Min_Fields = {
  readonly __typename?: 'ranking_rewards_min_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Min_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "ranking_rewards" */
export type Ranking_Rewards_Mutation_Response = {
  readonly __typename?: 'ranking_rewards_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Ranking_Rewards>;
};

/** on conflict condition type for table "ranking_rewards" */
export type Ranking_Rewards_On_Conflict = {
  readonly constraint: Ranking_Rewards_Constraint;
  readonly update_columns?: ReadonlyArray<Ranking_Rewards_Update_Column>;
  readonly where?: Maybe<Ranking_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "ranking_rewards". */
export type Ranking_Rewards_Order_By = {
  readonly course_reward?: Maybe<Course_Rewards_Order_By>;
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** primary key columns input for table: ranking_rewards */
export type Ranking_Rewards_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "ranking_rewards" */
export enum Ranking_Rewards_Select_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  RankingType = 'ranking_type'
}

/** input type for updating data in table "ranking_rewards" */
export type Ranking_Rewards_Set_Input = {
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ranking_Rewards_Stddev_Fields = {
  readonly __typename?: 'ranking_rewards_stddev_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Stddev_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ranking_Rewards_Stddev_Pop_Fields = {
  readonly __typename?: 'ranking_rewards_stddev_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Stddev_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ranking_Rewards_Stddev_Samp_Fields = {
  readonly __typename?: 'ranking_rewards_stddev_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Stddev_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ranking_Rewards_Sum_Fields = {
  readonly __typename?: 'ranking_rewards_sum_fields';
  readonly course_reward_id?: Maybe<Scalars['bigint']>;
  readonly days?: Maybe<Scalars['Int']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly ranking_type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Sum_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** update columns of table "ranking_rewards" */
export enum Ranking_Rewards_Update_Column {
  /** column name */
  CourseRewardId = 'course_reward_id',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  RankingType = 'ranking_type'
}

/** aggregate var_pop on columns */
export type Ranking_Rewards_Var_Pop_Fields = {
  readonly __typename?: 'ranking_rewards_var_pop_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Var_Pop_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ranking_Rewards_Var_Samp_Fields = {
  readonly __typename?: 'ranking_rewards_var_samp_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Var_Samp_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ranking_Rewards_Variance_Fields = {
  readonly __typename?: 'ranking_rewards_variance_fields';
  readonly course_reward_id?: Maybe<Scalars['Float']>;
  readonly days?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly ranking_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ranking_rewards" */
export type Ranking_Rewards_Variance_Order_By = {
  readonly course_reward_id?: Maybe<Order_By>;
  readonly days?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly ranking_type?: Maybe<Order_By>;
};

/** columns and relationships of "reward_certificate" */
export type Reward_Certificate = {
  readonly __typename?: 'reward_certificate';
  readonly cpdUnitsRewardId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward>;
  readonly createdAt: Scalars['timestamp'];
  /** An object relationship */
  readonly event?: Maybe<Event>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id: Scalars['uuid'];
  readonly identifier?: Maybe<Scalars['String']>;
  readonly otherRewardId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly other_reward?: Maybe<Other_Reward>;
  readonly rankingRewardId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly ranking_reward?: Maybe<Ranking_Reward>;
  readonly recipientUserId?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly reward_certificate_signatories_certificate_signatories: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** An aggregate relationship */
  readonly reward_certificate_signatories_certificate_signatories_aggregate: Reward_Certificate_Signatories_Certificate_Signatory_Aggregate;
  readonly updatedAt: Scalars['timestamp'];
  /** An object relationship */
  readonly user?: Maybe<User>;
  /** An array relationship */
  readonly users: ReadonlyArray<User>;
  /** An aggregate relationship */
  readonly users_aggregate: User_Aggregate;
};


/** columns and relationships of "reward_certificate" */
export type Reward_CertificateReward_Certificate_Signatories_Certificate_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


/** columns and relationships of "reward_certificate" */
export type Reward_CertificateReward_Certificate_Signatories_Certificate_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


/** columns and relationships of "reward_certificate" */
export type Reward_CertificateUsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "reward_certificate" */
export type Reward_CertificateUsers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** aggregated selection of "reward_certificate" */
export type Reward_Certificate_Aggregate = {
  readonly __typename?: 'reward_certificate_aggregate';
  readonly aggregate?: Maybe<Reward_Certificate_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Reward_Certificate>;
};

/** aggregate fields of "reward_certificate" */
export type Reward_Certificate_Aggregate_Fields = {
  readonly __typename?: 'reward_certificate_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Reward_Certificate_Max_Fields>;
  readonly min?: Maybe<Reward_Certificate_Min_Fields>;
};


/** aggregate fields of "reward_certificate" */
export type Reward_Certificate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reward_certificate" */
export type Reward_Certificate_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Reward_Certificate_Max_Order_By>;
  readonly min?: Maybe<Reward_Certificate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reward_certificate" */
export type Reward_Certificate_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Reward_Certificate_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Reward_Certificate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reward_certificate". All fields are combined with a logical 'AND'. */
export type Reward_Certificate_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Reward_Certificate_Bool_Exp>>;
  readonly _not?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Reward_Certificate_Bool_Exp>>;
  readonly cpdUnitsRewardId?: Maybe<Uuid_Comparison_Exp>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly identifier?: Maybe<String_Comparison_Exp>;
  readonly otherRewardId?: Maybe<Uuid_Comparison_Exp>;
  readonly other_reward?: Maybe<Other_Reward_Bool_Exp>;
  readonly rankingRewardId?: Maybe<Uuid_Comparison_Exp>;
  readonly ranking_reward?: Maybe<Ranking_Reward_Bool_Exp>;
  readonly recipientUserId?: Maybe<String_Comparison_Exp>;
  readonly reward_certificate_signatories_certificate_signatories?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly users?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "reward_certificate" */
export enum Reward_Certificate_Constraint {
  /** unique or primary key constraint */
  IdxC9f6b21a19fa9fdfe988d54fce = 'IDX_c9f6b21a19fa9fdfe988d54fce',
  /** unique or primary key constraint */
  Pk_465c15e28ecb22a4e779f2c25ac = 'PK_465c15e28ecb22a4e779f2c25ac'
}

/** input type for inserting data into table "reward_certificate" */
export type Reward_Certificate_Insert_Input = {
  readonly cpdUnitsRewardId?: Maybe<Scalars['uuid']>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Obj_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly otherRewardId?: Maybe<Scalars['uuid']>;
  readonly other_reward?: Maybe<Other_Reward_Obj_Rel_Insert_Input>;
  readonly rankingRewardId?: Maybe<Scalars['uuid']>;
  readonly ranking_reward?: Maybe<Ranking_Reward_Obj_Rel_Insert_Input>;
  readonly recipientUserId?: Maybe<Scalars['String']>;
  readonly reward_certificate_signatories_certificate_signatories?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Arr_Rel_Insert_Input>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly users?: Maybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reward_Certificate_Max_Fields = {
  readonly __typename?: 'reward_certificate_max_fields';
  readonly cpdUnitsRewardId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly otherRewardId?: Maybe<Scalars['uuid']>;
  readonly rankingRewardId?: Maybe<Scalars['uuid']>;
  readonly recipientUserId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "reward_certificate" */
export type Reward_Certificate_Max_Order_By = {
  readonly cpdUnitsRewardId?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly otherRewardId?: Maybe<Order_By>;
  readonly rankingRewardId?: Maybe<Order_By>;
  readonly recipientUserId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Certificate_Min_Fields = {
  readonly __typename?: 'reward_certificate_min_fields';
  readonly cpdUnitsRewardId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly otherRewardId?: Maybe<Scalars['uuid']>;
  readonly rankingRewardId?: Maybe<Scalars['uuid']>;
  readonly recipientUserId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "reward_certificate" */
export type Reward_Certificate_Min_Order_By = {
  readonly cpdUnitsRewardId?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly otherRewardId?: Maybe<Order_By>;
  readonly rankingRewardId?: Maybe<Order_By>;
  readonly recipientUserId?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "reward_certificate" */
export type Reward_Certificate_Mutation_Response = {
  readonly __typename?: 'reward_certificate_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Reward_Certificate>;
};

/** input type for inserting object relation for remote table "reward_certificate" */
export type Reward_Certificate_Obj_Rel_Insert_Input = {
  readonly data: Reward_Certificate_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Reward_Certificate_On_Conflict>;
};

/** on conflict condition type for table "reward_certificate" */
export type Reward_Certificate_On_Conflict = {
  readonly constraint: Reward_Certificate_Constraint;
  readonly update_columns?: ReadonlyArray<Reward_Certificate_Update_Column>;
  readonly where?: Maybe<Reward_Certificate_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_certificate". */
export type Reward_Certificate_Order_By = {
  readonly cpdUnitsRewardId?: Maybe<Order_By>;
  readonly cpd_units_reward?: Maybe<Cpd_Units_Reward_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly identifier?: Maybe<Order_By>;
  readonly otherRewardId?: Maybe<Order_By>;
  readonly other_reward?: Maybe<Other_Reward_Order_By>;
  readonly rankingRewardId?: Maybe<Order_By>;
  readonly ranking_reward?: Maybe<Ranking_Reward_Order_By>;
  readonly recipientUserId?: Maybe<Order_By>;
  readonly reward_certificate_signatories_certificate_signatories_aggregate?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly users_aggregate?: Maybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: reward_certificate */
export type Reward_Certificate_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "reward_certificate" */
export enum Reward_Certificate_Select_Column {
  /** column name */
  CpdUnitsRewardId = 'cpdUnitsRewardId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  OtherRewardId = 'otherRewardId',
  /** column name */
  RankingRewardId = 'rankingRewardId',
  /** column name */
  RecipientUserId = 'recipientUserId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "reward_certificate" */
export type Reward_Certificate_Set_Input = {
  readonly cpdUnitsRewardId?: Maybe<Scalars['uuid']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly otherRewardId?: Maybe<Scalars['uuid']>;
  readonly rankingRewardId?: Maybe<Scalars['uuid']>;
  readonly recipientUserId?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory';
  readonly certificateSignatoryId: Scalars['uuid'];
  /** An object relationship */
  readonly certificate_signatory: Certificate_Signatory;
  readonly rewardCertificateId: Scalars['uuid'];
  /** An object relationship */
  readonly reward_certificate: Reward_Certificate;
};

/** aggregated selection of "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Aggregate = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory_aggregate';
  readonly aggregate?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
};

/** aggregate fields of "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_Fields = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Max_Fields>;
  readonly min?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Min_Fields>;
};


/** aggregate fields of "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Max_Order_By>;
  readonly min?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reward_certificate_signatories_certificate_signatory". All fields are combined with a logical 'AND'. */
export type Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>>;
  readonly _not?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>>;
  readonly certificateSignatoryId?: Maybe<Uuid_Comparison_Exp>;
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Bool_Exp>;
  readonly rewardCertificateId?: Maybe<Uuid_Comparison_Exp>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Bool_Exp>;
};

/** unique or primary key constraints on table "reward_certificate_signatories_certificate_signatory" */
export enum Reward_Certificate_Signatories_Certificate_Signatory_Constraint {
  /** unique or primary key constraint */
  Pk_1b9b3579b864baf4a30dc5b9b3f = 'PK_1b9b3579b864baf4a30dc5b9b3f'
}

/** input type for inserting data into table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Insert_Input = {
  readonly certificateSignatoryId?: Maybe<Scalars['uuid']>;
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Obj_Rel_Insert_Input>;
  readonly rewardCertificateId?: Maybe<Scalars['uuid']>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reward_Certificate_Signatories_Certificate_Signatory_Max_Fields = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory_max_fields';
  readonly certificateSignatoryId?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Max_Order_By = {
  readonly certificateSignatoryId?: Maybe<Order_By>;
  readonly rewardCertificateId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reward_Certificate_Signatories_Certificate_Signatory_Min_Fields = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory_min_fields';
  readonly certificateSignatoryId?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Min_Order_By = {
  readonly certificateSignatoryId?: Maybe<Order_By>;
  readonly rewardCertificateId?: Maybe<Order_By>;
};

/** response of any mutation on the table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Mutation_Response = {
  readonly __typename?: 'reward_certificate_signatories_certificate_signatory_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
};

/** on conflict condition type for table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_On_Conflict = {
  readonly constraint: Reward_Certificate_Signatories_Certificate_Signatory_Constraint;
  readonly update_columns?: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Update_Column>;
  readonly where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};

/** Ordering options when selecting data from "reward_certificate_signatories_certificate_signatory". */
export type Reward_Certificate_Signatories_Certificate_Signatory_Order_By = {
  readonly certificateSignatoryId?: Maybe<Order_By>;
  readonly certificate_signatory?: Maybe<Certificate_Signatory_Order_By>;
  readonly rewardCertificateId?: Maybe<Order_By>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Order_By>;
};

/** primary key columns input for table: reward_certificate_signatories_certificate_signatory */
export type Reward_Certificate_Signatories_Certificate_Signatory_Pk_Columns_Input = {
  readonly certificateSignatoryId: Scalars['uuid'];
  readonly rewardCertificateId: Scalars['uuid'];
};

/** select columns of table "reward_certificate_signatories_certificate_signatory" */
export enum Reward_Certificate_Signatories_Certificate_Signatory_Select_Column {
  /** column name */
  CertificateSignatoryId = 'certificateSignatoryId',
  /** column name */
  RewardCertificateId = 'rewardCertificateId'
}

/** input type for updating data in table "reward_certificate_signatories_certificate_signatory" */
export type Reward_Certificate_Signatories_Certificate_Signatory_Set_Input = {
  readonly certificateSignatoryId?: Maybe<Scalars['uuid']>;
  readonly rewardCertificateId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "reward_certificate_signatories_certificate_signatory" */
export enum Reward_Certificate_Signatories_Certificate_Signatory_Update_Column {
  /** column name */
  CertificateSignatoryId = 'certificateSignatoryId',
  /** column name */
  RewardCertificateId = 'rewardCertificateId'
}

/** update columns of table "reward_certificate" */
export enum Reward_Certificate_Update_Column {
  /** column name */
  CpdUnitsRewardId = 'cpdUnitsRewardId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  OtherRewardId = 'otherRewardId',
  /** column name */
  RankingRewardId = 'rankingRewardId',
  /** column name */
  RecipientUserId = 'recipientUserId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "schema_migrations" */
export type Schema_Migrations = {
  readonly __typename?: 'schema_migrations';
  readonly version: Scalars['String'];
};

/** aggregated selection of "schema_migrations" */
export type Schema_Migrations_Aggregate = {
  readonly __typename?: 'schema_migrations_aggregate';
  readonly aggregate?: Maybe<Schema_Migrations_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Schema_Migrations>;
};

/** aggregate fields of "schema_migrations" */
export type Schema_Migrations_Aggregate_Fields = {
  readonly __typename?: 'schema_migrations_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Schema_Migrations_Max_Fields>;
  readonly min?: Maybe<Schema_Migrations_Min_Fields>;
};


/** aggregate fields of "schema_migrations" */
export type Schema_Migrations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Schema_Migrations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "schema_migrations". All fields are combined with a logical 'AND'. */
export type Schema_Migrations_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Schema_Migrations_Bool_Exp>>;
  readonly _not?: Maybe<Schema_Migrations_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Schema_Migrations_Bool_Exp>>;
  readonly version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "schema_migrations" */
export enum Schema_Migrations_Constraint {
  /** unique or primary key constraint */
  SchemaMigrationsPkey = 'schema_migrations_pkey'
}

/** input type for inserting data into table "schema_migrations" */
export type Schema_Migrations_Insert_Input = {
  readonly version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Schema_Migrations_Max_Fields = {
  readonly __typename?: 'schema_migrations_max_fields';
  readonly version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Schema_Migrations_Min_Fields = {
  readonly __typename?: 'schema_migrations_min_fields';
  readonly version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "schema_migrations" */
export type Schema_Migrations_Mutation_Response = {
  readonly __typename?: 'schema_migrations_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Schema_Migrations>;
};

/** on conflict condition type for table "schema_migrations" */
export type Schema_Migrations_On_Conflict = {
  readonly constraint: Schema_Migrations_Constraint;
  readonly update_columns?: ReadonlyArray<Schema_Migrations_Update_Column>;
  readonly where?: Maybe<Schema_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "schema_migrations". */
export type Schema_Migrations_Order_By = {
  readonly version?: Maybe<Order_By>;
};

/** primary key columns input for table: schema_migrations */
export type Schema_Migrations_Pk_Columns_Input = {
  readonly version: Scalars['String'];
};

/** select columns of table "schema_migrations" */
export enum Schema_Migrations_Select_Column {
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "schema_migrations" */
export type Schema_Migrations_Set_Input = {
  readonly version?: Maybe<Scalars['String']>;
};

/** update columns of table "schema_migrations" */
export enum Schema_Migrations_Update_Column {
  /** column name */
  Version = 'version'
}

/** columns and relationships of "speakers" */
export type Speakers = {
  readonly __typename?: 'speakers';
  /** An array relationship */
  readonly course_lecturers: ReadonlyArray<Course_Lecturers>;
  /** An aggregate relationship */
  readonly course_lecturers_aggregate: Course_Lecturers_Aggregate;
  readonly description: Scalars['String'];
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly id: Scalars['bigint'];
  readonly name: Scalars['String'];
  readonly user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "speakers" */
export type SpeakersCourse_LecturersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


/** columns and relationships of "speakers" */
export type SpeakersCourse_Lecturers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};

/** aggregated selection of "speakers" */
export type Speakers_Aggregate = {
  readonly __typename?: 'speakers_aggregate';
  readonly aggregate?: Maybe<Speakers_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Speakers>;
};

/** aggregate fields of "speakers" */
export type Speakers_Aggregate_Fields = {
  readonly __typename?: 'speakers_aggregate_fields';
  readonly avg?: Maybe<Speakers_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Speakers_Max_Fields>;
  readonly min?: Maybe<Speakers_Min_Fields>;
  readonly stddev?: Maybe<Speakers_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Speakers_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Speakers_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Speakers_Sum_Fields>;
  readonly var_pop?: Maybe<Speakers_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Speakers_Var_Samp_Fields>;
  readonly variance?: Maybe<Speakers_Variance_Fields>;
};


/** aggregate fields of "speakers" */
export type Speakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Speakers_Avg_Fields = {
  readonly __typename?: 'speakers_avg_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "speakers". All fields are combined with a logical 'AND'. */
export type Speakers_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Speakers_Bool_Exp>>;
  readonly _not?: Maybe<Speakers_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Speakers_Bool_Exp>>;
  readonly course_lecturers?: Maybe<Course_Lecturers_Bool_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly display_photo_uuid?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly name?: Maybe<String_Comparison_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "speakers" */
export enum Speakers_Constraint {
  /** unique or primary key constraint */
  SpeakersPkey = 'speakers_pkey'
}

/** input type for incrementing numeric columns in table "speakers" */
export type Speakers_Inc_Input = {
  readonly id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "speakers" */
export type Speakers_Insert_Input = {
  readonly course_lecturers?: Maybe<Course_Lecturers_Arr_Rel_Insert_Input>;
  readonly description?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Speakers_Max_Fields = {
  readonly __typename?: 'speakers_max_fields';
  readonly description?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Speakers_Min_Fields = {
  readonly __typename?: 'speakers_min_fields';
  readonly description?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "speakers" */
export type Speakers_Mutation_Response = {
  readonly __typename?: 'speakers_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Speakers>;
};

/** input type for inserting object relation for remote table "speakers" */
export type Speakers_Obj_Rel_Insert_Input = {
  readonly data: Speakers_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Speakers_On_Conflict>;
};

/** on conflict condition type for table "speakers" */
export type Speakers_On_Conflict = {
  readonly constraint: Speakers_Constraint;
  readonly update_columns?: ReadonlyArray<Speakers_Update_Column>;
  readonly where?: Maybe<Speakers_Bool_Exp>;
};

/** Ordering options when selecting data from "speakers". */
export type Speakers_Order_By = {
  readonly course_lecturers_aggregate?: Maybe<Course_Lecturers_Aggregate_Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly display_photo_uuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly name?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: speakers */
export type Speakers_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "speakers" */
export enum Speakers_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'display_photo_uuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "speakers" */
export type Speakers_Set_Input = {
  readonly description?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Speakers_Stddev_Fields = {
  readonly __typename?: 'speakers_stddev_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Speakers_Stddev_Pop_Fields = {
  readonly __typename?: 'speakers_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Speakers_Stddev_Samp_Fields = {
  readonly __typename?: 'speakers_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Speakers_Sum_Fields = {
  readonly __typename?: 'speakers_sum_fields';
  readonly id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "speakers" */
export enum Speakers_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisplayPhotoUuid = 'display_photo_uuid',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Speakers_Var_Pop_Fields = {
  readonly __typename?: 'speakers_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Speakers_Var_Samp_Fields = {
  readonly __typename?: 'speakers_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Speakers_Variance_Fields = {
  readonly __typename?: 'speakers_variance_fields';
  readonly id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "stream" */
export type Stream = {
  readonly __typename?: 'stream';
  readonly actualTimeEnd?: Maybe<Scalars['timestamp']>;
  readonly actualTimeStart?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  readonly breakout_rooms: ReadonlyArray<Breakout_Room>;
  /** An aggregate relationship */
  readonly breakout_rooms_aggregate: Breakout_Room_Aggregate;
  readonly createdAt: Scalars['timestamp'];
  readonly description: Scalars['String'];
  /** An object relationship */
  readonly event?: Maybe<Event>;
  readonly id: Scalars['uuid'];
  readonly isArchived: Scalars['Boolean'];
  readonly parentEventId?: Maybe<Scalars['uuid']>;
  readonly speakers: Scalars['_text'];
  readonly streamDisplayPhotoUuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly stream_attachments: ReadonlyArray<Stream_Attachment>;
  /** An aggregate relationship */
  readonly stream_attachments_aggregate: Stream_Attachment_Aggregate;
  /** An array relationship */
  readonly stream_forms_forms: ReadonlyArray<Stream_Forms_Form>;
  /** An aggregate relationship */
  readonly stream_forms_forms_aggregate: Stream_Forms_Form_Aggregate;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart: Scalars['timestamp'];
  readonly title: Scalars['String'];
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "stream" */
export type StreamBreakout_RoomsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


/** columns and relationships of "stream" */
export type StreamBreakout_Rooms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


/** columns and relationships of "stream" */
export type StreamStream_AttachmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


/** columns and relationships of "stream" */
export type StreamStream_Attachments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


/** columns and relationships of "stream" */
export type StreamStream_Forms_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


/** columns and relationships of "stream" */
export type StreamStream_Forms_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};

/** aggregated selection of "stream" */
export type Stream_Aggregate = {
  readonly __typename?: 'stream_aggregate';
  readonly aggregate?: Maybe<Stream_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Stream>;
};

/** aggregate fields of "stream" */
export type Stream_Aggregate_Fields = {
  readonly __typename?: 'stream_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Stream_Max_Fields>;
  readonly min?: Maybe<Stream_Min_Fields>;
};


/** aggregate fields of "stream" */
export type Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stream" */
export type Stream_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Stream_Max_Order_By>;
  readonly min?: Maybe<Stream_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stream" */
export type Stream_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Stream_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Stream_On_Conflict>;
};

/** columns and relationships of "stream_attachment" */
export type Stream_Attachment = {
  readonly __typename?: 'stream_attachment';
  readonly fileName: Scalars['String'];
  readonly fileUuid: Scalars['String'];
  readonly id: Scalars['uuid'];
  readonly parentStreamId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  readonly stream?: Maybe<Stream>;
};

/** aggregated selection of "stream_attachment" */
export type Stream_Attachment_Aggregate = {
  readonly __typename?: 'stream_attachment_aggregate';
  readonly aggregate?: Maybe<Stream_Attachment_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Stream_Attachment>;
};

/** aggregate fields of "stream_attachment" */
export type Stream_Attachment_Aggregate_Fields = {
  readonly __typename?: 'stream_attachment_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Stream_Attachment_Max_Fields>;
  readonly min?: Maybe<Stream_Attachment_Min_Fields>;
};


/** aggregate fields of "stream_attachment" */
export type Stream_Attachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stream_attachment" */
export type Stream_Attachment_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Stream_Attachment_Max_Order_By>;
  readonly min?: Maybe<Stream_Attachment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stream_attachment" */
export type Stream_Attachment_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Stream_Attachment_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Stream_Attachment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stream_attachment". All fields are combined with a logical 'AND'. */
export type Stream_Attachment_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Stream_Attachment_Bool_Exp>>;
  readonly _not?: Maybe<Stream_Attachment_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Stream_Attachment_Bool_Exp>>;
  readonly fileName?: Maybe<String_Comparison_Exp>;
  readonly fileUuid?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly parentStreamId?: Maybe<Uuid_Comparison_Exp>;
  readonly stream?: Maybe<Stream_Bool_Exp>;
};

/** unique or primary key constraints on table "stream_attachment" */
export enum Stream_Attachment_Constraint {
  /** unique or primary key constraint */
  Pk_9d83f18c3c994ca0ad5b7e08321 = 'PK_9d83f18c3c994ca0ad5b7e08321'
}

/** input type for inserting data into table "stream_attachment" */
export type Stream_Attachment_Insert_Input = {
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentStreamId?: Maybe<Scalars['uuid']>;
  readonly stream?: Maybe<Stream_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stream_Attachment_Max_Fields = {
  readonly __typename?: 'stream_attachment_max_fields';
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentStreamId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "stream_attachment" */
export type Stream_Attachment_Max_Order_By = {
  readonly fileName?: Maybe<Order_By>;
  readonly fileUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentStreamId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stream_Attachment_Min_Fields = {
  readonly __typename?: 'stream_attachment_min_fields';
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentStreamId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "stream_attachment" */
export type Stream_Attachment_Min_Order_By = {
  readonly fileName?: Maybe<Order_By>;
  readonly fileUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentStreamId?: Maybe<Order_By>;
};

/** response of any mutation on the table "stream_attachment" */
export type Stream_Attachment_Mutation_Response = {
  readonly __typename?: 'stream_attachment_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Stream_Attachment>;
};

/** on conflict condition type for table "stream_attachment" */
export type Stream_Attachment_On_Conflict = {
  readonly constraint: Stream_Attachment_Constraint;
  readonly update_columns?: ReadonlyArray<Stream_Attachment_Update_Column>;
  readonly where?: Maybe<Stream_Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "stream_attachment". */
export type Stream_Attachment_Order_By = {
  readonly fileName?: Maybe<Order_By>;
  readonly fileUuid?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentStreamId?: Maybe<Order_By>;
  readonly stream?: Maybe<Stream_Order_By>;
};

/** primary key columns input for table: stream_attachment */
export type Stream_Attachment_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "stream_attachment" */
export enum Stream_Attachment_Select_Column {
  /** column name */
  FileName = 'fileName',
  /** column name */
  FileUuid = 'fileUuid',
  /** column name */
  Id = 'id',
  /** column name */
  ParentStreamId = 'parentStreamId'
}

/** input type for updating data in table "stream_attachment" */
export type Stream_Attachment_Set_Input = {
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUuid?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentStreamId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "stream_attachment" */
export enum Stream_Attachment_Update_Column {
  /** column name */
  FileName = 'fileName',
  /** column name */
  FileUuid = 'fileUuid',
  /** column name */
  Id = 'id',
  /** column name */
  ParentStreamId = 'parentStreamId'
}

/** Boolean expression to filter rows from the table "stream". All fields are combined with a logical 'AND'. */
export type Stream_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Stream_Bool_Exp>>;
  readonly _not?: Maybe<Stream_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Stream_Bool_Exp>>;
  readonly actualTimeEnd?: Maybe<Timestamp_Comparison_Exp>;
  readonly actualTimeStart?: Maybe<Timestamp_Comparison_Exp>;
  readonly breakout_rooms?: Maybe<Breakout_Room_Bool_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly description?: Maybe<String_Comparison_Exp>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly isArchived?: Maybe<Boolean_Comparison_Exp>;
  readonly parentEventId?: Maybe<Uuid_Comparison_Exp>;
  readonly speakers?: Maybe<_Text_Comparison_Exp>;
  readonly streamDisplayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly stream_attachments?: Maybe<Stream_Attachment_Bool_Exp>;
  readonly stream_forms_forms?: Maybe<Stream_Forms_Form_Bool_Exp>;
  readonly timeEnd?: Maybe<Timestamp_Comparison_Exp>;
  readonly timeStart?: Maybe<Timestamp_Comparison_Exp>;
  readonly title?: Maybe<String_Comparison_Exp>;
  readonly type?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "stream" */
export enum Stream_Constraint {
  /** unique or primary key constraint */
  Pk_0dc9d7e04ff213c08a096f835f2 = 'PK_0dc9d7e04ff213c08a096f835f2'
}

/** columns and relationships of "stream_forms_form" */
export type Stream_Forms_Form = {
  readonly __typename?: 'stream_forms_form';
  /** An object relationship */
  readonly form: Form;
  readonly formId: Scalars['uuid'];
  /** An object relationship */
  readonly stream: Stream;
  readonly streamId: Scalars['uuid'];
};

/** aggregated selection of "stream_forms_form" */
export type Stream_Forms_Form_Aggregate = {
  readonly __typename?: 'stream_forms_form_aggregate';
  readonly aggregate?: Maybe<Stream_Forms_Form_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Stream_Forms_Form>;
};

/** aggregate fields of "stream_forms_form" */
export type Stream_Forms_Form_Aggregate_Fields = {
  readonly __typename?: 'stream_forms_form_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Stream_Forms_Form_Max_Fields>;
  readonly min?: Maybe<Stream_Forms_Form_Min_Fields>;
};


/** aggregate fields of "stream_forms_form" */
export type Stream_Forms_Form_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stream_forms_form" */
export type Stream_Forms_Form_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Stream_Forms_Form_Max_Order_By>;
  readonly min?: Maybe<Stream_Forms_Form_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stream_forms_form" */
export type Stream_Forms_Form_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Stream_Forms_Form_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Stream_Forms_Form_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stream_forms_form". All fields are combined with a logical 'AND'. */
export type Stream_Forms_Form_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Stream_Forms_Form_Bool_Exp>>;
  readonly _not?: Maybe<Stream_Forms_Form_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Stream_Forms_Form_Bool_Exp>>;
  readonly form?: Maybe<Form_Bool_Exp>;
  readonly formId?: Maybe<Uuid_Comparison_Exp>;
  readonly stream?: Maybe<Stream_Bool_Exp>;
  readonly streamId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "stream_forms_form" */
export enum Stream_Forms_Form_Constraint {
  /** unique or primary key constraint */
  PkE760fd204fdf5a953bc287c1b76 = 'PK_e760fd204fdf5a953bc287c1b76'
}

/** input type for inserting data into table "stream_forms_form" */
export type Stream_Forms_Form_Insert_Input = {
  readonly form?: Maybe<Form_Obj_Rel_Insert_Input>;
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly stream?: Maybe<Stream_Obj_Rel_Insert_Input>;
  readonly streamId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Stream_Forms_Form_Max_Fields = {
  readonly __typename?: 'stream_forms_form_max_fields';
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "stream_forms_form" */
export type Stream_Forms_Form_Max_Order_By = {
  readonly formId?: Maybe<Order_By>;
  readonly streamId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stream_Forms_Form_Min_Fields = {
  readonly __typename?: 'stream_forms_form_min_fields';
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "stream_forms_form" */
export type Stream_Forms_Form_Min_Order_By = {
  readonly formId?: Maybe<Order_By>;
  readonly streamId?: Maybe<Order_By>;
};

/** response of any mutation on the table "stream_forms_form" */
export type Stream_Forms_Form_Mutation_Response = {
  readonly __typename?: 'stream_forms_form_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Stream_Forms_Form>;
};

/** on conflict condition type for table "stream_forms_form" */
export type Stream_Forms_Form_On_Conflict = {
  readonly constraint: Stream_Forms_Form_Constraint;
  readonly update_columns?: ReadonlyArray<Stream_Forms_Form_Update_Column>;
  readonly where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};

/** Ordering options when selecting data from "stream_forms_form". */
export type Stream_Forms_Form_Order_By = {
  readonly form?: Maybe<Form_Order_By>;
  readonly formId?: Maybe<Order_By>;
  readonly stream?: Maybe<Stream_Order_By>;
  readonly streamId?: Maybe<Order_By>;
};

/** primary key columns input for table: stream_forms_form */
export type Stream_Forms_Form_Pk_Columns_Input = {
  readonly formId: Scalars['uuid'];
  readonly streamId: Scalars['uuid'];
};

/** select columns of table "stream_forms_form" */
export enum Stream_Forms_Form_Select_Column {
  /** column name */
  FormId = 'formId',
  /** column name */
  StreamId = 'streamId'
}

/** input type for updating data in table "stream_forms_form" */
export type Stream_Forms_Form_Set_Input = {
  readonly formId?: Maybe<Scalars['uuid']>;
  readonly streamId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "stream_forms_form" */
export enum Stream_Forms_Form_Update_Column {
  /** column name */
  FormId = 'formId',
  /** column name */
  StreamId = 'streamId'
}

/** input type for inserting data into table "stream" */
export type Stream_Insert_Input = {
  readonly actualTimeEnd?: Maybe<Scalars['timestamp']>;
  readonly actualTimeStart?: Maybe<Scalars['timestamp']>;
  readonly breakout_rooms?: Maybe<Breakout_Room_Arr_Rel_Insert_Input>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly parentEventId?: Maybe<Scalars['uuid']>;
  readonly speakers?: Maybe<Scalars['_text']>;
  readonly streamDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly stream_attachments?: Maybe<Stream_Attachment_Arr_Rel_Insert_Input>;
  readonly stream_forms_forms?: Maybe<Stream_Forms_Form_Arr_Rel_Insert_Input>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Stream_Max_Fields = {
  readonly __typename?: 'stream_max_fields';
  readonly actualTimeEnd?: Maybe<Scalars['timestamp']>;
  readonly actualTimeStart?: Maybe<Scalars['timestamp']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentEventId?: Maybe<Scalars['uuid']>;
  readonly streamDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "stream" */
export type Stream_Max_Order_By = {
  readonly actualTimeEnd?: Maybe<Order_By>;
  readonly actualTimeStart?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentEventId?: Maybe<Order_By>;
  readonly streamDisplayPhotoUuid?: Maybe<Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stream_Min_Fields = {
  readonly __typename?: 'stream_min_fields';
  readonly actualTimeEnd?: Maybe<Scalars['timestamp']>;
  readonly actualTimeStart?: Maybe<Scalars['timestamp']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly parentEventId?: Maybe<Scalars['uuid']>;
  readonly streamDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "stream" */
export type Stream_Min_Order_By = {
  readonly actualTimeEnd?: Maybe<Order_By>;
  readonly actualTimeStart?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly parentEventId?: Maybe<Order_By>;
  readonly streamDisplayPhotoUuid?: Maybe<Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "stream" */
export type Stream_Mutation_Response = {
  readonly __typename?: 'stream_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Stream>;
};

/** input type for inserting object relation for remote table "stream" */
export type Stream_Obj_Rel_Insert_Input = {
  readonly data: Stream_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Stream_On_Conflict>;
};

/** on conflict condition type for table "stream" */
export type Stream_On_Conflict = {
  readonly constraint: Stream_Constraint;
  readonly update_columns?: ReadonlyArray<Stream_Update_Column>;
  readonly where?: Maybe<Stream_Bool_Exp>;
};

/** Ordering options when selecting data from "stream". */
export type Stream_Order_By = {
  readonly actualTimeEnd?: Maybe<Order_By>;
  readonly actualTimeStart?: Maybe<Order_By>;
  readonly breakout_rooms_aggregate?: Maybe<Breakout_Room_Aggregate_Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly description?: Maybe<Order_By>;
  readonly event?: Maybe<Event_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly isArchived?: Maybe<Order_By>;
  readonly parentEventId?: Maybe<Order_By>;
  readonly speakers?: Maybe<Order_By>;
  readonly streamDisplayPhotoUuid?: Maybe<Order_By>;
  readonly stream_attachments_aggregate?: Maybe<Stream_Attachment_Aggregate_Order_By>;
  readonly stream_forms_forms_aggregate?: Maybe<Stream_Forms_Form_Aggregate_Order_By>;
  readonly timeEnd?: Maybe<Order_By>;
  readonly timeStart?: Maybe<Order_By>;
  readonly title?: Maybe<Order_By>;
  readonly type?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: stream */
export type Stream_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "stream" */
export enum Stream_Select_Column {
  /** column name */
  ActualTimeEnd = 'actualTimeEnd',
  /** column name */
  ActualTimeStart = 'actualTimeStart',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  ParentEventId = 'parentEventId',
  /** column name */
  Speakers = 'speakers',
  /** column name */
  StreamDisplayPhotoUuid = 'streamDisplayPhotoUuid',
  /** column name */
  TimeEnd = 'timeEnd',
  /** column name */
  TimeStart = 'timeStart',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "stream" */
export type Stream_Set_Input = {
  readonly actualTimeEnd?: Maybe<Scalars['timestamp']>;
  readonly actualTimeStart?: Maybe<Scalars['timestamp']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly isArchived?: Maybe<Scalars['Boolean']>;
  readonly parentEventId?: Maybe<Scalars['uuid']>;
  readonly speakers?: Maybe<Scalars['_text']>;
  readonly streamDisplayPhotoUuid?: Maybe<Scalars['String']>;
  readonly timeEnd?: Maybe<Scalars['timestamp']>;
  readonly timeStart?: Maybe<Scalars['timestamp']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "stream" */
export enum Stream_Update_Column {
  /** column name */
  ActualTimeEnd = 'actualTimeEnd',
  /** column name */
  ActualTimeStart = 'actualTimeStart',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  ParentEventId = 'parentEventId',
  /** column name */
  Speakers = 'speakers',
  /** column name */
  StreamDisplayPhotoUuid = 'streamDisplayPhotoUuid',
  /** column name */
  TimeEnd = 'timeEnd',
  /** column name */
  TimeStart = 'timeStart',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "student_progress" */
export type Student_Progress = {
  readonly __typename?: 'student_progress';
  /** An object relationship */
  readonly course_student?: Maybe<Course_Students>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id: Scalars['bigint'];
  readonly is_complete?: Maybe<Scalars['Boolean']>;
  readonly is_locked: Scalars['Boolean'];
  /** An object relationship */
  readonly module_item?: Maybe<Module_Items>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "student_progress" */
export type Student_Progress_Aggregate = {
  readonly __typename?: 'student_progress_aggregate';
  readonly aggregate?: Maybe<Student_Progress_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Student_Progress>;
};

/** aggregate fields of "student_progress" */
export type Student_Progress_Aggregate_Fields = {
  readonly __typename?: 'student_progress_aggregate_fields';
  readonly avg?: Maybe<Student_Progress_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Student_Progress_Max_Fields>;
  readonly min?: Maybe<Student_Progress_Min_Fields>;
  readonly stddev?: Maybe<Student_Progress_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Student_Progress_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Student_Progress_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Student_Progress_Sum_Fields>;
  readonly var_pop?: Maybe<Student_Progress_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Student_Progress_Var_Samp_Fields>;
  readonly variance?: Maybe<Student_Progress_Variance_Fields>;
};


/** aggregate fields of "student_progress" */
export type Student_Progress_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_progress" */
export type Student_Progress_Aggregate_Order_By = {
  readonly avg?: Maybe<Student_Progress_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<Student_Progress_Max_Order_By>;
  readonly min?: Maybe<Student_Progress_Min_Order_By>;
  readonly stddev?: Maybe<Student_Progress_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<Student_Progress_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<Student_Progress_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<Student_Progress_Sum_Order_By>;
  readonly var_pop?: Maybe<Student_Progress_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<Student_Progress_Var_Samp_Order_By>;
  readonly variance?: Maybe<Student_Progress_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_progress" */
export type Student_Progress_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Student_Progress_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<Student_Progress_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Progress_Avg_Fields = {
  readonly __typename?: 'student_progress_avg_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_progress" */
export type Student_Progress_Avg_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_progress". All fields are combined with a logical 'AND'. */
export type Student_Progress_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Student_Progress_Bool_Exp>>;
  readonly _not?: Maybe<Student_Progress_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Student_Progress_Bool_Exp>>;
  readonly course_student?: Maybe<Course_Students_Bool_Exp>;
  readonly course_student_id?: Maybe<Bigint_Comparison_Exp>;
  readonly id?: Maybe<Bigint_Comparison_Exp>;
  readonly is_complete?: Maybe<Boolean_Comparison_Exp>;
  readonly is_locked?: Maybe<Boolean_Comparison_Exp>;
  readonly module_item?: Maybe<Module_Items_Bool_Exp>;
  readonly module_item_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_progress" */
export enum Student_Progress_Constraint {
  /** unique or primary key constraint */
  StudentProgressPkey = 'student_progress_pkey'
}

/** input type for incrementing numeric columns in table "student_progress" */
export type Student_Progress_Inc_Input = {
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "student_progress" */
export type Student_Progress_Insert_Input = {
  readonly course_student?: Maybe<Course_Students_Obj_Rel_Insert_Input>;
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_complete?: Maybe<Scalars['Boolean']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly module_item?: Maybe<Module_Items_Obj_Rel_Insert_Input>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Student_Progress_Max_Fields = {
  readonly __typename?: 'student_progress_max_fields';
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "student_progress" */
export type Student_Progress_Max_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Progress_Min_Fields = {
  readonly __typename?: 'student_progress_min_fields';
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "student_progress" */
export type Student_Progress_Min_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_progress" */
export type Student_Progress_Mutation_Response = {
  readonly __typename?: 'student_progress_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Student_Progress>;
};

/** on conflict condition type for table "student_progress" */
export type Student_Progress_On_Conflict = {
  readonly constraint: Student_Progress_Constraint;
  readonly update_columns?: ReadonlyArray<Student_Progress_Update_Column>;
  readonly where?: Maybe<Student_Progress_Bool_Exp>;
};

/** Ordering options when selecting data from "student_progress". */
export type Student_Progress_Order_By = {
  readonly course_student?: Maybe<Course_Students_Order_By>;
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly is_complete?: Maybe<Order_By>;
  readonly is_locked?: Maybe<Order_By>;
  readonly module_item?: Maybe<Module_Items_Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_progress */
export type Student_Progress_Pk_Columns_Input = {
  readonly id: Scalars['bigint'];
};

/** select columns of table "student_progress" */
export enum Student_Progress_Select_Column {
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** input type for updating data in table "student_progress" */
export type Student_Progress_Set_Input = {
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly is_complete?: Maybe<Scalars['Boolean']>;
  readonly is_locked?: Maybe<Scalars['Boolean']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Student_Progress_Stddev_Fields = {
  readonly __typename?: 'student_progress_stddev_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_progress" */
export type Student_Progress_Stddev_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Progress_Stddev_Pop_Fields = {
  readonly __typename?: 'student_progress_stddev_pop_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_progress" */
export type Student_Progress_Stddev_Pop_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Progress_Stddev_Samp_Fields = {
  readonly __typename?: 'student_progress_stddev_samp_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_progress" */
export type Student_Progress_Stddev_Samp_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Progress_Sum_Fields = {
  readonly __typename?: 'student_progress_sum_fields';
  readonly course_student_id?: Maybe<Scalars['bigint']>;
  readonly id?: Maybe<Scalars['bigint']>;
  readonly module_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "student_progress" */
export type Student_Progress_Sum_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** update columns of table "student_progress" */
export enum Student_Progress_Update_Column {
  /** column name */
  CourseStudentId = 'course_student_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  ModuleItemId = 'module_item_id'
}

/** aggregate var_pop on columns */
export type Student_Progress_Var_Pop_Fields = {
  readonly __typename?: 'student_progress_var_pop_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_progress" */
export type Student_Progress_Var_Pop_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Progress_Var_Samp_Fields = {
  readonly __typename?: 'student_progress_var_samp_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_progress" */
export type Student_Progress_Var_Samp_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Progress_Variance_Fields = {
  readonly __typename?: 'student_progress_variance_fields';
  readonly course_student_id?: Maybe<Scalars['Float']>;
  readonly id?: Maybe<Scalars['Float']>;
  readonly module_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_progress" */
export type Student_Progress_Variance_Order_By = {
  readonly course_student_id?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly module_item_id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  readonly __typename?: 'subscription_root';
  /** fetch data from the table: "account_google" */
  readonly account_google: ReadonlyArray<Account_Google>;
  /** fetch aggregated fields from the table: "account_google" */
  readonly account_google_aggregate: Account_Google_Aggregate;
  /** fetch data from the table: "account_google" using primary key columns */
  readonly account_google_by_pk?: Maybe<Account_Google>;
  /** fetch data from the table: "account_subscription_plans" */
  readonly account_subscription_plans: ReadonlyArray<Account_Subscription_Plans>;
  /** fetch aggregated fields from the table: "account_subscription_plans" */
  readonly account_subscription_plans_aggregate: Account_Subscription_Plans_Aggregate;
  /** fetch data from the table: "account_subscription_plans" using primary key columns */
  readonly account_subscription_plans_by_pk?: Maybe<Account_Subscription_Plans>;
  /** An array relationship */
  readonly account_subscriptions: ReadonlyArray<Account_Subscriptions>;
  /** An aggregate relationship */
  readonly account_subscriptions_aggregate: Account_Subscriptions_Aggregate;
  /** fetch data from the table: "account_subscriptions" using primary key columns */
  readonly account_subscriptions_by_pk?: Maybe<Account_Subscriptions>;
  /** fetch data from the table: "account_zoom" */
  readonly account_zoom: ReadonlyArray<Account_Zoom>;
  /** fetch aggregated fields from the table: "account_zoom" */
  readonly account_zoom_aggregate: Account_Zoom_Aggregate;
  /** fetch data from the table: "account_zoom" using primary key columns */
  readonly account_zoom_by_pk?: Maybe<Account_Zoom>;
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "ar_internal_metadata" */
  readonly ar_internal_metadata: ReadonlyArray<Ar_Internal_Metadata>;
  /** fetch aggregated fields from the table: "ar_internal_metadata" */
  readonly ar_internal_metadata_aggregate: Ar_Internal_Metadata_Aggregate;
  /** fetch data from the table: "ar_internal_metadata" using primary key columns */
  readonly ar_internal_metadata_by_pk?: Maybe<Ar_Internal_Metadata>;
  /** fetch data from the table: "attachment" */
  readonly attachment: ReadonlyArray<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  readonly attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  readonly attachment_by_pk?: Maybe<Attachment>;
  /** An array relationship */
  readonly attendance_logs: ReadonlyArray<Attendance_Logs>;
  /** An aggregate relationship */
  readonly attendance_logs_aggregate: Attendance_Logs_Aggregate;
  /** fetch data from the table: "attendance_logs" using primary key columns */
  readonly attendance_logs_by_pk?: Maybe<Attendance_Logs>;
  /** An array relationship */
  readonly attendances: ReadonlyArray<Attendances>;
  /** An aggregate relationship */
  readonly attendances_aggregate: Attendances_Aggregate;
  /** fetch data from the table: "attendances" using primary key columns */
  readonly attendances_by_pk?: Maybe<Attendances>;
  /** fetch data from the table: "breakout_group" */
  readonly breakout_group: ReadonlyArray<Breakout_Group>;
  /** fetch aggregated fields from the table: "breakout_group" */
  readonly breakout_group_aggregate: Breakout_Group_Aggregate;
  /** fetch data from the table: "breakout_group" using primary key columns */
  readonly breakout_group_by_pk?: Maybe<Breakout_Group>;
  /** fetch data from the table: "breakout_group_participants_event_participant" */
  readonly breakout_group_participants_event_participant: ReadonlyArray<Breakout_Group_Participants_Event_Participant>;
  /** fetch aggregated fields from the table: "breakout_group_participants_event_participant" */
  readonly breakout_group_participants_event_participant_aggregate: Breakout_Group_Participants_Event_Participant_Aggregate;
  /** fetch data from the table: "breakout_group_participants_event_participant" using primary key columns */
  readonly breakout_group_participants_event_participant_by_pk?: Maybe<Breakout_Group_Participants_Event_Participant>;
  /** fetch data from the table: "breakout_room" */
  readonly breakout_room: ReadonlyArray<Breakout_Room>;
  /** fetch aggregated fields from the table: "breakout_room" */
  readonly breakout_room_aggregate: Breakout_Room_Aggregate;
  /** fetch data from the table: "breakout_room" using primary key columns */
  readonly breakout_room_by_pk?: Maybe<Breakout_Room>;
  /** fetch data from the table: "bunny_upload" */
  readonly bunny_upload: ReadonlyArray<Bunny_Upload>;
  /** fetch aggregated fields from the table: "bunny_upload" */
  readonly bunny_upload_aggregate: Bunny_Upload_Aggregate;
  /** fetch data from the table: "bunny_upload" using primary key columns */
  readonly bunny_upload_by_pk?: Maybe<Bunny_Upload>;
  /** fetch data from the table: "bunny_upload_states" */
  readonly bunny_upload_states: ReadonlyArray<Bunny_Upload_States>;
  /** fetch aggregated fields from the table: "bunny_upload_states" */
  readonly bunny_upload_states_aggregate: Bunny_Upload_States_Aggregate;
  /** fetch data from the table: "bunny_upload_states" using primary key columns */
  readonly bunny_upload_states_by_pk?: Maybe<Bunny_Upload_States>;
  /** fetch data from the table: "carousel_item" */
  readonly carousel_item: ReadonlyArray<Carousel_Item>;
  /** fetch aggregated fields from the table: "carousel_item" */
  readonly carousel_item_aggregate: Carousel_Item_Aggregate;
  /** fetch data from the table: "carousel_item" using primary key columns */
  readonly carousel_item_by_pk?: Maybe<Carousel_Item>;
  /** fetch data from the table: "certificate_signatory" */
  readonly certificate_signatory: ReadonlyArray<Certificate_Signatory>;
  /** fetch aggregated fields from the table: "certificate_signatory" */
  readonly certificate_signatory_aggregate: Certificate_Signatory_Aggregate;
  /** fetch data from the table: "certificate_signatory" using primary key columns */
  readonly certificate_signatory_by_pk?: Maybe<Certificate_Signatory>;
  /** An array relationship */
  readonly certificate_template_signatories: ReadonlyArray<Certificate_Template_Signatories>;
  /** An aggregate relationship */
  readonly certificate_template_signatories_aggregate: Certificate_Template_Signatories_Aggregate;
  /** fetch data from the table: "certificate_template_signatories" using primary key columns */
  readonly certificate_template_signatories_by_pk?: Maybe<Certificate_Template_Signatories>;
  /** An array relationship */
  readonly certificate_templates: ReadonlyArray<Certificate_Templates>;
  /** An aggregate relationship */
  readonly certificate_templates_aggregate: Certificate_Templates_Aggregate;
  /** fetch data from the table: "certificate_templates" using primary key columns */
  readonly certificate_templates_by_pk?: Maybe<Certificate_Templates>;
  /** fetch data from the table: "course_articles" */
  readonly course_articles: ReadonlyArray<Course_Articles>;
  /** An aggregate relationship */
  readonly course_articles_aggregate: Course_Articles_Aggregate;
  /** fetch data from the table: "course_articles" using primary key columns */
  readonly course_articles_by_pk?: Maybe<Course_Articles>;
  /** An array relationship */
  readonly course_assessments: ReadonlyArray<Course_Assessments>;
  /** An aggregate relationship */
  readonly course_assessments_aggregate: Course_Assessments_Aggregate;
  /** fetch data from the table: "course_assessments" using primary key columns */
  readonly course_assessments_by_pk?: Maybe<Course_Assessments>;
  /** An array relationship */
  readonly course_attachments: ReadonlyArray<Course_Attachments>;
  /** An aggregate relationship */
  readonly course_attachments_aggregate: Course_Attachments_Aggregate;
  /** fetch data from the table: "course_attachments" using primary key columns */
  readonly course_attachments_by_pk?: Maybe<Course_Attachments>;
  /** An array relationship */
  readonly course_certificates: ReadonlyArray<Course_Certificates>;
  /** An aggregate relationship */
  readonly course_certificates_aggregate: Course_Certificates_Aggregate;
  /** fetch data from the table: "course_certificates" using primary key columns */
  readonly course_certificates_by_pk?: Maybe<Course_Certificates>;
  /** fetch data from the table: "course_discount_types" */
  readonly course_discount_types: ReadonlyArray<Course_Discount_Types>;
  /** fetch aggregated fields from the table: "course_discount_types" */
  readonly course_discount_types_aggregate: Course_Discount_Types_Aggregate;
  /** fetch data from the table: "course_discount_types" using primary key columns */
  readonly course_discount_types_by_pk?: Maybe<Course_Discount_Types>;
  /** An array relationship */
  readonly course_discounts: ReadonlyArray<Course_Discounts>;
  /** An aggregate relationship */
  readonly course_discounts_aggregate: Course_Discounts_Aggregate;
  /** fetch data from the table: "course_discounts" using primary key columns */
  readonly course_discounts_by_pk?: Maybe<Course_Discounts>;
  /** An array relationship */
  readonly course_forms: ReadonlyArray<Course_Forms>;
  /** An aggregate relationship */
  readonly course_forms_aggregate: Course_Forms_Aggregate;
  /** fetch data from the table: "course_forms" using primary key columns */
  readonly course_forms_by_pk?: Maybe<Course_Forms>;
  /** An array relationship */
  readonly course_gains: ReadonlyArray<Course_Gains>;
  /** An aggregate relationship */
  readonly course_gains_aggregate: Course_Gains_Aggregate;
  /** fetch data from the table: "course_gains" using primary key columns */
  readonly course_gains_by_pk?: Maybe<Course_Gains>;
  /** An array relationship */
  readonly course_instructors: ReadonlyArray<Course_Instructors>;
  /** An aggregate relationship */
  readonly course_instructors_aggregate: Course_Instructors_Aggregate;
  /** fetch data from the table: "course_instructors" using primary key columns */
  readonly course_instructors_by_pk?: Maybe<Course_Instructors>;
  /** An array relationship */
  readonly course_invites: ReadonlyArray<Course_Invites>;
  /** An aggregate relationship */
  readonly course_invites_aggregate: Course_Invites_Aggregate;
  /** fetch data from the table: "course_invites" using primary key columns */
  readonly course_invites_by_pk?: Maybe<Course_Invites>;
  /** An array relationship */
  readonly course_lecturers: ReadonlyArray<Course_Lecturers>;
  /** An aggregate relationship */
  readonly course_lecturers_aggregate: Course_Lecturers_Aggregate;
  /** An array relationship */
  readonly course_live_sessions: ReadonlyArray<Course_Live_Sessions>;
  /** An aggregate relationship */
  readonly course_live_sessions_aggregate: Course_Live_Sessions_Aggregate;
  /** fetch data from the table: "course_live_sessions" using primary key columns */
  readonly course_live_sessions_by_pk?: Maybe<Course_Live_Sessions>;
  /** An array relationship */
  readonly course_module_students: ReadonlyArray<Course_Module_Students>;
  /** An aggregate relationship */
  readonly course_module_students_aggregate: Course_Module_Students_Aggregate;
  /** fetch data from the table: "course_module_students" using primary key columns */
  readonly course_module_students_by_pk?: Maybe<Course_Module_Students>;
  /** An array relationship */
  readonly course_modules: ReadonlyArray<Course_Modules>;
  /** An aggregate relationship */
  readonly course_modules_aggregate: Course_Modules_Aggregate;
  /** fetch data from the table: "course_modules" using primary key columns */
  readonly course_modules_by_pk?: Maybe<Course_Modules>;
  /** An array relationship */
  readonly course_objectives: ReadonlyArray<Course_Objectives>;
  /** An aggregate relationship */
  readonly course_objectives_aggregate: Course_Objectives_Aggregate;
  /** fetch data from the table: "course_objectives" using primary key columns */
  readonly course_objectives_by_pk?: Maybe<Course_Objectives>;
  /** fetch data from the table: "course_order" */
  readonly course_order: ReadonlyArray<Course_Order>;
  /** fetch aggregated fields from the table: "course_order" */
  readonly course_order_aggregate: Course_Order_Aggregate;
  /** fetch data from the table: "course_order" using primary key columns */
  readonly course_order_by_pk?: Maybe<Course_Order>;
  /** An array relationship */
  readonly course_order_modules: ReadonlyArray<Course_Order_Modules>;
  /** An aggregate relationship */
  readonly course_order_modules_aggregate: Course_Order_Modules_Aggregate;
  /** fetch data from the table: "course_order_modules" using primary key columns */
  readonly course_order_modules_by_pk?: Maybe<Course_Order_Modules>;
  /** fetch data from the table: "course_order_statuses" */
  readonly course_order_statuses: ReadonlyArray<Course_Order_Statuses>;
  /** fetch aggregated fields from the table: "course_order_statuses" */
  readonly course_order_statuses_aggregate: Course_Order_Statuses_Aggregate;
  /** fetch data from the table: "course_order_statuses" using primary key columns */
  readonly course_order_statuses_by_pk?: Maybe<Course_Order_Statuses>;
  /** fetch data from the table: "course_payment_methods" */
  readonly course_payment_methods: ReadonlyArray<Course_Payment_Methods>;
  /** fetch aggregated fields from the table: "course_payment_methods" */
  readonly course_payment_methods_aggregate: Course_Payment_Methods_Aggregate;
  /** fetch data from the table: "course_payment_methods" using primary key columns */
  readonly course_payment_methods_by_pk?: Maybe<Course_Payment_Methods>;
  /** An array relationship */
  readonly course_reminders: ReadonlyArray<Course_Reminders>;
  /** An aggregate relationship */
  readonly course_reminders_aggregate: Course_Reminders_Aggregate;
  /** fetch data from the table: "course_reminders" using primary key columns */
  readonly course_reminders_by_pk?: Maybe<Course_Reminders>;
  /** An array relationship */
  readonly course_requirements: ReadonlyArray<Course_Requirements>;
  /** An aggregate relationship */
  readonly course_requirements_aggregate: Course_Requirements_Aggregate;
  /** fetch data from the table: "course_requirements" using primary key columns */
  readonly course_requirements_by_pk?: Maybe<Course_Requirements>;
  /** An array relationship */
  readonly course_rewards: ReadonlyArray<Course_Rewards>;
  /** An aggregate relationship */
  readonly course_rewards_aggregate: Course_Rewards_Aggregate;
  /** fetch data from the table: "course_rewards" using primary key columns */
  readonly course_rewards_by_pk?: Maybe<Course_Rewards>;
  /** fetch data from the table: "course_sell_types" */
  readonly course_sell_types: ReadonlyArray<Course_Sell_Types>;
  /** fetch aggregated fields from the table: "course_sell_types" */
  readonly course_sell_types_aggregate: Course_Sell_Types_Aggregate;
  /** fetch data from the table: "course_sell_types" using primary key columns */
  readonly course_sell_types_by_pk?: Maybe<Course_Sell_Types>;
  /** fetch data from the table: "course_signatories" */
  readonly course_signatories: ReadonlyArray<Course_Signatories>;
  /** fetch aggregated fields from the table: "course_signatories" */
  readonly course_signatories_aggregate: Course_Signatories_Aggregate;
  /** fetch data from the table: "course_signatories" using primary key columns */
  readonly course_signatories_by_pk?: Maybe<Course_Signatories>;
  /** An array relationship */
  readonly course_students: ReadonlyArray<Course_Students>;
  /** An aggregate relationship */
  readonly course_students_aggregate: Course_Students_Aggregate;
  /** fetch data from the table: "course_students" using primary key columns */
  readonly course_students_by_pk?: Maybe<Course_Students>;
  /** An array relationship */
  readonly course_videos: ReadonlyArray<Course_Videos>;
  /** An aggregate relationship */
  readonly course_videos_aggregate: Course_Videos_Aggregate;
  /** fetch data from the table: "course_videos" using primary key columns */
  readonly course_videos_by_pk?: Maybe<Course_Videos>;
  /** fetch data from the table: "course_videos_statuses" */
  readonly course_videos_statuses: ReadonlyArray<Course_Videos_Statuses>;
  /** fetch aggregated fields from the table: "course_videos_statuses" */
  readonly course_videos_statuses_aggregate: Course_Videos_Statuses_Aggregate;
  /** fetch data from the table: "course_videos_statuses" using primary key columns */
  readonly course_videos_statuses_by_pk?: Maybe<Course_Videos_Statuses>;
  /** An array relationship */
  readonly courses: ReadonlyArray<Courses>;
  /** An aggregate relationship */
  readonly courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  readonly courses_by_pk?: Maybe<Courses>;
  /** An array relationship */
  readonly cpd_unit_rewards: ReadonlyArray<Cpd_Unit_Rewards>;
  /** An aggregate relationship */
  readonly cpd_unit_rewards_aggregate: Cpd_Unit_Rewards_Aggregate;
  /** fetch data from the table: "cpd_unit_rewards" using primary key columns */
  readonly cpd_unit_rewards_by_pk?: Maybe<Cpd_Unit_Rewards>;
  /** fetch data from the table: "cpd_units_reward" */
  readonly cpd_units_reward: ReadonlyArray<Cpd_Units_Reward>;
  /** fetch aggregated fields from the table: "cpd_units_reward" */
  readonly cpd_units_reward_aggregate: Cpd_Units_Reward_Aggregate;
  /** fetch data from the table: "cpd_units_reward" using primary key columns */
  readonly cpd_units_reward_by_pk?: Maybe<Cpd_Units_Reward>;
  /** fetch data from the table: "customer" */
  readonly customer: ReadonlyArray<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  readonly customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  readonly customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table: "discount" */
  readonly discount: ReadonlyArray<Discount>;
  /** fetch aggregated fields from the table: "discount" */
  readonly discount_aggregate: Discount_Aggregate;
  /** fetch data from the table: "discount" using primary key columns */
  readonly discount_by_pk?: Maybe<Discount>;
  /** fetch data from the table: "email_notification_types" */
  readonly email_notification_types: ReadonlyArray<Email_Notification_Types>;
  /** fetch aggregated fields from the table: "email_notification_types" */
  readonly email_notification_types_aggregate: Email_Notification_Types_Aggregate;
  /** fetch data from the table: "email_notification_types" using primary key columns */
  readonly email_notification_types_by_pk?: Maybe<Email_Notification_Types>;
  /** An array relationship */
  readonly email_notifications: ReadonlyArray<Email_Notifications>;
  /** An aggregate relationship */
  readonly email_notifications_aggregate: Email_Notifications_Aggregate;
  /** fetch data from the table: "email_notifications" using primary key columns */
  readonly email_notifications_by_pk?: Maybe<Email_Notifications>;
  /** fetch data from the table: "event" */
  readonly event: ReadonlyArray<Event>;
  /** fetch aggregated fields from the table: "event" */
  readonly event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  readonly event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "event_industries_industry" */
  readonly event_industries_industry: ReadonlyArray<Event_Industries_Industry>;
  /** fetch aggregated fields from the table: "event_industries_industry" */
  readonly event_industries_industry_aggregate: Event_Industries_Industry_Aggregate;
  /** fetch data from the table: "event_industries_industry" using primary key columns */
  readonly event_industries_industry_by_pk?: Maybe<Event_Industries_Industry>;
  /** fetch data from the table: "event_managing_users_user" */
  readonly event_managing_users_user: ReadonlyArray<Event_Managing_Users_User>;
  /** fetch aggregated fields from the table: "event_managing_users_user" */
  readonly event_managing_users_user_aggregate: Event_Managing_Users_User_Aggregate;
  /** fetch data from the table: "event_managing_users_user" using primary key columns */
  readonly event_managing_users_user_by_pk?: Maybe<Event_Managing_Users_User>;
  /** fetch data from the table: "event_participant" */
  readonly event_participant: ReadonlyArray<Event_Participant>;
  /** fetch aggregated fields from the table: "event_participant" */
  readonly event_participant_aggregate: Event_Participant_Aggregate;
  /** fetch data from the table: "event_participant" using primary key columns */
  readonly event_participant_by_pk?: Maybe<Event_Participant>;
  /** fetch data from the table: "faq_item" */
  readonly faq_item: ReadonlyArray<Faq_Item>;
  /** fetch aggregated fields from the table: "faq_item" */
  readonly faq_item_aggregate: Faq_Item_Aggregate;
  /** fetch data from the table: "faq_item" using primary key columns */
  readonly faq_item_by_pk?: Maybe<Faq_Item>;
  /** fetch data from the table: "form" */
  readonly form: ReadonlyArray<Form>;
  /** fetch aggregated fields from the table: "form" */
  readonly form_aggregate: Form_Aggregate;
  /** fetch data from the table: "form" using primary key columns */
  readonly form_by_pk?: Maybe<Form>;
  /** fetch data from the table: "form_event_names" */
  readonly form_event_names: ReadonlyArray<Form_Event_Names>;
  /** fetch aggregated fields from the table: "form_event_names" */
  readonly form_event_names_aggregate: Form_Event_Names_Aggregate;
  /** fetch data from the table: "form_event_names" using primary key columns */
  readonly form_event_names_by_pk?: Maybe<Form_Event_Names>;
  /** An array relationship */
  readonly form_events: ReadonlyArray<Form_Events>;
  /** An aggregate relationship */
  readonly form_events_aggregate: Form_Events_Aggregate;
  /** fetch data from the table: "form_events" using primary key columns */
  readonly form_events_by_pk?: Maybe<Form_Events>;
  /** fetch data from the table: "form_instance" */
  readonly form_instance: ReadonlyArray<Form_Instance>;
  /** fetch aggregated fields from the table: "form_instance" */
  readonly form_instance_aggregate: Form_Instance_Aggregate;
  /** fetch data from the table: "form_instance" using primary key columns */
  readonly form_instance_by_pk?: Maybe<Form_Instance>;
  /** An array relationship */
  readonly form_question_answers: ReadonlyArray<Form_Question_Answers>;
  /** An aggregate relationship */
  readonly form_question_answers_aggregate: Form_Question_Answers_Aggregate;
  /** fetch data from the table: "form_question_answers" using primary key columns */
  readonly form_question_answers_by_pk?: Maybe<Form_Question_Answers>;
  /** fetch data from the table: "form_question_submission_states" */
  readonly form_question_submission_states: ReadonlyArray<Form_Question_Submission_States>;
  /** fetch aggregated fields from the table: "form_question_submission_states" */
  readonly form_question_submission_states_aggregate: Form_Question_Submission_States_Aggregate;
  /** fetch data from the table: "form_question_submission_states" using primary key columns */
  readonly form_question_submission_states_by_pk?: Maybe<Form_Question_Submission_States>;
  /** An array relationship */
  readonly form_question_submissions: ReadonlyArray<Form_Question_Submissions>;
  /** An aggregate relationship */
  readonly form_question_submissions_aggregate: Form_Question_Submissions_Aggregate;
  /** fetch data from the table: "form_question_submissions" using primary key columns */
  readonly form_question_submissions_by_pk?: Maybe<Form_Question_Submissions>;
  /** fetch data from the table: "form_question_types" */
  readonly form_question_types: ReadonlyArray<Form_Question_Types>;
  /** fetch aggregated fields from the table: "form_question_types" */
  readonly form_question_types_aggregate: Form_Question_Types_Aggregate;
  /** fetch data from the table: "form_question_types" using primary key columns */
  readonly form_question_types_by_pk?: Maybe<Form_Question_Types>;
  /** An array relationship */
  readonly form_questions: ReadonlyArray<Form_Questions>;
  /** An aggregate relationship */
  readonly form_questions_aggregate: Form_Questions_Aggregate;
  /** fetch data from the table: "form_questions" using primary key columns */
  readonly form_questions_by_pk?: Maybe<Form_Questions>;
  /** fetch data from the table: "form_score" */
  readonly form_score: ReadonlyArray<Form_Score>;
  /** fetch aggregated fields from the table: "form_score" */
  readonly form_score_aggregate: Form_Score_Aggregate;
  /** fetch data from the table: "form_score" using primary key columns */
  readonly form_score_by_pk?: Maybe<Form_Score>;
  /** fetch data from the table: "form_scoring_policies" */
  readonly form_scoring_policies: ReadonlyArray<Form_Scoring_Policies>;
  /** fetch aggregated fields from the table: "form_scoring_policies" */
  readonly form_scoring_policies_aggregate: Form_Scoring_Policies_Aggregate;
  /** fetch data from the table: "form_scoring_policies" using primary key columns */
  readonly form_scoring_policies_by_pk?: Maybe<Form_Scoring_Policies>;
  /** fetch data from the table: "form_submission_statuses" */
  readonly form_submission_statuses: ReadonlyArray<Form_Submission_Statuses>;
  /** fetch aggregated fields from the table: "form_submission_statuses" */
  readonly form_submission_statuses_aggregate: Form_Submission_Statuses_Aggregate;
  /** fetch data from the table: "form_submission_statuses" using primary key columns */
  readonly form_submission_statuses_by_pk?: Maybe<Form_Submission_Statuses>;
  /** An array relationship */
  readonly form_submissions: ReadonlyArray<Form_Submissions>;
  /** An aggregate relationship */
  readonly form_submissions_aggregate: Form_Submissions_Aggregate;
  /** fetch data from the table: "form_submissions" using primary key columns */
  readonly form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** fetch data from the table: "form_types" */
  readonly form_types: ReadonlyArray<Form_Types>;
  /** fetch aggregated fields from the table: "form_types" */
  readonly form_types_aggregate: Form_Types_Aggregate;
  /** fetch data from the table: "form_types" using primary key columns */
  readonly form_types_by_pk?: Maybe<Form_Types>;
  /** An array relationship */
  readonly forms: ReadonlyArray<Forms>;
  /** An aggregate relationship */
  readonly forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  readonly forms_by_pk?: Maybe<Forms>;
  /** An array relationship */
  readonly images: ReadonlyArray<Images>;
  /** An aggregate relationship */
  readonly images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  readonly images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "industry" */
  readonly industry: ReadonlyArray<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  readonly industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  readonly industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table: "industry_category" */
  readonly industry_category: ReadonlyArray<Industry_Category>;
  /** fetch aggregated fields from the table: "industry_category" */
  readonly industry_category_aggregate: Industry_Category_Aggregate;
  /** fetch data from the table: "industry_category" using primary key columns */
  readonly industry_category_by_pk?: Maybe<Industry_Category>;
  /** fetch data from the table: "industry_type" */
  readonly industry_type: ReadonlyArray<Industry_Type>;
  /** fetch aggregated fields from the table: "industry_type" */
  readonly industry_type_aggregate: Industry_Type_Aggregate;
  /** fetch data from the table: "industry_type" using primary key columns */
  readonly industry_type_by_pk?: Maybe<Industry_Type>;
  /** fetch data from the table: "migrations" */
  readonly migrations: ReadonlyArray<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  readonly migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  readonly migrations_by_pk?: Maybe<Migrations>;
  /** fetch data from the table: "mock_order" */
  readonly mock_order: ReadonlyArray<Mock_Order>;
  /** fetch data from the table: "mock_order2" */
  readonly mock_order2: ReadonlyArray<Mock_Order2>;
  /** fetch aggregated fields from the table: "mock_order2" */
  readonly mock_order2_aggregate: Mock_Order2_Aggregate;
  /** fetch data from the table: "mock_order2" using primary key columns */
  readonly mock_order2_by_pk?: Maybe<Mock_Order2>;
  /** fetch aggregated fields from the table: "mock_order" */
  readonly mock_order_aggregate: Mock_Order_Aggregate;
  /** fetch data from the table: "mock_order" using primary key columns */
  readonly mock_order_by_pk?: Maybe<Mock_Order>;
  /** An array relationship */
  readonly module_item_comments: ReadonlyArray<Module_Item_Comments>;
  /** An aggregate relationship */
  readonly module_item_comments_aggregate: Module_Item_Comments_Aggregate;
  /** fetch data from the table: "module_item_comments" using primary key columns */
  readonly module_item_comments_by_pk?: Maybe<Module_Item_Comments>;
  /** An array relationship */
  readonly module_items: ReadonlyArray<Module_Items>;
  /** An aggregate relationship */
  readonly module_items_aggregate: Module_Items_Aggregate;
  /** fetch data from the table: "module_items" using primary key columns */
  readonly module_items_by_pk?: Maybe<Module_Items>;
  /** fetch data from the table: "order" */
  readonly order: ReadonlyArray<Order>;
  /** fetch aggregated fields from the table: "order" */
  readonly order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  readonly order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "other_reward" */
  readonly other_reward: ReadonlyArray<Other_Reward>;
  /** fetch aggregated fields from the table: "other_reward" */
  readonly other_reward_aggregate: Other_Reward_Aggregate;
  /** fetch data from the table: "other_reward" using primary key columns */
  readonly other_reward_by_pk?: Maybe<Other_Reward>;
  /** fetch data from the table: "other_reward_type" */
  readonly other_reward_type: ReadonlyArray<Other_Reward_Type>;
  /** fetch aggregated fields from the table: "other_reward_type" */
  readonly other_reward_type_aggregate: Other_Reward_Type_Aggregate;
  /** fetch data from the table: "other_reward_type" using primary key columns */
  readonly other_reward_type_by_pk?: Maybe<Other_Reward_Type>;
  /** An array relationship */
  readonly other_unit_rewards: ReadonlyArray<Other_Unit_Rewards>;
  /** An aggregate relationship */
  readonly other_unit_rewards_aggregate: Other_Unit_Rewards_Aggregate;
  /** fetch data from the table: "other_unit_rewards" using primary key columns */
  readonly other_unit_rewards_by_pk?: Maybe<Other_Unit_Rewards>;
  /** fetch data from the table: "product" */
  readonly product: ReadonlyArray<Product>;
  /** fetch aggregated fields from the table: "product" */
  readonly product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  readonly product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "provider" */
  readonly provider: ReadonlyArray<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  readonly provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider_application" */
  readonly provider_application: ReadonlyArray<Provider_Application>;
  /** fetch aggregated fields from the table: "provider_application" */
  readonly provider_application_aggregate: Provider_Application_Aggregate;
  /** fetch data from the table: "provider_application" using primary key columns */
  readonly provider_application_by_pk?: Maybe<Provider_Application>;
  /** fetch data from the table: "provider_application_industries_industry" */
  readonly provider_application_industries_industry: ReadonlyArray<Provider_Application_Industries_Industry>;
  /** fetch aggregated fields from the table: "provider_application_industries_industry" */
  readonly provider_application_industries_industry_aggregate: Provider_Application_Industries_Industry_Aggregate;
  /** fetch data from the table: "provider_application_industries_industry" using primary key columns */
  readonly provider_application_industries_industry_by_pk?: Maybe<Provider_Application_Industries_Industry>;
  /** fetch data from the table: "provider" using primary key columns */
  readonly provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "provider_dragonpay_credentials" */
  readonly provider_dragonpay_credentials: ReadonlyArray<Provider_Dragonpay_Credentials>;
  /** fetch aggregated fields from the table: "provider_dragonpay_credentials" */
  readonly provider_dragonpay_credentials_aggregate: Provider_Dragonpay_Credentials_Aggregate;
  /** fetch data from the table: "provider_dragonpay_credentials" using primary key columns */
  readonly provider_dragonpay_credentials_by_pk?: Maybe<Provider_Dragonpay_Credentials>;
  /** fetch data from the table: "provider_frequently_used_industries_industry" */
  readonly provider_frequently_used_industries_industry: ReadonlyArray<Provider_Frequently_Used_Industries_Industry>;
  /** fetch aggregated fields from the table: "provider_frequently_used_industries_industry" */
  readonly provider_frequently_used_industries_industry_aggregate: Provider_Frequently_Used_Industries_Industry_Aggregate;
  /** fetch data from the table: "provider_frequently_used_industries_industry" using primary key columns */
  readonly provider_frequently_used_industries_industry_by_pk?: Maybe<Provider_Frequently_Used_Industries_Industry>;
  /** fetch data from the table: "provider_google" */
  readonly provider_google: ReadonlyArray<Provider_Google>;
  /** fetch aggregated fields from the table: "provider_google" */
  readonly provider_google_aggregate: Provider_Google_Aggregate;
  /** fetch data from the table: "provider_google" using primary key columns */
  readonly provider_google_by_pk?: Maybe<Provider_Google>;
  /** fetch data from the table: "provider_managing_users_user" */
  readonly provider_managing_users_user: ReadonlyArray<Provider_Managing_Users_User>;
  /** fetch aggregated fields from the table: "provider_managing_users_user" */
  readonly provider_managing_users_user_aggregate: Provider_Managing_Users_User_Aggregate;
  /** fetch data from the table: "provider_managing_users_user" using primary key columns */
  readonly provider_managing_users_user_by_pk?: Maybe<Provider_Managing_Users_User>;
  /** fetch data from the table: "provider_subscription_plan" */
  readonly provider_subscription_plan: ReadonlyArray<Provider_Subscription_Plan>;
  /** fetch aggregated fields from the table: "provider_subscription_plan" */
  readonly provider_subscription_plan_aggregate: Provider_Subscription_Plan_Aggregate;
  /** fetch data from the table: "provider_subscription_plan" using primary key columns */
  readonly provider_subscription_plan_by_pk?: Maybe<Provider_Subscription_Plan>;
  /** fetch data from the table: "provider_subscription_plans" */
  readonly provider_subscription_plans: ReadonlyArray<Provider_Subscription_Plans>;
  /** fetch aggregated fields from the table: "provider_subscription_plans" */
  readonly provider_subscription_plans_aggregate: Provider_Subscription_Plans_Aggregate;
  /** fetch data from the table: "provider_subscription_plans" using primary key columns */
  readonly provider_subscription_plans_by_pk?: Maybe<Provider_Subscription_Plans>;
  /** An array relationship */
  readonly provider_subscriptions: ReadonlyArray<Provider_Subscriptions>;
  /** An aggregate relationship */
  readonly provider_subscriptions_aggregate: Provider_Subscriptions_Aggregate;
  /** fetch data from the table: "provider_subscriptions" using primary key columns */
  readonly provider_subscriptions_by_pk?: Maybe<Provider_Subscriptions>;
  /** fetch data from the table: "provider_zoom" */
  readonly provider_zoom: ReadonlyArray<Provider_Zoom>;
  /** fetch aggregated fields from the table: "provider_zoom" */
  readonly provider_zoom_aggregate: Provider_Zoom_Aggregate;
  /** fetch data from the table: "provider_zoom" using primary key columns */
  readonly provider_zoom_by_pk?: Maybe<Provider_Zoom>;
  /** fetch data from the table: "ranking_reward" */
  readonly ranking_reward: ReadonlyArray<Ranking_Reward>;
  /** fetch aggregated fields from the table: "ranking_reward" */
  readonly ranking_reward_aggregate: Ranking_Reward_Aggregate;
  /** fetch data from the table: "ranking_reward" using primary key columns */
  readonly ranking_reward_by_pk?: Maybe<Ranking_Reward>;
  /** An array relationship */
  readonly ranking_rewards: ReadonlyArray<Ranking_Rewards>;
  /** An aggregate relationship */
  readonly ranking_rewards_aggregate: Ranking_Rewards_Aggregate;
  /** fetch data from the table: "ranking_rewards" using primary key columns */
  readonly ranking_rewards_by_pk?: Maybe<Ranking_Rewards>;
  /** fetch data from the table: "reward_certificate" */
  readonly reward_certificate: ReadonlyArray<Reward_Certificate>;
  /** fetch aggregated fields from the table: "reward_certificate" */
  readonly reward_certificate_aggregate: Reward_Certificate_Aggregate;
  /** fetch data from the table: "reward_certificate" using primary key columns */
  readonly reward_certificate_by_pk?: Maybe<Reward_Certificate>;
  /** fetch data from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly reward_certificate_signatories_certificate_signatory: ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** fetch aggregated fields from the table: "reward_certificate_signatories_certificate_signatory" */
  readonly reward_certificate_signatories_certificate_signatory_aggregate: Reward_Certificate_Signatories_Certificate_Signatory_Aggregate;
  /** fetch data from the table: "reward_certificate_signatories_certificate_signatory" using primary key columns */
  readonly reward_certificate_signatories_certificate_signatory_by_pk?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory>;
  /** fetch data from the table: "schema_migrations" */
  readonly schema_migrations: ReadonlyArray<Schema_Migrations>;
  /** fetch aggregated fields from the table: "schema_migrations" */
  readonly schema_migrations_aggregate: Schema_Migrations_Aggregate;
  /** fetch data from the table: "schema_migrations" using primary key columns */
  readonly schema_migrations_by_pk?: Maybe<Schema_Migrations>;
  /** fetch data from the table: "speakers" */
  readonly speakers: ReadonlyArray<Speakers>;
  /** fetch aggregated fields from the table: "speakers" */
  readonly speakers_aggregate: Speakers_Aggregate;
  /** fetch data from the table: "speakers" using primary key columns */
  readonly speakers_by_pk?: Maybe<Speakers>;
  /** fetch data from the table: "stream" */
  readonly stream: ReadonlyArray<Stream>;
  /** fetch aggregated fields from the table: "stream" */
  readonly stream_aggregate: Stream_Aggregate;
  /** fetch data from the table: "stream_attachment" */
  readonly stream_attachment: ReadonlyArray<Stream_Attachment>;
  /** fetch aggregated fields from the table: "stream_attachment" */
  readonly stream_attachment_aggregate: Stream_Attachment_Aggregate;
  /** fetch data from the table: "stream_attachment" using primary key columns */
  readonly stream_attachment_by_pk?: Maybe<Stream_Attachment>;
  /** fetch data from the table: "stream" using primary key columns */
  readonly stream_by_pk?: Maybe<Stream>;
  /** fetch data from the table: "stream_forms_form" */
  readonly stream_forms_form: ReadonlyArray<Stream_Forms_Form>;
  /** fetch aggregated fields from the table: "stream_forms_form" */
  readonly stream_forms_form_aggregate: Stream_Forms_Form_Aggregate;
  /** fetch data from the table: "stream_forms_form" using primary key columns */
  readonly stream_forms_form_by_pk?: Maybe<Stream_Forms_Form>;
  /** fetch data from the table: "student_progress" */
  readonly student_progress: ReadonlyArray<Student_Progress>;
  /** fetch aggregated fields from the table: "student_progress" */
  readonly student_progress_aggregate: Student_Progress_Aggregate;
  /** fetch data from the table: "student_progress" using primary key columns */
  readonly student_progress_by_pk?: Maybe<Student_Progress>;
  /** fetch data from the table: "user" */
  readonly user: ReadonlyArray<User>;
  /** fetch aggregated fields from the table: "user" */
  readonly user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  readonly user_by_pk?: Maybe<User>;
  /** An array relationship */
  readonly user_certificates: ReadonlyArray<User_Certificates>;
  /** An aggregate relationship */
  readonly user_certificates_aggregate: User_Certificates_Aggregate;
  /** fetch data from the table: "user_certificates" using primary key columns */
  readonly user_certificates_by_pk?: Maybe<User_Certificates>;
  /** fetch data from the table: "user_followed_industries_industry" */
  readonly user_followed_industries_industry: ReadonlyArray<User_Followed_Industries_Industry>;
  /** fetch aggregated fields from the table: "user_followed_industries_industry" */
  readonly user_followed_industries_industry_aggregate: User_Followed_Industries_Industry_Aggregate;
  /** fetch data from the table: "user_followed_industries_industry" using primary key columns */
  readonly user_followed_industries_industry_by_pk?: Maybe<User_Followed_Industries_Industry>;
  /** fetch data from the table: "user_provider_subscriptions_provider" */
  readonly user_provider_subscriptions_provider: ReadonlyArray<User_Provider_Subscriptions_Provider>;
  /** fetch aggregated fields from the table: "user_provider_subscriptions_provider" */
  readonly user_provider_subscriptions_provider_aggregate: User_Provider_Subscriptions_Provider_Aggregate;
  /** fetch data from the table: "user_provider_subscriptions_provider" using primary key columns */
  readonly user_provider_subscriptions_provider_by_pk?: Maybe<User_Provider_Subscriptions_Provider>;
  /** fetch data from the table: "user_purchased_events_event" */
  readonly user_purchased_events_event: ReadonlyArray<User_Purchased_Events_Event>;
  /** fetch aggregated fields from the table: "user_purchased_events_event" */
  readonly user_purchased_events_event_aggregate: User_Purchased_Events_Event_Aggregate;
  /** fetch data from the table: "user_purchased_events_event" using primary key columns */
  readonly user_purchased_events_event_by_pk?: Maybe<User_Purchased_Events_Event>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch aggregated fields from the table: "users" */
  readonly users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
};


export type Subscription_RootAccount_GoogleArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


export type Subscription_RootAccount_Google_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Google_Order_By>>;
  where?: Maybe<Account_Google_Bool_Exp>;
};


export type Subscription_RootAccount_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_Subscription_PlansArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscription_Plans_Order_By>>;
  where?: Maybe<Account_Subscription_Plans_Bool_Exp>;
};


export type Subscription_RootAccount_Subscription_Plans_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscription_Plans_Order_By>>;
  where?: Maybe<Account_Subscription_Plans_Bool_Exp>;
};


export type Subscription_RootAccount_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootAccount_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


export type Subscription_RootAccount_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Subscriptions_Order_By>>;
  where?: Maybe<Account_Subscriptions_Bool_Exp>;
};


export type Subscription_RootAccount_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_ZoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


export type Subscription_RootAccount_Zoom_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Account_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Account_Zoom_Order_By>>;
  where?: Maybe<Account_Zoom_Bool_Exp>;
};


export type Subscription_RootAccount_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAr_Internal_MetadataArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Order_By>>;
  where?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
};


export type Subscription_RootAr_Internal_Metadata_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ar_Internal_Metadata_Order_By>>;
  where?: Maybe<Ar_Internal_Metadata_Bool_Exp>;
};


export type Subscription_RootAr_Internal_Metadata_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootAttachmentArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Subscription_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Subscription_RootAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAttendance_LogsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};


export type Subscription_RootAttendance_Logs_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendance_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendance_Logs_Order_By>>;
  where?: Maybe<Attendance_Logs_Bool_Exp>;
};


export type Subscription_RootAttendance_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAttendancesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


export type Subscription_RootAttendances_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Attendances_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Attendances_Order_By>>;
  where?: Maybe<Attendances_Bool_Exp>;
};


export type Subscription_RootAttendances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBreakout_GroupArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};


export type Subscription_RootBreakout_Group_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Order_By>>;
  where?: Maybe<Breakout_Group_Bool_Exp>;
};


export type Subscription_RootBreakout_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBreakout_Group_Participants_Event_ParticipantArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


export type Subscription_RootBreakout_Group_Participants_Event_Participant_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Group_Participants_Event_Participant_Order_By>>;
  where?: Maybe<Breakout_Group_Participants_Event_Participant_Bool_Exp>;
};


export type Subscription_RootBreakout_Group_Participants_Event_Participant_By_PkArgs = {
  breakoutGroupId: Scalars['uuid'];
  eventParticipantId: Scalars['uuid'];
};


export type Subscription_RootBreakout_RoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


export type Subscription_RootBreakout_Room_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Breakout_Room_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Breakout_Room_Order_By>>;
  where?: Maybe<Breakout_Room_Bool_Exp>;
};


export type Subscription_RootBreakout_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBunny_UploadArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


export type Subscription_RootBunny_Upload_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_Order_By>>;
  where?: Maybe<Bunny_Upload_Bool_Exp>;
};


export type Subscription_RootBunny_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBunny_Upload_StatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_States_Order_By>>;
  where?: Maybe<Bunny_Upload_States_Bool_Exp>;
};


export type Subscription_RootBunny_Upload_States_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Bunny_Upload_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Bunny_Upload_States_Order_By>>;
  where?: Maybe<Bunny_Upload_States_Bool_Exp>;
};


export type Subscription_RootBunny_Upload_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCarousel_ItemArgs = {
  distinct_on?: Maybe<ReadonlyArray<Carousel_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Carousel_Item_Order_By>>;
  where?: Maybe<Carousel_Item_Bool_Exp>;
};


export type Subscription_RootCarousel_Item_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Carousel_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Carousel_Item_Order_By>>;
  where?: Maybe<Carousel_Item_Bool_Exp>;
};


export type Subscription_RootCarousel_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCertificate_SignatoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


export type Subscription_RootCertificate_Signatory_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Signatory_Order_By>>;
  where?: Maybe<Certificate_Signatory_Bool_Exp>;
};


export type Subscription_RootCertificate_Signatory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCertificate_Template_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


export type Subscription_RootCertificate_Template_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Template_Signatories_Order_By>>;
  where?: Maybe<Certificate_Template_Signatories_Bool_Exp>;
};


export type Subscription_RootCertificate_Template_Signatories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCertificate_TemplatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


export type Subscription_RootCertificate_Templates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Certificate_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Certificate_Templates_Order_By>>;
  where?: Maybe<Certificate_Templates_Bool_Exp>;
};


export type Subscription_RootCertificate_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_ArticlesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


export type Subscription_RootCourse_Articles_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Articles_Order_By>>;
  where?: Maybe<Course_Articles_Bool_Exp>;
};


export type Subscription_RootCourse_Articles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_AssessmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


export type Subscription_RootCourse_Assessments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Assessments_Order_By>>;
  where?: Maybe<Course_Assessments_Bool_Exp>;
};


export type Subscription_RootCourse_Assessments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_AttachmentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


export type Subscription_RootCourse_Attachments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Attachments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Attachments_Order_By>>;
  where?: Maybe<Course_Attachments_Bool_Exp>;
};


export type Subscription_RootCourse_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


export type Subscription_RootCourse_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Certificates_Order_By>>;
  where?: Maybe<Course_Certificates_Bool_Exp>;
};


export type Subscription_RootCourse_Certificates_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_Discount_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discount_Types_Order_By>>;
  where?: Maybe<Course_Discount_Types_Bool_Exp>;
};


export type Subscription_RootCourse_Discount_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discount_Types_Order_By>>;
  where?: Maybe<Course_Discount_Types_Bool_Exp>;
};


export type Subscription_RootCourse_Discount_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCourse_DiscountsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


export type Subscription_RootCourse_Discounts_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Discounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Discounts_Order_By>>;
  where?: Maybe<Course_Discounts_Bool_Exp>;
};


export type Subscription_RootCourse_Discounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_FormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


export type Subscription_RootCourse_Forms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Forms_Order_By>>;
  where?: Maybe<Course_Forms_Bool_Exp>;
};


export type Subscription_RootCourse_Forms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_GainsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


export type Subscription_RootCourse_Gains_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Gains_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Gains_Order_By>>;
  where?: Maybe<Course_Gains_Bool_Exp>;
};


export type Subscription_RootCourse_Gains_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_InstructorsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


export type Subscription_RootCourse_Instructors_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Instructors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Instructors_Order_By>>;
  where?: Maybe<Course_Instructors_Bool_Exp>;
};


export type Subscription_RootCourse_Instructors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_InvitesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


export type Subscription_RootCourse_Invites_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Invites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Invites_Order_By>>;
  where?: Maybe<Course_Invites_Bool_Exp>;
};


export type Subscription_RootCourse_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_LecturersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


export type Subscription_RootCourse_Lecturers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Lecturers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Lecturers_Order_By>>;
  where?: Maybe<Course_Lecturers_Bool_Exp>;
};


export type Subscription_RootCourse_Live_SessionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


export type Subscription_RootCourse_Live_Sessions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Live_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Live_Sessions_Order_By>>;
  where?: Maybe<Course_Live_Sessions_Bool_Exp>;
};


export type Subscription_RootCourse_Live_Sessions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_Module_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


export type Subscription_RootCourse_Module_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Module_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Module_Students_Order_By>>;
  where?: Maybe<Course_Module_Students_Bool_Exp>;
};


export type Subscription_RootCourse_Module_Students_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


export type Subscription_RootCourse_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Modules_Order_By>>;
  where?: Maybe<Course_Modules_Bool_Exp>;
};


export type Subscription_RootCourse_Modules_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_ObjectivesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


export type Subscription_RootCourse_Objectives_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Objectives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Objectives_Order_By>>;
  where?: Maybe<Course_Objectives_Bool_Exp>;
};


export type Subscription_RootCourse_Objectives_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_OrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


export type Subscription_RootCourse_Order_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Order_By>>;
  where?: Maybe<Course_Order_Bool_Exp>;
};


export type Subscription_RootCourse_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_Order_ModulesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


export type Subscription_RootCourse_Order_Modules_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Modules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Modules_Order_By>>;
  where?: Maybe<Course_Order_Modules_Bool_Exp>;
};


export type Subscription_RootCourse_Order_Modules_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_Order_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Statuses_Order_By>>;
  where?: Maybe<Course_Order_Statuses_Bool_Exp>;
};


export type Subscription_RootCourse_Order_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Order_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Order_Statuses_Order_By>>;
  where?: Maybe<Course_Order_Statuses_Bool_Exp>;
};


export type Subscription_RootCourse_Order_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCourse_Payment_MethodsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Payment_Methods_Order_By>>;
  where?: Maybe<Course_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootCourse_Payment_Methods_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Payment_Methods_Order_By>>;
  where?: Maybe<Course_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootCourse_Payment_Methods_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCourse_RemindersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


export type Subscription_RootCourse_Reminders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Reminders_Order_By>>;
  where?: Maybe<Course_Reminders_Bool_Exp>;
};


export type Subscription_RootCourse_Reminders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourse_RequirementsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


export type Subscription_RootCourse_Requirements_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Requirements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Requirements_Order_By>>;
  where?: Maybe<Course_Requirements_Bool_Exp>;
};


export type Subscription_RootCourse_Requirements_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


export type Subscription_RootCourse_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Rewards_Order_By>>;
  where?: Maybe<Course_Rewards_Bool_Exp>;
};


export type Subscription_RootCourse_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_Sell_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Sell_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Sell_Types_Order_By>>;
  where?: Maybe<Course_Sell_Types_Bool_Exp>;
};


export type Subscription_RootCourse_Sell_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Sell_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Sell_Types_Order_By>>;
  where?: Maybe<Course_Sell_Types_Bool_Exp>;
};


export type Subscription_RootCourse_Sell_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCourse_SignatoriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Signatories_Order_By>>;
  where?: Maybe<Course_Signatories_Bool_Exp>;
};


export type Subscription_RootCourse_Signatories_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Signatories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Signatories_Order_By>>;
  where?: Maybe<Course_Signatories_Bool_Exp>;
};


export type Subscription_RootCourse_Signatories_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_StudentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};


export type Subscription_RootCourse_Students_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Students_Order_By>>;
  where?: Maybe<Course_Students_Bool_Exp>;
};


export type Subscription_RootCourse_Students_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_VideosArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


export type Subscription_RootCourse_Videos_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Order_By>>;
  where?: Maybe<Course_Videos_Bool_Exp>;
};


export type Subscription_RootCourse_Videos_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCourse_Videos_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Statuses_Order_By>>;
  where?: Maybe<Course_Videos_Statuses_Bool_Exp>;
};


export type Subscription_RootCourse_Videos_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Course_Videos_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Course_Videos_Statuses_Order_By>>;
  where?: Maybe<Course_Videos_Statuses_Bool_Exp>;
};


export type Subscription_RootCourse_Videos_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCoursesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Courses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Courses_Order_By>>;
  where?: Maybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCpd_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


export type Subscription_RootCpd_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Unit_Rewards_Order_By>>;
  where?: Maybe<Cpd_Unit_Rewards_Bool_Exp>;
};


export type Subscription_RootCpd_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCpd_Units_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


export type Subscription_RootCpd_Units_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Cpd_Units_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Cpd_Units_Reward_Order_By>>;
  where?: Maybe<Cpd_Units_Reward_Bool_Exp>;
};


export type Subscription_RootCpd_Units_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomerArgs = {
  distinct_on?: Maybe<ReadonlyArray<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiscountArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


export type Subscription_RootDiscount_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Discount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Discount_Order_By>>;
  where?: Maybe<Discount_Bool_Exp>;
};


export type Subscription_RootDiscount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmail_Notification_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notification_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notification_Types_Order_By>>;
  where?: Maybe<Email_Notification_Types_Bool_Exp>;
};


export type Subscription_RootEmail_Notification_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notification_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notification_Types_Order_By>>;
  where?: Maybe<Email_Notification_Types_Bool_Exp>;
};


export type Subscription_RootEmail_Notification_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEmail_NotificationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


export type Subscription_RootEmail_Notifications_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Email_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Email_Notifications_Order_By>>;
  where?: Maybe<Email_Notifications_Bool_Exp>;
};


export type Subscription_RootEmail_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEventArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvent_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootEvent_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Industries_Industry_Order_By>>;
  where?: Maybe<Event_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootEvent_Industries_Industry_By_PkArgs = {
  eventId: Scalars['uuid'];
  industryName: Scalars['String'];
};


export type Subscription_RootEvent_Managing_Users_UserArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


export type Subscription_RootEvent_Managing_Users_User_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


export type Subscription_RootEvent_Managing_Users_User_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Subscription_RootEvent_ParticipantArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


export type Subscription_RootEvent_Participant_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


export type Subscription_RootEvent_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFaq_ItemArgs = {
  distinct_on?: Maybe<ReadonlyArray<Faq_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Faq_Item_Order_By>>;
  where?: Maybe<Faq_Item_Bool_Exp>;
};


export type Subscription_RootFaq_Item_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Faq_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Faq_Item_Order_By>>;
  where?: Maybe<Faq_Item_Bool_Exp>;
};


export type Subscription_RootFaq_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFormArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


export type Subscription_RootForm_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Order_By>>;
  where?: Maybe<Form_Bool_Exp>;
};


export type Subscription_RootForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Event_NamesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Event_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Event_Names_Order_By>>;
  where?: Maybe<Form_Event_Names_Bool_Exp>;
};


export type Subscription_RootForm_Event_Names_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Event_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Event_Names_Order_By>>;
  where?: Maybe<Form_Event_Names_Bool_Exp>;
};


export type Subscription_RootForm_Event_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootForm_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


export type Subscription_RootForm_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Events_Order_By>>;
  where?: Maybe<Form_Events_Bool_Exp>;
};


export type Subscription_RootForm_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_InstanceArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


export type Subscription_RootForm_Instance_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Instance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Instance_Order_By>>;
  where?: Maybe<Form_Instance_Bool_Exp>;
};


export type Subscription_RootForm_Instance_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Question_AnswersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


export type Subscription_RootForm_Question_Answers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Answers_Order_By>>;
  where?: Maybe<Form_Question_Answers_Bool_Exp>;
};


export type Subscription_RootForm_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Question_Submission_StatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submission_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submission_States_Order_By>>;
  where?: Maybe<Form_Question_Submission_States_Bool_Exp>;
};


export type Subscription_RootForm_Question_Submission_States_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submission_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submission_States_Order_By>>;
  where?: Maybe<Form_Question_Submission_States_Bool_Exp>;
};


export type Subscription_RootForm_Question_Submission_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootForm_Question_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Question_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Submissions_Order_By>>;
  where?: Maybe<Form_Question_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Question_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Question_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Types_Order_By>>;
  where?: Maybe<Form_Question_Types_Bool_Exp>;
};


export type Subscription_RootForm_Question_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Question_Types_Order_By>>;
  where?: Maybe<Form_Question_Types_Bool_Exp>;
};


export type Subscription_RootForm_Question_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootForm_QuestionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


export type Subscription_RootForm_Questions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Questions_Order_By>>;
  where?: Maybe<Form_Questions_Bool_Exp>;
};


export type Subscription_RootForm_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_ScoreArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


export type Subscription_RootForm_Score_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


export type Subscription_RootForm_Score_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Scoring_PoliciesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Scoring_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Scoring_Policies_Order_By>>;
  where?: Maybe<Form_Scoring_Policies_Bool_Exp>;
};


export type Subscription_RootForm_Scoring_Policies_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Scoring_Policies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Scoring_Policies_Order_By>>;
  where?: Maybe<Form_Scoring_Policies_Bool_Exp>;
};


export type Subscription_RootForm_Scoring_Policies_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootForm_Submission_StatusesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submission_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submission_Statuses_Order_By>>;
  where?: Maybe<Form_Submission_Statuses_Bool_Exp>;
};


export type Subscription_RootForm_Submission_Statuses_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submission_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submission_Statuses_Order_By>>;
  where?: Maybe<Form_Submission_Statuses_Bool_Exp>;
};


export type Subscription_RootForm_Submission_Statuses_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootForm_SubmissionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_TypesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Types_Order_By>>;
  where?: Maybe<Form_Types_Bool_Exp>;
};


export type Subscription_RootForm_Types_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Types_Order_By>>;
  where?: Maybe<Form_Types_Bool_Exp>;
};


export type Subscription_RootForm_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFormsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImagesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


export type Subscription_RootIndustry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


export type Subscription_RootIndustry_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootIndustry_CategoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Category_Order_By>>;
  where?: Maybe<Industry_Category_Bool_Exp>;
};


export type Subscription_RootIndustry_Category_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Category_Order_By>>;
  where?: Maybe<Industry_Category_Bool_Exp>;
};


export type Subscription_RootIndustry_Category_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootIndustry_TypeArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Type_Order_By>>;
  where?: Maybe<Industry_Type_Bool_Exp>;
};


export type Subscription_RootIndustry_Type_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Industry_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Industry_Type_Order_By>>;
  where?: Maybe<Industry_Type_Bool_Exp>;
};


export type Subscription_RootIndustry_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootMigrationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Migrations_Order_By>>;
  where?: Maybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMock_OrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order_Order_By>>;
  where?: Maybe<Mock_Order_Bool_Exp>;
};


export type Subscription_RootMock_Order2Args = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


export type Subscription_RootMock_Order2_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


export type Subscription_RootMock_Order2_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMock_Order_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order_Order_By>>;
  where?: Maybe<Mock_Order_Bool_Exp>;
};


export type Subscription_RootMock_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootModule_Item_CommentsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


export type Subscription_RootModule_Item_Comments_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Item_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Item_Comments_Order_By>>;
  where?: Maybe<Module_Item_Comments_Bool_Exp>;
};


export type Subscription_RootModule_Item_Comments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootModule_ItemsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};


export type Subscription_RootModule_Items_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Module_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Module_Items_Order_By>>;
  where?: Maybe<Module_Items_Bool_Exp>;
};


export type Subscription_RootModule_Items_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootOrderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOther_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


export type Subscription_RootOther_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Order_By>>;
  where?: Maybe<Other_Reward_Bool_Exp>;
};


export type Subscription_RootOther_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOther_Reward_TypeArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Type_Order_By>>;
  where?: Maybe<Other_Reward_Type_Bool_Exp>;
};


export type Subscription_RootOther_Reward_Type_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Reward_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Reward_Type_Order_By>>;
  where?: Maybe<Other_Reward_Type_Bool_Exp>;
};


export type Subscription_RootOther_Reward_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootOther_Unit_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


export type Subscription_RootOther_Unit_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Other_Unit_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Other_Unit_Rewards_Order_By>>;
  where?: Maybe<Other_Unit_Rewards_Bool_Exp>;
};


export type Subscription_RootOther_Unit_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<ReadonlyArray<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProviderArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Order_By>>;
  where?: Maybe<Provider_Bool_Exp>;
};


export type Subscription_RootProvider_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Order_By>>;
  where?: Maybe<Provider_Bool_Exp>;
};


export type Subscription_RootProvider_ApplicationArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


export type Subscription_RootProvider_Application_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


export type Subscription_RootProvider_Application_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_Application_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootProvider_Application_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Application_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootProvider_Application_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerApplicationId: Scalars['uuid'];
};


export type Subscription_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_Dragonpay_CredentialsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Order_By>>;
  where?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
};


export type Subscription_RootProvider_Dragonpay_Credentials_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Dragonpay_Credentials_Order_By>>;
  where?: Maybe<Provider_Dragonpay_Credentials_Bool_Exp>;
};


export type Subscription_RootProvider_Dragonpay_Credentials_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_Frequently_Used_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootProvider_Frequently_Used_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Frequently_Used_Industries_Industry_Order_By>>;
  where?: Maybe<Provider_Frequently_Used_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootProvider_Frequently_Used_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  providerId: Scalars['uuid'];
};


export type Subscription_RootProvider_GoogleArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


export type Subscription_RootProvider_Google_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Google_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Google_Order_By>>;
  where?: Maybe<Provider_Google_Bool_Exp>;
};


export type Subscription_RootProvider_Google_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_Managing_Users_UserArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


export type Subscription_RootProvider_Managing_Users_User_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


export type Subscription_RootProvider_Managing_Users_User_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Subscription_RootProvider_Subscription_PlanArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Order_By>>;
  where?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
};


export type Subscription_RootProvider_Subscription_Plan_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plan_Order_By>>;
  where?: Maybe<Provider_Subscription_Plan_Bool_Exp>;
};


export type Subscription_RootProvider_Subscription_Plan_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootProvider_Subscription_PlansArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Order_By>>;
  where?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
};


export type Subscription_RootProvider_Subscription_Plans_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscription_Plans_Order_By>>;
  where?: Maybe<Provider_Subscription_Plans_Bool_Exp>;
};


export type Subscription_RootProvider_Subscription_Plans_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootProvider_SubscriptionsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


export type Subscription_RootProvider_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Subscriptions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Subscriptions_Order_By>>;
  where?: Maybe<Provider_Subscriptions_Bool_Exp>;
};


export type Subscription_RootProvider_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_ZoomArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


export type Subscription_RootProvider_Zoom_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Zoom_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Zoom_Order_By>>;
  where?: Maybe<Provider_Zoom_Bool_Exp>;
};


export type Subscription_RootProvider_Zoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRanking_RewardArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


export type Subscription_RootRanking_Reward_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Reward_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Reward_Order_By>>;
  where?: Maybe<Ranking_Reward_Bool_Exp>;
};


export type Subscription_RootRanking_Reward_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRanking_RewardsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};


export type Subscription_RootRanking_Rewards_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Ranking_Rewards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Ranking_Rewards_Order_By>>;
  where?: Maybe<Ranking_Rewards_Bool_Exp>;
};


export type Subscription_RootRanking_Rewards_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootReward_CertificateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


export type Subscription_RootReward_Certificate_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


export type Subscription_RootReward_Certificate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReward_Certificate_Signatories_Certificate_SignatoryArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


export type Subscription_RootReward_Certificate_Signatories_Certificate_Signatory_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Signatories_Certificate_Signatory_Order_By>>;
  where?: Maybe<Reward_Certificate_Signatories_Certificate_Signatory_Bool_Exp>;
};


export type Subscription_RootReward_Certificate_Signatories_Certificate_Signatory_By_PkArgs = {
  certificateSignatoryId: Scalars['uuid'];
  rewardCertificateId: Scalars['uuid'];
};


export type Subscription_RootSchema_MigrationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Schema_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Schema_Migrations_Order_By>>;
  where?: Maybe<Schema_Migrations_Bool_Exp>;
};


export type Subscription_RootSchema_Migrations_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Schema_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Schema_Migrations_Order_By>>;
  where?: Maybe<Schema_Migrations_Bool_Exp>;
};


export type Subscription_RootSchema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


export type Subscription_RootSpeakersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


export type Subscription_RootSpeakers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


export type Subscription_RootSpeakers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStreamArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


export type Subscription_RootStream_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Order_By>>;
  where?: Maybe<Stream_Bool_Exp>;
};


export type Subscription_RootStream_AttachmentArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


export type Subscription_RootStream_Attachment_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Attachment_Order_By>>;
  where?: Maybe<Stream_Attachment_Bool_Exp>;
};


export type Subscription_RootStream_Attachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStream_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStream_Forms_FormArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


export type Subscription_RootStream_Forms_Form_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Stream_Forms_Form_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Stream_Forms_Form_Order_By>>;
  where?: Maybe<Stream_Forms_Form_Bool_Exp>;
};


export type Subscription_RootStream_Forms_Form_By_PkArgs = {
  formId: Scalars['uuid'];
  streamId: Scalars['uuid'];
};


export type Subscription_RootStudent_ProgressArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


export type Subscription_RootStudent_Progress_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Student_Progress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Student_Progress_Order_By>>;
  where?: Maybe<Student_Progress_Bool_Exp>;
};


export type Subscription_RootStudent_Progress_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


export type Subscription_RootUser_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


export type Subscription_RootUser_Certificates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Followed_Industries_IndustryArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootUser_Followed_Industries_Industry_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


export type Subscription_RootUser_Followed_Industries_Industry_By_PkArgs = {
  industryName: Scalars['String'];
  userId: Scalars['String'];
};


export type Subscription_RootUser_Provider_Subscriptions_ProviderArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


export type Subscription_RootUser_Provider_Subscriptions_Provider_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


export type Subscription_RootUser_Provider_Subscriptions_Provider_By_PkArgs = {
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Subscription_RootUser_Purchased_Events_EventArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


export type Subscription_RootUser_Purchased_Events_Event_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


export type Subscription_RootUser_Purchased_Events_Event_By_PkArgs = {
  eventId: Scalars['uuid'];
  userId: Scalars['String'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['timestamp']>;
  readonly _gt?: Maybe<Scalars['timestamp']>;
  readonly _gte?: Maybe<Scalars['timestamp']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['timestamp']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['timestamp']>;
  readonly _lte?: Maybe<Scalars['timestamp']>;
  readonly _neq?: Maybe<Scalars['timestamp']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['timestamptz']>;
  readonly _gt?: Maybe<Scalars['timestamptz']>;
  readonly _gte?: Maybe<Scalars['timestamptz']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['timestamptz']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['timestamptz']>;
  readonly _lte?: Maybe<Scalars['timestamptz']>;
  readonly _neq?: Maybe<Scalars['timestamptz']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  readonly __typename?: 'user';
  readonly birthDate?: Maybe<Scalars['timestamp']>;
  readonly certificatesEarnedId?: Maybe<Scalars['uuid']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['timestamp'];
  readonly displayName: Scalars['String'];
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  /** An array relationship */
  readonly event_managing_users_users: ReadonlyArray<Event_Managing_Users_User>;
  /** An aggregate relationship */
  readonly event_managing_users_users_aggregate: Event_Managing_Users_User_Aggregate;
  /** An array relationship */
  readonly event_participants: ReadonlyArray<Event_Participant>;
  /** An aggregate relationship */
  readonly event_participants_aggregate: Event_Participant_Aggregate;
  /** An array relationship */
  readonly form_scores: ReadonlyArray<Form_Score>;
  /** An aggregate relationship */
  readonly form_scores_aggregate: Form_Score_Aggregate;
  readonly id: Scalars['String'];
  /** An array relationship */
  readonly images: ReadonlyArray<Images>;
  /** An aggregate relationship */
  readonly images_aggregate: Images_Aggregate;
  readonly isAdmin: Scalars['Boolean'];
  readonly isLightTheme: Scalars['Boolean'];
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licensePhotoUuid?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly mock_order2s: ReadonlyArray<Mock_Order2>;
  /** An aggregate relationship */
  readonly mock_order2s_aggregate: Mock_Order2_Aggregate;
  /** An array relationship */
  readonly orders: ReadonlyArray<Order>;
  /** An aggregate relationship */
  readonly orders_aggregate: Order_Aggregate;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  readonly provider_applications: ReadonlyArray<Provider_Application>;
  /** An aggregate relationship */
  readonly provider_applications_aggregate: Provider_Application_Aggregate;
  /** An array relationship */
  readonly provider_managing_users_users: ReadonlyArray<Provider_Managing_Users_User>;
  /** An aggregate relationship */
  readonly provider_managing_users_users_aggregate: Provider_Managing_Users_User_Aggregate;
  /** An object relationship */
  readonly reward_certificate?: Maybe<Reward_Certificate>;
  /** An array relationship */
  readonly reward_certificates: ReadonlyArray<Reward_Certificate>;
  /** An aggregate relationship */
  readonly reward_certificates_aggregate: Reward_Certificate_Aggregate;
  readonly sex?: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['timestamp'];
  /** An array relationship */
  readonly user_certificates: ReadonlyArray<User_Certificates>;
  /** An aggregate relationship */
  readonly user_certificates_aggregate: User_Certificates_Aggregate;
  /** An array relationship */
  readonly user_followed_industries_industries: ReadonlyArray<User_Followed_Industries_Industry>;
  /** An aggregate relationship */
  readonly user_followed_industries_industries_aggregate: User_Followed_Industries_Industry_Aggregate;
  /** An array relationship */
  readonly user_provider_subscriptions_providers: ReadonlyArray<User_Provider_Subscriptions_Provider>;
  /** An aggregate relationship */
  readonly user_provider_subscriptions_providers_aggregate: User_Provider_Subscriptions_Provider_Aggregate;
  /** An array relationship */
  readonly user_purchased_events_events: ReadonlyArray<User_Purchased_Events_Event>;
  /** An aggregate relationship */
  readonly user_purchased_events_events_aggregate: User_Purchased_Events_Event_Aggregate;
};


/** columns and relationships of "user" */
export type UserEvent_Managing_Users_UsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEvent_Managing_Users_Users_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Managing_Users_User_Order_By>>;
  where?: Maybe<Event_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEvent_ParticipantsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEvent_Participants_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Event_Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Event_Participant_Order_By>>;
  where?: Maybe<Event_Participant_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserForm_ScoresArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserForm_Scores_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Form_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Form_Score_Order_By>>;
  where?: Maybe<Form_Score_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserImagesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserImages_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Images_Order_By>>;
  where?: Maybe<Images_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMock_Order2sArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMock_Order2s_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Mock_Order2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Mock_Order2_Order_By>>;
  where?: Maybe<Mock_Order2_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrdersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserOrders_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProvider_ApplicationsArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProvider_Applications_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Application_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Application_Order_By>>;
  where?: Maybe<Provider_Application_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProvider_Managing_Users_UsersArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProvider_Managing_Users_Users_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Provider_Managing_Users_User_Order_By>>;
  where?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReward_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReward_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<Reward_Certificate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<Reward_Certificate_Order_By>>;
  where?: Maybe<Reward_Certificate_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_CertificatesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Certificates_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Certificates_Order_By>>;
  where?: Maybe<User_Certificates_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Followed_Industries_IndustriesArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Followed_Industries_Industries_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Order_By>>;
  where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Provider_Subscriptions_ProvidersArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Provider_Subscriptions_Providers_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Order_By>>;
  where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Purchased_Events_EventsArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Purchased_Events_Events_AggregateArgs = {
  distinct_on?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Order_By>>;
  where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  readonly __typename?: 'user_aggregate';
  readonly aggregate?: Maybe<User_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  readonly __typename?: 'user_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<User_Max_Fields>;
  readonly min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<User_Max_Order_By>;
  readonly min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<User_Bool_Exp>>;
  readonly _not?: Maybe<User_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<User_Bool_Exp>>;
  readonly birthDate?: Maybe<Timestamp_Comparison_Exp>;
  readonly certificatesEarnedId?: Maybe<Uuid_Comparison_Exp>;
  readonly city?: Maybe<String_Comparison_Exp>;
  readonly createdAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly displayName?: Maybe<String_Comparison_Exp>;
  readonly displayPhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly event_managing_users_users?: Maybe<Event_Managing_Users_User_Bool_Exp>;
  readonly event_participants?: Maybe<Event_Participant_Bool_Exp>;
  readonly form_scores?: Maybe<Form_Score_Bool_Exp>;
  readonly id?: Maybe<String_Comparison_Exp>;
  readonly images?: Maybe<Images_Bool_Exp>;
  readonly isAdmin?: Maybe<Boolean_Comparison_Exp>;
  readonly isLightTheme?: Maybe<Boolean_Comparison_Exp>;
  readonly licenseNumber?: Maybe<String_Comparison_Exp>;
  readonly licensePhotoUuid?: Maybe<String_Comparison_Exp>;
  readonly mock_order2s?: Maybe<Mock_Order2_Bool_Exp>;
  readonly orders?: Maybe<Order_Bool_Exp>;
  readonly phoneNumber?: Maybe<String_Comparison_Exp>;
  readonly provider_applications?: Maybe<Provider_Application_Bool_Exp>;
  readonly provider_managing_users_users?: Maybe<Provider_Managing_Users_User_Bool_Exp>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Bool_Exp>;
  readonly sex?: Maybe<String_Comparison_Exp>;
  readonly updatedAt?: Maybe<Timestamp_Comparison_Exp>;
  readonly user_certificates?: Maybe<User_Certificates_Bool_Exp>;
  readonly user_followed_industries_industries?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
  readonly user_provider_subscriptions_providers?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
  readonly user_purchased_events_events?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};

/** columns and relationships of "user_certificates" */
export type User_Certificates = {
  readonly __typename?: 'user_certificates';
  /** An object relationship */
  readonly certificate_template: Certificate_Templates;
  readonly certificate_template_id: Scalars['uuid'];
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly date_distributed: Scalars['timestamptz'];
  readonly id: Scalars['uuid'];
  readonly short_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  readonly user: User;
  readonly user_id: Scalars['String'];
  readonly user_name: Scalars['String'];
};

/** aggregated selection of "user_certificates" */
export type User_Certificates_Aggregate = {
  readonly __typename?: 'user_certificates_aggregate';
  readonly aggregate?: Maybe<User_Certificates_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Certificates>;
};

/** aggregate fields of "user_certificates" */
export type User_Certificates_Aggregate_Fields = {
  readonly __typename?: 'user_certificates_aggregate_fields';
  readonly avg?: Maybe<User_Certificates_Avg_Fields>;
  readonly count: Scalars['Int'];
  readonly max?: Maybe<User_Certificates_Max_Fields>;
  readonly min?: Maybe<User_Certificates_Min_Fields>;
  readonly stddev?: Maybe<User_Certificates_Stddev_Fields>;
  readonly stddev_pop?: Maybe<User_Certificates_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<User_Certificates_Stddev_Samp_Fields>;
  readonly sum?: Maybe<User_Certificates_Sum_Fields>;
  readonly var_pop?: Maybe<User_Certificates_Var_Pop_Fields>;
  readonly var_samp?: Maybe<User_Certificates_Var_Samp_Fields>;
  readonly variance?: Maybe<User_Certificates_Variance_Fields>;
};


/** aggregate fields of "user_certificates" */
export type User_Certificates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<User_Certificates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_certificates" */
export type User_Certificates_Aggregate_Order_By = {
  readonly avg?: Maybe<User_Certificates_Avg_Order_By>;
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<User_Certificates_Max_Order_By>;
  readonly min?: Maybe<User_Certificates_Min_Order_By>;
  readonly stddev?: Maybe<User_Certificates_Stddev_Order_By>;
  readonly stddev_pop?: Maybe<User_Certificates_Stddev_Pop_Order_By>;
  readonly stddev_samp?: Maybe<User_Certificates_Stddev_Samp_Order_By>;
  readonly sum?: Maybe<User_Certificates_Sum_Order_By>;
  readonly var_pop?: Maybe<User_Certificates_Var_Pop_Order_By>;
  readonly var_samp?: Maybe<User_Certificates_Var_Samp_Order_By>;
  readonly variance?: Maybe<User_Certificates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_certificates" */
export type User_Certificates_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Certificates_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_Certificates_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Certificates_Avg_Fields = {
  readonly __typename?: 'user_certificates_avg_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_certificates" */
export type User_Certificates_Avg_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_certificates". All fields are combined with a logical 'AND'. */
export type User_Certificates_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<User_Certificates_Bool_Exp>>;
  readonly _not?: Maybe<User_Certificates_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<User_Certificates_Bool_Exp>>;
  readonly certificate_template?: Maybe<Certificate_Templates_Bool_Exp>;
  readonly certificate_template_id?: Maybe<Uuid_Comparison_Exp>;
  readonly course_id?: Maybe<Bigint_Comparison_Exp>;
  readonly date_distributed?: Maybe<Timestamptz_Comparison_Exp>;
  readonly id?: Maybe<Uuid_Comparison_Exp>;
  readonly short_id?: Maybe<String_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly user_id?: Maybe<String_Comparison_Exp>;
  readonly user_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_certificates" */
export enum User_Certificates_Constraint {
  /** unique or primary key constraint */
  UserCertificatesPkey = 'user_certificates_pkey',
  /** unique or primary key constraint */
  UserCertificatesShortIdKey = 'user_certificates_short_id_key'
}

/** input type for incrementing numeric columns in table "user_certificates" */
export type User_Certificates_Inc_Input = {
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_certificates" */
export type User_Certificates_Insert_Input = {
  readonly certificate_template?: Maybe<Certificate_Templates_Obj_Rel_Insert_Input>;
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly date_distributed?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly short_id?: Maybe<Scalars['String']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly user_id?: Maybe<Scalars['String']>;
  readonly user_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Certificates_Max_Fields = {
  readonly __typename?: 'user_certificates_max_fields';
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly date_distributed?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly short_id?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
  readonly user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_certificates" */
export type User_Certificates_Max_Order_By = {
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly date_distributed?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly short_id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
  readonly user_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Certificates_Min_Fields = {
  readonly __typename?: 'user_certificates_min_fields';
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly date_distributed?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly short_id?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
  readonly user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_certificates" */
export type User_Certificates_Min_Order_By = {
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly date_distributed?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly short_id?: Maybe<Order_By>;
  readonly user_id?: Maybe<Order_By>;
  readonly user_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_certificates" */
export type User_Certificates_Mutation_Response = {
  readonly __typename?: 'user_certificates_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Certificates>;
};

/** on conflict condition type for table "user_certificates" */
export type User_Certificates_On_Conflict = {
  readonly constraint: User_Certificates_Constraint;
  readonly update_columns?: ReadonlyArray<User_Certificates_Update_Column>;
  readonly where?: Maybe<User_Certificates_Bool_Exp>;
};

/** Ordering options when selecting data from "user_certificates". */
export type User_Certificates_Order_By = {
  readonly certificate_template?: Maybe<Certificate_Templates_Order_By>;
  readonly certificate_template_id?: Maybe<Order_By>;
  readonly course_id?: Maybe<Order_By>;
  readonly date_distributed?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly short_id?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly user_id?: Maybe<Order_By>;
  readonly user_name?: Maybe<Order_By>;
};

/** primary key columns input for table: user_certificates */
export type User_Certificates_Pk_Columns_Input = {
  readonly id: Scalars['uuid'];
};

/** select columns of table "user_certificates" */
export enum User_Certificates_Select_Column {
  /** column name */
  CertificateTemplateId = 'certificate_template_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  DateDistributed = 'date_distributed',
  /** column name */
  Id = 'id',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "user_certificates" */
export type User_Certificates_Set_Input = {
  readonly certificate_template_id?: Maybe<Scalars['uuid']>;
  readonly course_id?: Maybe<Scalars['bigint']>;
  readonly date_distributed?: Maybe<Scalars['timestamptz']>;
  readonly id?: Maybe<Scalars['uuid']>;
  readonly short_id?: Maybe<Scalars['String']>;
  readonly user_id?: Maybe<Scalars['String']>;
  readonly user_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Certificates_Stddev_Fields = {
  readonly __typename?: 'user_certificates_stddev_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_certificates" */
export type User_Certificates_Stddev_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Certificates_Stddev_Pop_Fields = {
  readonly __typename?: 'user_certificates_stddev_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_certificates" */
export type User_Certificates_Stddev_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Certificates_Stddev_Samp_Fields = {
  readonly __typename?: 'user_certificates_stddev_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_certificates" */
export type User_Certificates_Stddev_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Certificates_Sum_Fields = {
  readonly __typename?: 'user_certificates_sum_fields';
  readonly course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_certificates" */
export type User_Certificates_Sum_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** update columns of table "user_certificates" */
export enum User_Certificates_Update_Column {
  /** column name */
  CertificateTemplateId = 'certificate_template_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  DateDistributed = 'date_distributed',
  /** column name */
  Id = 'id',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type User_Certificates_Var_Pop_Fields = {
  readonly __typename?: 'user_certificates_var_pop_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_certificates" */
export type User_Certificates_Var_Pop_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Certificates_Var_Samp_Fields = {
  readonly __typename?: 'user_certificates_var_samp_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_certificates" */
export type User_Certificates_Var_Samp_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Certificates_Variance_Fields = {
  readonly __typename?: 'user_certificates_variance_fields';
  readonly course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_certificates" */
export type User_Certificates_Variance_Order_By = {
  readonly course_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  PkCace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key'
}

/** columns and relationships of "user_followed_industries_industry" */
export type User_Followed_Industries_Industry = {
  readonly __typename?: 'user_followed_industries_industry';
  /** An object relationship */
  readonly industry: Industry;
  readonly industryName: Scalars['String'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};

/** aggregated selection of "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Aggregate = {
  readonly __typename?: 'user_followed_industries_industry_aggregate';
  readonly aggregate?: Maybe<User_Followed_Industries_Industry_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Followed_Industries_Industry>;
};

/** aggregate fields of "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Aggregate_Fields = {
  readonly __typename?: 'user_followed_industries_industry_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<User_Followed_Industries_Industry_Max_Fields>;
  readonly min?: Maybe<User_Followed_Industries_Industry_Min_Fields>;
};


/** aggregate fields of "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<User_Followed_Industries_Industry_Max_Order_By>;
  readonly min?: Maybe<User_Followed_Industries_Industry_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Followed_Industries_Industry_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_Followed_Industries_Industry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_followed_industries_industry". All fields are combined with a logical 'AND'. */
export type User_Followed_Industries_Industry_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Bool_Exp>>;
  readonly _not?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<User_Followed_Industries_Industry_Bool_Exp>>;
  readonly industry?: Maybe<Industry_Bool_Exp>;
  readonly industryName?: Maybe<String_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_followed_industries_industry" */
export enum User_Followed_Industries_Industry_Constraint {
  /** unique or primary key constraint */
  Pk_906e77b56f64c8389fe6a71fb28 = 'PK_906e77b56f64c8389fe6a71fb28'
}

/** input type for inserting data into table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Insert_Input = {
  readonly industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  readonly industryName?: Maybe<Scalars['String']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Followed_Industries_Industry_Max_Fields = {
  readonly __typename?: 'user_followed_industries_industry_max_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Max_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Followed_Industries_Industry_Min_Fields = {
  readonly __typename?: 'user_followed_industries_industry_min_fields';
  readonly industryName?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Min_Order_By = {
  readonly industryName?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Mutation_Response = {
  readonly __typename?: 'user_followed_industries_industry_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Followed_Industries_Industry>;
};

/** on conflict condition type for table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_On_Conflict = {
  readonly constraint: User_Followed_Industries_Industry_Constraint;
  readonly update_columns?: ReadonlyArray<User_Followed_Industries_Industry_Update_Column>;
  readonly where?: Maybe<User_Followed_Industries_Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "user_followed_industries_industry". */
export type User_Followed_Industries_Industry_Order_By = {
  readonly industry?: Maybe<Industry_Order_By>;
  readonly industryName?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: user_followed_industries_industry */
export type User_Followed_Industries_Industry_Pk_Columns_Input = {
  readonly industryName: Scalars['String'];
  readonly userId: Scalars['String'];
};

/** select columns of table "user_followed_industries_industry" */
export enum User_Followed_Industries_Industry_Select_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_followed_industries_industry" */
export type User_Followed_Industries_Industry_Set_Input = {
  readonly industryName?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "user_followed_industries_industry" */
export enum User_Followed_Industries_Industry_Update_Column {
  /** column name */
  IndustryName = 'industryName',
  /** column name */
  UserId = 'userId'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  readonly birthDate?: Maybe<Scalars['timestamp']>;
  readonly certificatesEarnedId?: Maybe<Scalars['uuid']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly event_managing_users_users?: Maybe<Event_Managing_Users_User_Arr_Rel_Insert_Input>;
  readonly event_participants?: Maybe<Event_Participant_Arr_Rel_Insert_Input>;
  readonly form_scores?: Maybe<Form_Score_Arr_Rel_Insert_Input>;
  readonly id?: Maybe<Scalars['String']>;
  readonly images?: Maybe<Images_Arr_Rel_Insert_Input>;
  readonly isAdmin?: Maybe<Scalars['Boolean']>;
  readonly isLightTheme?: Maybe<Scalars['Boolean']>;
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licensePhotoUuid?: Maybe<Scalars['String']>;
  readonly mock_order2s?: Maybe<Mock_Order2_Arr_Rel_Insert_Input>;
  readonly orders?: Maybe<Order_Arr_Rel_Insert_Input>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly provider_applications?: Maybe<Provider_Application_Arr_Rel_Insert_Input>;
  readonly provider_managing_users_users?: Maybe<Provider_Managing_Users_User_Arr_Rel_Insert_Input>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Obj_Rel_Insert_Input>;
  readonly reward_certificates?: Maybe<Reward_Certificate_Arr_Rel_Insert_Input>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
  readonly user_certificates?: Maybe<User_Certificates_Arr_Rel_Insert_Input>;
  readonly user_followed_industries_industries?: Maybe<User_Followed_Industries_Industry_Arr_Rel_Insert_Input>;
  readonly user_provider_subscriptions_providers?: Maybe<User_Provider_Subscriptions_Provider_Arr_Rel_Insert_Input>;
  readonly user_purchased_events_events?: Maybe<User_Purchased_Events_Event_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  readonly __typename?: 'user_max_fields';
  readonly birthDate?: Maybe<Scalars['timestamp']>;
  readonly certificatesEarnedId?: Maybe<Scalars['uuid']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licensePhotoUuid?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  readonly birthDate?: Maybe<Order_By>;
  readonly certificatesEarnedId?: Maybe<Order_By>;
  readonly city?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly displayName?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly licenseNumber?: Maybe<Order_By>;
  readonly licensePhotoUuid?: Maybe<Order_By>;
  readonly phoneNumber?: Maybe<Order_By>;
  readonly sex?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  readonly __typename?: 'user_min_fields';
  readonly birthDate?: Maybe<Scalars['timestamp']>;
  readonly certificatesEarnedId?: Maybe<Scalars['uuid']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licensePhotoUuid?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  readonly birthDate?: Maybe<Order_By>;
  readonly certificatesEarnedId?: Maybe<Order_By>;
  readonly city?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly displayName?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly licenseNumber?: Maybe<Order_By>;
  readonly licensePhotoUuid?: Maybe<Order_By>;
  readonly phoneNumber?: Maybe<Order_By>;
  readonly sex?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  readonly __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  readonly data: User_Insert_Input;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  readonly constraint: User_Constraint;
  readonly update_columns?: ReadonlyArray<User_Update_Column>;
  readonly where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  readonly birthDate?: Maybe<Order_By>;
  readonly certificatesEarnedId?: Maybe<Order_By>;
  readonly city?: Maybe<Order_By>;
  readonly createdAt?: Maybe<Order_By>;
  readonly displayName?: Maybe<Order_By>;
  readonly displayPhotoUuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly event_managing_users_users_aggregate?: Maybe<Event_Managing_Users_User_Aggregate_Order_By>;
  readonly event_participants_aggregate?: Maybe<Event_Participant_Aggregate_Order_By>;
  readonly form_scores_aggregate?: Maybe<Form_Score_Aggregate_Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly images_aggregate?: Maybe<Images_Aggregate_Order_By>;
  readonly isAdmin?: Maybe<Order_By>;
  readonly isLightTheme?: Maybe<Order_By>;
  readonly licenseNumber?: Maybe<Order_By>;
  readonly licensePhotoUuid?: Maybe<Order_By>;
  readonly mock_order2s_aggregate?: Maybe<Mock_Order2_Aggregate_Order_By>;
  readonly orders_aggregate?: Maybe<Order_Aggregate_Order_By>;
  readonly phoneNumber?: Maybe<Order_By>;
  readonly provider_applications_aggregate?: Maybe<Provider_Application_Aggregate_Order_By>;
  readonly provider_managing_users_users_aggregate?: Maybe<Provider_Managing_Users_User_Aggregate_Order_By>;
  readonly reward_certificate?: Maybe<Reward_Certificate_Order_By>;
  readonly reward_certificates_aggregate?: Maybe<Reward_Certificate_Aggregate_Order_By>;
  readonly sex?: Maybe<Order_By>;
  readonly updatedAt?: Maybe<Order_By>;
  readonly user_certificates_aggregate?: Maybe<User_Certificates_Aggregate_Order_By>;
  readonly user_followed_industries_industries_aggregate?: Maybe<User_Followed_Industries_Industry_Aggregate_Order_By>;
  readonly user_provider_subscriptions_providers_aggregate?: Maybe<User_Provider_Subscriptions_Provider_Aggregate_Order_By>;
  readonly user_purchased_events_events_aggregate?: Maybe<User_Purchased_Events_Event_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  readonly id: Scalars['String'];
};

/** columns and relationships of "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider = {
  readonly __typename?: 'user_provider_subscriptions_provider';
  /** An object relationship */
  readonly provider: Provider;
  readonly providerId: Scalars['uuid'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};

/** aggregated selection of "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Aggregate = {
  readonly __typename?: 'user_provider_subscriptions_provider_aggregate';
  readonly aggregate?: Maybe<User_Provider_Subscriptions_Provider_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Provider_Subscriptions_Provider>;
};

/** aggregate fields of "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Aggregate_Fields = {
  readonly __typename?: 'user_provider_subscriptions_provider_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<User_Provider_Subscriptions_Provider_Max_Fields>;
  readonly min?: Maybe<User_Provider_Subscriptions_Provider_Min_Fields>;
};


/** aggregate fields of "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<User_Provider_Subscriptions_Provider_Max_Order_By>;
  readonly min?: Maybe<User_Provider_Subscriptions_Provider_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Provider_Subscriptions_Provider_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_Provider_Subscriptions_Provider_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_provider_subscriptions_provider". All fields are combined with a logical 'AND'. */
export type User_Provider_Subscriptions_Provider_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Bool_Exp>>;
  readonly _not?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<User_Provider_Subscriptions_Provider_Bool_Exp>>;
  readonly provider?: Maybe<Provider_Bool_Exp>;
  readonly providerId?: Maybe<Uuid_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_provider_subscriptions_provider" */
export enum User_Provider_Subscriptions_Provider_Constraint {
  /** unique or primary key constraint */
  PkD89555eceb44226992e5019b372 = 'PK_d89555eceb44226992e5019b372'
}

/** input type for inserting data into table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Insert_Input = {
  readonly provider?: Maybe<Provider_Obj_Rel_Insert_Input>;
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Provider_Subscriptions_Provider_Max_Fields = {
  readonly __typename?: 'user_provider_subscriptions_provider_max_fields';
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Max_Order_By = {
  readonly providerId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Provider_Subscriptions_Provider_Min_Fields = {
  readonly __typename?: 'user_provider_subscriptions_provider_min_fields';
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Min_Order_By = {
  readonly providerId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Mutation_Response = {
  readonly __typename?: 'user_provider_subscriptions_provider_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Provider_Subscriptions_Provider>;
};

/** on conflict condition type for table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_On_Conflict = {
  readonly constraint: User_Provider_Subscriptions_Provider_Constraint;
  readonly update_columns?: ReadonlyArray<User_Provider_Subscriptions_Provider_Update_Column>;
  readonly where?: Maybe<User_Provider_Subscriptions_Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "user_provider_subscriptions_provider". */
export type User_Provider_Subscriptions_Provider_Order_By = {
  readonly provider?: Maybe<Provider_Order_By>;
  readonly providerId?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: user_provider_subscriptions_provider */
export type User_Provider_Subscriptions_Provider_Pk_Columns_Input = {
  readonly providerId: Scalars['uuid'];
  readonly userId: Scalars['String'];
};

/** select columns of table "user_provider_subscriptions_provider" */
export enum User_Provider_Subscriptions_Provider_Select_Column {
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_provider_subscriptions_provider" */
export type User_Provider_Subscriptions_Provider_Set_Input = {
  readonly providerId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "user_provider_subscriptions_provider" */
export enum User_Provider_Subscriptions_Provider_Update_Column {
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "user_purchased_events_event" */
export type User_Purchased_Events_Event = {
  readonly __typename?: 'user_purchased_events_event';
  /** An object relationship */
  readonly event: Event;
  readonly eventId: Scalars['uuid'];
  /** An object relationship */
  readonly user: User;
  readonly userId: Scalars['String'];
};

/** aggregated selection of "user_purchased_events_event" */
export type User_Purchased_Events_Event_Aggregate = {
  readonly __typename?: 'user_purchased_events_event_aggregate';
  readonly aggregate?: Maybe<User_Purchased_Events_Event_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Purchased_Events_Event>;
};

/** aggregate fields of "user_purchased_events_event" */
export type User_Purchased_Events_Event_Aggregate_Fields = {
  readonly __typename?: 'user_purchased_events_event_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<User_Purchased_Events_Event_Max_Fields>;
  readonly min?: Maybe<User_Purchased_Events_Event_Min_Fields>;
};


/** aggregate fields of "user_purchased_events_event" */
export type User_Purchased_Events_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Aggregate_Order_By = {
  readonly count?: Maybe<Order_By>;
  readonly max?: Maybe<User_Purchased_Events_Event_Max_Order_By>;
  readonly min?: Maybe<User_Purchased_Events_Event_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Purchased_Events_Event_Insert_Input>;
  /** on conflict condition */
  readonly on_conflict?: Maybe<User_Purchased_Events_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_purchased_events_event". All fields are combined with a logical 'AND'. */
export type User_Purchased_Events_Event_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Bool_Exp>>;
  readonly _not?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<User_Purchased_Events_Event_Bool_Exp>>;
  readonly event?: Maybe<Event_Bool_Exp>;
  readonly eventId?: Maybe<Uuid_Comparison_Exp>;
  readonly user?: Maybe<User_Bool_Exp>;
  readonly userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_purchased_events_event" */
export enum User_Purchased_Events_Event_Constraint {
  /** unique or primary key constraint */
  PkDe52b248b04045a40aa17a371eb = 'PK_de52b248b04045a40aa17a371eb'
}

/** input type for inserting data into table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Insert_Input = {
  readonly event?: Maybe<Event_Obj_Rel_Insert_Input>;
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly user?: Maybe<User_Obj_Rel_Insert_Input>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Purchased_Events_Event_Max_Fields = {
  readonly __typename?: 'user_purchased_events_event_max_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Max_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Purchased_Events_Event_Min_Fields = {
  readonly __typename?: 'user_purchased_events_event_min_fields';
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Min_Order_By = {
  readonly eventId?: Maybe<Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Mutation_Response = {
  readonly __typename?: 'user_purchased_events_event_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Purchased_Events_Event>;
};

/** on conflict condition type for table "user_purchased_events_event" */
export type User_Purchased_Events_Event_On_Conflict = {
  readonly constraint: User_Purchased_Events_Event_Constraint;
  readonly update_columns?: ReadonlyArray<User_Purchased_Events_Event_Update_Column>;
  readonly where?: Maybe<User_Purchased_Events_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "user_purchased_events_event". */
export type User_Purchased_Events_Event_Order_By = {
  readonly event?: Maybe<Event_Order_By>;
  readonly eventId?: Maybe<Order_By>;
  readonly user?: Maybe<User_Order_By>;
  readonly userId?: Maybe<Order_By>;
};

/** primary key columns input for table: user_purchased_events_event */
export type User_Purchased_Events_Event_Pk_Columns_Input = {
  readonly eventId: Scalars['uuid'];
  readonly userId: Scalars['String'];
};

/** select columns of table "user_purchased_events_event" */
export enum User_Purchased_Events_Event_Select_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_purchased_events_event" */
export type User_Purchased_Events_Event_Set_Input = {
  readonly eventId?: Maybe<Scalars['uuid']>;
  readonly userId?: Maybe<Scalars['String']>;
};

/** update columns of table "user_purchased_events_event" */
export enum User_Purchased_Events_Event_Update_Column {
  /** column name */
  EventId = 'eventId',
  /** column name */
  UserId = 'userId'
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CertificatesEarnedId = 'certificatesEarnedId',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsLightTheme = 'isLightTheme',
  /** column name */
  LicenseNumber = 'licenseNumber',
  /** column name */
  LicensePhotoUuid = 'licensePhotoUuid',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Sex = 'sex',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  readonly birthDate?: Maybe<Scalars['timestamp']>;
  readonly certificatesEarnedId?: Maybe<Scalars['uuid']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['timestamp']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly displayPhotoUuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly isAdmin?: Maybe<Scalars['Boolean']>;
  readonly isLightTheme?: Maybe<Scalars['Boolean']>;
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licensePhotoUuid?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly updatedAt?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  BirthDate = 'birthDate',
  /** column name */
  CertificatesEarnedId = 'certificatesEarnedId',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DisplayPhotoUuid = 'displayPhotoUuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsLightTheme = 'isLightTheme',
  /** column name */
  LicenseNumber = 'licenseNumber',
  /** column name */
  LicensePhotoUuid = 'licensePhotoUuid',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Sex = 'sex',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "users" */
export type Users = {
  readonly __typename?: 'users';
  readonly bio: Scalars['String'];
  readonly birth_date: Scalars['timestamp'];
  readonly city: Scalars['String'];
  readonly created_at: Scalars['timestamptz'];
  readonly display_name: Scalars['String'];
  readonly display_photo_uuid: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['String'];
  readonly locale: Scalars['String'];
  readonly phone_number: Scalars['String'];
  readonly sex: Scalars['String'];
  readonly tenant_id: Scalars['String'];
  readonly time_zone: Scalars['String'];
  readonly updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  readonly __typename?: 'users_aggregate';
  readonly aggregate?: Maybe<Users_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  readonly __typename?: 'users_aggregate_fields';
  readonly count: Scalars['Int'];
  readonly max?: Maybe<Users_Max_Fields>;
  readonly min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<ReadonlyArray<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  readonly _and?: Maybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly _not?: Maybe<Users_Bool_Exp>;
  readonly _or?: Maybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly bio?: Maybe<String_Comparison_Exp>;
  readonly birth_date?: Maybe<Timestamp_Comparison_Exp>;
  readonly city?: Maybe<String_Comparison_Exp>;
  readonly created_at?: Maybe<Timestamptz_Comparison_Exp>;
  readonly display_name?: Maybe<String_Comparison_Exp>;
  readonly display_photo_uuid?: Maybe<String_Comparison_Exp>;
  readonly email?: Maybe<String_Comparison_Exp>;
  readonly id?: Maybe<String_Comparison_Exp>;
  readonly locale?: Maybe<String_Comparison_Exp>;
  readonly phone_number?: Maybe<String_Comparison_Exp>;
  readonly sex?: Maybe<String_Comparison_Exp>;
  readonly tenant_id?: Maybe<String_Comparison_Exp>;
  readonly time_zone?: Maybe<String_Comparison_Exp>;
  readonly updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  readonly bio?: Maybe<Scalars['String']>;
  readonly birth_date?: Maybe<Scalars['timestamp']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly display_name?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly locale?: Maybe<Scalars['String']>;
  readonly phone_number?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly time_zone?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  readonly __typename?: 'users_max_fields';
  readonly bio?: Maybe<Scalars['String']>;
  readonly birth_date?: Maybe<Scalars['timestamp']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly display_name?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly locale?: Maybe<Scalars['String']>;
  readonly phone_number?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly time_zone?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  readonly __typename?: 'users_min_fields';
  readonly bio?: Maybe<Scalars['String']>;
  readonly birth_date?: Maybe<Scalars['timestamp']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly display_name?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly locale?: Maybe<Scalars['String']>;
  readonly phone_number?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly time_zone?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  readonly __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  readonly constraint: Users_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Update_Column>;
  readonly where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  readonly bio?: Maybe<Order_By>;
  readonly birth_date?: Maybe<Order_By>;
  readonly city?: Maybe<Order_By>;
  readonly created_at?: Maybe<Order_By>;
  readonly display_name?: Maybe<Order_By>;
  readonly display_photo_uuid?: Maybe<Order_By>;
  readonly email?: Maybe<Order_By>;
  readonly id?: Maybe<Order_By>;
  readonly locale?: Maybe<Order_By>;
  readonly phone_number?: Maybe<Order_By>;
  readonly sex?: Maybe<Order_By>;
  readonly tenant_id?: Maybe<Order_By>;
  readonly time_zone?: Maybe<Order_By>;
  readonly updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  readonly id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Bio = 'bio',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  DisplayPhotoUuid = 'display_photo_uuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Sex = 'sex',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  readonly bio?: Maybe<Scalars['String']>;
  readonly birth_date?: Maybe<Scalars['timestamp']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly created_at?: Maybe<Scalars['timestamptz']>;
  readonly display_name?: Maybe<Scalars['String']>;
  readonly display_photo_uuid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly locale?: Maybe<Scalars['String']>;
  readonly phone_number?: Maybe<Scalars['String']>;
  readonly sex?: Maybe<Scalars['String']>;
  readonly tenant_id?: Maybe<Scalars['String']>;
  readonly time_zone?: Maybe<Scalars['String']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Bio = 'bio',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  DisplayPhotoUuid = 'display_photo_uuid',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Sex = 'sex',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  readonly _eq?: Maybe<Scalars['uuid']>;
  readonly _gt?: Maybe<Scalars['uuid']>;
  readonly _gte?: Maybe<Scalars['uuid']>;
  readonly _in?: Maybe<ReadonlyArray<Scalars['uuid']>>;
  readonly _is_null?: Maybe<Scalars['Boolean']>;
  readonly _lt?: Maybe<Scalars['uuid']>;
  readonly _lte?: Maybe<Scalars['uuid']>;
  readonly _neq?: Maybe<Scalars['uuid']>;
  readonly _nin?: Maybe<ReadonlyArray<Scalars['uuid']>>;
};

export type CreateAttachmentMutationVariables = Exact<{
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  rootType: Scalars['String'];
  rootEntityId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  bucket: Scalars['String'];
}>;


export type CreateAttachmentMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly attachment?: Maybe<(
    { readonly __typename?: 'attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type CreateAttendanceMutationVariables = Exact<{
  liveSessionId?: Maybe<Scalars['bigint']>;
}>;


export type CreateAttendanceMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_attendances_one?: Maybe<(
    { readonly __typename?: 'attendances' }
    & Pick<Attendances, 'id' | 'created_at'>
  )> }
);

export type CreateCertificateSignatoriesMutationVariables = Exact<{
  signatoryId: Scalars['uuid'];
  certificateTemplateId: Scalars['uuid'];
  isSuperimposed: Scalars['Boolean'];
}>;


export type CreateCertificateSignatoriesMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly certificateSignatory?: Maybe<(
    { readonly __typename?: 'certificate_template_signatories' }
    & Pick<Certificate_Template_Signatories, 'id'>
  )> }
);

export type CreateCourseMutationVariables = Exact<{
  providerId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  courseDisplayPhotoUuid?: Maybe<Scalars['String']>;
  coursePreviewVideoUrl?: Maybe<Scalars['String']>;
  courseSellType?: Maybe<Course_Sell_Types_Enum>;
  consumablePeriod?: Maybe<Scalars['numeric']>;
  requireSequentialProgress?: Maybe<Scalars['Boolean']>;
  displayStudents?: Maybe<Scalars['Boolean']>;
  paymentLink?: Maybe<Scalars['String']>;
}>;


export type CreateCourseMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], providerId: Courses['provider_id'], createdAt: Courses['created_at'] }
  )> }
);

export type CreateCourseArticleMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseArticleMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseArticleDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCourseArticleDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseAssessmentMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseAssessmentMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseAssessmentDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  formId?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCourseAssessmentDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseAttachmentMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseAttachmentMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseAttachmentDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
}>;


export type CreateCourseAttachmentDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseCpdUnitRewardMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  certificateType: Scalars['Int'];
  isSelfDirected: Scalars['Boolean'];
  units: Scalars['Int'];
  industry: Scalars['String'];
}>;


export type CreateCourseCpdUnitRewardMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseReward?: Maybe<(
    { readonly __typename?: 'course_rewards' }
    & Pick<Course_Rewards, 'id'>
  )> }
);

export type CreateCourseCertificateMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  providerId: Scalars['uuid'];
  accreditationNumber?: Maybe<Scalars['String']>;
  dateHeld?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  hasLogo?: Maybe<Scalars['Boolean']>;
  hasProviderName?: Maybe<Scalars['Boolean']>;
  hasAwardsThis?: Maybe<Scalars['Boolean']>;
  hasToDivider?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
}>;


export type CreateCourseCertificateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly certificate?: Maybe<(
    { readonly __typename?: 'certificate_templates' }
    & Pick<Certificate_Templates, 'id'>
  )> }
);

export type CreateCourseDiscountMutationVariables = Exact<{
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['date']>;
  courseId: Scalars['Int'];
  providerId?: Maybe<Scalars['uuid']>;
  count: Scalars['Int'];
  value: Scalars['numeric'];
  type: Course_Discount_Types_Enum;
  infiniteUsage: Scalars['Boolean'];
}>;


export type CreateCourseDiscountMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseDiscount?: Maybe<(
    { readonly __typename?: 'course_discounts' }
    & Pick<Course_Discounts, 'id'>
  )> }
);

export type CreateCourseFormMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseFormMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseFormDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  formId?: Maybe<Scalars['uuid']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCourseFormDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseGainMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  description: Scalars['String'];
}>;


export type CreateCourseGainMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly gain?: Maybe<(
    { readonly __typename?: 'course_gains' }
    & Pick<Course_Gains, 'description' | 'id'>
  )> }
);

export type CreateCourseInstructorMutationVariables = Exact<{
  userId: Scalars['String'];
  courseId: Scalars['bigint'];
  email: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateCourseInstructorMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInstructor?: Maybe<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'] }
  )> }
);

export type CreateCourseInviteMutationVariables = Exact<{
  courseId: Scalars['Int'];
  freeModules?: Maybe<Scalars['_text']>;
  giveProceedingAccess: Scalars['Boolean'];
  isCourseFree: Scalars['Boolean'];
  userDisplayName?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
}>;


export type CreateCourseInviteMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInvite?: Maybe<(
    { readonly __typename?: 'course_invites' }
    & Pick<Course_Invites, 'created_at' | 'id'>
  )> }
);

export type CreateCourseInvitesMutationVariables = Exact<{
  objects: ReadonlyArray<Course_Invites_Insert_Input> | Course_Invites_Insert_Input;
}>;


export type CreateCourseInvitesMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInvite?: Maybe<(
    { readonly __typename?: 'course_invites_mutation_response' }
    & Pick<Course_Invites_Mutation_Response, 'affected_rows'>
    & { readonly returning: ReadonlyArray<(
      { readonly __typename?: 'course_invites' }
      & Pick<Course_Invites, 'created_at' | 'id' | 'user_email'>
    )> }
  )> }
);

export type CreateCourseLecturerMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  speakerId: Scalars['bigint'];
}>;


export type CreateCourseLecturerMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseLecturer?: Maybe<(
    { readonly __typename?: 'course_lecturers' }
    & { courseId: Course_Lecturers['course_id'], speakerId: Course_Lecturers['speaker_id'] }
  )> }
);

export type CreateCourseLiveSessionMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseLiveSessionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseLiveSessionDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  sessionDate?: Maybe<Scalars['timestamptz']>;
  timeStart?: Maybe<Scalars['timestamptz']>;
  timeEnd?: Maybe<Scalars['timestamptz']>;
  streamingService?: Maybe<Scalars['Int']>;
  attendanceEnabled?: Maybe<Scalars['Boolean']>;
  customLink?: Maybe<Scalars['String']>;
}>;


export type CreateCourseLiveSessionDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseModuleMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  requireSequentialProgress?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['numeric']>;
  consumablePeriod?: Maybe<Scalars['numeric']>;
  order: Scalars['Int'];
}>;


export type CreateCourseModuleMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseModule?: Maybe<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'id' | 'title' | 'description' | 'price' | 'order'>
    & { courseId: Course_Modules['course_id'], requireSequentialProgress: Course_Modules['require_sequential_progress'] }
  )> }
);

export type CreateCourseModuleItemCommentMutationVariables = Exact<{
  message: Scalars['String'];
  userId: Scalars['String'];
  moduleItemId: Scalars['bigint'];
  isProvider?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCourseModuleItemCommentMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_module_item_comments_one?: Maybe<(
    { readonly __typename?: 'module_item_comments' }
    & Pick<Module_Item_Comments, 'id'>
  )> }
);

export type CreateCourseObjectiveMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  description: Scalars['String'];
}>;


export type CreateCourseObjectiveMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly objective?: Maybe<(
    { readonly __typename?: 'course_objectives' }
    & Pick<Course_Objectives, 'description' | 'id'>
  )> }
);

export type CreateCourseOtherRewardMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  certificateType: Scalars['Int'];
  otherUnitType: Scalars['String'];
  units: Scalars['Int'];
}>;


export type CreateCourseOtherRewardMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseReward?: Maybe<(
    { readonly __typename?: 'course_rewards' }
    & Pick<Course_Rewards, 'id'>
  )> }
);

export type CreateCoursePaymentRequestMutationVariables = Exact<{
  id: Scalars['Int'];
  paymentChannel?: Maybe<Scalars['String']>;
  selectedModules: ReadonlyArray<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  discountCode?: Maybe<Scalars['String']>;
  courseInviteId?: Maybe<Scalars['String']>;
  proofPaymentUuid?: Maybe<Scalars['String']>;
}>;


export type CreateCoursePaymentRequestMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly result?: Maybe<(
    { readonly __typename?: 'CoursePaymentResponse' }
    & Pick<CoursePaymentResponse, 'url'>
  )> }
);

export type CreateCourseRankingRewardMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  certificateType: Scalars['Int'];
  days: Scalars['Int'];
  rankingType: Scalars['Int'];
}>;


export type CreateCourseRankingRewardMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseReward?: Maybe<(
    { readonly __typename?: 'course_rewards' }
    & Pick<Course_Rewards, 'id'>
  )> }
);

export type CreateCourseReminderMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  entityId: Scalars['bigint'];
  entityName: Scalars['String'];
  entityType: Scalars['String'];
  studentEmail: Scalars['String'];
}>;


export type CreateCourseReminderMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly reminder?: Maybe<(
    { readonly __typename?: 'course_reminders' }
    & Pick<Course_Reminders, 'id'>
    & { courseId: Course_Reminders['course_id'], entityId: Course_Reminders['entity_id'], entityName: Course_Reminders['entity_name'], entityType: Course_Reminders['entity_type'], studentEmail: Course_Reminders['student_email'] }
  )> }
);

export type CreateCourseRequirementMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  description: Scalars['String'];
}>;


export type CreateCourseRequirementMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly requirement?: Maybe<(
    { readonly __typename?: 'course_requirements' }
    & Pick<Course_Requirements, 'description' | 'id'>
  )> }
);

export type CreateCourseSignatoriesMutationVariables = Exact<{
  objects: ReadonlyArray<Course_Signatories_Insert_Input> | Course_Signatories_Insert_Input;
}>;


export type CreateCourseSignatoriesMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_course_signatories?: Maybe<(
    { readonly __typename?: 'course_signatories_mutation_response' }
    & { readonly returning: ReadonlyArray<(
      { readonly __typename?: 'course_signatories' }
      & Pick<Course_Signatories, 'id'>
    )> }
  )> }
);

export type CreateCourseVideoMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateCourseVideoMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateCourseVideoDuplicateMutationVariables = Exact<{
  moduleId: Scalars['bigint'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  enableCommentSection?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Course_Videos_Statuses_Enum>;
  source?: Maybe<Scalars['String']>;
}>;


export type CreateCourseVideoDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type CreateFormMutationVariables = Exact<{
  providerId: Scalars['uuid'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Form_Types_Enum>;
}>;


export type CreateFormMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'forms' }
    & Pick<Forms, 'id' | 'title' | 'description' | 'type'>
  )> }
);

export type CreateFormQuestionMutationVariables = Exact<{
  formId: Scalars['uuid'];
  name: Scalars['String'];
  type: Form_Question_Types_Enum;
  position: Scalars['numeric'];
  isOpenEnded: Scalars['Boolean'];
  caption?: Maybe<Scalars['String']>;
}>;


export type CreateFormQuestionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestion?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & Pick<Form_Questions, 'id' | 'name' | 'type'>
  )> }
);

export type CreateFormQuestionAnswerMutationVariables = Exact<{
  formQuestionId: Scalars['uuid'];
  name: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  points: Scalars['numeric'];
}>;


export type CreateFormQuestionAnswerMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestionAnswer?: Maybe<(
    { readonly __typename?: 'form_question_answers' }
    & Pick<Form_Question_Answers, 'id' | 'name' | 'feedback' | 'points'>
  )> }
);

export type CreateFormQuestionSubmissionMutationVariables = Exact<{
  objects: ReadonlyArray<Form_Question_Submissions_Insert_Input> | Form_Question_Submissions_Insert_Input;
}>;


export type CreateFormQuestionSubmissionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestionSubmission?: Maybe<(
    { readonly __typename?: 'form_question_submissions_mutation_response' }
    & Pick<Form_Question_Submissions_Mutation_Response, 'affected_rows'>
  )> }
);

export type CreateFormSubmissionMutationVariables = Exact<{
  formId: Scalars['uuid'];
  courseFormId?: Maybe<Scalars['uuid']>;
  userId: Scalars['String'];
  startedAt: Scalars['timestamp'];
  attempt: Scalars['numeric'];
}>;


export type CreateFormSubmissionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formSubmission?: Maybe<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id' | 'started_at'>
  )> }
);

export type CreateManagingUserMutationVariables = Exact<{
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
}>;


export type CreateManagingUserMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly managingUser?: Maybe<(
    { readonly __typename?: 'provider_managing_users_user' }
    & Pick<Provider_Managing_Users_User, 'providerId' | 'userId'>
  )> }
);

export type CreateProviderMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateProviderMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly provider?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id'>
  )> }
);

export type CreateProviderDuplicateMutationVariables = Exact<{
  data: Provider_Insert_Input;
}>;


export type CreateProviderDuplicateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly provider?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'aboutVideoUuid' | 'email' | 'createdAt' | 'isArchived' | 'prcAccreditationNumber' | 'website' | 'lyonCommunity' | 'certificateLogoUuid' | 'isLightTheme'>
    & { readonly signatories: ReadonlyArray<(
      { readonly __typename?: 'certificate_signatory' }
      & Pick<Certificate_Signatory, 'id' | 'companyName' | 'honorific' | 'isActive' | 'name' | 'position' | 'signatureImageUuid' | 'uploaderId'>
    )> }
  )> }
);

export type CreateSignatoryMutationVariables = Exact<{
  providerId: Scalars['uuid'];
  companyName: Scalars['String'];
  honorific?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position: Scalars['String'];
  signatureImageUuid: Scalars['String'];
  uploaderId?: Maybe<Scalars['String']>;
}>;


export type CreateSignatoryMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly signatory?: Maybe<(
    { readonly __typename?: 'certificate_signatory' }
    & Pick<Certificate_Signatory, 'id'>
  )> }
);

export type CreateSpeakerMutationVariables = Exact<{
  description: Scalars['String'];
  displayPhotoUuid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
}>;


export type CreateSpeakerMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly speaker?: Maybe<(
    { readonly __typename?: 'speakers' }
    & Pick<Speakers, 'id'>
  )> }
);

export type CreateStudentProgessMutationVariables = Exact<{
  moduleItemId: Scalars['bigint'];
  courseStudentId: Scalars['bigint'];
}>;


export type CreateStudentProgessMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly studentProgress?: Maybe<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id'>
    & { isComplete: Student_Progress['is_complete'], moduleItemId: Student_Progress['module_item_id'], studentId: Student_Progress['course_student_id'] }
  )> }
);

export type CreateUploadUrlToBunnyMutationVariables = Exact<{
  bunnyId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  state: Bunny_Upload_States_Enum;
  userId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['bigint']>;
}>;


export type CreateUploadUrlToBunnyMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly bunnyUpload?: Maybe<(
    { readonly __typename?: 'bunny_upload' }
    & Pick<Bunny_Upload, 'id' | 'location' | 'state' | 'status'>
    & { bunnyId: Bunny_Upload['bunny_id'], userId: Bunny_Upload['user_id'], providerId: Bunny_Upload['provider_id'], courseId: Bunny_Upload['course_id'] }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
  displayName: Scalars['String'];
  displayPhotoUuid?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'displayName' | 'displayPhotoUuid'>
  )> }
);

export type CreateUserAttendanceLogMutationVariables = Exact<{
  attendanceId: Scalars['uuid'];
}>;


export type CreateUserAttendanceLogMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_attendance_logs_one?: Maybe<(
    { readonly __typename?: 'attendance_logs' }
    & Pick<Attendance_Logs, 'id' | 'created_at'>
  )> }
);

export type CreateUserCertificatesMutationVariables = Exact<{
  objects: ReadonlyArray<User_Certificates_Insert_Input> | User_Certificates_Insert_Input;
}>;


export type CreateUserCertificatesMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly students?: Maybe<(
    { readonly __typename?: 'user_certificates_mutation_response' }
    & Pick<User_Certificates_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCertificateSignatoryByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCertificateSignatoryByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly signatory?: Maybe<(
    { readonly __typename?: 'certificate_template_signatories' }
    & Pick<Certificate_Template_Signatories, 'id'>
  )> }
);

export type DeleteCourseByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id'>
  )> }
);

export type DeleteCourseCertificateByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCourseCertificateByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly certificate?: Maybe<(
    { readonly __typename?: 'certificate_templates' }
    & Pick<Certificate_Templates, 'id'>
  )> }
);

export type DeleteCourseDiscountByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCourseDiscountByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseDiscount?: Maybe<(
    { readonly __typename?: 'course_discounts' }
    & Pick<Course_Discounts, 'id'>
  )> }
);

export type DeleteCourseGainByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCourseGainByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseGain?: Maybe<(
    { readonly __typename?: 'course_gains' }
    & Pick<Course_Gains, 'id'>
  )> }
);

export type DeleteCourseInviteByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCourseInviteByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInvite?: Maybe<(
    { readonly __typename?: 'course_invites' }
    & Pick<Course_Invites, 'id'>
  )> }
);

export type DeleteCourseLecturerByIdMutationVariables = Exact<{
  speakerId: Scalars['bigint'];
  courseId: Scalars['bigint'];
}>;


export type DeleteCourseLecturerByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseLecturer?: Maybe<(
    { readonly __typename?: 'course_lecturers_mutation_response' }
    & Pick<Course_Lecturers_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCourseModuleByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseModuleByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseModule?: Maybe<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'id'>
  )> }
);

export type DeleteCourseModuleItemByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseModuleItemByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id'>
  )> }
);

export type DeleteCourseObjectiveByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseObjectiveByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseObjective?: Maybe<(
    { readonly __typename?: 'course_objectives' }
    & Pick<Course_Objectives, 'id'>
  )> }
);

export type DeleteCourseRequirementByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseRequirementByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseRequirement?: Maybe<(
    { readonly __typename?: 'course_requirements' }
    & Pick<Course_Requirements, 'id'>
  )> }
);

export type DeleteCourseRewardByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteCourseRewardByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseReward?: Maybe<(
    { readonly __typename?: 'course_rewards' }
    & { courseId: Course_Rewards['course_id'] }
  )> }
);

export type DeleteFormByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteFormByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'forms' }
    & Pick<Forms, 'id'>
  )> }
);

export type DeleteFormQuestionAnswerByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteFormQuestionAnswerByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestionAnswer?: Maybe<(
    { readonly __typename?: 'form_question_answers' }
    & Pick<Form_Question_Answers, 'id'>
  )> }
);

export type DeleteFormQuestionByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteFormQuestionByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestion?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & Pick<Form_Questions, 'id'>
  )> }
);

export type DeleteManagingUserByIdMutationVariables = Exact<{
  providerId: Scalars['uuid'];
  userId: Scalars['String'];
}>;


export type DeleteManagingUserByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly managingUser?: Maybe<(
    { readonly __typename?: 'provider_managing_users_user' }
    & Pick<Provider_Managing_Users_User, 'providerId' | 'userId'>
  )> }
);

export type DeleteProviderByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteProviderByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly provider?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id'>
  )> }
);

export type DeleteSignatoryByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSignatoryByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly signatory?: Maybe<(
    { readonly __typename?: 'certificate_signatory' }
    & Pick<Certificate_Signatory, 'id'>
  )> }
);

export type DeleteUserCertificateByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserCertificateByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly userCertificate?: Maybe<(
    { readonly __typename?: 'user_certificates' }
    & Pick<User_Certificates, 'id'>
  )> }
);

export type GetCertificatePageGsspQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCertificatePageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly userCertificate?: Maybe<(
    { readonly __typename?: 'user_certificates' }
    & Pick<User_Certificates, 'id'>
    & { dateDistributed: User_Certificates['date_distributed'], shortId: User_Certificates['short_id'], userName: User_Certificates['user_name'] }
    & { readonly certificateTemplate: (
      { readonly __typename?: 'certificate_templates' }
      & Pick<Certificate_Templates, 'description' | 'id' | 'type' | 'label'>
      & { accreditationNumber: Certificate_Templates['accreditation_number'], courseId: Certificate_Templates['course_id'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
    ) }
  )> }
);

export type GetCoursePageGsspQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCoursePageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { isArchived: Courses['is_archived'], courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], coursePreviewVideoUrl: Courses['course_preview_video_url'], prcAccreditationNumber: Courses['prc_accreditation_number'], providerId: Courses['provider_id'], sellType: Courses['course_sell_type'], publishStatus: Courses['publish_status'], paymentLink: Courses['payment_link'], lyonCommunity: Courses['lyon_community'], communityDescription: Courses['community_description'] }
    & { readonly lecturers: ReadonlyArray<(
      { readonly __typename?: 'course_lecturers' }
      & { readonly speaker?: Maybe<(
        { readonly __typename?: 'speakers' }
        & Pick<Speakers, 'id' | 'name' | 'description'>
        & { userId: Speakers['user_id'] }
      )> }
    )> }
  )> }
);

export type GetCourseStudentPageGsspQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseStudentPageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly student?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
    & { isActive: Course_Students['is_active'] }
  )> }
);

export type GetCourseTransactionPageGsspQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCourseTransactionPageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly order?: Maybe<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status' | 'course_invite_id'>
    & { refNo: Course_Order['ref_no'], totalPrice: Course_Order['total_price'], purchaseDate: Course_Order['purchase_date'], paymentChannel: Course_Order['payment_method'], userId: Course_Order['user_id'] }
    & { readonly course_discount?: Maybe<(
      { readonly __typename?: 'course_discounts' }
      & Pick<Course_Discounts, 'id' | 'value' | 'type'>
    )>, readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'price' | 'provider_id'>
      & { displayPhotoUuid: Courses['course_display_photo_uuid'] }
    ), readonly selectedModules: ReadonlyArray<(
      { readonly __typename?: 'course_order_modules' }
      & { readonly courseModule?: Maybe<(
        { readonly __typename?: 'course_modules' }
        & Pick<Course_Modules, 'id' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type GetDiscoverPageGsspQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscoverPageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'] }
  )> }
);

export type GetProfilePageGsspQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProfilePageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'displayName' | 'city' | 'phoneNumber' | 'birthDate' | 'displayPhotoUuid' | 'sex'>
  )> }
);

export type GetProviderPageGsspQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProviderPageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'email' | 'prcAccreditationNumber' | 'website' | 'lyonCommunity'>
  )> }
);

export type GetSearchPageGsspQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type GetSearchPageGsspQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], prcAccreditationNumber: Courses['prc_accreditation_number'] }
  )>, readonly providers: ReadonlyArray<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'email' | 'prcAccreditationNumber'>
  )> }
);

export type GetActiveCourseStudentQueryVariables = Exact<{
  courseID: Scalars['bigint'];
  userID: Scalars['String'];
}>;


export type GetActiveCourseStudentQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseStudents: ReadonlyArray<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
    & { userId: Course_Students['user_id'], orderId: Course_Students['order_id'], courseId: Course_Students['course_id'], approvalStatus: Course_Students['approval_status'], isActive: Course_Students['is_active'], registeredAt: Course_Students['registered_at'], startedAt: Course_Students['started_at'], expiredAt: Course_Students['expired_at'], hasProceedingAccess: Course_Students['has_proceeding_access'] }
    & { readonly courseModuleStudents: ReadonlyArray<(
      { readonly __typename?: 'course_module_students' }
      & Pick<Course_Module_Students, 'id'>
      & { courseModuleId: Course_Module_Students['course_module_id'], registeredAt: Course_Module_Students['registered_at'], startedAt: Course_Module_Students['started_at'], expiredAt: Course_Module_Students['expired_at'] }
      & { readonly courseModule: (
        { readonly __typename?: 'course_modules' }
        & Pick<Course_Modules, 'title'>
        & { consumablePeriod: Course_Modules['consumable_period'] }
      ) }
    )> }
  )> }
);

export type GetAttendanceLogsQueryVariables = Exact<{
  attendanceId: Scalars['uuid'];
}>;


export type GetAttendanceLogsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'attendance_logs' }
    & Pick<Attendance_Logs, 'id'>
    & { createdAt: Attendance_Logs['created_at'], userId: Attendance_Logs['user_id'] }
  )> }
);

export type GetAttendanceLogsByUserIdQueryVariables = Exact<{
  liveSessionId: Scalars['bigint'];
  userId: Scalars['String'];
}>;


export type GetAttendanceLogsByUserIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'attendance_logs' }
    & Pick<Attendance_Logs, 'id'>
    & { createdAt: Attendance_Logs['created_at'] }
    & { readonly attendance: (
      { readonly __typename?: 'attendances' }
      & Pick<Attendances, 'id'>
    ) }
  )> }
);

export type GetAttendancesQueryVariables = Exact<{
  liveSessionId: Scalars['bigint'];
}>;


export type GetAttendancesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'attendances' }
    & Pick<Attendances, 'id'>
    & { createdAt: Attendances['created_at'] }
    & { readonly attendanceLogs: ReadonlyArray<(
      { readonly __typename?: 'attendance_logs' }
      & Pick<Attendance_Logs, 'id'>
      & { userId: Attendance_Logs['user_id'], createdAt: Attendance_Logs['created_at'] }
    )> }
  )> }
);

export type GetBasicUserDetailsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetBasicUserDetailsByEmailQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user: ReadonlyArray<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'email'>
  )> }
);

export type GetBasicUserDetailsByIDsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;


export type GetBasicUserDetailsByIDsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user: ReadonlyArray<(
    { readonly __typename?: 'user' }
    & Pick<User, 'displayName' | 'email' | 'id' | 'displayPhotoUuid'>
  )> }
);

export type GetBunnyUploadDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetBunnyUploadDetailsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly bunnyUpload?: Maybe<(
    { readonly __typename?: 'bunny_upload' }
    & Pick<Bunny_Upload, 'id' | 'location' | 'state' | 'status'>
    & { userId: Bunny_Upload['user_id'], providerId: Bunny_Upload['provider_id'], courseId: Bunny_Upload['course_id'], bunnyGuid: Bunny_Upload['bunny_id'] }
  )> }
);

export type GetCourseArticleByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseArticleByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly article: ReadonlyArray<(
      { readonly __typename?: 'course_articles' }
      & Pick<Course_Articles, 'id' | 'body'>
      & { moduleItemId: Course_Articles['module_item_id'] }
    )> }
  )> }
);

export type GetCourseAssessmentByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseAssessmentByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly assessment: ReadonlyArray<(
      { readonly __typename?: 'course_assessments' }
      & Pick<Course_Assessments, 'id'>
      & { formId: Course_Assessments['form_id'], moduleItemId: Course_Assessments['module_item_id'], isLocked: Course_Assessments['is_locked'] }
    )> }
  )> }
);

export type GetCourseByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], coursePreviewVideoUrl: Courses['course_preview_video_url'], prcAccreditationNumber: Courses['prc_accreditation_number'], publishStatus: Courses['publish_status'], isArchived: Courses['is_archived'], isPinned: Courses['is_pinned'], providerId: Courses['provider_id'], createdAt: Courses['created_at'], updatedAt: Courses['updated_at'], sellType: Courses['course_sell_type'], displayStudents: Courses['display_students'], consumablePeriod: Courses['consumable_period'], requireSequentialProgress: Courses['require_sequential_progress'], paymentLink: Courses['payment_link'], lyonCommunity: Courses['lyon_community'], communityDescription: Courses['community_description'] }
    & { readonly moduleCount: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ), readonly studentCount: (
      { readonly __typename?: 'course_students_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_students_aggregate_fields' }
        & Pick<Course_Students_Aggregate_Fields, 'count'>
      )> }
    ), readonly videoHour: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ), readonly objectives: ReadonlyArray<(
      { readonly __typename?: 'course_objectives' }
      & Pick<Course_Objectives, 'id' | 'description'>
    )>, readonly requirements: ReadonlyArray<(
      { readonly __typename?: 'course_requirements' }
      & Pick<Course_Requirements, 'id' | 'description'>
    )>, readonly gains: ReadonlyArray<(
      { readonly __typename?: 'course_gains' }
      & Pick<Course_Gains, 'id' | 'description'>
    )>, readonly lecturers: ReadonlyArray<(
      { readonly __typename?: 'course_lecturers' }
      & { readonly speaker?: Maybe<(
        { readonly __typename?: 'speakers' }
        & Pick<Speakers, 'id' | 'name' | 'description'>
        & { userId: Speakers['user_id'], displayPhotoUuid: Speakers['display_photo_uuid'] }
      )> }
    )>, readonly courseRewards: ReadonlyArray<(
      { readonly __typename?: 'course_rewards' }
      & Pick<Course_Rewards, 'id'>
      & { rewardCertificateType: Course_Rewards['certificate_type'] }
      & { readonly rankingRewards: ReadonlyArray<(
        { readonly __typename?: 'ranking_rewards' }
        & Pick<Ranking_Rewards, 'id' | 'days'>
        & { type: Ranking_Rewards['ranking_type'] }
      )>, readonly otherRewards: ReadonlyArray<(
        { readonly __typename?: 'other_unit_rewards' }
        & Pick<Other_Unit_Rewards, 'id' | 'units'>
        & { type: Other_Unit_Rewards['other_unit_type'] }
      )>, readonly cpdUnitRewards: ReadonlyArray<(
        { readonly __typename?: 'cpd_unit_rewards' }
        & Pick<Cpd_Unit_Rewards, 'id' | 'units' | 'industry'>
        & { isSelfDirected: Cpd_Unit_Rewards['is_self_directed'] }
      )> }
    )> }
  )> }
);

export type GetCourseCertificateByCourseIdQueryVariables = Exact<{
  courseID: Scalars['bigint'];
}>;


export type GetCourseCertificateByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly certificates: ReadonlyArray<(
    { readonly __typename?: 'certificate_templates' }
    & Pick<Certificate_Templates, 'description' | 'id' | 'reward' | 'type' | 'label' | 'name'>
    & { accreditationNumber: Certificate_Templates['accreditation_number'], backgroundPhotoUuid: Certificate_Templates['background_photo_uuid'], colorTheme: Certificate_Templates['color_theme'], courseId: Certificate_Templates['course_id'], createdAt: Certificate_Templates['created_at'], dateHeld: Certificate_Templates['date_held'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
    & { readonly certificateSignatories: ReadonlyArray<(
      { readonly __typename?: 'certificate_template_signatories' }
      & Pick<Certificate_Template_Signatories, 'id'>
      & { isSuperimposed: Certificate_Template_Signatories['is_superimposed'] }
      & { readonly signatory: (
        { readonly __typename?: 'certificate_signatory' }
        & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'id' | 'isActive' | 'name' | 'position' | 'signatureImageUuid'>
      ) }
    )> }
  )> }
);

export type GetCourseCertificateByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCourseCertificateByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly certificate?: Maybe<(
    { readonly __typename?: 'certificate_templates' }
    & Pick<Certificate_Templates, 'description' | 'id' | 'reward' | 'type' | 'name' | 'label'>
    & { accreditationNumber: Certificate_Templates['accreditation_number'], backgroundPhotoUuid: Certificate_Templates['background_photo_uuid'], colorTheme: Certificate_Templates['color_theme'], courseId: Certificate_Templates['course_id'], createdAt: Certificate_Templates['created_at'], dateHeld: Certificate_Templates['date_held'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
    & { readonly certificateSignatories: ReadonlyArray<(
      { readonly __typename?: 'certificate_template_signatories' }
      & Pick<Certificate_Template_Signatories, 'id'>
      & { isSuperimposed: Certificate_Template_Signatories['is_superimposed'] }
      & { readonly signatory: (
        { readonly __typename?: 'certificate_signatory' }
        & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'id' | 'isActive' | 'name' | 'position' | 'signatureImageUuid'>
      ) }
    )> }
  )> }
);

export type GetCourseContentQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseContentQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly modules: ReadonlyArray<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'title' | 'description' | 'price' | 'order'>
    & { consumablePeriod: Course_Modules['consumable_period'], requireSequentialProgress: Course_Modules['require_sequential_progress'] }
    & { readonly moduleItems: ReadonlyArray<(
      { readonly __typename?: 'module_items' }
      & Pick<Module_Items, 'title' | 'order'>
      & { itemType: Module_Items['item_type'], courseModuleId: Module_Items['course_module_id'], isFree: Module_Items['is_free'], enableCommentSection: Module_Items['enable_comment_section'], isLocked: Module_Items['is_locked'] }
      & { readonly article: ReadonlyArray<(
        { readonly __typename?: 'course_articles' }
        & Pick<Course_Articles, 'id' | 'body'>
        & { moduleItemId: Course_Articles['module_item_id'] }
      )>, readonly assessment: ReadonlyArray<(
        { readonly __typename?: 'course_assessments' }
        & Pick<Course_Assessments, 'id'>
        & { formId: Course_Assessments['form_id'], moduleItemId: Course_Assessments['module_item_id'], isLocked: Course_Assessments['is_locked'] }
      )>, readonly form: ReadonlyArray<(
        { readonly __typename?: 'course_forms' }
        & Pick<Course_Forms, 'id'>
        & { formId: Course_Forms['form_id'], moduleItemId: Course_Forms['module_item_id'] }
        & { readonly details?: Maybe<(
          { readonly __typename?: 'forms' }
          & Pick<Forms, 'title' | 'description'>
        )> }
      )>, readonly liveSession: ReadonlyArray<(
        { readonly __typename?: 'course_live_sessions' }
        & Pick<Course_Live_Sessions, 'id' | 'description'>
        & { sessionDate: Course_Live_Sessions['session_date'], timeStart: Course_Live_Sessions['time_start'], timeEnd: Course_Live_Sessions['time_end'], streamingService: Course_Live_Sessions['streaming_service'], moduleItemId: Course_Live_Sessions['module_item_id'], attendanceEnabled: Course_Live_Sessions['attendance_enabled'], customLink: Course_Live_Sessions['custom_link'] }
      )>, readonly pdf: ReadonlyArray<(
        { readonly __typename?: 'course_attachments' }
        & Pick<Course_Attachments, 'id' | 'url'>
        & { moduleItemId: Course_Attachments['module_item_id'] }
      )>, readonly video: ReadonlyArray<(
        { readonly __typename?: 'course_videos' }
        & Pick<Course_Videos, 'id' | 'description' | 'source' | 'status'>
        & { videoUrl: Course_Videos['video_url'], moduleItemId: Course_Videos['module_item_id'] }
      )> }
    )> }
  )> }
);

export type GetCourseCourseOrderQueryVariables = Exact<{
  courseID: Scalars['bigint'];
}>;


export type GetCourseCourseOrderQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly orders: ReadonlyArray<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status' | 'course_invite_id'>
    & { refNo: Course_Order['ref_no'], totalPrice: Course_Order['total_price'], purchaseDate: Course_Order['purchase_date'], paymentChannel: Course_Order['payment_method'], userId: Course_Order['user_id'], acknowledgerId: Course_Order['acknowledger_id'], acknowledgerName: Course_Order['acknowledger_name'], acknowledgedAt: Course_Order['acknowledged_at'] }
    & { readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title'>
    ) }
  )> }
);

export type GetCourseDiscountQueryVariables = Exact<{
  code: Scalars['String'];
  courseId: Scalars['Int'];
}>;


export type GetCourseDiscountQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly discount: ReadonlyArray<(
    { readonly __typename?: 'course_discounts' }
    & Pick<Course_Discounts, 'id' | 'code' | 'type' | 'count' | 'value'>
    & { expirationDate: Course_Discounts['expiration_date'], courseId: Course_Discounts['course_id'], providerId: Course_Discounts['provider_id'] }
  )> }
);

export type GetCourseFormByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseFormByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly form: ReadonlyArray<(
      { readonly __typename?: 'course_forms' }
      & Pick<Course_Forms, 'id'>
      & { formId: Course_Forms['form_id'], moduleItemId: Course_Forms['module_item_id'] }
      & { readonly details?: Maybe<(
        { readonly __typename?: 'forms' }
        & Pick<Forms, 'title' | 'description'>
      )> }
    )> }
  )> }
);

export type GetCourseFormManagementByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseFormManagementByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title'>
    & { courseModuleId: Module_Items['course_module_id'] }
    & { readonly form: ReadonlyArray<(
      { readonly __typename?: 'course_forms' }
      & Pick<Course_Forms, 'id'>
      & { formId: Course_Forms['form_id'], moduleItemId: Course_Forms['module_item_id'] }
      & { readonly details?: Maybe<(
        { readonly __typename?: 'forms' }
        & Pick<Forms, 'title' | 'description'>
        & { readonly questions: ReadonlyArray<(
          { readonly __typename?: 'form_questions' }
          & Pick<Form_Questions, 'id' | 'name' | 'position' | 'type'>
          & { pointsPossible: Form_Questions['points_possible'], answerCount: Form_Questions['maximum_answer_count'], isOpenEnded: Form_Questions['is_open_ended'] }
        )> }
      )> }
    )> }
  )> }
);

export type GetCourseFormsByCourseIdQueryVariables = Exact<{
  courseID: Scalars['bigint'];
}>;


export type GetCourseFormsByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseForms: ReadonlyArray<(
    { readonly __typename?: 'course_forms' }
    & Pick<Course_Forms, 'id'>
    & { formId: Course_Forms['form_id'], moduleItemId: Course_Forms['module_item_id'] }
    & { readonly details?: Maybe<(
      { readonly __typename?: 'forms' }
      & Pick<Forms, 'title' | 'description'>
    )>, readonly moduleItem?: Maybe<(
      { readonly __typename?: 'module_items' }
      & Pick<Module_Items, 'title'>
      & { readonly courseModule?: Maybe<(
        { readonly __typename?: 'course_modules' }
        & Pick<Course_Modules, 'title'>
      )> }
    )> }
  )> }
);

export type GetCourseInstructorByCourseIdQueryVariables = Exact<{
  courseID: Scalars['bigint'];
}>;


export type GetCourseInstructorByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInstructor: ReadonlyArray<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id' | 'email' | 'description'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'], userId: Course_Instructors['user_id'], isActive: Course_Instructors['is_active'] }
  )> }
);

export type GetCourseInstructorCheckQueryVariables = Exact<{
  courseID: Scalars['bigint'];
  userID: Scalars['String'];
}>;


export type GetCourseInstructorCheckQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInstructor: ReadonlyArray<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id' | 'email' | 'description'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'], userId: Course_Instructors['user_id'], isActive: Course_Instructors['is_active'] }
  )> }
);

export type GetCourseInstructorCoursesQueryVariables = Exact<{
  userID: Scalars['String'];
}>;


export type GetCourseInstructorCoursesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInstructor: ReadonlyArray<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id' | 'email' | 'description'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'], userId: Course_Instructors['user_id'], isActive: Course_Instructors['is_active'] }
    & { readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'description'>
      & { readonly moduleCount: (
        { readonly __typename?: 'course_modules_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'course_modules_aggregate_fields' }
          & Pick<Course_Modules_Aggregate_Fields, 'count'>
        )> }
      ) }
    ) }
  )> }
);

export type GetCourseInviteByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCourseInviteByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInvite?: Maybe<(
    { readonly __typename?: 'course_invites' }
    & Pick<Course_Invites, 'id'>
    & { isUsed: Course_Invites['is_used'], usedAt: Course_Invites['used_at'], userDisplayName: Course_Invites['user_display_name'], userEmail: Course_Invites['user_email'], giveProceedingAccess: Course_Invites['give_proceeding_access'], freeModules: Course_Invites['free_modules'], isCourseFree: Course_Invites['is_course_free'], createdAt: Course_Invites['created_at'] }
  )> }
);

export type GetCourseInvitesQueryVariables = Exact<{
  courseId: Scalars['Int'];
}>;


export type GetCourseInvitesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInvites: ReadonlyArray<(
    { readonly __typename?: 'course_invites' }
    & Pick<Course_Invites, 'id'>
    & { isUsed: Course_Invites['is_used'], usedAt: Course_Invites['used_at'], userDisplayName: Course_Invites['user_display_name'], userEmail: Course_Invites['user_email'], giveProceedingAccess: Course_Invites['give_proceeding_access'], freeModules: Course_Invites['free_modules'], isCourseFree: Course_Invites['is_course_free'], createdAt: Course_Invites['created_at'] }
  )> }
);

export type GetCourseLiveSessionByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseLiveSessionByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly liveSession: ReadonlyArray<(
      { readonly __typename?: 'course_live_sessions' }
      & Pick<Course_Live_Sessions, 'id' | 'description'>
      & { sessionDate: Course_Live_Sessions['session_date'], timeStart: Course_Live_Sessions['time_start'], timeEnd: Course_Live_Sessions['time_end'], streamingService: Course_Live_Sessions['streaming_service'], moduleItemId: Course_Live_Sessions['module_item_id'], attendanceEnabled: Course_Live_Sessions['attendance_enabled'], customLink: Course_Live_Sessions['custom_link'] }
    )> }
  )> }
);

export type GetCourseModuleItemByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseModuleItemByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'], enableCommentSection: Module_Items['enable_comment_section'] }
  )> }
);

export type GetCourseModuleItemCommentsQueryVariables = Exact<{
  id: Scalars['bigint'];
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetCourseModuleItemCommentsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly comments: ReadonlyArray<(
    { readonly __typename?: 'module_item_comments' }
    & Pick<Module_Item_Comments, 'id' | 'message'>
    & { createdAt: Module_Item_Comments['created_at'], userId: Module_Item_Comments['user_id'], isProvider: Module_Item_Comments['is_provider'], isVisible: Module_Item_Comments['is_visible'] }
  )> }
);

export type GetCourseModulesQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseModulesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly modules: ReadonlyArray<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'title' | 'id' | 'description' | 'price' | 'order'>
    & { consumablePeriod: Course_Modules['consumable_period'], requireSequentialProgress: Course_Modules['require_sequential_progress'], createdAt: Course_Modules['created_at'], updatedAt: Course_Modules['updated_at'] }
    & { readonly moduleItems: ReadonlyArray<(
      { readonly __typename?: 'module_items' }
      & Pick<Module_Items, 'id' | 'title' | 'order'>
      & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    )> }
  )> }
);

export type GetCourseModulesByCourseIdQueryVariables = Exact<{
  courseID: Scalars['bigint'];
}>;


export type GetCourseModulesByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseModules: ReadonlyArray<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'id'>
  )> }
);

export type GetCourseOrdersQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetCourseOrdersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly orders: ReadonlyArray<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status' | 'course_invite_id'>
    & { refNo: Course_Order['ref_no'], totalPrice: Course_Order['total_price'], purchaseDate: Course_Order['purchase_date'], paymentChannel: Course_Order['payment_method'], userId: Course_Order['user_id'] }
    & { readonly course_discount?: Maybe<(
      { readonly __typename?: 'course_discounts' }
      & Pick<Course_Discounts, 'id' | 'value' | 'type'>
    )>, readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'price'>
      & { displayPhotoUuid: Courses['course_display_photo_uuid'] }
    ), readonly selectedModules: ReadonlyArray<(
      { readonly __typename?: 'course_order_modules' }
      & { readonly courseModule?: Maybe<(
        { readonly __typename?: 'course_modules' }
        & Pick<Course_Modules, 'id' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type GetCoursePdfByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCoursePdfByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly pdf: ReadonlyArray<(
      { readonly __typename?: 'course_attachments' }
      & Pick<Course_Attachments, 'id' | 'url'>
      & { moduleItemId: Course_Attachments['module_item_id'] }
    )> }
  )> }
);

export type GetCourseSpeakerByUserIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCourseSpeakerByUserIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly speaker: ReadonlyArray<(
    { readonly __typename?: 'speakers' }
    & Pick<Speakers, 'id' | 'description' | 'name'>
    & { displayPhotoUuid: Speakers['display_photo_uuid'], userId: Speakers['user_id'] }
  )> }
);

export type GetCourseStudentQueryVariables = Exact<{
  courseID?: Maybe<Scalars['bigint']>;
}>;


export type GetCourseStudentQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseStudent: ReadonlyArray<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
    & { userId: Course_Students['user_id'], orderId: Course_Students['order_id'], courseId: Course_Students['course_id'], approvalStatus: Course_Students['approval_status'], isActive: Course_Students['is_active'], registeredAt: Course_Students['registered_at'], startedAt: Course_Students['started_at'], expiredAt: Course_Students['expired_at'], hasProceedingAccess: Course_Students['has_proceeding_access'] }
    & { readonly courseModuleStudents: ReadonlyArray<(
      { readonly __typename?: 'course_module_students' }
      & Pick<Course_Module_Students, 'id'>
      & { courseModuleId: Course_Module_Students['course_module_id'], registeredAt: Course_Module_Students['registered_at'], startedAt: Course_Module_Students['started_at'], expiredAt: Course_Module_Students['expired_at'] }
    )> }
  )> }
);

export type GetCourseStudentByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseStudentByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly student?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
    & { approvalStatus: Course_Students['approval_status'], userId: Course_Students['user_id'], courseId: Course_Students['course_id'], expiredAt: Course_Students['expired_at'], hasProceedingAccess: Course_Students['has_proceeding_access'], isActive: Course_Students['is_active'], orderId: Course_Students['order_id'], registeredAt: Course_Students['registered_at'], startedAt: Course_Students['started_at'] }
    & { readonly courseModuleStudents: ReadonlyArray<(
      { readonly __typename?: 'course_module_students' }
      & Pick<Course_Module_Students, 'id'>
      & { courseModuleId: Course_Module_Students['course_module_id'], registeredAt: Course_Module_Students['registered_at'], startedAt: Course_Module_Students['started_at'], expiredAt: Course_Module_Students['expired_at'] }
    )> }
  )> }
);

export type GetCourseStudentDetailsByIDsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['bigint']> | Scalars['bigint'];
}>;


export type GetCourseStudentDetailsByIDsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly students: ReadonlyArray<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
    & { approvalStatus: Course_Students['approval_status'], userId: Course_Students['user_id'], courseId: Course_Students['course_id'], expiredAt: Course_Students['expired_at'], hasProceedingAccess: Course_Students['has_proceeding_access'], isActive: Course_Students['is_active'], orderId: Course_Students['order_id'], registeredAt: Course_Students['registered_at'], startedAt: Course_Students['started_at'] }
  )> }
);

export type GetCourseStudentProgressQueryVariables = Exact<{
  studentID?: Maybe<Scalars['bigint']>;
}>;


export type GetCourseStudentProgressQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly studentProgress: ReadonlyArray<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id'>
    & { isComplete: Student_Progress['is_complete'], isLocked: Student_Progress['is_locked'], moduleItemId: Student_Progress['module_item_id'], studentId: Student_Progress['course_student_id'] }
  )> }
);

export type GetCourseTransactionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCourseTransactionQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly order?: Maybe<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status' | 'course_invite_id' | 'acknowledger_id' | 'acknowledger_name' | 'acknowledged_at'>
    & { refNo: Course_Order['ref_no'], totalPrice: Course_Order['total_price'], purchaseDate: Course_Order['purchase_date'], paymentChannel: Course_Order['payment_method'], proofPaymentUuid: Course_Order['proof_payment_uuid'], userId: Course_Order['user_id'] }
    & { readonly course_discount?: Maybe<(
      { readonly __typename?: 'course_discounts' }
      & Pick<Course_Discounts, 'id' | 'value' | 'type'>
    )>, readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'price'>
      & { displayPhotoUuid: Courses['course_display_photo_uuid'] }
    ), readonly selectedModules: ReadonlyArray<(
      { readonly __typename?: 'course_order_modules' }
      & { readonly courseModule?: Maybe<(
        { readonly __typename?: 'course_modules' }
        & Pick<Course_Modules, 'id' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type GetCourseVideoByIdQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetCourseVideoByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title' | 'order'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly video: ReadonlyArray<(
      { readonly __typename?: 'course_videos' }
      & Pick<Course_Videos, 'id' | 'description' | 'source' | 'status'>
      & { videoUrl: Course_Videos['video_url'], moduleItemId: Course_Videos['module_item_id'] }
    )> }
  )> }
);

export type GetCourseVideoStatusQueryVariables = Exact<{
  videoID: Scalars['String'];
}>;


export type GetCourseVideoStatusQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly video: ReadonlyArray<(
    { readonly __typename?: 'course_videos' }
    & Pick<Course_Videos, 'status' | 'source'>
    & { videoURL: Course_Videos['video_url'] }
  )> }
);

export type GetDiscountUserUsageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetDiscountUserUsageQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly discount?: Maybe<(
    { readonly __typename?: 'course_discounts' }
    & { readonly courseOrder: ReadonlyArray<(
      { readonly __typename?: 'course_order' }
      & { userId: Course_Order['user_id'] }
    )> }
  )> }
);

export type GetEventByIdQueryVariables = Exact<{
  eventId: Scalars['uuid'];
}>;


export type GetEventByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly event?: Maybe<(
    { readonly __typename?: 'event' }
    & Pick<Event, 'id' | 'description' | 'eventDisplayPhotoUuid' | 'isArchived' | 'price' | 'timeEnd' | 'timeStart' | 'title'>
  )> }
);

export type GetEventsByMonthQueryVariables = Exact<{
  timeMin?: Maybe<Scalars['timestamp']>;
  timeMax?: Maybe<Scalars['timestamp']>;
}>;


export type GetEventsByMonthQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly event: ReadonlyArray<(
    { readonly __typename?: 'event' }
    & Pick<Event, 'id' | 'title' | 'timeStart' | 'eventDisplayPhotoUuid' | 'isArchived' | 'price' | 'timeEnd'>
    & { readonly provider?: Maybe<(
      { readonly __typename?: 'provider' }
      & Pick<Provider, 'displayPhotoUuid' | 'id' | 'isArchived' | 'name'>
    )>, readonly industries: ReadonlyArray<(
      { readonly __typename?: 'event_industries_industry' }
      & { name: Event_Industries_Industry['industryName'] }
    )> }
  )> }
);

export type GetEventsLiveNowQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsLiveNowQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly event: ReadonlyArray<(
    { readonly __typename?: 'event' }
    & Pick<Event, 'id' | 'title' | 'timeStart' | 'eventDisplayPhotoUuid' | 'isArchived' | 'price' | 'timeEnd'>
    & { readonly provider?: Maybe<(
      { readonly __typename?: 'provider' }
      & Pick<Provider, 'displayPhotoUuid' | 'id' | 'isArchived' | 'name'>
    )>, readonly industries: ReadonlyArray<(
      { readonly __typename?: 'event_industries_industry' }
      & { name: Event_Industries_Industry['industryName'] }
    )> }
  )> }
);

export type GetFormByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'forms' }
    & Pick<Forms, 'id' | 'title' | 'description' | 'type'>
    & { canGoBack: Forms['can_go_back'], shuffleQuestion: Forms['shuffle_question'], textSelection: Forms['text_selection'], timeLimit: Forms['time_limit'], allowedAttempts: Forms['allowed_attempts'], scoringPolicy: Forms['scoring_policy'], showScore: Forms['show_score'], showCorrectResponse: Forms['show_correct_response'], showRankPercentile: Forms['show_rank_percentile'], showAfterRegrade: Forms['show_after_regrade'], oneTimeResult: Forms['one_time_result'], isLocked: Forms['is_locked'], isPractice: Forms['is_practice'], accessCode: Forms['access_code'] }
  )> }
);

export type GetFormQuestionAnswerByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormQuestionAnswerByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formQuestionAnswer?: Maybe<(
    { readonly __typename?: 'form_question_answers' }
    & Pick<Form_Question_Answers, 'id' | 'name' | 'feedback' | 'points'>
  )> }
);

export type GetFormQuestionAnswersQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormQuestionAnswersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly question?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & { readonly answers: ReadonlyArray<(
      { readonly __typename?: 'form_question_answers' }
      & Pick<Form_Question_Answers, 'id' | 'name' | 'feedback' | 'points'>
    )> }
  )> }
);

export type GetFormQuestionAnswersForStudentsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormQuestionAnswersForStudentsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly question?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & { readonly answers: ReadonlyArray<(
      { readonly __typename?: 'form_question_answers' }
      & Pick<Form_Question_Answers, 'id' | 'name'>
    )> }
  )> }
);

export type GetFormQuestionByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormQuestionByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formQuestion?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & Pick<Form_Questions, 'id' | 'name' | 'type' | 'position' | 'caption'>
    & { isOpenEnded: Form_Questions['is_open_ended'], pointsPossible: Form_Questions['points_possible'], answerCount: Form_Questions['maximum_answer_count'], isCaseSensitive: Form_Questions['is_case_sensitive'], correctFeedback: Form_Questions['correct_feedback'], incorrectFeedback: Form_Questions['incorrect_feedback'], indeterminateFeedback: Form_Questions['indeterminate_feedback'] }
  )> }
);

export type GetFormQuestionSubmissionQueryVariables = Exact<{
  submission_id: Scalars['uuid'];
  question_id: Scalars['uuid'];
  user_id: Scalars['String'];
}>;


export type GetFormQuestionSubmissionQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formQuestionSubmissions: ReadonlyArray<(
    { readonly __typename?: 'form_question_submissions' }
    & Pick<Form_Question_Submissions, 'state' | 'id' | 'remark' | 'answers'>
    & { scoreBeforeRegrade: Form_Question_Submissions['score_before_regrade'], userId: Form_Question_Submissions['user_id'], finalScore: Form_Question_Submissions['final_score'] }
    & { readonly question: (
      { readonly __typename?: 'form_questions' }
      & Pick<Form_Questions, 'type'>
    ) }
  )> }
);

export type GetFormQuestionSubmissionsByCourseFormQueryVariables = Exact<{
  courseFormId: Scalars['uuid'];
  id: Scalars['uuid'];
}>;


export type GetFormQuestionSubmissionsByCourseFormQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formQuestionSubmissions: ReadonlyArray<(
    { readonly __typename?: 'form_question_submissions' }
    & Pick<Form_Question_Submissions, 'state' | 'id' | 'remark' | 'answers'>
    & { scoreBeforeRegrade: Form_Question_Submissions['score_before_regrade'], userId: Form_Question_Submissions['user_id'], finalScore: Form_Question_Submissions['final_score'] }
  )> }
);

export type GetFormQuestionSubmissionsByUserQueryVariables = Exact<{
  courseFormId: Scalars['uuid'];
  userId: Scalars['String'];
  submissionId: Scalars['uuid'];
}>;


export type GetFormQuestionSubmissionsByUserQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formQuestionSubmissions: ReadonlyArray<(
    { readonly __typename?: 'form_question_submissions' }
    & Pick<Form_Question_Submissions, 'state' | 'id' | 'remark' | 'answers'>
    & { scoreBeforeRegrade: Form_Question_Submissions['score_before_regrade'], userId: Form_Question_Submissions['user_id'], finalScore: Form_Question_Submissions['final_score'] }
    & { readonly question: (
      { readonly __typename?: 'form_questions' }
      & Pick<Form_Questions, 'type'>
    ) }
  )> }
);

export type GetFormQuestionsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormQuestionsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'forms' }
    & { shuffleQuestion: Forms['shuffle_question'] }
    & { readonly questions: ReadonlyArray<(
      { readonly __typename?: 'form_questions' }
      & Pick<Form_Questions, 'id' | 'name' | 'caption' | 'type' | 'position'>
      & { pointsPossible: Form_Questions['points_possible'], answerCount: Form_Questions['maximum_answer_count'], correctFeedback: Form_Questions['correct_feedback'], incorrectFeedback: Form_Questions['incorrect_feedback'], indeterminateFeedback: Form_Questions['indeterminate_feedback'], isOpenEnded: Form_Questions['is_open_ended'], isCaseSensitive: Form_Questions['is_case_sensitive'] }
    )> }
  )> }
);

export type GetFormSubmissionDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormSubmissionDetailsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formSubmission?: Maybe<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id' | 'attempt'>
    & { finishedAt: Form_Submissions['finished_at'], startedAt: Form_Submissions['started_at'] }
    & { readonly formQuestionSubmissionsAggregate: (
      { readonly __typename?: 'form_question_submissions_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'form_question_submissions_aggregate_fields' }
        & { readonly sum?: Maybe<(
          { readonly __typename?: 'form_question_submissions_sum_fields' }
          & { scoreBeforeRegrade: Form_Question_Submissions_Sum_Fields['score_before_regrade'], finalScore: Form_Question_Submissions_Sum_Fields['final_score'] }
        )> }
      )> }
    ), readonly form: (
      { readonly __typename?: 'forms' }
      & { readonly formQuestionAggregate: (
        { readonly __typename?: 'form_questions_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'form_questions_aggregate_fields' }
          & { readonly sum?: Maybe<(
            { readonly __typename?: 'form_questions_sum_fields' }
            & { pointsPossible: Form_Questions_Sum_Fields['points_possible'] }
          )> }
        )> }
      ) }
    ) }
  )> }
);

export type GetFormSubmissionQuestionsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFormSubmissionQuestionsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formSubmission?: Maybe<(
    { readonly __typename?: 'form_submissions' }
    & { readonly formQuestionSubmission: ReadonlyArray<(
      { readonly __typename?: 'form_question_submissions' }
      & Pick<Form_Question_Submissions, 'id' | 'answers' | 'state' | 'remark'>
      & { scoreBeforeRegrade: Form_Question_Submissions['score_before_regrade'], finalScore: Form_Question_Submissions['final_score'] }
      & { readonly formQuestion: (
        { readonly __typename?: 'form_questions' }
        & Pick<Form_Questions, 'id' | 'name' | 'type' | 'position'>
        & { pointsPossible: Form_Questions['points_possible'], answerCount: Form_Questions['maximum_answer_count'] }
      ) }
    )> }
  )> }
);

export type GetFormSubmissionsQueryVariables = Exact<{
  courseFormId: Scalars['uuid'];
  userId: Scalars['String'];
}>;


export type GetFormSubmissionsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formSubmission: ReadonlyArray<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id' | 'status' | 'attempt'>
    & { finishedAt: Form_Submissions['finished_at'], startedAt: Form_Submissions['started_at'], deadlineAt: Form_Submissions['deadline_at'] }
  )> }
);

export type GetFormSubmissionsByCourseFormQueryVariables = Exact<{
  courseFormId: Scalars['uuid'];
}>;


export type GetFormSubmissionsByCourseFormQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formSubmission: ReadonlyArray<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id' | 'attempt'>
    & { finishedAt: Form_Submissions['finished_at'], startedAt: Form_Submissions['started_at'], userId: Form_Submissions['user_id'] }
    & { readonly formQuestionSubmissionsAggregate: (
      { readonly __typename?: 'form_question_submissions_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'form_question_submissions_aggregate_fields' }
        & { readonly sum?: Maybe<(
          { readonly __typename?: 'form_question_submissions_sum_fields' }
          & { scoreBeforeRegrade: Form_Question_Submissions_Sum_Fields['score_before_regrade'], finalScore: Form_Question_Submissions_Sum_Fields['final_score'] }
        )> }
      )> }
    ), readonly form: (
      { readonly __typename?: 'forms' }
      & { readonly formQuestionAggregate: (
        { readonly __typename?: 'form_questions_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'form_questions_aggregate_fields' }
          & { readonly sum?: Maybe<(
            { readonly __typename?: 'form_questions_sum_fields' }
            & { pointsPossible: Form_Questions_Sum_Fields['points_possible'] }
          )> }
        )> }
      ) }
    ) }
  )> }
);

export type GetFormSubmissionsByUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetFormSubmissionsByUserQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly formSubmission: ReadonlyArray<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id' | 'attempt'>
    & { finishedAt: Form_Submissions['finished_at'], startedAt: Form_Submissions['started_at'], userId: Form_Submissions['user_id'] }
    & { readonly formQuestionSubmissionsAggregate: (
      { readonly __typename?: 'form_question_submissions_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'form_question_submissions_aggregate_fields' }
        & { readonly sum?: Maybe<(
          { readonly __typename?: 'form_question_submissions_sum_fields' }
          & { scoreBeforeRegrade: Form_Question_Submissions_Sum_Fields['score_before_regrade'], finalScore: Form_Question_Submissions_Sum_Fields['final_score'] }
        )> }
      )> }
    ), readonly form: (
      { readonly __typename?: 'forms' }
      & Pick<Forms, 'title' | 'id'>
      & { showAfterRegrade: Forms['show_after_regrade'], showScore: Forms['show_score'] }
      & { readonly formQuestionAggregate: (
        { readonly __typename?: 'form_questions_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'form_questions_aggregate_fields' }
          & { readonly sum?: Maybe<(
            { readonly __typename?: 'form_questions_sum_fields' }
            & { pointsPossible: Form_Questions_Sum_Fields['points_possible'] }
          )> }
        )> }
      ) }
    ) }
  )> }
);

export type GetIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustriesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly industry: ReadonlyArray<(
    { readonly __typename?: 'industry' }
    & Pick<Industry, 'name'>
    & { readonly category?: Maybe<(
      { readonly __typename?: 'industry_category' }
      & Pick<Industry_Category, 'name'>
    )>, readonly type?: Maybe<(
      { readonly __typename?: 'industry_type' }
      & Pick<Industry_Type, 'name'>
    )> }
  )> }
);

export type GetJoinSessionDetailsQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GetJoinSessionDetailsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'title'>
    & { itemType: Module_Items['item_type'], isFree: Module_Items['is_free'], isLocked: Module_Items['is_locked'], courseModuleId: Module_Items['course_module_id'], createdAt: Module_Items['created_at'], updatedAt: Module_Items['updated_at'] }
    & { readonly liveSession: ReadonlyArray<(
      { readonly __typename?: 'course_live_sessions' }
      & Pick<Course_Live_Sessions, 'id' | 'description'>
      & { sessionDate: Course_Live_Sessions['session_date'], timeStart: Course_Live_Sessions['time_start'], timeEnd: Course_Live_Sessions['time_end'], streamingService: Course_Live_Sessions['streaming_service'], moduleItemId: Course_Live_Sessions['module_item_id'], attendanceEnabled: Course_Live_Sessions['attendance_enabled'], customLink: Course_Live_Sessions['custom_link'] }
    )>, readonly courseModule?: Maybe<(
      { readonly __typename?: 'course_modules' }
      & Pick<Course_Modules, 'course_id'>
    )> }
  )> }
);

export type GetLiveSessionsByCourseIdQueryVariables = Exact<{
  courseId: Scalars['bigint'];
}>;


export type GetLiveSessionsByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'course_live_sessions' }
    & Pick<Course_Live_Sessions, 'id'>
    & { attendanceEnabled: Course_Live_Sessions['attendance_enabled'] }
    & { readonly moduleItem?: Maybe<(
      { readonly __typename?: 'module_items' }
      & Pick<Module_Items, 'title'>
    )> }
  )> }
);

export type GetModuleItemCompleteProgressQueryVariables = Exact<{
  moduleItemId: Scalars['bigint'];
}>;


export type GetModuleItemCompleteProgressQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly studentProgress: ReadonlyArray<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id'>
    & { isComplete: Student_Progress['is_complete'], moduleItemId: Student_Progress['module_item_id'], studentId: Student_Progress['course_student_id'] }
  )> }
);

export type GetOrdersByUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type GetOrdersByUserQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly order: ReadonlyArray<(
    { readonly __typename?: 'order' }
    & Pick<Order, 'id' | 'refno' | 'purchaseDate' | 'total' | 'status' | 'channel'>
    & { readonly productMetadata?: Maybe<(
      { readonly __typename?: 'product' }
      & Pick<Product, 'type' | 'productEntityId'>
    )>, readonly discount?: Maybe<(
      { readonly __typename?: 'discount' }
      & Pick<Discount, 'id' | 'code' | 'startDate' | 'expirationDate' | 'type' | 'value'>
    )> }
  )> }
);

export type GetProviderQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProviderQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider: ReadonlyArray<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'aboutVideoUuid' | 'email' | 'createdAt' | 'isArchived' | 'prcAccreditationNumber' | 'website' | 'lyonCommunity' | 'lyonApiKey' | 'lyonMerchantId'>
    & { readonly upcomingEventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly archivedEventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly eventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly subscribersAggregate: (
      { readonly __typename?: 'user_provider_subscriptions_provider_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'user_provider_subscriptions_provider_aggregate_fields' }
        & Pick<User_Provider_Subscriptions_Provider_Aggregate_Fields, 'count'>
      )> }
    ), readonly industries: ReadonlyArray<(
      { readonly __typename?: 'provider_frequently_used_industries_industry' }
      & Pick<Provider_Frequently_Used_Industries_Industry, 'industryName'>
    )> }
  )> }
);

export type GetProviderByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetProviderByNameQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider: ReadonlyArray<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name'>
  )> }
);

export type GetProviderCourseOrderQueryVariables = Exact<{
  providerID: Scalars['String'];
}>;


export type GetProviderCourseOrderQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly orders: ReadonlyArray<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status' | 'course_invite_id'>
    & { refNo: Course_Order['ref_no'], totalPrice: Course_Order['total_price'], purchaseDate: Course_Order['purchase_date'], paymentChannel: Course_Order['payment_method'], userId: Course_Order['user_id'], acknowledgerId: Course_Order['acknowledger_id'], acknowledgerName: Course_Order['acknowledger_name'], acknowledgedAt: Course_Order['acknowledged_at'] }
    & { readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title'>
    ) }
  )> }
);

export type GetProviderCoursesQueryVariables = Exact<{
  id: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetProviderCoursesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], publishStatus: Courses['publish_status'], isArchived: Courses['is_archived'], providerId: Courses['provider_id'], createdAt: Courses['created_at'], updatedAt: Courses['updated_at'], isPinned: Courses['is_pinned'] }
    & { readonly moduleCount: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ), readonly videoHour: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ), readonly courseRewards: ReadonlyArray<(
      { readonly __typename?: 'course_rewards' }
      & { readonly cpdUnitRewards: ReadonlyArray<(
        { readonly __typename?: 'cpd_unit_rewards' }
        & Pick<Cpd_Unit_Rewards, 'industry'>
      )> }
    )> }
  )> }
);

export type GetProviderDiscountsQueryVariables = Exact<{
  providerId: Scalars['uuid'];
}>;


export type GetProviderDiscountsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly discount: ReadonlyArray<(
    { readonly __typename?: 'course_discounts' }
    & Pick<Course_Discounts, 'id' | 'code' | 'type' | 'count' | 'value'>
    & { expirationDate: Course_Discounts['expiration_date'], courseId: Course_Discounts['course_id'], providerId: Course_Discounts['provider_id'] }
    & { readonly course?: Maybe<(
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'title'>
    )>, readonly courseOrdersAggregate: (
      { readonly __typename?: 'course_order_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_order_aggregate_fields' }
        & Pick<Course_Order_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type GetProviderForDuplicateQueryVariables = Exact<{
  id: Scalars['uuid'];
  idString: Scalars['String'];
}>;


export type GetProviderForDuplicateQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'aboutVideoUuid' | 'email' | 'createdAt' | 'isArchived' | 'prcAccreditationNumber' | 'website' | 'lyonCommunity' | 'certificateLogoUuid' | 'isLightTheme'>
    & { readonly signatories: ReadonlyArray<(
      { readonly __typename?: 'certificate_signatory' }
      & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'isActive' | 'name' | 'position' | 'signatureImageUuid' | 'uploaderId'>
    )>, readonly managingUsers: ReadonlyArray<(
      { readonly __typename?: 'provider_managing_users_user' }
      & Pick<Provider_Managing_Users_User, 'userId'>
    )> }
  )>, readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'community_description' | 'consumable_period' | 'course_display_photo_uuid' | 'course_preview_video_url' | 'course_sell_type' | 'description' | 'display_students' | 'is_archived' | 'is_pinned' | 'is_private' | 'lyon_community' | 'payment_link' | 'prc_accreditation_number' | 'price' | 'publish_status' | 'require_sequential_progress' | 'title' | 'id'>
    & { readonly gains: ReadonlyArray<(
      { readonly __typename?: 'course_gains' }
      & Pick<Course_Gains, 'id' | 'description'>
    )>, readonly requirements: ReadonlyArray<(
      { readonly __typename?: 'course_requirements' }
      & Pick<Course_Requirements, 'id' | 'description'>
    )>, readonly objectives: ReadonlyArray<(
      { readonly __typename?: 'course_objectives' }
      & Pick<Course_Objectives, 'id' | 'description'>
    )>, readonly lecturers: ReadonlyArray<(
      { readonly __typename?: 'course_lecturers' }
      & { readonly speaker?: Maybe<(
        { readonly __typename?: 'speakers' }
        & Pick<Speakers, 'id' | 'user_id' | 'name' | 'description' | 'display_photo_uuid'>
      )> }
    )> }
  )> }
);

export type GetProviderFormsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProviderFormsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly forms: ReadonlyArray<(
    { readonly __typename?: 'forms' }
    & Pick<Forms, 'id' | 'title' | 'description'>
  )> }
);

export type GetProviderInstructorCheckQueryVariables = Exact<{
  providerID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type GetProviderInstructorCheckQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseInstructor: ReadonlyArray<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id' | 'email' | 'description'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'], userId: Course_Instructors['user_id'], isActive: Course_Instructors['is_active'] }
  )> }
);

export type GetProviderManagingUsersByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProviderManagingUsersByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly managingUsers: ReadonlyArray<(
    { readonly __typename?: 'provider_managing_users_user' }
    & { readonly user: (
      { readonly __typename?: 'user' }
      & Pick<User, 'email' | 'displayName' | 'displayPhotoUuid' | 'id' | 'isAdmin'>
    ) }
  )> }
);

export type GetProviderSignatoriesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProviderSignatoriesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly signatories: ReadonlyArray<(
    { readonly __typename?: 'certificate_signatory' }
    & Pick<Certificate_Signatory, 'name' | 'position' | 'id' | 'honorific' | 'companyName' | 'signatureImageUuid' | 'isActive' | 'uploaderId'>
  )> }
);

export type GetProviderStudentCountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProviderStudentCountQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseStudentAggregate: (
    { readonly __typename?: 'course_students_aggregate' }
    & { readonly aggregate?: Maybe<(
      { readonly __typename?: 'course_students_aggregate_fields' }
      & Pick<Course_Students_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetPublishedCoursesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPublishedCoursesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], publishStatus: Courses['publish_status'], isArchived: Courses['is_archived'], isPinned: Courses['is_pinned'], providerId: Courses['provider_id'], createdAt: Courses['created_at'], updatedAt: Courses['updated_at'] }
    & { readonly moduleCount: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ), readonly courseRewards: ReadonlyArray<(
      { readonly __typename?: 'course_rewards' }
      & { readonly cpdUnitRewards: ReadonlyArray<(
        { readonly __typename?: 'cpd_unit_rewards' }
        & Pick<Cpd_Unit_Rewards, 'industry'>
      )> }
    )> }
  )> }
);

export type GetRevenueByCourseIdQueryVariables = Exact<{
  courseId: Scalars['bigint'];
}>;


export type GetRevenueByCourseIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly order: ReadonlyArray<(
    { readonly __typename?: 'course_order' }
    & { totalPrice: Course_Order['total_price'] }
  )> }
);

export type GetRevenueByProductIdQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type GetRevenueByProductIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly order: ReadonlyArray<(
    { readonly __typename?: 'order' }
    & Pick<Order, 'total'>
  )> }
);

export type GetStudentCoursesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetStudentCoursesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courseStudent: ReadonlyArray<(
    { readonly __typename?: 'course_students' }
    & { startedAt: Course_Students['started_at'], approvalStatus: Course_Students['approval_status'] }
    & { readonly course?: Maybe<(
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
      & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], coursePreviewVideoUrl: Courses['course_preview_video_url'], prcAccreditationNumber: Courses['prc_accreditation_number'], publishStatus: Courses['publish_status'], isArchived: Courses['is_archived'], providerId: Courses['provider_id'], createdAt: Courses['created_at'], updatedAt: Courses['updated_at'], sellType: Courses['course_sell_type'], displayStudents: Courses['display_students'], consumablePeriod: Courses['consumable_period'], requireSequentialProgress: Courses['require_sequential_progress'], isPinned: Courses['is_pinned'] }
      & { readonly moduleCount: (
        { readonly __typename?: 'course_modules_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'course_modules_aggregate_fields' }
          & Pick<Course_Modules_Aggregate_Fields, 'count'>
        )> }
      ), readonly videoHour: (
        { readonly __typename?: 'course_modules_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'course_modules_aggregate_fields' }
          & Pick<Course_Modules_Aggregate_Fields, 'count'>
        )> }
      ) }
    )>, readonly studentProgressAggregate: (
      { readonly __typename?: 'student_progress_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'student_progress_aggregate_fields' }
        & Pick<Student_Progress_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type GetStudentProgessQueryVariables = Exact<{
  moduleItemId: Scalars['bigint'];
  courseStudentId: Scalars['bigint'];
}>;


export type GetStudentProgessQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly studentProgress: ReadonlyArray<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id'>
    & { isComplete: Student_Progress['is_complete'], moduleItemId: Student_Progress['module_item_id'], studentId: Student_Progress['course_student_id'] }
  )> }
);

export type GetUserAdminByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserAdminByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'isAdmin'>
  )> }
);

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserByEmailQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user: ReadonlyArray<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'displayName' | 'city' | 'phoneNumber' | 'licenseNumber' | 'licensePhotoUuid' | 'birthDate' | 'displayPhotoUuid' | 'sex'>
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'displayName' | 'city' | 'phoneNumber' | 'licenseNumber' | 'licensePhotoUuid' | 'birthDate' | 'displayPhotoUuid' | 'sex'>
  )> }
);

export type GetUserCertificateByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserCertificateByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly userCertificate?: Maybe<(
    { readonly __typename?: 'user_certificates' }
    & Pick<User_Certificates, 'id'>
    & { dateDistributed: User_Certificates['date_distributed'], shortId: User_Certificates['short_id'], userName: User_Certificates['user_name'] }
    & { readonly certificateTemplate: (
      { readonly __typename?: 'certificate_templates' }
      & Pick<Certificate_Templates, 'description' | 'id' | 'reward' | 'type' | 'label'>
      & { accreditationNumber: Certificate_Templates['accreditation_number'], backgroundPhotoUuid: Certificate_Templates['background_photo_uuid'], colorTheme: Certificate_Templates['color_theme'], courseId: Certificate_Templates['course_id'], createdAt: Certificate_Templates['created_at'], dateHeld: Certificate_Templates['date_held'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
      & { readonly certificateSignatories: ReadonlyArray<(
        { readonly __typename?: 'certificate_template_signatories' }
        & Pick<Certificate_Template_Signatories, 'id'>
        & { isSuperimposed: Certificate_Template_Signatories['is_superimposed'] }
        & { readonly signatory: (
          { readonly __typename?: 'certificate_signatory' }
          & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'id' | 'isActive' | 'name' | 'position' | 'signatureImageUuid'>
        ) }
      )> }
    ) }
  )> }
);

export type GetUserCertificateByUserIdQueryVariables = Exact<{
  userID: Scalars['String'];
  courseID: Scalars['bigint'];
}>;


export type GetUserCertificateByUserIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly userCertificates: ReadonlyArray<(
    { readonly __typename?: 'user_certificates' }
    & Pick<User_Certificates, 'id'>
    & { dateDistributed: User_Certificates['date_distributed'], shortId: User_Certificates['short_id'], userName: User_Certificates['user_name'], userId: User_Certificates['user_id'], courseId: User_Certificates['course_id'] }
    & { readonly certificateTemplate: (
      { readonly __typename?: 'certificate_templates' }
      & Pick<Certificate_Templates, 'description' | 'id' | 'reward' | 'type' | 'label'>
      & { accreditationNumber: Certificate_Templates['accreditation_number'], backgroundPhotoUuid: Certificate_Templates['background_photo_uuid'], colorTheme: Certificate_Templates['color_theme'], courseId: Certificate_Templates['course_id'], createdAt: Certificate_Templates['created_at'], dateHeld: Certificate_Templates['date_held'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
      & { readonly certificateSignatories: ReadonlyArray<(
        { readonly __typename?: 'certificate_template_signatories' }
        & Pick<Certificate_Template_Signatories, 'id'>
        & { isSuperimposed: Certificate_Template_Signatories['is_superimposed'] }
        & { readonly signatory: (
          { readonly __typename?: 'certificate_signatory' }
          & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'id' | 'isActive' | 'name' | 'position' | 'signatureImageUuid'>
        ) }
      )> }
    ) }
  )> }
);

export type GetUserCertificatesQueryVariables = Exact<{
  userID: Scalars['String'];
}>;


export type GetUserCertificatesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly userCertificates: ReadonlyArray<(
    { readonly __typename?: 'user_certificates' }
    & Pick<User_Certificates, 'id'>
    & { dateDistributed: User_Certificates['date_distributed'], shortId: User_Certificates['short_id'], userName: User_Certificates['user_name'], userId: User_Certificates['user_id'], courseId: User_Certificates['course_id'] }
    & { readonly certificateTemplate: (
      { readonly __typename?: 'certificate_templates' }
      & Pick<Certificate_Templates, 'description' | 'id' | 'label' | 'reward' | 'type'>
      & { accreditationNumber: Certificate_Templates['accreditation_number'], backgroundPhotoUuid: Certificate_Templates['background_photo_uuid'], colorTheme: Certificate_Templates['color_theme'], courseId: Certificate_Templates['course_id'], createdAt: Certificate_Templates['created_at'], dateHeld: Certificate_Templates['date_held'], providerId: Certificate_Templates['provider_id'], hasLogo: Certificate_Templates['has_logo'], hasProviderName: Certificate_Templates['has_provider_name'], hasAwardsThis: Certificate_Templates['has_awards_this'], hasToDivider: Certificate_Templates['has_to_divider'] }
      & { readonly certificateSignatories: ReadonlyArray<(
        { readonly __typename?: 'certificate_template_signatories' }
        & Pick<Certificate_Template_Signatories, 'id'>
        & { isSuperimposed: Certificate_Template_Signatories['is_superimposed'] }
        & { readonly signatory: (
          { readonly __typename?: 'certificate_signatory' }
          & Pick<Certificate_Signatory, 'companyName' | 'honorific' | 'id' | 'isActive' | 'name' | 'position' | 'signatureImageUuid'>
        ) }
      )> }
    ) }
  )> }
);

export type GetUserFollowedIndustriesByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserFollowedIndustriesByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & { readonly followedIndustries: ReadonlyArray<(
      { readonly __typename?: 'user_followed_industries_industry' }
      & { readonly industry: (
        { readonly __typename?: 'industry' }
        & Pick<Industry, 'name'>
        & { category: Industry['categoryName'] }
      ) }
    )> }
  )> }
);

export type GetUserInfosQueryVariables = Exact<{
  userIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;


export type GetUserInfosQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'displayName' | 'email'>
  )> }
);

export type GetUserNameQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserNameQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'displayName'>
  )> }
);

export type GetUserNamesQueryVariables = Exact<{
  userIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;


export type GetUserNamesQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly result: ReadonlyArray<(
    { readonly __typename?: 'user' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type GetUserProviderAccountByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserProviderAccountByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider: ReadonlyArray<(
    { readonly __typename?: 'provider_managing_users_user' }
    & { readonly provider: (
      { readonly __typename?: 'provider' }
      & Pick<Provider, 'id' | 'name' | 'displayPhotoUuid' | 'email' | 'createdAt' | 'description' | 'address' | 'contactNumber'>
      & { readonly managingUserAggregate: (
        { readonly __typename?: 'provider_managing_users_user_aggregate' }
        & { readonly aggregate?: Maybe<(
          { readonly __typename?: 'provider_managing_users_user_aggregate_fields' }
          & Pick<Provider_Managing_Users_User_Aggregate_Fields, 'count'>
        )> }
      ) }
    ) }
  )> }
);

export type GetUserProviderSubscriptionsByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserProviderSubscriptionsByIdQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & { readonly providerSubscriptions: ReadonlyArray<(
      { readonly __typename?: 'user_provider_subscriptions_provider' }
      & { readonly provider: (
        { readonly __typename?: 'provider' }
        & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'email' | 'createdAt' | 'isArchived' | 'prcAccreditationNumber'>
        & { readonly upcomingEventsAggregate: (
          { readonly __typename?: 'event_aggregate' }
          & { readonly aggregate?: Maybe<(
            { readonly __typename?: 'event_aggregate_fields' }
            & Pick<Event_Aggregate_Fields, 'count'>
          )> }
        ), readonly archivedEventsAggregate: (
          { readonly __typename?: 'event_aggregate' }
          & { readonly aggregate?: Maybe<(
            { readonly __typename?: 'event_aggregate_fields' }
            & Pick<Event_Aggregate_Fields, 'count'>
          )> }
        ), readonly eventsAggregate: (
          { readonly __typename?: 'event_aggregate' }
          & { readonly aggregate?: Maybe<(
            { readonly __typename?: 'event_aggregate_fields' }
            & Pick<Event_Aggregate_Fields, 'count'>
          )> }
        ), readonly subscribersAggregate: (
          { readonly __typename?: 'user_provider_subscriptions_provider_aggregate' }
          & { readonly aggregate?: Maybe<(
            { readonly __typename?: 'user_provider_subscriptions_provider_aggregate_fields' }
            & Pick<User_Provider_Subscriptions_Provider_Aggregate_Fields, 'count'>
          )> }
        ), readonly industries: ReadonlyArray<(
          { readonly __typename?: 'provider_frequently_used_industries_industry' }
          & Pick<Provider_Frequently_Used_Industries_Industry, 'industryName'>
        )> }
      ) }
    )> }
  )> }
);

export type InsertCourseModuleStudentsMutationVariables = Exact<{
  objects: ReadonlyArray<Course_Module_Students_Insert_Input> | Course_Module_Students_Insert_Input;
}>;


export type InsertCourseModuleStudentsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_course_module_students?: Maybe<(
    { readonly __typename?: 'course_module_students_mutation_response' }
    & Pick<Course_Module_Students_Mutation_Response, 'affected_rows'>
  )> }
);

export type InsertCourseStudentMutationVariables = Exact<{
  courseID: Scalars['bigint'];
  userID: Scalars['String'];
  hasProceedingAccess: Scalars['Boolean'];
}>;


export type InsertCourseStudentMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly newCourseStudent?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
  )> }
);

export type SearchCourseQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type SearchCourseQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly courses: ReadonlyArray<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description' | 'price'>
    & { courseDisplayPhotoUuid: Courses['course_display_photo_uuid'], coursePreviewVideoUrl: Courses['course_preview_video_url'], isArchived: Courses['is_archived'], isPinned: Courses['is_pinned'], prcAccreditationNumber: Courses['prc_accreditation_number'], publishStatus: Courses['publish_status'] }
    & { readonly industries: ReadonlyArray<(
      { readonly __typename?: 'course_rewards' }
      & { readonly name: ReadonlyArray<(
        { readonly __typename?: 'cpd_unit_rewards' }
        & Pick<Cpd_Unit_Rewards, 'industry'>
      )> }
    )>, readonly modulesAggregate: (
      { readonly __typename?: 'course_modules_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'course_modules_aggregate_fields' }
        & Pick<Course_Modules_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type SearchEventQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type SearchEventQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly event: ReadonlyArray<(
    { readonly __typename?: 'event' }
    & Pick<Event, 'id' | 'title' | 'timeStart' | 'eventDisplayPhotoUuid' | 'isArchived' | 'price' | 'timeEnd'>
    & { readonly provider?: Maybe<(
      { readonly __typename?: 'provider' }
      & Pick<Provider, 'displayPhotoUuid' | 'id' | 'isArchived' | 'name'>
    )>, readonly industries: ReadonlyArray<(
      { readonly __typename?: 'event_industries_industry' }
      & { name: Event_Industries_Industry['industryName'] }
    )> }
  )> }
);

export type SearchProviderQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type SearchProviderQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly provider: ReadonlyArray<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id' | 'name' | 'address' | 'contactNumber' | 'coverPhotoUuid' | 'description' | 'displayPhotoUuid' | 'email' | 'createdAt' | 'isArchived' | 'prcAccreditationNumber'>
    & { readonly upcomingEventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly archivedEventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly eventsAggregate: (
      { readonly __typename?: 'event_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'event_aggregate_fields' }
        & Pick<Event_Aggregate_Fields, 'count'>
      )> }
    ), readonly subscribersAggregate: (
      { readonly __typename?: 'user_provider_subscriptions_provider_aggregate' }
      & { readonly aggregate?: Maybe<(
        { readonly __typename?: 'user_provider_subscriptions_provider_aggregate_fields' }
        & Pick<User_Provider_Subscriptions_Provider_Aggregate_Fields, 'count'>
      )> }
    ), readonly industries: ReadonlyArray<(
      { readonly __typename?: 'provider_frequently_used_industries_industry' }
      & Pick<Provider_Frequently_Used_Industries_Industry, 'industryName'>
    )> }
  )> }
);

export type UpdateCertificateSignatoriesPositionMutationVariables = Exact<{
  id: Scalars['uuid'];
  isSuperimposed: Scalars['Boolean'];
}>;


export type UpdateCertificateSignatoriesPositionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly certificateSignatory?: Maybe<(
    { readonly __typename?: 'certificate_template_signatories' }
    & Pick<Certificate_Template_Signatories, 'is_superimposed' | 'id'>
  )> }
);

export type UpdateCommentVisibilityMutationVariables = Exact<{
  id: Scalars['bigint'];
  isVisible: Scalars['Boolean'];
}>;


export type UpdateCommentVisibilityMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_item_comments' }
    & Pick<Module_Item_Comments, 'id' | 'is_visible'>
  )> }
);

export type UpdateCourseMutationVariables = Exact<{
  courseId: Scalars['bigint'];
  providerId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  courseDisplayPhotoUuid?: Maybe<Scalars['String']>;
  courseSellType?: Maybe<Course_Sell_Types_Enum>;
  consumablePeriod?: Maybe<Scalars['numeric']>;
  requireSequentialProgress?: Maybe<Scalars['Boolean']>;
  paymentLink?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'title' | 'description'>
    & { providerId: Courses['provider_id'] }
  )> }
);

export type UpdateCourseArchiveStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  status: Scalars['Boolean'];
}>;


export type UpdateCourseArchiveStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'is_archived'>
  )> }
);

export type UpdateCourseArticleByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  body?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseArticleByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_articles_mutation_response' }
    & Pick<Course_Articles_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCourseAssessmentByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  formId?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateCourseAssessmentByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_assessments_mutation_response' }
    & Pick<Course_Assessments_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCourseCertificateMutationVariables = Exact<{
  id: Scalars['uuid'];
  label?: Maybe<Scalars['String']>;
  accreditationNumber?: Maybe<Scalars['String']>;
  backgroundPhotoUuid?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<Scalars['String']>;
  dateHeld?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  hasLogo?: Maybe<Scalars['Boolean']>;
  hasProviderName?: Maybe<Scalars['Boolean']>;
  hasAwardsThis?: Maybe<Scalars['Boolean']>;
  hasToDivider?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateCourseCertificateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly certificate?: Maybe<(
    { readonly __typename?: 'certificate_templates' }
    & Pick<Certificate_Templates, 'id'>
  )> }
);

export type UpdateCourseCommunityMutationVariables = Exact<{
  id: Scalars['bigint'];
  description?: Maybe<Scalars['String']>;
  community?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseCommunityMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'lyon_community' | 'community_description'>
  )> }
);

export type UpdateCourseFormByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  formId?: Maybe<Scalars['uuid']>;
}>;


export type UpdateCourseFormByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_forms_mutation_response' }
    & Pick<Course_Forms_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCourseInstructorIsActiveMutationVariables = Exact<{
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateCourseInstructorIsActiveMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInstructor?: Maybe<(
    { readonly __typename?: 'course_instructors' }
    & Pick<Course_Instructors, 'id'>
    & { createdAt: Course_Instructors['created_at'], updatedAt: Course_Instructors['updated_at'] }
  )> }
);

export type UpdateCourseInviteUsedMutationVariables = Exact<{
  id: Scalars['uuid'];
  usedAt: Scalars['timestamptz'];
}>;


export type UpdateCourseInviteUsedMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseInvites?: Maybe<(
    { readonly __typename?: 'course_invites' }
    & Pick<Course_Invites, 'id'>
    & { isUsed: Course_Invites['is_used'], usedAt: Course_Invites['used_at'] }
  )> }
);

export type UpdateCourseLiveSessionByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  description?: Maybe<Scalars['String']>;
  sessionDate?: Maybe<Scalars['timestamptz']>;
  timeStart?: Maybe<Scalars['timestamptz']>;
  timeEnd?: Maybe<Scalars['timestamptz']>;
  streamingService?: Maybe<Scalars['Int']>;
  attendanceEnabled?: Maybe<Scalars['Boolean']>;
  customLink?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseLiveSessionByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_live_sessions_mutation_response' }
    & Pick<Course_Live_Sessions_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCourseModuleByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  requireSequentialProgress?: Maybe<Scalars['Boolean']>;
  consumablePeriod?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
}>;


export type UpdateCourseModuleByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'id' | 'title' | 'description' | 'price'>
    & { courseId: Course_Modules['course_id'], requireSequentialProgress: Course_Modules['require_sequential_progress'] }
  )> }
);

export type UpdateCourseModuleItemCommentSectionMutationVariables = Exact<{
  id: Scalars['bigint'];
  enableCommentSection: Scalars['Boolean'];
}>;


export type UpdateCourseModuleItemCommentSectionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'enable_comment_section'>
  )> }
);

export type UpdateCourseModuleItemFreeStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isFree: Scalars['Boolean'];
}>;


export type UpdateCourseModuleItemFreeStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'title' | 'is_free'>
  )> }
);

export type UpdateCourseModuleItemLockStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isLocked: Scalars['Boolean'];
}>;


export type UpdateCourseModuleItemLockStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'title' | 'is_locked'>
  )> }
);

export type UpdateCourseModuleItemOrderByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  order: Scalars['Int'];
}>;


export type UpdateCourseModuleItemOrderByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'id' | 'order'>
  )> }
);

export type UpdateCourseModuleItemTitleMutationVariables = Exact<{
  id: Scalars['bigint'];
  title: Scalars['String'];
}>;


export type UpdateCourseModuleItemTitleMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'module_items' }
    & Pick<Module_Items, 'title'>
  )> }
);

export type UpdateCourseModuleOrderByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  order: Scalars['Int'];
}>;


export type UpdateCourseModuleOrderByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly module?: Maybe<(
    { readonly __typename?: 'course_modules' }
    & Pick<Course_Modules, 'id' | 'order'>
  )> }
);

export type UpdateCourseModuleStudentStartMutationVariables = Exact<{
  id: Scalars['uuid'];
  startedAt: Scalars['timestamp'];
}>;


export type UpdateCourseModuleStudentStartMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseModuleStudent?: Maybe<(
    { readonly __typename?: 'course_module_students' }
    & Pick<Course_Module_Students, 'id'>
  )> }
);

export type UpdateCourseOrderAcknowledgementMutationVariables = Exact<{
  id: Scalars['uuid'];
  acknowledger_id: Scalars['String'];
  acknowledger_name: Scalars['String'];
}>;


export type UpdateCourseOrderAcknowledgementMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseOrder?: Maybe<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id'>
    & { acknowledgerId: Course_Order['acknowledger_id'], acknowledgerName: Course_Order['acknowledger_name'], acknowledgedAt: Course_Order['acknowledged_at'] }
  )> }
);

export type UpdateCourseOrderStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  acknowledger_id?: Maybe<Scalars['String']>;
  acknowledger_name?: Maybe<Scalars['String']>;
  status: Course_Order_Statuses_Enum;
}>;


export type UpdateCourseOrderStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly order?: Maybe<(
    { readonly __typename?: 'course_order' }
    & Pick<Course_Order, 'id' | 'status'>
    & { acknowledgerId: Course_Order['acknowledger_id'], acknowledgerName: Course_Order['acknowledger_name'], acknowledgedAt: Course_Order['acknowledged_at'], userId: Course_Order['user_id'], totalPrice: Course_Order['total_price'], hasProceedingAccess: Course_Order['has_proceeding_access'] }
    & { readonly course: (
      { readonly __typename?: 'courses' }
      & Pick<Courses, 'id' | 'title' | 'price'>
      & { displayPhotoUuid: Courses['course_display_photo_uuid'], providerId: Courses['provider_id'], createdAt: Courses['created_at'], sellType: Courses['course_sell_type'] }
    ) }
  )> }
);

export type UpdateCoursePdfByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  url?: Maybe<Scalars['String']>;
}>;


export type UpdateCoursePdfByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_attachments_mutation_response' }
    & Pick<Course_Attachments_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCoursePinnedStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isPinned: Scalars['Boolean'];
}>;


export type UpdateCoursePinnedStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id'>
    & { isPinned: Courses['is_pinned'] }
  )> }
);

export type UpdateCoursePrivateStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isPrivate: Scalars['Boolean'];
}>;


export type UpdateCoursePrivateStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'id' | 'is_private'>
  )> }
);

export type UpdateCoursePublishStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  status: Scalars['Int'];
}>;


export type UpdateCoursePublishStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'publish_status'>
  )> }
);

export type UpdateCourseShowStudentsMutationVariables = Exact<{
  id: Scalars['bigint'];
  displayStudents: Scalars['Boolean'];
}>;


export type UpdateCourseShowStudentsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'display_students'>
  )> }
);

export type UpdateCourseStudentProgressLockStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isLocked: Scalars['Boolean'];
}>;


export type UpdateCourseStudentProgressLockStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id' | 'is_locked'>
  )> }
);

export type UpdateCourseStudentStartMutationVariables = Exact<{
  id: Scalars['bigint'];
  startedAt: Scalars['timestamp'];
}>;


export type UpdateCourseStudentStartMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseStudent?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id'>
  )> }
);

export type UpdateCourseStudentStateMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type UpdateCourseStudentStateMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_course_students_by_pk?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id' | 'is_active'>
  )> }
);

export type UpdateCourseVideoByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
  videoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseVideoByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly moduleItem?: Maybe<(
    { readonly __typename?: 'course_videos_mutation_response' }
    & Pick<Course_Videos_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateCourseVideoPreviewMutationVariables = Exact<{
  id: Scalars['bigint'];
  video?: Maybe<Scalars['String']>;
}>;


export type UpdateCourseVideoPreviewMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly course?: Maybe<(
    { readonly __typename?: 'courses' }
    & Pick<Courses, 'course_preview_video_url'>
  )> }
);

export type UpdateFormByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Form_Types_Enum;
  scoringPolicy?: Maybe<Form_Scoring_Policies_Enum>;
  allowedAttempts?: Maybe<Scalars['numeric']>;
  timeLimit?: Maybe<Scalars['numeric']>;
  accessCode?: Maybe<Scalars['String']>;
  canGoBack?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  isPractice?: Maybe<Scalars['Boolean']>;
  oneTimeResult?: Maybe<Scalars['Boolean']>;
  showCorrectResponse?: Maybe<Scalars['Boolean']>;
  showRankPercentile?: Maybe<Scalars['Boolean']>;
  showScore?: Maybe<Scalars['Boolean']>;
  showAfterRegrade?: Maybe<Scalars['Boolean']>;
  shuffleQuestion?: Maybe<Scalars['Boolean']>;
  textSelection?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateFormByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly form?: Maybe<(
    { readonly __typename?: 'forms' }
    & Pick<Forms, 'id' | 'access_code' | 'allowed_attempts' | 'can_go_back' | 'description' | 'is_locked' | 'is_practice' | 'one_time_result' | 'scoring_policy' | 'show_correct_response' | 'show_rank_percentile' | 'show_score' | 'show_after_regrade' | 'shuffle_question' | 'text_selection' | 'time_limit' | 'title' | 'type'>
  )> }
);

export type UpdateFormQuestionByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  type: Form_Question_Types_Enum;
  isOpenEnded: Scalars['Boolean'];
  pointsPossible?: Maybe<Scalars['numeric']>;
  answerCount?: Maybe<Scalars['numeric']>;
  isCaseSensitive: Scalars['Boolean'];
  correctFeedback?: Maybe<Scalars['String']>;
  incorrectFeedback?: Maybe<Scalars['String']>;
  indeterminateFeedback?: Maybe<Scalars['String']>;
  position: Scalars['numeric'];
}>;


export type UpdateFormQuestionByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestion?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & Pick<Form_Questions, 'id' | 'name' | 'type'>
  )> }
);

export type UpdateFormQuestionPositionByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  position: Scalars['numeric'];
}>;


export type UpdateFormQuestionPositionByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestion?: Maybe<(
    { readonly __typename?: 'form_questions' }
    & Pick<Form_Questions, 'id' | 'position'>
  )> }
);

export type UpdateFormQuestionSubmissionMutationVariables = Exact<{
  objects: ReadonlyArray<Form_Question_Submissions_Insert_Input> | Form_Question_Submissions_Insert_Input;
}>;


export type UpdateFormQuestionSubmissionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formQuestionSubmission?: Maybe<(
    { readonly __typename?: 'form_question_submissions_mutation_response' }
    & Pick<Form_Question_Submissions_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateFormSubmissionFinishMutationVariables = Exact<{
  id: Scalars['uuid'];
  finishedAt: Scalars['timestamp'];
  status: Form_Submission_Statuses_Enum;
}>;


export type UpdateFormSubmissionFinishMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly formSubmission?: Maybe<(
    { readonly __typename?: 'form_submissions' }
    & Pick<Form_Submissions, 'id'>
    & { finishedAt: Form_Submissions['finished_at'], userId: Form_Submissions['user_id'], status: Form_Submissions['status'] }
  )> }
);

export type UpdateProviderMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayPhotoUuid?: Maybe<Scalars['String']>;
  coverPhotoUuid?: Maybe<Scalars['String']>;
  aboutVideoUuid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  lyonCommunity?: Maybe<Scalars['String']>;
}>;


export type UpdateProviderMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_provider_by_pk?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id'>
  )> }
);

export type UpdateProviderMerchantDetailsMutationVariables = Exact<{
  id: Scalars['uuid'];
  lyonMerchantId?: Maybe<Scalars['String']>;
  lyonApiKey?: Maybe<Scalars['String']>;
}>;


export type UpdateProviderMerchantDetailsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_provider_by_pk?: Maybe<(
    { readonly __typename?: 'provider' }
    & Pick<Provider, 'id'>
  )> }
);

export type UpdateStudentApprovalStatusByIdMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type UpdateStudentApprovalStatusByIdMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly courseStudent?: Maybe<(
    { readonly __typename?: 'course_students' }
    & Pick<Course_Students, 'id' | 'approval_status'>
  )> }
);

export type UpdateStudentApprovalStatusByIDsMutationVariables = Exact<{
  objects: ReadonlyArray<Course_Students_Insert_Input> | Course_Students_Insert_Input;
}>;


export type UpdateStudentApprovalStatusByIDsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly students?: Maybe<(
    { readonly __typename?: 'course_students_mutation_response' }
    & Pick<Course_Students_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateStudentProgressStatusMutationVariables = Exact<{
  id: Scalars['bigint'];
  isComplete: Scalars['Boolean'];
}>;


export type UpdateStudentProgressStatusMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly studentProgress?: Maybe<(
    { readonly __typename?: 'student_progress' }
    & Pick<Student_Progress, 'id'>
    & { isComplete: Student_Progress['is_complete'], moduleItemId: Student_Progress['module_item_id'], studentId: Student_Progress['course_student_id'] }
  )> }
);

export type UpdateUserAboutDetailsMutationVariables = Exact<{
  id: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['timestamp']>;
  city?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAboutDetailsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'licenseNumber' | 'birthDate' | 'city'>
  )> }
);

export type UpdateUserAboutIndustriesMutationVariables = Exact<{
  id: Scalars['String'];
  payload: ReadonlyArray<User_Followed_Industries_Industry_Insert_Input> | User_Followed_Industries_Industry_Insert_Input;
}>;


export type UpdateUserAboutIndustriesMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly delete?: Maybe<(
    { readonly __typename?: 'user_followed_industries_industry_mutation_response' }
    & { readonly returning: ReadonlyArray<(
      { readonly __typename?: 'user_followed_industries_industry' }
      & Pick<User_Followed_Industries_Industry, 'industryName' | 'userId'>
    )> }
  )>, readonly insert?: Maybe<(
    { readonly __typename?: 'user_followed_industries_industry_mutation_response' }
    & { readonly returning: ReadonlyArray<(
      { readonly __typename?: 'user_followed_industries_industry' }
      & Pick<User_Followed_Industries_Industry, 'industryName' | 'userId'>
    )> }
  )> }
);

export type UpdateUserDetailsMutationVariables = Exact<{
  id: Scalars['String'];
  displayName: Scalars['String'];
  displayPhotoUuid?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
}>;


export type UpdateUserDetailsMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly user?: Maybe<(
    { readonly __typename?: 'user' }
    & Pick<User, 'displayName' | 'displayPhotoUuid' | 'city'>
  )> }
);


export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($name: String!, $size: Int!, $mimeType: String!, $rootType: String!, $rootEntityId: String!, $userId: String, $bucket: String!) {
  attachment: insert_attachment_one(
    object: {name: $name, size: $size, mimeType: $mimeType, rootType: $rootType, rootEntityId: $rootEntityId, userId: $userId, bucket: $bucket}
  ) {
    id
  }
}
    `;
export const CreateAttendanceDocument = gql`
    mutation CreateAttendance($liveSessionId: bigint) {
  insert_attendances_one(object: {live_session_id: $liveSessionId}) {
    id
    created_at
  }
}
    `;
export const CreateCertificateSignatoriesDocument = gql`
    mutation CreateCertificateSignatories($signatoryId: uuid!, $certificateTemplateId: uuid!, $isSuperimposed: Boolean!) {
  certificateSignatory: insert_certificate_template_signatories_one(
    object: {signatory_id: $signatoryId, certificate_template_id: $certificateTemplateId, is_superimposed: $isSuperimposed}
  ) {
    id
  }
}
    `;
export const CreateCourseDocument = gql`
    mutation CreateCourse($providerId: String!, $title: String!, $description: String!, $price: numeric, $courseDisplayPhotoUuid: String, $coursePreviewVideoUrl: String, $courseSellType: course_sell_types_enum, $consumablePeriod: numeric, $requireSequentialProgress: Boolean, $displayStudents: Boolean, $paymentLink: String) {
  course: insert_courses_one(
    object: {provider_id: $providerId, title: $title, description: $description, price: $price, course_display_photo_uuid: $courseDisplayPhotoUuid, course_preview_video_url: $coursePreviewVideoUrl, course_sell_type: $courseSellType, consumable_period: $consumablePeriod, require_sequential_progress: $requireSequentialProgress, display_students: $displayStudents, payment_link: $paymentLink}
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    providerId: provider_id
    createdAt: created_at
  }
}
    `;
export const CreateCourseArticleDocument = gql`
    mutation CreateCourseArticle($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 0, is_free: $isFree, order: $order, course_articles: {data: {body: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseArticleDuplicateDocument = gql`
    mutation CreateCourseArticleDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $body: String, $isLocked: Boolean, $enableCommentSection: Boolean) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 0, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_articles: {data: {body: $body}}}
  ) {
    id
  }
}
    `;
export const CreateCourseAssessmentDocument = gql`
    mutation CreateCourseAssessment($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 2, is_free: $isFree, order: $order, course_assessments: {data: {form_id: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseAssessmentDuplicateDocument = gql`
    mutation CreateCourseAssessmentDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $formId: String, $isLocked: Boolean, $enableCommentSection: Boolean) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 2, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_assessments: {data: {form_id: $formId}}}
  ) {
    id
  }
}
    `;
export const CreateCourseAttachmentDocument = gql`
    mutation CreateCourseAttachment($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 3, is_free: $isFree, order: $order, course_attachments: {data: {url: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseAttachmentDuplicateDocument = gql`
    mutation CreateCourseAttachmentDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $isLocked: Boolean, $enableCommentSection: Boolean, $url: String) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 3, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_attachments: {data: {url: $url}}}
  ) {
    id
  }
}
    `;
export const CreateCourseCpdUnitRewardDocument = gql`
    mutation CreateCourseCPDUnitReward($courseId: bigint!, $certificateType: Int!, $isSelfDirected: Boolean!, $units: Int!, $industry: String!) {
  courseReward: insert_course_rewards_one(
    object: {course_id: $courseId, reward_type: 0, certificate_type: $certificateType, cpd_unit_rewards: {data: {is_self_directed: $isSelfDirected, units: $units, industry: $industry}}}
  ) {
    id
  }
}
    `;
export const CreateCourseCertificateDocument = gql`
    mutation CreateCourseCertificate($courseId: bigint!, $providerId: uuid!, $accreditationNumber: String, $dateHeld: String, $description: String, $reward: String, $type: String!, $name: String, $hasLogo: Boolean, $hasProviderName: Boolean, $hasAwardsThis: Boolean, $hasToDivider: Boolean, $label: String) {
  certificate: insert_certificate_templates_one(
    object: {accreditation_number: $accreditationNumber, course_id: $courseId, date_held: $dateHeld, description: $description, provider_id: $providerId, reward: $reward, type: $type, name: $name, has_logo: $hasLogo, has_provider_name: $hasProviderName, has_awards_this: $hasAwardsThis, has_to_divider: $hasToDivider, label: $label}
  ) {
    id
  }
}
    `;
export const CreateCourseDiscountDocument = gql`
    mutation CreateCourseDiscount($code: String!, $expirationDate: date, $courseId: Int!, $providerId: uuid, $count: Int!, $value: numeric!, $type: course_discount_types_enum!, $infiniteUsage: Boolean!) {
  courseDiscount: insert_course_discounts_one(
    object: {code: $code, expiration_date: $expirationDate, course_id: $courseId, provider_id: $providerId, count: $count, value: $value, type: $type, infinite_usage: $infiniteUsage}
  ) {
    id
  }
}
    `;
export const CreateCourseFormDocument = gql`
    mutation CreateCourseForm($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 5, is_free: $isFree, order: $order, course_forms: {data: {form_id: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseFormDuplicateDocument = gql`
    mutation CreateCourseFormDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $formId: uuid, $isLocked: Boolean, $enableCommentSection: Boolean) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 5, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_forms: {data: {form_id: $formId}}}
  ) {
    id
  }
}
    `;
export const CreateCourseGainDocument = gql`
    mutation CreateCourseGain($courseId: bigint!, $description: String!) {
  gain: insert_course_gains_one(
    object: {course_id: $courseId, description: $description}
  ) {
    description
    id
  }
}
    `;
export const CreateCourseInstructorDocument = gql`
    mutation CreateCourseInstructor($userId: String!, $courseId: bigint!, $email: String!, $description: String) {
  courseInstructor: insert_course_instructors_one(
    object: {user_id: $userId, course_id: $courseId, email: $email, description: $description, is_active: true}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
  }
}
    `;
export const CreateCourseInviteDocument = gql`
    mutation CreateCourseInvite($courseId: Int!, $freeModules: _text, $giveProceedingAccess: Boolean!, $isCourseFree: Boolean!, $userDisplayName: String, $userEmail: String!) {
  courseInvite: insert_course_invites_one(
    object: {course_id: $courseId, free_modules: $freeModules, give_proceeding_access: $giveProceedingAccess, user_display_name: $userDisplayName, user_email: $userEmail, is_course_free: $isCourseFree}
  ) {
    created_at
    id
  }
}
    `;
export const CreateCourseInvitesDocument = gql`
    mutation CreateCourseInvites($objects: [course_invites_insert_input!]!) {
  courseInvite: insert_course_invites(objects: $objects) {
    affected_rows
    returning {
      created_at
      id
      user_email
    }
  }
}
    `;
export const CreateCourseLecturerDocument = gql`
    mutation CreateCourseLecturer($courseId: bigint!, $speakerId: bigint!) {
  courseLecturer: insert_course_lecturers_one(
    object: {course_id: $courseId, speaker_id: $speakerId}
  ) {
    courseId: course_id
    speakerId: speaker_id
  }
}
    `;
export const CreateCourseLiveSessionDocument = gql`
    mutation CreateCourseLiveSession($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 4, is_free: $isFree, order: $order, course_live_sessions: {data: {description: null, session_date: null, time_start: null, time_end: null, streaming_service: null, custom_link: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseLiveSessionDuplicateDocument = gql`
    mutation CreateCourseLiveSessionDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $isLocked: Boolean, $enableCommentSection: Boolean, $description: String, $sessionDate: timestamptz, $timeStart: timestamptz, $timeEnd: timestamptz, $streamingService: Int, $attendanceEnabled: Boolean, $customLink: String) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 4, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_live_sessions: {data: {description: $description, session_date: $sessionDate, time_start: $timeStart, time_end: $timeEnd, streaming_service: $streamingService, attendance_enabled: $attendanceEnabled, custom_link: $customLink}}}
  ) {
    id
  }
}
    `;
export const CreateCourseModuleDocument = gql`
    mutation CreateCourseModule($title: String!, $description: String, $id: bigint!, $requireSequentialProgress: Boolean, $price: numeric, $consumablePeriod: numeric, $order: Int!) {
  courseModule: insert_course_modules_one(
    object: {title: $title, description: $description, course_id: $id, price: $price, require_sequential_progress: $requireSequentialProgress, consumable_period: $consumablePeriod, order: $order}
  ) {
    id
    title
    description
    price
    order
    courseId: course_id
    requireSequentialProgress: require_sequential_progress
  }
}
    `;
export const CreateCourseModuleItemCommentDocument = gql`
    mutation CreateCourseModuleItemComment($message: String!, $userId: String!, $moduleItemId: bigint!, $isProvider: Boolean) {
  insert_module_item_comments_one(
    object: {message: $message, user_id: $userId, module_item_id: $moduleItemId, is_provider: $isProvider}
  ) {
    id
  }
}
    `;
export const CreateCourseObjectiveDocument = gql`
    mutation CreateCourseObjective($courseId: bigint!, $description: String!) {
  objective: insert_course_objectives_one(
    object: {course_id: $courseId, description: $description}
  ) {
    description
    id
  }
}
    `;
export const CreateCourseOtherRewardDocument = gql`
    mutation CreateCourseOtherReward($courseId: bigint!, $certificateType: Int!, $otherUnitType: String!, $units: Int!) {
  courseReward: insert_course_rewards_one(
    object: {course_id: $courseId, reward_type: 2, certificate_type: $certificateType, other_unit_rewards: {data: {other_unit_type: $otherUnitType, units: $units}}}
  ) {
    id
  }
}
    `;
export const CreateCoursePaymentRequestDocument = gql`
    mutation CreateCoursePaymentRequest($id: Int!, $paymentChannel: String, $selectedModules: [Int]!, $discountCode: String, $courseInviteId: String, $proofPaymentUuid: String) {
  result: createCoursePaymentRequest(
    id: $id
    paymentChannel: $paymentChannel
    selectedModules: $selectedModules
    discountCode: $discountCode
    courseInviteId: $courseInviteId
    proofPaymentUuid: $proofPaymentUuid
  ) {
    url
  }
}
    `;
export const CreateCourseRankingRewardDocument = gql`
    mutation CreateCourseRankingReward($courseId: bigint!, $certificateType: Int!, $days: Int!, $rankingType: Int!) {
  courseReward: insert_course_rewards_one(
    object: {course_id: $courseId, reward_type: 1, certificate_type: $certificateType, ranking_rewards: {data: {days: $days, ranking_type: $rankingType}}}
  ) {
    id
  }
}
    `;
export const CreateCourseReminderDocument = gql`
    mutation CreateCourseReminder($courseId: bigint!, $entityId: bigint!, $entityName: String!, $entityType: String!, $studentEmail: String!) {
  reminder: insert_course_reminders_one(
    object: {course_id: $courseId, entity_id: $entityId, entity_name: $entityName, entity_type: $entityType, student_email: $studentEmail}
  ) {
    id
    courseId: course_id
    entityId: entity_id
    entityName: entity_name
    entityType: entity_type
    studentEmail: student_email
  }
}
    `;
export const CreateCourseRequirementDocument = gql`
    mutation CreateCourseRequirement($courseId: bigint!, $description: String!) {
  requirement: insert_course_requirements_one(
    object: {course_id: $courseId, description: $description}
  ) {
    description
    id
  }
}
    `;
export const CreateCourseSignatoriesDocument = gql`
    mutation CreateCourseSignatories($objects: [course_signatories_insert_input!]!) {
  insert_course_signatories(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export const CreateCourseVideoDocument = gql`
    mutation CreateCourseVideo($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 1, is_free: $isFree, order: $order, course_videos: {data: {video_url: null, description: null}}}
  ) {
    id
  }
}
    `;
export const CreateCourseVideoDuplicateDocument = gql`
    mutation CreateCourseVideoDuplicate($moduleId: bigint!, $title: String!, $isFree: Boolean, $order: Int, $isLocked: Boolean, $enableCommentSection: Boolean, $videoUrl: String, $description: String, $status: course_videos_statuses_enum, $source: String) {
  moduleItem: insert_module_items_one(
    object: {course_module_id: $moduleId, title: $title, item_type: 1, is_free: $isFree, order: $order, enable_comment_section: $enableCommentSection, is_locked: $isLocked, course_videos: {data: {video_url: $videoUrl, description: $description, status: $status, source: $source}}}
  ) {
    id
  }
}
    `;
export const CreateFormDocument = gql`
    mutation CreateForm($providerId: uuid!, $title: String!, $description: String, $type: form_types_enum) {
  form: insert_forms_one(
    object: {provider_id: $providerId, title: $title, description: $description, type: $type}
  ) {
    id
    title
    description
    type
  }
}
    `;
export const CreateFormQuestionDocument = gql`
    mutation CreateFormQuestion($formId: uuid!, $name: String!, $type: form_question_types_enum!, $position: numeric!, $isOpenEnded: Boolean!, $caption: String) {
  formQuestion: insert_form_questions_one(
    object: {form_id: $formId, name: $name, type: $type, position: $position, caption: $caption, is_open_ended: $isOpenEnded}
  ) {
    id
    name
    type
  }
}
    `;
export const CreateFormQuestionAnswerDocument = gql`
    mutation CreateFormQuestionAnswer($formQuestionId: uuid!, $name: String!, $feedback: String, $points: numeric!) {
  formQuestionAnswer: insert_form_question_answers_one(
    object: {form_question_id: $formQuestionId, name: $name, feedback: $feedback, points: $points}
  ) {
    id
    name
    feedback
    points
  }
}
    `;
export const CreateFormQuestionSubmissionDocument = gql`
    mutation CreateFormQuestionSubmission($objects: [form_question_submissions_insert_input!]!) {
  formQuestionSubmission: insert_form_question_submissions(
    objects: $objects
    on_conflict: {constraint: form_question_submissions_form_submission_id_form_question_id_u, update_columns: [score_before_regrade, final_score, state, remark, answers, last_answered_at]}
  ) {
    affected_rows
  }
}
    `;
export const CreateFormSubmissionDocument = gql`
    mutation CreateFormSubmission($formId: uuid!, $courseFormId: uuid, $userId: String!, $startedAt: timestamp!, $attempt: numeric!) {
  formSubmission: insert_form_submissions_one(
    object: {form_id: $formId, course_form_id: $courseFormId, user_id: $userId, started_at: $startedAt, attempt: $attempt}
  ) {
    id
    started_at
  }
}
    `;
export const CreateManagingUserDocument = gql`
    mutation CreateManagingUser($providerId: uuid!, $userId: String!) {
  managingUser: insert_provider_managing_users_user_one(
    object: {providerId: $providerId, userId: $userId}
  ) {
    providerId
    userId
  }
}
    `;
export const CreateProviderDocument = gql`
    mutation CreateProvider($name: String!, $description: String!) {
  provider: insert_provider_one(object: {name: $name, description: $description}) {
    id
  }
}
    `;
export const CreateProviderDuplicateDocument = gql`
    mutation CreateProviderDuplicate($data: provider_insert_input!) {
  provider: insert_provider_one(object: $data) {
    id
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    aboutVideoUuid
    email
    createdAt
    isArchived
    prcAccreditationNumber
    website
    lyonCommunity
    certificateLogoUuid
    isLightTheme
    signatories: certificate_signatories {
      id
      companyName
      honorific
      isActive
      name
      position
      signatureImageUuid
      uploaderId
    }
  }
}
    `;
export const CreateSignatoryDocument = gql`
    mutation CreateSignatory($providerId: uuid!, $companyName: String!, $honorific: String, $name: String!, $position: String!, $signatureImageUuid: String!, $uploaderId: String) {
  signatory: insert_certificate_signatory_one(
    object: {companyName: $companyName, honorific: $honorific, name: $name, isActive: true, position: $position, providerId: $providerId, signatureImageUuid: $signatureImageUuid, uploaderId: $uploaderId}
  ) {
    id
  }
}
    `;
export const CreateSpeakerDocument = gql`
    mutation CreateSpeaker($description: String!, $displayPhotoUuid: String, $name: String!, $userId: String) {
  speaker: insert_speakers_one(
    object: {description: $description, display_photo_uuid: $displayPhotoUuid, name: $name, user_id: $userId}
  ) {
    id
  }
}
    `;
export const CreateStudentProgessDocument = gql`
    mutation CreateStudentProgess($moduleItemId: bigint!, $courseStudentId: bigint!) {
  studentProgress: insert_student_progress_one(
    object: {course_student_id: $courseStudentId, module_item_id: $moduleItemId}
  ) {
    id
    isComplete: is_complete
    moduleItemId: module_item_id
    studentId: course_student_id
  }
}
    `;
export const CreateUploadUrlToBunnyDocument = gql`
    mutation CreateUploadURLToBunny($bunnyId: String, $location: String, $state: bunny_upload_states_enum!, $userId: String, $providerId: String, $courseId: bigint) {
  bunnyUpload: insert_bunny_upload_one(
    object: {bunny_id: $bunnyId, location: $location, state: $state, user_id: $userId, provider_id: $providerId, course_id: $courseId}
  ) {
    id
    bunnyId: bunny_id
    userId: user_id
    providerId: provider_id
    courseId: course_id
    location
    state
    status
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($id: String!, $email: String!, $displayName: String!, $displayPhotoUuid: String) {
  user: insert_user_one(
    object: {id: $id, email: $email, displayName: $displayName, displayPhotoUuid: $displayPhotoUuid}
  ) {
    id
    email
    displayName
    displayPhotoUuid
  }
}
    `;
export const CreateUserAttendanceLogDocument = gql`
    mutation CreateUserAttendanceLog($attendanceId: uuid!) {
  insert_attendance_logs_one(object: {attendance_id: $attendanceId}) {
    id
    created_at
  }
}
    `;
export const CreateUserCertificatesDocument = gql`
    mutation CreateUserCertificates($objects: [user_certificates_insert_input!]!) {
  students: insert_user_certificates(
    objects: $objects
    on_conflict: {constraint: user_certificates_pkey, update_columns: [user_name]}
  ) {
    affected_rows
  }
}
    `;
export const DeleteCertificateSignatoryByIdDocument = gql`
    mutation DeleteCertificateSignatoryById($id: uuid!) {
  signatory: delete_certificate_template_signatories_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseByIdDocument = gql`
    mutation DeleteCourseById($id: bigint!) {
  course: delete_courses_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseCertificateByIdDocument = gql`
    mutation DeleteCourseCertificateById($id: uuid!) {
  certificate: delete_certificate_templates_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseDiscountByIdDocument = gql`
    mutation DeleteCourseDiscountById($id: uuid!) {
  courseDiscount: delete_course_discounts_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseGainByIdDocument = gql`
    mutation DeleteCourseGainById($id: uuid!) {
  courseGain: delete_course_gains_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseInviteByIdDocument = gql`
    mutation DeleteCourseInviteById($id: uuid!) {
  courseInvite: delete_course_invites_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseLecturerByIdDocument = gql`
    mutation DeleteCourseLecturerById($speakerId: bigint!, $courseId: bigint!) {
  courseLecturer: delete_course_lecturers(
    where: {speaker_id: {_eq: $speakerId}, course_id: {_eq: $courseId}}
  ) {
    affected_rows
  }
}
    `;
export const DeleteCourseModuleByIdDocument = gql`
    mutation DeleteCourseModuleById($id: bigint!) {
  courseModule: delete_course_modules_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseModuleItemByIdDocument = gql`
    mutation DeleteCourseModuleItemById($id: bigint!) {
  moduleItem: delete_module_items_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseObjectiveByIdDocument = gql`
    mutation DeleteCourseObjectiveById($id: bigint!) {
  courseObjective: delete_course_objectives_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseRequirementByIdDocument = gql`
    mutation DeleteCourseRequirementById($id: bigint!) {
  courseRequirement: delete_course_requirements_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteCourseRewardByIdDocument = gql`
    mutation DeleteCourseRewardById($id: bigint!) {
  courseReward: delete_course_rewards_by_pk(id: $id) {
    courseId: course_id
  }
}
    `;
export const DeleteFormByIdDocument = gql`
    mutation DeleteFormById($id: uuid!) {
  form: delete_forms_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteFormQuestionAnswerByIdDocument = gql`
    mutation DeleteFormQuestionAnswerById($id: uuid!) {
  formQuestionAnswer: delete_form_question_answers_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteFormQuestionByIdDocument = gql`
    mutation DeleteFormQuestionById($id: uuid!) {
  formQuestion: delete_form_questions_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteManagingUserByIdDocument = gql`
    mutation DeleteManagingUserById($providerId: uuid!, $userId: String!) {
  managingUser: delete_provider_managing_users_user_by_pk(
    providerId: $providerId
    userId: $userId
  ) {
    providerId
    userId
  }
}
    `;
export const DeleteProviderByIdDocument = gql`
    mutation DeleteProviderById($id: uuid!) {
  provider: delete_provider_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteSignatoryByIdDocument = gql`
    mutation DeleteSignatoryById($id: uuid!) {
  signatory: delete_certificate_signatory_by_pk(id: $id) {
    id
  }
}
    `;
export const DeleteUserCertificateByIdDocument = gql`
    mutation DeleteUserCertificateById($id: uuid!) {
  userCertificate: delete_user_certificates_by_pk(id: $id) {
    id
  }
}
    `;
export const GetCertificatePageGsspDocument = gql`
    query GetCertificatePageGSSP($id: uuid!) {
  userCertificate: user_certificates_by_pk(id: $id) {
    id
    dateDistributed: date_distributed
    shortId: short_id
    userName: user_name
    certificateTemplate: certificate_template {
      accreditationNumber: accreditation_number
      courseId: course_id
      description
      id
      providerId: provider_id
      type
      label
      hasLogo: has_logo
      hasProviderName: has_provider_name
      hasAwardsThis: has_awards_this
      hasToDivider: has_to_divider
    }
  }
}
    `;
export const GetCoursePageGsspDocument = gql`
    query GetCoursePageGSSP($id: bigint!) {
  course: courses_by_pk(id: $id) {
    id
    title
    description
    price
    isArchived: is_archived
    courseDisplayPhotoUuid: course_display_photo_uuid
    coursePreviewVideoUrl: course_preview_video_url
    prcAccreditationNumber: prc_accreditation_number
    providerId: provider_id
    sellType: course_sell_type
    publishStatus: publish_status
    paymentLink: payment_link
    lyonCommunity: lyon_community
    communityDescription: community_description
    lecturers: course_lecturers {
      speaker {
        id
        userId: user_id
        name
        description
      }
    }
  }
}
    `;
export const GetCourseStudentPageGsspDocument = gql`
    query GetCourseStudentPageGSSP($id: bigint!) {
  student: course_students_by_pk(id: $id) {
    id
    isActive: is_active
  }
}
    `;
export const GetCourseTransactionPageGsspDocument = gql`
    query GetCourseTransactionPageGSSP($id: uuid!) {
  order: course_order_by_pk(id: $id) {
    id
    status
    refNo: ref_no
    totalPrice: total_price
    purchaseDate: purchase_date
    paymentChannel: payment_method
    userId: user_id
    course_discount {
      id
      value
      type
    }
    course_invite_id
    course {
      id
      title
      price
      displayPhotoUuid: course_display_photo_uuid
      provider_id
    }
    selectedModules: course_order_modules(order_by: {course_module_id: asc}) {
      courseModule: course_module {
        id
        price
        title
      }
    }
  }
}
    `;
export const GetDiscoverPageGsspDocument = gql`
    query GetDiscoverPageGSSP {
  courses(
    where: {publish_status: {_eq: 1}, is_archived: {_eq: false}}
    offset: 0
    limit: 10
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
  }
}
    `;
export const GetProfilePageGsspDocument = gql`
    query GetProfilePageGSSP($id: String!) {
  user: user_by_pk(id: $id) {
    id
    email
    displayName
    city
    phoneNumber
    birthDate
    displayPhotoUuid
    sex
  }
}
    `;
export const GetProviderPageGsspDocument = gql`
    query GetProviderPageGSSP($id: uuid!) {
  provider: provider_by_pk(id: $id) {
    id
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    email
    prcAccreditationNumber
    website
    lyonCommunity
  }
}
    `;
export const GetSearchPageGsspDocument = gql`
    query GetSearchPageGSSP($query: String) {
  courses(
    where: {_and: [{is_archived: {_eq: false}}, {publish_status: {_eq: 1}}], _or: [{title: {_ilike: $query}}, {description: {_ilike: $query}}, {course_lecturers: {speaker: {name: {_ilike: $query}}}}]}
    offset: 0
    limit: 10
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    prcAccreditationNumber: prc_accreditation_number
  }
  providers: provider(
    where: {_and: {isArchived: {_eq: false}, _or: [{name: {_ilike: $query}}, {description: {_ilike: $query}}]}}
    offset: 0
    limit: 10
  ) {
    id
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    email
    prcAccreditationNumber
  }
}
    `;
export const GetActiveCourseStudentDocument = gql`
    query GetActiveCourseStudent($courseID: bigint!, $userID: String!) {
  courseStudents: course_students(
    where: {course_id: {_eq: $courseID}, user_id: {_eq: $userID}, is_active: {_eq: true}}
  ) {
    id
    userId: user_id
    orderId: order_id
    courseId: course_id
    approvalStatus: approval_status
    isActive: is_active
    registeredAt: registered_at
    startedAt: started_at
    expiredAt: expired_at
    hasProceedingAccess: has_proceeding_access
    courseModuleStudents: course_module_students {
      id
      courseModuleId: course_module_id
      courseModule: course_module {
        title
        consumablePeriod: consumable_period
      }
      registeredAt: registered_at
      startedAt: started_at
      expiredAt: expired_at
    }
  }
}
    `;
export const GetAttendanceLogsDocument = gql`
    query GetAttendanceLogs($attendanceId: uuid!) {
  result: attendance_logs(where: {attendance_id: {_eq: $attendanceId}}) {
    id
    createdAt: created_at
    userId: user_id
  }
}
    `;
export const GetAttendanceLogsByUserIdDocument = gql`
    query GetAttendanceLogsByUserId($liveSessionId: bigint!, $userId: String!) {
  result: attendance_logs(
    where: {attendance: {live_session_id: {_eq: $liveSessionId}}, _and: {user_id: {_eq: $userId}}}
    order_by: {created_at: desc}
  ) {
    id
    attendance {
      id
    }
    createdAt: created_at
  }
}
    `;
export const GetAttendancesDocument = gql`
    query GetAttendances($liveSessionId: bigint!) {
  result: attendances(
    where: {live_session_id: {_eq: $liveSessionId}}
    order_by: {created_at: desc}
  ) {
    id
    attendanceLogs: attendance_logs {
      id
      userId: user_id
      createdAt: created_at
    }
    createdAt: created_at
  }
}
    `;
export const GetBasicUserDetailsByEmailDocument = gql`
    query GetBasicUserDetailsByEmail($email: String!) {
  user: user(where: {email: {_eq: $email}}) {
    id
    email
  }
}
    `;
export const GetBasicUserDetailsByIDsDocument = gql`
    query GetBasicUserDetailsByIDs($ids: [String!]!) {
  user(where: {id: {_in: $ids}}) {
    displayName
    email
    id
    displayPhotoUuid
  }
}
    `;
export const GetBunnyUploadDetailsDocument = gql`
    query GetBunnyUploadDetails($id: uuid!) {
  bunnyUpload: bunny_upload_by_pk(id: $id) {
    id
    location
    state
    status
    userId: user_id
    providerId: provider_id
    courseId: course_id
    bunnyGuid: bunny_id
  }
}
    `;
export const GetCourseArticleByIdDocument = gql`
    query GetCourseArticleById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    article: course_articles {
      id
      body
      moduleItemId: module_item_id
    }
  }
}
    `;
export const GetCourseAssessmentByIdDocument = gql`
    query GetCourseAssessmentById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    assessment: course_assessments {
      id
      formId: form_id
      moduleItemId: module_item_id
      isLocked: is_locked
    }
  }
}
    `;
export const GetCourseByIdDocument = gql`
    query GetCourseById($id: bigint!) {
  course: courses_by_pk(id: $id) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    coursePreviewVideoUrl: course_preview_video_url
    prcAccreditationNumber: prc_accreditation_number
    publishStatus: publish_status
    isArchived: is_archived
    isPinned: is_pinned
    providerId: provider_id
    createdAt: created_at
    updatedAt: updated_at
    sellType: course_sell_type
    displayStudents: display_students
    consumablePeriod: consumable_period
    requireSequentialProgress: require_sequential_progress
    paymentLink: payment_link
    lyonCommunity: lyon_community
    communityDescription: community_description
    moduleCount: course_modules_aggregate {
      aggregate {
        count
      }
    }
    studentCount: course_students_aggregate(where: {is_active: {_eq: true}}) {
      aggregate {
        count
      }
    }
    videoHour: course_modules_aggregate(
      where: {module_items: {item_type: {_eq: 1}}}
    ) {
      aggregate {
        count
      }
    }
    objectives: course_objectives {
      id
      description
    }
    requirements: course_requirements {
      id
      description
    }
    gains: course_gains {
      id
      description
    }
    lecturers: course_lecturers {
      speaker {
        id
        userId: user_id
        name
        displayPhotoUuid: display_photo_uuid
        description
      }
    }
    courseRewards: course_rewards {
      id
      rankingRewards: ranking_rewards {
        id
        type: ranking_type
        days
      }
      otherRewards: other_unit_rewards {
        id
        units
        type: other_unit_type
      }
      cpdUnitRewards: cpd_unit_rewards {
        id
        isSelfDirected: is_self_directed
        units
        industry
      }
      rewardCertificateType: certificate_type
    }
  }
}
    `;
export const GetCourseCertificateByCourseIdDocument = gql`
    query GetCourseCertificateByCourseId($courseID: bigint!) {
  certificates: certificate_templates(
    where: {course_id: {_eq: $courseID}}
    order_by: {created_at: asc}
  ) {
    accreditationNumber: accreditation_number
    backgroundPhotoUuid: background_photo_uuid
    colorTheme: color_theme
    courseId: course_id
    createdAt: created_at
    dateHeld: date_held
    description
    id
    providerId: provider_id
    reward
    type
    label
    name
    hasLogo: has_logo
    hasProviderName: has_provider_name
    hasAwardsThis: has_awards_this
    hasToDivider: has_to_divider
    certificateSignatories: certificate_template_signatories {
      isSuperimposed: is_superimposed
      signatory: certificate_signatory {
        companyName
        honorific
        id
        isActive
        name
        position
        signatureImageUuid
      }
      id
    }
  }
}
    `;
export const GetCourseCertificateByIdDocument = gql`
    query GetCourseCertificateById($id: uuid!) {
  certificate: certificate_templates_by_pk(id: $id) {
    accreditationNumber: accreditation_number
    backgroundPhotoUuid: background_photo_uuid
    colorTheme: color_theme
    courseId: course_id
    createdAt: created_at
    dateHeld: date_held
    description
    id
    providerId: provider_id
    reward
    type
    name
    label
    hasLogo: has_logo
    hasProviderName: has_provider_name
    hasAwardsThis: has_awards_this
    hasToDivider: has_to_divider
    certificateSignatories: certificate_template_signatories {
      isSuperimposed: is_superimposed
      signatory: certificate_signatory {
        companyName
        honorific
        id
        isActive
        name
        position
        signatureImageUuid
      }
      id
    }
  }
}
    `;
export const GetCourseContentDocument = gql`
    query GetCourseContent($id: bigint!) {
  modules: course_modules(where: {course_id: {_eq: $id}}, order_by: {order: asc}) {
    title
    description
    price
    consumablePeriod: consumable_period
    requireSequentialProgress: require_sequential_progress
    order
    moduleItems: module_items(order_by: {order: asc}) {
      title
      itemType: item_type
      courseModuleId: course_module_id
      isFree: is_free
      order
      enableCommentSection: enable_comment_section
      isLocked: is_locked
      article: course_articles {
        id
        body
        moduleItemId: module_item_id
      }
      assessment: course_assessments {
        id
        formId: form_id
        moduleItemId: module_item_id
        isLocked: is_locked
      }
      form: course_forms {
        id
        formId: form_id
        moduleItemId: module_item_id
        details: form {
          title
          description
        }
      }
      liveSession: course_live_sessions {
        id
        description
        sessionDate: session_date
        timeStart: time_start
        timeEnd: time_end
        streamingService: streaming_service
        moduleItemId: module_item_id
        attendanceEnabled: attendance_enabled
        customLink: custom_link
      }
      pdf: course_attachments {
        id
        url
        moduleItemId: module_item_id
      }
      video: course_videos {
        id
        videoUrl: video_url
        description
        moduleItemId: module_item_id
        source
        status
      }
    }
  }
}
    `;
export const GetCourseCourseOrderDocument = gql`
    query GetCourseCourseOrder($courseID: bigint!) {
  orders: course_order(
    order_by: {purchase_date: desc}
    where: {course_id: {_eq: $courseID}}
  ) {
    id
    status
    refNo: ref_no
    totalPrice: total_price
    purchaseDate: purchase_date
    paymentChannel: payment_method
    userId: user_id
    course_invite_id
    course {
      id
      title
    }
    acknowledgerId: acknowledger_id
    acknowledgerName: acknowledger_name
    acknowledgedAt: acknowledged_at
  }
}
    `;
export const GetCourseDiscountDocument = gql`
    query GetCourseDiscount($code: String!, $courseId: Int!) {
  discount: course_discounts(
    where: {code: {_eq: $code}, _and: {course_id: {_eq: $courseId}}}
  ) {
    id
    code
    expirationDate: expiration_date
    type
    count
    value
    courseId: course_id
    providerId: provider_id
  }
}
    `;
export const GetCourseFormByIdDocument = gql`
    query GetCourseFormById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    form: course_forms {
      id
      formId: form_id
      moduleItemId: module_item_id
      details: form {
        title
        description
      }
    }
  }
}
    `;
export const GetCourseFormManagementByIdDocument = gql`
    query GetCourseFormManagementById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    courseModuleId: course_module_id
    form: course_forms {
      id
      formId: form_id
      moduleItemId: module_item_id
      details: form {
        title
        description
        questions: form_questions(order_by: {position: asc}) {
          id
          name
          position
          pointsPossible: points_possible
          answerCount: maximum_answer_count
          type
          isOpenEnded: is_open_ended
        }
      }
    }
  }
}
    `;
export const GetCourseFormsByCourseIdDocument = gql`
    query GetCourseFormsByCourseId($courseID: bigint!) {
  courseForms: course_forms(
    where: {module_item: {course_module: {course: {id: {_eq: $courseID}}}}}
    order_by: {module_item: {course_module: {order: asc}}}
  ) {
    id
    formId: form_id
    moduleItemId: module_item_id
    details: form {
      title
      description
    }
    moduleItem: module_item {
      title
      courseModule: course_module {
        title
      }
    }
  }
}
    `;
export const GetCourseInstructorByCourseIdDocument = gql`
    query GetCourseInstructorByCourseId($courseID: bigint!) {
  courseInstructor: course_instructors(
    where: {course_id: {_eq: $courseID}}
    order_by: {is_active: desc}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
    userId: user_id
    isActive: is_active
    email
    description
  }
}
    `;
export const GetCourseInstructorCheckDocument = gql`
    query GetCourseInstructorCheck($courseID: bigint!, $userID: String!) {
  courseInstructor: course_instructors(
    where: {course_id: {_eq: $courseID}, user_id: {_eq: $userID}, is_active: {_eq: true}}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
    userId: user_id
    isActive: is_active
    email
    description
  }
}
    `;
export const GetCourseInstructorCoursesDocument = gql`
    query GetCourseInstructorCourses($userID: String!) {
  courseInstructor: course_instructors(
    where: {user_id: {_eq: $userID}, is_active: {_eq: true}}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
    userId: user_id
    course {
      id
      title
      description
      moduleCount: course_modules_aggregate {
        aggregate {
          count
        }
      }
    }
    isActive: is_active
    email
    description
  }
}
    `;
export const GetCourseInviteByIdDocument = gql`
    query GetCourseInviteById($id: uuid!) {
  courseInvite: course_invites_by_pk(id: $id) {
    id
    isUsed: is_used
    usedAt: used_at
    userDisplayName: user_display_name
    userEmail: user_email
    giveProceedingAccess: give_proceeding_access
    freeModules: free_modules
    isCourseFree: is_course_free
    createdAt: created_at
  }
}
    `;
export const GetCourseInvitesDocument = gql`
    query GetCourseInvites($courseId: Int!) {
  courseInvites: course_invites(
    where: {course_id: {_eq: $courseId}}
    order_by: {created_at: asc}
  ) {
    id
    isUsed: is_used
    usedAt: used_at
    userDisplayName: user_display_name
    userEmail: user_email
    giveProceedingAccess: give_proceeding_access
    freeModules: free_modules
    isCourseFree: is_course_free
    createdAt: created_at
  }
}
    `;
export const GetCourseLiveSessionByIdDocument = gql`
    query GetCourseLiveSessionById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    liveSession: course_live_sessions {
      id
      description
      sessionDate: session_date
      timeStart: time_start
      timeEnd: time_end
      streamingService: streaming_service
      moduleItemId: module_item_id
      attendanceEnabled: attendance_enabled
      customLink: custom_link
    }
  }
}
    `;
export const GetCourseModuleItemByIdDocument = gql`
    query GetCourseModuleItemById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    enableCommentSection: enable_comment_section
  }
}
    `;
export const GetCourseModuleItemCommentsDocument = gql`
    query GetCourseModuleItemComments($id: bigint!, $skip: Int, $take: Int) {
  comments: module_item_comments(
    where: {module_item_id: {_eq: $id}}
    order_by: {created_at: desc}
    offset: $skip
    limit: $take
  ) {
    id
    createdAt: created_at
    message
    userId: user_id
    isProvider: is_provider
    isVisible: is_visible
  }
}
    `;
export const GetCourseModulesDocument = gql`
    query GetCourseModules($id: bigint!) {
  modules: course_modules(where: {course_id: {_eq: $id}}, order_by: {order: asc}) {
    title
    id
    description
    price
    consumablePeriod: consumable_period
    requireSequentialProgress: require_sequential_progress
    createdAt: created_at
    updatedAt: updated_at
    order
    moduleItems: module_items(order_by: {order: asc}) {
      id
      title
      itemType: item_type
      isFree: is_free
      isLocked: is_locked
      order
      courseModuleId: course_module_id
      createdAt: created_at
      updatedAt: updated_at
    }
  }
}
    `;
export const GetCourseModulesByCourseIdDocument = gql`
    query GetCourseModulesByCourseID($courseID: bigint!) {
  courseModules: course_modules(where: {course_id: {_eq: $courseID}}) {
    id
  }
}
    `;
export const GetCourseOrdersDocument = gql`
    query GetCourseOrders($userId: String!) {
  orders: course_order(
    order_by: {purchase_date: desc}
    where: {user_id: {_eq: $userId}}
  ) {
    id
    status
    refNo: ref_no
    totalPrice: total_price
    purchaseDate: purchase_date
    paymentChannel: payment_method
    userId: user_id
    course_discount {
      id
      value
      type
    }
    course_invite_id
    course {
      id
      title
      price
      displayPhotoUuid: course_display_photo_uuid
    }
    selectedModules: course_order_modules(order_by: {course_module_id: asc}) {
      courseModule: course_module {
        id
        price
        title
      }
    }
  }
}
    `;
export const GetCoursePdfByIdDocument = gql`
    query GetCoursePDFById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    pdf: course_attachments {
      id
      url
      moduleItemId: module_item_id
    }
  }
}
    `;
export const GetCourseSpeakerByUserIdDocument = gql`
    query GetCourseSpeakerByUserId($id: String!) {
  speaker: speakers(where: {user_id: {_eq: $id}}) {
    id
    description
    name
    displayPhotoUuid: display_photo_uuid
    userId: user_id
  }
}
    `;
export const GetCourseStudentDocument = gql`
    query GetCourseStudent($courseID: bigint) {
  courseStudent: course_students(where: {course_id: {_eq: $courseID}}) {
    id
    userId: user_id
    orderId: order_id
    courseId: course_id
    approvalStatus: approval_status
    isActive: is_active
    registeredAt: registered_at
    startedAt: started_at
    expiredAt: expired_at
    hasProceedingAccess: has_proceeding_access
    courseModuleStudents: course_module_students {
      id
      courseModuleId: course_module_id
      registeredAt: registered_at
      startedAt: started_at
      expiredAt: expired_at
    }
  }
}
    `;
export const GetCourseStudentByIdDocument = gql`
    query GetCourseStudentById($id: bigint!) {
  student: course_students_by_pk(id: $id) {
    approvalStatus: approval_status
    userId: user_id
    courseId: course_id
    expiredAt: expired_at
    hasProceedingAccess: has_proceeding_access
    id
    isActive: is_active
    orderId: order_id
    registeredAt: registered_at
    startedAt: started_at
    courseModuleStudents: course_module_students {
      id
      courseModuleId: course_module_id
      registeredAt: registered_at
      startedAt: started_at
      expiredAt: expired_at
    }
  }
}
    `;
export const GetCourseStudentDetailsByIDsDocument = gql`
    query GetCourseStudentDetailsByIDs($ids: [bigint!]!) {
  students: course_students(where: {id: {_in: $ids}}) {
    approvalStatus: approval_status
    userId: user_id
    courseId: course_id
    expiredAt: expired_at
    hasProceedingAccess: has_proceeding_access
    id
    isActive: is_active
    orderId: order_id
    registeredAt: registered_at
    startedAt: started_at
  }
}
    `;
export const GetCourseStudentProgressDocument = gql`
    query GetCourseStudentProgress($studentID: bigint) {
  studentProgress: student_progress(where: {course_student_id: {_eq: $studentID}}) {
    id
    isComplete: is_complete
    isLocked: is_locked
    moduleItemId: module_item_id
    studentId: course_student_id
  }
}
    `;
export const GetCourseTransactionDocument = gql`
    query GetCourseTransaction($id: uuid!) {
  order: course_order_by_pk(id: $id) {
    id
    status
    refNo: ref_no
    totalPrice: total_price
    purchaseDate: purchase_date
    paymentChannel: payment_method
    proofPaymentUuid: proof_payment_uuid
    userId: user_id
    course_discount {
      id
      value
      type
    }
    course_invite_id
    course {
      id
      title
      price
      displayPhotoUuid: course_display_photo_uuid
    }
    selectedModules: course_order_modules(order_by: {course_module_id: asc}) {
      courseModule: course_module {
        id
        price
        title
      }
    }
    acknowledger_id
    acknowledger_name
    acknowledged_at
  }
}
    `;
export const GetCourseVideoByIdDocument = gql`
    query GetCourseVideoById($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    order
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    video: course_videos {
      id
      videoUrl: video_url
      description
      moduleItemId: module_item_id
      source
      status
    }
  }
}
    `;
export const GetCourseVideoStatusDocument = gql`
    query GetCourseVideoStatus($videoID: String!) {
  video: course_videos(where: {video_url: {_eq: $videoID}}) {
    status
    videoURL: video_url
    source
  }
}
    `;
export const GetDiscountUserUsageDocument = gql`
    query GetDiscountUserUsage($id: uuid!) {
  discount: course_discounts_by_pk(id: $id) {
    courseOrder: course_orders {
      userId: user_id
    }
  }
}
    `;
export const GetEventByIdDocument = gql`
    query GetEventById($eventId: uuid!) {
  event: event_by_pk(id: $eventId) {
    id
    description
    eventDisplayPhotoUuid
    isArchived
    price
    timeEnd
    timeStart
    title
  }
}
    `;
export const GetEventsByMonthDocument = gql`
    query GetEventsByMonth($timeMin: timestamp, $timeMax: timestamp) {
  event(
    order_by: {timeStart: asc}
    where: {timeStart: {_gte: $timeMin}, _and: {timeStart: {_lt: $timeMax}, _and: {isArchived: {_eq: false}}}}
  ) {
    id
    title
    timeStart
    eventDisplayPhotoUuid
    isArchived
    price
    timeEnd
    provider {
      displayPhotoUuid
      id
      isArchived
      name
    }
    industries: event_industries_industries {
      name: industryName
    }
  }
}
    `;
export const GetEventsLiveNowDocument = gql`
    query GetEventsLiveNow {
  event(
    order_by: {timeStart: asc}
    where: {timeStart: {_lte: "NOW()"}, _and: {timeEnd: {_gt: "NOW()"}, _and: {isArchived: {_eq: false}}}}
  ) {
    id
    title
    timeStart
    eventDisplayPhotoUuid
    isArchived
    price
    timeEnd
    provider {
      displayPhotoUuid
      id
      isArchived
      name
    }
    industries: event_industries_industries {
      name: industryName
    }
  }
}
    `;
export const GetFormByIdDocument = gql`
    query GetFormById($id: uuid!) {
  form: forms_by_pk(id: $id) {
    id
    title
    description
    type
    canGoBack: can_go_back
    shuffleQuestion: shuffle_question
    textSelection: text_selection
    timeLimit: time_limit
    allowedAttempts: allowed_attempts
    scoringPolicy: scoring_policy
    showScore: show_score
    showCorrectResponse: show_correct_response
    showRankPercentile: show_rank_percentile
    showAfterRegrade: show_after_regrade
    oneTimeResult: one_time_result
    isLocked: is_locked
    isPractice: is_practice
    accessCode: access_code
  }
}
    `;
export const GetFormQuestionAnswerByIdDocument = gql`
    query GetFormQuestionAnswerById($id: uuid!) {
  formQuestionAnswer: form_question_answers_by_pk(id: $id) {
    id
    name
    feedback
    points
  }
}
    `;
export const GetFormQuestionAnswersDocument = gql`
    query GetFormQuestionAnswers($id: uuid!) {
  question: form_questions_by_pk(id: $id) {
    answers: form_question_answers(order_by: {created_at: asc}) {
      id
      name
      feedback
      points
    }
  }
}
    `;
export const GetFormQuestionAnswersForStudentsDocument = gql`
    query GetFormQuestionAnswersForStudents($id: uuid!) {
  question: form_questions_by_pk(id: $id) {
    answers: form_question_answers(order_by: {created_at: asc}) {
      id
      name
    }
  }
}
    `;
export const GetFormQuestionByIdDocument = gql`
    query GetFormQuestionById($id: uuid!) {
  formQuestion: form_questions_by_pk(id: $id) {
    id
    name
    type
    position
    caption
    isOpenEnded: is_open_ended
    pointsPossible: points_possible
    answerCount: maximum_answer_count
    isCaseSensitive: is_case_sensitive
    correctFeedback: correct_feedback
    incorrectFeedback: incorrect_feedback
    indeterminateFeedback: indeterminate_feedback
  }
}
    `;
export const GetFormQuestionSubmissionDocument = gql`
    query GetFormQuestionSubmission($submission_id: uuid!, $question_id: uuid!, $user_id: String!) {
  formQuestionSubmissions: form_question_submissions(
    where: {form_submission_id: {_eq: $submission_id}, form_question_id: {_eq: $question_id}, user_id: {_eq: $user_id}}
  ) {
    state
    id
    remark
    scoreBeforeRegrade: score_before_regrade
    userId: user_id
    finalScore: final_score
    answers
    question: form_question {
      type
    }
  }
}
    `;
export const GetFormQuestionSubmissionsByCourseFormDocument = gql`
    query GetFormQuestionSubmissionsByCourseForm($courseFormId: uuid!, $id: uuid!) {
  formQuestionSubmissions: form_question_submissions(
    where: {form_submission: {course_form_id: {_eq: $courseFormId}}, form_question_id: {_eq: $id}}
  ) {
    state
    id
    remark
    scoreBeforeRegrade: score_before_regrade
    userId: user_id
    finalScore: final_score
    answers
  }
}
    `;
export const GetFormQuestionSubmissionsByUserDocument = gql`
    query GetFormQuestionSubmissionsByUser($courseFormId: uuid!, $userId: String!, $submissionId: uuid!) {
  formQuestionSubmissions: form_question_submissions(
    where: {form_submission: {course_form_id: {_eq: $courseFormId}}, user_id: {_eq: $userId}, form_submission_id: {_eq: $submissionId}}
  ) {
    state
    id
    remark
    scoreBeforeRegrade: score_before_regrade
    userId: user_id
    finalScore: final_score
    answers
    question: form_question {
      type
    }
  }
}
    `;
export const GetFormQuestionsDocument = gql`
    query GetFormQuestions($id: uuid!) {
  form: forms_by_pk(id: $id) {
    shuffleQuestion: shuffle_question
    questions: form_questions(order_by: {position: asc}) {
      id
      name
      caption
      type
      pointsPossible: points_possible
      answerCount: maximum_answer_count
      correctFeedback: correct_feedback
      incorrectFeedback: incorrect_feedback
      indeterminateFeedback: indeterminate_feedback
      isOpenEnded: is_open_ended
      isCaseSensitive: is_case_sensitive
      position
    }
  }
}
    `;
export const GetFormSubmissionDetailsDocument = gql`
    query GetFormSubmissionDetails($id: uuid!) {
  formSubmission: form_submissions_by_pk(id: $id) {
    id
    attempt
    finishedAt: finished_at
    startedAt: started_at
    formQuestionSubmissionsAggregate: form_question_submissions_aggregate {
      aggregate {
        sum {
          scoreBeforeRegrade: score_before_regrade
          finalScore: final_score
        }
      }
    }
    form {
      formQuestionAggregate: form_questions_aggregate {
        aggregate {
          sum {
            pointsPossible: points_possible
          }
        }
      }
    }
  }
}
    `;
export const GetFormSubmissionQuestionsDocument = gql`
    query GetFormSubmissionQuestions($id: uuid!) {
  formSubmission: form_submissions_by_pk(id: $id) {
    formQuestionSubmission: form_question_submissions(
      order_by: {form_question: {position: asc}}
    ) {
      id
      answers
      state
      scoreBeforeRegrade: score_before_regrade
      finalScore: final_score
      remark
      formQuestion: form_question {
        id
        name
        type
        position
        pointsPossible: points_possible
        answerCount: maximum_answer_count
      }
    }
  }
}
    `;
export const GetFormSubmissionsDocument = gql`
    query GetFormSubmissions($courseFormId: uuid!, $userId: String!) {
  formSubmission: form_submissions(
    where: {course_form_id: {_eq: $courseFormId}, user_id: {_eq: $userId}}
  ) {
    id
    finishedAt: finished_at
    status
    startedAt: started_at
    deadlineAt: deadline_at
    attempt
  }
}
    `;
export const GetFormSubmissionsByCourseFormDocument = gql`
    query GetFormSubmissionsByCourseForm($courseFormId: uuid!) {
  formSubmission: form_submissions(where: {course_form_id: {_eq: $courseFormId}}) {
    id
    attempt
    finishedAt: finished_at
    startedAt: started_at
    userId: user_id
    formQuestionSubmissionsAggregate: form_question_submissions_aggregate {
      aggregate {
        sum {
          scoreBeforeRegrade: score_before_regrade
          finalScore: final_score
        }
      }
    }
    form {
      formQuestionAggregate: form_questions_aggregate {
        aggregate {
          sum {
            pointsPossible: points_possible
          }
        }
      }
    }
  }
}
    `;
export const GetFormSubmissionsByUserDocument = gql`
    query GetFormSubmissionsByUser($userId: String!) {
  formSubmission: form_submissions(
    where: {user_id: {_eq: $userId}, form: {one_time_result: {_eq: false}}}
  ) {
    id
    attempt
    finishedAt: finished_at
    startedAt: started_at
    userId: user_id
    formQuestionSubmissionsAggregate: form_question_submissions_aggregate {
      aggregate {
        sum {
          scoreBeforeRegrade: score_before_regrade
          finalScore: final_score
        }
      }
    }
    form {
      title
      id
      showAfterRegrade: show_after_regrade
      showScore: show_score
      formQuestionAggregate: form_questions_aggregate {
        aggregate {
          sum {
            pointsPossible: points_possible
          }
        }
      }
    }
  }
}
    `;
export const GetIndustriesDocument = gql`
    query GetIndustries {
  industry {
    name
    category: industry_category {
      name
    }
    type: industry_type {
      name
    }
  }
}
    `;
export const GetJoinSessionDetailsDocument = gql`
    query GetJoinSessionDetails($id: bigint!) {
  moduleItem: module_items_by_pk(id: $id) {
    id
    title
    itemType: item_type
    isFree: is_free
    isLocked: is_locked
    courseModuleId: course_module_id
    createdAt: created_at
    updatedAt: updated_at
    liveSession: course_live_sessions {
      id
      description
      sessionDate: session_date
      timeStart: time_start
      timeEnd: time_end
      streamingService: streaming_service
      moduleItemId: module_item_id
      attendanceEnabled: attendance_enabled
      customLink: custom_link
    }
    courseModule: course_module {
      course_id
    }
  }
}
    `;
export const GetLiveSessionsByCourseIdDocument = gql`
    query GetLiveSessionsByCourseId($courseId: bigint!) {
  result: course_live_sessions(
    where: {module_item: {course_module: {course_id: {_eq: $courseId}}}}
  ) {
    id
    moduleItem: module_item {
      title
    }
    attendanceEnabled: attendance_enabled
  }
}
    `;
export const GetModuleItemCompleteProgressDocument = gql`
    query GetModuleItemCompleteProgress($moduleItemId: bigint!) {
  studentProgress: student_progress(
    where: {is_complete: {_eq: true}, module_item_id: {_eq: $moduleItemId}}
  ) {
    id
    isComplete: is_complete
    moduleItemId: module_item_id
    studentId: course_student_id
  }
}
    `;
export const GetOrdersByUserDocument = gql`
    query getOrdersByUser($userId: String) {
  order(where: {user: {id: {_eq: $userId}}}, order_by: {purchaseDate: desc}) {
    id
    refno
    purchaseDate
    total
    productMetadata: product {
      type
      productEntityId
    }
    status
    channel
    discount {
      id
      code
      startDate
      expirationDate
      type
      value
    }
  }
}
    `;
export const GetProviderDocument = gql`
    query GetProvider($id: uuid!) {
  provider(where: {id: {_eq: $id}}) {
    id
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    aboutVideoUuid
    email
    createdAt
    isArchived
    prcAccreditationNumber
    website
    lyonCommunity
    lyonApiKey
    lyonMerchantId
    upcomingEventsAggregate: events_aggregate(where: {timeStart: {_gt: "NOW()"}}) {
      aggregate {
        count
      }
    }
    archivedEventsAggregate: events_aggregate(where: {isArchived: {_eq: true}}) {
      aggregate {
        count
      }
    }
    eventsAggregate: events_aggregate {
      aggregate {
        count
      }
    }
    subscribersAggregate: user_provider_subscriptions_providers_aggregate {
      aggregate {
        count
      }
    }
    industries: provider_frequently_used_industries_industries {
      industryName
    }
  }
}
    `;
export const GetProviderByNameDocument = gql`
    query GetProviderByName($name: String!) {
  provider(where: {name: {_eq: $name}}) {
    id
    name
  }
}
    `;
export const GetProviderCourseOrderDocument = gql`
    query GetProviderCourseOrder($providerID: String!) {
  orders: course_order(
    order_by: {purchase_date: desc}
    where: {course: {provider_id: {_eq: $providerID}}}
  ) {
    id
    status
    refNo: ref_no
    totalPrice: total_price
    purchaseDate: purchase_date
    paymentChannel: payment_method
    userId: user_id
    course_invite_id
    course {
      id
      title
    }
    acknowledgerId: acknowledger_id
    acknowledgerName: acknowledger_name
    acknowledgedAt: acknowledged_at
  }
}
    `;
export const GetProviderCoursesDocument = gql`
    query GetProviderCourses($id: String!, $skip: Int, $take: Int) {
  courses(
    where: {provider_id: {_eq: $id}, is_archived: {_eq: false}}
    order_by: {created_at: asc, is_pinned: desc}
    offset: $skip
    limit: $take
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    publishStatus: publish_status
    isArchived: is_archived
    providerId: provider_id
    createdAt: created_at
    updatedAt: updated_at
    isPinned: is_pinned
    moduleCount: course_modules_aggregate {
      aggregate {
        count
      }
    }
    videoHour: course_modules_aggregate(
      where: {module_items: {item_type: {_eq: 1}}}
    ) {
      aggregate {
        count
      }
    }
    courseRewards: course_rewards {
      cpdUnitRewards: cpd_unit_rewards {
        industry
      }
    }
  }
}
    `;
export const GetProviderDiscountsDocument = gql`
    query GetProviderDiscounts($providerId: uuid!) {
  discount: course_discounts(where: {provider_id: {_eq: $providerId}}) {
    id
    code
    expirationDate: expiration_date
    type
    count
    value
    courseId: course_id
    providerId: provider_id
    course {
      title
    }
    courseOrdersAggregate: course_orders_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;
export const GetProviderForDuplicateDocument = gql`
    query GetProviderForDuplicate($id: uuid!, $idString: String!) {
  provider: provider_by_pk(id: $id) {
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    aboutVideoUuid
    email
    createdAt
    isArchived
    prcAccreditationNumber
    website
    lyonCommunity
    certificateLogoUuid
    isLightTheme
    signatories: certificate_signatories {
      companyName
      honorific
      isActive
      name
      position
      signatureImageUuid
      uploaderId
    }
    managingUsers: provider_managing_users_users {
      userId
    }
  }
  courses(where: {provider_id: {_eq: $idString}}) {
    community_description
    consumable_period
    course_display_photo_uuid
    course_preview_video_url
    course_sell_type
    description
    display_students
    is_archived
    is_pinned
    is_private
    lyon_community
    payment_link
    prc_accreditation_number
    price
    publish_status
    require_sequential_progress
    title
    id
    gains: course_gains {
      id
      description
    }
    requirements: course_requirements {
      id
      description
    }
    objectives: course_objectives {
      id
      description
    }
    lecturers: course_lecturers {
      speaker {
        id
        user_id
        name
        description
        display_photo_uuid
      }
    }
  }
}
    `;
export const GetProviderFormsDocument = gql`
    query GetProviderForms($id: uuid!) {
  forms(where: {provider_id: {_eq: $id}}, order_by: {created_at: desc}) {
    id
    title
    description
  }
}
    `;
export const GetProviderInstructorCheckDocument = gql`
    query GetProviderInstructorCheck($providerID: String!, $userID: String!) {
  courseInstructor: course_instructors(
    where: {course: {provider_id: {_eq: $providerID}}, user_id: {_eq: $userID}, is_active: {_eq: true}}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
    userId: user_id
    isActive: is_active
    email
    description
  }
}
    `;
export const GetProviderManagingUsersByIdDocument = gql`
    query GetProviderManagingUsersById($id: uuid!) {
  managingUsers: provider_managing_users_user(where: {providerId: {_eq: $id}}) {
    user {
      email
      displayName
      displayPhotoUuid
      id
      isAdmin
    }
  }
}
    `;
export const GetProviderSignatoriesDocument = gql`
    query GetProviderSignatories($id: uuid!) {
  signatories: certificate_signatory(
    where: {providerId: {_eq: $id}}
    order_by: {name: asc}
  ) {
    name
    position
    id
    honorific
    companyName
    signatureImageUuid
    isActive
    uploaderId
  }
}
    `;
export const GetProviderStudentCountDocument = gql`
    query GetProviderStudentCount($id: String!) {
  courseStudentAggregate: course_students_aggregate(
    where: {course: {provider_id: {_eq: $id}}}
  ) {
    aggregate {
      count(columns: user_id, distinct: true)
    }
  }
}
    `;
export const GetPublishedCoursesDocument = gql`
    query GetPublishedCourses($skip: Int, $take: Int) {
  courses(
    where: {publish_status: {_eq: 1}, is_archived: {_eq: false}}
    order_by: {created_at: asc}
    offset: $skip
    limit: $take
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    publishStatus: publish_status
    isArchived: is_archived
    isPinned: is_pinned
    providerId: provider_id
    createdAt: created_at
    updatedAt: updated_at
    moduleCount: course_modules_aggregate {
      aggregate {
        count
      }
    }
    courseRewards: course_rewards {
      cpdUnitRewards: cpd_unit_rewards {
        industry
      }
    }
  }
}
    `;
export const GetRevenueByCourseIdDocument = gql`
    query GetRevenueByCourseId($courseId: bigint!) {
  order: course_order(where: {course_id: {_eq: $courseId}, status: {_eq: S}}) {
    totalPrice: total_price
  }
}
    `;
export const GetRevenueByProductIdDocument = gql`
    query GetRevenueByProductId($productId: String!) {
  order(
    where: {product: {productEntityId: {_eq: $productId}}, status: {_eq: "S"}}
  ) {
    total
  }
}
    `;
export const GetStudentCoursesDocument = gql`
    query GetStudentCourses($userId: String!) {
  courseStudent: course_students(
    where: {user_id: {_eq: $userId}, is_active: {_eq: true}}
  ) {
    startedAt: started_at
    course {
      id
      title
      description
      price
      courseDisplayPhotoUuid: course_display_photo_uuid
      coursePreviewVideoUrl: course_preview_video_url
      prcAccreditationNumber: prc_accreditation_number
      publishStatus: publish_status
      isArchived: is_archived
      providerId: provider_id
      createdAt: created_at
      updatedAt: updated_at
      sellType: course_sell_type
      displayStudents: display_students
      consumablePeriod: consumable_period
      requireSequentialProgress: require_sequential_progress
      isPinned: is_pinned
      moduleCount: course_modules_aggregate {
        aggregate {
          count
        }
      }
      videoHour: course_modules_aggregate(
        where: {module_items: {item_type: {_eq: 1}}}
      ) {
        aggregate {
          count
        }
      }
    }
    approvalStatus: approval_status
    studentProgressAggregate: student_progresses_aggregate(
      where: {is_complete: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;
export const GetStudentProgessDocument = gql`
    query GetStudentProgess($moduleItemId: bigint!, $courseStudentId: bigint!) {
  studentProgress: student_progress(
    where: {course_student_id: {_eq: $courseStudentId}, module_item_id: {_eq: $moduleItemId}}
  ) {
    id
    isComplete: is_complete
    moduleItemId: module_item_id
    studentId: course_student_id
  }
}
    `;
export const GetUserAdminByIdDocument = gql`
    query GetUserAdminById($id: String!) {
  user: user_by_pk(id: $id) {
    isAdmin
  }
}
    `;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String!) {
  user: user(where: {email: {_ilike: $email}}) {
    id
    email
    displayName
    city
    phoneNumber
    licenseNumber
    licensePhotoUuid
    birthDate
    displayPhotoUuid
    sex
  }
}
    `;
export const GetUserByIdDocument = gql`
    query GetUserById($id: String!) {
  user: user_by_pk(id: $id) {
    id
    email
    displayName
    city
    phoneNumber
    licenseNumber
    licensePhotoUuid
    birthDate
    displayPhotoUuid
    sex
  }
}
    `;
export const GetUserCertificateByIdDocument = gql`
    query GetUserCertificateById($id: uuid!) {
  userCertificate: user_certificates_by_pk(id: $id) {
    id
    dateDistributed: date_distributed
    shortId: short_id
    userName: user_name
    certificateTemplate: certificate_template {
      accreditationNumber: accreditation_number
      backgroundPhotoUuid: background_photo_uuid
      colorTheme: color_theme
      courseId: course_id
      createdAt: created_at
      dateHeld: date_held
      description
      id
      providerId: provider_id
      reward
      type
      label
      hasLogo: has_logo
      hasProviderName: has_provider_name
      hasAwardsThis: has_awards_this
      hasToDivider: has_to_divider
      certificateSignatories: certificate_template_signatories {
        isSuperimposed: is_superimposed
        signatory: certificate_signatory {
          companyName
          honorific
          id
          isActive
          name
          position
          signatureImageUuid
        }
        id
      }
    }
  }
}
    `;
export const GetUserCertificateByUserIdDocument = gql`
    query GetUserCertificateByUserId($userID: String!, $courseID: bigint!) {
  userCertificates: user_certificates(
    where: {user_id: {_eq: $userID}, course_id: {_eq: $courseID}}
    order_by: {date_distributed: asc}
  ) {
    id
    dateDistributed: date_distributed
    shortId: short_id
    userName: user_name
    userId: user_id
    courseId: course_id
    certificateTemplate: certificate_template {
      accreditationNumber: accreditation_number
      backgroundPhotoUuid: background_photo_uuid
      colorTheme: color_theme
      courseId: course_id
      createdAt: created_at
      dateHeld: date_held
      description
      id
      providerId: provider_id
      reward
      type
      label
      hasLogo: has_logo
      hasProviderName: has_provider_name
      hasAwardsThis: has_awards_this
      hasToDivider: has_to_divider
      certificateSignatories: certificate_template_signatories {
        isSuperimposed: is_superimposed
        signatory: certificate_signatory {
          companyName
          honorific
          id
          isActive
          name
          position
          signatureImageUuid
        }
        id
      }
    }
  }
}
    `;
export const GetUserCertificatesDocument = gql`
    query GetUserCertificates($userID: String!) {
  userCertificates: user_certificates(
    where: {user_id: {_eq: $userID}}
    order_by: {date_distributed: asc}
  ) {
    id
    dateDistributed: date_distributed
    shortId: short_id
    userName: user_name
    userId: user_id
    courseId: course_id
    certificateTemplate: certificate_template {
      accreditationNumber: accreditation_number
      backgroundPhotoUuid: background_photo_uuid
      colorTheme: color_theme
      courseId: course_id
      createdAt: created_at
      dateHeld: date_held
      description
      id
      label
      providerId: provider_id
      reward
      type
      hasLogo: has_logo
      hasProviderName: has_provider_name
      hasAwardsThis: has_awards_this
      hasToDivider: has_to_divider
      certificateSignatories: certificate_template_signatories {
        isSuperimposed: is_superimposed
        signatory: certificate_signatory {
          companyName
          honorific
          id
          isActive
          name
          position
          signatureImageUuid
        }
        id
      }
    }
  }
}
    `;
export const GetUserFollowedIndustriesByIdDocument = gql`
    query GetUserFollowedIndustriesById($id: String!) {
  user: user_by_pk(id: $id) {
    followedIndustries: user_followed_industries_industries {
      industry {
        name
        category: categoryName
      }
    }
  }
}
    `;
export const GetUserInfosDocument = gql`
    query GetUserInfos($userIds: [String!]!) {
  result: user(where: {id: {_in: $userIds}}, order_by: {displayName: asc}) {
    id
    displayName
    email
  }
}
    `;
export const GetUserNameDocument = gql`
    query GetUserName($userId: String!) {
  result: user_by_pk(id: $userId) {
    displayName
  }
}
    `;
export const GetUserNamesDocument = gql`
    query GetUserNames($userIds: [String!]!) {
  result: user(where: {id: {_in: $userIds}}, order_by: {displayName: asc}) {
    id
    displayName
  }
}
    `;
export const GetUserProviderAccountByIdDocument = gql`
    query GetUserProviderAccountById($id: String!) {
  provider: provider_managing_users_user(where: {userId: {_eq: $id}}) {
    provider {
      id
      name
      displayPhotoUuid
      email
      createdAt
      description
      address
      contactNumber
      managingUserAggregate: provider_managing_users_users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export const GetUserProviderSubscriptionsByIdDocument = gql`
    query GetUserProviderSubscriptionsById($id: String!) {
  user: user_by_pk(id: $id) {
    providerSubscriptions: user_provider_subscriptions_providers {
      provider {
        id
        name
        address
        contactNumber
        coverPhotoUuid
        description
        displayPhotoUuid
        email
        createdAt
        isArchived
        prcAccreditationNumber
        upcomingEventsAggregate: events_aggregate(where: {timeStart: {_gt: "NOW()"}}) {
          aggregate {
            count
          }
        }
        archivedEventsAggregate: events_aggregate(where: {isArchived: {_eq: true}}) {
          aggregate {
            count
          }
        }
        eventsAggregate: events_aggregate {
          aggregate {
            count
          }
        }
        subscribersAggregate: user_provider_subscriptions_providers_aggregate {
          aggregate {
            count
          }
        }
        industries: provider_frequently_used_industries_industries {
          industryName
        }
      }
    }
  }
}
    `;
export const InsertCourseModuleStudentsDocument = gql`
    mutation InsertCourseModuleStudents($objects: [course_module_students_insert_input!]!) {
  insert_course_module_students(objects: $objects) {
    affected_rows
  }
}
    `;
export const InsertCourseStudentDocument = gql`
    mutation InsertCourseStudent($courseID: bigint!, $userID: String!, $hasProceedingAccess: Boolean!) {
  newCourseStudent: insert_course_students_one(
    object: {course_id: $courseID, user_id: $userID, has_proceeding_access: $hasProceedingAccess}
  ) {
    id
  }
}
    `;
export const SearchCourseDocument = gql`
    query SearchCourse($query: String, $skip: Int, $take: Int) {
  courses(
    where: {_and: [{is_archived: {_eq: false}}, {publish_status: {_eq: 1}}], _or: [{title: {_ilike: $query}}, {description: {_ilike: $query}}, {course_lecturers: {speaker: {name: {_ilike: $query}}}}]}
    order_by: {course_students_aggregate: {count: asc}}
    offset: $skip
    limit: $take
  ) {
    id
    title
    description
    price
    courseDisplayPhotoUuid: course_display_photo_uuid
    coursePreviewVideoUrl: course_preview_video_url
    isArchived: is_archived
    isPinned: is_pinned
    prcAccreditationNumber: prc_accreditation_number
    publishStatus: publish_status
    industries: course_rewards {
      name: cpd_unit_rewards {
        industry
      }
    }
    modulesAggregate: course_modules_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;
export const SearchEventDocument = gql`
    query SearchEvent($query: String, $skip: Int, $take: Int) {
  event(
    where: {_and: {isArchived: {_eq: false}}, _or: [{title: {_ilike: $query}}, {description: {_ilike: $query}}, {streams: {title: {_ilike: $query}}}, {streams: {description: {_ilike: $query}}}]}
    order_by: {event_participants_aggregate: {count: asc}}
    offset: $skip
    limit: $take
  ) {
    id
    title
    timeStart
    eventDisplayPhotoUuid
    isArchived
    price
    timeEnd
    provider {
      displayPhotoUuid
      id
      isArchived
      name
    }
    industries: event_industries_industries {
      name: industryName
    }
  }
}
    `;
export const SearchProviderDocument = gql`
    query SearchProvider($query: String, $skip: Int, $take: Int) {
  provider(
    where: {_and: {isArchived: {_eq: false}, _or: [{name: {_ilike: $query}}, {description: {_ilike: $query}}]}}
    order_by: {user_provider_subscriptions_providers_aggregate: {count: asc}}
    offset: $skip
    limit: $take
  ) {
    id
    name
    address
    contactNumber
    coverPhotoUuid
    description
    displayPhotoUuid
    email
    createdAt
    isArchived
    prcAccreditationNumber
    upcomingEventsAggregate: events_aggregate(where: {timeStart: {_gt: "NOW()"}}) {
      aggregate {
        count
      }
    }
    archivedEventsAggregate: events_aggregate(where: {isArchived: {_eq: true}}) {
      aggregate {
        count
      }
    }
    eventsAggregate: events_aggregate {
      aggregate {
        count
      }
    }
    subscribersAggregate: user_provider_subscriptions_providers_aggregate {
      aggregate {
        count
      }
    }
    industries: provider_frequently_used_industries_industries {
      industryName
    }
  }
}
    `;
export const UpdateCertificateSignatoriesPositionDocument = gql`
    mutation UpdateCertificateSignatoriesPosition($id: uuid!, $isSuperimposed: Boolean!) {
  certificateSignatory: update_certificate_template_signatories_by_pk(
    pk_columns: {id: $id}
    _set: {is_superimposed: $isSuperimposed}
  ) {
    is_superimposed
    id
  }
}
    `;
export const UpdateCommentVisibilityDocument = gql`
    mutation UpdateCommentVisibility($id: bigint!, $isVisible: Boolean!) {
  moduleItem: update_module_item_comments_by_pk(
    pk_columns: {id: $id}
    _set: {is_visible: $isVisible}
  ) {
    id
    is_visible
  }
}
    `;
export const UpdateCourseDocument = gql`
    mutation UpdateCourse($courseId: bigint!, $providerId: String!, $title: String!, $description: String!, $price: numeric, $courseDisplayPhotoUuid: String, $courseSellType: course_sell_types_enum, $consumablePeriod: numeric, $requireSequentialProgress: Boolean, $paymentLink: String) {
  course: update_courses_by_pk(
    pk_columns: {id: $courseId}
    _set: {provider_id: $providerId, title: $title, description: $description, price: $price, course_display_photo_uuid: $courseDisplayPhotoUuid, course_sell_type: $courseSellType, payment_link: $paymentLink, consumable_period: $consumablePeriod, require_sequential_progress: $requireSequentialProgress}
  ) {
    id
    title
    description
    providerId: provider_id
  }
}
    `;
export const UpdateCourseArchiveStatusDocument = gql`
    mutation UpdateCourseArchiveStatus($id: bigint!, $status: Boolean!) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {is_archived: $status}
  ) {
    is_archived
  }
}
    `;
export const UpdateCourseArticleByIdDocument = gql`
    mutation UpdateCourseArticleById($id: bigint!, $body: String) {
  moduleItem: update_course_articles(
    where: {module_item_id: {_eq: $id}}
    _set: {body: $body}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCourseAssessmentByIdDocument = gql`
    mutation UpdateCourseAssessmentById($id: bigint!, $formId: String, $isLocked: Boolean) {
  moduleItem: update_course_assessments(
    where: {module_item_id: {_eq: $id}}
    _set: {form_id: $formId, is_locked: $isLocked}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCourseCertificateDocument = gql`
    mutation UpdateCourseCertificate($id: uuid!, $label: String, $accreditationNumber: String, $backgroundPhotoUuid: String, $colorTheme: String, $dateHeld: String, $description: String, $reward: String, $type: String!, $name: String, $hasLogo: Boolean, $hasProviderName: Boolean, $hasAwardsThis: Boolean, $hasToDivider: Boolean) {
  certificate: update_certificate_templates_by_pk(
    pk_columns: {id: $id}
    _set: {label: $label, accreditation_number: $accreditationNumber, background_photo_uuid: $backgroundPhotoUuid, color_theme: $colorTheme, date_held: $dateHeld, description: $description, reward: $reward, type: $type, name: $name, has_logo: $hasLogo, has_provider_name: $hasProviderName, has_awards_this: $hasAwardsThis, has_to_divider: $hasToDivider}
  ) {
    id
  }
}
    `;
export const UpdateCourseCommunityDocument = gql`
    mutation UpdateCourseCommunity($id: bigint!, $description: String, $community: String) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {lyon_community: $community, community_description: $description}
  ) {
    id
    lyon_community
    community_description
  }
}
    `;
export const UpdateCourseFormByIdDocument = gql`
    mutation UpdateCourseFormById($id: bigint!, $formId: uuid) {
  moduleItem: update_course_forms(
    where: {module_item_id: {_eq: $id}}
    _set: {form_id: $formId}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCourseInstructorIsActiveDocument = gql`
    mutation UpdateCourseInstructorIsActive($id: uuid!, $isActive: Boolean!) {
  courseInstructor: update_course_instructors_by_pk(
    pk_columns: {id: $id}
    _set: {is_active: $isActive}
  ) {
    id
    createdAt: created_at
    updatedAt: updated_at
  }
}
    `;
export const UpdateCourseInviteUsedDocument = gql`
    mutation UpdateCourseInviteUsed($id: uuid!, $usedAt: timestamptz!) {
  courseInvites: update_course_invites_by_pk(
    pk_columns: {id: $id}
    _set: {is_used: true, used_at: $usedAt}
  ) {
    id
    isUsed: is_used
    usedAt: used_at
  }
}
    `;
export const UpdateCourseLiveSessionByIdDocument = gql`
    mutation UpdateCourseLiveSessionById($id: bigint!, $description: String, $sessionDate: timestamptz, $timeStart: timestamptz, $timeEnd: timestamptz, $streamingService: Int, $attendanceEnabled: Boolean, $customLink: String) {
  moduleItem: update_course_live_sessions(
    where: {module_item_id: {_eq: $id}}
    _set: {description: $description, session_date: $sessionDate, time_start: $timeStart, time_end: $timeEnd, streaming_service: $streamingService, attendance_enabled: $attendanceEnabled, custom_link: $customLink}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCourseModuleByIdDocument = gql`
    mutation UpdateCourseModuleById($id: bigint!, $title: String!, $description: String, $requireSequentialProgress: Boolean, $consumablePeriod: numeric, $price: numeric) {
  moduleItem: update_course_modules_by_pk(
    pk_columns: {id: $id}
    _set: {title: $title, description: $description, price: $price, consumable_period: $consumablePeriod, require_sequential_progress: $requireSequentialProgress}
  ) {
    id
    title
    description
    price
    courseId: course_id
    requireSequentialProgress: require_sequential_progress
  }
}
    `;
export const UpdateCourseModuleItemCommentSectionDocument = gql`
    mutation UpdateCourseModuleItemCommentSection($id: bigint!, $enableCommentSection: Boolean!) {
  moduleItem: update_module_items_by_pk(
    pk_columns: {id: $id}
    _set: {enable_comment_section: $enableCommentSection}
  ) {
    id
    enable_comment_section
  }
}
    `;
export const UpdateCourseModuleItemFreeStatusDocument = gql`
    mutation UpdateCourseModuleItemFreeStatus($id: bigint!, $isFree: Boolean!) {
  moduleItem: update_module_items_by_pk(
    pk_columns: {id: $id}
    _set: {is_free: $isFree}
  ) {
    title
    is_free
  }
}
    `;
export const UpdateCourseModuleItemLockStatusDocument = gql`
    mutation UpdateCourseModuleItemLockStatus($id: bigint!, $isLocked: Boolean!) {
  moduleItem: update_module_items_by_pk(
    pk_columns: {id: $id}
    _set: {is_locked: $isLocked}
  ) {
    title
    is_locked
  }
}
    `;
export const UpdateCourseModuleItemOrderByIdDocument = gql`
    mutation UpdateCourseModuleItemOrderById($id: bigint!, $order: Int!) {
  moduleItem: update_module_items_by_pk(
    pk_columns: {id: $id}
    _set: {order: $order}
  ) {
    id
    order
  }
}
    `;
export const UpdateCourseModuleItemTitleDocument = gql`
    mutation UpdateCourseModuleItemTitle($id: bigint!, $title: String!) {
  moduleItem: update_module_items_by_pk(
    pk_columns: {id: $id}
    _set: {title: $title}
  ) {
    title
  }
}
    `;
export const UpdateCourseModuleOrderByIdDocument = gql`
    mutation UpdateCourseModuleOrderById($id: bigint!, $order: Int!) {
  module: update_course_modules_by_pk(
    pk_columns: {id: $id}
    _set: {order: $order}
  ) {
    id
    order
  }
}
    `;
export const UpdateCourseModuleStudentStartDocument = gql`
    mutation UpdateCourseModuleStudentStart($id: uuid!, $startedAt: timestamp!) {
  courseModuleStudent: update_course_module_students_by_pk(
    pk_columns: {id: $id}
    _set: {started_at: $startedAt}
  ) {
    id
  }
}
    `;
export const UpdateCourseOrderAcknowledgementDocument = gql`
    mutation UpdateCourseOrderAcknowledgement($id: uuid!, $acknowledger_id: String!, $acknowledger_name: String!) {
  courseOrder: update_course_order_by_pk(
    pk_columns: {id: $id}
    _set: {acknowledger_id: $acknowledger_id, acknowledger_name: $acknowledger_name, acknowledged_at: "now()"}
  ) {
    id
    acknowledgerId: acknowledger_id
    acknowledgerName: acknowledger_name
    acknowledgedAt: acknowledged_at
  }
}
    `;
export const UpdateCourseOrderStatusDocument = gql`
    mutation UpdateCourseOrderStatus($id: uuid!, $acknowledger_id: String, $acknowledger_name: String, $status: course_order_statuses_enum!) {
  order: update_course_order_by_pk(
    pk_columns: {id: $id}
    _set: {acknowledger_id: $acknowledger_id, acknowledger_name: $acknowledger_name, acknowledged_at: "now()", status: $status}
  ) {
    id
    acknowledgerId: acknowledger_id
    acknowledgerName: acknowledger_name
    acknowledgedAt: acknowledged_at
    status
    userId: user_id
    totalPrice: total_price
    hasProceedingAccess: has_proceeding_access
    course {
      id
      title
      price
      displayPhotoUuid: course_display_photo_uuid
      providerId: provider_id
      createdAt: created_at
      sellType: course_sell_type
    }
  }
}
    `;
export const UpdateCoursePdfByIdDocument = gql`
    mutation UpdateCoursePDFById($id: bigint!, $url: String) {
  moduleItem: update_course_attachments(
    where: {module_item_id: {_eq: $id}}
    _set: {url: $url}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCoursePinnedStatusDocument = gql`
    mutation UpdateCoursePinnedStatus($id: bigint!, $isPinned: Boolean!) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {is_pinned: $isPinned}
  ) {
    id
    isPinned: is_pinned
  }
}
    `;
export const UpdateCoursePrivateStatusDocument = gql`
    mutation UpdateCoursePrivateStatus($id: bigint!, $isPrivate: Boolean!) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {is_private: $isPrivate}
  ) {
    id
    is_private
  }
}
    `;
export const UpdateCoursePublishStatusDocument = gql`
    mutation UpdateCoursePublishStatus($id: bigint!, $status: Int!) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {publish_status: $status}
  ) {
    publish_status
  }
}
    `;
export const UpdateCourseShowStudentsDocument = gql`
    mutation UpdateCourseShowStudents($id: bigint!, $displayStudents: Boolean!) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {display_students: $displayStudents}
  ) {
    display_students
  }
}
    `;
export const UpdateCourseStudentProgressLockStatusDocument = gql`
    mutation UpdateCourseStudentProgressLockStatus($id: bigint!, $isLocked: Boolean!) {
  moduleItem: update_student_progress_by_pk(
    pk_columns: {id: $id}
    _set: {is_locked: $isLocked}
  ) {
    id
    is_locked
  }
}
    `;
export const UpdateCourseStudentStartDocument = gql`
    mutation UpdateCourseStudentStart($id: bigint!, $startedAt: timestamp!) {
  courseStudent: update_course_students_by_pk(
    pk_columns: {id: $id}
    _set: {started_at: $startedAt}
  ) {
    id
  }
}
    `;
export const UpdateCourseStudentStateDocument = gql`
    mutation UpdateCourseStudentState($id: bigint!) {
  update_course_students_by_pk(pk_columns: {id: $id}, _set: {is_active: false}) {
    id
    is_active
  }
}
    `;
export const UpdateCourseVideoByIdDocument = gql`
    mutation UpdateCourseVideoById($id: bigint!, $videoUrl: String, $description: String, $source: String) {
  moduleItem: update_course_videos(
    where: {module_item_id: {_eq: $id}}
    _set: {video_url: $videoUrl, description: $description, source: $source}
  ) {
    affected_rows
  }
}
    `;
export const UpdateCourseVideoPreviewDocument = gql`
    mutation UpdateCourseVideoPreview($id: bigint!, $video: String) {
  course: update_courses_by_pk(
    pk_columns: {id: $id}
    _set: {course_preview_video_url: $video}
  ) {
    course_preview_video_url
  }
}
    `;
export const UpdateFormByIdDocument = gql`
    mutation UpdateFormById($id: uuid!, $title: String!, $description: String, $type: form_types_enum!, $scoringPolicy: form_scoring_policies_enum, $allowedAttempts: numeric, $timeLimit: numeric, $accessCode: String, $canGoBack: Boolean, $isLocked: Boolean, $isPractice: Boolean, $oneTimeResult: Boolean, $showCorrectResponse: Boolean, $showRankPercentile: Boolean, $showScore: Boolean, $showAfterRegrade: Boolean, $shuffleQuestion: Boolean, $textSelection: Boolean) {
  form: update_forms_by_pk(
    pk_columns: {id: $id}
    _set: {access_code: $accessCode, allowed_attempts: $allowedAttempts, can_go_back: $canGoBack, description: $description, is_locked: $isLocked, is_practice: $isPractice, one_time_result: $oneTimeResult, scoring_policy: $scoringPolicy, show_correct_response: $showCorrectResponse, show_rank_percentile: $showRankPercentile, show_score: $showScore, show_after_regrade: $showAfterRegrade, shuffle_question: $shuffleQuestion, text_selection: $textSelection, time_limit: $timeLimit, title: $title, type: $type}
  ) {
    id
    access_code
    allowed_attempts
    can_go_back
    description
    is_locked
    is_practice
    one_time_result
    scoring_policy
    show_correct_response
    show_rank_percentile
    show_score
    show_after_regrade
    shuffle_question
    text_selection
    time_limit
    title
    type
  }
}
    `;
export const UpdateFormQuestionByIdDocument = gql`
    mutation UpdateFormQuestionById($id: uuid!, $name: String!, $caption: String, $type: form_question_types_enum!, $isOpenEnded: Boolean!, $pointsPossible: numeric, $answerCount: numeric, $isCaseSensitive: Boolean!, $correctFeedback: String, $incorrectFeedback: String, $indeterminateFeedback: String, $position: numeric!) {
  formQuestion: update_form_questions_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, caption: $caption, type: $type, is_open_ended: $isOpenEnded, points_possible: $pointsPossible, maximum_answer_count: $answerCount, is_case_sensitive: $isCaseSensitive, correct_feedback: $correctFeedback, incorrect_feedback: $incorrectFeedback, indeterminate_feedback: $indeterminateFeedback, position: $position}
  ) {
    id
    name
    type
  }
}
    `;
export const UpdateFormQuestionPositionByIdDocument = gql`
    mutation UpdateFormQuestionPositionById($id: uuid!, $position: numeric!) {
  formQuestion: update_form_questions_by_pk(
    pk_columns: {id: $id}
    _set: {position: $position}
  ) {
    id
    position
  }
}
    `;
export const UpdateFormQuestionSubmissionDocument = gql`
    mutation UpdateFormQuestionSubmission($objects: [form_question_submissions_insert_input!]!) {
  formQuestionSubmission: insert_form_question_submissions(
    objects: $objects
    on_conflict: {constraint: form_question_submissions_pkey, update_columns: [state, final_score, remark]}
  ) {
    affected_rows
  }
}
    `;
export const UpdateFormSubmissionFinishDocument = gql`
    mutation UpdateFormSubmissionFinish($id: uuid!, $finishedAt: timestamp!, $status: form_submission_statuses_enum!) {
  formSubmission: update_form_submissions_by_pk(
    pk_columns: {id: $id}
    _set: {finished_at: $finishedAt, status: $status}
  ) {
    id
    finishedAt: finished_at
    userId: user_id
    status: status
  }
}
    `;
export const UpdateProviderDocument = gql`
    mutation UpdateProvider($id: uuid!, $name: String!, $address: String, $contactNumber: String, $description: String, $email: String, $displayPhotoUuid: String, $coverPhotoUuid: String, $aboutVideoUuid: String, $website: String, $lyonCommunity: String) {
  update_provider_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, address: $address, contactNumber: $contactNumber, description: $description, email: $email, displayPhotoUuid: $displayPhotoUuid, coverPhotoUuid: $coverPhotoUuid, aboutVideoUuid: $aboutVideoUuid, website: $website, lyonCommunity: $lyonCommunity}
  ) {
    id
  }
}
    `;
export const UpdateProviderMerchantDetailsDocument = gql`
    mutation UpdateProviderMerchantDetails($id: uuid!, $lyonMerchantId: String, $lyonApiKey: String) {
  update_provider_by_pk(
    pk_columns: {id: $id}
    _set: {lyonMerchantId: $lyonMerchantId, lyonApiKey: $lyonApiKey}
  ) {
    id
  }
}
    `;
export const UpdateStudentApprovalStatusByIdDocument = gql`
    mutation UpdateStudentApprovalStatusByID($id: bigint!) {
  courseStudent: update_course_students_by_pk(
    pk_columns: {id: $id}
    _set: {approval_status: 1}
  ) {
    id
    approval_status
  }
}
    `;
export const UpdateStudentApprovalStatusByIDsDocument = gql`
    mutation UpdateStudentApprovalStatusByIDs($objects: [course_students_insert_input!]!) {
  students: insert_course_students(
    objects: $objects
    on_conflict: {constraint: course_students_pkey, update_columns: [approval_status, is_active]}
  ) {
    affected_rows
  }
}
    `;
export const UpdateStudentProgressStatusDocument = gql`
    mutation UpdateStudentProgressStatus($id: bigint!, $isComplete: Boolean!) {
  studentProgress: update_student_progress_by_pk(
    pk_columns: {id: $id}
    _set: {is_complete: $isComplete}
  ) {
    id
    isComplete: is_complete
    moduleItemId: module_item_id
    studentId: course_student_id
  }
}
    `;
export const UpdateUserAboutDetailsDocument = gql`
    mutation UpdateUserAboutDetails($id: String!, $licenseNumber: String, $birthDate: timestamp, $city: String) {
  user: update_user_by_pk(
    pk_columns: {id: $id}
    _set: {licenseNumber: $licenseNumber, birthDate: $birthDate, city: $city}
  ) {
    licenseNumber
    birthDate
    city
  }
}
    `;
export const UpdateUserAboutIndustriesDocument = gql`
    mutation UpdateUserAboutIndustries($id: String!, $payload: [user_followed_industries_industry_insert_input!]!) {
  delete: delete_user_followed_industries_industry(where: {userId: {_eq: $id}}) {
    returning {
      industryName
      userId
    }
  }
  insert: insert_user_followed_industries_industry(objects: $payload) {
    returning {
      industryName
      userId
    }
  }
}
    `;
export const UpdateUserDetailsDocument = gql`
    mutation UpdateUserDetails($id: String!, $displayName: String!, $displayPhotoUuid: String, $city: String) {
  user: update_user_by_pk(
    pk_columns: {id: $id}
    _set: {displayName: $displayName, displayPhotoUuid: $displayPhotoUuid, city: $city}
  ) {
    displayName
    displayPhotoUuid
    city
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateAttachment(variables: CreateAttachmentMutationVariables): Promise<CreateAttachmentMutation> {
      return withWrapper(() => client.request<CreateAttachmentMutation>(print(CreateAttachmentDocument), variables));
    },
    CreateAttendance(variables?: CreateAttendanceMutationVariables): Promise<CreateAttendanceMutation> {
      return withWrapper(() => client.request<CreateAttendanceMutation>(print(CreateAttendanceDocument), variables));
    },
    CreateCertificateSignatories(variables: CreateCertificateSignatoriesMutationVariables): Promise<CreateCertificateSignatoriesMutation> {
      return withWrapper(() => client.request<CreateCertificateSignatoriesMutation>(print(CreateCertificateSignatoriesDocument), variables));
    },
    CreateCourse(variables: CreateCourseMutationVariables): Promise<CreateCourseMutation> {
      return withWrapper(() => client.request<CreateCourseMutation>(print(CreateCourseDocument), variables));
    },
    CreateCourseArticle(variables: CreateCourseArticleMutationVariables): Promise<CreateCourseArticleMutation> {
      return withWrapper(() => client.request<CreateCourseArticleMutation>(print(CreateCourseArticleDocument), variables));
    },
    CreateCourseArticleDuplicate(variables: CreateCourseArticleDuplicateMutationVariables): Promise<CreateCourseArticleDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseArticleDuplicateMutation>(print(CreateCourseArticleDuplicateDocument), variables));
    },
    CreateCourseAssessment(variables: CreateCourseAssessmentMutationVariables): Promise<CreateCourseAssessmentMutation> {
      return withWrapper(() => client.request<CreateCourseAssessmentMutation>(print(CreateCourseAssessmentDocument), variables));
    },
    CreateCourseAssessmentDuplicate(variables: CreateCourseAssessmentDuplicateMutationVariables): Promise<CreateCourseAssessmentDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseAssessmentDuplicateMutation>(print(CreateCourseAssessmentDuplicateDocument), variables));
    },
    CreateCourseAttachment(variables: CreateCourseAttachmentMutationVariables): Promise<CreateCourseAttachmentMutation> {
      return withWrapper(() => client.request<CreateCourseAttachmentMutation>(print(CreateCourseAttachmentDocument), variables));
    },
    CreateCourseAttachmentDuplicate(variables: CreateCourseAttachmentDuplicateMutationVariables): Promise<CreateCourseAttachmentDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseAttachmentDuplicateMutation>(print(CreateCourseAttachmentDuplicateDocument), variables));
    },
    CreateCourseCPDUnitReward(variables: CreateCourseCpdUnitRewardMutationVariables): Promise<CreateCourseCpdUnitRewardMutation> {
      return withWrapper(() => client.request<CreateCourseCpdUnitRewardMutation>(print(CreateCourseCpdUnitRewardDocument), variables));
    },
    CreateCourseCertificate(variables: CreateCourseCertificateMutationVariables): Promise<CreateCourseCertificateMutation> {
      return withWrapper(() => client.request<CreateCourseCertificateMutation>(print(CreateCourseCertificateDocument), variables));
    },
    CreateCourseDiscount(variables: CreateCourseDiscountMutationVariables): Promise<CreateCourseDiscountMutation> {
      return withWrapper(() => client.request<CreateCourseDiscountMutation>(print(CreateCourseDiscountDocument), variables));
    },
    CreateCourseForm(variables: CreateCourseFormMutationVariables): Promise<CreateCourseFormMutation> {
      return withWrapper(() => client.request<CreateCourseFormMutation>(print(CreateCourseFormDocument), variables));
    },
    CreateCourseFormDuplicate(variables: CreateCourseFormDuplicateMutationVariables): Promise<CreateCourseFormDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseFormDuplicateMutation>(print(CreateCourseFormDuplicateDocument), variables));
    },
    CreateCourseGain(variables: CreateCourseGainMutationVariables): Promise<CreateCourseGainMutation> {
      return withWrapper(() => client.request<CreateCourseGainMutation>(print(CreateCourseGainDocument), variables));
    },
    CreateCourseInstructor(variables: CreateCourseInstructorMutationVariables): Promise<CreateCourseInstructorMutation> {
      return withWrapper(() => client.request<CreateCourseInstructorMutation>(print(CreateCourseInstructorDocument), variables));
    },
    CreateCourseInvite(variables: CreateCourseInviteMutationVariables): Promise<CreateCourseInviteMutation> {
      return withWrapper(() => client.request<CreateCourseInviteMutation>(print(CreateCourseInviteDocument), variables));
    },
    CreateCourseInvites(variables: CreateCourseInvitesMutationVariables): Promise<CreateCourseInvitesMutation> {
      return withWrapper(() => client.request<CreateCourseInvitesMutation>(print(CreateCourseInvitesDocument), variables));
    },
    CreateCourseLecturer(variables: CreateCourseLecturerMutationVariables): Promise<CreateCourseLecturerMutation> {
      return withWrapper(() => client.request<CreateCourseLecturerMutation>(print(CreateCourseLecturerDocument), variables));
    },
    CreateCourseLiveSession(variables: CreateCourseLiveSessionMutationVariables): Promise<CreateCourseLiveSessionMutation> {
      return withWrapper(() => client.request<CreateCourseLiveSessionMutation>(print(CreateCourseLiveSessionDocument), variables));
    },
    CreateCourseLiveSessionDuplicate(variables: CreateCourseLiveSessionDuplicateMutationVariables): Promise<CreateCourseLiveSessionDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseLiveSessionDuplicateMutation>(print(CreateCourseLiveSessionDuplicateDocument), variables));
    },
    CreateCourseModule(variables: CreateCourseModuleMutationVariables): Promise<CreateCourseModuleMutation> {
      return withWrapper(() => client.request<CreateCourseModuleMutation>(print(CreateCourseModuleDocument), variables));
    },
    CreateCourseModuleItemComment(variables: CreateCourseModuleItemCommentMutationVariables): Promise<CreateCourseModuleItemCommentMutation> {
      return withWrapper(() => client.request<CreateCourseModuleItemCommentMutation>(print(CreateCourseModuleItemCommentDocument), variables));
    },
    CreateCourseObjective(variables: CreateCourseObjectiveMutationVariables): Promise<CreateCourseObjectiveMutation> {
      return withWrapper(() => client.request<CreateCourseObjectiveMutation>(print(CreateCourseObjectiveDocument), variables));
    },
    CreateCourseOtherReward(variables: CreateCourseOtherRewardMutationVariables): Promise<CreateCourseOtherRewardMutation> {
      return withWrapper(() => client.request<CreateCourseOtherRewardMutation>(print(CreateCourseOtherRewardDocument), variables));
    },
    CreateCoursePaymentRequest(variables: CreateCoursePaymentRequestMutationVariables): Promise<CreateCoursePaymentRequestMutation> {
      return withWrapper(() => client.request<CreateCoursePaymentRequestMutation>(print(CreateCoursePaymentRequestDocument), variables));
    },
    CreateCourseRankingReward(variables: CreateCourseRankingRewardMutationVariables): Promise<CreateCourseRankingRewardMutation> {
      return withWrapper(() => client.request<CreateCourseRankingRewardMutation>(print(CreateCourseRankingRewardDocument), variables));
    },
    CreateCourseReminder(variables: CreateCourseReminderMutationVariables): Promise<CreateCourseReminderMutation> {
      return withWrapper(() => client.request<CreateCourseReminderMutation>(print(CreateCourseReminderDocument), variables));
    },
    CreateCourseRequirement(variables: CreateCourseRequirementMutationVariables): Promise<CreateCourseRequirementMutation> {
      return withWrapper(() => client.request<CreateCourseRequirementMutation>(print(CreateCourseRequirementDocument), variables));
    },
    CreateCourseSignatories(variables: CreateCourseSignatoriesMutationVariables): Promise<CreateCourseSignatoriesMutation> {
      return withWrapper(() => client.request<CreateCourseSignatoriesMutation>(print(CreateCourseSignatoriesDocument), variables));
    },
    CreateCourseVideo(variables: CreateCourseVideoMutationVariables): Promise<CreateCourseVideoMutation> {
      return withWrapper(() => client.request<CreateCourseVideoMutation>(print(CreateCourseVideoDocument), variables));
    },
    CreateCourseVideoDuplicate(variables: CreateCourseVideoDuplicateMutationVariables): Promise<CreateCourseVideoDuplicateMutation> {
      return withWrapper(() => client.request<CreateCourseVideoDuplicateMutation>(print(CreateCourseVideoDuplicateDocument), variables));
    },
    CreateForm(variables: CreateFormMutationVariables): Promise<CreateFormMutation> {
      return withWrapper(() => client.request<CreateFormMutation>(print(CreateFormDocument), variables));
    },
    CreateFormQuestion(variables: CreateFormQuestionMutationVariables): Promise<CreateFormQuestionMutation> {
      return withWrapper(() => client.request<CreateFormQuestionMutation>(print(CreateFormQuestionDocument), variables));
    },
    CreateFormQuestionAnswer(variables: CreateFormQuestionAnswerMutationVariables): Promise<CreateFormQuestionAnswerMutation> {
      return withWrapper(() => client.request<CreateFormQuestionAnswerMutation>(print(CreateFormQuestionAnswerDocument), variables));
    },
    CreateFormQuestionSubmission(variables: CreateFormQuestionSubmissionMutationVariables): Promise<CreateFormQuestionSubmissionMutation> {
      return withWrapper(() => client.request<CreateFormQuestionSubmissionMutation>(print(CreateFormQuestionSubmissionDocument), variables));
    },
    CreateFormSubmission(variables: CreateFormSubmissionMutationVariables): Promise<CreateFormSubmissionMutation> {
      return withWrapper(() => client.request<CreateFormSubmissionMutation>(print(CreateFormSubmissionDocument), variables));
    },
    CreateManagingUser(variables: CreateManagingUserMutationVariables): Promise<CreateManagingUserMutation> {
      return withWrapper(() => client.request<CreateManagingUserMutation>(print(CreateManagingUserDocument), variables));
    },
    CreateProvider(variables: CreateProviderMutationVariables): Promise<CreateProviderMutation> {
      return withWrapper(() => client.request<CreateProviderMutation>(print(CreateProviderDocument), variables));
    },
    CreateProviderDuplicate(variables: CreateProviderDuplicateMutationVariables): Promise<CreateProviderDuplicateMutation> {
      return withWrapper(() => client.request<CreateProviderDuplicateMutation>(print(CreateProviderDuplicateDocument), variables));
    },
    CreateSignatory(variables: CreateSignatoryMutationVariables): Promise<CreateSignatoryMutation> {
      return withWrapper(() => client.request<CreateSignatoryMutation>(print(CreateSignatoryDocument), variables));
    },
    CreateSpeaker(variables: CreateSpeakerMutationVariables): Promise<CreateSpeakerMutation> {
      return withWrapper(() => client.request<CreateSpeakerMutation>(print(CreateSpeakerDocument), variables));
    },
    CreateStudentProgess(variables: CreateStudentProgessMutationVariables): Promise<CreateStudentProgessMutation> {
      return withWrapper(() => client.request<CreateStudentProgessMutation>(print(CreateStudentProgessDocument), variables));
    },
    CreateUploadURLToBunny(variables: CreateUploadUrlToBunnyMutationVariables): Promise<CreateUploadUrlToBunnyMutation> {
      return withWrapper(() => client.request<CreateUploadUrlToBunnyMutation>(print(CreateUploadUrlToBunnyDocument), variables));
    },
    CreateUser(variables: CreateUserMutationVariables): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(print(CreateUserDocument), variables));
    },
    CreateUserAttendanceLog(variables: CreateUserAttendanceLogMutationVariables): Promise<CreateUserAttendanceLogMutation> {
      return withWrapper(() => client.request<CreateUserAttendanceLogMutation>(print(CreateUserAttendanceLogDocument), variables));
    },
    CreateUserCertificates(variables: CreateUserCertificatesMutationVariables): Promise<CreateUserCertificatesMutation> {
      return withWrapper(() => client.request<CreateUserCertificatesMutation>(print(CreateUserCertificatesDocument), variables));
    },
    DeleteCertificateSignatoryById(variables: DeleteCertificateSignatoryByIdMutationVariables): Promise<DeleteCertificateSignatoryByIdMutation> {
      return withWrapper(() => client.request<DeleteCertificateSignatoryByIdMutation>(print(DeleteCertificateSignatoryByIdDocument), variables));
    },
    DeleteCourseById(variables: DeleteCourseByIdMutationVariables): Promise<DeleteCourseByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseByIdMutation>(print(DeleteCourseByIdDocument), variables));
    },
    DeleteCourseCertificateById(variables: DeleteCourseCertificateByIdMutationVariables): Promise<DeleteCourseCertificateByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseCertificateByIdMutation>(print(DeleteCourseCertificateByIdDocument), variables));
    },
    DeleteCourseDiscountById(variables: DeleteCourseDiscountByIdMutationVariables): Promise<DeleteCourseDiscountByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseDiscountByIdMutation>(print(DeleteCourseDiscountByIdDocument), variables));
    },
    DeleteCourseGainById(variables: DeleteCourseGainByIdMutationVariables): Promise<DeleteCourseGainByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseGainByIdMutation>(print(DeleteCourseGainByIdDocument), variables));
    },
    DeleteCourseInviteById(variables: DeleteCourseInviteByIdMutationVariables): Promise<DeleteCourseInviteByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseInviteByIdMutation>(print(DeleteCourseInviteByIdDocument), variables));
    },
    DeleteCourseLecturerById(variables: DeleteCourseLecturerByIdMutationVariables): Promise<DeleteCourseLecturerByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseLecturerByIdMutation>(print(DeleteCourseLecturerByIdDocument), variables));
    },
    DeleteCourseModuleById(variables: DeleteCourseModuleByIdMutationVariables): Promise<DeleteCourseModuleByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseModuleByIdMutation>(print(DeleteCourseModuleByIdDocument), variables));
    },
    DeleteCourseModuleItemById(variables: DeleteCourseModuleItemByIdMutationVariables): Promise<DeleteCourseModuleItemByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseModuleItemByIdMutation>(print(DeleteCourseModuleItemByIdDocument), variables));
    },
    DeleteCourseObjectiveById(variables: DeleteCourseObjectiveByIdMutationVariables): Promise<DeleteCourseObjectiveByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseObjectiveByIdMutation>(print(DeleteCourseObjectiveByIdDocument), variables));
    },
    DeleteCourseRequirementById(variables: DeleteCourseRequirementByIdMutationVariables): Promise<DeleteCourseRequirementByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseRequirementByIdMutation>(print(DeleteCourseRequirementByIdDocument), variables));
    },
    DeleteCourseRewardById(variables: DeleteCourseRewardByIdMutationVariables): Promise<DeleteCourseRewardByIdMutation> {
      return withWrapper(() => client.request<DeleteCourseRewardByIdMutation>(print(DeleteCourseRewardByIdDocument), variables));
    },
    DeleteFormById(variables: DeleteFormByIdMutationVariables): Promise<DeleteFormByIdMutation> {
      return withWrapper(() => client.request<DeleteFormByIdMutation>(print(DeleteFormByIdDocument), variables));
    },
    DeleteFormQuestionAnswerById(variables: DeleteFormQuestionAnswerByIdMutationVariables): Promise<DeleteFormQuestionAnswerByIdMutation> {
      return withWrapper(() => client.request<DeleteFormQuestionAnswerByIdMutation>(print(DeleteFormQuestionAnswerByIdDocument), variables));
    },
    DeleteFormQuestionById(variables: DeleteFormQuestionByIdMutationVariables): Promise<DeleteFormQuestionByIdMutation> {
      return withWrapper(() => client.request<DeleteFormQuestionByIdMutation>(print(DeleteFormQuestionByIdDocument), variables));
    },
    DeleteManagingUserById(variables: DeleteManagingUserByIdMutationVariables): Promise<DeleteManagingUserByIdMutation> {
      return withWrapper(() => client.request<DeleteManagingUserByIdMutation>(print(DeleteManagingUserByIdDocument), variables));
    },
    DeleteProviderById(variables: DeleteProviderByIdMutationVariables): Promise<DeleteProviderByIdMutation> {
      return withWrapper(() => client.request<DeleteProviderByIdMutation>(print(DeleteProviderByIdDocument), variables));
    },
    DeleteSignatoryById(variables: DeleteSignatoryByIdMutationVariables): Promise<DeleteSignatoryByIdMutation> {
      return withWrapper(() => client.request<DeleteSignatoryByIdMutation>(print(DeleteSignatoryByIdDocument), variables));
    },
    DeleteUserCertificateById(variables: DeleteUserCertificateByIdMutationVariables): Promise<DeleteUserCertificateByIdMutation> {
      return withWrapper(() => client.request<DeleteUserCertificateByIdMutation>(print(DeleteUserCertificateByIdDocument), variables));
    },
    GetCertificatePageGSSP(variables: GetCertificatePageGsspQueryVariables): Promise<GetCertificatePageGsspQuery> {
      return withWrapper(() => client.request<GetCertificatePageGsspQuery>(print(GetCertificatePageGsspDocument), variables));
    },
    GetCoursePageGSSP(variables: GetCoursePageGsspQueryVariables): Promise<GetCoursePageGsspQuery> {
      return withWrapper(() => client.request<GetCoursePageGsspQuery>(print(GetCoursePageGsspDocument), variables));
    },
    GetCourseStudentPageGSSP(variables: GetCourseStudentPageGsspQueryVariables): Promise<GetCourseStudentPageGsspQuery> {
      return withWrapper(() => client.request<GetCourseStudentPageGsspQuery>(print(GetCourseStudentPageGsspDocument), variables));
    },
    GetCourseTransactionPageGSSP(variables: GetCourseTransactionPageGsspQueryVariables): Promise<GetCourseTransactionPageGsspQuery> {
      return withWrapper(() => client.request<GetCourseTransactionPageGsspQuery>(print(GetCourseTransactionPageGsspDocument), variables));
    },
    GetDiscoverPageGSSP(variables?: GetDiscoverPageGsspQueryVariables): Promise<GetDiscoverPageGsspQuery> {
      return withWrapper(() => client.request<GetDiscoverPageGsspQuery>(print(GetDiscoverPageGsspDocument), variables));
    },
    GetProfilePageGSSP(variables: GetProfilePageGsspQueryVariables): Promise<GetProfilePageGsspQuery> {
      return withWrapper(() => client.request<GetProfilePageGsspQuery>(print(GetProfilePageGsspDocument), variables));
    },
    GetProviderPageGSSP(variables: GetProviderPageGsspQueryVariables): Promise<GetProviderPageGsspQuery> {
      return withWrapper(() => client.request<GetProviderPageGsspQuery>(print(GetProviderPageGsspDocument), variables));
    },
    GetSearchPageGSSP(variables?: GetSearchPageGsspQueryVariables): Promise<GetSearchPageGsspQuery> {
      return withWrapper(() => client.request<GetSearchPageGsspQuery>(print(GetSearchPageGsspDocument), variables));
    },
    GetActiveCourseStudent(variables: GetActiveCourseStudentQueryVariables): Promise<GetActiveCourseStudentQuery> {
      return withWrapper(() => client.request<GetActiveCourseStudentQuery>(print(GetActiveCourseStudentDocument), variables));
    },
    GetAttendanceLogs(variables: GetAttendanceLogsQueryVariables): Promise<GetAttendanceLogsQuery> {
      return withWrapper(() => client.request<GetAttendanceLogsQuery>(print(GetAttendanceLogsDocument), variables));
    },
    GetAttendanceLogsByUserId(variables: GetAttendanceLogsByUserIdQueryVariables): Promise<GetAttendanceLogsByUserIdQuery> {
      return withWrapper(() => client.request<GetAttendanceLogsByUserIdQuery>(print(GetAttendanceLogsByUserIdDocument), variables));
    },
    GetAttendances(variables: GetAttendancesQueryVariables): Promise<GetAttendancesQuery> {
      return withWrapper(() => client.request<GetAttendancesQuery>(print(GetAttendancesDocument), variables));
    },
    GetBasicUserDetailsByEmail(variables: GetBasicUserDetailsByEmailQueryVariables): Promise<GetBasicUserDetailsByEmailQuery> {
      return withWrapper(() => client.request<GetBasicUserDetailsByEmailQuery>(print(GetBasicUserDetailsByEmailDocument), variables));
    },
    GetBasicUserDetailsByIDs(variables: GetBasicUserDetailsByIDsQueryVariables): Promise<GetBasicUserDetailsByIDsQuery> {
      return withWrapper(() => client.request<GetBasicUserDetailsByIDsQuery>(print(GetBasicUserDetailsByIDsDocument), variables));
    },
    GetBunnyUploadDetails(variables: GetBunnyUploadDetailsQueryVariables): Promise<GetBunnyUploadDetailsQuery> {
      return withWrapper(() => client.request<GetBunnyUploadDetailsQuery>(print(GetBunnyUploadDetailsDocument), variables));
    },
    GetCourseArticleById(variables: GetCourseArticleByIdQueryVariables): Promise<GetCourseArticleByIdQuery> {
      return withWrapper(() => client.request<GetCourseArticleByIdQuery>(print(GetCourseArticleByIdDocument), variables));
    },
    GetCourseAssessmentById(variables: GetCourseAssessmentByIdQueryVariables): Promise<GetCourseAssessmentByIdQuery> {
      return withWrapper(() => client.request<GetCourseAssessmentByIdQuery>(print(GetCourseAssessmentByIdDocument), variables));
    },
    GetCourseById(variables: GetCourseByIdQueryVariables): Promise<GetCourseByIdQuery> {
      return withWrapper(() => client.request<GetCourseByIdQuery>(print(GetCourseByIdDocument), variables));
    },
    GetCourseCertificateByCourseId(variables: GetCourseCertificateByCourseIdQueryVariables): Promise<GetCourseCertificateByCourseIdQuery> {
      return withWrapper(() => client.request<GetCourseCertificateByCourseIdQuery>(print(GetCourseCertificateByCourseIdDocument), variables));
    },
    GetCourseCertificateById(variables: GetCourseCertificateByIdQueryVariables): Promise<GetCourseCertificateByIdQuery> {
      return withWrapper(() => client.request<GetCourseCertificateByIdQuery>(print(GetCourseCertificateByIdDocument), variables));
    },
    GetCourseContent(variables: GetCourseContentQueryVariables): Promise<GetCourseContentQuery> {
      return withWrapper(() => client.request<GetCourseContentQuery>(print(GetCourseContentDocument), variables));
    },
    GetCourseCourseOrder(variables: GetCourseCourseOrderQueryVariables): Promise<GetCourseCourseOrderQuery> {
      return withWrapper(() => client.request<GetCourseCourseOrderQuery>(print(GetCourseCourseOrderDocument), variables));
    },
    GetCourseDiscount(variables: GetCourseDiscountQueryVariables): Promise<GetCourseDiscountQuery> {
      return withWrapper(() => client.request<GetCourseDiscountQuery>(print(GetCourseDiscountDocument), variables));
    },
    GetCourseFormById(variables: GetCourseFormByIdQueryVariables): Promise<GetCourseFormByIdQuery> {
      return withWrapper(() => client.request<GetCourseFormByIdQuery>(print(GetCourseFormByIdDocument), variables));
    },
    GetCourseFormManagementById(variables: GetCourseFormManagementByIdQueryVariables): Promise<GetCourseFormManagementByIdQuery> {
      return withWrapper(() => client.request<GetCourseFormManagementByIdQuery>(print(GetCourseFormManagementByIdDocument), variables));
    },
    GetCourseFormsByCourseId(variables: GetCourseFormsByCourseIdQueryVariables): Promise<GetCourseFormsByCourseIdQuery> {
      return withWrapper(() => client.request<GetCourseFormsByCourseIdQuery>(print(GetCourseFormsByCourseIdDocument), variables));
    },
    GetCourseInstructorByCourseId(variables: GetCourseInstructorByCourseIdQueryVariables): Promise<GetCourseInstructorByCourseIdQuery> {
      return withWrapper(() => client.request<GetCourseInstructorByCourseIdQuery>(print(GetCourseInstructorByCourseIdDocument), variables));
    },
    GetCourseInstructorCheck(variables: GetCourseInstructorCheckQueryVariables): Promise<GetCourseInstructorCheckQuery> {
      return withWrapper(() => client.request<GetCourseInstructorCheckQuery>(print(GetCourseInstructorCheckDocument), variables));
    },
    GetCourseInstructorCourses(variables: GetCourseInstructorCoursesQueryVariables): Promise<GetCourseInstructorCoursesQuery> {
      return withWrapper(() => client.request<GetCourseInstructorCoursesQuery>(print(GetCourseInstructorCoursesDocument), variables));
    },
    GetCourseInviteById(variables: GetCourseInviteByIdQueryVariables): Promise<GetCourseInviteByIdQuery> {
      return withWrapper(() => client.request<GetCourseInviteByIdQuery>(print(GetCourseInviteByIdDocument), variables));
    },
    GetCourseInvites(variables: GetCourseInvitesQueryVariables): Promise<GetCourseInvitesQuery> {
      return withWrapper(() => client.request<GetCourseInvitesQuery>(print(GetCourseInvitesDocument), variables));
    },
    GetCourseLiveSessionById(variables: GetCourseLiveSessionByIdQueryVariables): Promise<GetCourseLiveSessionByIdQuery> {
      return withWrapper(() => client.request<GetCourseLiveSessionByIdQuery>(print(GetCourseLiveSessionByIdDocument), variables));
    },
    GetCourseModuleItemById(variables: GetCourseModuleItemByIdQueryVariables): Promise<GetCourseModuleItemByIdQuery> {
      return withWrapper(() => client.request<GetCourseModuleItemByIdQuery>(print(GetCourseModuleItemByIdDocument), variables));
    },
    GetCourseModuleItemComments(variables: GetCourseModuleItemCommentsQueryVariables): Promise<GetCourseModuleItemCommentsQuery> {
      return withWrapper(() => client.request<GetCourseModuleItemCommentsQuery>(print(GetCourseModuleItemCommentsDocument), variables));
    },
    GetCourseModules(variables: GetCourseModulesQueryVariables): Promise<GetCourseModulesQuery> {
      return withWrapper(() => client.request<GetCourseModulesQuery>(print(GetCourseModulesDocument), variables));
    },
    GetCourseModulesByCourseID(variables: GetCourseModulesByCourseIdQueryVariables): Promise<GetCourseModulesByCourseIdQuery> {
      return withWrapper(() => client.request<GetCourseModulesByCourseIdQuery>(print(GetCourseModulesByCourseIdDocument), variables));
    },
    GetCourseOrders(variables: GetCourseOrdersQueryVariables): Promise<GetCourseOrdersQuery> {
      return withWrapper(() => client.request<GetCourseOrdersQuery>(print(GetCourseOrdersDocument), variables));
    },
    GetCoursePDFById(variables: GetCoursePdfByIdQueryVariables): Promise<GetCoursePdfByIdQuery> {
      return withWrapper(() => client.request<GetCoursePdfByIdQuery>(print(GetCoursePdfByIdDocument), variables));
    },
    GetCourseSpeakerByUserId(variables: GetCourseSpeakerByUserIdQueryVariables): Promise<GetCourseSpeakerByUserIdQuery> {
      return withWrapper(() => client.request<GetCourseSpeakerByUserIdQuery>(print(GetCourseSpeakerByUserIdDocument), variables));
    },
    GetCourseStudent(variables?: GetCourseStudentQueryVariables): Promise<GetCourseStudentQuery> {
      return withWrapper(() => client.request<GetCourseStudentQuery>(print(GetCourseStudentDocument), variables));
    },
    GetCourseStudentById(variables: GetCourseStudentByIdQueryVariables): Promise<GetCourseStudentByIdQuery> {
      return withWrapper(() => client.request<GetCourseStudentByIdQuery>(print(GetCourseStudentByIdDocument), variables));
    },
    GetCourseStudentDetailsByIDs(variables: GetCourseStudentDetailsByIDsQueryVariables): Promise<GetCourseStudentDetailsByIDsQuery> {
      return withWrapper(() => client.request<GetCourseStudentDetailsByIDsQuery>(print(GetCourseStudentDetailsByIDsDocument), variables));
    },
    GetCourseStudentProgress(variables?: GetCourseStudentProgressQueryVariables): Promise<GetCourseStudentProgressQuery> {
      return withWrapper(() => client.request<GetCourseStudentProgressQuery>(print(GetCourseStudentProgressDocument), variables));
    },
    GetCourseTransaction(variables: GetCourseTransactionQueryVariables): Promise<GetCourseTransactionQuery> {
      return withWrapper(() => client.request<GetCourseTransactionQuery>(print(GetCourseTransactionDocument), variables));
    },
    GetCourseVideoById(variables: GetCourseVideoByIdQueryVariables): Promise<GetCourseVideoByIdQuery> {
      return withWrapper(() => client.request<GetCourseVideoByIdQuery>(print(GetCourseVideoByIdDocument), variables));
    },
    GetCourseVideoStatus(variables: GetCourseVideoStatusQueryVariables): Promise<GetCourseVideoStatusQuery> {
      return withWrapper(() => client.request<GetCourseVideoStatusQuery>(print(GetCourseVideoStatusDocument), variables));
    },
    GetDiscountUserUsage(variables: GetDiscountUserUsageQueryVariables): Promise<GetDiscountUserUsageQuery> {
      return withWrapper(() => client.request<GetDiscountUserUsageQuery>(print(GetDiscountUserUsageDocument), variables));
    },
    GetEventById(variables: GetEventByIdQueryVariables): Promise<GetEventByIdQuery> {
      return withWrapper(() => client.request<GetEventByIdQuery>(print(GetEventByIdDocument), variables));
    },
    GetEventsByMonth(variables?: GetEventsByMonthQueryVariables): Promise<GetEventsByMonthQuery> {
      return withWrapper(() => client.request<GetEventsByMonthQuery>(print(GetEventsByMonthDocument), variables));
    },
    GetEventsLiveNow(variables?: GetEventsLiveNowQueryVariables): Promise<GetEventsLiveNowQuery> {
      return withWrapper(() => client.request<GetEventsLiveNowQuery>(print(GetEventsLiveNowDocument), variables));
    },
    GetFormById(variables: GetFormByIdQueryVariables): Promise<GetFormByIdQuery> {
      return withWrapper(() => client.request<GetFormByIdQuery>(print(GetFormByIdDocument), variables));
    },
    GetFormQuestionAnswerById(variables: GetFormQuestionAnswerByIdQueryVariables): Promise<GetFormQuestionAnswerByIdQuery> {
      return withWrapper(() => client.request<GetFormQuestionAnswerByIdQuery>(print(GetFormQuestionAnswerByIdDocument), variables));
    },
    GetFormQuestionAnswers(variables: GetFormQuestionAnswersQueryVariables): Promise<GetFormQuestionAnswersQuery> {
      return withWrapper(() => client.request<GetFormQuestionAnswersQuery>(print(GetFormQuestionAnswersDocument), variables));
    },
    GetFormQuestionAnswersForStudents(variables: GetFormQuestionAnswersForStudentsQueryVariables): Promise<GetFormQuestionAnswersForStudentsQuery> {
      return withWrapper(() => client.request<GetFormQuestionAnswersForStudentsQuery>(print(GetFormQuestionAnswersForStudentsDocument), variables));
    },
    GetFormQuestionById(variables: GetFormQuestionByIdQueryVariables): Promise<GetFormQuestionByIdQuery> {
      return withWrapper(() => client.request<GetFormQuestionByIdQuery>(print(GetFormQuestionByIdDocument), variables));
    },
    GetFormQuestionSubmission(variables: GetFormQuestionSubmissionQueryVariables): Promise<GetFormQuestionSubmissionQuery> {
      return withWrapper(() => client.request<GetFormQuestionSubmissionQuery>(print(GetFormQuestionSubmissionDocument), variables));
    },
    GetFormQuestionSubmissionsByCourseForm(variables: GetFormQuestionSubmissionsByCourseFormQueryVariables): Promise<GetFormQuestionSubmissionsByCourseFormQuery> {
      return withWrapper(() => client.request<GetFormQuestionSubmissionsByCourseFormQuery>(print(GetFormQuestionSubmissionsByCourseFormDocument), variables));
    },
    GetFormQuestionSubmissionsByUser(variables: GetFormQuestionSubmissionsByUserQueryVariables): Promise<GetFormQuestionSubmissionsByUserQuery> {
      return withWrapper(() => client.request<GetFormQuestionSubmissionsByUserQuery>(print(GetFormQuestionSubmissionsByUserDocument), variables));
    },
    GetFormQuestions(variables: GetFormQuestionsQueryVariables): Promise<GetFormQuestionsQuery> {
      return withWrapper(() => client.request<GetFormQuestionsQuery>(print(GetFormQuestionsDocument), variables));
    },
    GetFormSubmissionDetails(variables: GetFormSubmissionDetailsQueryVariables): Promise<GetFormSubmissionDetailsQuery> {
      return withWrapper(() => client.request<GetFormSubmissionDetailsQuery>(print(GetFormSubmissionDetailsDocument), variables));
    },
    GetFormSubmissionQuestions(variables: GetFormSubmissionQuestionsQueryVariables): Promise<GetFormSubmissionQuestionsQuery> {
      return withWrapper(() => client.request<GetFormSubmissionQuestionsQuery>(print(GetFormSubmissionQuestionsDocument), variables));
    },
    GetFormSubmissions(variables: GetFormSubmissionsQueryVariables): Promise<GetFormSubmissionsQuery> {
      return withWrapper(() => client.request<GetFormSubmissionsQuery>(print(GetFormSubmissionsDocument), variables));
    },
    GetFormSubmissionsByCourseForm(variables: GetFormSubmissionsByCourseFormQueryVariables): Promise<GetFormSubmissionsByCourseFormQuery> {
      return withWrapper(() => client.request<GetFormSubmissionsByCourseFormQuery>(print(GetFormSubmissionsByCourseFormDocument), variables));
    },
    GetFormSubmissionsByUser(variables: GetFormSubmissionsByUserQueryVariables): Promise<GetFormSubmissionsByUserQuery> {
      return withWrapper(() => client.request<GetFormSubmissionsByUserQuery>(print(GetFormSubmissionsByUserDocument), variables));
    },
    GetIndustries(variables?: GetIndustriesQueryVariables): Promise<GetIndustriesQuery> {
      return withWrapper(() => client.request<GetIndustriesQuery>(print(GetIndustriesDocument), variables));
    },
    GetJoinSessionDetails(variables: GetJoinSessionDetailsQueryVariables): Promise<GetJoinSessionDetailsQuery> {
      return withWrapper(() => client.request<GetJoinSessionDetailsQuery>(print(GetJoinSessionDetailsDocument), variables));
    },
    GetLiveSessionsByCourseId(variables: GetLiveSessionsByCourseIdQueryVariables): Promise<GetLiveSessionsByCourseIdQuery> {
      return withWrapper(() => client.request<GetLiveSessionsByCourseIdQuery>(print(GetLiveSessionsByCourseIdDocument), variables));
    },
    GetModuleItemCompleteProgress(variables: GetModuleItemCompleteProgressQueryVariables): Promise<GetModuleItemCompleteProgressQuery> {
      return withWrapper(() => client.request<GetModuleItemCompleteProgressQuery>(print(GetModuleItemCompleteProgressDocument), variables));
    },
    getOrdersByUser(variables?: GetOrdersByUserQueryVariables): Promise<GetOrdersByUserQuery> {
      return withWrapper(() => client.request<GetOrdersByUserQuery>(print(GetOrdersByUserDocument), variables));
    },
    GetProvider(variables: GetProviderQueryVariables): Promise<GetProviderQuery> {
      return withWrapper(() => client.request<GetProviderQuery>(print(GetProviderDocument), variables));
    },
    GetProviderByName(variables: GetProviderByNameQueryVariables): Promise<GetProviderByNameQuery> {
      return withWrapper(() => client.request<GetProviderByNameQuery>(print(GetProviderByNameDocument), variables));
    },
    GetProviderCourseOrder(variables: GetProviderCourseOrderQueryVariables): Promise<GetProviderCourseOrderQuery> {
      return withWrapper(() => client.request<GetProviderCourseOrderQuery>(print(GetProviderCourseOrderDocument), variables));
    },
    GetProviderCourses(variables: GetProviderCoursesQueryVariables): Promise<GetProviderCoursesQuery> {
      return withWrapper(() => client.request<GetProviderCoursesQuery>(print(GetProviderCoursesDocument), variables));
    },
    GetProviderDiscounts(variables: GetProviderDiscountsQueryVariables): Promise<GetProviderDiscountsQuery> {
      return withWrapper(() => client.request<GetProviderDiscountsQuery>(print(GetProviderDiscountsDocument), variables));
    },
    GetProviderForDuplicate(variables: GetProviderForDuplicateQueryVariables): Promise<GetProviderForDuplicateQuery> {
      return withWrapper(() => client.request<GetProviderForDuplicateQuery>(print(GetProviderForDuplicateDocument), variables));
    },
    GetProviderForms(variables: GetProviderFormsQueryVariables): Promise<GetProviderFormsQuery> {
      return withWrapper(() => client.request<GetProviderFormsQuery>(print(GetProviderFormsDocument), variables));
    },
    GetProviderInstructorCheck(variables: GetProviderInstructorCheckQueryVariables): Promise<GetProviderInstructorCheckQuery> {
      return withWrapper(() => client.request<GetProviderInstructorCheckQuery>(print(GetProviderInstructorCheckDocument), variables));
    },
    GetProviderManagingUsersById(variables: GetProviderManagingUsersByIdQueryVariables): Promise<GetProviderManagingUsersByIdQuery> {
      return withWrapper(() => client.request<GetProviderManagingUsersByIdQuery>(print(GetProviderManagingUsersByIdDocument), variables));
    },
    GetProviderSignatories(variables: GetProviderSignatoriesQueryVariables): Promise<GetProviderSignatoriesQuery> {
      return withWrapper(() => client.request<GetProviderSignatoriesQuery>(print(GetProviderSignatoriesDocument), variables));
    },
    GetProviderStudentCount(variables: GetProviderStudentCountQueryVariables): Promise<GetProviderStudentCountQuery> {
      return withWrapper(() => client.request<GetProviderStudentCountQuery>(print(GetProviderStudentCountDocument), variables));
    },
    GetPublishedCourses(variables?: GetPublishedCoursesQueryVariables): Promise<GetPublishedCoursesQuery> {
      return withWrapper(() => client.request<GetPublishedCoursesQuery>(print(GetPublishedCoursesDocument), variables));
    },
    GetRevenueByCourseId(variables: GetRevenueByCourseIdQueryVariables): Promise<GetRevenueByCourseIdQuery> {
      return withWrapper(() => client.request<GetRevenueByCourseIdQuery>(print(GetRevenueByCourseIdDocument), variables));
    },
    GetRevenueByProductId(variables: GetRevenueByProductIdQueryVariables): Promise<GetRevenueByProductIdQuery> {
      return withWrapper(() => client.request<GetRevenueByProductIdQuery>(print(GetRevenueByProductIdDocument), variables));
    },
    GetStudentCourses(variables: GetStudentCoursesQueryVariables): Promise<GetStudentCoursesQuery> {
      return withWrapper(() => client.request<GetStudentCoursesQuery>(print(GetStudentCoursesDocument), variables));
    },
    GetStudentProgess(variables: GetStudentProgessQueryVariables): Promise<GetStudentProgessQuery> {
      return withWrapper(() => client.request<GetStudentProgessQuery>(print(GetStudentProgessDocument), variables));
    },
    GetUserAdminById(variables: GetUserAdminByIdQueryVariables): Promise<GetUserAdminByIdQuery> {
      return withWrapper(() => client.request<GetUserAdminByIdQuery>(print(GetUserAdminByIdDocument), variables));
    },
    GetUserByEmail(variables: GetUserByEmailQueryVariables): Promise<GetUserByEmailQuery> {
      return withWrapper(() => client.request<GetUserByEmailQuery>(print(GetUserByEmailDocument), variables));
    },
    GetUserById(variables: GetUserByIdQueryVariables): Promise<GetUserByIdQuery> {
      return withWrapper(() => client.request<GetUserByIdQuery>(print(GetUserByIdDocument), variables));
    },
    GetUserCertificateById(variables: GetUserCertificateByIdQueryVariables): Promise<GetUserCertificateByIdQuery> {
      return withWrapper(() => client.request<GetUserCertificateByIdQuery>(print(GetUserCertificateByIdDocument), variables));
    },
    GetUserCertificateByUserId(variables: GetUserCertificateByUserIdQueryVariables): Promise<GetUserCertificateByUserIdQuery> {
      return withWrapper(() => client.request<GetUserCertificateByUserIdQuery>(print(GetUserCertificateByUserIdDocument), variables));
    },
    GetUserCertificates(variables: GetUserCertificatesQueryVariables): Promise<GetUserCertificatesQuery> {
      return withWrapper(() => client.request<GetUserCertificatesQuery>(print(GetUserCertificatesDocument), variables));
    },
    GetUserFollowedIndustriesById(variables: GetUserFollowedIndustriesByIdQueryVariables): Promise<GetUserFollowedIndustriesByIdQuery> {
      return withWrapper(() => client.request<GetUserFollowedIndustriesByIdQuery>(print(GetUserFollowedIndustriesByIdDocument), variables));
    },
    GetUserInfos(variables: GetUserInfosQueryVariables): Promise<GetUserInfosQuery> {
      return withWrapper(() => client.request<GetUserInfosQuery>(print(GetUserInfosDocument), variables));
    },
    GetUserName(variables: GetUserNameQueryVariables): Promise<GetUserNameQuery> {
      return withWrapper(() => client.request<GetUserNameQuery>(print(GetUserNameDocument), variables));
    },
    GetUserNames(variables: GetUserNamesQueryVariables): Promise<GetUserNamesQuery> {
      return withWrapper(() => client.request<GetUserNamesQuery>(print(GetUserNamesDocument), variables));
    },
    GetUserProviderAccountById(variables: GetUserProviderAccountByIdQueryVariables): Promise<GetUserProviderAccountByIdQuery> {
      return withWrapper(() => client.request<GetUserProviderAccountByIdQuery>(print(GetUserProviderAccountByIdDocument), variables));
    },
    GetUserProviderSubscriptionsById(variables: GetUserProviderSubscriptionsByIdQueryVariables): Promise<GetUserProviderSubscriptionsByIdQuery> {
      return withWrapper(() => client.request<GetUserProviderSubscriptionsByIdQuery>(print(GetUserProviderSubscriptionsByIdDocument), variables));
    },
    InsertCourseModuleStudents(variables: InsertCourseModuleStudentsMutationVariables): Promise<InsertCourseModuleStudentsMutation> {
      return withWrapper(() => client.request<InsertCourseModuleStudentsMutation>(print(InsertCourseModuleStudentsDocument), variables));
    },
    InsertCourseStudent(variables: InsertCourseStudentMutationVariables): Promise<InsertCourseStudentMutation> {
      return withWrapper(() => client.request<InsertCourseStudentMutation>(print(InsertCourseStudentDocument), variables));
    },
    SearchCourse(variables?: SearchCourseQueryVariables): Promise<SearchCourseQuery> {
      return withWrapper(() => client.request<SearchCourseQuery>(print(SearchCourseDocument), variables));
    },
    SearchEvent(variables?: SearchEventQueryVariables): Promise<SearchEventQuery> {
      return withWrapper(() => client.request<SearchEventQuery>(print(SearchEventDocument), variables));
    },
    SearchProvider(variables?: SearchProviderQueryVariables): Promise<SearchProviderQuery> {
      return withWrapper(() => client.request<SearchProviderQuery>(print(SearchProviderDocument), variables));
    },
    UpdateCertificateSignatoriesPosition(variables: UpdateCertificateSignatoriesPositionMutationVariables): Promise<UpdateCertificateSignatoriesPositionMutation> {
      return withWrapper(() => client.request<UpdateCertificateSignatoriesPositionMutation>(print(UpdateCertificateSignatoriesPositionDocument), variables));
    },
    UpdateCommentVisibility(variables: UpdateCommentVisibilityMutationVariables): Promise<UpdateCommentVisibilityMutation> {
      return withWrapper(() => client.request<UpdateCommentVisibilityMutation>(print(UpdateCommentVisibilityDocument), variables));
    },
    UpdateCourse(variables: UpdateCourseMutationVariables): Promise<UpdateCourseMutation> {
      return withWrapper(() => client.request<UpdateCourseMutation>(print(UpdateCourseDocument), variables));
    },
    UpdateCourseArchiveStatus(variables: UpdateCourseArchiveStatusMutationVariables): Promise<UpdateCourseArchiveStatusMutation> {
      return withWrapper(() => client.request<UpdateCourseArchiveStatusMutation>(print(UpdateCourseArchiveStatusDocument), variables));
    },
    UpdateCourseArticleById(variables: UpdateCourseArticleByIdMutationVariables): Promise<UpdateCourseArticleByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseArticleByIdMutation>(print(UpdateCourseArticleByIdDocument), variables));
    },
    UpdateCourseAssessmentById(variables: UpdateCourseAssessmentByIdMutationVariables): Promise<UpdateCourseAssessmentByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseAssessmentByIdMutation>(print(UpdateCourseAssessmentByIdDocument), variables));
    },
    UpdateCourseCertificate(variables: UpdateCourseCertificateMutationVariables): Promise<UpdateCourseCertificateMutation> {
      return withWrapper(() => client.request<UpdateCourseCertificateMutation>(print(UpdateCourseCertificateDocument), variables));
    },
    UpdateCourseCommunity(variables: UpdateCourseCommunityMutationVariables): Promise<UpdateCourseCommunityMutation> {
      return withWrapper(() => client.request<UpdateCourseCommunityMutation>(print(UpdateCourseCommunityDocument), variables));
    },
    UpdateCourseFormById(variables: UpdateCourseFormByIdMutationVariables): Promise<UpdateCourseFormByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseFormByIdMutation>(print(UpdateCourseFormByIdDocument), variables));
    },
    UpdateCourseInstructorIsActive(variables: UpdateCourseInstructorIsActiveMutationVariables): Promise<UpdateCourseInstructorIsActiveMutation> {
      return withWrapper(() => client.request<UpdateCourseInstructorIsActiveMutation>(print(UpdateCourseInstructorIsActiveDocument), variables));
    },
    UpdateCourseInviteUsed(variables: UpdateCourseInviteUsedMutationVariables): Promise<UpdateCourseInviteUsedMutation> {
      return withWrapper(() => client.request<UpdateCourseInviteUsedMutation>(print(UpdateCourseInviteUsedDocument), variables));
    },
    UpdateCourseLiveSessionById(variables: UpdateCourseLiveSessionByIdMutationVariables): Promise<UpdateCourseLiveSessionByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseLiveSessionByIdMutation>(print(UpdateCourseLiveSessionByIdDocument), variables));
    },
    UpdateCourseModuleById(variables: UpdateCourseModuleByIdMutationVariables): Promise<UpdateCourseModuleByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleByIdMutation>(print(UpdateCourseModuleByIdDocument), variables));
    },
    UpdateCourseModuleItemCommentSection(variables: UpdateCourseModuleItemCommentSectionMutationVariables): Promise<UpdateCourseModuleItemCommentSectionMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleItemCommentSectionMutation>(print(UpdateCourseModuleItemCommentSectionDocument), variables));
    },
    UpdateCourseModuleItemFreeStatus(variables: UpdateCourseModuleItemFreeStatusMutationVariables): Promise<UpdateCourseModuleItemFreeStatusMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleItemFreeStatusMutation>(print(UpdateCourseModuleItemFreeStatusDocument), variables));
    },
    UpdateCourseModuleItemLockStatus(variables: UpdateCourseModuleItemLockStatusMutationVariables): Promise<UpdateCourseModuleItemLockStatusMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleItemLockStatusMutation>(print(UpdateCourseModuleItemLockStatusDocument), variables));
    },
    UpdateCourseModuleItemOrderById(variables: UpdateCourseModuleItemOrderByIdMutationVariables): Promise<UpdateCourseModuleItemOrderByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleItemOrderByIdMutation>(print(UpdateCourseModuleItemOrderByIdDocument), variables));
    },
    UpdateCourseModuleItemTitle(variables: UpdateCourseModuleItemTitleMutationVariables): Promise<UpdateCourseModuleItemTitleMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleItemTitleMutation>(print(UpdateCourseModuleItemTitleDocument), variables));
    },
    UpdateCourseModuleOrderById(variables: UpdateCourseModuleOrderByIdMutationVariables): Promise<UpdateCourseModuleOrderByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleOrderByIdMutation>(print(UpdateCourseModuleOrderByIdDocument), variables));
    },
    UpdateCourseModuleStudentStart(variables: UpdateCourseModuleStudentStartMutationVariables): Promise<UpdateCourseModuleStudentStartMutation> {
      return withWrapper(() => client.request<UpdateCourseModuleStudentStartMutation>(print(UpdateCourseModuleStudentStartDocument), variables));
    },
    UpdateCourseOrderAcknowledgement(variables: UpdateCourseOrderAcknowledgementMutationVariables): Promise<UpdateCourseOrderAcknowledgementMutation> {
      return withWrapper(() => client.request<UpdateCourseOrderAcknowledgementMutation>(print(UpdateCourseOrderAcknowledgementDocument), variables));
    },
    UpdateCourseOrderStatus(variables: UpdateCourseOrderStatusMutationVariables): Promise<UpdateCourseOrderStatusMutation> {
      return withWrapper(() => client.request<UpdateCourseOrderStatusMutation>(print(UpdateCourseOrderStatusDocument), variables));
    },
    UpdateCoursePDFById(variables: UpdateCoursePdfByIdMutationVariables): Promise<UpdateCoursePdfByIdMutation> {
      return withWrapper(() => client.request<UpdateCoursePdfByIdMutation>(print(UpdateCoursePdfByIdDocument), variables));
    },
    UpdateCoursePinnedStatus(variables: UpdateCoursePinnedStatusMutationVariables): Promise<UpdateCoursePinnedStatusMutation> {
      return withWrapper(() => client.request<UpdateCoursePinnedStatusMutation>(print(UpdateCoursePinnedStatusDocument), variables));
    },
    UpdateCoursePrivateStatus(variables: UpdateCoursePrivateStatusMutationVariables): Promise<UpdateCoursePrivateStatusMutation> {
      return withWrapper(() => client.request<UpdateCoursePrivateStatusMutation>(print(UpdateCoursePrivateStatusDocument), variables));
    },
    UpdateCoursePublishStatus(variables: UpdateCoursePublishStatusMutationVariables): Promise<UpdateCoursePublishStatusMutation> {
      return withWrapper(() => client.request<UpdateCoursePublishStatusMutation>(print(UpdateCoursePublishStatusDocument), variables));
    },
    UpdateCourseShowStudents(variables: UpdateCourseShowStudentsMutationVariables): Promise<UpdateCourseShowStudentsMutation> {
      return withWrapper(() => client.request<UpdateCourseShowStudentsMutation>(print(UpdateCourseShowStudentsDocument), variables));
    },
    UpdateCourseStudentProgressLockStatus(variables: UpdateCourseStudentProgressLockStatusMutationVariables): Promise<UpdateCourseStudentProgressLockStatusMutation> {
      return withWrapper(() => client.request<UpdateCourseStudentProgressLockStatusMutation>(print(UpdateCourseStudentProgressLockStatusDocument), variables));
    },
    UpdateCourseStudentStart(variables: UpdateCourseStudentStartMutationVariables): Promise<UpdateCourseStudentStartMutation> {
      return withWrapper(() => client.request<UpdateCourseStudentStartMutation>(print(UpdateCourseStudentStartDocument), variables));
    },
    UpdateCourseStudentState(variables: UpdateCourseStudentStateMutationVariables): Promise<UpdateCourseStudentStateMutation> {
      return withWrapper(() => client.request<UpdateCourseStudentStateMutation>(print(UpdateCourseStudentStateDocument), variables));
    },
    UpdateCourseVideoById(variables: UpdateCourseVideoByIdMutationVariables): Promise<UpdateCourseVideoByIdMutation> {
      return withWrapper(() => client.request<UpdateCourseVideoByIdMutation>(print(UpdateCourseVideoByIdDocument), variables));
    },
    UpdateCourseVideoPreview(variables: UpdateCourseVideoPreviewMutationVariables): Promise<UpdateCourseVideoPreviewMutation> {
      return withWrapper(() => client.request<UpdateCourseVideoPreviewMutation>(print(UpdateCourseVideoPreviewDocument), variables));
    },
    UpdateFormById(variables: UpdateFormByIdMutationVariables): Promise<UpdateFormByIdMutation> {
      return withWrapper(() => client.request<UpdateFormByIdMutation>(print(UpdateFormByIdDocument), variables));
    },
    UpdateFormQuestionById(variables: UpdateFormQuestionByIdMutationVariables): Promise<UpdateFormQuestionByIdMutation> {
      return withWrapper(() => client.request<UpdateFormQuestionByIdMutation>(print(UpdateFormQuestionByIdDocument), variables));
    },
    UpdateFormQuestionPositionById(variables: UpdateFormQuestionPositionByIdMutationVariables): Promise<UpdateFormQuestionPositionByIdMutation> {
      return withWrapper(() => client.request<UpdateFormQuestionPositionByIdMutation>(print(UpdateFormQuestionPositionByIdDocument), variables));
    },
    UpdateFormQuestionSubmission(variables: UpdateFormQuestionSubmissionMutationVariables): Promise<UpdateFormQuestionSubmissionMutation> {
      return withWrapper(() => client.request<UpdateFormQuestionSubmissionMutation>(print(UpdateFormQuestionSubmissionDocument), variables));
    },
    UpdateFormSubmissionFinish(variables: UpdateFormSubmissionFinishMutationVariables): Promise<UpdateFormSubmissionFinishMutation> {
      return withWrapper(() => client.request<UpdateFormSubmissionFinishMutation>(print(UpdateFormSubmissionFinishDocument), variables));
    },
    UpdateProvider(variables: UpdateProviderMutationVariables): Promise<UpdateProviderMutation> {
      return withWrapper(() => client.request<UpdateProviderMutation>(print(UpdateProviderDocument), variables));
    },
    UpdateProviderMerchantDetails(variables: UpdateProviderMerchantDetailsMutationVariables): Promise<UpdateProviderMerchantDetailsMutation> {
      return withWrapper(() => client.request<UpdateProviderMerchantDetailsMutation>(print(UpdateProviderMerchantDetailsDocument), variables));
    },
    UpdateStudentApprovalStatusByID(variables: UpdateStudentApprovalStatusByIdMutationVariables): Promise<UpdateStudentApprovalStatusByIdMutation> {
      return withWrapper(() => client.request<UpdateStudentApprovalStatusByIdMutation>(print(UpdateStudentApprovalStatusByIdDocument), variables));
    },
    UpdateStudentApprovalStatusByIDs(variables: UpdateStudentApprovalStatusByIDsMutationVariables): Promise<UpdateStudentApprovalStatusByIDsMutation> {
      return withWrapper(() => client.request<UpdateStudentApprovalStatusByIDsMutation>(print(UpdateStudentApprovalStatusByIDsDocument), variables));
    },
    UpdateStudentProgressStatus(variables: UpdateStudentProgressStatusMutationVariables): Promise<UpdateStudentProgressStatusMutation> {
      return withWrapper(() => client.request<UpdateStudentProgressStatusMutation>(print(UpdateStudentProgressStatusDocument), variables));
    },
    UpdateUserAboutDetails(variables: UpdateUserAboutDetailsMutationVariables): Promise<UpdateUserAboutDetailsMutation> {
      return withWrapper(() => client.request<UpdateUserAboutDetailsMutation>(print(UpdateUserAboutDetailsDocument), variables));
    },
    UpdateUserAboutIndustries(variables: UpdateUserAboutIndustriesMutationVariables): Promise<UpdateUserAboutIndustriesMutation> {
      return withWrapper(() => client.request<UpdateUserAboutIndustriesMutation>(print(UpdateUserAboutIndustriesDocument), variables));
    },
    UpdateUserDetails(variables: UpdateUserDetailsMutationVariables): Promise<UpdateUserDetailsMutation> {
      return withWrapper(() => client.request<UpdateUserDetailsMutation>(print(UpdateUserDetailsDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;